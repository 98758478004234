import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

//hooks
import { useAdminSupportReduxHandlers } from '../../../features/admin/support/useAdminSupportHandlers';
import { useAdminAccountReduxHandlers } from '../../../features/admin/account/useAdminAccountHandlers';

const TicketsSocketContext = createContext({});

export const TicketsSocketProvider = ({ children }) => {
  //Hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleSocketTicketsData } = useAdminSupportReduxHandlers();

  //state
  const [ticketsSocketStatus, setTicketsSocketStatus] = useState('closed');

  //variables
  const logsOn = false;
  const socketRef = useRef(null);

  const ioTicketsSocketURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/tickets'
      : 'https://myndfull.com/ws/tickets';
  const withCredentials =
    process.env.REACT_APP_ENV === 'development' ? false : true;

  const connectTicketsSocket = () => {
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    if (!socketRef.current && ticketsSocketStatus !== 'connected') {
      setTicketsSocketStatus('initializing');
      let newSocket;

      newSocket = io(ioTicketsSocketURL, {
        query: {
          adminAccountId,
        },
        reconnectionAttempts: 10,
        withCredentials: withCredentials,
      });

      newSocket.on('connect', () => {
        logsOn &&
          console.log(`Connected to Tickets Socket, id: ${newSocket.id}`);
        setTicketsSocketStatus('connected');
      });

      newSocket.on('ticketData', (ticketData) => {
        handleSocketTicketsData(ticketData);
      });

      newSocket.on('disconnect', (reason, details) => {
        logsOn &&
          console.error(
            `Tickets socket disconnect, Reason: ${reason}, Details: ${details}`
          );
        setTicketsSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn &&
            console.error('Tickets socket disconnect, no automatic reconnect');
          closeTicketsSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn &&
            console.error('Tickets socket disconnect, automatic reconnect');
        } else {
          logsOn &&
            console.error(
              `Tickets socket disconnect, Unexpected disconnect reason: ${reason}`
            );
          closeTicketsSocket();
        }
      });

      newSocket.on('error', (error) => {
        logsOn && console.error('Tickets socket, socket error:', error);
        setTicketsSocketStatus('closed');
        closeTicketsSocket();
      });

      return (socketRef.current = newSocket);
    }
  };

  const getTicketsSocket = () => {
    return socketRef.current;
  };

  const closeTicketsSocket = () => {
    const socket = socketRef.current;

    if (socket) {
      socket.close();
      socketRef.current = null;
      setTicketsSocketStatus('closed');
    }
  };

  return (
    <TicketsSocketContext.Provider
      value={{
        connectTicketsSocket,
        closeTicketsSocket,
        getTicketsSocket,
        ticketsSocketStatus,
      }}
    >
      {children}
    </TicketsSocketContext.Provider>
  );
};

export const useTicketsSocket = () => {
  return useContext(TicketsSocketContext);
};

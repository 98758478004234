import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useTranslationsHandlers } from '../../../useTranslationsHandlers';
import { useApiStatus } from '../../../../../../context/ApiStatusProvider';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';

//utility
import { image_draft } from '../../../../../../assets/images';
import { img_empty_box, trash } from '../../../../../../assets/icons/index';

const TranslationsRemoveDrafts = () => {
  const {
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    savedTranslationsRoomData,
  } = useTranslationRoomSocket();
  const { handleRemoveTranslationsDrafts } = useTranslationsHandlers();
  const { handleError } = useApiStatus();

  //language
  const { TranslationDrafts } = useLanguageTranslationRoom();
  const { IconAlts } = useLanguageComponents();

  //state
  const [removeDraftIds, setRemoveDraftIds] = useState([]);
  const [removeDraftsIsLoading, setRemoveDraftsIsLoading] = useState(false);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  async function removeDrafts() {
    if (!removeDraftsIsLoading) {
      setRemoveDraftsIsLoading(true);
      try {
        const success = await handleRemoveTranslationsDrafts({
          removeDraftIds,
        });
        setRemoveDraftsIsLoading(false);

        if (success) {
          handleCreatePageChange('drafts');
        }
      } catch (error) {
        handleError({
          id: Date.now(),
          origin: 'TranslationsRemoveDrafts/removeDrafts',
          error,
        });
        return setRemoveDraftsIsLoading(false);
      }
    }
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_draft}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          {savedTranslationsRoomData?.translationsSessions?.length === 0 ? (
            <>
              <p
                className="fs21 fwsb mrg-tb24 access-ob access-o6"
                tabIndex="0"
              >
                {TranslationDrafts.noSavedDrafts}
              </p>
              <img
                src={img_empty_box}
                style={{ height: '64px' }}
                alt={IconAlts.iconEmptyBox}
                className="svg filter-gray"
              />
            </>
          ) : (
            <>
              <p
                className="fs21 fwsb mrg-tb24 access-ob access-o6"
                tabIndex="0"
              >
                {TranslationDrafts.removeDrafts}
              </p>

              <ul className="multi-selection-list">
                {savedTranslationsRoomData?.translationsSessions?.map(
                  (sessionData, key) => {
                    return (
                      <li key={`translationsSessions-${key}`}>
                        <button
                          className={`multi-selection-item fs18 ${
                            removeDraftIds.includes(sessionData._id)
                              ? 'multi-selection-item--selected'
                              : ''
                          }`}
                          onClick={() => {
                            let newArr = [];
                            if (removeDraftIds.includes(sessionData._id)) {
                              newArr = removeDraftIds.filter(
                                (id) => id !== sessionData._id
                              );
                            } else {
                              newArr = [...removeDraftIds, sessionData._id];
                            }
                            setRemoveDraftIds(newArr);
                          }}
                        >
                          {sessionData.meta.saveName}
                          {removeDraftIds.includes(sessionData._id) && (
                            <img
                              alt={IconAlts.iconTrash}
                              src={trash}
                              className="filter-red mrg-auto-left"
                            />
                          )}
                        </button>
                      </li>
                    );
                  }
                )}
              </ul>
            </>
          )}

          <div className="divider full-width mrg-tb36" />

          <ItemButton
            text={`${TranslationDrafts.removeSelectedDrafts} (${removeDraftIds?.length})`}
            handler={removeDrafts}
            fullWidth={true}
            customWrapper={'mrg-b24'}
            unavailable={removeDraftIds?.length === 0}
          />

          <ItemButton
            text={TranslationDrafts.back}
            handler={() => handleCreatePageChange('drafts')}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
};

export default TranslationsRemoveDrafts;

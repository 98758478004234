import { useEffect, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Utility
import {
  dropdown_arrow,
  folder,
  social,
  warning,
} from '../../../../assets/icons';
import useLanguageData from '../../../../language/useLanguageData';

const TagsToggle = ({ view }) => {
  //Hooks
  const {
    //Big Tags
    settingsBigTagsListOpened,
    handleSettingsSetBigTagsListOpened,
    settingsSelectedBigTags,

    //My events
    settingsSelectedMyEvents,
    settingsMyEventsListOpened,
    handleSettingsSetMyEventsListOpened,

    //Tiny Tags
    settingsTinyTagsListOpened,
    handleSettingsSetTinyTagsListOpened,
    settingsSelectedTinyTags,
    settingsSelectedTinyTagGroupIds,

    //Community
    settingsSelectedAccountsFollowingIds,
    handleSettingsSetAccountsFollowingListOpened,
    settingsAccountsFollowingListOpened,
    settingsSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();
  const {
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  } = useAccountReduxHandlers();

  //lanugage
  const { IconAlts } = useLanguageComponents();
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { BigTagIcon, TinyTagIcon } = useLanguageData();

  //Component state
  const [viewSelectionHeading, setViewSelectionHeading] = useState();

  //Component variables
  let tagsToggle;

  useEffect(() => {
    if (view === 'myevents') {
      setViewSelectionHeading(`${SettingsEventsLanguage.myEvents}`);
    }
    if (view === 'bigtags') {
      setViewSelectionHeading(`${SettingsEventsLanguage.bigTags}`);
    }
    if (view === 'tinytags') {
      setViewSelectionHeading(`${SettingsEventsLanguage.tinyTags}`);
    }
    if (view === 'community') {
      setViewSelectionHeading(`${SettingsEventsLanguage.communityEvents}`);
    }
  }, [view]); //sets translated viewSelectionHeading

  //JSX conditional
  if (view === 'myevents') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() =>
          handleSettingsSetMyEventsListOpened(!settingsMyEventsListOpened)
        }
        aria-expanded={settingsMyEventsListOpened}
        aria-label={`${SettingsEventsLanguage.myEvents}. ${
          SettingsEventsLanguage.ariaCurrentSelections
        }. ${
          settingsSelectedMyEvents?.length === 0
            ? SettingsEventsLanguage.ariaNoSelectionsActive
            : ''
        } ${
          settingsSelectedMyEvents.includes('saved')
            ? SettingsEventsLanguage.saved
            : ''
        } ${
          settingsSelectedMyEvents.includes('registered')
            ? SettingsEventsLanguage.saved
            : ''
        } ${
          settingsSelectedMyEvents.includes('participationEvents')
            ? SettingsEventsLanguage.saved
            : ''
        }.`}
      >
        <div className="tags-toggle-btn align-center">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsMyEventsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>

        {!settingsMyEventsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            {settingsSelectedMyEvents.includes('saved') && (
              <h2
                className="fs18 fwsb "
                aria-label={
                  SettingsEventsLanguage.ariaMyEventsSelectionSavedEvents
                }
              >
                {SettingsEventsLanguage.saved}
                {settingsSelectedMyEvents?.length > 1 && `,`}&nbsp;
              </h2>
            )}
            {settingsSelectedMyEvents.includes('registered') && (
              <h2
                className="fs18 fwsb "
                aria-label={
                  SettingsEventsLanguage.ariaMyEventsSelectionRegisteredEvents
                }
              >
                {SettingsEventsLanguage.registered}
                {settingsSelectedMyEvents.includes('participationEvents') &&
                  `,`}
                &nbsp;
              </h2>
            )}
            {settingsSelectedMyEvents.includes('participationEvents') && (
              <h2
                className="fs18 fwsb "
                aria-label={
                  SettingsEventsLanguage.ariaSelectionParticipationEvents
                }
              >
                {SettingsEventsLanguage.participation}
              </h2>
            )}
            {settingsSelectedMyEvents?.length === 0 && (
              <>
                <img
                  src={warning}
                  alt={IconAlts.iconWarning}
                  style={{ height: '24px' }}
                  className="mrg-r12"
                />
                <h2 className="fs18 fwsb">
                  {SettingsEventsLanguage.noEventsSelected}
                </h2>
              </>
            )}
          </>
        )}
      </button>
    );
  } else if (view === 'bigtags') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() =>
          handleSettingsSetBigTagsListOpened(!settingsBigTagsListOpened)
        }
        tabIndex="0"
        aria-expanded={settingsBigTagsListOpened}
        aria-label={`${SettingsEventsLanguage.ariaBigTagsSelectionToggle}. ${SettingsEventsLanguage.ariaBigTagsSelectionSummary} ${settingsSelectedBigTags?.length}`}
      >
        <div className="tags-toggle-btn align-center">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsBigTagsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>
        {!settingsBigTagsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div className="tags-sum fs21">
              <img
                src={BigTagIcon}
                alt={IconAlts.iconBigTag}
                style={{ height: '22px' }}
                className="mrg-r12"
              />
              <p className="color-theme fwsb pad-r6">
                {settingsSelectedBigTags?.length}
                {settingsSelectedBigTags?.length < accountBigTags?.length &&
                  `/${accountBigTags?.length}`}
              </p>
            </div>
          </>
        )}
      </button>
    );
  } else if (view === 'tinytags') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() =>
          handleSettingsSetTinyTagsListOpened(!settingsTinyTagsListOpened)
        }
        tabIndex="0"
        aria-expanded={settingsTinyTagsListOpened}
        aria-label={`${SettingsEventsLanguage.ariaTinyTagsSelectionToggle}. ${
          SettingsEventsLanguage.ariaTinyTagsSelectionSummaryFolders +
          settingsSelectedTinyTagGroupIds?.length
        }.`}
      >
        <div className="tags-toggle-btn align-center">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsTinyTagsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>
        {!settingsTinyTagsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div className="flex-row">
              <div className="tags-sum fs21 fwsb">
                <img
                  src={folder}
                  alt={IconAlts.iconTinyTagGroup}
                  className="filter-theme mrg-l12"
                  aria-hidden="true"
                />
                <p className="color-theme mrg-l12">
                  {settingsSelectedTinyTagGroupIds?.length}
                  {settingsSelectedTinyTagGroupIds?.length <
                    accountTinyTagGroups?.length &&
                    `/${accountTinyTagGroups?.length}`}
                </p>
              </div>
              <div className="tags-sum fs21 fwsb">
                <img
                  src={TinyTagIcon}
                  alt={IconAlts.tinyTags}
                  aria-hidden="true"
                  style={{ height: '30px' }}
                  className="mrg-lr12"
                />
                <p className="color-theme pad-r12">
                  {settingsSelectedTinyTags?.length}
                  {settingsSelectedTinyTags?.length < accountTinyTags?.length &&
                    `/${accountTinyTags?.length}`}
                </p>
              </div>
            </div>
          </>
        )}
      </button>
    );
  } else if (view === 'community') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() =>
          handleSettingsSetAccountsFollowingListOpened(
            !settingsAccountsFollowingListOpened
          )
        }
        tabIndex="0"
        aria-expanded={settingsAccountsFollowingListOpened}
        aria-label={`${SettingsEventsLanguage.ariaCommunitySelectionToggle}. ${
          SettingsEventsLanguage.ariaCommunitySelectionSummaryFolders +
          settingsSelectedAccountsFollowingGroupIds?.length
        }.`}
      >
        <div className="tags-toggle-btn align-center ">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsAccountsFollowingListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>
        {!settingsAccountsFollowingListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div className="flex-row">
              <div className="tags-sum fs21 fwsb">
                <img
                  src={folder}
                  alt={IconAlts.iconCommunity}
                  className="filter-theme mrg-l12"
                  aria-hidden="true"
                />
                <p className="color-theme mrg-l12">
                  {settingsSelectedAccountsFollowingGroupIds?.length}
                  {settingsSelectedAccountsFollowingGroupIds?.length <
                    accountCommunityFollowingGroups?.length &&
                    `/${accountCommunityFollowingGroups?.length}`}
                </p>
              </div>
              <div
                className="tags-sum fs21 fwsb access-ob"
                tabIndex="0"
                aria-label={`${SettingsEventsLanguage.ariaCommunitySelectionSummary} ${settingsSelectedAccountsFollowingIds?.length}.`}
              >
                <img src={social} alt={IconAlts.iconCommunity} />
                <p className="color-theme fwsb pad-r6">
                  {settingsSelectedAccountsFollowingIds?.length}
                  {settingsSelectedAccountsFollowingIds?.length <
                    accountCommunityFollowing?.length &&
                    `/${accountCommunityFollowing?.length}`}
                </p>
              </div>
            </div>
          </>
        )}
      </button>
    );
  }

  return tagsToggle;
};

export default TagsToggle;

import { useEffect, useRef, useState } from 'react';

//Hooks
import { useSettings } from '../../../context/SettingsProvider';
import { useApiStatus } from '../../../context/ApiStatusProvider';
import useReCaptcha from '../../../hooks/useReCaptcha';
import {
  useCreateDraftEventMutation,
  useLazyGetMyDraftQuery,
  useLazyMyDraftEventsQuery,
  useRemoveDraftEventMutation,
  useUpdateDraftEventMutation,
} from './draftEventApiSlice';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { useOrganizeHandlers } from './useOrganizeHandlers';
import { useOrganizeEventsSocket } from '../../../context/sockets/main/OrganizeEventsSocketProvider';
import { useCreateEventReduxHandlers } from './create/useCreateEventHandlers';
import { useEventParticipantsHandlers } from './eventParticipants/useEventParticipantsHandlers';
import { useEditEventReduxHandlers } from './edit/useEditEventHandlers';

//api
import {
  useLazyMyOrganizedEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
  useLazyMyOrganizedArchivedEventsQuery,
  useCreateEventMutation,
  useUpdateEventMutation,
} from './organizeApiSlice';
import {
  useEventRemovalNoticesUpdateForInviteContactParticipantsMutation,
  useUpdateInviteContactParticipantEventsFromEventRemovalMutation,
} from './eventParticipants/eventParticipantsApiSlice';

//Components
import SettingsOrganize from '../settings/Organize/SettingsOrganize';
import CreateEvent from './create/CreateEvent';
import OrganizeOverview from './OrganizeOverview';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import EventPage from '../events/EventPage/EventPage';
import EditEvent from './edit/EditEvent';
import FindSpeakersContent from './findSpeakers/FindSpeakersContent';
import ScrollTo from '../../../utils/UI/ScrollTo';

//Utility
import { checkImagePath } from '../../../utils/images/checkImagePath';

const Organize = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleError, handleSuccess } = useApiStatus();
  const { accountId, accountAffiliations } = useAccountReduxHandlers();
  const {
    settingsOrganizeView: organizeView,
    settingsOrganizeSelectedOrganizers: selectedOrganizers,
    handleSettingsSetSelectedOrganizers,
    handleSettingsSetSelectAllOrganizersBtn,
    handleSettingsSetOrganizeView,
    handleSettingsSetOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
  } = useSettingsHandlers();
  const { handleRemoveOrganizerEvent, handleOrganizerEventFormData } =
    useOrganizeHandlers();

  const {
    handleSendEventParticipantInviteDispatches,
    handlePublishedEventParticipationInvites,
    handleRemoveParticipationInvite,
    eventDataHasAcceptedInviteContactParticipants,
    eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts,
    //translations
    handleSendEventParticipantTranslationsDispatches,
  } = useEventParticipantsHandlers();

  const { handleCreateEventReset, handleCreateEventSetId, createEventId } =
    useCreateEventReduxHandlers();
  const { handleEditEventReset } = useEditEventReduxHandlers();

  //Socket
  const { connectOrganizeEventsSocket, closeOrganizeEventsSocket } =
    useOrganizeEventsSocket();

  //API Queries
  const [getMyOrgEvents, { isUninitialized: myOrgEventsisUninitialized }] =
    useLazyMyOrganizedEventsQuery(accountId);

  const [
    getMyOrgScheduledEvents,
    { isUninitialized: myOrgScheduledEventsisUninitialized },
  ] = useLazyMyOrganizedScheduledEventsQuery(accountId);
  const [
    getMyOrgArchivedEvents,
    { isUninitialized: myOrgArchivedEventsisUninitialized },
  ] = useLazyMyOrganizedArchivedEventsQuery(accountId);

  const [getDraftEvents, { isUninitialized: myDraftEventsIsUninitialized }] =
    useLazyMyDraftEventsQuery(accountId);

  //Api Event
  const [createEvent] = useCreateEventMutation();
  const [updateEvent] = useUpdateEventMutation();

  //API Draft
  const [updateDraft] = useUpdateDraftEventMutation();
  const [createDraftEvent] = useCreateDraftEventMutation();
  const [getMyDraft] = useLazyGetMyDraftQuery();
  const [removeDraft] = useRemoveDraftEventMutation();
  const [updateInviteContactParticipantEventsFromEventRemoval] =
    useUpdateInviteContactParticipantEventsFromEventRemovalMutation();
  const [eventRemovalNoticesUpdateForInviteContactParticipants] =
    useEventRemovalNoticesUpdateForInviteContactParticipantsMutation();

  //Component state
  const [editEvent, setEditEvent] = useState();
  const [eventPreview, setEventPreview] = useState(false);
  const [
    removedParticipantsWithDispatchedInvites,
    setRemovedParticipantsWithDispatchedInvites,
  ] = useState([]);

  //ui
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [organizeIsLoading, setOrganizeIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  //Image state
  const [eventImageDisplayCropped, setEventImageDisplayCropped] = useState();
  const [participantImagesCropped, setParticipantImagesCropped] = useState([]);
  const [eventAdditionalImagesCropped, setEventAdditionalImagesCropped] =
    useState([]);

  //pwd
  const [decryptedTranslationsPassword, setDecryptedTranslationsPassword] =
    useState('');
  const [decryptedVirtualPasscode, setDecryptedVirtualPasscode] = useState('');

  // variables
  const reinitializeParticipantImagesCropped = useRef(null);

  //Initialize functions
  useEffect(() => {
    setNavbar('organize');
    setEventPageLoadComplete(false);
    setOrganizeIsLoading(false);

    connectOrganizeEventsSocket();

    if (selectedOrganizers?.length === 0) {
      let newSelections = [];
      accountAffiliations?.map((aA) => newSelections.push(aA.affiliation._id));
      newSelections.push(accountId);
      handleSettingsSetSelectedOrganizers(newSelections);
      handleSettingsSetSelectAllOrganizersBtn(true);
    }

    return () => {
      closeOrganizeEventsSocket();

      if (reinitializeParticipantImagesCropped.current) {
        clearTimeout(reinitializeParticipantImagesCropped.current);
      }
    };
    /*eslint-disable-next-line*/
  }, []); //initialize state +  web socket for organizedEvents + selects accountId and affiliate organizers for sorting if none are selected upon load + clear timer on unmount if still running

  useEffect(() => {
    if (organizeView === 'create') {
      setOrganizeIsLoading(false);
    }
  }, [organizeView]);

  useEffect(() => {
    if (organizeView !== 'create') {
      setDecryptedTranslationsPassword('');
      setDecryptedVirtualPasscode('');
    }
  }, [organizeView]); //clear decrypt passwords when navigating within Organize

  useEffect(() => {
    if (
      accountId &&
      myOrgEventsisUninitialized &&
      myOrgScheduledEventsisUninitialized &&
      myOrgArchivedEventsisUninitialized &&
      myDraftEventsIsUninitialized
    ) {
      getMyOrgEvents(accountId);
      getMyOrgScheduledEvents(accountId);
      getMyOrgArchivedEvents(accountId);
      getDraftEvents(accountId);
    }

    /*eslint-disable-next-line*/
  }, [
    myDraftEventsIsUninitialized,
    myOrgEventsisUninitialized,
    myOrgScheduledEventsisUninitialized,
    myOrgArchivedEventsisUninitialized,
    accountId,
  ]); //get my events

  //API functions
  async function handleSubmitEvent({
    eventData,
    handleType,
    exit,
    editEventConflictObj,
  }) {
    //set this to top scope; if api failure image, the participant image cropper is embedded in component and wont automatically run, wiping imagePreview state; save here and reload on error;
    let savedParticipantImagesCropped = [];

    try {
      const recaptchaVerified = await handleReCaptchaVerify(
        'SUBMIT_EVENT',
        false
      );
      if (recaptchaVerified !== true) return false;

      let res;
      let newEventData;

      setSubmitIsLoading(true);

      if (participantImagesCropped?.length > 0) {
        savedParticipantImagesCropped = JSON.parse(
          JSON.stringify(participantImagesCropped)
        );
      }

      const formData = await handleOrganizerEventFormData({
        handleType,
        eventData,
        participantImagesCropped,
        eventImageDisplayCropped,
        eventAdditionalImagesCropped,
        editEventConflictObj,
      });

      if (handleType === 'publish' || handleType === 'publishScheduled') {
        res = await createEvent({
          body: formData,
        });

        if (res?.error?.error || !res?.data?.data) {
          throw new Error(res.error.error || 'no response data');
        }

        //add event to invite participants and notices
        newEventData = res?.data?.data;

        await handlePublishedEventParticipationInvites({
          eventId: newEventData._id,
          draftId: createEventId,
          eventParticipants: newEventData?.participants,
          handleType,
        }); //handle invites from draft

        await handleSendEventParticipantInviteDispatches({
          eventData: newEventData,
          eventType: handleType === 'publish' ? 'event' : 'scheduledEvent',
        }); //sends undispatched invites + updates event;

        if (eventData?.translations?.translationsEnabled) {
          await handleSendEventParticipantTranslationsDispatches({
            eventData: newEventData,
            eventType: handleType === 'publish' ? 'event' : 'scheduledEvent',
          });
        }

        //remove associated event draft
        if (createEventId) {
          await removeDraft({ accountId, draftId: createEventId });
        }

        //need to get new dispatches data.
        await getMyOrgEvents(accountId);

        if (exit) {
          //need to check for image path
          if (
            res.data.data.newEvent?.generic?.eventImageDisplay?.imagePath &&
            res.data.data.newEvent?.generic?.eventImageDisplay?.imagePath !==
              'event_img_default.png'
          ) {
            await checkImagePath(
              res.data.data.newEvent.generic.eventImageDisplay?.imagePath,
              'event'
            );
          }

          if (handleType === 'publish' || handleType === 'publishScheduled') {
            handleSuccess({
              message: 'eventCreated',
              id: Date.now(),
              origin: 'CreateEvent.js/handleSubmitEvent',
            });
          } else if (
            handleType === 'editPublished' ||
            handleType === 'editScheduled'
          ) {
            handleSuccess({
              message: 'eventUpdated',
              id: Date.now(),
              origin: 'CreateEvent.js/handleSubmitEvent',
            });
          }
          handleCreateEventReset();
          //prep settings slice for navigation
          eventData?.schedule?.enabled
            ? handleSettingsSetOrganizeOverviewStandaloneGridEvents('scheduled')
            : handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
          handleSettingsSetOrganizeOverviewContent('standalone');
          handleSettingsSetOrganizeView('overview');
        }
      } else if (
        handleType === 'editScheduled' ||
        handleType === 'editPublished'
      ) {
        if (removedParticipantsWithDispatchedInvites?.length > 0) {
          await handleRemoveParticipationInvite({
            accountId,
            updateEventData: {
              eventType: 'event',
              eventId: eventData?.meta?._id,
            },
            removedParticipantsWithDispatchedInvites,
          });
          setRemovedParticipantsWithDispatchedInvites([]);
        }

        res = await updateEvent({
          body: formData,
        });

        if (res?.error?.error || !res?.data?.data) {
          throw new Error(res.error.error || 'no response data');
        }

        const updatedEventData = res?.data?.data;

        const eventType =
          handleType === 'editPublished' ? 'event' : 'scheduledEvent';

        await handleSendEventParticipantInviteDispatches({
          eventData: updatedEventData,
          eventType,
        });

        if (eventData?.translations?.translationsEnabled) {
          await handleSendEventParticipantTranslationsDispatches({
            eventData: updatedEventData,
            eventType,
          });
        }
        setEditEvent();
        handleEditEventReset();
      }
      clearImages();
      setSubmitIsLoading(false);
    } catch (error) {
      console.log('handleSubmitError', error);
      setOrganizeIsLoading(false);

      //reset participantImagesCropped with short delay
      reinitializeParticipantImagesCropped.current = setTimeout(() => {
        setSubmitIsLoading(false);
        setParticipantImagesCropped(savedParticipantImagesCropped);
      }, 2000);

      handleError({
        message: 'tryAgain',
        statusCode: 500,
        origin: 'CreateEvent/handleSubmitEvent',
      });
    }
  }

  async function handleSubmitEventDraft({ eventData, handleType, exit }) {
    //set this to top scope; if api failure image, the participant image cropper is embedded in component and wont automatically run, wiping imagePreview state; save here and reload on error;
    let savedParticipantImagesCropped = [];

    try {
      const recaptchaVerified = await handleReCaptchaVerify(
        'SUBMIT_DRAFT',
        false
      );
      if (recaptchaVerified !== true) return false;

      let res;
      let newDraft;

      setSubmitIsLoading(true);

      if (participantImagesCropped?.length > 0) {
        savedParticipantImagesCropped = JSON.parse(
          JSON.stringify(participantImagesCropped)
        );
      }

      //draft
      const formData = await handleOrganizerEventFormData({
        handleType,
        eventData,
        participantImagesCropped,
        eventImageDisplayCropped,
        eventAdditionalImagesCropped,
      });

      if (handleType === 'createDraft') {
        res = await createDraftEvent({
          body: formData,
        });

        handleCreateEventSetId(res?.data?.data?._id);
      } else if (handleType === 'updateDraft') {
        if (removedParticipantsWithDispatchedInvites?.length > 0) {
          await handleRemoveParticipationInvite({
            accountId,
            updateEventData: {
              eventType: 'draft',
              eventId: eventData?.meta?._id,
            },
            removedParticipantsWithDispatchedInvites,
          });
          setRemovedParticipantsWithDispatchedInvites([]);
        }

        res = await updateDraft({
          body: formData,
        });
      }

      if (res?.error?.error || !res?.data?.data) {
        throw new Error(res.error.error || 'no response data');
      }

      newDraft = res?.data?.data;
      await handleSendEventParticipantInviteDispatches({
        eventData: newDraft,
        eventType: 'draft',
      });

      if (newDraft?.translations?.translationsEnabled) {
        await handleSendEventParticipantTranslationsDispatches({
          eventData: newDraft,
          eventType: 'draft',
        });
      }

      clearImages();
      if (exit && newDraft) {
        handleSuccess({
          message: 'draftSaved',
          id: Date.now(),
          origin: 'CreateEvent.js/handleSubmitEvent',
        });
        handleCreateEventReset();
        setEventPageLoadComplete(false);

        //prep settings slice for navigation
        handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
        handleSettingsSetOrganizeOverviewContent('standalone');
        handleSettingsSetOrganizeView('overview');
      } else {
        //need to get event again with updated data then set create slice.
        const draftData = await getMyDraft({
          accountId,
          draftId: newDraft?._id,
        });
        //send data back to initialize to encrypt pwds for local storage (restore functionality)
        if (draftData) {
          return draftData?.data;
        }
      }
    } catch (error) {
      setOrganizeIsLoading(false);

      //reset participantImagesCropped with short delay
      reinitializeParticipantImagesCropped.current = setTimeout(() => {
        setSubmitIsLoading(false);
        setParticipantImagesCropped(savedParticipantImagesCropped);
      }, 2000);

      handleError({
        message: error?.message,
        statusCode: 500,
        origin: 'CreateEvent/handleSubmitEvent',
      });
    }
  }

  async function handleRemoveEvent({
    eventId,
    organizerId,
    eventType,
    participants,
  }) {
    //eventTypes based on settings redux property: 'active', 'scheduled', 'archived',
    if (!submitIsLoading) {
      try {
        setSubmitIsLoading(true);

        const hasAcceptedInviteContactParticipants =
          eventDataHasAcceptedInviteContactParticipants(participants);

        if (hasAcceptedInviteContactParticipants) {
          // remove event from accountEvent.participantEvents
          await updateInviteContactParticipantEventsFromEventRemoval({
            accountId,
            eventId,
            participants,
          });
        }

        const hasAcceptedOrPendingInviteContactParticipants =
          eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts(
            participants
          );

        if (hasAcceptedOrPendingInviteContactParticipants) {
          //remove pending notices for participants + notify accepted participants that event was removed
          await eventRemovalNoticesUpdateForInviteContactParticipants({
            accountId,
            eventId,
          });
        }

        await handleRemoveOrganizerEvent({ eventId, organizerId, eventType });
        setEditEvent();
        setSubmitIsLoading(false);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('DEV handleRemoveEvent error', error);
        }
      }
    }
  }

  //functions
  function handleReturnFromPreview(scrollToLabel) {
    setEventPreview();
    const animationTimer = setTimeout(() => {
      ScrollTo({ focusId: scrollToLabel, scrollToElementId: scrollToLabel });
    }, 100);

    return () => clearTimeout(animationTimer);
  }

  function clearImages() {
    if (eventImageDisplayCropped?.imagePreview) {
      URL.revokeObjectURL(eventImageDisplayCropped.imagePreview);
    }

    if (eventAdditionalImagesCropped?.length > 0) {
      for (const image of eventAdditionalImagesCropped || []) {
        if (image?.imagePreview) {
          URL.revokeObjectURL(image.imagePreview);
        }
      }
    }

    if (participantImagesCropped?.length > 0) {
      for (const image of participantImagesCropped || []) {
        if (image?.imagePreview) {
          URL.revokeObjectURL(image.imagePreview);
        }
      }
    }
    setEventImageDisplayCropped(null);
    setEventAdditionalImagesCropped([]);
    setParticipantImagesCropped([]);
  }

  return (
    <>
      {!eventPageLoadComplete ? (
        <LoadingScreenContent
          numberOfLoadChecks={3}
          setLoadComplete={() => {
            setEventPageLoadComplete(true);
          }}
          loadCheck1={accountId ? true : false}
          loadCheck2={organizeView ? true : false}
          loadCheck3={!eventPageLoadComplete ? true : false}
          customWrapper={'min-full-height'}
        />
      ) : (
        <>
          {eventPreview && (
            <EventPage
              handleCustomReturn={handleReturnFromPreview}
              organizerViewOn={true}
              previewEventData={eventPreview}
              editPreviewMode={editEvent ? true : false}
              //cropped images
              participantImagesCropped={participantImagesCropped}
              eventDisplayImageCropped={eventImageDisplayCropped}
              eventAdditionalImagesCropped={eventAdditionalImagesCropped}
              //pwd
              decryptedTranslationsPassword={decryptedTranslationsPassword}
              decryptedVirtualPasscode={decryptedVirtualPasscode}
            />
          )}

          <div className={`organize ${eventPreview ? 'remove' : ''}`}>
            {!eventPreview && !editEvent && <SettingsOrganize />}

            {organizeView === 'overview' && !editEvent && (
              <OrganizeOverview
                setEditEvent={setEditEvent}
                handleRemoveEvent={handleRemoveEvent}
                setEventPreview={setEventPreview}
              />
            )}

            {organizeView === 'create' && !editEvent && (
              <CreateEvent
                eventPreview={eventPreview}
                setEventPreview={setEventPreview}
                handleSubmitEvent={handleSubmitEvent}
                handleSubmitEventDraft={handleSubmitEventDraft}
                removedParticipantsWithDispatchedInvites={
                  removedParticipantsWithDispatchedInvites
                }
                setRemovedParticipantsWithDispatchedInvites={
                  setRemovedParticipantsWithDispatchedInvites
                }
                //loading state
                setSubmitIsLoading={setSubmitIsLoading}
                organizeIsLoading={organizeIsLoading}
                setOrganizeIsLoading={setOrganizeIsLoading}
                submitIsLoading={submitIsLoading}
                //cropped imgs
                clearImages={clearImages}
                participantImagesCropped={participantImagesCropped}
                setParticipantImagesCropped={setParticipantImagesCropped}
                eventImageDisplayCropped={eventImageDisplayCropped}
                setEventImageDisplayCropped={setEventImageDisplayCropped}
                eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                setEventAdditionalImagesCropped={
                  setEventAdditionalImagesCropped
                }
                //pwd
                decryptedTranslationsPassword={decryptedTranslationsPassword}
                setDecryptedTranslationsPassword={
                  setDecryptedTranslationsPassword
                }
                decryptedVirtualPasscode={decryptedVirtualPasscode}
                setDecryptedVirtualPasscode={setDecryptedVirtualPasscode}
              />
            )}

            {editEvent && (
              <EditEvent
                eventData={editEvent}
                setEditEvent={setEditEvent}
                handleRemoveEvent={handleRemoveEvent}
                setEventPreview={setEventPreview}
                eventPreview={eventPreview}
                handleSubmitEvent={handleSubmitEvent}
                removedParticipantsWithDispatchedInvites={
                  removedParticipantsWithDispatchedInvites
                }
                setRemovedParticipantsWithDispatchedInvites={
                  setRemovedParticipantsWithDispatchedInvites
                }
                //loading state
                organizeIsLoading={organizeIsLoading}
                setOrganizeIsLoading={setOrganizeIsLoading}
                submitIsLoading={submitIsLoading}
                //cropped imgs
                participantImagesCropped={participantImagesCropped}
                setParticipantImagesCropped={setParticipantImagesCropped}
                eventImageDisplayCropped={eventImageDisplayCropped}
                setEventImageDisplayCropped={setEventImageDisplayCropped}
                eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                setEventAdditionalImagesCropped={
                  setEventAdditionalImagesCropped
                }
                clearImages={clearImages}
              />
            )}

            {organizeView === 'speakers' && <FindSpeakersContent />}
          </div>
        </>
      )}
    </>
  );
};

export default Organize;

import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//lanugage
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//utils
import { dropdown_arrow } from '../../../../assets/icons';

const EventAdditionalImages = ({
  eventData,
  eventAdditionalImagesCropped,
  previewMode,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();

  //for preview mode, need to combine data and cropped image
  const [evAdditionalImages, setEvAdditionalImages] = useState([]);
  const [evAdditionalStandaloneImages, setEvAdditionalStandaloneImages] =
    useState();

  //UI
  const [currentIndex, setCurrentIndex] = useState(0);
  const [prevButtonTap, setPrevButtonTap] = useState(false);
  const [nextButtonTap, setNextButtonTap] = useState(false);

  useEffect(() => {
    let newEvAdditionalImages = [];
    let newEvAdditionalImagesStandalone = [];

    if (eventAdditionalImagesCropped?.length > 0) {
      let newArr = [];

      eventAdditionalImagesCropped?.map((croppedObj, index) => {
        let evImgData;

        if (previewMode) {
          evImgData = eventData?.generic?.eventAdditionalImages?.find(
            (evImgData) => {
              if (evImgData?.id === croppedObj.id) return evImgData;
            }
          );
          evImgData = { ...evImgData, ...croppedObj };
        }

        newArr.push(evImgData);
      });

      newArr?.map((ev) => {
        if (ev?.standalone) {
          newEvAdditionalImagesStandalone.push(ev);
        } else {
          newEvAdditionalImages.push(ev);
        }
      });
    } else if (eventData?.generic?.eventAdditionalImages?.length > 0) {
      eventData?.generic?.eventAdditionalImages?.map((ev) => {
        if (ev?.standalone) {
          newEvAdditionalImagesStandalone.push(ev);
        } else {
          newEvAdditionalImages.push(ev);
        }
      });
    }

    if (newEvAdditionalImages?.length === 1) {
      newEvAdditionalImagesStandalone = [
        ...newEvAdditionalImages,
        ...newEvAdditionalImagesStandalone,
      ];
      newEvAdditionalImages = [];
    } //if only one image in carousel then just make it a standalone

    setEvAdditionalImages(newEvAdditionalImages);
    setEvAdditionalStandaloneImages(newEvAdditionalImagesStandalone);
  }, [eventAdditionalImagesCropped, eventData]); //set image states

  //UI funcitons
  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === evAdditionalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? evAdditionalImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      {evAdditionalImages?.length > 0 && (
        <>
          <div className="carousel__wrapper">
            <div className="carousel__container">
              {evAdditionalImages?.map((evImgObj, index) => (
                <div
                  key={`ev-additional-${index}`}
                  className={`carousel-slide no-select ${
                    index === currentIndex ? 'active' : ''
                  }`}
                >
                  <LoadBucketImage
                    altAriaText={evImgObj?.altCaption}
                    backupPath={'event_img_default.png'}
                    containerClassName={'carousel-image'}
                    croppedImage={evImgObj?.croppedImage?.croppedImage}
                    imagePreview={evImgObj?.imagePreview}
                    imagePath={evImgObj?.imagePath}
                    imgClassName={'full-width'}
                    partialPath={'myndfull_event_additional_images'}
                    disableFocus={index !== currentIndex}
                    accessFocusClass={'access-o-6'}
                  />
                </div>
              ))}
            </div>
          </div>
          {evAdditionalImages?.map((evImgObj, index) => {
            if (
              evImgObj?.altCaptionToggle === 'displayCaption' &&
              evImgObj?.altCaption?.length > 0
            ) {
              return (
                <div
                  className={`${
                    index !== currentIndex ? 'remove' : ''
                  } ev-image-caption`}
                  key={`ev-additionalCaption-${index}`}
                >
                  {evImgObj.altCaption}
                </div>
              );
            }
          })}

          <div className="flex-row pos-rel">
            <button
              className={`carousel-button carousel-button--left no-select access-ob access-o-6 ${
                prevButtonTap ? 'carousel-button--tap' : ''
              }`}
              tabIndex="0"
              onClick={() =>
                handleMobileTap(
                  [() => setPrevButtonTap(true), () => setPrevButtonTap(false)],
                  [() => goToPrevSlide()]
                )
              }
            >
              <img
                src={dropdown_arrow}
                alt={IconAlts.iconArrow}
                className={`${
                  prevButtonTap ? 'filter-white' : 'filter-darkgray'
                } rotate-cw90 svg`}
                style={{ height: '12px' }}
              />
            </button>
            <button
              className={`carousel-button carousel-button--right no-select access-ob access-o-6 ${
                nextButtonTap ? 'carousel-button--mobile--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [() => setNextButtonTap(true), () => setNextButtonTap(false)],
                  [() => goToNextSlide()]
                )
              }
            >
              <img
                src={dropdown_arrow}
                alt={IconAlts.iconArrow}
                className={`${
                  nextButtonTap ? 'filter-white' : 'filter-darkgray'
                } rotate-ccw90 svg`}
                style={{ height: '12px' }}
              />
            </button>
          </div>

          <div className="carousel-selection-bar__container mrg-tb24 full-width no-select">
            {evAdditionalImages?.map((_, index) => {
              return (
                <div
                  className={`carousel-selection-bar no-select  ${
                    index === currentIndex
                      ? 'carousel-selection-bar--selected'
                      : ''
                  } ${
                    index !== evAdditionalImages?.length - 1 ? 'mrg-r12' : ''
                  }`}
                  key={`carousel-selection-bar-${index}`}
                />
              );
            })}
          </div>
        </>
      )}

      {evAdditionalStandaloneImages?.length > 0 && (
        <div className="ev-image__container no-select  mrg-t48">
          {evAdditionalStandaloneImages?.map((evImgObj, index) => {
            return (
              <div
                key={`ev-additional-standalone-${index}`}
                className={`full-width ${
                  index === evAdditionalStandaloneImages?.length - 1
                    ? ''
                    : 'mrg-b24'
                }`}
              >
                <LoadBucketImage
                  altAriaText={evImgObj?.altCaption}
                  backupPath={'event_img_default.png'}
                  containerClassName={'full-width'}
                  croppedImage={evImgObj?.croppedImage?.croppedImage}
                  imagePreview={evImgObj?.imagePreview}
                  imagePath={evImgObj?.imagePath}
                  imgClassName={'full-width'}
                  partialPath={'myndfull_event_additional_images'}
                />

                {evImgObj?.altCaptionToggle === 'displayCaption' &&
                  evImgObj?.altCaption?.length > 0 && (
                    <div className="ev-image-caption no-select">
                      <p className="ev-image-caption--text no-select">
                        {evImgObj?.altCaption}
                      </p>
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default EventAdditionalImages;

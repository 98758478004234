//Hooks
import { useEffect } from 'react';
import { useSettings } from '../../../context/SettingsProvider';

const AdminLanding = () => {
  const { setNavbar } = useSettings();

  useEffect(() => {
    setNavbar('landing');
    /*eslint-disable-next-line*/
  }, []);

  return (
    <>
      <div className="admin">Admin Landing</div>
    </>
  );
};

export default AdminLanding;

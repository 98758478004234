//language
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import FieldValueMissing from './FieldValueMissing';

//Utility
import { lock_locked } from '../../../../assets/icons';

const HostingInPersonContent = ({
  eventData,
  EventPage,
  eventStatus,
  organizerViewOn,
  decryptedVirtualPasscode,
  isParticipant,
}) => {
  //language
  const { OnlinePlatformOptions } = useLanguageData();
  const { IconAlts } = useLanguageComponents();

  //Component Variables
  const privateDetails = eventData.registrationData.privateDetails.enabled;
  const meetingIdLocked =
    eventData.registrationData.privateDetails.virtualMeetingId;
  const passcodeLocked =
    eventData.registrationData.privateDetails.virtualMeetingPasscode;
  const linkLocked =
    eventData.registrationData.privateDetails.virtualMeetingLink;

  let meetingIdInfo;
  let passcodeInfo;
  let linkInfo;

  //JSX conditional

  if (
    privateDetails &&
    meetingIdLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    meetingIdInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.meetingId}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (organizerViewOn && privateDetails && meetingIdLocked) {
    meetingIdInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.meetingId}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {eventData.hosting.virtual.meetingId?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {eventData.hosting.virtual.meetingId}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    meetingIdInfo = (
      <>
        {eventData.hosting.virtual.meetingId?.length > 0 && (
          <p
            className="ev-info__row access-ob access-o6"
            tabIndex="0"
          >{`${EventPage.meetingId}: ${eventData.hosting.virtual.meetingId}`}</p>
        )}
      </>
    );
  }

  if (
    privateDetails &&
    passcodeLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    passcodeInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.passcode}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (organizerViewOn && privateDetails && passcodeLocked) {
    passcodeInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.passcode}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {decryptedVirtualPasscode?.length > 0 ||
        eventData.hosting.virtual.passcode?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {decryptedVirtualPasscode || eventData.hosting.virtual.passcode}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    passcodeInfo = (
      <>
        {(decryptedVirtualPasscode || eventData.hosting.virtual.passcode) && (
          <p className="ev-info__row access-ob access-o6" tabIndex="0">{`${
            EventPage.passcode
          }: ${
            decryptedVirtualPasscode || eventData.hosting.virtual.passcode
          }`}</p>
        )}
      </>
    );
  }

  if (
    privateDetails &&
    linkLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    linkInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.link}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (organizerViewOn && privateDetails && linkLocked) {
    linkInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.link}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {eventData.hosting.virtual.meetingLink?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">{`${eventData.hosting.virtual.meetingLink}`}</p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    linkInfo = (
      <>
        {eventData.hosting.virtual.meetingLink?.length > 0 && (
          <p
            className="ev-info__row access-ob access-o6"
            tabIndex="0"
          >{`${EventPage.link}: ${eventData.hosting.virtual.meetingLink}`}</p>
        )}
      </>
    );
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="virtualAttendanceLabel"
      >
        {EventPage.virtualAttendance}
      </label>
      <div className="ev-info__container">
        <p className="ev-info__row access-ob access-o6" tabIndex="0">
          {OnlinePlatformOptions[eventData.hosting?.virtual?.platform]}
        </p>
        {meetingIdInfo}
        {passcodeInfo}
        {linkInfo}
        <div className="h24" />
      </div>
    </>
  );
};

export default HostingInPersonContent;

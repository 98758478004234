const FindEventsToolbarInteractionSummary = ({
  toolbarActiveHighlight,
  side,
}) => {
  return (
    <div
      className={`demo-toolbar-highlight-description ${
        side === 'left'
          ? 'demo-toolbar-highlight-description--left'
          : 'demo-toolbar-highlight-description--right'
      }`}
    >
      {side === 'left' && toolbarActiveHighlight === 'view' && (
        <div className="flex-column">
          <h4>Views</h4>
          <p>Switch through Views to change your Events List.</p>
        </div>
      )}

      {side === 'right' && toolbarActiveHighlight === 'filters' && (
        <div className="flex-column">
          <h4>Quick Sorting</h4>
          <p>
            Sort your events. In-Person and Virtual events includes hybrids.
          </p>
        </div>
      )}
      {side === 'left' && toolbarActiveHighlight === 'tags' && (
        <div className="flex-column">
          <h4>Tags</h4>
          <p>
            Your account Tags can be toggled to quickly filter events. Tiny Tags
            and Community Tags can be grouped for easier management.
          </p>
        </div>
      )}
    </div>
  );
};

export default FindEventsToolbarInteractionSummary;

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import {
  resetRegistration,
  softResetRegistration,

  //Generic
  setAccessCode,
  setAccountType,
  setAccountVerificationURL,
  setRegistrationPage,
  setRegistrationPageStatus,
  setRegistrationPageIndex,
  setRegistrationIsLoadingCompleteRegistration,
  setIsSuccess,
  setRegistrationComponentArr,
  selectRegistrationGenericAccountType,
  selectRegistrationGenericAccountVerificationURL,
  selectRegistrationGenericRegistrationPage,
  selectRegistrationGenericRegistrationPageStatus,
  selectRegistrationGenericRegistrationPageIndex,
  selectRegistrationGenericIsLoadingCompleteRegistration,
  selectRegistrationGenericAccessCode,
  selectRegistrationGenericIsSuccess,
  selectRegistrationGenericComponentArr,
  setResendVerificationEmailIsLoading,
  setResendVerificationEmailLimit,
  selectRegistrationGenericResendVerificationEmailIsLoading,
  selectRegistrationGenericResendVerificationEmailLimit,
  selectRegistrationGenericUnverifiedAccountId,
  setUnverifiedAccountId,

  //Attendee
  setAttendeeBigTags,
  setAttendeeCoords,
  setAttendeeDistanceFilter,
  setAttendeeDistanceMeasurement,
  setAttendeeEventSearchOnlineCountries,
  setAttendeePlaceCoordinates,
  setAttendeeSearchLanguages,
  setAttendeeSearchLanguagesNotListed,
  setAttendeeTinyTagGroups,
  setAttendeeTinyTags,
  selectRegistrationAttendeeBigTags,
  selectRegistrationAttendeeCoords,
  selectRegistrationAttendeeDistanceFilter,
  selectRegistrationAttendeeDistanceMeasurement,
  selectRegistrationAttendeeEventSearchOnlineCountries,
  selectRegistrationAttendeePlaceCoordinates,
  selectRegistrationAttendeeSearchLanguages,
  selectRegistrationAttendeeSearchLanguagesNotListed,
  selectRegistrationAttendeeTinyTagGroups,
  selectRegistrationAttendeeTinyTags,

  //Billing
  selectRegistrationBillingTaxData,
  selectRegistrationBillingTotalCost,
  selectRegistrationBillingSubtotalCost,
  setBillingTaxData,
  setBillingTotalCost,
  setBillingSubtotalCost,

  //Selections
  setTranslationRoomCodesOptIn,
  setAttendEventsOptIn,
  setSpeakerEventsOptIn,
  setOrganizeEventsOptIn,
  selectRegistrationSelectionsTranslationRoomCodesOptIn,
  selectRegistrationSelectionsAttendEventsOptIn,
  selectRegistrationSelectionsSpeakerEventsOptIn,
  selectRegistrationSelectionsOrganizeEventsOptIn,

  //Personal
  setPersonalContentLanguage,
  setPersonalCountryCode,
  setPersonalEmail,
  setPersonalFirstName,
  setPersonalLastName,
  setPersonalPassword,
  setPersonalPasswordConfirm,
  setPersonalPhoneNumber,
  setPersonalPrefix,
  setPersonalProfileImageAlt,
  setPersonalPronouns,
  setPersonalTimeZone,
  selectRegistrationPersonalContentLanguage,
  selectRegistrationPersonalCountryCode,
  selectRegistrationPersonalEmail,
  selectRegistrationPersonalFirstName,
  selectRegistrationPersonalLastName,
  selectRegistrationPersonalPassword,
  selectRegistrationPersonalPasswordConfirm,
  selectRegistrationPersonalPhoneNumber,
  selectRegistrationPersonalPrefix,
  selectRegistrationPersonalProfileImageAlt,
  selectRegistrationPersonalPronouns,
  selectRegistrationPersonalTimeZone,

  //TranslationRooms;
  setTranslationRoomsRoomCodes,
  selectRegistrationTranslationRoomsRoomCodes,

  //Email Preferences
  setEmailPreferencesMyndFullNewsletter,
  setEmailPreferencesPromotions,
  selectRegistrationEmailPreferencesMyndFullNewsletter,
  selectRegistrationEmailPreferencesPromotions,
} from './registrationSlice';

//Hooks
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useApiStatus } from '../../../context/ApiStatusProvider';

//Utils
import { axiosLimited } from '../../../axios/axios';
import convertBase64ToJPEG from '../../../utils/images/convertBase64ToJPEG';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { retryWrapper } from '../../../utils/logic/retryFns';

export const useRegistrationHandlers = () => {
  const dispatch = useDispatch();
  const { handleError, handleSuccess } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleSettingsSetLoginEmail } = useSettingsHandlers();

  //Generic
  const registrationAccessCode = useSelector(
    selectRegistrationGenericAccessCode
  );
  const registrationAccountType = useSelector(
    selectRegistrationGenericAccountType
  );
  const registrationGenericAccountVerificationURL = useSelector(
    selectRegistrationGenericAccountVerificationURL
  );
  const registrationPage = useSelector(
    selectRegistrationGenericRegistrationPage
  );
  const registrationPageStatus = useSelector(
    selectRegistrationGenericRegistrationPageStatus
  );
  const registrationPageIndex = useSelector(
    selectRegistrationGenericRegistrationPageIndex
  );
  const registrationIsLoadingCompleteRegistration = useSelector(
    selectRegistrationGenericIsLoadingCompleteRegistration
  );
  const registrationIsSuccess = useSelector(selectRegistrationGenericIsSuccess);

  const registrationComponentArr = useSelector(
    selectRegistrationGenericComponentArr
  );
  const registrationResendVerificationEmailIsLoading = useSelector(
    selectRegistrationGenericResendVerificationEmailIsLoading
  );
  const registrationResendVerificationEmailLimit = useSelector(
    selectRegistrationGenericResendVerificationEmailLimit
  );
  const registrationUnverifiedAccountId = useSelector(
    selectRegistrationGenericUnverifiedAccountId
  );

  //selections
  const registrationAttendEventsOptIn = useSelector(
    selectRegistrationSelectionsAttendEventsOptIn
  );
  const registrationSpeakerEventsOptIn = useSelector(
    selectRegistrationSelectionsSpeakerEventsOptIn
  );
  const registrationOrganizeEventsOptIn = useSelector(
    selectRegistrationSelectionsOrganizeEventsOptIn
  );
  const registrationTranslationRoomCodesOptIn = useSelector(
    selectRegistrationSelectionsTranslationRoomCodesOptIn
  );

  //Attendee
  const registrationAttendeeBigTags = useSelector(
    selectRegistrationAttendeeBigTags
  );
  const registrationAttendeeCoords = useSelector(
    selectRegistrationAttendeeCoords
  );
  const registrationAttendeeDistanceFilter = useSelector(
    selectRegistrationAttendeeDistanceFilter
  );
  const registrationAttendeeDistanceMeasurement = useSelector(
    selectRegistrationAttendeeDistanceMeasurement
  );
  const registrationAttendeeEventSearchOnlineCountries = useSelector(
    selectRegistrationAttendeeEventSearchOnlineCountries
  );
  const registrationAttendeePlaceCoordinates = useSelector(
    selectRegistrationAttendeePlaceCoordinates
  );
  const registrationAttendeeSearchLanguages = useSelector(
    selectRegistrationAttendeeSearchLanguages
  );
  const registrationAttendeeSearchLanguagesNotListed = useSelector(
    selectRegistrationAttendeeSearchLanguagesNotListed
  );

  const registrationAttendeeTinyTagGroups = useSelector(
    selectRegistrationAttendeeTinyTagGroups
  );
  const registrationAttendeeTinyTags = useSelector(
    selectRegistrationAttendeeTinyTags
  );

  //Billing
  const registrationBillingTaxData = useSelector(
    selectRegistrationBillingTaxData
  );
  const registrationBillingTotalCost = useSelector(
    selectRegistrationBillingTotalCost
  );
  const registrationBillingSubtotalCost = useSelector(
    selectRegistrationBillingSubtotalCost
  );

  //Personal
  const registrationPersonalContentLanguage = useSelector(
    selectRegistrationPersonalContentLanguage
  );
  const registrationPersonalCountryCode = useSelector(
    selectRegistrationPersonalCountryCode
  );
  const registrationPersonalEmail = useSelector(
    selectRegistrationPersonalEmail
  );
  const registrationPersonalFirstName = useSelector(
    selectRegistrationPersonalFirstName
  );
  const registrationPersonalLastName = useSelector(
    selectRegistrationPersonalLastName
  );
  const registrationPersonalPassword = useSelector(
    selectRegistrationPersonalPassword
  );
  const registrationPersonalPasswordConfirm = useSelector(
    selectRegistrationPersonalPasswordConfirm
  );
  const registrationPersonalPhoneNumber = useSelector(
    selectRegistrationPersonalPhoneNumber
  );
  const registrationPersonalPrefix = useSelector(
    selectRegistrationPersonalPrefix
  );
  const registrationPersonalProfileImageAlt = useSelector(
    selectRegistrationPersonalProfileImageAlt
  );
  const registrationPersonalPronouns = useSelector(
    selectRegistrationPersonalPronouns
  );
  const registrationPersonalTimeZone = useSelector(
    selectRegistrationPersonalTimeZone
  );

  //Translation Rooms
  const registrationTranslationRoomsRoomCodes = useSelector(
    selectRegistrationTranslationRoomsRoomCodes
  );

  //Email Preferences
  const registrationEmailPreferencesMyndFullNewsletter = useSelector(
    selectRegistrationEmailPreferencesMyndFullNewsletter
  );

  const registrationEmailPreferencesPromotions = useSelector(
    selectRegistrationEmailPreferencesPromotions
  );

  //////HANDLERS
  function handleRegistrationResetRegistration() {
    dispatch(resetRegistration());
  }

  function handleRegistrationSoftResetRegistration() {
    dispatch(softResetRegistration());
  }

  //GENERIC
  function handleRegistrationSetGenericAccessCode(str) {
    dispatch(setAccessCode(str));
  }
  function handleRegistrationSetGenericAccountType(str) {
    dispatch(setAccountType(str));
  }
  function handleRegistrationSetGenericAccountVerificationURL(str) {
    dispatch(setAccountVerificationURL(str));
  }
  function handleRegistrationSetGenericRegistrationPage(num) {
    dispatch(setRegistrationPage(num));
  }
  function handleRegistrationSetGenericRegistrationPageStatus(arr) {
    dispatch(setRegistrationPageStatus(arr));
  }
  function handleRegistrationSetGenericRegistrationPageIndex(num) {
    dispatch(setRegistrationPageIndex(num));
  }

  function handleRegistrationSetGenericIsLoadingCompleteRegistration(boolean) {
    dispatch(setRegistrationIsLoadingCompleteRegistration(boolean));
  }
  function handleRegistrationSetIsSuccess(boolean) {
    dispatch(setIsSuccess(boolean));
  }

  function handleRegistrationSetComponentArr(arr) {
    dispatch(setRegistrationComponentArr(arr));
  }

  function handleRegistrationSetUnverifiedAccountId(str) {
    dispatch(setUnverifiedAccountId(str));
  }

  function handleRegistrationStatusInitialization() {
    //set up process
    let newRegistrationStatus = ['incomplete', 'incomplete'];

    if (registrationTranslationRoomCodesOptIn) {
      newRegistrationStatus = [
        ...newRegistrationStatus,
        'incomplete',
        'incomplete',
      ];
    } // translation room code/ billing/ billing review

    registrationAttendEventsOptIn && newRegistrationStatus.push('incomplete');
    registrationSpeakerEventsOptIn && newRegistrationStatus.push('incomplete');
    registrationOrganizeEventsOptIn && newRegistrationStatus.push('incomplete');
    handleRegistrationSetGenericRegistrationPageStatus(newRegistrationStatus);
  }

  function handleRegistrationPageCompletionStatus(
    currentRegistrationPage,
    completionStatusBoolean
  ) {
    if (registrationPage !== 2) {
      let newRegistrationPageStatus = registrationPageStatus?.map(
        (status, index) => {
          if (index + 3 === currentRegistrationPage) {
            if (completionStatusBoolean) return 'complete';
            if (!completionStatusBoolean) return 'incomplete';
          } else {
            return status;
          }
        }
      );
      handleRegistrationSetGenericRegistrationPageStatus(
        newRegistrationPageStatus
      );
    }
  }

  function handleRegistrationPageIncrease() {
    //initialize
    if (registrationPage === 2) {
      handleRegistrationStatusInitialization();
    }

    handleRegistrationSetGenericRegistrationPageIndex(
      registrationPageIndex + 1
    );
    handleRegistrationSetGenericRegistrationPage(registrationPage + 1);
  }

  function handleRegistrationPageDecrease() {
    handleRegistrationSetGenericRegistrationPageIndex(
      registrationPageIndex - 1
    );
    handleRegistrationSetGenericRegistrationPage(registrationPage - 1);
  }

  function handleRegistrationSetResendVerificationEmailIsLoading(boolean) {
    dispatch(setResendVerificationEmailIsLoading(boolean));
  }

  function handleRegistrationSetResendVerificationEmailLimit(boolean) {
    dispatch(setResendVerificationEmailLimit(boolean));
  }

  //SELECTIONS
  function handleRegistrationSetSelectionsTranslationRoomCodesOptIn(boolean) {
    dispatch(setTranslationRoomCodesOptIn(boolean));
    // if false, will reset translation rooms in slice
  }

  function handleRegistrationSetSelectionsAttendEventsOptIn(boolean) {
    dispatch(setAttendEventsOptIn(boolean));
    // if false, will reset search options in slice
  }

  function handleRegistrationSetSelectionsSpeakerEventsOptIn(boolean) {
    dispatch(setSpeakerEventsOptIn(boolean));
  }
  function handleRegistrationSetSelectionsOrganizeEventsOptIn(boolean) {
    dispatch(setOrganizeEventsOptIn(boolean));
  }

  //Personal
  function handleRegistrationSetPersonalContentLanguage(str) {
    dispatch(setPersonalContentLanguage(str));
  }
  function handleRegistrationSetPersonalCountryCode(str) {
    dispatch(setPersonalCountryCode(str));
  }
  function handleRegistrationSetPersonalEmail(str) {
    dispatch(setPersonalEmail(str));
  }
  function handleRegistrationSetPersonalFirstName(str) {
    dispatch(setPersonalFirstName(str));
  }
  function handleRegistrationSetPersonalLastName(str) {
    dispatch(setPersonalLastName(str));
  }
  function handleRegistrationSetPersonalPassword(str) {
    dispatch(setPersonalPassword(str));
  }
  function handleRegistrationSetPersonalPasswordConfirm(str) {
    dispatch(setPersonalPasswordConfirm(str));
  }
  function handleRegistrationSetPersonalPhoneNumber(str) {
    dispatch(setPersonalPhoneNumber(str));
  }
  function handleRegistrationSetPersonalPrefix(str) {
    dispatch(setPersonalPrefix(str));
  }
  function handleRegistrationSetPersonalProfileImageAlt(str) {
    dispatch(setPersonalProfileImageAlt(str));
  }
  function handleRegistrationSetPersonalPronouns(str) {
    dispatch(setPersonalPronouns(str));
  }
  function handleRegistrationSetPersonalTimeZone(str) {
    dispatch(setPersonalTimeZone(str));
  }

  //Attendee
  function handleRegistrationSetAttendeeBigTags(arr) {
    dispatch(setAttendeeBigTags(arr));
  }

  function handleRegistrationSetAttendeeCoords(str) {
    dispatch(setAttendeeCoords(str));
  }
  function handleRegistrationSetAttendeeDistanceFilter(num) {
    dispatch(setAttendeeDistanceFilter(num));
  }
  function handleRegistrationSetAttendeeDistanceMeasurement(str) {
    dispatch(setAttendeeDistanceMeasurement(str));
  }
  function handleRegistrationSetAttendeeEventSearchOnlineCountries(arr) {
    dispatch(setAttendeeEventSearchOnlineCountries(arr));
  }
  function handleRegistrationSetAttendeePlaceCoordinates(obj) {
    dispatch(setAttendeePlaceCoordinates(obj));
  }
  function handleRegistrationSetAttendeeSearchLanguages(arr) {
    if (arr?.includes('notListed')) {
      if (registrationAttendeeSearchLanguagesNotListed?.length === 0) {
        handleRegistrationSetAttendeeSearchLanguagesNotListed([
          {
            id: uuidv4(),
            language: '',
          },
        ]);
      }
    } else {
      handleRegistrationSetAttendeeSearchLanguagesNotListed([]);
    }

    dispatch(setAttendeeSearchLanguages(arr));
  }
  function handleRegistrationSetAttendeeSearchLanguagesNotListed(arr) {
    dispatch(setAttendeeSearchLanguagesNotListed(arr));
  }
  function handleRegistrationSetAttendeeTinyTagGroups(arr) {
    dispatch(setAttendeeTinyTagGroups(arr));
  }
  function handleRegistrationSetAttendeeTinyTags(arr) {
    dispatch(setAttendeeTinyTags(arr));
  }

  //Billing
  function handleRegistrationSetBillingTaxData(arr) {
    dispatch(setBillingTaxData(arr));
  }
  function handleRegistrationSetBillingTotalCost(num) {
    dispatch(setBillingTotalCost(num));
  }
  function handleRegistrationSetBillingSubtotalCost(arr) {
    dispatch(setBillingSubtotalCost(arr));
  }

  //TranslationRooms
  function handleRegistrationSetTranslationRoomsRoomCodes(arr) {
    dispatch(setTranslationRoomsRoomCodes(arr));
  }

  //Email Preferences
  function handleRegistrationSetEmailPreferencesMyndFullNewsletter(boolean) {
    dispatch(setEmailPreferencesMyndFullNewsletter(boolean));
  }
  function handleRegistrationSetEmailPreferencesPromotions(boolean) {
    dispatch(setEmailPreferencesPromotions(boolean));
  }

  //API
  async function handleCreateMyndFullAccountPersonal(
    billingRequired,
    croppedImg
  ) {
    const recaptchaVerified = await handleReCaptchaVerify('REGISTER', false);

    if (recaptchaVerified !== true) return false;

    if (!registrationIsLoadingCompleteRegistration) {
      handleRegistrationSetGenericIsLoadingCompleteRegistration(true);

      // handle create subscription
      let formData = new FormData();

      //prelaunch
      if (registrationAccessCode) {
        formData.append('accessCode', registrationAccessCode);
      }

      //App Settings
      formData.append('contentLanguage', registrationPersonalContentLanguage);
      formData.append('timeZone', registrationPersonalTimeZone);

      //Personal Data
      registrationPersonalPrefix &&
        formData.append('prefix', registrationPersonalPrefix);
      registrationPersonalPronouns &&
        formData.append('pronouns', registrationPersonalPronouns);
      formData.append('firstName', registrationPersonalFirstName);
      formData.append('lastName', registrationPersonalLastName);
      formData.append('password', registrationPersonalPassword);
      formData.append('email', registrationPersonalEmail);
      if (registrationPersonalPhoneNumber && registrationPersonalCountryCode) {
        formData.append('phoneNumber', registrationPersonalPhoneNumber);
        formData.append('countryCode', registrationPersonalCountryCode);
      }

      const profileImage = croppedImg;
      let profileImgJPG;

      if (profileImage.croppedImage.croppedImage) {
        profileImgJPG = await convertBase64ToJPEG(
          profileImage.croppedImage.croppedImage,
          'profileImage.jpg'
        );
      }
      if (profileImgJPG?.size < 2097152 || !profileImgJPG) {
        formData.append('profileImage', profileImgJPG);
        formData.append('profileImageAlt', registrationPersonalProfileImageAlt);
      }

      //Translation room data
      if (registrationTranslationRoomsRoomCodes?.length > 0) {
        formData.append(
          'registrationTranslationRoomsRoomCodes',
          registrationTranslationRoomsRoomCodes
        );
      }

      //Attendee Data
      if (registrationAttendEventsOptIn) {
        formData.append(
          'registrationAttendEventsOptIn',
          registrationAttendEventsOptIn
        );
        formData.append('bigTags', registrationAttendeeBigTags);
        formData.append('geoPosition_lat', registrationAttendeeCoords[0]);
        formData.append('geoPosition_lng', registrationAttendeeCoords[1]);

        let distanceFilterInMeters;
        if (registrationAttendeeDistanceMeasurement === 'km') {
          distanceFilterInMeters = registrationAttendeeDistanceFilter * 1000;
        } else {
          distanceFilterInMeters = parseInt(
            registrationAttendeeDistanceFilter * 1609.344
          );
        }
        formData.append('distanceFilter', distanceFilterInMeters);

        formData.append('searchLanguages', registrationAttendeeSearchLanguages);

        if (registrationAttendeeSearchLanguagesNotListed?.length > 0) {
          formData.append(
            'searchLanguagesNotListed',
            JSON.stringify(registrationAttendeeSearchLanguagesNotListed)
          );
        }

        formData.append('tinyTags', registrationAttendeeTinyTags);
        formData.append(
          'distanceMeasurement',
          registrationAttendeeDistanceMeasurement
        );

        if (registrationAttendeeEventSearchOnlineCountries?.length > 0) {
          formData.append(
            'eventSearchOnlineCountries',
            registrationAttendeeEventSearchOnlineCountries
          );
        }
        if (registrationAttendeeTinyTagGroups?.length > 0) {
          const tinyTagGroupsString = JSON.stringify(
            registrationAttendeeTinyTagGroups
          );
          formData.append('tinyTagGroups', tinyTagGroupsString);
        }
      }

      //Speaker
      if (registrationSpeakerEventsOptIn) {
        formData.append('speakerEventsOptIn', registrationSpeakerEventsOptIn);
      }

      //Organizer
      if (registrationOrganizeEventsOptIn) {
        formData.append('organizeEventsOptIn', registrationOrganizeEventsOptIn);
      }

      //Email Preferences
      if (registrationEmailPreferencesMyndFullNewsletter) {
        formData.append(
          'emailPreferencesMyndFullNewsletter',
          registrationEmailPreferencesMyndFullNewsletter
        );
      }

      if (registrationEmailPreferencesPromotions) {
        formData.append(
          'emailPreferencesPromotions',
          registrationEmailPreferencesPromotions
        );
      }

      formData.append('billingRequired', billingRequired);
      try {
        const createUnverifiedAccountResponse = await axiosLimited.post(
          '/main/register/personal',
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }
        );

        if (billingRequired) {
          //Continue registration for Billing
          handleRegistrationSetUnverifiedAccountId(
            createUnverifiedAccountResponse?.data?.unverifiedAccountId
          ); //set in case needed for redirect
          handleRegistrationSetGenericAccountVerificationURL(
            createUnverifiedAccountResponse?.data?.accountVerificationURL
          );

          return {
            unverifiedAccountId:
              createUnverifiedAccountResponse?.data?.unverifiedAccountId,
            accountVerificationURL:
              createUnverifiedAccountResponse?.data?.accountVerificationURL,
          };
        } else {
          //cleanup
          handleSettingsSetLoginEmail(registrationPersonalEmail);
          handleRegistrationSetGenericIsLoadingCompleteRegistration(false);
          handleRegistrationSetIsSuccess(true);
          //Note: completion page will reset registration slice, which comes after retaining email to use on the page
        }
      } catch (error) {
        throw error;
      }
    }
  }

  async function handleAddPaymentMethodIdToUnverifiedAccount({
    unverifiedAccountId,
    paymentMethodId,
  }) {
    try {
      async function addPaymentMethod() {
        await axiosLimited.patch(
          '/main/register/personal/addPaymentMethod',
          { unverifiedAccountId, paymentMethodId },
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );
      }
      await retryWrapper(addPaymentMethod);
    } catch (error) {
      throw error;
    }
  }

  async function handleSendWelcomeEmail(
    unverifiedAccountId,
    accountVerificationURL
  ) {
    try {
      const response = await axiosLimited.post(
        '/main/register/personal/sendWelcomeEmail',
        { unverifiedAccountId, accountVerificationURL },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );

      if (response?.data?.status === 'success') {
        //Note: completion page will reset registration slice, which comes after retaining email to use on the page
        handleSettingsSetLoginEmail(registrationPersonalEmail);
        handleRegistrationSetGenericIsLoadingCompleteRegistration(false);
        handleRegistrationSetIsSuccess(true);
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleRemoveUnverifiedAccount(
    unverifiedAccountId,
    removeImage
  ) {
    try {
      async function removeUnverifiedAccount() {
        if (removeImage) {
          await axiosLimited.delete(
            '/main/register/personal/removeAccountAndImage',
            {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
              data: { unverifiedAccountId },
            }
          );
        } else {
          await axiosLimited.delete('/main/register/personal/removeAccount', {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
            data: { unverifiedAccountId },
          });
        }
      }
      await retryWrapper(removeUnverifiedAccount);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log(
          'DEV: useRegistrationHandlers/handleRemoveUnverifiedAccount',
          error
        );
      }
    }
  }

  async function handleCompleteRegistrationInstitutional() {}

  async function resendVerifyAccountEmailToken() {
    try {
      const recaptchaVerified = await handleReCaptchaVerify(
        'REGISTER_RESEND_VERIFICATION_EMAIL',
        false
      );

      if (recaptchaVerified !== true) return false;

      if (!registrationResendVerificationEmailIsLoading) {
        if (registrationResendVerificationEmailLimit >= 10) {
          return handleError({
            message: 'pleaseContactSupport',
            id: Date.now(),
            origin: 'PersonalRegistrationMain.js/resendVerifyAccountEmailToken',
          });
        }

        setResendVerificationEmailLimit((prev) => prev + 1);
        setResendVerificationEmailIsLoading(true);

        if (registrationAccountType === 'personal') {
          await axiosLimited.patch(
            '/api/main/auth/resendVerifyAccountEmailToken',
            {
              email: registrationPersonalEmail,
            }
          );
        }

        handleSuccess({
          message: 'emailSent',
          id: Date.now(),
          origin: 'PersonalRegistrationMain.js/resendVerifyAccountEmailToken',
        });

        setResendVerificationEmailIsLoading(false);
      }
    } catch (error) {
      setResendVerificationEmailIsLoading(false);
      handleError({
        ...error,
        id: Date.now(),
        origin: 'PersonalRegistrationMain.js/resendVerifyAccountEmailToken',
      });
    }
  }

  async function roomCodeRegistrationSearch(roomCode) {
    const recaptchaVerified = await handleReCaptchaVerify(
      'ROOM_CODE_REGISTRATION',
      false
    );
    if (recaptchaVerified !== true) return false;

    try {
      const roomCodeRegistrationSearchResponse = await axiosLimited.get(
        `/main/register/roomCode/${roomCode}`
      );

      const roomCodeExists =
        roomCodeRegistrationSearchResponse?.data?.roomCodeExists;

      return roomCodeExists;
    } catch (error) {
      handleError({
        ...error,
        id: Date.now(),
        origin: 'PersonalRegistrationMain.js/roomCodeRegistrationSearch',
      });
    }
  }

  return {
    handleRegistrationResetRegistration,
    handleRegistrationSoftResetRegistration,
    handleCreateMyndFullAccountPersonal,
    handleCompleteRegistrationInstitutional,
    resendVerifyAccountEmailToken,
    handleAddPaymentMethodIdToUnverifiedAccount,
    handleSendWelcomeEmail,
    handleRemoveUnverifiedAccount,

    //Generic
    registrationAccessCode,
    registrationAccountType,
    registrationGenericAccountVerificationURL,
    registrationPage,
    registrationPageStatus,
    registrationPageIndex,
    registrationIsLoadingCompleteRegistration,
    registrationIsSuccess,
    registrationComponentArr,
    registrationResendVerificationEmailIsLoading,
    registrationResendVerificationEmailLimit,
    registrationUnverifiedAccountId,
    handleRegistrationSetGenericAccessCode,
    handleRegistrationSetGenericAccountType,
    handleRegistrationSetGenericAccountVerificationURL,
    handleRegistrationSetGenericRegistrationPage,
    handleRegistrationSetGenericRegistrationPageStatus,
    handleRegistrationSetGenericRegistrationPageIndex,
    handleRegistrationSetGenericIsLoadingCompleteRegistration,
    handleRegistrationPageIncrease,
    handleRegistrationPageDecrease,
    handleRegistrationSetIsSuccess,
    handleRegistrationPageCompletionStatus,
    handleRegistrationSetResendVerificationEmailIsLoading,
    handleRegistrationSetResendVerificationEmailLimit,
    handleRegistrationSetComponentArr,
    handleRegistrationSetUnverifiedAccountId,

    //Attendee
    registrationAttendeeBigTags,
    registrationAttendeeCoords,
    registrationAttendeeDistanceFilter,
    registrationAttendeeDistanceMeasurement,
    registrationAttendeeEventSearchOnlineCountries,
    registrationAttendeePlaceCoordinates,
    registrationAttendeeSearchLanguages,
    registrationAttendeeSearchLanguagesNotListed,
    registrationAttendeeTinyTagGroups,
    registrationAttendeeTinyTags,
    handleRegistrationSetAttendeeBigTags,
    handleRegistrationSetAttendeeCoords,
    handleRegistrationSetAttendeeDistanceFilter,
    handleRegistrationSetAttendeeDistanceMeasurement,
    handleRegistrationSetAttendeeEventSearchOnlineCountries,
    handleRegistrationSetAttendeePlaceCoordinates,
    handleRegistrationSetAttendeeSearchLanguages,
    handleRegistrationSetAttendeeSearchLanguagesNotListed,
    handleRegistrationSetAttendeeTinyTagGroups,
    handleRegistrationSetAttendeeTinyTags,

    //Billing
    registrationBillingTaxData,
    registrationBillingTotalCost,
    registrationBillingSubtotalCost,
    handleRegistrationSetBillingTaxData,
    handleRegistrationSetBillingTotalCost,
    handleRegistrationSetBillingSubtotalCost,

    //Selections
    registrationTranslationRoomCodesOptIn,
    registrationAttendEventsOptIn,
    registrationSpeakerEventsOptIn,
    registrationOrganizeEventsOptIn,
    handleRegistrationSetSelectionsTranslationRoomCodesOptIn,
    handleRegistrationSetSelectionsAttendEventsOptIn,
    handleRegistrationSetSelectionsSpeakerEventsOptIn,
    handleRegistrationSetSelectionsOrganizeEventsOptIn,
    handleRegistrationStatusInitialization,

    //Personal
    registrationPersonalContentLanguage,
    registrationPersonalCountryCode,
    registrationPersonalEmail,
    registrationPersonalFirstName,
    registrationPersonalLastName,
    registrationPersonalPassword,
    registrationPersonalPasswordConfirm,
    registrationPersonalPhoneNumber,
    registrationPersonalPrefix,
    registrationPersonalProfileImageAlt,
    registrationPersonalPronouns,
    registrationPersonalTimeZone,
    handleRegistrationSetPersonalContentLanguage,
    handleRegistrationSetPersonalCountryCode,
    handleRegistrationSetPersonalEmail,
    handleRegistrationSetPersonalFirstName,
    handleRegistrationSetPersonalLastName,
    handleRegistrationSetPersonalPassword,
    handleRegistrationSetPersonalPasswordConfirm,
    handleRegistrationSetPersonalPhoneNumber,
    handleRegistrationSetPersonalPrefix,
    handleRegistrationSetPersonalProfileImageAlt,
    handleRegistrationSetPersonalPronouns,
    handleRegistrationSetPersonalTimeZone,

    //TranslationRooms
    registrationTranslationRoomsRoomCodes,
    handleRegistrationSetTranslationRoomsRoomCodes,
    roomCodeRegistrationSearch,

    //Email Preferences
    registrationEmailPreferencesMyndFullNewsletter,
    registrationEmailPreferencesPromotions,
    handleRegistrationSetEmailPreferencesMyndFullNewsletter,
    handleRegistrationSetEmailPreferencesPromotions,
  };
};

//hooks
import { useEffect, useState } from 'react';
import useLanguageAdmin from '../../../../../language/features/useLanguageAdmin';
import useLanguageData from '../../../../../language/useLanguageData';

//components
//utils
import { getRandomColor } from '../../../../../utils/UI/randomColorCodes';
import { dropdown_arrow, flag } from '../../../../../assets/icons';

const InfoCardContainerTickets = ({ ticketData }) => {
  //hooks
  const { ReportEventOpts } = useLanguageData();
  const { AdminStatusEl } = useLanguageAdmin();
  //state
  const [openTickets, setOpenTickets] = useState(false);
  const [openTicketFlag, setOpenTicketFlag] = useState(false);
  //ui

  const [handleColors, setHandleColors] = useState([]);

  //initialize
  useEffect(() => {
    let colorArr = [];
    ticketData?.forEach(() => colorArr.push(getRandomColor()));
    setHandleColors(colorArr);
  }, [ticketData]);

  useEffect(() => {
    if (ticketData) {
      const openTicketCheck = ticketData?.map(
        (ticket) => ticket?.ticketData?.status === 'open'
      );
      if (openTicketCheck?.includes(true)) {
        setOpenTicketFlag(true);
      }
    }
  }, [ticketData]);
  return (
    <div className="support-ticket__container">
      <div className="flex-row align-center">
        <p className="fs21 fwsb mrg-b6">Tickets ({ticketData?.length})</p>
        {ticketData?.length > 0 && (
          <>
            <button
              className="flex-center pad-tb12 pad-lr12 mrg-l12 br--standard highlight-btreg-theme highlight-i-gt"
              style={{ height: '24px' }}
              onClick={() => {
                setOpenTickets(!openTickets);
              }}
            >
              <img
                src={dropdown_arrow}
                alt="arrow"
                style={{ height: '10px' }}
                className={`${openTickets === false ? 'reverse-vertical' : ''}`}
              />
            </button>
            {openTicketFlag && (
              <img
                src={flag}
                alt=""
                style={{ height: '18px' }}
                className="filter-red mrg-l12"
                title={'Open Ticket'}
              />
            )}
          </>
        )}
      </div>
      {openTickets && (
        <ul className="info-card-list">
          {ticketData
            ?.map((ticket, index) => {
              return (
                <li key={`tickets-${ticket.meta?.ticketReference}-${index}`}>
                  <div
                    className="list-handle"
                    style={{ backgroundColor: handleColors[index] }}
                  />
                  <p>
                    Ticket Reference: [{ticket.meta?.ticketReference}] &nbsp;
                  </p>
                  {ReportEventOpts[`${ticket?.ticketData?.ticketKey}`]}
                  <span className="flex-row">
                    {AdminStatusEl(ticket?.ticketData?.status)}

                    {ticket?.support?.current &&
                    ticket?.ticketData?.status !== 'resolved' ? (
                      <p> &nbsp;/&nbsp;Assigned</p>
                    ) : ticket?.ticketData?.status &&
                      ticket?.ticketData?.status !== 'resolved' ? (
                      <p> &nbsp;/&nbsp;Not Assigned</p>
                    ) : null}
                  </span>
                </li>
              );
            })
            .reverse()}
        </ul>
      )}
    </div>
  );
};

export default InfoCardContainerTickets;

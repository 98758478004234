//components
import EventsNextSearchBtn from './EventsNextSearchBtn';

const NoMoreCard = ({ fadeInAnimation }) => {
  return (
    <div className={'ec__ghost flex-center flex-column space-evenly'}>
      <EventsNextSearchBtn fadeInAnimation={fadeInAnimation} />
    </div>
  );
};

export default NoMoreCard;

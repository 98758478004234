import { useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

const ButtonBlock = ({
  icon,
  btnText,
  handler,
  customWrapper,
  iconAlt,
  tapHighlight,
  iconCustomClass,
  iconCustomStyle,
  iconAltPosition,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //ui
  const [tapHighlightBtn, setTapHighlightBtn] = useState(false);
  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <button
      className={`block-btn access-ob ${customWrapper ? customWrapper : ''} ${
        tapHighlightBtn && tapHighlight ? tapHighlight : ''
      }`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleMobileTap(
          [() => setTapHighlightBtn(true), () => setTapHighlightBtn(false)],
          [() => handler()]
        );
      }}
    >
      {iconAltPosition && icon && (
        <img
          src={icon}
          alt={iconAlt ? iconAlt : IconAlts.icon}
          className={`svg ${iconCustomClass ? iconCustomClass : ''}`}
          style={iconCustomStyle ? iconCustomStyle : null}
        />
      )}
      {btnText && <p>{btnText}</p>}
      {!iconAltPosition && icon && (
        <img
          src={icon}
          alt={iconAlt ? iconAlt : IconAlts.icon}
          className={`svg ${iconCustomClass ? iconCustomClass : ''}`}
          style={iconCustomStyle ? iconCustomStyle : null}
        />
      )}
    </button>
  );
};

export default ButtonBlock;

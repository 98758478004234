import { useEffect, useRef, useState } from 'react';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../language/useLanguageData';

//components
import ConfirmSelection from '../../../../components/ConfirmSelection/ConfirmSelection';
import RadialButtonList from '../../../../components/RadialButtonList/RadialButtonList';
import Spinner from '../../../../components/Spinner/Spinner';

//utils
import { warning } from '../../../../assets/icons';
import editEventConflictObjDefault from './editEventConflictObjDefault';

const EditEventConflictsAndSaveConfirmation = ({
  editEventConflictObj,
  setEditEventConflictObj,
  editEventConflictsResolved,
  setEditEventConflictsResolved,
  EditOverview,
  handleSaveBtnClick,
  handleSaveEditChanges,
  setConfirmSelectionBtn,
}) => {
  //state
  const [initialized, setInitialized] = useState(false);
  const [removingRegistrationsConflict, setRemovingRegistrationsConflict] =
    useState(false);
  const [
    removingInPersonLocationConflict,
    setRemovingInPersonLocationConflict,
  ] = useState();
  const [removingOnlineLocationConflict, setRemovingOnlineLocationConflict] =
    useState();

  //language
  const { IconAlts } = useLanguageComponents();
  const { EditEventConflicts } = useLanguageOrganize();
  const { EditEventConflictData } = useLanguageData();

  //variables
  const initializationTimer = useRef();

  useEffect(() => {
    if (initialized === false) {
      initializationTimer.current = setTimeout(() => {
        setInitialized(true);
      }, 1000);

      const conflictEntries = Object.entries(editEventConflictObj);

      let conflict;

      conflictEntries?.map((conflictArr) => {
        if (conflictArr[1].conflict === true) {
          conflict = true;
          if (conflictArr[0] === 'removingRegistrations') {
            setRemovingRegistrationsConflict(true);
          }
          if (conflictArr[0] === 'removingInPersonLocation') {
            setRemovingInPersonLocationConflict(true);
          }
          if (conflictArr[0] === 'removingOnlineLocation') {
            setRemovingOnlineLocationConflict(true);
          }
        }
      });

      if (!conflict) {
        setEditEventConflictsResolved(true);
      } else {
        setEditEventConflictsResolved(false);
      }

      return () => clearTimeout(initializationTimer.current);
    }
  }, [editEventConflictObj]); // create delay to ensure data flow;

  function handleSelection(resolutionSelection, resolutionId) {
    if (initialized) {
      let newObj = { ...editEventConflictObj };
      if (newObj[resolutionId]) {
        newObj[resolutionId] = {
          ...newObj[resolutionId],
          resolution: resolutionSelection,
        };
      }

      let allConflictsResolved = true;

      Object.entries(newObj)?.map((conflictArr) =>
        conflictArr[0] === true && !conflictArr[1].resolution
          ? (allConflictsResolved = false)
          : null
      );

      setEditEventConflictObj(newObj);
      setEditEventConflictsResolved(allConflictsResolved);
    }
  }

  function handleSave() {
    if (editEventConflictsResolved) {
      return handleSaveEditChanges();
    }
  }

  return (
    <>
      {initialized ? (
        <>
          {removingRegistrationsConflict ||
          removingInPersonLocationConflict ||
          removingOnlineLocationConflict ? (
            <>
              <div className="flex-row flex-column--ph align-center mrg-t36 mrg-t24--ph">
                <img
                  src={warning}
                  style={{ height: '38px' }}
                  className="mrg-r24 mrg-b24--ph"
                  alt={IconAlts.iconWarning}
                />
                <p
                  className="banners__notice fs18 access-ob access-o6"
                  tabIndex="0"
                >
                  {EditEventConflicts.conflictNotice}
                </p>
              </div>

              {removingRegistrationsConflict && (
                <div key={`removingRegistrationsConflict`}>
                  <div className="mrg-b24 mrg-l36 mrg-l0--ph">
                    <p
                      className="mrg-t24 fs18 access-ob access-o6"
                      tabIndex="0"
                    >
                      {EditEventConflicts.removingRegistrationsConflict}
                    </p>

                    <RadialButtonList
                      id={'removingRegistrations'}
                      options={
                        EditEventConflictData.removingRegistrationsConflict
                      }
                      customWrapper={'mrg-t12 mrg-t24--ph mrg-l36'}
                      onChange={handleSelection}
                    />
                  </div>
                </div>
              )}

              {!removingRegistrationsConflict &&
                removingInPersonLocationConflict && (
                  <div key={`removingRegistrationsConflict`}>
                    <div className="mrg-b24 mrg-l36 mrg-l0--ph">
                      <p
                        className="mrg-t24 fs18 access-ob access-o6"
                        tabIndex="0"
                      >
                        {EditEventConflicts.removingInPersonLocationConflict}
                      </p>

                      <RadialButtonList
                        id={'removingInPersonLocation'}
                        options={
                          EditEventConflictData.removingInPersonLocationConflict
                        }
                        customWrapper={'mrg-t12 mrg-t24--ph mrg-l36'}
                        onChange={handleSelection}
                      />
                    </div>
                  </div>
                )}

              {!removingRegistrationsConflict &&
                removingOnlineLocationConflict && (
                  <div key={`removingRegistrationsConflict`}>
                    <div className="mrg-b24 mrg-l36 mrg-l0--ph">
                      <p
                        className="mrg-t24 fs18 access-ob access-o6"
                        tabIndex="0"
                      >
                        {EditEventConflicts.removingOnlineLocationConflict}
                      </p>

                      <RadialButtonList
                        id={'removingOnlineLocation'}
                        options={
                          EditEventConflictData.removingOnlineLocationConflict
                        }
                        customWrapper={'mrg-t12 mrg-t24--ph mrg-l36'}
                        onChange={handleSelection}
                      />
                    </div>
                  </div>
                )}

              <ConfirmSelection
                btn1Text={EditOverview.cancel}
                btn1Color={'neutral'}
                btn1Handler={handleSaveBtnClick}
                btn2Text={EditOverview.save}
                btn2Color={'pos'}
                btn2Handler={handleSave}
                ignoreMaxWidth={true}
                customWrapper={'rg-t36 mrg-t24--ph text-center'}
                btn2CustomWrapper={
                  editEventConflictsResolved ? '' : 'opacity-05 unavailable'
                }
              />
            </>
          ) : (
            <ConfirmSelection
              text={EditOverview.saveConfirmation}
              btn1Text={EditOverview.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => {
                setEditEventConflictObj(editEventConflictObjDefault);
                setConfirmSelectionBtn();
              }}
              btn2Text={EditOverview.save}
              btn2Color={'pos'}
              btn2Handler={handleSaveEditChanges}
              ignoreMaxWidth={true}
              customWrapper={'rg-t36 mrg-t36 mrg-t24--ph text-center'}
            />
          )}
        </>
      ) : (
        <div className="full-width mrg-t24">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default EditEventConflictsAndSaveConfirmation;

import { useEffect, useState } from 'react';

//Hooks
import { useEventHandlers } from '../../useEventHandlers';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//Utility
import {
  checkmark_green,
  clock,
  img_building3,
  img_virtual,
  return_curved_arrow,
} from '../../../../../assets/icons';
import { useSettings } from '../../../../../context/SettingsProvider';

const RegistrationButtons = ({
  eventData,
  setEventData,
  eventStatus,
  EventPage,
  handleMobileTap,
  organizerViewOn,
  participantPreviewOn,
  registrationActionBtnsDisplayOnly,
  handleReturn,
  setActionBtnsIsLoading,
  actionBtnsIsLoading,
  updateEventDataListPostRegistration,
}) => {
  //Hooks
  const { width } = useSettings();
  const { handleUserInPersonRegistration, handleUserVirtualRegistration } =
    useEventHandlers();
  const {
    accountId,
    accountRegistrationsInPerson,
    accountPendingApprovalInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalVirtual,
  } = useAccountReduxHandlers();

  //language
  const { IconAlts } = useLanguageComponents();

  //UI state
  const [inPersonStatusUI, setInPersonStatusUI] = useState();
  const [virtualStatusUI, setVirtualStatusUI] = useState();
  //
  const [tapHighlightReturn, setTapHighlightReturn] = useState(false);
  const [tapHighlightInPersonRegister, setTapHighlightInPersonRegister] =
    useState(false);
  const [tapHighlightVirtualRegister, setTapHighlightVirtualRegister] =
    useState(false);

  useEffect(() => {
    handleInPersonEventStatusUI();
    handleVirtualEventStatusUI();
    /*eslint-disable-next-line*/
  }, [
    eventData.registrationData,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  //Button UI In Person
  function handleInPersonEventStatusUI() {
    if (accountRegistrationsInPerson.includes(eventData._id)) {
      return setInPersonStatusUI('registered');
    }

    if (accountPendingApprovalInPerson.includes(eventData._id)) {
      return setInPersonStatusUI('pendingApproval');
    }

    if (
      eventData.registrationData?.registeredAttendeesInPerson?.length >=
        eventData.registrationData?.maxRegistrationsInPersonAmount &&
      eventData.registrationData.maxRegistrationsInPersonEnabled
    ) {
      return setInPersonStatusUI('full');
    }

    if (
      eventData.registrationData?.maxRegistrationsInPersonAmount / 2 <=
        eventData.registrationData?.registeredAttendeesInPerson?.length &&
      eventData.registrationData.maxRegistrationsInPersonEnabled
    ) {
      return setInPersonStatusUI('almostFull');
    }

    return setInPersonStatusUI('open');
  } //order of checks matters

  function handleVirtualEventStatusUI() {
    if (accountRegistrationsVirtual.includes(eventData._id)) {
      return setVirtualStatusUI('registered');
    }

    if (accountPendingApprovalVirtual.includes(eventData._id)) {
      return setVirtualStatusUI('pendingApproval');
    }

    if (
      eventData.registrationData?.registeredAttendeesVirtual?.length >=
        eventData.registrationData?.maxRegistrationsVirtualAmount &&
      eventData.registrationData.maxRegistrationsVirtualEnabled
    ) {
      return setVirtualStatusUI('full');
    }

    if (
      eventData.registrationData?.maxRegistrationsVirtualAmount / 2 <=
        eventData.registrationData?.registeredAttendeesVirtual?.length &&
      eventData.registrationData.maxRegistrationsVirtualEnabled
    ) {
      return setVirtualStatusUI('almostFull');
    }

    return setVirtualStatusUI('open');
  } //order of checks matters

  async function handleRegistration(registrationType) {
    if (actionBtnsIsLoading) return;
    if (registrationType === 'virtual') {
      handleUserVirtualRegistration(
        eventData,
        accountId,
        eventStatus,
        setActionBtnsIsLoading,
        setEventData,
        updateEventDataListPostRegistration
      );
    }
    if (registrationType === 'inPerson') {
      handleUserInPersonRegistration(
        eventData,
        accountId,
        eventStatus,
        setActionBtnsIsLoading,
        setEventData,
        updateEventDataListPostRegistration
      );
    }
  }

  function handleVirtualRegistrationBtn() {
    handleMobileTap(
      [
        () => setTapHighlightVirtualRegister(true),
        () => setTapHighlightVirtualRegister(false),
      ],
      [() => handleRegistration('virtual')]
    );
  }

  function handleInPersonRegistrationBtn() {
    handleMobileTap(
      [
        () => setTapHighlightInPersonRegister(true),
        () => setTapHighlightInPersonRegister(false),
      ],
      [() => handleRegistration('inPerson')]
    );
  }

  return (
    <div
      className={`ev__action-btns--registrations__wrapper ${
        registrationActionBtnsDisplayOnly && width > 480 ? 'pad-t12' : ''
      }
      ${!registrationActionBtnsDisplayOnly ? 'mrg-t24' : ''}
      `}
    >
      {registrationActionBtnsDisplayOnly && (
        <button
          className={`ev__action-btn highlight-i-theme
           ${
             tapHighlightReturn
               ? 'highlight-i-theme--tap click-scaledown-95'
               : ''
           } ${
            width <= 480 && registrationActionBtnsDisplayOnly ? 'mrg-b24' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightReturn(true),
                () => setTapHighlightReturn(false),
              ],
              [() => handleReturn()],
              true
            )
          }
          title={EventPage.return}
        >
          <img
            src={return_curved_arrow}
            alt={IconAlts.iconArrow}
            className="filter-lightgray"
            style={{ height: '28px' }}
          />
        </button>
      )}

      <div className="ev__action-btns--registrations__container ">
        {(eventData.hosting.hostingOption === 'hybrid' ||
          eventData.hosting.hostingOption === 'online') && (
          <div className="flex-column flex-center pos-rel">
            <button
              className={`ev__action-btn--large flex-column highlight-i-theme ${
                tapHighlightVirtualRegister ? ` click-scaledown-95` : ''
              } ${organizerViewOn || participantPreviewOn ? 'opacity-03' : ''}`}
              onClick={
                !organizerViewOn && !participantPreviewOn
                  ? handleVirtualRegistrationBtn
                  : null
              }
            >
              <div className="flex-row full-width">
                <img
                  className="filter-lightgray"
                  src={img_virtual}
                  alt={IconAlts.iconVirtual}
                />
                <div className="flex-column flex-center full-width">
                  <div className="flex-row">
                    {virtualStatusUI === 'open' && (
                      <div className="flex-column">
                        <p className="fs18">{EventPage.registration}</p>
                        <p className="fs14">{EventPage.open}</p>
                      </div>
                    )}
                    {virtualStatusUI === 'almostFull' && (
                      <div className="flex-column">
                        <p className="fs18">{EventPage.registration}</p>
                        <p className="fs14">{EventPage.almostFull}</p>
                      </div>
                    )}
                    {virtualStatusUI === 'full' && (
                      <div className="flex-column">
                        <p className="fs18">{EventPage.registration}</p>
                        <p className="fs14">{EventPage.full}</p>
                      </div>
                    )}
                    {virtualStatusUI === 'registered' && (
                      <div className="flex-row">
                        <p>{EventPage.registered}</p>
                        <img
                          src={checkmark_green}
                          alt={IconAlts.iconCheckmark}
                          className="checkmark"
                          style={{ height: '16px' }}
                        />
                      </div>
                    )}
                    {virtualStatusUI === 'pendingApproval' && (
                      <>
                        <p>{EventPage.pendingApproval}</p>
                        <img
                          src={clock}
                          alt={IconAlts.iconClock}
                          className="clock"
                          style={{ height: '16px' }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </button>
            <div
              className="flex-row fs14"
              style={{
                position: 'absolute',
                bottom: '-24px',
                right: '0px',
                left: '0px',
              }}
            >
              {virtualStatusUI === 'pendingApproval' &&
              inPersonStatusUI === 'registered' ? (
                <p className="flex-center full-width mrg-t6">
                  {EventPage.clickToCancelSwitch}
                </p>
              ) : (
                (virtualStatusUI === 'pendingApproval' ||
                  virtualStatusUI === 'registered') && (
                  <p className="flex-center full-width mrg-t6">
                    {EventPage.clickToUnregister}
                  </p>
                )
              )}

              {virtualStatusUI === 'full' && (
                <p className="flex-center full-width mrg-t6">
                  {EventPage.joinWaitingList}
                </p>
              )}

              {(inPersonStatusUI === 'pendingApproval' ||
                inPersonStatusUI === 'registered') &&
                virtualStatusUI !== 'full' &&
                virtualStatusUI !== 'pendingApproval' &&
                virtualStatusUI !== 'registered' && (
                  <p className="flex-center full-width mrg-t6">
                    {EventPage.clickToSwitch}
                  </p>
                )}

              {(virtualStatusUI === 'open' ||
                virtualStatusUI === 'almostFull') &&
                inPersonStatusUI !== 'pendingApproval' &&
                inPersonStatusUI !== 'registered' && (
                  <p className="flex-center full-width mrg-t6">
                    {EventPage.clickToRegister}
                  </p>
                )}
            </div>
          </div>
        )}

        {(eventData.hosting.hostingOption === 'hybrid' ||
          eventData.hosting.hostingOption === 'inPerson') && (
          <div
            className={`pos-rel flex-column flex-center ${
              width <= 800 && eventData.hosting.hostingOption === 'hybrid'
                ? 'mrg-t36'
                : ''
            }`}
          >
            <button
              className={`ev__action-btn--large flex-column highlight-i-theme ${
                tapHighlightInPersonRegister ? `click-scaledown-95` : ''
              } ${organizerViewOn || participantPreviewOn ? 'opacity-03' : ''}`}
              onClick={
                !organizerViewOn && !participantPreviewOn
                  ? handleInPersonRegistrationBtn
                  : null
              }
            >
              <div className="flex-row full-width ">
                <img
                  className="filter-lightgray"
                  src={img_building3}
                  alt={IconAlts.iconInPerson}
                />
                <div className="flex-column flex-center full-width">
                  <div className="flex-row">
                    {inPersonStatusUI === 'open' && (
                      <div className="flex-column">
                        <p className="fs18">{EventPage.registration}</p>
                        <p className="fs14">{EventPage.open}</p>
                      </div>
                    )}
                    {inPersonStatusUI === 'almostFull' && (
                      <div className="flex-column">
                        <p className="fs18">{EventPage.registration}</p>
                        <p className="fs14">{EventPage.almostFull}</p>
                      </div>
                    )}
                    {inPersonStatusUI === 'full' && (
                      <div className="flex-column">
                        <p className="fs18">{EventPage.registration}</p>
                        <p className="fs14">{EventPage.full}</p>
                      </div>
                    )}
                    {inPersonStatusUI === 'registered' && (
                      <div className="flex-row">
                        <p>{EventPage.registered}</p>
                        <img
                          src={checkmark_green}
                          alt={EventPage.checkmark}
                          className="checkmark"
                          style={{ height: '16px' }}
                        />
                      </div>
                    )}
                    {inPersonStatusUI === 'pendingApproval' && (
                      <>
                        <p>{EventPage.pendingApproval}</p>
                        <img
                          src={clock}
                          alt={IconAlts.iconClock}
                          className="clock"
                          style={{ height: '16px' }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </button>
            <div
              className="flex-row fs14"
              style={{
                position: 'absolute',
                bottom: '-24px',
                right: '0px',
                left: '0px',
              }}
            >
              {inPersonStatusUI === 'pendingApproval' &&
              virtualStatusUI === 'registered' ? (
                <p className="flex-center full-width mrg-t6">
                  {EventPage.clickToCancelSwitch}
                </p>
              ) : (
                (inPersonStatusUI === 'pendingApproval' ||
                  inPersonStatusUI === 'registered') && (
                  <p className="flex-center full-width mrg-t6">
                    {EventPage.clickToUnregister}
                  </p>
                )
              )}

              {inPersonStatusUI === 'full' && (
                <p className="flex-center full-width mrg-t6">
                  {EventPage.joinWaitingList}
                </p>
              )}

              {(virtualStatusUI === 'pendingApproval' ||
                virtualStatusUI === 'registered') &&
                inPersonStatusUI !== 'full' &&
                inPersonStatusUI !== 'pendingApproval' &&
                inPersonStatusUI !== 'registered' && (
                  <p className="flex-center full-width mrg-t6">
                    {EventPage.clickToSwitch}
                  </p>
                )}

              {(inPersonStatusUI === 'open' ||
                inPersonStatusUI === 'almostFull') &&
                virtualStatusUI !== 'pendingApproval' &&
                virtualStatusUI !== 'registered' && (
                  <p className="flex-center full-width mrg-t6">
                    {EventPage.clickToRegister}
                  </p>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RegistrationButtons;

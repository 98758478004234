//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

const EventsViewsSummaryMobile = ({ view }) => {
  //language
  const { SettingsEventsViewsSummaryMobile } = useLanguageSettings();

  let viewContent;

  if (view === 'myevents') {
    viewContent = (
      <p
        className="mrg-t24 flex-center fs18 color-gray fwsb opacity-06 access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsEventsViewsSummaryMobile.myEvents}
      >
        {SettingsEventsViewsSummaryMobile.myEvents}
      </p>
    );
  } else if (view === 'bigtags') {
    viewContent = (
      <p
        className="mrg-t24 flex-center full-width fs18 color-gray fwsb opacity-06 access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsEventsViewsSummaryMobile.bigTags}
      >
        {SettingsEventsViewsSummaryMobile.bigTags}
      </p>
    );
  } else if (view === 'tinytags') {
    viewContent = (
      <p
        className="mrg-t24 flex-center full-width fs18 color-gray fwsb opacity-06 access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsEventsViewsSummaryMobile.tinyTags}
      >
        {SettingsEventsViewsSummaryMobile.tinyTags}
      </p>
    );
  } else if (view === 'community') {
    viewContent = (
      <p
        className="mrg-t24 flex-center full-width fs18 color-gray fwsb opacity-06  access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsEventsViewsSummaryMobile.community}
      >
        {SettingsEventsViewsSummaryMobile.community}
      </p>
    );
  }

  return viewContent;
};

export default EventsViewsSummaryMobile;

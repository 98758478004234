//components
import DemoFilters from './DemoFilters';
import DemoSearchSummary from './DemoSearchSummary';
import DemoTagsList from './DemoTagsList';
import DemoTagsToggle from './DemoTagsToggle';
import DemoViews from './DemoViews';

const DemoSettingsDekstop = ({
  view,
  setView,
  setToolbarActiveHighlight,
  searchDirection,
  setSearchDirection,
  searchFormat,
  setSearchFormat,
  searchCategory,
  setSearchCategory,
  settingsListOpened,
  setSettingsListOpened,
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
  selectedMyEvents,
  selectedBigTags,
  selectedTinyTags,
  selectedTinyTagGroupIds,
  selectedFollowingIds,
  selectedFollowingGroupIds,
  width,
  selectAllBTBtn,
  removeSelectedBTs,
  addSelectedBTs,
  removeAllSelectedBTs,
  setSelectAllBTBtn,
  selectAllMyEventsBtn,
  setSelectedMyEvents,
  setSelectAllMyEventsBtn,
  selectAllTTBtn,
  removeSelectedTinyTags,
  addSelectedTinyTags,
  removeSelectedTinyTagGroupIds,
  addSelectedTinyTagGroupIds,
  removeAllSelectedTinyTagGroupIds,
  setSelectAllTTBtn,
  removeAllSelectedTinyTags,
  selectAllAccountsFollowingBtn,
  setSelectAllAccountsFollowingBtn,
  removeAllSelectedAccountsFollowingIds,
  removeSelectedAccountsFollowingId,
  addSelectedAccountsFollowingId,
  removeSelectedAccountsFollowingGroupIds,
  addSelectedAccountsFollowingGroupIds,
  removeAllSelectedAccountsFollowingGroupIds,
}) => {
  return (
    <>
      <div className="search-sort no-mrg full-width no-select mrg-t12">
        <div onMouseEnter={() => setToolbarActiveHighlight('view')}>
          <DemoViews view={view} setView={setView} />
        </div>
        <div onMouseEnter={() => setToolbarActiveHighlight('filters')}>
          <DemoFilters
            view={view}
            searchDirection={searchDirection}
            setSearchDirection={setSearchDirection}
            searchFormat={searchFormat}
            setSearchFormat={setSearchFormat}
            searchCategory={searchCategory}
            setSearchCategory={setSearchCategory}
          />
        </div>
      </div>

      <div className="h12" />
      <div className="demo-summary-heading no-mrg color-gray fs18 fwsb">
        <DemoTagsToggle
          view={view}
          settingsListOpened={settingsListOpened}
          setSettingsListOpened={setSettingsListOpened}
          demoBigTags={demoBigTags}
          demoTinyTags={demoTinyTags}
          demoTinyTagGroups={demoTinyTagGroups}
          demoCommunityFollowing={demoCommunityFollowing}
          demoCommunityFollowingGroups={demoCommunityFollowingGroups}
          selectedMyEvents={selectedMyEvents}
          selectedBigTags={selectedBigTags}
          selectedTinyTags={selectedTinyTags}
          selectedTinyTagGroupIds={selectedTinyTagGroupIds}
          selectedFollowingIds={selectedFollowingIds}
          selectedFollowingGroupIds={selectedFollowingGroupIds}
        />
        <DemoSearchSummary
          width={width}
          searchCategory={searchCategory}
          searchDirection={searchDirection}
          searchFormat={searchFormat}
          view={view}
        />
      </div>

      <div onMouseEnter={() => setToolbarActiveHighlight('tags')}>
        <div className="flex-row space-between">
          {settingsListOpened && (
            <DemoTagsList
              view={view}
              ////
              demoBigTags={demoBigTags}
              demoTinyTags={demoTinyTags}
              demoTinyTagGroups={demoTinyTagGroups}
              demoCommunityFollowing={demoCommunityFollowing}
              demoCommunityFollowingGroups={demoCommunityFollowingGroups}
              //BT
              selectedBigTags={selectedBigTags}
              selectAllBTBtn={selectAllBTBtn}
              removeSelectedBTs={removeSelectedBTs}
              addSelectedBTs={addSelectedBTs}
              removeAllSelectedBTs={removeAllSelectedBTs}
              setSelectAllBTBtn={setSelectAllBTBtn}
              //My Events
              selectedMyEvents={selectedMyEvents}
              selectAllMyEventsBtn={selectAllMyEventsBtn}
              setSelectedMyEvents={setSelectedMyEvents}
              setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
              // //Tiny Tags
              selectedTinyTags={selectedTinyTags}
              selectedTinyTagGroupIds={selectedTinyTagGroupIds}
              selectAllTTBtn={selectAllTTBtn}
              removeSelectedTinyTags={removeSelectedTinyTags}
              addSelectedTinyTags={addSelectedTinyTags}
              setSelectAllTTBtn={setSelectAllTTBtn}
              removeAllSelectedTinyTags={removeAllSelectedTinyTags}
              removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
              addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
              removeAllSelectedTinyTagGroupIds={
                removeAllSelectedTinyTagGroupIds
              }
              //community
              selectedFollowingIds={selectedFollowingIds}
              selectedFollowingGroupIds={selectedFollowingGroupIds}
              selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
              setSelectAllAccountsFollowingBtn={
                setSelectAllAccountsFollowingBtn
              }
              removeAllSelectedAccountsFollowingIds={
                removeAllSelectedAccountsFollowingIds
              }
              removeSelectedAccountsFollowingId={
                removeSelectedAccountsFollowingId
              }
              addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
              removeSelectedAccountsFollowingGroupIds={
                removeSelectedAccountsFollowingGroupIds
              }
              addSelectedAccountsFollowingGroupIds={
                addSelectedAccountsFollowingGroupIds
              }
              removeAllSelectedAccountsFollowingGroupIds={
                removeAllSelectedAccountsFollowingGroupIds
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default DemoSettingsDekstop;

import { useEffect } from 'react';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';

const TranslationsTutorialHome = ({ setSubPage, setPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <>
      <h1 className="fwsb fs21 lh36">
        Welcome to the MyndFull Translations Tutorial . . .
      </h1>

      <p className="fs18 lh28 mrg-t24 ">
        MyndFull Translations is a tool that provides real-time speech
        translations for formal presentations, particularly those in academic
        and educational settings.
      </p>

      <h1 className="fwsb fs21 lh36 mrg-t24">The Basics</h1>

      <p className="fs18 mrg-t24 lh28">
        One of the main features that helps make MyndFull Translations unique is
        its use of artificial intelligence to automatically correct errors in
        voice-to-text data. Through our session creation process, the host
        provides contextual information about the topic and a list of keywords
        for each speaker. Using this information, MyndFull Translations delivers
        voice and text translations to event participants in real time with
        increased accuracy.
      </p>

      <p className="fs18 mrg-t24 lh28">
        Another standout feature of MyndFull Translations is how effortlessly it
        connects users. For audience members, they only need to follow a few
        simple steps: visit MyndFull on their mobile device, enter the room code
        (and, if required, a password), select their language, and sit back and
        listen through their earbuds or headphones. No downloads or account set
        up is required!
      </p>

      <p className="fs18 mrg-t24 lh28">
        Through this streamlined process, MyndFull Translations can transform
        your presentation into a United Nations-style event, where each audience
        member hears the speaker in their preferred language. With this powerful
        technology, academic and educational events can become more accessible
        than ever before.
      </p>

      <h1 className="fwsb fs21 lh36 mrg-t24">Learn More</h1>

      <p className="fs18 mrg-t24 lh28">
        Beyond the basics, MyndFull Translations offers many unique features
        designed to provide the best possible user experience — whether you're a
        host, speaker, or attendee. We've provided a more detailed overview of
        our current features below.
      </p>

      <div className="translations-selections mrg-auto-lr mrg-t24">
        <ItemButton
          text={'Learn as an Attendee'}
          handler={() => setSubPage(1)}
          customWrapper={'item-button-standard mrg-b36 mrg-t12 fs18'}
        />
        <ItemButton
          text={'Learn as a Speaker'}
          handler={() => setSubPage(2)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />
        <ItemButton
          text={'Learn as a Host'}
          handler={() => setSubPage(3)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />
        <ItemButton
          text={'Return'}
          handler={() => setPage(1)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </>
  );
};

export default TranslationsTutorialHome;

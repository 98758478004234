//hooks
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import { useSettings } from '../../../../../context/SettingsProvider';
import { useNoticeHandlers } from '../../useNoticeHandlers';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';

//utils
import { getEventInvitationType } from '../functions/getEventInvitationType';
import { formatDateFromCreatedAt } from '../../../../../utils/dateTime/DateCalc';
import {
  dropdown_arrow,
  img_exclamation,
  trash,
} from '../../../../../assets/icons';

const NoticeHeader = ({
  n,
  setHighlightNoticeEntryTap,
  openedNotice,
  setOpenedNotice,
  noticeOverlayIsOpened,
  setNoticeOverlayIsOpened,
}) => {
  //hooks
  const { handleMobileTap, contentLanguage } = useSettings();
  const { accountTimeZone } = useAccountReduxHandlers();
  const { noticeTypesWithActionsArr, handleRemoveNotice } = useNoticeHandlers();

  //language
  const { NoticeOverlay, GetEventInvitationType, IconAlts } =
    useLanguageComponents();
  const { NoticeTypes } = useLanguageData();

  function handleNoticeEntryClick(n) {
    const noticeId = n?._id;

    if (openedNotice === noticeId) {
      setOpenedNotice();
    } else {
      setOpenedNotice(noticeId);
    }
    if (!noticeOverlayIsOpened) {
      setNoticeOverlayIsOpened(true);
    }
  }

  return (
    <>
      {!noticeTypesWithActionsArr.includes(n?.noticeType) &&
        n?.noticeStatus === 'unseen' && (
          <div
            className="noticeOverlay--seen"
            title={NoticeOverlay.unseenNotice}
            aria-hidden={true}
          />
        )}

      <button
        className={`msg-notice-entry-btn access-ob access-o-6 ${
          noticeTypesWithActionsArr.includes(n?.noticeType) || n?.noticeHistory
            ? ''
            : 'pointer-default'
        }`}
        tabIndex="0"
        onClick={() =>
          handleMobileTap(
            [
              () => setHighlightNoticeEntryTap(n?._id),
              () => setHighlightNoticeEntryTap(),
            ],
            [() => handleNoticeEntryClick(n)]
          )
        }
        aria-expanded={openedNotice === n?._id ? true : false}
      >
        <div className="flex-row full-width align-center space-between mrg-b12">
          <p className="fs14 color-gray text-left mrg-l12">
            {formatDateFromCreatedAt(
              n.createdAt,
              contentLanguage,
              accountTimeZone
            )}
          </p>

          {noticeTypesWithActionsArr.includes(n?.noticeType) &&
            !n?.noticeHistory && (
              <div className="mrg-r12" title={NoticeOverlay.actionRequired}>
                <div className="flex-row align-center">
                  <img
                    src={img_exclamation}
                    alt={IconAlts.iconAlert}
                    className="exclamation filter-red"
                    style={{ height: '18px' }}
                  />
                  <img
                    src={dropdown_arrow}
                    alt={IconAlts.iconArrow}
                    style={{ height: '12px' }}
                    className={`filter-gray mrg-l12 ${
                      openedNotice ? 'reverse-vertical' : ''
                    }`}
                  />
                </div>
              </div>
            )}
          {n?.noticeHistory && (
            <div className="mrg-r12" title={NoticeOverlay.actionRequired}>
              <div className="flex-row align-center">
                <img
                  src={dropdown_arrow}
                  alt={IconAlts.iconArrow}
                  style={{ height: '12px' }}
                  className={`filter-gray mrg-l12 ${
                    openedNotice ? 'reverse-vertical' : ''
                  }`}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex-row full-width align-center pos-rel">
          {n?.noticeHistory || n?.noticeStatus === 'archived' ? (
            <div
              tabIndex="0"
              role="button"
              className="notice-remove-btn highlight-i-gr mrg-r12 mrg-l6 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleRemoveNotice({ noticeId: n._id });
                setOpenedNotice();
              }}
            >
              <img src={trash} alt={IconAlts.iconTrash} />
            </div>
          ) : (
            <LoadBucketImage
              imagePath={n?.from?.account?.personal?.profileImage}
              imgClassName={'noticeOverlay-profile mrg-lr12'}
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
              containerClassName={'mrg-auto-bottom mrg-t4'}
            />
          )}

          <div className="flex-column full-width">
            <div
              className="flex-row text-left fs18 fwsb full-width"
              style={{ maxWidth: '274px' }}
            >
              {n?.noticeType === 'eventParticipationInvite' ? (
                <>{getEventInvitationType(n, GetEventInvitationType)}</>
              ) : (
                <>{NoticeTypes[n?.noticeType]}</>
              )}
            </div>

            <p className="flex-row full-width fs16 text-left">
              {NoticeOverlay.from}&nbsp;
              {n?.fromMyndFull && 'MyndFull'}
              {n?.from?.account?.personal?.prefix && (
                <>{n?.from?.account?.personal?.prefix}&nbsp;</>
              )}
              {n?.from?.account?.personal?.firstName}
              &nbsp;
              {n?.from?.account?.personal?.lastName}
            </p>

            {n?.eventInfo?.eventTitle && (
              <p className="flex-row full-width fs16 text-left">
                {`${NoticeOverlay.event} ${n?.eventInfo?.eventTitle}`}
              </p>
            )}
          </div>
        </div>
      </button>
    </>
  );
};

export default NoticeHeader;

import { useEffect, useState } from 'react';

//components
import HostSubPageZero from './TranslationsTutorialHostSubPages/HostSubPageZero';
import HostSubPageOne from './TranslationsTutorialHostSubPages/HostSubPageOne';
import HostSubPageTwo from './TranslationsTutorialHostSubPages/HostSubPageTwo';
import HostSubPageThree from './TranslationsTutorialHostSubPages/HostSubPageThree';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import HostSubPageFour from './TranslationsTutorialHostSubPages/HostSubPageFour';

const TranslationsTutorialHost = ({ setSubPage }) => {
  const [hostSubPage, setHostSubPage] = useState(0);

  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <div className="full-width">
      {hostSubPage === 0 && (
        <HostSubPageZero
          setHostSubPage={setHostSubPage}
          setSubPage={setSubPage}
        />
      )}
      {hostSubPage === 1 && <HostSubPageOne setHostSubPage={setHostSubPage} />}
      {hostSubPage === 2 && <HostSubPageTwo setHostSubPage={setHostSubPage} />}

      {hostSubPage === 3 && (
        <HostSubPageThree setHostSubPage={setHostSubPage} />
      )}
      {hostSubPage === 4 && <HostSubPageFour setHostSubPage={setHostSubPage} />}
    </div>
  );
};

export default TranslationsTutorialHost;

import { Suspense, useState } from 'react';

//Hooks
import { useSettings } from '../context/SettingsProvider';

//langauge
import useLanguageLayout from '../language/useLanguageLayout';

//Components
import Spinner from '../components/Spinner/Spinner';
import Navbar from './main/Navbar';
import Footer from './main/Footer';
import SlideMenu from './main/SlideMenu';
import ItemButton from '../components/ItemButton/ItemButton';

//utils
import { image_error } from '../assets/images';
import { img_refresh_two_circular } from '../assets/icons';

const ErrorPage = () => {
  //Hooks
  const { ErrorPage } = useLanguageLayout();
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlightBtn, setTapHighlightBtn] = useState(false);

  //Component functions
  function reloadPage() {
    window.location.reload();
  }

  return (
    <Suspense fallback={<Spinner />}>
      <Navbar />

      <div className="error-page__wrapper">
        <div className="error-divider" />
        <div className="error-page__container mrg-lr24">
          <div className="flex-row flex-column--ph flex-center min-width-full">
            <img src={image_error} alt="" className="mrg-b24" />

            <div className="error-page-text flex-column mrg-l24 mrg-l0--ph">
              <p className="fs21 fwsb lh28">
                <span className="fs28 fwb mrg-r12">{ErrorPage.oops}</span>
                {ErrorPage.errorOccurred1}
                <br /> <br /> {ErrorPage.errorOccurred2}{' '}
                <span className="color-blue underline">
                  {ErrorPage.supportEmail}
                </span>
                .
                <br /> <br />
                {ErrorPage.errorOccurred3}
              </p>
            </div>
          </div>

          <div className="full-width flex-center">
            <ItemButton
              isLoading={tapHighlightBtn}
              handler={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightBtn(true),
                    () => setTapHighlightBtn(false),
                  ],
                  [() => reloadPage()]
                )
              }
              text={ErrorPage.refreshButton}
              customWrapper={'mrg-t24 item-button-standard'}
              iconOn={true}
              icon={img_refresh_two_circular}
              iconStyle={{ height: '24px' }}
            />
          </div>
        </div>
        <div className="error-divider" />
      </div>

      <Footer />
      <SlideMenu />
    </Suspense>
  );
};

export default ErrorPage;

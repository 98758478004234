import { useState } from 'react';

//components
import TranslationsTutorialHome from './TranslationsTutorialHome';
import TranslationsTutorialAttendee from './TranslationsTutorialAttendee';
import TranslationsTutorialSpeaker from './TranslationsTutorialSpeaker';
import TranslationsTutorialHost from './TranslationsTutorialHost';

const TranslationsTutorial = ({ setPage }) => {
  //state
  const [subPage, setSubPage] = useState(0);

  //UI
  return (
    <div
      className={`translations__container translations__container--tutorial onscreen-fade-in-500ms`}
    >
      {subPage === 0 && (
        <TranslationsTutorialHome setSubPage={setSubPage} setPage={setPage} />
      )}
      {subPage === 1 && (
        <TranslationsTutorialAttendee setSubPage={setSubPage} />
      )}
      {subPage === 2 && <TranslationsTutorialSpeaker setSubPage={setSubPage} />}

      {subPage === 3 && <TranslationsTutorialHost setSubPage={setSubPage} />}
    </div>
  );
};

export default TranslationsTutorial;

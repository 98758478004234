import { useState } from 'react';
//Hooks
import { useEventHandlers } from '../../useEventHandlers';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//Utility
import {
  return_curved_arrow,
  star,
  view_ignore,
} from '../../../../../assets/icons';

const GeneralActionButtons = ({
  accountId,
  eventData,
  EventPage,
  eventStatus,
  handleMobileTap,
  handleReturn,
  organizerViewOn,
  participantPreviewOn,
  registrationActionBtnsDisplayOnly,
  setEventStatus,
}) => {
  //Hooks
  const { handleUserSaveEvent, handleUserIgnoreEvent } = useEventHandlers();

  //lanuage
  const { IconAlts } = useLanguageComponents();

  //UI state
  const [tapHighlightReturn, setTapHighlightReturn] = useState(false);
  const [tapHighlightIgnore, setTapHighlightIgnore] = useState(false);
  const [tapHighlightSave, setTapHighlightSave] = useState(false);

  return (
    <div
      className={`${
        !registrationActionBtnsDisplayOnly
          ? 'flex-row space-evenly full-width'
          : ''
      }`}
    >
      <button
        className={`ev__action-btn highlight-i-theme
       ${
         tapHighlightReturn ? 'highlight-i-theme--tap click-scaledown-95' : ''
       }`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightReturn(true),
              () => setTapHighlightReturn(false),
            ],
            [() => handleReturn()],
            true
          )
        }
        title={EventPage.return}
      >
        <img
          src={return_curved_arrow}
          alt={IconAlts.iconArrow}
          className="filter-lightgray"
          style={{ height: '28px' }}
        />
      </button>

      <button
        id="status-ignore"
        className={`ev__action-btn highlight-i-theme ${
          organizerViewOn || participantPreviewOn ? 'opacity-03' : ''
        } ${tapHighlightIgnore ? 'click-scaledown-95' : ''}`}
        onClick={
          !organizerViewOn && !participantPreviewOn
            ? () =>
                handleMobileTap(
                  [
                    () => setTapHighlightIgnore(true),
                    () => setTapHighlightIgnore(false),
                  ],
                  [
                    () =>
                      handleUserIgnoreEvent(
                        eventData._id,
                        accountId,
                        eventStatus,
                        setEventStatus
                      ),
                  ],
                  true
                )
            : null
        }
        title={`${
          eventStatus === 'ignored'
            ? EventPage.clickToUnignore
            : EventPage.clickToIgnore
        }`}
      >
        <img
          src={view_ignore}
          alt={EventPage.ignoreButton}
          className={`${
            eventStatus === 'ignored' ? 'filter-theme' : 'filter-lightgray'
          }`}
          style={{ height: '30px' }}
        />
      </button>
      <button
        id="status-save"
        className={`ev__action-btn highlight-i-theme  ${
          organizerViewOn || participantPreviewOn ? 'opacity-03' : ''
        } ${tapHighlightSave ? 'click-scaledown-95' : ''}`}
        onClick={
          !organizerViewOn && !participantPreviewOn
            ? () =>
                handleMobileTap(
                  [
                    () => setTapHighlightSave(true),
                    () => setTapHighlightSave(false),
                  ],
                  [
                    () =>
                      handleUserSaveEvent(
                        eventData._id,
                        accountId,
                        eventStatus,
                        setEventStatus
                      ),
                  ],
                  true
                )
            : null
        }
        title={
          eventStatus === 'saved'
            ? EventPage.clickToUnsave
            : EventPage.clickToSave
        }
      >
        <img
          src={star}
          alt={EventPage.saveButton}
          className={`${
            eventStatus === 'saved' ? 'filter-theme' : 'filter-lightgray'
          }`}
          style={{ height: '32px' }}
        />
      </button>
    </div>
  );
};

export default GeneralActionButtons;

import { useEffect } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';

//components
import TranslationCreateRoomAuth from './TranslationCreateRoomAuth';
import TranslationRoom from '../Room/TranslationRoom';
//
import CreateRoomInitializeRoomData from './CreateRoomInitializeRoomData';
import CreateRoomPassword from './CreateRoomPassword';
import CreateRoomLanguages from './CreateRoomLanguages';
import CreateRoomSpeakers from './CreateRoomSpeakers';
//
import TranslationsDrafts from './TranslationsDrafts/TranslationsDrafts';
import TranslationsOrganizeDrafts from './TranslationsDrafts/TranslationsOrganizeDrafts';
import TranslationsRemoveDrafts from './TranslationsDrafts/TranslationsRemoveDrafts';
//
import TranslationsTranscripts from './Transcripts/TranslationsTranscripts';
import TranslationsTranscriptsOpen from './Transcripts/TranslationsTranscriptsOpen';
import TranslationsTranscriptsRemove from './Transcripts/TranslationsTranscriptsRemove';

//
import Spinner from '../../../../../components/Spinner/Spinner';

//utility
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import CreateRoomSpeakerProfiles from './CreateRoomSpeakerProfiles';
import CreateRoomTitle from './CreateRoomTitle';

const TranslationCreateRoom = ({ setPage, setRemoveAnimationBackground }) => {
  //hooks
  const {
    translationsConnectionIsInitializing,
    setRestoreDataStatus,
    createRoomPage,
    closeTranslationRoomSocket,
  } = useTranslationRoomSocket();

  //initialize
  useEffect(() => {
    setRestoreDataStatus();

    return () => {
      closeTranslationRoomSocket({
        source: 'TranslationCreateRoom/component unmounted',
      });
      setRemoveAnimationBackground(false);
    };
  }, []);

  //UI
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });

    if (createRoomPage !== 'home') {
      if (setRemoveAnimationBackground) {
        setRemoveAnimationBackground(true);
      }
    }
  }, [createRoomPage, setRemoveAnimationBackground]);

  return (
    <>
      {createRoomPage === 'home' && (
        <TranslationCreateRoomAuth setPage={setPage} />
      )}
      {createRoomPage === 'createMainMenu' && (
        <CreateRoomInitializeRoomData setPage={setPage} />
      )}
      {createRoomPage === 'createTitle' && <CreateRoomTitle />}
      {createRoomPage === 'createPassword' && <CreateRoomPassword />}
      {createRoomPage === 'createLanguages' && <CreateRoomLanguages />}
      {createRoomPage === 'createSpeakers' && <CreateRoomSpeakers />}
      {createRoomPage === 'createSpeakerProfiles' && (
        <CreateRoomSpeakerProfiles />
      )}

      {createRoomPage === 'drafts' && <TranslationsDrafts />}
      {createRoomPage === 'organizeDrafts' && <TranslationsOrganizeDrafts />}
      {createRoomPage === 'removeDrafts' && <TranslationsRemoveDrafts />}

      {createRoomPage === 'transcripts' && <TranslationsTranscripts />}
      {createRoomPage === 'openTranscripts' && <TranslationsTranscriptsOpen />}
      {createRoomPage === 'removeTranscripts' && (
        <TranslationsTranscriptsRemove />
      )}

      {createRoomPage === 'hostRoom' &&
      !translationsConnectionIsInitializing ? (
        <TranslationRoom isHost={true} setPage={setPage} />
      ) : (
        createRoomPage === 6 &&
        translationsConnectionIsInitializing && <Spinner minHeight={'100%'} />
      )}
    </>
  );
};

export default TranslationCreateRoom;

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import TextArea from '../../../../../components/TextArea/TextArea';
import CheckBoxBoolean from '../../../../../components/CheckBoxes/CheckBoxBoolean';

//utils
import { return_curved_arrow } from '../../../../../assets/icons';
import { image_title_card } from '../../../../../assets/images';

const CreateRoomTitle = () => {
  //hooks
  const {
    sessionSaveName,
    setSessionSaveName,
    handleCreatePageChange,
    handleResetCurrentHostSessionData,
    setSaveTranslationTranscript,
    saveTranslationTranscript,
  } = useTranslationRoomSocket();

  //language
  const { TranslationsCreateRoomGeneralSettings } =
    useLanguageTranslationRoom();

  return (
    <div
      className={`translations__container translations__container--no-box-shadow`}
    >
      <img
        src={image_title_card}
        alt="img"
        style={{ height: '200px' }}
        className="svg"
        aria-hidden={true}
      />
      <div className="translations-selections">
        <p
          className="fs21 fwsb mrg-t24 text-center access-ob access-o6"
          tabIndex="0"
          aria-label={TranslationsCreateRoomGeneralSettings.generalSettings}
          id={'createRoom'}
        >
          {TranslationsCreateRoomGeneralSettings.generalSettings}
        </p>
        <div className="h24" />
        <TextArea
          limit={200}
          id={'sessionSaveName'}
          labelText={TranslationsCreateRoomGeneralSettings.saveName}
          disableEnter={true}
          customWidth={'100%'}
          handleChange={setSessionSaveName}
          loadValue={sessionSaveName}
          loadTrigger={true}
          onBlurHandlerOff={true}
          hideWordCount={true}
        />

        <CheckBoxBoolean
          handler={setSaveTranslationTranscript}
          customWrapper={'color-gray mrg-t24 mrg-auto-right'}
          handleAccountId={true}
          loadTrigger={true}
          loadValue={saveTranslationTranscript}
          label={TranslationsCreateRoomGeneralSettings.saveTranscript}
          id={'toggleTranscripts'}
        />

        <ItemButton
          text={TranslationsCreateRoomGeneralSettings.continue}
          handler={() => handleCreatePageChange('createPassword')}
          customWrapper={'full-width mrg-t24'}
          iconOn={true}
          icon={return_curved_arrow}
          iconCustomClass={'reverse'}
          iconStyle={{ height: '21px' }}
          unavailable={sessionSaveName?.length === 0}
        />
        <div className="divider full-width mrg-t36 mrg-b12" />
        <div className="h24" />
        <ItemButton
          text={TranslationsCreateRoomGeneralSettings.back}
          handler={() => {
            handleResetCurrentHostSessionData();
            handleCreatePageChange('createMainMenu');
          }}
          fullWidth={true}
        />
      </div>
    </div>
  );
};

export default CreateRoomTitle;

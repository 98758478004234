import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//components
import FieldValueMissing from './FieldValueMissing';

//language
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import { dropdown_arrow, lock_locked } from '../../../../assets/icons';
import { MapContainerStatic } from '../../../../components/MapContainer';

const HostingInPersonContent = ({
  eventData,
  EventPage,
  eventStatus,
  handleMobileTap,
  organizerViewOn,
  previewMode,
  editPreviewMode,
  isParticipant,
}) => {
  //hooks
  const { isTouchDevice } = useSettings();
  //language
  const { CountriesOpts } = useLanguageData();
  const { IconAlts } = useLanguageComponents();

  //Component State
  const [showMap, setShowMap] = useState(false);
  const [mapLocked, setMapLocked] = useState(false);
  const [mapCoords, setMapCoords] = useState([]);

  //UI state
  const [tapHighlightShowMapBtn, setTapHighlightShowMapBtn] = useState(false);
  const [onHoverHighlightMapBtn, setOnHoverHighlightMapBtn] = useState(false);

  //Component Variables
  const privateDetailsEnabled =
    eventData.registrationData.privateDetails.enabled;
  const roomLocked = eventData.registrationData.privateDetails.room;
  const locationNameLocked =
    eventData.registrationData.privateDetails.locationName;
  const streetAddressLocked =
    eventData.registrationData.privateDetails.streetAddress;

  let roomInfo;
  let locationNameInfo;
  let streetAddressInfo;
  let cityCountryInfo;

  //Initialize functions
  useEffect(() => {
    if (organizerViewOn || !privateDetailsEnabled) {
      return setMapLocked(false);
    } // doesnt require map lock

    //requires map lock
    if (locationNameLocked || streetAddressLocked) {
      //check user registration
      if (
        !isParticipant &&
        eventStatus !== 'registeredInPerson' &&
        eventStatus !== 'registeredVirtual' &&
        eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
        eventStatus !== 'pendingApprovalVirtual-registeredInPerson'
      ) {
        setMapLocked(true);
      } else {
        setMapLocked(false);
      }
    }
  }, [
    eventData,
    eventStatus,
    organizerViewOn,
    locationNameLocked,
    streetAddressLocked,
    isParticipant,
  ]); //determine map lock

  useEffect(() => {
    if (eventData) {
      if (
        eventData.hosting.inPerson?.geoPosition?.coordinates[0] &&
        eventData.hosting.inPerson?.geoPosition?.coordinates[1]
      ) {
        if ((previewMode && eventData?.meta?.isDraft) || editPreviewMode) {
          setMapCoords([
            eventData.hosting.inPerson?.geoPosition?.coordinates[1],
            eventData.hosting.inPerson?.geoPosition?.coordinates[0],
          ]);
        } else {
          setMapCoords(eventData.hosting.inPerson?.geoPosition?.coordinates);
        }
      }
    }
  }, [eventData]); //need to reverse map coords in preview mode when draft

  //UI functions
  function handleShowMapBtn() {
    if (!mapLocked) {
      return handleMobileTap(
        [
          () => setTapHighlightShowMapBtn(true),
          () => setTapHighlightShowMapBtn(false),
        ],
        [() => setShowMap(!showMap)]
      );
    } else {
      return null;
    }
  }

  //ROOM
  if (
    privateDetailsEnabled &&
    roomLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    roomInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.room}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (organizerViewOn && privateDetailsEnabled && roomLocked) {
    roomInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.room}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {eventData.hosting.inPerson.location.room?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {eventData.hosting.inPerson.location.room}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    roomInfo = (
      <>
        {eventData.hosting.inPerson.location.room?.length > 0 && (
          <p className="ev-info__row access-ob access-o6" tabIndex="0">
            {`${EventPage.room}: ${eventData.hosting.inPerson.location.room}`}
          </p>
        )}
      </>
    );
  }

  //LOCATION NAME
  if (
    privateDetailsEnabled &&
    locationNameLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    locationNameInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.locationName}</p>
        <img src={lock_locked} alt="" className="lock-img mrg-l12" />
      </div>
    );
  } else if (organizerViewOn && privateDetailsEnabled && locationNameLocked) {
    locationNameInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.locationName}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {eventData.hosting.inPerson.location.locationName?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {eventData.hosting.inPerson.location.locationName}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    locationNameInfo = (
      <>
        {eventData.hosting.inPerson.location.locationName?.length > 0 && (
          <p className="ev-info__row access-ob access-o6" tabIndex="0">
            {`${eventData.hosting.inPerson.location.locationName}`}
          </p>
        )}
      </>
    );
  }

  //STREET ADDRESS
  if (
    privateDetailsEnabled &&
    streetAddressLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    streetAddressInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.streetAddress}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (organizerViewOn && privateDetailsEnabled && streetAddressLocked) {
    streetAddressInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.streetAddress}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {eventData.hosting.inPerson.location.streetAddress?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {eventData.hosting.inPerson.location.streetAddress}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    streetAddressInfo = (
      <>
        {eventData.hosting.inPerson.location.streetAddress?.length > 0 && (
          <p className="ev-info__row access-ob access-o6" tabIndex="0">
            {`${eventData.hosting.inPerson.location.streetAddress}`}
          </p>
        )}
      </>
    );
  }

  //City + Country
  if (eventData.hosting?.inPerson?.location?.city) {
    cityCountryInfo = (
      <p className="ev-info__row access-ob access-o6" tabIndex="0">
        {eventData.hosting?.inPerson.location.city},&nbsp;
        {CountriesOpts[eventData.hosting.inPerson.location.country]}
      </p>
    );
  } else {
    cityCountryInfo = (
      <p className="ev-info__row access-ob access-o6" tabIndex="0">
        {CountriesOpts[eventData.hosting.inPerson.location.country]}
      </p>
    );
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="locationLabel"
      >
        {EventPage.location}
      </label>
      <div className="ev-info__container">
        {roomInfo}
        {locationNameInfo}
        {streetAddressInfo}
        {cityCountryInfo}
      </div>

      <div
        className={`flex-column br--standard mrg-t24 flex-center full-width border-reg ${
          tapHighlightShowMapBtn ? 'highlight-b2-theme--tap' : ''
        }
        ${onHoverHighlightMapBtn ? 'highlight-b2-theme--tap' : ''}`}
      >
        <button
          className={`flex-row flex-center full-width  pad-6 min-44 `}
          onClick={handleShowMapBtn}
          aria-pressed={showMap}
          aria-label={EventPage.showMap}
          onMouseEnter={
            !isTouchDevice ? () => setOnHoverHighlightMapBtn(true) : null
          }
          onMouseLeave={
            !isTouchDevice ? () => setOnHoverHighlightMapBtn(false) : null
          }
        >
          <p className="fs18 fwn">{EventPage.showMap}</p>
          {organizerViewOn && (
            <div className="flex-center mrg-l6">
              <img
                src={lock_locked}
                alt={IconAlts.iconLock}
                className="lock-img"
                title={EventPage.valueHiddenForNonRegistered}
              />
            </div>
          )}
          {mapLocked ? (
            <img
              src={lock_locked}
              alt={IconAlts.iconLock}
              className="lock-img mrg-l12"
              title={EventPage.valueHiddenForNonRegistered}
            />
          ) : (
            <img
              src={dropdown_arrow}
              alt={IconAlts.arrow}
              className={` mrg-l24 filter-gray ${
                showMap ? '' : ' reverse-vertical'
              }`}
              style={{ width: '18px' }}
            />
          )}
        </button>
        {showMap && !mapLocked && (
          <div className="full-width pad-l12 pad-r12 pad-b12 pad-0--ph">
            <MapContainerStatic loadValue={mapCoords} />
          </div>
        )}
      </div>
    </>
  );
};

export default HostingInPersonContent;

import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';

//components
import EventsNextSearchBtn from './EventsNextSearchBtn';
import EventsExpandSearchBtn from './EventsExpandSearchBtn';
import Spinner from '../../../../components/Spinner/Spinner';

const EventsSearchEmpty = ({ isLoading }) => {
  //hooks
  const { settingsEventSearchView } = useSettingsHandlers();
  //ui
  const [renderSearchEmptyUI, setRenderSearchEmptyUI] = useState(false);

  //ui
  const [
    eventSearchEmptySpinnerAnimation,
    setEventSearchEmptySpinnerAnimation,
  ] = useState(false);

  //variables
  const renderSearchEmptyUIDelayTimer = useRef();
  const spinnerAnimationTimer = useRef();

  //Initialize functions
  useEffect(() => {
    if (renderSearchEmptyUIDelayTimer.current) {
      clearTimeout(renderSearchEmptyUIDelayTimer.current);
    }
  }, []); //unmount cleanup

  //ui
  useEffect(() => {
    if (isLoading) {
      setRenderSearchEmptyUI(false);
    } else {
      renderSearchEmptyUIDelayTimer.current = setTimeout(() => {
        setRenderSearchEmptyUI(true);
      }, 500);
    }
  }, [isLoading]); //provides delay before rendering search results empty ui

  useEffect(() => {
    if (eventSearchEmptySpinnerAnimation) {
      spinnerAnimationTimer.current = setTimeout(() => {
        setEventSearchEmptySpinnerAnimation(false);
      }, 500);
    }

    return () => {
      clearTimeout(spinnerAnimationTimer.current);
    };
  }, [eventSearchEmptySpinnerAnimation]);

  return (
    <div
      className={`transition-opacity--500ms fs18 mrg-t24 mrg-t36--ph align-center flex-column ${
        renderSearchEmptyUI ? '' : 'opacity-0'
      }`}
    >
      {eventSearchEmptySpinnerAnimation ? (
        <Spinner minHeight={'310px'} />
      ) : (
        <>
          {settingsEventSearchView !== 'myevents' ? (
            <>
              <EventsNextSearchBtn
                setEventSearchEmptySpinnerAnimation={
                  setEventSearchEmptySpinnerAnimation
                }
              />
              <div className="divider full-width opacity-05" />
              <EventsExpandSearchBtn />
            </>
          ) : (
            <EventsNextSearchBtn
              setEventSearchEmptySpinnerAnimation={
                setEventSearchEmptySpinnerAnimation
              }
            />
          )}
        </>
      )}
    </div>
  );
};

export default EventsSearchEmpty;

const editEventConflictObjDefault = {
  removingRegistrations: {
    conflict: false,
    resolution: '',
  },
  removingInPersonLocation: {
    conflict: false,
    resolution: '',
  },
  removingOnlineLocation: {
    conflict: false,
    resolution: '',
  },
};

export default editEventConflictObjDefault;

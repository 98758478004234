import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../../../context/SettingsProvider';

//lanugage
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import LoadBucketImage from '../../../../../../components/LoadBucketImage/LoadBucketImage';
import ParticipantListItemName from './Generic/ParticipantListItemName';
import ParticipantListItemStatusParticipant from './EventParticipants/ParticipantListItemStatusParticipant';
import ParticipantListItemStatusTranslations from './Translations/ParticipantListItemStatusTranslations';
import ParticipantListItemStatusDispatches from './Dispatches/ParticipantListItemStatusDispatches';

//utils
import { search_dark } from '../../../../../../assets/icons';

const ParticipantListItem = ({
  participant,
  handler,
  previewImage,
  listItemMode,

  //UI
  turnOffAnimation,
  borderBottomOff,
  translationsNameOnly,
  translationsDraft,
  customContainer,
  customWrapper,
  dispatches,

  //dispatches
  totalDispatchesAvailable,
  totalCurrentDispatches,
  allDispatchesIssued,
  translationsEnabled,
}) => {
  //hooks
  const { width } = useSettings();

  //language
  const { IconAlts, CommunityListAccountPersonal } = useLanguageComponents();

  //ui
  const [contactHoverUI, setContactHoverUI] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    });
    return () => clearTimeout(timer);
  }, []); //prevents hover effect from appearing while loading

  return (
    <div className={`flex-row full-width`}>
      {participant && !isLoading && (
        <div
          id={`contact-${participant?.id}`}
          className={`participant-list-item__wrapper full-width access-ob access-o6 
            ${
              borderBottomOff
                ? ''
                : 'participant-list-item__wrapper--border-bottom'
            }
            ${
              turnOffAnimation || isLoading
                ? ''
                : 'participant-list-item__wrapper--hover'
            }
            ${
              listItemMode === 'dispatches'
                ? 'participant-list-item__wrapper--dispatches'
                : ''
            }

            ${customWrapper ? customWrapper : ''}
            `}
          tabIndex="0"
          onFocus={
            turnOffAnimation || isLoading ? null : () => setContactHoverUI(true)
          }
          onBlur={
            turnOffAnimation || isLoading
              ? null
              : () => setContactHoverUI(false)
          }
          onMouseEnter={
            turnOffAnimation || isLoading ? null : () => setContactHoverUI(true)
          }
          onMouseLeave={
            turnOffAnimation || isLoading
              ? null
              : () => setContactHoverUI(false)
          }
          aria-label={`${participant?.participantData?.personal?.prefix} ${participant?.participantData?.personal?.firstName} ${participant?.participantData?.personal?.lastName}. ${CommunityListAccountPersonal.openProfileView}.`}
        >
          <button
            className={`participant-list-item__container fs16 ${
              listItemMode === 'dispatches'
                ? 'participant-list-item__container--dispatches'
                : ''
            } ${customContainer ? customContainer : ''}`}
            onClick={handler ? () => handler(participant?.id) : null}
          >
            <div
              className={`flex-row full-width align-center ${
                listItemMode === 'dispatches' ? 'flex-column--ph' : ''
              }`}
            >
              {(width <= 480 && listItemMode === 'dispatches') ||
              translationsDraft ? null : (
                <div className="pos-rel">
                  <LoadBucketImage
                    imagePath={
                      participant?.participantData?.personal?.profileImage
                    }
                    imgClassName={'participant-contact--profile mrg-r6'}
                    backupPath={'account_img_default.png'}
                    partialPath={
                      participant?.organizedAs !== 'inviteContact'
                        ? 'myndfull_participant_images'
                        : 'myndfull_account_images'
                    }
                    croppedImage={previewImage?.croppedImage?.croppedImage}
                  />

                  <div
                    className={`participant-contact-view pos-abs ${
                      contactHoverUI ? 'opacity-1' : 'opacity-0'
                    }`}
                  >
                    <img src={search_dark} alt={IconAlts.iconArrow} />
                  </div>
                </div>
              )}

              <div className="participant-name-status flex-column mrg-l6 text-left">
                <ParticipantListItemName
                  participant={participant}
                  translationsNameOnly={translationsNameOnly}
                  dispatches={dispatches}
                />

                {listItemMode === 'eventParticipant' && (
                  <ParticipantListItemStatusParticipant p={participant} />
                )}

                {listItemMode === 'translations' && (
                  <ParticipantListItemStatusTranslations p={participant} />
                )}

                {listItemMode === 'dispatches' && (
                  <ParticipantListItemStatusDispatches
                    p={participant}
                    totalDispatchesAvailable={totalDispatchesAvailable}
                    totalCurrentDispatches={totalCurrentDispatches}
                    allDispatchesIssued={allDispatchesIssued}
                    translationsEnabled={translationsEnabled}
                    translationsDraft={translationsDraft}
                  />
                )}
              </div>
            </div>
          </button>
        </div>
      )}
    </div>
  );
};

export default ParticipantListItem;

import { useEffect, useRef, useState } from 'react';

//hooks
import { useEventHandlers } from '../useEventHandlers';
import { useSettings } from '../../../../context/SettingsProvider';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';

//language
import useLanguageEvents from '../../../../language/features/useLanguageEvents';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//component
import Spinner from '../../../../components/Spinner/Spinner';

//utils
import { image_search_cards_icon } from '../../../../assets/images/index';
import ScrollTo from '../../../../utils/UI/ScrollTo';

const EventsNextSearchBtn = ({
  setEventSearchEmptySpinnerAnimation,
  fadeInAnimation,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();
  const { handleGuidedEventNavigation } = useEventHandlers();
  const { settingsEventSearchView } = useSettingsHandlers();

  //language
  const { EventsGrid } = useLanguageEvents();
  const { IconAlts } = useLanguageComponents();

  //ui
  const [highlightTapNextSearchBtn, setHighlightTapNextSearchBtn] =
    useState(false);
  const [spinnerAnimation, setSpinnerAnimation] = useState(false);

  const [renderFadeIn, setRenderFadeIn] = useState(false);

  const fadeInAnimationTimer = useRef();

  useEffect(() => {
    if (fadeInAnimation) {
      fadeInAnimationTimer.current = setTimeout(() => {
        setRenderFadeIn(true);
      }, 500);

      return () => {
        if (fadeInAnimationTimer.current) {
          clearTimeout(fadeInAnimationTimer.current);
        }
      };
    }
  }, [fadeInAnimation]);

  function handleSpinner() {
    if (!setEventSearchEmptySpinnerAnimation) {
      setSpinnerAnimation(true);
    } else {
      setEventSearchEmptySpinnerAnimation(true);
    }
  }

  return (
    <div
      className={`transition-opacity--500ms ${
        fadeInAnimation && !renderFadeIn ? 'opacity-0 ' : 'opacity-1'
      }`}
    >
      {!spinnerAnimation ? (
        <button
          className={`ec-search-complete-change-search-btn access-ob access-o6 mrg-auto-bottom--ph full-width ${
            highlightTapNextSearchBtn
              ? 'ec-search-complete-change-search-btn--tap'
              : ''
          }`}
          tabIndex="0"
          aria-label={EventsGrid.nextSearch}
          onClick={() =>
            handleMobileTap(
              [
                () => setHighlightTapNextSearchBtn(true),
                () => setHighlightTapNextSearchBtn(false),
                () => handleSpinner(),
              ],
              [
                () =>
                  ScrollTo({
                    focusId: 'events-grid',
                    scrollToTop: true,
                  }),
                () => handleGuidedEventNavigation(),
              ],
              true
            )
          }
        >
          <div className="pad-tb24 flex-center flex-column">
            <p className="fs16 mrg-b12 color-gray ff2 br--standard pad-6">
              {settingsEventSearchView === 'myevents'
                ? EventsGrid.findMoreEvents
                : EventsGrid.clickForNextSearch}
            </p>

            <div className="flex-row align-center">
              <img
                src={image_search_cards_icon}
                alt={IconAlts.iconSearch}
                style={{ height: '44px' }}
                className="filter-gray"
              />
            </div>
          </div>
        </button>
      ) : (
        spinnerAnimation && <Spinner />
      )}
    </div>
  );
};

export default EventsNextSearchBtn;

//components
import BillingPaymentCard from './BillingPaymentCard';
import BillingPaymentNewCardStripeWrapper from './BillingPaymentNewCardStripeWrapper';

const BillingPaymentMethods = ({
  cardDetails,
  saveAccountChange,
  handleCancelNewCard,
  reset,
  setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices,
}) => {
  return (
    <>
      <div className={`cycle-card-row `}>
        {cardDetails
          ?.map((card, index) => {
            const hasPrimaryCard = cardDetails?.some((card) => card.isDefault);

            if (!card?.card?.brand) {
              return (
                <BillingPaymentNewCardStripeWrapper
                  key={`BillingPaymentNewCardStripeWrapper-${index}`}
                  saveAccountChange={saveAccountChange}
                  handleCancelNewCard={handleCancelNewCard}
                  reset={reset}
                  hasPrimaryCard={hasPrimaryCard}
                  setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices={
                    setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices
                  }
                />
              );
            } else {
              return (
                <BillingPaymentCard
                  cardData={card}
                  key={`billingPaymentCard-${index}`}
                  reset={reset}
                  saveAccountChange={saveAccountChange}
                  setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices={
                    setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices
                  }
                />
              );
            }
          })
          .reverse()}
      </div>
    </>
  );
};

export default BillingPaymentMethods;

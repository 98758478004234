import { useState } from 'react';

//hooks
import { useSettings } from '../../../../../../context/SettingsProvider';

//language
import useLanguageData from '../../../../../../language/useLanguageData';

//utils
import { formatDateFromCreatedAt } from '../../../../../../utils/dateTime/DateCalc';
import { dropdown_arrow } from '../../../../../../assets/icons';

const TranslationsTranscriptParticipants = ({
  selectedTranscript,
  TranslationTranscripts,
  TranslationLanguagesSingular,
  IconAlts,
  handleDisplaySpeakerIndex,
  displaySpeakerUpdateIndexes,
  hostTimeZone,
  contentLanguage,
  TimeZonesAbbr,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //ui
  const [
    tapHighlightSpeakerUpdateDropdown,
    setTapHighlightSpeakerUpdateDropdown,
  ] = useState(false);

  //language
  const { VoiceOpts } = useLanguageData();

  return (
    <ul
      className="list-style-none full-width fs18 access-ob access-o6"
      tabIndex="0"
    >
      {selectedTranscript?.speakersData?.map((speakerData, speakerIndex) => {
        let speakerUpdateTotal = 0;
        let updateSummaries = [];

        selectedTranscript.speakerUpdates?.map(
          (speakerUpdateData, speakerUpdateIndex) => {
            if (speakerUpdateData.id === speakerData.id) {
              let speakerComparison;

              if (
                speakerUpdateIndex ===
                selectedTranscript.speakerUpdates?.length - 1
              ) {
                speakerComparison = speakerData;
              } else if (selectedTranscript.speakerUpdates?.length > 0) {
                speakerComparison =
                  selectedTranscript.speakerUpdates[speakerUpdateIndex + 1];
              }

              if (speakerComparison) {
                speakerUpdateTotal++;

                const updates = [];

                updates.push({
                  updatedAt: speakerUpdateData.updatedAt,
                });

                if (
                  speakerUpdateData.languageOpt !==
                  speakerComparison.languageOpt
                ) {
                  updates.push({
                    language: speakerUpdateData.languageOpt,
                  });
                }

                if (speakerUpdateData.voiceOpt !== speakerComparison.voiceOpt) {
                  updates.push({
                    voice: speakerUpdateData.voiceOpt,
                  });
                }

                if (
                  JSON.stringify(speakerUpdateData.keywordsList) !==
                  JSON.stringify(speakerComparison.keywordsList)
                ) {
                  let addedKeywords = [];
                  let removedKeywords = [];

                  speakerUpdateData.keywordsList?.filter((kw) => {
                    if (!speakerComparison.keywordsList.includes(kw)) {
                      removedKeywords.push(kw);
                    }
                  });

                  speakerComparison.keywordsList?.filter((kw) => {
                    if (!speakerUpdateData.keywordsList.includes(kw)) {
                      addedKeywords.push(kw);
                    }
                  });

                  if (removedKeywords?.length > 0) {
                    updates.push({
                      removedKeywords: removedKeywords,
                    });
                  }
                  if (addedKeywords?.length > 0) {
                    updates.push({
                      addedKeywords: addedKeywords,
                    });
                  }
                }

                if (
                  speakerUpdateData.discussionContext !==
                  speakerComparison.discussionContext
                ) {
                  updates.push({
                    previousDiscussionContext:
                      speakerUpdateData.discussionContext,
                  });
                }

                updateSummaries.push(updates);
              }
            }
            return null;
          }
        );

        return (
          <li
            key={`participant-${speakerData.id}`}
            className="border-thin pad-24 br--standard mrg-b12"
          >
            <p className="fwsb mrg-b12 access-ob access-o6" tabIndex="0">
              {speakerData.name}
            </p>
            <p className="mrg-b12 access-ob access-o6" tabIndex="0">
              {TranslationTranscripts.language}:{' '}
              {TranslationLanguagesSingular[speakerData.languageOpt]}
            </p>
            <p className="mrg-b12 access-ob access-o6" tabIndex="0">
              {TranslationTranscripts.voice}: {VoiceOpts[speakerData.voiceOpt]}
            </p>
            {speakerData?.keywordsList?.length === 0 ? (
              <>
                <p className="mrg-b12 access-ob access-o6" tabIndex="0">
                  {TranslationTranscripts.keywordsList}:{' '}
                  {TranslationTranscripts.noKeywords}
                </p>
              </>
            ) : (
              <>
                <div className="flex-row">
                  <p
                    className="mrg-b12 mrg-r12 align-center access-ob access-o6"
                    tabIndex="0"
                  >
                    {TranslationTranscripts.keywordsList}:
                  </p>
                  <ul className="mrg-b12 list-style-none flex-row">
                    {speakerData?.keywordsList?.map((keyword, index) => {
                      return (
                        <li key={`keyword-${index}-${speakerIndex}`}>
                          <p
                            className="transcript-keyword access-ob access-o6"
                            tabIndex="0"
                          >
                            {keyword}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </>
            )}
            <p className="mrg-b12 access-ob access-o6" tabIndex="0">
              {TranslationTranscripts.discussionContext}:{' '}
              {speakerData?.discussionContext}
            </p>

            {speakerUpdateTotal > 0 && (
              <>
                <button
                  className={`flex-row fit-content align-center highlight-b-theme border-thin pad-6 br--small space-between fs18 pad-lr12 mrg-t24 ${
                    tapHighlightSpeakerUpdateDropdown
                      ? 'highlight-b2-theme--tap'
                      : ''
                  }`}
                  onClick={() =>
                    handleMobileTap(
                      [
                        () => setTapHighlightSpeakerUpdateDropdown(true),
                        () => setTapHighlightSpeakerUpdateDropdown(false),
                      ],
                      [() => handleDisplaySpeakerIndex(speakerIndex)]
                    )
                  }
                >
                  <p>
                    {TranslationTranscripts.inSessionUpdates}:{' '}
                    {speakerUpdateTotal}
                  </p>
                  <img
                    src={dropdown_arrow}
                    alt={IconAlts.iconArrow}
                    className={`mrg-l12 filter-gray ${
                      displaySpeakerUpdateIndexes.includes(speakerIndex)
                        ? 'reverse-vertical'
                        : ''
                    }`}
                  />
                </button>

                {displaySpeakerUpdateIndexes.includes(speakerIndex) && (
                  <ul className="mrg-l12 list-style-none fs18">
                    {updateSummaries
                      ?.map((updateData, updateIndex) => {
                        return (
                          <li
                            key={`updateData-${updateIndex}`}
                            className={`mrg-t12 ${
                              updateIndex !== 0 ? 'mrg-b24' : 'mrg-b12'
                            }`}
                          >
                            {updateData?.map((entry, entryIndex) => {
                              return (
                                <div
                                  key={`updateEntry-${speakerIndex}-${updateIndex}-${entryIndex}`}
                                >
                                  {Object.entries(entry)?.map(
                                    ([key, value], updateEntryIndex) => {
                                      if (key === 'updatedAt') {
                                        return (
                                          <p
                                            key={`createdAt-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}`}
                                          >
                                            {formatDateFromCreatedAt(
                                              value,
                                              contentLanguage,
                                              hostTimeZone
                                            )}{' '}
                                            ({TimeZonesAbbr[hostTimeZone]})
                                          </p>
                                        );
                                      }

                                      if (key === 'language') {
                                        return (
                                          <p
                                            key={`previousLanguage-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}`}
                                            className="mrg-l24 mrg-t6"
                                          >
                                            {TranslationTranscripts.changedFrom}{' '}
                                            {
                                              TranslationLanguagesSingular[
                                                value
                                              ]
                                            }
                                          </p>
                                        );
                                      }

                                      if (key === 'voice') {
                                        return (
                                          <p
                                            key={`previousVoice-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}`}
                                            className="mrg-l24 mrg-t6"
                                          >
                                            {TranslationTranscripts.changedFrom}{' '}
                                            {VoiceOpts[value]}
                                          </p>
                                        );
                                      }

                                      if (key === 'previousDiscussionContext') {
                                        return (
                                          <p
                                            key={`previousContext-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}`}
                                            className="mrg-l24 mrg-t6"
                                          >
                                            {
                                              TranslationTranscripts.changedFromDiscussionContext
                                            }
                                            : {value}
                                          </p>
                                        );
                                      }

                                      if (key === 'removedKeywords') {
                                        return (
                                          <div
                                            className="flex-row align-center mrg-l24 mrg-t6"
                                            key={`removedKeywords-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}`}
                                          >
                                            <p className="mrg-r12">
                                              {
                                                TranslationTranscripts.removedKeywords
                                              }
                                              :{' '}
                                            </p>

                                            {value?.map(
                                              (v, removedKeywordIndex) => {
                                                return (
                                                  <p
                                                    className="transcript-keyword"
                                                    key={`removedKeywords-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}-${removedKeywordIndex}`}
                                                  >
                                                    {v}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }

                                      if (key === 'addedKeywords') {
                                        return (
                                          <div
                                            className="flex-row align-center mrg-l24 mrg-t6"
                                            key={`addedKeywords-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}`}
                                          >
                                            <p className="mrg-r12">
                                              {
                                                TranslationTranscripts.addedKeywords
                                              }
                                              :{' '}
                                            </p>
                                            {value?.map(
                                              (v, addedKeywordIndex) => {
                                                return (
                                                  <p
                                                    className="transcript-keyword"
                                                    key={`addedKeywords-${speakerIndex}-${updateIndex}-${entryIndex}-${updateEntryIndex}-${addedKeywordIndex}`}
                                                  >
                                                    {v}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>
                                        );
                                      }

                                      return null;
                                    }
                                  )}
                                </div>
                              );
                            })}
                          </li>
                        );
                      })
                      .reverse()}
                  </ul>
                )}
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default TranslationsTranscriptParticipants;

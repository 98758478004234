import { useEffect, useState } from 'react';

//hooks
import { useGenericModal } from '../../../../../context/GenericModalProvider';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import ProfilePersonalModal from '../../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//utils
import {
  img_hourglass,
  search_dark,
  star,
} from '../../../../../assets/icons/index';

const CommunityListAccountPersonal = ({
  profile,
  customProfileInteractionHandler,
}) => {
  //hooks
  const { accountCommunityContacts, accountCommunityContactsRequested } =
    useAccountReduxHandlers();
  const { setGenericModal } = useGenericModal();

  //language
  const { IconAlts, CommunityListAccountPersonal } = useLanguageComponents();

  //state
  const [isRequested, setIsRequested] = useState(false);
  const [isContact, setIsContact] = useState(false);

  //UI
  const [contactHoverUI, setContactHoverUI] = useState(false);

  //initialize
  useEffect(() => {
    let contactIds = [];
    accountCommunityContacts?.map((acct) => contactIds.push(acct?._id));

    if (contactIds?.includes(profile?._id)) {
      setIsContact(true);
    } else {
      setIsContact(false);
    }

    let contactRequestedIds = [];

    accountCommunityContactsRequested?.map((acct) =>
      contactRequestedIds.push(acct?._id)
    );
    if (contactRequestedIds?.includes(profile?._id)) {
      setIsRequested(true);
    } else {
      setIsRequested(false);
    }
  }, [accountCommunityContactsRequested, accountCommunityContacts]);

  function openProfileView() {
    setGenericModal(
      <ProfilePersonalModal
        closeModalFocusId={'communityAccountsLabel'}
        profileData={profile}
        customProfileInteractionHandler={customProfileInteractionHandler}
      />
    );
  }
  return (
    <div
      id={`contact-${profile?._id}`}
      className="community-contact__wrapper mrg-t12 access-ob access-o6"
      tabIndex="0"
      onFocus={() => setContactHoverUI(true)}
      onBlur={() => setContactHoverUI(false)}
      onMouseEnter={() => setContactHoverUI(true)}
      onMouseLeave={() => setContactHoverUI(false)}
      aria-label={`${profile?.personal?.prefix} ${profile?.personal?.firstName} ${profile?.personal?.lastName}. ${CommunityListAccountPersonal.openProfileView}.`}
    >
      <button
        className="community-contact__container"
        onClick={openProfileView}
      >
        <div className="flex-row full-width align-center ">
          <div className="pos-rel">
            <LoadBucketImage
              imagePath={profile?.personal?.profileImage}
              imgClassName={'community-contact--profile mrg-r6'}
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
            <div
              className={`community-contact-view ${
                contactHoverUI ? 'opacity-1' : 'opacity-0'
              }`}
            >
              <img src={search_dark} alt={IconAlts.iconArrow} />
            </div>
          </div>

          <div className="flex-column mrg-l6 full-width">
            <p className="community-contact--name">
              {`${profile?.personal?.prefix} `}
              {`${profile?.personal?.firstName} `}
              {profile?.personal?.lastName}
            </p>
            <p className="community-contact--accountType">
              {CommunityListAccountPersonal.personalAccount}
              {profile?.speaker?.speakerModeActive &&
                `, ${CommunityListAccountPersonal.speaker}`}
              {profile?.organizer?.organizerModeActive &&
                `, ${CommunityListAccountPersonal.organizer}`}
            </p>
          </div>

          {isContact && (
            <img
              src={star}
              alt={IconAlts.iconStar}
              className="filter-lightgray"
              title={CommunityListAccountPersonal.contact}
            />
          )}

          {isRequested && (
            <img
              src={img_hourglass}
              alt={IconAlts.iconStar}
              className="filter-lightgray"
              title={CommunityListAccountPersonal.contactRequested}
            />
          )}
        </div>
      </button>
    </div>
  );
};

export default CommunityListAccountPersonal;

import { useEffect, useState } from 'react';

//hooks
import { useGenericModal } from '../../../../context/GenericModalProvider';
import { useSettings } from '../../../../context/SettingsProvider';
import { useTranslationRoomSocket } from '../../../../context/sockets/main/TranslationRoomSocketProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageTranslationRoom from '../../../../language/features/useLanguageTranslationRoom';

//components
import SelectDropdown from '../../../SelectDropdown/SelectDropdown';

import TextArea from '../../../TextArea/TextArea';
import AutocorrectKeywords from '../../../AutocorrectKeywords/AutocorrectKeywords';
import Spinner from '../../../Spinner/Spinner';
import ButtonTextSimple from '../../../ButtonTextSimple/ButtonTextSimple';

//utils
import {
  cancel,
  img_examine_page,
  img_refresh_two_circular,
  save,
} from '../../../../assets/icons';
import { deepEqual } from '../../../../utils/logic/deepEqual';

const TranslationSpeakerModal = ({ currentSpeakerId }) => {
  //Hooks
  const {
    speakersData,
    roomLanguages,
    keywordsListLengthMaximum,
    updateSpeakerData,
  } = useTranslationRoomSocket();
  const { handleCloseModal } = useGenericModal();
  const { handleMobileTap, contentLanguage } = useSettings();

  //language
  const { VoiceOpts, TranslationLanguageOpts } = useLanguageData();
  const { CreateRoomSpeakersVoiceAndLanguage, CreateRoomSpeakersAutocorrect } =
    useLanguageTranslationRoom();
  const { Generic, IconAlts } = useLanguageComponents();

  //state
  const [updatedSpeaker, setUpdatedSpeaker] = useState({});
  const [defaultSpeaker, setDefaultSpeaker] = useState({});
  const [isResetting, setIsResetting] = useState(false);
  const [validUpdate, setValidUpdate] = useState(true);

  //UI state
  const [roomLanguageOpts, setRoomLanguageOpts] = useState([]);
  const [noChange, setNoChange] = useState(true);
  const [invalidUpdateWarning, setInvalidUpdateWarning] = useState(false);
  const [tapHighlightClose, setTapHighlightClose] = useState(false);

  //Initialize functions
  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }
  }, []);

  useEffect(() => {
    if (currentSpeakerId && updatedSpeaker && roomLanguageOpts) {
      let currentSpeaker = speakersData.find(
        (obj) => obj.id === currentSpeakerId
      );

      setDefaultSpeaker(currentSpeaker);
      setUpdatedSpeaker(currentSpeaker);
    }
  }, [currentSpeakerId, speakersData, roomLanguageOpts]); //set current speaker data

  useEffect(() => {
    const translationOpts = TranslationLanguageOpts;
    const filteredOpts = Object.keys(translationOpts)
      .filter((key) => roomLanguages.includes(key))
      .reduce((obj, key) => {
        obj[key] = translationOpts[key];
        return obj;
      }, {});
    setRoomLanguageOpts(filteredOpts);
  }, [contentLanguage, speakersData]); //set translated language options

  //functions
  useEffect(() => {
    if (updatedSpeaker) {
      let roomLanguages = Object.keys(roomLanguageOpts);
      if (
        updatedSpeaker?.languageOpt &&
        updatedSpeaker?.languageOpt !== 'select' &&
        updatedSpeaker?.voiceOpt &&
        updatedSpeaker?.voiceOpt !== 'select' &&
        roomLanguages.includes(updatedSpeaker?.languageOpt) &&
        updatedSpeaker?.discussionContext?.length > 25
      ) {
        setValidUpdate(true);
        setInvalidUpdateWarning(false);
      } else {
        setValidUpdate(false);
      }

      const noChangeDetected = deepEqual(defaultSpeaker, updatedSpeaker);
      setNoChange(noChangeDetected);
    }
  }, [updatedSpeaker, roomLanguageOpts]); // set participant completion statuses

  function handleLanguageOpt(key) {
    let newSpeaker = { ...updatedSpeaker };
    newSpeaker.languageOpt = key || 'select';
    setUpdatedSpeaker(newSpeaker);
  }

  function handleVoiceOpt(key) {
    let newSpeaker = { ...updatedSpeaker };
    newSpeaker.voiceOpt = key || 'select';
    setUpdatedSpeaker(newSpeaker);
  }

  function handleDiscussionContext(value) {
    let newSpeaker = { ...updatedSpeaker };
    newSpeaker.discussionContext = value;
    setUpdatedSpeaker(newSpeaker);
  }

  function handleKeywordList(newKeywordList) {
    let newSpeaker = { ...updatedSpeaker };
    newSpeaker.keywordsList = newKeywordList;
    setUpdatedSpeaker(newSpeaker);
  }

  function handleResetToDefault() {
    setIsResetting(!isResetting);
    setUpdatedSpeaker(defaultSpeaker);
  }

  function handleUpdate() {
    if (!validUpdate) {
      return setInvalidUpdateWarning(true);
    }

    //upload save
    updateSpeakerData(updatedSpeaker);

    //close modal
    handleCloseModal();
  }

  return (
    <div
      className="modal-basic__wrapper translations-modal access-ow access-o12"
      tabIndex="0"
      id="translationSpeakerModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <img
            src={img_examine_page}
            alt="img"
            style={{ height: '32px' }}
            className="mrg-r12 filter-gray"
          />
          <p className="fs18 fwsb">Participant Details</p>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob  ${
            tapHighlightClose ? 'highlight-i-lgr--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightClose(true),
                () => setTapHighlightClose(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.cancel}
        >
          <img src={cancel} alt={IconAlts.iconCancel} className="svg" />
        </button>
      </div>

      <div
        className="modal-basic__container list-style-none"
        id="translationsParticipantModal"
      >
        {!updatedSpeaker ? (
          <Spinner />
        ) : (
          <>
            <p className="fs21 fwsb mrg-auto-lr mrg-b24 mrg-t12">
              {updatedSpeaker.name}
            </p>

            <div className="flex-row space-evenly full-width">
              <ButtonTextSimple
                customWrapper={`mrg-b24 mrg-auto-lr text-darkgray ${
                  !validUpdate || noChange ? 'opacity-05' : ''
                }`}
                icon={save}
                iconAlt={IconAlts.iconReset}
                iconClass={`mrg-r12 filter-gray`}
                iconOn={true}
                text={'Save'}
                handler={handleUpdate}
              />
              <ButtonTextSimple
                customWrapper={`mrg-b24 mrg-auto-lr text-darkgray ${
                  noChange ? 'opacity-05' : ''
                }`}
                icon={img_refresh_two_circular}
                iconAlt={IconAlts.iconReset}
                iconClass={`mrg-r12 filter-gray`}
                iconOn={true}
                text={'Reset'}
                handler={handleResetToDefault}
              />
            </div>

            {!validUpdate && invalidUpdateWarning && (
              <div className="banners__notice fs18 mrg-b24">
                <p className="flex-center full-width">
                  Please ensure the participant has a language and voice
                  selected. Also the discussion context must be at least 25
                  characters in length.
                </p>
              </div>
            )}

            <SelectDropdown
              id={'speakerLanguage'}
              list={roomLanguageOpts}
              handleSelection={handleLanguageOpt}
              internalLabel={
                CreateRoomSpeakersVoiceAndLanguage.participantsLanguage
              }
              asterisk={true}
              defaultKey={
                updatedSpeaker?.languageOpt !== 'select'
                  ? updatedSpeaker?.languageOpt
                  : 'select'
              }
              fullWidth={true}
              autoUpdate={true}
            />

            <SelectDropdown
              autoUpdate={true}
              id={'voiceOptions'}
              list={VoiceOpts}
              handleSelection={handleVoiceOpt}
              internalLabel={CreateRoomSpeakersVoiceAndLanguage.voiceSelection}
              defaultKey={
                updatedSpeaker?.voiceOpt !== 'select'
                  ? updatedSpeaker?.voiceOpt
                  : 'select'
              }
              asterisk={true}
              tempDisable={
                !updatedSpeaker?.languageOpt ||
                updatedSpeaker?.languageOpt === 'select'
              }
              temporaryDisableMessage={'speakerLanguageSelectionRequired'}
              customWrapper={`mrg-tb24`}
              fullWidth={true}
            />

            <TextArea
              handleChange={handleDiscussionContext}
              labelText={CreateRoomSpeakersAutocorrect.discussionContext}
              large={true}
              limit={250}
              reload={isResetting || !isResetting}
              loadValue={updatedSpeaker?.discussionContext}
              asterisk={true}
              onBlurHandlerOff={true}
              disableEnter={true}
              customWrapper={'mrg-b24'}
              autoUpdate={true}
            />

            <AutocorrectKeywords
              limit={keywordsListLengthMaximum}
              index={0}
              speakersKeywordsList={updatedSpeaker.keywordsList}
              setSpeakersKeywordsList={handleKeywordList}
              autodetectOff={true}
            />
            <div className="h48" />
          </>
        )}
      </div>
    </div>
  );
};

export default TranslationSpeakerModal;

import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../../context/SettingsProvider';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';

//Components
import SettingsEvents from '../settings/Events/SettingsEvents';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import BigTagsGrid from './EventsGrid/EventsGridViews/BigTagsGrid';
import TinyTagsGrid from './EventsGrid/EventsGridViews/TinyTagsGrid';
import CommunityGrid from './EventsGrid/EventsGridViews/CommunityGrid';
import MyEventsGrid from './EventsGrid/EventsGridViews/MyEventsGrid';

const Events = () => {
  //Hooks
  const { accountId } = useAccountReduxHandlers();
  const { cards, setNavbar, settingsViewEventDetails } = useSettings();
  const {
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
  } = useSettingsHandlers();

  //Component state
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [hasViewChangedToMyEvents, setHasViewChangedToMyEvents] =
    useState(false);

  //Initialize functions
  useEffect(() => {
    setNavbar('events');
    /*eslint-disable-next-line*/
  }, []); //sets navbar

  useEffect(() => {
    if (hasViewChangedToMyEvents !== 'myEvents') {
      setHasViewChangedToMyEvents(false);
    }
    if (view !== 'myEvents') {
      setHasViewChangedToMyEvents(true);
    }
  }, [view]); //dont want to refresh the entire my events list when the user is managing saves/ignores on the myEvents page; but if the user is switching to the myEvents page, should update with latest myEvents state

  return (
    <>
      {eventPageLoadComplete ? (
        <div className="events">
          {!settingsViewEventDetails && <SettingsEvents />}
          {view === 'bigtags' && <BigTagsGrid />}
          {view === 'tinytags' && <TinyTagsGrid />}
          {view === 'community' && <CommunityGrid />}
          {view === 'myevents' && (
            <MyEventsGrid hasViewChangedToMyEvents={hasViewChangedToMyEvents} />
          )}
        </div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={3}
          setLoadComplete={() => setEventPageLoadComplete(true)}
          loadCheck1={accountId ? true : false}
          loadCheck2={cards ? true : false}
          loadCheck3={
            searchDirection && searchCategory && searchFormat && view
              ? true
              : false
          }
          customWrapper={'min-full-height'}
        />
      )}
    </>
  );
};

export default Events;

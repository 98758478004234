import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useTranslationsHandlers } from '../../../useTranslationsHandlers';
import { useApiStatus } from '../../../../../../context/ApiStatusProvider';

//language
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';

//utility
import { image_transcripts } from '../../../../../../assets/images';
import { img_empty_box, trash } from '../../../../../../assets/icons/index';

const TranslationsTranscriptsRemove = () => {
  const {
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    translationsTranscripts,
  } = useTranslationRoomSocket();
  const { handleRemoveTranscripts } = useTranslationsHandlers();
  const { handleError } = useApiStatus();

  //language
  const { TranslationTranscripts } = useLanguageTranslationRoom();
  const { IconAlts } = useLanguageComponents();

  //state
  const [removeTranscriptIds, setRemoveTranscriptIds] = useState([]);
  const [removeTranscriptsIsLoading, setRemoveTranscriptsIsLoading] =
    useState(false);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  async function removeTranscripts() {
    if (!removeTranscriptsIsLoading) {
      setRemoveTranscriptsIsLoading(true);
      try {
        const success = await handleRemoveTranscripts({
          removeTranscriptIds,
        });
        setRemoveTranscriptsIsLoading(false);

        if (success) {
          handleCreatePageChange('transcripts');
        }
      } catch (error) {
        handleError({
          id: Date.now(),
          origin: 'TranslationsTranscriptsRemove/removeTranscripts',
          error,
        });
        return setRemoveTranscriptsIsLoading(false);
      }
    }
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_transcripts}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          {translationsTranscripts?.length === 0 ? (
            <>
              <p
                className="fs21 fwsb mrg-tb24 access-ob access-o6"
                tabIndex="0"
              >
                {TranslationTranscripts.noTranscriptsSaved}
              </p>
              <img
                src={img_empty_box}
                style={{ height: '64px' }}
                alt={IconAlts.iconEmptyBox}
                className="svg filter-gray"
              />
            </>
          ) : (
            <>
              <p
                className="fs21 fwsb mrg-tb24 access-ob access-o6"
                tabIndex="0"
              >
                {TranslationTranscripts.removeTranscripts}
              </p>
              <ul className="multi-selection-list">
                {translationsTranscripts?.map((transcript, key) => {
                  return (
                    <li key={`translationsSessions-${key}`}>
                      <button
                        className={`multi-selection-item fs18 ${
                          removeTranscriptIds.includes(transcript._id)
                            ? 'multi-selection-item--selected'
                            : ''
                        }`}
                        onClick={() => {
                          let newArr = [];
                          if (removeTranscriptIds.includes(transcript._id)) {
                            newArr = removeTranscriptIds.filter(
                              (id) => id !== transcript._id
                            );
                          } else {
                            newArr = [...removeTranscriptIds, transcript._id];
                          }
                          setRemoveTranscriptIds(newArr);
                        }}
                      >
                        <div className="flex-column">
                          {transcript?.meta?.saveName}
                        </div>
                        {removeTranscriptIds.includes(transcript._id) && (
                          <img
                            alt={IconAlts.iconTrash}
                            src={trash}
                            className="filter-red mrg-auto-left"
                          />
                        )}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </>
          )}

          <div className="divider full-width mrg-tb36" />
          <ItemButton
            text={`${TranslationTranscripts.removeSelectedTranscripts} (${removeTranscriptIds?.length})`}
            handler={removeTranscripts}
            fullWidth={true}
            customWrapper={'mrg-b24'}
            unavailable={removeTranscriptIds?.length === 0}
          />

          <ItemButton
            text={TranslationTranscripts.back}
            handler={() => handleCreatePageChange('transcripts')}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
};

export default TranslationsTranscriptsRemove;

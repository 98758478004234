import { useEffect, useState } from 'react';

//utility
import ScrollTo from '../../../utils/UI/ScrollTo';

const AdminSupportNav = ({ view, setView }) => {
  //hooks

  //state
  const [repositionSelectionsContainer, setRepositionSelectionsContainer] =
    useState(false);

  //initialize
  useEffect(() => {
    ScrollTo({ scrollToTop: true });
  }, []);

  //adjust selections container so it fills up navbar space when you scroll down
  window.addEventListener('scroll', function () {
    let distanceScrolled = window.scrollY;
    if (distanceScrolled > 10) {
      setRepositionSelectionsContainer(true);
    } else {
      setRepositionSelectionsContainer(false);
    }
  });

  return (
    <div role="navigation" className="nav-menu__wrapper">
      <div className="h12" />
      <ul
        className="nav-menu__container access-ob access-o-6"
        style={repositionSelectionsContainer ? { top: '0px' } : null}
        tabIndex="0"
      >
        <li>
          <button
            className={`primary-heading fs21 fwsb access-ob access-o-6
              ${view === 'workspace' ? ' selected' : ''}`}
            onClick={() => setView('workspace')}
            tabIndex="0"
          >
            Workspace
          </button>
        </li>
      </ul>
    </div>
  );
};

export default AdminSupportNav;

import { useState, useEffect } from 'react';

//Hooks
import useLanguageAdmin from '../../../language/features/useLanguageAdmin';
import { useAdminLoginMutation } from '../../admin/auth/adminAuthApiSlice';
import { useSettings } from '../../../context/SettingsProvider';
import { useAdminAuthReduxHandlers } from './useAdminAuthHandlers';
import useReCaptcha from '../../../hooks/useReCaptcha';

//Components
import Spinner from '../../../components/Spinner/Spinner';

const AdminLogin = () => {
  //Hooks
  const { adminAuthCurrentToken, handleAdminGetMyAccount } =
    useAdminAuthReduxHandlers();
  const [adminLogin, { isSuccess: adminLoginisSuccess }] =
    useAdminLoginMutation();
  const { setNavbar, width, dismissKeypad } = useSettings();
  const { Auth } = useLanguageAdmin();
  const { handleReCaptchaVerify } = useReCaptcha();

  //Component state
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //Initialize & UI functions
  useEffect(() => {
    setNavbar('login');
  }, []);

  //API functions
  async function handleAdminLogin(e) {
    e.preventDefault();
    dismissKeypad(e);
    setIsLoading(true);
    const recaptchaVerified = await handleReCaptchaVerify('ADMIN_LOGIN', false);

    if (recaptchaVerified !== true) return false;

    await adminLogin({ email, pwd });

    setPwd('');
    setIsLoading(false);
  }

  //Component functions
  useEffect(() => {
    if (adminLoginisSuccess && adminAuthCurrentToken) {
      handleAdminGetMyAccount();
    }
  }, [adminLoginisSuccess, adminAuthCurrentToken]);

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      dismissKeypad(e);
      handleAdminLogin(e);
    }
  }

  return (
    <>
      <div className="layout">
        <div className="login bg-blur">
          <div
            className="form-template access-ob access-o-6"
            tabIndex="0"
            aria-label={Auth.signInWithEmail}
          >
            <h1 className="content-label mrg-auto-left mrg-auto-right mrg-t12--ph">
              Administrative Login
            </h1>
            <div className="h36-ph24" />
            <form
              onSubmit={(e) => handleAdminLogin(e)}
              aria-label={Auth.signIn}
            >
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="off"
                placeholder={Auth.emailAddress}
                required
                className="login-input fs21"
                tabIndex="0"
                maxLength="255"
                onKeyDown={(e) => handleKeyDown(e)}
                enterKeyHint="next"
              />
              <div className="h24" />
              <input
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                placeholder={Auth.password}
                autoComplete="on"
                required
                className="login-input fs21"
                tabIndex="0"
                maxLength="255"
                onKeyDown={(e) => handleKeyDown(e)}
                enterKeyHint="enter"
              />
              <div className="h24" />

              {isLoading ? (
                <Spinner maxHeight={width > 480 ? '64px' : '54px'} />
              ) : (
                <div className="login-btn__wrapper">
                  <button
                    className="login-btn fs24 fwn color-white ff2 access-ob access-o6"
                    id="signin"
                    tabIndex="0"
                  >
                    {Auth.signIn}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminLogin;

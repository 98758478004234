import { useState } from 'react';

//language
import useLanguageComponents from '../../../language/useLanguageComponents';

//utils
import {
  checkmark,
  facebook,
  globe2,
  instagram,
  linkedin,
  x_twitter,
} from '../../../assets/icons';

const ProfileSocials = ({ ProfilePersonal, profile }) => {
  //language
  const { IconAlts } = useLanguageComponents();

  //state
  const [selectedLink, setSelectedLink] = useState('');

  return (
    <>
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="tagsLabel"
      >
        {ProfilePersonal.socialLinks}
      </label>
      <div className="h12" />
      <ul className="social-media-links full-width">
        <li>
          <button
            onClick={() => {
              if (selectedLink === 'personalWebsite') {
                setSelectedLink();
              } else {
                setSelectedLink('personalWebsite');
              }
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'personalWebsite'}
          >
            <img
              src={globe2}
              alt={IconAlts.iconGlobe}
              className={`social-media-logo
                ${
                  profile?.personal?.links?.personalWebsite
                    ? 'filter-theme'
                    : 'filter-lightgray'
                }`}
              title={ProfilePersonal.personalWebsite}
              style={{ height: '44px' }}
            />

            {selectedLink === 'personalWebsite' ? (
              <div className="highlight" />
            ) : profile?.personal?.links?.personalWebsite?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              if (selectedLink === 'linkedIn') {
                setSelectedLink();
              } else {
                setSelectedLink('linkedIn');
              }
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'linkedIn'}
          >
            <img
              src={linkedin}
              alt="linkedin"
              className={`social-media-logo
                  ${
                    profile?.personal?.links?.linkedIn
                      ? 'filter-theme'
                      : 'filter-lightgray'
                  }`}
              title="LinkedIn"
              style={{ height: '44px' }}
            />
            {selectedLink === 'linkedIn' ? (
              <div className="highlight" />
            ) : profile?.personal?.links?.linkedIn?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              if (selectedLink === 'x') {
                setSelectedLink();
              } else {
                setSelectedLink('x');
              }
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'x'}
          >
            <img
              src={x_twitter}
              alt="x"
              className={`social-media-logo
                  ${
                    profile?.personal?.links?.x
                      ? 'filter-theme'
                      : 'filter-lightgray'
                  }`}
              title="X"
              style={{ height: '44px' }}
            />

            {selectedLink === 'x' ? (
              <div className="highlight" />
            ) : profile?.personal?.links?.x?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              if (selectedLink === 'instagram') {
                setSelectedLink();
              } else {
                setSelectedLink('instagram');
              }
            }}
            className="social-media-btn access-ob access-o6"
            aria-pressed={selectedLink === 'instagram'}
          >
            <img
              src={instagram}
              alt="instagram"
              className={`social-media-logo
                  ${
                    profile?.personal?.links?.instagram
                      ? 'filter-theme'
                      : 'filter-lightgray'
                  }`}
              title="Instagram"
              style={{ height: '44px' }}
            />

            {selectedLink === 'instagram' ? (
              <div className="highlight" />
            ) : profile?.personal?.links?.instagram?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              if (selectedLink === 'facebook') {
                setSelectedLink();
              } else {
                setSelectedLink('facebook');
              }
            }}
            className="access-ob access-o6"
            aria-pressed={selectedLink === 'facebook'}
          >
            <img
              src={facebook}
              alt="facebook"
              className={`social-media-logo
                  ${
                    profile?.personal?.links?.facebook
                      ? 'filter-theme'
                      : 'filter-lightgray'
                  }`}
              title="Facebook"
              style={{ height: '44px' }}
            />

            {selectedLink === 'facebook' ? (
              <div className="highlight" />
            ) : profile?.personal?.links?.facebook?.length > 0 ? (
              <img
                src={checkmark}
                alt={IconAlts.checkmark}
                className={'social-media-filled-marker'}
              />
            ) : (
              <div className="highlight-placeholder" />
            )}
          </button>
        </li>
      </ul>

      {!selectedLink ? (
        <div className="account-profile-social-link--ghost mrg-t24 access-ob access-o6" />
      ) : selectedLink === 'personalWebsite' ? (
        <div
          className="account-profile-social-link full-width text-center mrg-t24 access-ob access-o6"
          tabIndex="0"
        >
          <p className="fs18">{profile?.personal?.links?.personalWebsite}</p>
        </div>
      ) : selectedLink === 'linkedIn' ? (
        <div
          className="account-profile-social-link full-width text-center mrg-t24 access-ob access-o6"
          tabIndex="0"
        >
          <p className="fs18">{profile?.personal?.links?.linkedIn}</p>
        </div>
      ) : selectedLink === 'x' ? (
        <div
          className="account-profile-social-link full-width text-center mrg-t24 access-ob access-o6"
          tabIndex="0"
        >
          <p className="fs18">{profile?.personal?.links?.x}</p>
        </div>
      ) : selectedLink === 'instagram' ? (
        <div
          className="account-profile-social-link full-width text-center mrg-t24 access-ob access-o6"
          tabIndex="0"
        >
          <p className="fs18">{profile?.personal?.links?.instagram}</p>
        </div>
      ) : (
        selectedLink === 'facebook' && (
          <div
            className="account-profile-social-link full-width text-center mrg-t24 access-ob access-o6"
            tabIndex="0"
          >
            <p className="fs18">{profile?.personal?.links?.facebook}</p>
          </div>
        )
      )}
    </>
  );
};

export default ProfileSocials;

import { useRef, useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import { exchange } from '../../assets/icons';

const DistanceInput = ({
  distanceFilter,
  setDistanceFilter,
  distanceMeasurement,
  setDistanceMeasurement,
}) => {
  //Hooks
  const { handleMobileTap, dismissKeypad, width } = useSettings();

  //language
  const { DistanceInput } = useLanguageComponents();

  //ui
  const [tapHighlightSwitchDistance, setTapHighlightSwitchDistance] =
    useState(false);
  const [tapHighlightIncrement, setTapHighlightIncrement] = useState(false);
  const [tapHighlightDecrement, setTapHighlightDecrement] = useState(false);

  //Component variables
  const inputRef = useRef(null);

  //Component functions
  function selectText() {
    inputRef.current.focus();
    inputRef.current.select();
  }

  function handleAddDistanceFilter() {
    let newNum = Number(distanceFilter + 1);
    if (newNum > 999) {
      return setDistanceFilter(999);
    }
    setDistanceFilter(newNum);
  }

  function handleSubtractDistanceFilter() {
    if (distanceFilter > 1) {
      let newNum = Number(distanceFilter - 1);
      setDistanceFilter(newNum);
    }
  }

  function convertDistanceFilter() {
    if (distanceMeasurement === 'km') {
      const distanceInMeters = distanceFilter * 1000;
      let newDistance = distanceInMeters / 1609.344;
      newDistance = Number(newDistance.toFixed(1));
      if (newDistance > 999) {
        newDistance = 999;
      }
      setDistanceFilter(newDistance);
      setDistanceMeasurement('mi');
    } else {
      const distanceInMeters = distanceFilter * 1609.344;
      let newDistance = distanceInMeters / 1000;
      newDistance = Number(newDistance.toFixed(1));
      if (newDistance > 999) {
        newDistance = 999;
      }
      setDistanceFilter(newDistance);
      setDistanceMeasurement('km');
    }
  }

  function handleDistanceFilterChange(e) {
    let value = inputRef.current.value;
    let numVal = Number(value);
    if (value === '' || isNaN(value)) {
      setDistanceFilter(0);
    } else if (numVal > 999) {
      setDistanceFilter(999);
    } else {
      if (distanceMeasurement === 'km') {
        setDistanceFilter(numVal);
      } else {
        setDistanceFilter(numVal);
      }
    }
  }

  return (
    <>
      {distanceMeasurement === 'km' ? (
        <button
          className={`switch-option__container highlight-i-theme color-gray fs18 fwn access-ob access-o6 ${
            tapHighlightSwitchDistance ? 'highlight-i-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightSwitchDistance(true),
                () => setTapHighlightSwitchDistance(false),
              ],
              [() => convertDistanceFilter()]
            )
          }
        >
          <img
            aria-hidden="true"
            src={exchange}
            alt={DistanceInput.switch}
            className={`filter-lightgray mrg-r8`}
          />
          {DistanceInput.switchToMiles}
        </button>
      ) : (
        <button
          className={`switch-option__container color-gray fs18 fwn access-ob access-o6 ${
            tapHighlightSwitchDistance ? 'highlight-i-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightSwitchDistance(true),
                () => setTapHighlightSwitchDistance(false),
              ],
              [() => convertDistanceFilter()]
            )
          }
        >
          <img
            aria-hidden="true"
            src={exchange}
            alt={DistanceInput.switch}
            className={`filter-lightgray mrg-r8`}
          />
          {DistanceInput.switchToKilometers}
        </button>
      )}
      <div className="h24" />
      <div className="counter__wrapper">
        <button
          className={`counter-button fwsb fs21 highlight-bthin-theme highlight-it-theme access-ob access-o6 ${
            tapHighlightDecrement ? 'highlight-tb-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightDecrement(true),
                () => setTapHighlightDecrement(false),
              ],
              [() => handleSubtractDistanceFilter()]
            )
          }
          aria-label={DistanceInput.ariaDecrease}
          tabIndex="0"
        >
          -
        </button>
        <div className="count__wrapper">
          <input
            className={`count__container count__container--distance highlight-o-theme fs18
            `}
            style={{
              paddingRight: `${width > 480 ? '52px' : '0%'}`,
            }}
            id={'distanceInput'}
            onChange={(e) => handleDistanceFilterChange(e)}
            onClick={() => selectText()}
            onKeyDown={(e) => dismissKeypad(e)}
            ref={inputRef}
            type="number"
            value={distanceFilter ? distanceFilter : ''}
            onBlur={!distanceFilter ? setDistanceFilter(1) : null}
            enterKeyHint="done"
          />
          {distanceMeasurement && (
            <p className="unit no-select">{distanceMeasurement}</p>
          )}
        </div>
        <button
          className={`counter-button fwsb fs21 highlight-it-theme highlight-bthin-theme access-ob access-o6 ${
            tapHighlightIncrement ? 'highlight-tb-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightIncrement(true),
                () => setTapHighlightIncrement(false),
              ],
              [() => handleAddDistanceFilter()]
            )
          }
          aria-label={DistanceInput.ariaIncrease}
          tabIndex="0"
        >
          +
        </button>
      </div>
    </>
  );
};

export default DistanceInput;

import { useEffect, useState } from 'react';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import useLanguageData from '../../../../language/useLanguageData';

//Utility
import {
  dropdown_arrow,
  folder,
  social,
  warning,
} from '../../../../assets/icons';

const DemoTagsToggle = ({
  view,
  settingsListOpened,
  setSettingsListOpened,
  demoBigTags,
  demoTinyTags,
  demoTinyTagGroups,
  demoCommunityFollowing,
  demoCommunityFollowingGroups,
  //
  selectedMyEvents,
  selectedBigTags,
  selectedTinyTags,
  selectedTinyTagGroupIds,
  selectedFollowingIds,
  selectedFollowingGroupIds,
}) => {
  //lanugage
  const { IconAlts } = useLanguageComponents();
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { BigTagIcon, TinyTagIcon } = useLanguageData();

  //Component state
  const [viewSelectionHeading, setViewSelectionHeading] = useState();

  //Component variables
  let tagsToggle;

  useEffect(() => {
    if (view === 'myevents') {
      setViewSelectionHeading(`${SettingsEventsLanguage.myEvents}`);
    }
    if (view === 'bigtags') {
      setViewSelectionHeading(`${SettingsEventsLanguage.bigTags}`);
    }
    if (view === 'tinytags') {
      setViewSelectionHeading(`${SettingsEventsLanguage.tinyTags}`);
    }
    if (view === 'community') {
      setViewSelectionHeading(`${SettingsEventsLanguage.communityEvents}`);
    }
  }, [view]); //sets translated viewSelectionHeading

  //JSX conditional
  if (view === 'myevents') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() => setSettingsListOpened(!settingsListOpened)}
        aria-expanded={settingsListOpened}
        aria-label={`${SettingsEventsLanguage.myEvents}. ${
          SettingsEventsLanguage.ariaCurrentSelections
        }. ${
          selectedMyEvents?.length === 0
            ? SettingsEventsLanguage.ariaNoSelectionsActive
            : ''
        } ${
          selectedMyEvents.includes('saved') ? SettingsEventsLanguage.saved : ''
        } ${
          selectedMyEvents.includes('registered')
            ? SettingsEventsLanguage.saved
            : ''
        } ${
          selectedMyEvents.includes('participationEvents')
            ? SettingsEventsLanguage.saved
            : ''
        }.`}
      >
        <div className="tags-toggle-btn align-center">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>

        {!settingsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            {selectedMyEvents.includes('saved') && (
              <p
                className="fs18 fwsb "
                aria-label={
                  SettingsEventsLanguage.ariaMyEventsSelectionSavedEvents
                }
              >
                {SettingsEventsLanguage.saved}
                {selectedMyEvents?.length > 1 && `,`}&nbsp;
              </p>
            )}
            {selectedMyEvents.includes('registered') && (
              <p
                className="fs18 fwsb "
                aria-label={
                  SettingsEventsLanguage.ariaMyEventsSelectionRegisteredEvents
                }
              >
                {SettingsEventsLanguage.registered}
                {selectedMyEvents.includes('participationEvents') && `,`}
                &nbsp;
              </p>
            )}
            {selectedMyEvents.includes('participationEvents') && (
              <p
                className="fs18 fwsb "
                aria-label={
                  SettingsEventsLanguage.ariaSelectionParticipationEvents
                }
              >
                {SettingsEventsLanguage.participation}
              </p>
            )}
            {selectedMyEvents?.length === 0 && (
              <>
                <img
                  src={warning}
                  alt={IconAlts.iconWarning}
                  style={{ height: '24px' }}
                  className="mrg-r12"
                />
                <p className="fs18 fwsb">
                  {SettingsEventsLanguage.noEventsSelected}
                </p>
              </>
            )}
          </>
        )}
      </button>
    );
  } else if (view === 'bigtags') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() => setSettingsListOpened(!settingsListOpened)}
        tabIndex="0"
        aria-expanded={settingsListOpened}
        aria-label={`${SettingsEventsLanguage.ariaBigTagsSelectionToggle}. ${SettingsEventsLanguage.ariaBigTagsSelectionSummary} ${selectedBigTags?.length}`}
      >
        <div className="tags-toggle-btn align-center">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>
        {!settingsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div className="tags-sum fs21">
              <img
                src={BigTagIcon}
                alt={IconAlts.iconBigTag}
                style={{ height: '22px' }}
                className="mrg-r12"
              />
              <p className="color-theme fwsb pad-r6">
                {selectedBigTags?.length}
                {selectedBigTags?.length < demoBigTags?.length &&
                  `/${demoBigTags?.length}`}
              </p>
            </div>
          </>
        )}
      </button>
    );
  } else if (view === 'tinytags') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() => setSettingsListOpened(!settingsListOpened)}
        tabIndex="0"
        aria-expanded={settingsListOpened}
        aria-label={`${SettingsEventsLanguage.ariaTinyTagsSelectionToggle}. ${
          SettingsEventsLanguage.ariaTinyTagsSelectionSummaryFolders +
          selectedTinyTagGroupIds?.length
        }.`}
      >
        <div className="tags-toggle-btn align-center">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>
        {!settingsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div className="flex-row">
              <div className="tags-sum fs21 fwsb">
                <img
                  src={folder}
                  alt={IconAlts.iconTinyTagGroup}
                  className="filter-theme mrg-l12"
                  aria-hidden="true"
                />
                <p className="color-theme mrg-l12">
                  {selectedTinyTagGroupIds?.length}
                  {selectedTinyTagGroupIds?.length <
                    demoTinyTagGroups?.length &&
                    `/${demoTinyTagGroups?.length}`}
                </p>
              </div>
              <div className="tags-sum fs21 fwsb">
                <img
                  src={TinyTagIcon}
                  alt={IconAlts.tinyTags}
                  aria-hidden="true"
                  style={{ height: '30px' }}
                  className="mrg-lr12"
                />
                <p className="color-theme pad-r12">
                  {selectedTinyTags?.length}
                  {selectedTinyTags?.length < demoTinyTags?.length &&
                    `/${demoTinyTags?.length}`}
                </p>
              </div>
            </div>
          </>
        )}
      </button>
    );
  } else if (view === 'community') {
    tagsToggle = (
      <button
        className="flex-row flex-center color-gray fs18 fwsb access-ob access-o6"
        onClick={() => setSettingsListOpened(!settingsListOpened)}
        tabIndex="0"
        aria-expanded={settingsListOpened}
        aria-label={`${SettingsEventsLanguage.ariaCommunitySelectionToggle}. ${
          SettingsEventsLanguage.ariaCommunitySelectionSummaryFolders +
          selectedFollowingGroupIds?.length
        }.`}
      >
        <div className="tags-toggle-btn align-center ">
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </div>
        {!settingsListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <div className="flex-row">
              <div className="tags-sum fs21 fwsb">
                <img
                  src={folder}
                  alt={IconAlts.iconCommunity}
                  className="filter-theme mrg-l12"
                  aria-hidden="true"
                />
                <p className="color-theme mrg-l12">
                  {selectedFollowingGroupIds?.length}
                  {selectedFollowingGroupIds?.length <
                    demoCommunityFollowingGroups?.length &&
                    `/${demoCommunityFollowingGroups?.length}`}
                </p>
              </div>
              <div
                className="tags-sum fs21 fwsb access-ob"
                tabIndex="0"
                aria-label={`${SettingsEventsLanguage.ariaCommunitySelectionSummary} ${selectedFollowingIds?.length}.`}
              >
                <img src={social} alt={IconAlts.iconCommunity} />
                <p className="color-theme fwsb pad-r6">
                  {selectedFollowingIds?.length}
                  {selectedFollowingIds?.length <
                    demoCommunityFollowing?.length &&
                    `/${demoCommunityFollowing?.length}`}
                </p>
              </div>
            </div>
          </>
        )}
      </button>
    );
  }

  return tagsToggle;
};
export default DemoTagsToggle;

//hooks
import { useNoticeHandlers } from '../../../useNoticeHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import Spinner from '../../../../../../components/Spinner/Spinner';

//utils

const NoticeResponse = ({ n, openedNotice, setOpenedNotice }) => {
  //hooks
  const { removeNoticeIsLoading, archiveNoticeIsLoading } = useNoticeHandlers();

  //language
  const { NoticeSpeakerComponents } = useLanguageComponents();
  return (
    <>
      {removeNoticeIsLoading || archiveNoticeIsLoading ? (
        <Spinner minHeight={'44px'} />
      ) : (
        <>
          {openedNotice === n._id && (
            <div className="noticeOverlay-action-btns__container mrg-t12">
              {n?.noticeHistory && (
                <>
                  {n?.noticeHistory === 'accepted' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.accepted}
                    </p>
                  )}
                  {n?.noticeHistory === 'rejected' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.rejected}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NoticeResponse;

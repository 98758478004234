import { useEffect, useRef, useState } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';

//Components
import EventCard from '../EventCard/EventCard';
import Spinner from '../../../../components/Spinner/Spinner';
import NoMoreCard from './NoMoreCard';
import { GhostCards } from './EventSkeletons';
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import EventsLoadMore from './EventsLoadMore';
import LoadingScreenContent from '../../../../components/LoadingScreenContent/LoadingScreenContent';
import EventsSearchEmpty from './EventsSearchEmpty';

//utils
import ScrollTo from '../../../../utils/UI/ScrollTo';

//will only show a row if theres enough cards to fill up a full row, dictated by the cards variable
const EventsGrid = ({
  events,
  isLoading,
  cards,
  isUninitialized,
  nextPageFunction,
  hasMore,
  tagsCheck,
  eventsGridType,
  focusId,
  setFocusId,
  returnFromEventPageComplete,
  setReturnFromEventPageComplete,
}) => {
  //Hooks
  const { setSettingsSetViewEventDetails, width } = useSettings();

  //Component state
  const [fullRows, setFullRows] = useState();
  const [fullRowEvents, setFullRowEvents] = useState();
  const [remainingEvents, setRemainingEvents] = useState();

  //ui
  const [initialized, setInitialized] = useState(false);
  const [rowsRendered, setRowsRendered] = useState(0);
  const [loadMoreDelayRenderIsComplete, setLoadMoreDelayRenderIsComplete] =
    useState(true);

  const observerRef = useRef();
  const loadMoreRef = useRef(null);
  const returnedFromEventPageHasRenderedRef = useRef(false);

  //initialize
  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitialized(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (!loadMoreRef.current || !hasMore || !initialized) return;

    observerRef.current = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (!isLoading) {
            nextPageFunction();
          }
        }
      },
      { threshold: 0.2 }
    );

    observerRef.current.observe(loadMoreRef.current);
    return () => observerRef.current?.disconnect();
  }, [nextPageFunction, hasMore, initialized, loadMoreRef.current]); //create loadMoreRef observer

  //UI functions
  useEffect(() => {
    if (events?.length > 0) {
      const totalFullRows = Math.floor(events.length / cards);
      setFullRows(totalFullRows);

      const fullEvents = events.slice(0, totalFullRows * cards);
      const remaining = events.slice(totalFullRows * cards);

      setFullRowEvents(fullEvents);
      setRemainingEvents(remaining);
    } else {
      setRemainingEvents([]);
      setFullRowEvents();
    }
  }, [events, cards]); //calculate full card rows + remaining cards

  useEffect(() => {
    //faster load role, client should have images already.
    if (focusId && !returnFromEventPageComplete) {
      const totalFullRows = Math.floor(events.length / cards);
      return setRowsRendered(totalFullRows);
    }

    //load event cards row by row
    if (rowsRendered < fullRows) {
      const timeout = setTimeout(() => {
        setRowsRendered((prev) => prev + 1);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, [rowsRendered, fullRows, focusId, returnFromEventPageComplete]); //rowsRendered settings

  useEffect(() => {
    if (focusId) {
      setReturnFromEventPageComplete(false);
    }
  }, [focusId]); //state used for UI after returning from event page

  useEffect(() => {
    if (isLoading) {
      setLoadMoreDelayRenderIsComplete(false);
      const timer = setTimeout(() => {
        setLoadMoreDelayRenderIsComplete(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]); //provides delay between rendering loadMore component

  useEffect(() => {
    if (focusId && returnFromEventPageComplete) {
      const timer = setTimeout(() => {
        setFocusId();
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [returnFromEventPageComplete]); //give a short delay to provide the loadingScreenContent a chance to show progress bar complete.

  useEffect(() => {
    if (returnedFromEventPageHasRenderedRef.current === true) {
      setReturnFromEventPageComplete(true);
      returnedFromEventPageHasRenderedRef.current = false;
    }
  }, [returnedFromEventPageHasRenderedRef]);

  return (
    <>
      {focusId && (
        <div className="events-grid-loading-overlay">
          <LoadingScreenContent
            numberOfLoadChecks={3}
            setLoadComplete={() => {
              setReturnFromEventPageComplete(true);
              ScrollTo({
                focusId,
                scrollToElementId: focusId,
                behavior: 'auto',
              });
            }}
            loadCheck1={true}
            loadCheck2={focusId ? true : false}
            loadCheck3={returnFromEventPageComplete ? true : false}
            customWrapper={`${width > 480 ? 'mrg-t128' : 'mrg-t48'}`}
          />
        </div>
      )}

      {setReturnFromEventPageComplete && (
        <div
          className={`events-grid__wrapper ${
            returnFromEventPageComplete ? '' : 'opacity-0'
          }`}
          key={`${eventsGridType}-grid`}
        >
          {events?.length === 0 &&
            !isUninitialized &&
            !hasMore &&
            !isLoading && <EventsSearchEmpty isLoading={isLoading} />}

          <article className="events-grid mrg-t24" id="events-grid">
            {events?.length > 0 &&
              fullRowEvents
                ?.slice(0, rowsRendered * cards)
                ?.map((event, index) => {
                  if (focusId === event.meta.eventReference) {
                    returnedFromEventPageHasRenderedRef.current = true;
                  } //used for UI, returning from event page, ensures that card is rendered so then the page can focus on it
                  return (
                    <EventCard
                      eventData={event}
                      key={`eventcard-${index}-${event?._id}`}
                      setViewEvent={setSettingsSetViewEventDetails}
                    />
                  );
                })}

            <div
              ref={loadMoreRef}
              style={{
                height: '1px',
                minWidth: '100%',
                position: 'absolute',
                bottom: '600px',
                opacity: '0',
              }}
            />

            {events?.length > 0 && !hasMore && remainingEvents?.length >= 0 && (
              <>
                {remainingEvents?.map((event, index) => {
                  if (focusId === event.meta.eventReference) {
                    returnedFromEventPageHasRenderedRef.current = true;
                  } //used for UI, returning from event page

                  return (
                    <EventCard
                      eventData={event}
                      key={`eventcard-remaining-${index}-${event?._id}`}
                      setViewEvent={setSettingsSetViewEventDetails}
                    />
                  );
                })}
                <NoMoreCard
                  fadeInAnimation={true}
                  noMoreCardKey={`eventsGrid-${eventsGridType}`}
                />
                {GhostCards(remainingEvents.length, cards)}
              </>
            )}
          </article>

          {rowsRendered >= 2 &&
          hasMore &&
          tagsCheck > 0 &&
          loadMoreDelayRenderIsComplete ? (
            <EventsLoadMore />
          ) : (
            rowsRendered >= 2 &&
            initialized &&
            hasMore &&
            events?.length > 0 &&
            isLoading && <Spinner />
          )}

          {initialized && !hasMore && events?.length > cards * 2 ? (
            <ScrollToTop
              large={true}
              focusId={'view-myevents'}
              customWrapper={'mrg-tb48'}
            />
          ) : (
            <div className="h96" />
          )}
        </div>
      )}
    </>
  );
};

export default EventsGrid;

import { useEffect, useRef, useState } from 'react';

//Hooks
import useAdminTechnicalHandlers from './useAdminTechnicalHandlers';
import { useSettings } from '../../../context/SettingsProvider';
import { useApiStatus } from '../../../context/ApiStatusProvider';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import {
  useGetTechnicalLogsArchiveQuery,
  useGetTechnicalLogsQuery,
} from './adminTechnicalApiSlice';
import { useAdminSettingsHandlers } from '../settings/useAdminSettingsHandlers';
import { useTechnicalLogsSocket } from '../../../context/sockets/admin/TechnicalLogsSocketProvider';

//Components
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import AdminCardsRowTechnical from '../utils/AdminCardsRowTechnical';
import Table from '../../../components/Table/Table';
import AdminTechnicalNav from './AdminTechnicalNav';
import FileDirectory from '../../../components/FileDirectory/FileDirectory';

//Utils
import { img_archived, img_bug } from '../../../assets/icons';
import { getDateOrTimeFromUTZstring } from '../../../utils/dateTime/DateCalc';

const AdminTechnical = () => {
  //hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { setNavbar } = useSettings();
  const { handleWarning } = useApiStatus();
  const {
    adminTechnicalLogs,
    handleAdminTechnicalDateSorting,
    adminTechnicalLogsArchive,
  } = useAdminTechnicalHandlers();
  const {
    adminSettingsTechnicalHeadingsSize,
    adminSettingsTechnicalSortingDate,
    adminSettingsTechnicalSubHeadingsSize,
    handleAdminSettingsResetTechnicalHeadingsSize,
    handleAdminSettingsResetTechnicalSubHeadingsSize,
    handleAdminSettingsTechnicalSetHeadingsSize,
    handleAdminSettingsTechnicalSubHeadingsSize,
  } = useAdminSettingsHandlers();

  //component state
  const [view, setView] = useState('tickets');
  const [cardsRowIds, setCardsRowIds] = useState([]);
  const [formattedTechnicalLogs, setFormattedTechnicalLogs] = useState([]);
  const [technicalLogsArchiveDirectory, setTechnicalLogsArchiveDirectory] =
    useState({});

  //ui
  const [pageLoadComplete, setPageLoadComplete] = useState();
  const [currentSearchIndex, setCurrentSearchIndex] = useState(0);

  //Sockets
  const {
    //technical logs
    connectTechnicalLogsSocket,
    closeTechnicalLogsSocket,
    technicalLogsSocketStatus,

    //technical logs archive
    connectTechnicalLogsArchiveSocket,
    closeTechnicalLogsArchiveSocket,
    technicalLogsArchiveSocketStatus,
  } = useTechnicalLogsSocket();

  //API
  const { isLoading: getTechnicalLogsisLoading } = useGetTechnicalLogsQuery({
    adminAccountId,
  });
  const { isLoading: getTechnicalLogsArchiveisLoading } =
    useGetTechnicalLogsArchiveQuery({
      adminAccountId,
    });

  //variables
  const fileDirectoryRef = useRef();

  //Initialize
  useEffect(() => {
    setNavbar('technical');
  }, []);

  useEffect(() => {
    connectTechnicalLogsArchiveSocket();
    connectTechnicalLogsSocket();

    return () => {
      closeTechnicalLogsArchiveSocket();
      closeTechnicalLogsSocket();
    };
  }, []); //web socket for tickets

  useEffect(() => {
    let folderObject = {};

    adminTechnicalLogsArchive?.forEach((log) => {
      const logArchive = log.archive.archivePath.split('/');

      let currentLevel = folderObject;

      logArchive.forEach((folder, index) => {
        if (!currentLevel[folder]) {
          currentLevel[folder] = {};
        }
        currentLevel = currentLevel[folder];
      });
    });

    setTechnicalLogsArchiveDirectory(folderObject);
  }, [adminTechnicalLogsArchive]); //set archive directory

  useEffect(() => {
    let newTechnicalLogs = [];
    adminTechnicalLogs?.map((log) => {
      const createdAt = `${getDateOrTimeFromUTZstring(
        log.meta.createdAt,
        'date',
        {
          weekday: '',
          month: 'short',
        }
      )} (${getDateOrTimeFromUTZstring(log.meta.createdAt, 'time')})`;

      let formattedLog = {
        functionId: log?.trace?.fnId,
        createdAt: createdAt,
        errorMessage: log?.error?.message,
        errorStatusCode: log?.error?.statusCode,
        originalUrl: log?.request?.originalUrl,
        reference: log?.meta?.technicalLogReference,
        id: log?._id,
      };
      return newTechnicalLogs.push(formattedLog);
    });
    if (newTechnicalLogs?.length > 0) {
      setFormattedTechnicalLogs(newTechnicalLogs);
    } else {
      // add blank data to fill table
      setFormattedTechnicalLogs([
        {
          functionId: '',
          createdAt: '',
          errorMessage: '',
          errorStatusCode: '',
          originalUrl: '',
          reference: '',
          id: '',
          blank: '',
        },
      ]);
    }
  }, [adminTechnicalLogs]); //prep data for table

  //functions
  function handleCardRowSelection(selectionId) {
    if (cardsRowIds.includes(selectionId)) {
      return handleWarning({
        message: 'alreadySelected',
        id: Date.now(),
        origin: 'AdminTechnical.js/handleCardRowSelection',
      });
    }

    //No duplicate
    let newArr = [...cardsRowIds, selectionId];
    setCardsRowIds(newArr);
  }

  function handleFileDirectorySelection(selectionPath) {
    const selectedTechnicalLogArchive = adminTechnicalLogsArchive.find(
      (l) => `/${l.archive.archivePath}` === selectionPath
    );
    if (cardsRowIds.includes(selectedTechnicalLogArchive?._id)) {
      let newArr = [...cardsRowIds];
      const reducedArr = newArr.filter((cardRowId) => {
        return selectedTechnicalLogArchive?._id !== cardRowId;
      });
      setCardsRowIds(reducedArr);
      if (currentSearchIndex > 0) {
        setCurrentSearchIndex(currentSearchIndex - 1);
      } else {
        setCurrentSearchIndex(0);
      }
    } else {
      handleCardRowSelection(selectedTechnicalLogArchive?._id);
    }
  }

  function handleUnselectFileFromDirectory(archivePath) {
    fileDirectoryRef.current.handleFileDirectorySelection(
      false,
      `/${archivePath}`
    );
  }

  return (
    <>
      {pageLoadComplete ? (
        <div className="admin">
          <AdminTechnicalNav setView={setView} view={view} />
          <div className="admin-tickets__wrapper">
            <AdminCardsRowTechnical
              currentSearchIndex={currentSearchIndex}
              setCurrentSearchIndex={setCurrentSearchIndex}
              cardsRowIds={cardsRowIds}
              setCardsRowIds={setCardsRowIds}
              technicalLogs={adminTechnicalLogs}
              technicalLogsArchived={adminTechnicalLogsArchive}
              handleUnselectFileFromDirectory={handleUnselectFileFromDirectory}
              // adminSearchQueries={adminSupportSearchQueries}
            />
            <Table
              id={'technicalLogsTable'}
              data={formattedTechnicalLogs}
              handleViewDetails={
                adminTechnicalLogs?.length > 0 ? handleCardRowSelection : null
              }
              currentViewDetailsSelections={cardsRowIds}
              viewDetailsText={'Open Log'}
              tableTitle={'Technical Logs'}
              tableTitleIcon={img_bug}
              parentHeadings={[
                '',
                'FnId',
                'Created (UTC)',
                'Error',
                'Request URL',
                'Log Reference',
              ]}
              subHeadingPos={[3]}
              subHeadings={[['Message', 'Status Code']]}
              loadTableHeadings={adminSettingsTechnicalHeadingsSize}
              handleSetTableHeadings={
                handleAdminSettingsTechnicalSetHeadingsSize
              }
              isLoadingData={getTechnicalLogsisLoading}
              loadTableSubHeadings={adminSettingsTechnicalSubHeadingsSize}
              handleSetTableSubHeadings={
                handleAdminSettingsTechnicalSubHeadingsSize
              }
              hiddenColumns={[6]}
              sortingHandlers={[{ 2: handleAdminTechnicalDateSorting }]}
              sortingState={[
                {
                  2:
                    adminSettingsTechnicalSortingDate === 'ascending'
                      ? true
                      : false,
                },
              ]}
              handleResetTableSizes={() => {
                handleAdminSettingsResetTechnicalHeadingsSize();
                handleAdminSettingsResetTechnicalSubHeadingsSize();
              }}
              connectionStatusTrackerOn={true}
              connectionStatus={technicalLogsSocketStatus}
            />
            <div className="h128 " />
            <FileDirectory
              directory={technicalLogsArchiveDirectory}
              isLoading={getTechnicalLogsArchiveisLoading}
              title={'Archived Technical Logs'}
              iconOn={true}
              icon={img_archived}
              iconCustomClass={'flex-start mrg-l12 filter-gray'}
              customImgStyle={{ height: '28px' }}
              handleFileSelection={handleFileDirectorySelection}
              ref={fileDirectoryRef}
              connectionStatusTrackerOn={true}
              connectionStatus={technicalLogsArchiveSocketStatus}
            />
          </div>
        </div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setPageLoadComplete(true)}
          loadCheck1={adminAccountId ? true : false}
          customWrapper={'min-full-height'}
        />
      )}
    </>
  );
};

export default AdminTechnical;

import { useState } from 'react';

//hooks
import { useAccountPersonalSocket } from '../../../../../context/sockets/main/AccountPersonalProvider';

//components
import CommunityAccounts from './CommunityAccounts';
import LoadingScreenContent from '../../../../../components/LoadingScreenContent/LoadingScreenContent';

const CommunityContent = () => {
  //hooks
  const { getAccountCommunityListsIsLoading } = useAccountPersonalSocket();

  //state
  const [initialized, setInitialized] = useState(false);

  return (
    <div className="account-content" id="account-content">
      <div className="h24" />

      {!initialized ? (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          loadCheck1={!getAccountCommunityListsIsLoading}
          setLoadComplete={() => setInitialized(true)}
        />
      ) : (
        <CommunityAccounts />
      )}

      <div className="h48" />
    </div>
  );
};

export default CommunityContent;

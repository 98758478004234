import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

//Hooks
import { useSettings } from '../../../context/SettingsProvider';
import { useLoginMutation } from './authApiSlice';
import { useAuthReduxHandlers } from './useAuthHandlers';

//language
import useLanguageAccount from '../../../language/features/useLanguageAccount';
import useLanguageAuth from '../../../language/features/useLanguageAuth';

//Components
import CreatePasswordInput from '../../../components/TextArea/CreatePasswordInput';
import PasswordConfirmInput from '../../../components/TextArea/PasswordConfirmInput';
import Spinner from '../../../components/Spinner/Spinner';

//Utility
import { cancel } from '../../../assets/icons';

const ResetPassword = () => {
  //Hooks
  const {
    handleGetMyAccount,
    handleSaveAccountPasswordChange,
    handleResetTokenValidation,
    authCurrentToken: token,
  } = useAuthReduxHandlers();
  const navigate = useNavigate();
  const { resettoken } = useParams();
  const [login, { isSuccess: loginisSuccess }] = useLoginMutation();
  const { handleMobileTap } = useSettings();

  //language
  const { Auth } = useLanguageAuth();
  const { Account } = useLanguageAccount();

  //Component functions
  const [page, setPage] = useState(0);
  const [resEmail, setResEmail] = useState();

  const [newPassword, setNewPassword] = useState();
  const [newPasswordValid, setNewPasswordValid] = useState(false);

  const [passwordConfirm, setPasswordConfirm] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //UI state
  const [warningHighlight, setWarningHighlight] = useState(false);
  const [tapHighlightSaveBtn, setTapHighlightSaveBtn] = useState(false);
  const [tapHighlightContinueBtn, setTapHighlightContinueBtn] = useState(false);
  const [tapHighlightForgotPassword, setTapHighlightForgotPassword] =
    useState(false);
  const [tapHighlightRequestAssistance, setTapHighlightRequestAssistance] =
    useState(false);

  //Component variables

  let contents;

  //Initialize functions
  useEffect(() => {
    handleResetTokenValidation(resettoken, setPage);
  }, []);

  //API functions

  //Component functions
  function handleLogin() {
    login({
      email: resEmail,
      pwd: passwordConfirm,
    });
  }

  useEffect(() => {
    if (loginisSuccess && token) {
      handleGetMyAccount({ navigateRoute: '/' });
    }
  }, [loginisSuccess, token]);

  //JSX Conditional
  if (page === 0) {
    contents = (
      <div
        className="form-template"
        id="form-template"
        tabIndex="0"
        style={{ maxWidth: '512px' }}
      >
        <Spinner />
      </div>
    );
  } else if (page === 1) {
    contents = (
      <div
        className="form-template"
        id="form-template"
        tabIndex="0"
        style={{ maxWidth: '512px' }}
      >
        <div className="flex-row align-center">
          <label
            className="content-heading-styled text-gray mrg-r12"
            tabIndex="0"
            id="resetPasswordLabel"
          >
            {Auth.invalidLink}
          </label>
          <img
            src={cancel}
            alt="x"
            className="filter-red svg"
            style={{ height: '24px' }}
          />
        </div>
        <div className="h24" />
        <p className="description-text" tabIndex="0">
          {Auth.incorrectResetLink}
        </p>
        <div className="h24" />

        <div className="space-evenly align-center flex-column--ph  fit-content mrg-auto-left mrg-auto-right ">
          <button
            className={`text-btn pad-12 highlight-tb-theme access-ob mrg-r24 mrg-b24--ph mrg-r0--ph ${
              tapHighlightForgotPassword ? '' : ''
            }`}
            tabIndex="0"
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightForgotPassword(true),
                  () => setTapHighlightForgotPassword(false),
                ],
                [() => navigate('/forgotpassword')]
              )
            }
          >
            {Auth.goToForgotPassword}
          </button>

          <button
            className={`text-btn pad-12 highlight-tb-theme access-ob min-full-width--ph ${
              tapHighlightRequestAssistance ? '' : ''
            }`}
            tabIndex="0"
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightRequestAssistance(true),
                  () => setTapHighlightRequestAssistance(false),
                ],
                [() => navigate('/help')]
              )
            }
          >
            {Auth.requestAssistance}
          </button>
        </div>
      </div>
    );
  } else if (page === 2) {
    contents = (
      <div
        className="form-template"
        id="form-template"
        tabIndex="0"
        style={{ maxWidth: '512px' }}
      >
        <label
          className="content-heading-styled text-gray"
          tabIndex="0"
          id="resetPasswordLabel"
        >
          {Auth.resetYourPassword}
        </label>
        <div className="h24" />
        <CreatePasswordInput
          id={'newPassword'}
          asterisk={true}
          handleInput={setNewPassword}
          setValidPassword={setNewPasswordValid}
          autocompleteOff={true}
          customWrapper={'mrg-t24'}
        />
        <div className="h24" />
        <PasswordConfirmInput
          asterisk={true}
          customWrapper={'mrg-t24'}
          handleInput={setPasswordConfirm}
          id={'confirmNewPassword'}
          newPassword={newPassword}
          passwordConfirm={passwordConfirm}
          passwordsMatch={passwordsMatch}
          setPasswordsMatch={setPasswordsMatch}
        />
        <div className="h24" />
        <button
          className={`item-button access-ob fwn fs16 highlight-o-thin-theme ${
            tapHighlightSaveBtn ? 'highlight-o-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightSaveBtn(true),
                () => setTapHighlightSaveBtn(false),
              ],
              [
                () =>
                  handleSaveAccountPasswordChange(
                    resettoken,
                    newPassword,
                    passwordConfirm,
                    newPasswordValid,
                    passwordsMatch,
                    isLoading,
                    setIsLoading,
                    setResEmail,
                    setPage,
                    setWarningHighlight
                  ),
              ]
            )
          }
          tabIndex="0"
          aria-label={
            newPasswordValid && passwordsMatch && passwordConfirm
              ? `${Account.generic.save} ${Account.account.password}.`
              : `${Account.generic.ariaSaveUnavailable}`
          }
        >
          {!isLoading ? (
            <>{Account.generic.save}</>
          ) : (
            <Spinner maxHeight={'32px'} />
          )}
        </button>
      </div>
    );
  } else if (page === 3) {
    contents = (
      <div
        className="form-template"
        id="form-template"
        tabIndex="0"
        style={{ maxWidth: '512px' }}
      >
        <label
          className="content-heading-styled text-gray mrg-auto-left mrg-auto-right"
          tabIndex="0"
          id="passwordChangeSuccessfulLabel"
        >
          {Auth.passwordChangeSuccessful}
        </label>
        <div className="h24" />

        <button
          className={`item-button item-button access-ob fwn fs16 highlight-o-thin-theme ${
            tapHighlightContinueBtn ? 'highlight-o-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightContinueBtn(true),
                () => setTapHighlightContinueBtn(false),
              ],
              [() => handleLogin()]
            )
          }
          type="button"
          tabIndex="0"
          aria-label={Auth.continueToMyndFull}
        >
          {Auth.continueToMyndFull}
        </button>
      </div>
    );
  }

  return (
    <div className="layout">
      <div className="forgotpassword bg-blur">{contents}</div>
    </div>
  );
};

export default ResetPassword;

import { useEffect, useRef, useState } from 'react';

//Hooks
import { useGenericModal } from '../../context/GenericModalProvider';
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//used for the background overlay for the modals. click grey area and closes modal.
const GenericModal = () => {
  //Hooks
  const { genericModal, genericModalMobileFloat, handleCloseModal } =
    useGenericModal();
  const { Generic } = useLanguageComponents();
  const { handleMobileTap } = useSettings();

  //ui
  const [tapHighlightClose, setTapHighlightClose] = useState(false);
  // const [initialize, setInitialize] = useState(false);

  // useEffect(() => {
  //   if (genericModal || genericModalMobileFloat) {
  //     setInitialize(true);
  //   } else {
  //     setInitialize(false);
  //   }
  // }, [genericModal, genericModalMobileFloat]);

  // ${initialize ? 'generic-modal--fade-in' : 'generic-modal--fade-out'}

  return (
    <div className="generic-modal">
      <div
        className={`generic-modal--transitions  ${
          genericModal ? 'generic-modal__container' : ''
        } ${
          genericModalMobileFloat ? 'generic-modal__container-mobilefloat' : ''
        }`}
      >
        {genericModal || genericModalMobileFloat}
      </div>
      <button
        className={`highlight-it-theme access-ow access-o-12 ${
          genericModal || genericModalMobileFloat
            ? 'generic-modal-overlay'
            : 'remove'
        } ${!tapHighlightClose ? 'color-theme' : ''}`}
        onClickCapture={() =>
          handleMobileTap(
            [
              () => setTapHighlightClose(true),
              () => setTapHighlightClose(false),
            ],
            [() => handleCloseModal()]
          )
        }
        tabIndex="0"
        aria-label={Generic.closeList}
      />
    </div>
  );
};

export default GenericModal;

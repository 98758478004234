import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../../context/SettingsProvider';
import { useRegistrationHandlers } from './useRegistrationHandlers';

//Components
import AccountRegistrationType from './Content/AccountRegistrationType';
import PersonalRegistrationMain from './Content/Personal/PersonalRegistrationMain';
import PersonalAccessCode from './Content/Personal/PersonalAccessCode';
import RegistrationSelections from './Content/RegistrationSelections';
import CompletionTracker from './Content/CompletionTracker';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';
import MyndFullPattern from '../../../layout/shared/MyndFullPattern/MyndFullPattern';

const Registration = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const {
    registrationAccountType,
    registrationPage,
    handleRegistrationResetRegistration,
    registrationIsSuccess,
    handleRegistrationSoftResetRegistration,
  } = useRegistrationHandlers();

  //UI
  const [fadeState, setFadeState] = useState('');
  const [currentPage, setCurrentPage] = useState(registrationPage || 0);

  //Component variables
  let contents;
  let setupContents;
  const formContainer = document.getElementById('form-template');

  //UI & Initialize functions
  useEffect(() => {
    setNavbar('register');

    return () => {
      handleRegistrationResetRegistration();
    };
  }, []); //set navbar

  useEffect(() => {
    if (registrationPage === 1) {
      handleRegistrationSoftResetRegistration();
    }
  }, [registrationPage]);

  useEffect(() => {
    ScrollTo({
      focusId: 'form-template',
      scrollToTop: true,
      behavior: 'smooth',
      scrollToElementId: 'form-template',
    });
  }, [registrationPage, formContainer]); //scroll

  useEffect(() => {
    setFadeState('registration-container--fade-out');

    const timeout = setTimeout(() => {
      setCurrentPage(registrationPage);
      setFadeState('registration-container--fade-in');
    }, 400); // Adjust timing based on .registration-container  duration + adjust timer in PersonalAccountInfo and other sub-sections

    return () => clearTimeout(timeout);
  }, [registrationPage]);

  //JSX CONDITIONAL
  //Main Registration pages
  if (currentPage === 0) {
    setupContents = <PersonalAccessCode />;
  }

  if (currentPage === 1) {
    setupContents = <AccountRegistrationType />;
  }

  //PERSONAL
  if (currentPage === 2) {
    setupContents = <RegistrationSelections />;
  }

  if (currentPage > 2) {
    setupContents = null;
  }

  if (currentPage > 2 && registrationAccountType === 'personal') {
    if (!registrationIsSuccess) {
      contents = (
        <>
          <CompletionTracker />
          <PersonalRegistrationMain />
        </>
      );
    } else {
      contents = <PersonalRegistrationMain />;
    }
  }

  return (
    <div className="layout">
      <div className={`register min-full-height`}>
        <MyndFullPattern
          patternActive={currentPage === 0 ? true : false}
          key={'myndfullPattern-login'}
        />
        <div
          className={`form-template mrg-tb48`}
          id="form-template"
          tabIndex="-1"
          style={{ maxWidth: '796px', width: '100%' }}
        >
          <div className={`registration-container ${fadeState}`}>
            {setupContents}
            {contents}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

//Institutional
// import InitializeRegistrationInstitutional from './Content/Institutional/InitializeRegistrationInstitutional';

// if (registrationPage === 2 && registrationAccountType === 'institutional') {
//   contents = (
//     <InitializeRegistrationInstitutional

//       handleRegistrationPageIncrease={handleRegistrationPageIncrease}
//       handleRegistrationPageDecrease={handleRegistrationPageDecrease}
//       translationRoomCodesOptIn={translationRoomCodesOptIn}
//       setTranslationRoomCodesOptIn={setTranslationRoomCodesOptIn}
//       attendEventsOptIn={attendEventsOptIn}
//       setAttendEventsOptIn={setAttendEventsOptIn}
//       speakerEventsOptIn={speakerEventsOptIn}
//       setSpeakerEventsOptIn={setSpeakerEventsOptIn}
//       organizeEventsOptIn={organizeEventsOptIn}
//       setOrganizeEventsOptIn={setOrganizeEventsOptIn}
//     />
//   );
// }

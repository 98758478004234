//note, imageType should match bucket name.
import { retryWrapper } from '../logic/retryFns';

async function fetchImage(imagePath) {
  try {
    const res = await fetch(imagePath);
    return res;
  } catch (error) {
    throw error;
  }
}

export const handleDBImageBlob = (argsObj) => {
  return new Promise(async (resolve, reject) => {
    const imagePath = argsObj?.imagePath;
    const imageType = argsObj?.imageType;
    const setImage = argsObj?.setImage;
    const returnImageObj = argsObj?.returnImageObj;

    if (
      imagePath === 'myndfull_account_image.png' ||
      imagePath === 'myndfull_event_image.png' ||
      !imagePath
    ) {
      return resolve(null);
    }

    try {
      const fullImagePath = `https://storage.googleapis.com/myndfull_${imageType}_images${
        process.env.REACT_APP_ENV === 'development' ? '_development' : ''
      }/${imagePath}`;

      const response = await retryWrapper(fetchImage, [fullImagePath]);

      if (response?.status === 404) {
        return reject(new Error('notfound'));
      }

      if (!response) {
        return reject(new Error('error'));
      }

      const blob = await response.blob();
      const urlBlob = URL.createObjectURL(blob);

      if (setImage) {
        setImage({
          imagePreview: urlBlob,
          croppedImage: '',
        });
      }

      if (returnImageObj) {
        return resolve({
          imagePreview: urlBlob,
          croppedImage: '',
        });
      }

      return resolve(null);
    } catch (error) {
      return reject(error);
    }
  });
};

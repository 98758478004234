import React, { useEffect, useState } from 'react';

// Language
import useLanguageComponents from '../../language/useLanguageComponents';

// Utility
import { account_img_default } from '../../assets/images';

const LoadingScreenContent = ({
  loadCheck1,
  loadCheck2,
  loadCheck3,
  numberOfLoadChecks,
  setLoadComplete,
  customLoadingText,
  customWrapper,
}) => {
  // Language
  const { LoadingScreen } = useLanguageComponents();

  // Component states
  const [animationPercentage, setAnimationPercentage] = useState(0);
  const [trackedChecks, setTrackedChecks] = useState([false, false, false]);

  // Track progress without reset
  useEffect(() => {
    setTrackedChecks((prevChecks) => {
      const newChecks = [
        prevChecks[0] || loadCheck1,
        prevChecks[1] || loadCheck2,
        prevChecks[2] || loadCheck3,
      ];

      const successfulChecks = newChecks.filter(Boolean).length;
      const newPercentage = (successfulChecks / numberOfLoadChecks) * 100;

      setAnimationPercentage(newPercentage);

      if (successfulChecks === numberOfLoadChecks && setLoadComplete) {
        setTimeout(() => setLoadComplete(), 600);
      }

      return newChecks;
    });
  }, [loadCheck1, loadCheck2, loadCheck3, numberOfLoadChecks, setLoadComplete]);

  return (
    <div className={`loading-screen ${customWrapper ? customWrapper : ''}`}>
      <div className="flex-column fadeInOut flex-center">
        <img
          src={account_img_default}
          alt={LoadingScreen.loading}
          className="loading-screen-logo"
        />
        <h1 className="color-gray mrg-b6">
          {customLoadingText ? customLoadingText : LoadingScreen.loading}
        </h1>
      </div>

      <div className="loading-bar__container">
        <div
          className="loading-bar"
          style={{
            width: `${animationPercentage}%`,
            backgroundColor: '#f67512',
          }}
        />
      </div>
    </div>
  );
};

export default LoadingScreenContent;

import { useEffect, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//Utility
import { dropdown_arrow } from '../../../../assets/icons';

const OrganizeTagsToggle = ({ view }) => {
  //Hooks
  const {
    accountAffiliations,
    accountId,
    accountFirstName,
    accountLastName,
    accountProfileImage,
  } = useAccountReduxHandlers();
  const {
    settingsOrganizeSelectedOrganizers,
    handleSettingsSetOrganizeOverviewListOpened,
    settingsOrganizeOverviewListOpened,
  } = useSettingsHandlers();

  //language
  const { IconAlts } = useLanguageComponents();
  const { SettingsOrganizeLanguage } = useLanguageSettings();

  //Component state
  const [viewSelectionHeading, setViewSelectionHeading] = useState();

  //Component variables
  let tagsToggle;

  useEffect(() => {
    if (view === 'overview') {
      setViewSelectionHeading(`${SettingsOrganizeLanguage.organizerOverview}`);
    }
    if (view === 'create') {
      setViewSelectionHeading(`${SettingsOrganizeLanguage.createEvents}`);
    }
    if (view === 'speakers') {
      setViewSelectionHeading(`${SettingsOrganizeLanguage.findSpeakers}`);
    }
  }, [view]); //sets translated viewSelectionHeading

  //JSX conditional
  if (view === 'overview') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <button
          className="tags-toggle-btn color-gray align-center fs18 fwsb access-ob access-o6"
          onClick={() =>
            handleSettingsSetOrganizeOverviewListOpened(
              !settingsOrganizeOverviewListOpened
            )
          }
          tabIndex="0"
          aria-label={SettingsOrganizeLanguage.ariaOrganizerSelections}
          aria-expanded={settingsOrganizeOverviewListOpened}
        >
          <img
            src={dropdown_arrow}
            alt={IconAlts.iconArrow}
            className={`tags-toggle-arrow svg ${
              !settingsOrganizeOverviewListOpened ? 'rotate-180' : ''
            }`}
            aria-hidden="true"
          />
          {viewSelectionHeading}
        </button>
        {!settingsOrganizeOverviewListOpened && (
          <>
            <div className="divider mrg-auto-tb" />
            <ul className="flex-row list-style-none">
              {settingsOrganizeSelectedOrganizers.includes(accountId) && (
                <div
                  key={`tags-toggle-personal`}
                  className="flex-row flex-center fs18 fwsb mrg-r6 access-ob access-o6"
                  tabIndex="0"
                  aria-label={`${accountFirstName} ${accountLastName}`}
                >
                  <LoadBucketImage
                    imagePath={accountProfileImage}
                    imgClassName="tag-profile-img tag-profile-community mrg-r6"
                    backupPath={'account_img_default.png'}
                    partialPath={'myndfull_account_images'}
                  />
                  {settingsOrganizeSelectedOrganizers?.length === 1 &&
                    `${accountFirstName} ${accountLastName}`}
                </div>
              )}
              {settingsOrganizeSelectedOrganizers?.map(
                (selectedAffiliationId, index) => {
                  let selection;

                  selection = accountAffiliations?.find(
                    (acct) => acct.affiliation._id === selectedAffiliationId
                  );

                  let acctItem;
                  if (selection) {
                    acctItem = (
                      <div
                        key={`tags-toggle-${index}`}
                        className="flex-row flex-center fs18 fwsb mrg-r6 access-ob access-o6"
                        tabIndex="0"
                        aria-label={
                          selection?.affiliation?.institution?.institutionTitle
                        }
                      >
                        <LoadBucketImage
                          imagePath={
                            selection?.affiliation?.institution?.institutionLogo
                          }
                          imgClassName="institutional-profile-img tag-profile-community"
                          backupPath={
                            'myndfull_institutionalaccount_images/account_img_default.png'
                          }
                          partialPath={'myndfull_account_images'}
                        />
                        {selection &&
                          settingsOrganizeSelectedOrganizers?.length === 1 &&
                          selection?.affiliation?.institution?.institutionTitle}
                      </div>
                    );
                  }
                  return acctItem;
                }
              )}
            </ul>
          </>
        )}
      </div>
    );
  } else if (view === 'create') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <div
          className="tags-toggle-btn color-gray align-center fs18 fwsb pointer-default access-ob access-o6"
          tabIndex="0"
          aria-label={SettingsOrganizeLanguage.createEvents}
        >
          {viewSelectionHeading}
        </div>
      </div>
    );
  } else if (view === 'speakers') {
    tagsToggle = (
      <div className="flex-row flex-center">
        <div
          className="tags-toggle-btn color-gray align-center fs18 fwsb pointer-default access-ob access-o6"
          tabIndex="0"
          aria-label={SettingsOrganizeLanguage.findSpeakers}
        >
          {viewSelectionHeading}
        </div>
      </div>
    );
  }

  return tagsToggle;
};

export default OrganizeTagsToggle;

import { useSettings } from '../../context/SettingsProvider';

const useLanguageOrganize = () => {
  const { contentLanguage } = useSettings();

  const CreateEvent = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        skipTo: {
          skipToParticipants: 'Skip to Participants',
          skipToAccess: 'Skip to Access',
          skipToRegistration: 'Skip to Registration',
          skipToPublish: 'Skip to Publish',
          skipToAgenda: 'Skip to Agenda',
          skipToImages: 'Skip to Images',
          skipToTags: 'Skip to Tags',
          skipToContact: 'Skip to Contact',
          skipToTranslations: 'Skip to Translations',
          skipToDispatches: 'Skip to Dispatches',
          skipToGeneral: 'Skip to General',
          skipToInitialize: 'Skip to Initialize',
          skipToEditOverview: 'Skip to Edit Overview',
        },
        skip: {
          skipCalendar: 'Skip Calendar',
        },
        initialize: {
          initialize: 'Initialize',
          ariaFieldsetInstructions: `Initialize Event. Restore data from a previous session on this device if
          data is available. Load or save event drafts. Create a new event draft.`,
          load: 'Load',
          create: 'Create',
          draftFiles: 'Draft Files',
          ariaTableTitle: 'Draft files table.',
          lastModified: 'Last Modified',
          noDrafts: `No Saved Drafts`,
          cancel: 'Cancel',
          save: 'Save',
          saveAs: 'Save As',
          delete: 'Delete',
          dataNotSaved: 'Images will not be saved in drafts.',
          fileNameAlreadyExists: 'This file name already exists.',
          saveAndExit: 'Save & Exit',
          restore: 'Restore',
          restoreDataMessage: 'Restore data from your previous session?',
          createNewEventAndLoseProgress:
            'Create a new event and lose unsaved progress?',
          loadDraftPt1: 'Load draft "',
          loadDraftPt2: '" and lose unsaved progress?',
          participantInvitesResetWarning: `This event has already dispatched participant invites. Creating a new save with this data will reset all dispatches.`,
          participantInvitesResetWarningWithNoSaveName: `This event's restored data has already dispatched participant invites. Creating a new save with this data will reset all dispatches.`,
        },
        dispatches: {
          participantDispatches: 'Participant Dispatches',
          participantInvites: 'Participant Invites',
          participantDispatchesDescription:
            'Select checkboxes to dispatch participation invites and / or translation data requests to the corresponding participant. Selected dispatches will be sent during the next draft save or event publication.',
          noDispatchesAvailable: 'No dispatches available.',
          completedParticipants: 'Completed Participants',
          participants: 'Participants',
          participantInvite: 'Participant Invite',
          translationRequest: 'Translation Request',
        },
        general: {
          general: 'General',
          titleDescription: 'Title & Description',
          ariaFieldsetInstructions: `Create Event sub-section: General. Complete standard event information,
          including title, description, event type, audience type, date & time,
          and an optional event agenda.`,
          eventTitle: 'Event Title',
          description: 'Description',
          eventType: 'Event Type',
          eventTypeWhatsThis: 'event',
          audienceType: 'Target Audience',
          audienceTypeWhatsThis: 'audience',
          dateAndTime: 'Date & Time',
          dateAndTimeDescription: 'Select the date and time of the event.',

          startTime: 'Start Time',
          endTime: 'End Time',
          eventAgenda: 'Event Agenda',
          agendaItem: 'Agenda item',
          eventAgendaDescription:
            'Create an event agenda. Toggle the agenda schedule to display as either specific start/end times or duration in minutes.',
          agendaItemButton: 'Add Agenda Item',
          languages: 'Event Languages',
          addLanguages: 'Add Languages',
          languageDescription: 'Add the language(s) of the event.',
        },
        participants: {
          participants: 'Participants',
          ariaFieldsetInstructions: `Create Event sub-section: Participants. Add an event moderator and up to
          six speakers. A speaker name for each speaker is required.`,
          moderator: 'Moderator',
          profilePhoto: 'Profile Photo',
          moderatorName: 'Moderator Name',
          speakers: 'Speakers',
          speakersDescription:
            'Add up to six speakers manually or through MyndFull accounts.',
          speakerName: 'Speaker Name',
          speakerBio: 'Speaker Bio',
          addSpeaker: 'Add Speaker',
          addParticipant: 'Add Participant',
          selectParticipantType: 'Select Participant Type',
        },
        eventTranslations: {
          translations: 'Translations',
          enableTranslations: 'Enable (Real-time) Translations',
          roomCode: 'Room Code',
          password: 'Password',
          roomLanguages: 'Room Languages',
          participants: 'Participants',
          create: 'Create',
          email: 'Email',
          dispatches: 'Dispatches',
          dispatchesInstructions:
            'Select Speakers to dispatch their data collection email the next time you save this draft or publish this event.',
          inviteContact: 'Invite Contact',
          noParticipants: 'No Participants.',
        },
        access: {
          access: 'Access',
          ariaFieldsetInstructions: `Create Event sub-section: Access. Provide details regarding the event
          location, accesssibility, and language. Hosting options and event
          language is required.`,
          hostingOptions: 'Hosting Options',
          hostOptionsDescription:
            'Indicate whether your event will be hosted in person, online or a hybrid of both.',
          onlinePlatform: 'Online Platform',
          virtualMeetingId: 'Virtual Meeting Id',
          virtualMeetingPasscode: 'Virtual Meeting Passcode',
          virtualMeetingLink: 'Virtual Meeting Link',
          clearOnlinePlatformFields: 'Clear Online Platform Fields',
          location: 'Location',
          locationDescription:
            'Use the autocomplete feature to quickly fill the address fields. Drag and drop the marker to pinpoint the location on large buildings.',
          room: 'Room',
          locationName: 'Location Name',
          streetAddress: 'Street Address',
          cityTown: 'City/Town',
          country: 'Country',
          clearInPersonFields: 'Clear In-person Location',
          accessibility: 'Accessibility',
          wheelchairAccessibility: 'Wheelchair Accessibility',
          signLanguageInterpreter: 'Sign Language Interpreter',
          notes: 'Notes',
          accessibilityNotesDescription:
            'Provide notes on accessibility. For example, the location of a wheelchair ramp or how to obtain on-site assistance.',
        },
        registration: {
          registration: 'Registration',
          ariaFieldsetInstructions: `Create Event sub-section: Registration. Toggle registration options,
          including requiring registrations, approve registrations, max
          registrations, and private details.`,
          requireRegistration: 'Require Registrations',
          requireRegistrationsDescription:
            'Attendees are required to register and additional event organization options are unlocked below.',
          approveRegistrations: 'Approve Registrations',
          approveRegistrationsDescription:
            'Attendee registrations requires approval by an event organizer.',

          maxRegistrationsInPerson: 'Max Registrations (In Person)',
          maxRegistrationsDescriptionsInPerson:
            'Set the number of maximum registrations for in-person attendance.',
          ariaMaxRegistrationsCounterInputInPerson: `Current number of maximum registrations for in-person attendance.`,

          maxRegistrationsVirtual: 'Max Registrations (Virtual)',
          maxRegistrationsDescriptionsVirtual:
            'Set the number of maximum registrations for virtual attendance.',
          ariaMaxRegistrationsCounterInputVirtual: `Current number of maximum registrations for virtual attendance.`,

          privateDetails: 'Private Details',
          privateDetailsDescription:
            'Selected event details will only be visible to registered attendees.',
          privateDetailsWarning: 'Please select a Hosting Option',
          room: 'Room',
          locationName: 'Location Name',
          streetAddress: 'Street Address',
          virtualMeetingId: 'Virtual Meeting Id',
          virtualMeetingPasscode: 'Virtual Meeting Passcode',
          virtualMeetingLink: 'Virtual Meeting Link',
          hostingInPerson: 'In Person Hosting',
          hostingVirtual: 'Virtual Hosting',
          translations: 'Translations',
          contact: 'Contact',
          contactInformation: 'Contact Information',
          restrictLocationMap:
            'Restricting Location Name or Street Address will also lock the map view.',
        },
        tags: {
          tags: 'Tags',
          ariaFieldsetInstructions: `Create Event sub-section: Assign big tags and tiny tags to match user interests.`,
          bigTags: 'Big Tags',
          bigTagsDescription:
            'Choose scholastic fields / disciplines that match your event.',
          ariaBigTagsInputInstructions:
            'Choose scholastic fields / disciplines that match your event. For the best accessibility, use the See Full List button located below the input field to directly add big tags from a list. Alternatively, use this autocomplete field to find tags from the Big Tags list. Use the arrow up and arrow down keys to scroll through the generated list. Press enter to add the highlighted tag or backspace to remove characters from the input field.',
          seeFullList: 'See Full List',
          tinyTags: 'Tiny Tags',
          tinyTagsDescription: `Select specific tags for greater visibility to attendees that have matching tags. Only use letters and numbers. At least one tag is required.`,
        },
        images: {
          images: 'Images',
          ariaFieldsetInstructions: `Create Event sub-section: Images.`,
          eventDisplay: 'Event Display',
          ariaImageDescription: 'Event photo.',
          eventDisplayDescription: `Add a primary display image. It is recommended that display images do not contain text for reasons related to both accessibility and aesthetic.`,
          //
          eventDisplayAltText: 'Event Display Alt Text',
          additionalImages: 'Additional Images',
          additionalImagesDescription:
            'Provide up to four additional images with optional captions.',
          addImage: 'Add Image',
        },
        publish: {
          publish: 'Publish',
          ariaFieldsetInstructions: `Create Event sub-section: Publish. Check event completion status. When the event status is ready, you may schedule event publication or publish the event immediately.`,
          incomplete: 'Incomplete',
          complete: 'Complete',
          checkCompletion: 'Check Completion',

          fieldsThatRequireCompletion: 'Fields that require completion:',

          scheduleEventPublication: 'Schedule Event Publication',

          previewEvent: 'Preview Event',
          publishEvent: 'Publish Event',
          publishDraft: 'Publish Draft',
          completePublishInformation:
            'Please complete the scheduling information.',
          removeList: 'Remove List',
        },
        validations: {
          eventTitle: 'Event Title',
          eventType: 'Event Type',
          audienceType: 'Audience Type',
          date: 'Date',
          timeZone: 'Time Zone',
          startTime: 'Start Time',
          endTime: 'End Time',
          hostingOption: 'Hosting Option',
          language: 'Language',
          bigTags: 'Big Tags',
          tinyTags: 'Tiny Tags',
          agendaItem: 'Agenda Item',
          streetAddress: 'Street Address',
          cityTown: 'City/Town',
          country: 'Country',
          onlinePlatform: 'Online Platform',
          virtualMeetingId: 'Virtual Meeting Id',
          viritualPasscode: 'Virtual Passcode',
          maxRegistrationsInPerson: 'Max Registrations (In Person) is 0',
          maxRegistrationsVirtual: 'Max Registrations (Virtual) is 0',
          schedulePublication: 'Schedule Publication',
          contact: 'Contact',
          createdParticipants: 'Created Participants',
          emailInvitationParticipants: 'Email Invitation Participants',
          translationsRoomCode: 'Translations Room Code',
          translationsPassword: 'Translations Password',
          translationsRoomLanguages: 'Translations Room Languages',
          //privateDetails
          privateDetailsContactPhone: `Private details for 'Contact Phone' is enabled but this field has no value.`,
          privateDetailsContactEmail: `Private details for 'Contact Email' is enabled but this field has no value.`,
          privateDetailsRoom: `Private details for 'Room' is enabled but this field has no value.`,
          privateDetailsLocationName: `Private details for 'Location Name' is enabled but this field has no value.`,
          privateDetailsStreetAddress: `Private details for 'Street Address' is enabled but this field has no value.`,
          privateDetailsVirtualMeetingId: `Private details for 'Virtual Meeting ID' is enabled but this field has no value.`,
          privateDetailsVirtualMeetingPasscode: `Private details for 'Virtual Meeting Passcode' is enabled but this field has no value.`,
          privateDetailsVirtualMeetingLink: `Private details for 'Virtual Meeting Link' is enabled but this field has no value.`,
          privateDetailsTranslationsRoomCode: `Private details for 'Translations Room Code' is enabled but this field has no value.`,
          privateDetailsTranslationsRoomPassword: `Private details for 'Translations Room Password' is enabled but this field has no value.`,
        },
        contact: {
          contact: 'Contact',
          ariaFieldsetInstructions: `Create Event sub-section: Contact. Adjust your contact preferences.`,
          provideContact: 'Provide contact information',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        skipTo: {
          skipToParticipants: 'Passer aux Participants',
          skipToAccess: `Passer à l'accès`,
          skipToRegistration: `Passer à l'inscription`,
          skipToPublish: 'Passer à Publier',
          skipToAgenda: `Passer à l'agenda`,
          skipToImages: `Passer aux images`,
          skipToTags: `Passer aux balises`,
          skipToContact: `Passer au contact`,
          skipToTranslations: 'Passer aux traductions',
          skipToDispatches: 'Passer aux dépêches',
          skipToGeneral: 'Passer au général',
          skipToInitialize: `Passer à l'initialisation`,
          skipToEditOverview: `Passer à l'aperçu des modifications`,
        },
        skip: {
          skipCalendar: 'Ignorer le calendrier',
        },
        initialize: {
          initialize: 'Initialiser',
          ariaFieldsetInstructions: `Initialiser l'événement. Restaurer les données d'une session précédente sur cet appareil si
          les données sont disponibles. Chargez ou enregistrez des brouillons d'événements. Créer un nouveau brouillon d'événement.`,
          load: 'Charger',
          create: 'Créer',
          draftFiles: 'Brouillons de fichiers',
          ariaTableTitle: 'Tableau des brouillons de fichiers.',
          lastModified: 'Dernière modification',
          noDrafts: 'Aucun brouillon sauvegardé',
          cancel: 'Annuler',
          save: 'Sauvegarder',
          saveAs: 'Enregistrer sous',
          delete: 'Supprimer',
          dataNotSaved: `Les images ne seront pas enregistrées dans les brouillons.`,
          fileNameAlreadyExists: `Ce nom de fichier existe déjà.`,
          saveAndExit: 'Enregistrer & quitter',
          restore: 'Restaurer',
          restoreDataMessage:
            'Restaurer les données de votre session précédente ?',
          createNewEventAndLoseProgress: `Créer un nouvel événement et perdre la progression non enregistrée ?`,
          loadDraftPt1: 'Charger le brouillon "',
          loadDraftPt2: '" et perdre la progression non enregistrée ?',
          participantInvitesResetWarning: `Cet événement a déjà dispatché les invitations aux participants. Créer une nouvelle sauvegarde avec ces données réinitialisera tous les dispatches.`,
          participantInvitesResetWarningWithNoSaveName: `Les données restaurées de cet événement ont déjà dispatché les invitations aux participants. Créer une nouvelle sauvegarde avec ces données réinitialisera tous les dispatches.`,
        },
        dispatches: {
          participantDispatches: 'Dépêches des participants',
          participantInvites: 'Invitations des participants',
          participantDispatchesDescription: `Cochez les cases pour envoyer les invitations à participer et/ou les demandes de traduction aux participants concernés. Les envois sélectionnés seront effectués lors de la prochaine sauvegarde du brouillon ou de la prochaine publication de l'événement.`,
          noDispatchesAvailable: `Aucune dépêche n'est disponible.`,
          completedParticipants: 'Participants terminés',
          participants: 'Participants',
          participantInvite: 'Invitation des participants',
          translationRequest: 'Demande de traduction',
        },
        general: {
          general: 'Général',
          titleDescription: 'Titre & description',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Général. Informations complètes sur les événements standards,
          y compris le titre, la description, le type d'événement, le type d'audience, la date et l'heure,
          et un programme d'événement facultatif.`,
          eventTitle: `Titre de l'événement`,
          description: 'Description',
          eventType: `Type d'événement`,
          eventTypeWhatsThis: 'événement',
          audienceType: 'Public cible',
          audienceTypeWhatsThis: 'public',
          dateAndTime: 'Date et heure',
          dateAndTimeDescription: `Date non sélectionnée`,

          startTime: 'Heure de début',
          endTime: 'Heure de fin',
          eventAgenda: `Ordre du jour de l'événement`,
          agendaItem: `Point à l'ordre du jour`,
          eventAgendaDescription: `Créer un programme d'événements. Basculez le calendrier de l'agenda pour afficher soit des heures de début/de fin spécifiques, soit une durée en minutes.`,
          agendaItemButton: `Ajouter un élément d'ordre du jour`,
          languages: `Langues de l'événement`,
          addLanguages: 'Ajouter des langues',
          languageDescription: `Ajoutez la ou les langues de l'événement.`,
        },
        participants: {
          participants: 'Participants',
          profilePhoto: 'Photo de profil',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Participants. Ajoutez un modérateur d'événement et jusqu'à
          six intervenants. Un nom de conférencier pour chaque conférencier est requis.`,
          moderator: 'Modérateur',
          moderatorName: 'Nom du modérateur',
          speakers: 'Conférenciers',
          speakersDescription:
            'Ajoutez jusqu’à six conférenciers manuellement ou via des comptes MyndFull.',
          speakerName: 'Nom du conférencier',
          speakerBio: 'Biographie du conférencier',
          addSpeaker: 'Ajouter un conférencier',
          addParticipant: 'Ajouter un participant',
          selectParticipantType: 'Sélectionnez le type de participant',
        },
        access: {
          access: 'Accès',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Accès. Fournir des détails concernant l'événement
          l'emplacement, l'accessibilité et la langue. Options d'organisation et événement
          la langue est requise.`,
          hostingOptions: `Options d'organisation`,
          hostOptionsDescription:
            'Indiquez si votre événement aura lieu en personne, en ligne ou sous forme hybride.',
          onlinePlatform: 'Plateforme en ligne',
          virtualMeetingId: 'Id de la réunion virtuelle',
          virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
          virtualMeetingLink: 'Lien de la réunion virtuelle',
          clearOnlinePlatformFields:
            'Effacer les champs de la plateforme en ligne',
          location: 'Emplacement',
          locationDescription: `Utilisez la fonction de saisie semi-automatique pour remplir rapidement les champs d'adresse. Faites glisser et déposez le marqueur pour localiser l'emplacement sur de grands bâtiments.`,
          room: 'Salle',
          locationName: `Nom de l'emplacement`,
          streetAddress: 'Adresse',
          cityTown: 'Ville',
          country: 'Pays',
          clearInPersonFields: `Effacer l'emplacement en personne`,
          accessibility: 'Accessibilité',
          wheelchairAccessibility: 'Accessibilité en fauteuil roulant',
          signLanguageInterpreter: 'Interprète en langue des signes',
          notes: 'Notes',
          accessibilityNotesDescription: `Fournissez des notes sur l'accessibilité. Par exemple, l'emplacement d'une rampe pour fauteuil roulant ou comment obtenir de l'aide sur place.`,
        },
        eventTranslations: {
          translations: 'Traductions',
          enableTranslations: 'Activer les traductions (en temps réel)',
          roomCode: 'Code de la salle',
          password: 'Mot de passe',
          roomLanguages: 'Langues des salles',
          participants: 'Participants',
          create: 'Créer',
          email: 'E-mail',
          dispatches: 'Dépêches',
          dispatchesInstructions:
            'Sélectionnez les orateur pour envoyer leur e-mail de collecte de données la prochaine fois que vous enregistrez ce brouillon ou publiez cet événement.',
          inviteContact: 'Inviter un contact',
          noParticipants: 'Aucun participant.',
        },
        registration: {
          registration: 'Inscription',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Inscription. Basculez les options d'inscription, y compris les inscriptions obligatoires, les inscriptions approuvées, les inscriptions maximales et les détails privés.`,
          requireRegistration: 'Inscription requise',
          requireRegistrationsDescription: `Les participants doivent s'inscrire et des options supplémentaires pour l'organisation de l'événement sont débloquées ci-dessous.`,
          approveRegistrations: `Approuver l'inscription`,
          approveRegistrationsDescription: `L'inscription des participants doit être approuvée par un organisateur de l'événement.`,

          maxRegistrationsInPerson: 'Inscriptions maximales (en personne)',
          maxRegistrationsDescriptionsInPerson: `Définissez le nombre maximum d'inscriptions pour la participation en personne.`,
          ariaMaxRegistrationsCounterInputInPerson: `Nombre actuel d'inscriptions maximales pour la participation en personne.`,

          maxRegistrationsVirtual: 'Inscriptions maximales (virtuelles)',
          maxRegistrationsDescriptionsVirtual: `Définissez le nombre maximum d'inscriptions pour la participation virtuelle.`,
          ariaMaxRegistrationsCounterInputVirtual: `Nombre actuel d'inscriptions maximales pour la participation virtuelle.`,

          privateDetails: 'Détails privés',
          privateDetailsDescription: `Les détails sélectionnés de l'événement seront visibles uniquement pour les participants inscrits.`,
          privateDetailsWarning: `Veuillez sélectionner une option d'organisation`,
          room: 'Salle',
          locationName: `Nom de l'emplacement`,
          streetAddress: 'Adresse',
          virtualMeetingId: 'ID de la réunion virtuelle',
          virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
          virtualMeetingLink: 'Lien de la réunion virtuelle ',
          hostingInPerson: 'Hébergement en personne',
          hostingVirtual: 'Hébergement virtuel',
          translations: 'Traductions',
          contact: 'Contact',
          contactInformation: 'Informations de contact',
          restrictLocationMap: `La restriction du nom de l'emplacement ou de l'adresse postale verrouillera également la vue de la carte.`,
        },
        tags: {
          tags: 'Balises',
          ariaFieldsetInstructions: `Sous-section Créer un événement: Attribuer grand balises et petit balises pour correspondre aux intérêts des utilisateurs.`,
          bigTags: 'Grand Balises',
          bigTagsDescription: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants.`,
          ariaBigTagsInputInstructions: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants. Pour une meilleure accessibilité, utilisez le bouton Voir la liste complète situé sous le champ de saisie pour ajouter directement de grand bailses à partir d'une liste. Vous pouvez également utiliser ce champ de saisie semi-automatique pour rechercher des balises dans la liste Grande Tags. Community Verified icon Utilisez les flèches vers le haut et vers le bas pour faire défiler la liste générée. Appuyez sur Entrée pour ajouter la balise en surbrillance ou sur Retour arrière pour supprimer des caractères du champ de saisie.`,
          seeFullList: 'Voir la liste complète',
          tinyTags: 'Petit Balises',
          tinyTagsDescription: `Sélectionner des balises spécifiques pour une plus grande visibilité auprès des participants qui ont des balises correspondants. Utilisez uniquement des lettres et des chiffres. Au moins une étiquette est nécessaire.`,
        },

        images: {
          images: 'Images',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Images. Ajouter une image d'événement. `,
          eventDisplay: `Image principale de l'événement`,
          ariaImageDescription: `Photographie de l'événement.`,
          eventDisplayDescription: `Ajoutez une image d'affichage principale. Il est recommandé que les images affichées ne contiennent pas de texte pour des raisons liées à la fois à l'accessibilité et à l'esthétique.`,
          additionalImages: 'Images supplémentaires',
          additionalImagesDescription: `Fournissez jusqu'à quatre images supplémentaires avec des légendes facultatives.`,
          addImage: 'Ajouter des images',
        },
        publish: {
          publish: 'Publier',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Publier. Vérifiez l'état d'achèvement de l'événement. Quand
          le statut de l'événement est prêt, vous pouvez programmer la publication de l'événement ou publier
          l'événement immédiatement.`,
          incomplete: 'Incomplet',
          complete: 'Complet',
          checkCompletion: 'Vérifier la complétion',
          fieldsThatRequireCompletion: 'Champs qui nécessitent la complétion:',
          scheduleEventPublication: `Planifier la publication de l'événement`,
          previewEvent: `Prévisualiser l'événement`,
          publishEvent: `Publier l'événement`,
          publishDraft: 'Publier le brouillon',
          completePublishInformation: `Veuillez compléter les informations de planification.`,
          removeList: 'Supprimer la liste',
        },
        validations: {
          eventTitle: `Titre de l'événement`,
          eventType: `Type d'événement`,
          audienceType: 'Type de public',
          date: 'Date',
          timeZone: 'Fuseau horaire',
          startTime: 'Heure de début',
          endTime: 'Heure de fin',
          hostingOption: `Options d'organisation`,
          language: 'Langue',
          bigTags: 'Grand Balises',
          tinyTags: 'Petit Balises',
          agendaItem: `Ordre du jour de l'événement`,
          streetAddress: 'Adresse',
          cityTown: 'Ville',
          country: 'Pays',
          onlinePlatform: 'Plateforme en ligne',
          virtualMeetingId: 'ID de la réunion virtuelle',
          viritualPasscode: `Code d'accès à la réunion virtuelle`,
          maxRegistrationsInPerson: `Le nombre maximum d'inscriptions (en personne) est de 0`,
          maxRegistrationsVirtual: `Le nombre maximum d'inscriptions (virtuelles) est de 0`,
          schedulePublication: 'Calendrier de publication',
          contact: 'Contact',
          createdParticipants: 'Participants créés',
          emailInvitationParticipants: `Participants à l'invitation par e-mail`,
          translationsRoomCode: 'Traductions Code de la salle',
          translationsPassword: 'Traductions Mot de passe',
          translationsRoomLanguages: 'Salle de traduction Langues',
          //privateDetails
          privateDetailsContactPhone: `Les détails privés pour 'Numéro de contact' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsContactEmail: `Les détails privés pour 'E-mail de contact' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsRoom: `Les détails privés pour 'Salle' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsLocationName: `Les détails privés pour 'Nom de l'emplacement' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsStreetAddress: `Les détails privés pour 'Adresse' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsVirtualMeetingId: `Les détails privés pour 'ID de réunion virtuelle' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsVirtualMeetingPasscode: `Les détails privés pour 'Code d'accès de réunion virtuelle' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsVirtualMeetingLink: `Les détails privés pour 'Lien de réunion virtuelle' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsTranslationsRoomCode: `Les détails privés pour 'Code de la salle de traduction' sont activés, mais ce champ n'a pas de valeur.`,
          privateDetailsTranslationsRoomPassword: `Les détails privés pour 'Mot de passe de la salle de traduction' sont activés, mais ce champ n'a pas de valeur.`,
        },
        contact: {
          contact: 'Contact',
          ariaFieldsetInstructions: `Sous-section Créer un événement : Contact. Ajustez vos préférences de contact.`,
          provideContact: 'Fournir des informations de contact',
        },
      };
    }

    return translation;
  };

  const OrganizerEventCard = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        viewEvent: 'View Event',
        registrations: 'Registrations',
        virtualRegistrations: 'Virtual Registrations',
        inpersonRegistrations: 'In-Person Registrations',
        messages: 'Messages',
        announcements: 'Announcements',
        editEvent: 'Edit Event',
        eventDashboard: 'Event Dashboard',
        pendingRegistrations: 'Pending Registrations',
        approvedRegistrations: 'Approved Registrations',
        approveRegisteree: 'Approve Registeree',
        removeRegisteree: 'Remove Registeree',
        pendingInPersonRegistrations: 'Pending In-Person',
        approvedInPersonRegistrations: 'Approved In-Person',
        pendingVirtualRegistrations: 'Pending Virtual',
        approvedVirtualRegistrations: 'Approved Virtual',
        fullCapacity: 'Full Capacity',
        waitingListInPerson: 'In-Person Waiting List',
        waitingListVirtual: 'Virtual Waiting List',
        inPersonEvent: 'In-Person Event',
        virtualEvent: 'Virtual Event',
        hybridEvent: 'Hybrid Event',
        eventDate: 'Event Date',

        eventPublication: 'Event Publication',
        calendarIcon: 'calendar icon',
        clockIcon: 'clock icon',
        organizer: 'Organizer',
        inPersonIcon: 'inperson icon',
        virtualIcon: 'virtual icon',
        hybridIcon: 'hybrid icon',
        archivedEvent: 'Archived Event',
        archivedIcon: 'archived icon',
        removeArchivedEvent: 'Remove Archived Event',
        iconTrash: 'trash icon',

        //modal confirmation
        confirmArchivedEventRemoval:
          'Are you sure you want to remove this archived event?',
        remove: 'Remove',
        cancel: 'Cancel',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        viewEvent: `Voir l'événement`,
        registrations: 'Inscriptions',
        virtualRegistrations: 'Inscriptions virtuelles',
        inpersonRegistrations: 'Inscriptions en personne',
        messages: 'Messages',
        announcements: 'Annonces',
        editEvent: `Modifier l'événement`,
        eventDashboard: 'Tableau de bord des événements',
        pendingRegistrations: 'Inscriptions en attente',
        approvedRegistrations: 'Inscriptions approuvées',
        approveRegisteree: `Approuver l'inscrit`,
        removeRegisteree: 'Supprimer un inscrit',
        pendingInPersonRegistrations: 'Personne en attente',
        approvedInPersonRegistrations: 'Personne approuvées',
        pendingVirtualRegistrations: 'Virtuelles en attente',
        approvedVirtualRegistrations: 'Virtuelles approuvées',
        fullCapacity: 'Pleine capacité',
        waitingListInPerson: `Liste d'attente en personne`,
        waitingListVirtual: `Liste d'attente virtuelle`,
        inPersonEvent: 'Événement en personne',
        virtualEvent: 'Événement virtuel',
        hybridEvent: 'Événement hybride',
        eventDate: `Date de l'événement`,
        eventPublication: `Publication d'événement`,
        calendarIcon: 'icône de calendrier',
        clockIcon: `icône de l'horloge`,
        organizer: 'Organisateur',
        inPersonIcon: 'icône en personne',
        virtualIcon: 'icône virtuelle',
        hybridIcon: 'icône hybride',
        archivedEvent: 'Événement archivé',
        archivedIcon: 'icône archivée',
        removeArchivedEvent: `Supprimer l'événement archivé`,
        iconTrash: 'icône poubelle',

        //modal confirmation
        confirmArchivedEventRemoval:
          'Êtes-vous sûr de vouloir supprimer cet événement archivé ?',
        remove: 'Supprimer',
        cancel: 'Annuler',
      };
    }

    return translation;
  };

  const OrganizeOverview = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        activeStandaloneEvents: 'Active Standalone Events',
        scheduledStandaloneEvents: 'Scheduled Standalone Events',
        standaloneEvents: 'Standalone Events',
        createEvent: 'Create an Event',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        activeStandaloneEvents: 'Événements autonomes actifs',
        scheduledStandaloneEvents: 'Événements autonomes programmés',
        standaloneEvents: 'Événements autonomes',
        createEvent: 'Créer un événement',
      };
    }

    return translation;
  };

  const EditOverview = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        editOverview: 'Edit Overview',
        changeList: 'Change List',
        noChange: 'No Change',
        saveChanges: 'Save Changes',
        undoChanges: 'Undo Changes',
        deleteEvent: 'Delete Event',
        cancelEvent: 'Cancel Event',

        //contact
        provideContact: 'Provide Contact',
        contactEmail: 'Contact Email',
        contactPhoneNumber: 'Contact Phone Number',

        //confirmation buttons
        cancel: 'Cancel',
        confirm: 'Confirm',
        continue: 'Continue',
        exit: 'Exit',
        delete: 'Delete',
        save: 'Save',
        undoConfirmation: 'Are you sure you want to undo changes?',
        saveConfirmation: 'Are you sure you want to save these changes?',
        confirmationModalWarningToReturn: 'Unsaved progress will be lost.',
        deletePermanently: 'Delete this event',
        deleteConfirmation: 'Are you sure you want to delete this event?',

        previewEvent: 'Preview Event',
        fieldsThatRequireCompletion: 'Fields that require completion:',

        //Schedule
        schedulePublication: 'Schedule Publication',
        startTime: 'Start Time',
        publishTime: 'Publish Time',
        togglePublicationSchedule: 'Schedule Event Publication',

        //Generic
        title: 'Title',
        description: 'Description',
        eventType: 'Event Type',
        audienceType: 'Target Audience',
        languages: 'Languages',
        date: 'Date',
        timeZone: 'Time Zone',
        timeStart: 'Start Time',
        timeEnd: 'End Time',
        agenda: 'Agenda',
        signLanguageInterpreter: 'Sign Language Interpreter',
        wheelchairAccessibility: 'Wheelchair Accessibility',
        eventImageDisplay: 'Event Display Image',
        eventAdditionalImages: 'Event Additional Images',

        //Hosting
        hostingOption: 'Hosting Option',
        virtualPlatform: 'Online Platform',
        virtualMeetingId: 'Virtual Meeting Id',
        virtualMeetingPasscode: 'Virtual Meeting Passcode',
        virtualMeetingLink: 'Virtual Meeting Link',
        location: 'Location',
        coordinates: 'Geolocation Coordinates',
        accessibilityNotes: 'Accessibility Notes',

        //Participants
        participants: 'Participants',
        participantImages: 'Participant Images',

        //Registrations
        requireRegistrations: 'Require Registrations',
        approveRegistrations: 'Approve Registrations',
        maxRegistrationsInPerson: 'Max Registrations (In Person)',
        maxRegistrationsVirtual: 'Max Registrations (Virtual)',

        privateDetails: 'Private Details',

        //Schedule
        schedulePublishDate: 'Scheduled Publication Date',
        schedulePublishTime: 'Scheduled Publication Time',
        schedulePublishTimeZone: 'Scheduled Publication Time Zone',

        //Tags
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',

        //Translations
        translationsEnabled: 'Translations Enabled',
        translationsRoomCode: 'Translations Room Code',
        translationsPassword: 'Translations Password',
        translationsRoomLanguages: 'Translations Room Languages',

        //warning
        warningList: 'Warning List',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        editOverview: `Modifier l'aperçu`,
        changeList: 'Changer la liste',
        noChange: 'Pas de changement',
        saveChanges: 'Sauvegarder les modifications',
        undoChanges: 'Annuler les changements',
        deleteEvent: `Supprimer l'événement`,
        cancelEvent: `Annuler l'événement`,

        //contact
        provideContact: 'Fournir un contact',
        contactEmail: 'E-mail de contact',
        contactPhoneNumber: 'Numéro de téléphone de contact',

        //confirmation buttons
        cancel: 'Annuler',
        confirm: 'Confirmer',
        continue: 'Continuer',
        exit: 'Sortie',
        delete: 'Supprimer',
        save: 'Sauvegarder',
        undoConfirmation: 'Êtes-vous sûr de vouloir annuler les modifications?',
        saveConfirmation:
          'Êtes-vous sûr de vouloir enregistrer ces modifications?',
        confirmationModalWarningToReturn:
          'Les progrès non enregistrés seront perdus.',
        deletePermanently: 'Supprimer cet événement',
        deleteConfirmation: 'Êtes-vous sûr de vouloir supprimer cet événement?',

        previewEvent: `Prévisualiser l'événement`,
        fieldsThatRequireCompletion: 'Champs qui nécessitent la complétion:',

        //Schedule
        schedulePublication: 'Calendrier de publication',
        startTime: 'Heure de début',
        publishTime: 'Heure de publication',
        togglePublicationSchedule: `Programmer la publication de l'événement`,

        //Generic
        title: `Titre de l'événement`,
        description: 'Description',
        eventType: `Type d'événement`,
        audienceType: 'Public cible',
        languages: 'Langues',
        date: 'Date',
        timeZone: 'Fuseau horaire',
        timeStart: 'Heure de début',
        timeEnd: 'Heure de fin',
        agenda: `Ordre du jour`,
        signLanguageInterpreter: 'Interprète en langue des signes',
        wheelchairAccessibility: 'Accessibilité en fauteuil roulant',
        accessibilityNotes: `Remarques sur l'accessibilité`,
        eventImageDisplay: `Image d'affichage de l'événement`,
        eventAdditionalImages: `Images supplémentaires de l'événement`,

        //Participants
        participants: 'Participants',
        participantImages: 'Images des participants',

        //Hosting
        hostingOption: `Options d'organisation`,
        virtualPlatform: 'Plateforme en ligne',
        virtualMeetingId: 'Id de la réunion virtuelle',
        virtualMeetingPasscode: `Code d'accès à la réunion virtuelle`,
        virtualMeetingLink: 'Lien de la réunion virtuelle',
        location: 'Emplacement',
        coordinates: 'Coordonnées de géolocalisation',

        //Registrations
        requireRegistrations: 'Exiger des inscriptions',
        approveRegistrations: `Approuver l'inscription`,
        maxRegistrationsInPerson: 'Inscriptions maximales (en personne)',
        maxRegistrationsVirtual: 'Inscriptions maximales (virtuelles)',
        privateDetails: 'Détails privés',

        //Schedule
        schedulePublishDate: 'Date de publication prévue',
        schedulePublishTime: 'Heure de publication prévue',
        schedulePublishTimeZone: 'Fuseau horaire de publication programmée',

        //Tags
        bigTags: 'Grand Balises',
        tinyTags: 'Petit Balises',

        //Translations
        translationsEnabled: 'Traductions activées',
        translationsRoomCode: 'Code de la salle de traduction',
        translationsPassword: 'Mot de passe de traduction',
        translationsRoomLanguages: 'Langues de la salle de traduction',

        //warning
        warningList: `Liste d'avertissement`,
      };
    }
    return translation;
  };

  const WarningList = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        expiredDate: 'Expired Event Date',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        expiredDate: `Date de l'événement expiré`,
      };
    }
    return translation;
  };

  const FindSpeakersQueryFilters = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        searchFilters: 'Search Filters',
        searchByLocation: 'Search By Location',
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',
        country: 'Country',
        searchByCountry: 'Search By Country',
        languages: 'Languages',
        addLanguages: 'Add Languages',
        language: 'Language',
        ariaNotListedLanguageTextInput: 'Add a non-listed language here.',
        addAnotherNonListedLanguage: 'Add Another Non-listed Language',
        addBigTags: 'Add Big Tags',
        resetFilters: 'Reset Filters',
        importEventData: 'Import Event Data',
        viewContacts: 'View Contacts',
        addCountries: 'Add Countries',
        search: 'Search',
        tagRequired: '1 Big Tag or Tiny Tag required',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        bigTags: 'Grand Balises',
        tinyTags: 'Petit Balises',
        country: 'Pays',
        languages: 'Langues',
        addLanguages: 'Ajouter des langues',
        language: 'Langue',
        addAnotherNonListedLanguage: 'Ajouter une autre langue non répertoriée',
        ariaNotListedLanguageTextInput:
          'Ajoutez ici une langue non répertoriée.',
        addBigTags: 'Ajoutez de Grand Balises',
        addTinyTags: 'Ajoutez de Petit Balises',
        resetFilters: 'Réinitialiser les filtres',
        viewContacts: 'Voir les contacts',
        addCountries: 'Ajouter des pays',
        search: 'Recherche',
        tagRequired: '1 Grand Balise ou Petit Balise requise',
      };
    }
    return translation;
  };

  const MobileFilterSelections = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        locationFilter: 'Location filter',
        languageFilter: 'Language filter',
        bigTagsFilter: 'Big Tags filter',
        tinyTagsFilter: 'Tiny Tags filter',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        locationFilter: 'Filtre de localisation',
        languageFilter: 'Filtre de langue',
        bigTagsFilter: 'Filtre des grandes balises',
        tinyTagsFilter: 'Filtre des petites balises',
      };
    }
    return translation;
  };

  const FindSpeakersQueryResults = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        searchResults: 'Search Results',
        noResults: 'No Results',
        tagMatches: 'Tag Matches',
        searchRequired: 'Search Required',
        displayTagMatchDetails: 'Display Tag Match Details',
        speakerLocation: 'Speaker Location',
        speakerLanguages: 'Speaker Languages',
        requestedContact: 'Requested Contact',
        contact: 'Contact',
        searchComplete: 'Search Complete',
        openSettings: 'Open Settings',
        closeSettings: 'Close Settings',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        searchResults: 'Résultats de la recherche',
        noResults: 'Aucun résultat',
        tagMatches: 'Correspondances de balises',
        searchRequired: 'Recherche requise',
        displayTagMatchDetails:
          'Afficher les détails des correspondances de balises',
        speakerLocation: `Emplacement de l'orateur`,
        speakerLanguages: `Langues de l'orateur`,
        requestedContact: 'Contact demandé',
        contact: 'Contact',
        searchComplete: 'Recherche terminée',
        openSettings: 'Ouvrir les paramètres',
        closeSettings: 'Fermer les paramètres',
      };
    }
    return translation;
  };

  const EventParticipant = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        description: `Add event participants (speakers, moderators etc.). Create a participant profile manually, invite Contacts through MyndFull, or email a form link for a participant to fill without a MyndFull account. Events can be published with pending invitations but participants are not displayed until their invitation has been accepted.`,
        create: 'Create',
        inviteContact: 'Invite Contact',
        emailInvitation: 'Email Invitation',
        currentParticipants: 'Current Participants',
        return: 'Return',
        saveAndReturn: 'Save and Return',
        removeParticipant: 'Remove Participant',
        dispatches: 'Dispatches',
        dispatchesInstructions:
          'Select Participants to dispatch their invites the next time you save this draft or publish this event.',
        cancel: 'Cancel',
        editMode: 'Edit Mode',
        resetToPreviousData: 'Reset to Previous Data',
        remove: 'Remove',
        removeParticipantWarning: 'Remove this Participant?',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        description: `Ajoutez des participants à l'événement (orateurs, modérateurs, etc.). Créez un profil de participant manuellement, invitez des Contacts via MyndFull ou envoyez un lien vers un formulaire que le participant peut remplir sans compte MyndFull. Les événements peuvent être publiés avec des invitations en attente, mais les participants ne sont affichés que lorsque leur invitation a été acceptée.`,
        create: 'Créer',
        inviteContact: 'Inviter un contact',
        emailInvitation: 'Invitation par e-mail',
        currentParticipants: 'Participants actuels',
        incomplete: 'Incomplet',
        return: 'Retour',
        saveAndReturn: 'Enregistrer et retourner',
        removeParticipant: 'Supprimer le participant',
        dispatches: 'Dépêches',
        dispatchesInstructions: `Sélectionnez les participants pour envoyer leurs invitations la prochaine fois que vous enregistrez ce brouillon ou publiez cet événement.`,
        cancel: 'Annuler',
        editMode: 'Mode édition',
        resetToPreviousData: 'Réinitialiser les données précédentes',
        remove: 'Supprimer',
        removeParticipantWarning: 'Supprimer ce participant ?',
      };
    }
    return translation;
  };

  const ParticipantListItem = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        participant: 'Participant',
        //types
        create: 'Created',
        inviteContact: 'Invited Contact',
        emailInvitation: 'Email Invitation',
        //statuses
        incomplete: 'Incomplete',
        pending: 'Pending Response',
        complete: 'Complete',
        accepted: 'Accepted',
        rejected: 'Rejected',
        withdrew: 'Withdrew',
        dispatching: 'Dispatching',
        notDispatched: 'Not Dispatched',
        //
        requiresEmail: 'Requires Email',
        invalidEmail: 'Invalid Email',
        duplicateEmail: 'Duplicate Email',
        emailInvitationExpired: 'Email Invitation Expired',
        requiresRoomLanguages: 'Requires Room Languages',
        languageConflict: 'Language Selection Conflict',
        readyToDispatch: 'Ready to Dispatch',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        participant: 'Participant',

        //types
        create: 'Created',
        inviteContact: 'Invited Contact',
        emailInvitation: 'Email Invitation',

        //statuses
        incomplete: 'Incomplete',
        pending: 'Pending Response',
        complete: 'Complete',
        accepted: 'Accepter',
        rejected: 'Rejeter',
        withdrew: 'Retirer',
        dispatching: `Envoi`,
        notDispatched: 'Non envoyé',
        //
        requiresEmail: 'Nécessite un e-mail',
        invalidEmail: 'Email invalide',
        duplicateEmail: 'E-mail en double',
        emailInvitationExpired: `L'invitation par e-mail a expiré`,
        requiresRoomLanguages: 'Nécessite des langues de salle',
        languageConflict: 'Conflit de sélection de langue',
        readyToDispatch: 'Prêt à envoyer',
      };
    }
    return translation;
  };

  const ParticipantListItemCompletionStatusNonDispatchable = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        complete: 'Complete',
        incomplete: 'Incomplete',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        complete: 'Complete',
        incomplete: 'Incomplete',
      };
    }
    return translation;
  };

  const ParticipantListItemCompletionStatusDispatches = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        dispatchesAvailable: 'Dispatches Available',
        dispatching: 'Dispatching',
        allDispatchesIssued: 'All Dispatches Issued',
        dispatchAvailable: 'Dispatch Available',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        dispatchesAvailable: 'Envois disponibles',
        dispatching: 'Envoi',
        allDispatchesIssued: 'Tous les dispatches émis',
        dispatchAvailable: 'Expédition disponible',
      };
    }
    return translation;
  };

  const ParticipantListItemCompletionStatusDispatchesSingularDispatch = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        dispatchesAvailable: 'Dispatch Available',
        dispatching: 'Dispatching',
        allDispatchesIssued: 'All Dispatches Issued',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        dispatchesAvailable: 'Expédition disponible',
        dispatching: 'Envoi',
        allDispatchesIssued: 'Tous les dispatches émis',
      };
    }
    return translation;
  };

  const ParticipantListItemCompletionStatusTranslationsEmailInvitation = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        complete: 'Ready to Dispatch',
        incomplete: 'Email Incomplete',
        requiresRoomLanguages: 'Requires Room Languages',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        complete: 'Prêt à envoyer',
        incomplete: 'Courriel incomplet',
        requiresRoomLanguages: 'Nécessite des langues de salle',
      };
    }
    return translation;
  };

  const EventAdditionalImages = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        ariaCropperDescription: 'Add an additional image for your event.',
        imageCaptionAndAlt: 'Caption & Alt Text',
        imageAltText: 'Alt Text',
        removeImage: 'Remove Image',
        standalone: 'Image is displayed as standalone',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        ariaCropperDescription:
          'Ajoutez une image supplémentaire pour votre événement.',
        imageCaptionAndAlt: 'Légende et texte alternatif',
        imageAltText: 'Texte alternatif',
        removeImage: `Supprimer l'image`,
        standalone: `L'image est affichée de manière autonome`,
      };
    }
    return translation;
  };

  const ParticipantCreated = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        pronouns: 'Pronouns',
        prefix: 'Prefix',
        firstName: 'First Name',
        lastName: 'Last Name',
        profilePicture: 'Profile Picture',
        personalBio: 'Personal Bio',
        eventRole: 'Event Role',
        eventRolePlaceholder: 'e.g. Speaker, Moderator',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        pronouns: 'Pronoms',
        prefix: 'Préfixe',
        firstName: 'Prénom',
        lastName: 'Nom de famille',
        profilePicture: 'Photo de profil',
        personalBio: 'Bio personnelle',
        eventRole: `Rôle de l'événement`,
        eventRolePlaceholder: 'par ex. Orateur, Modérateur',
      };
    }
    return translation;
  };

  const EventParticipantsEmailInvitationForm = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        participantTranslationsForm: 'Participant Translations Form',
        invitationExpired: 'Invitation Expired',
        invitationExpiredDescription:
          'Your invitation has expired. Please contact the event organizer and request a new email invitation.',
        eventNotFound: 'Event Not Found',
        eventNotFoundDescription:
          'The event for this email invitation cannot be found. The event may have been removed. Please contact the event organizer for more details.',
        participantProfile: 'Participant Profile',
        viewEvent: 'View Event',
        submit: 'Submit',
        participantInformationSent: 'Participant Information Sent',
        participantInformationSentDescription:
          'Your participant information has been successfully sent to the organizer. Thank you and we hope you have an insightful and rewarding experience at your upcoming event.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        participantTranslationsForm:
          'Formulaire de traduction des participants',
        invitationExpired: 'Invitation expirée',
        invitationExpiredDescription: `Votre invitation a expiré. Veuillez contacter l'organisateur de l'événement et demander une nouvelle invitation par e-mail.`,
        eventNotFound: 'Événement non trouvé',
        eventNotFoundDescription: `Impossible de trouver l'événement correspondant à cette invitation par e-mail. L'événement a peut-être été supprimé. Veuillez contacter l'organisateur de l'événement pour plus de détails.`,
        participantProfile: 'Profil du participant',
        viewEvent: `Voir l'événement`,
        submit: 'Soumettre',
        participantInformationSent: 'Informations sur le participant envoyées',
        participantInformationSentDescription: `Vos informations de participant ont été envoyées avec succès à l'organisateur. Nous vous remercions et espérons que vous vivrez une expérience enrichissante et enrichissante lors de votre prochain événement.`,
      };
    }
    return translation;
  };

  const ParticipantInviteContact = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        eventRole: 'Event Role',
        eventRolePlaceholder: 'e.g. Speaker, Moderator',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        eventRole: `Rôle de l'événement`,
        eventRolePlaceholder: 'par ex. Orateur, Modérateur',
      };
    }
    return translation;
  };

  const ParticipantEmailInvitation = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        eventRole: 'Event Role',
        eventRolePlaceholder: 'e.g. Speaker, Moderator',
        emailInvitationLanguage: 'Email Invitation Language',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        eventRole: `Rôle de l'événement`,
        eventRolePlaceholder: 'par ex. Orateur, Modérateur',
        emailInvitationLanguage: `Langue de l'invitation par e-mail`,
      };
    }
    return translation;
  };

  const EditEventConflicts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        conflictNotice: `The current changes will create a conflict within your event. Please consider each conflict option carefully because saved changes are not reversible.`,
        removingRegistrationsConflict:
          'You are removing registrations. This will unregister all attendees.',
        removingInPersonLocationConflict:
          'You are removing the in-person hosting option, but this event has in-person registrations.',
        removingOnlineLocationConflict:
          'You are removing the virtual hosting option, but this event has in-person registrations.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        conflictNotice: `Les modifications actuelles créeront un conflit au sein de votre événement. Veuillez examiner attentivement chaque option de conflit, car les modifications enregistrées sont irréversibles.`,
        removingRegistrationsConflict:
          'Vous supprimez des inscriptions. Cela entraînera la désinscription de tous les participants.',
        removingInPersonLocationConflict: `Vous supprimez l'option d'hébergement en personne, mais cet événement propose des inscriptions en personne.`,
        removingOnlineLocationConflict: `Vous supprimez l'option d'hébergement virtuel, mais cet événement propose des inscriptions en personne.`,
      };
    }
    return translation;
  };

  return {
    EditOverview: EditOverview(),
    WarningList: WarningList(),
    OrganizeOverview: OrganizeOverview(),
    OrganizerEventCard: OrganizerEventCard(),
    CreateEvent: CreateEvent(),
    FindSpeakersQueryFilters: FindSpeakersQueryFilters(),
    MobileFilterSelections: MobileFilterSelections(),
    FindSpeakersQueryResults: FindSpeakersQueryResults(),
    EventParticipant: EventParticipant(),
    ParticipantListItem: ParticipantListItem(),
    EventAdditionalImages: EventAdditionalImages(),
    EditEventConflicts: EditEventConflicts(),

    ParticipantCreated: ParticipantCreated(),
    ParticipantInviteContact: ParticipantInviteContact(),
    ParticipantEmailInvitation: ParticipantEmailInvitation(),
    EventParticipantsEmailInvitationForm:
      EventParticipantsEmailInvitationForm(),

    ParticipantListItemCompletionStatusNonDispatchable:
      ParticipantListItemCompletionStatusNonDispatchable(),
    ParticipantListItemCompletionStatusTranslationsEmailInvitation:
      ParticipantListItemCompletionStatusTranslationsEmailInvitation(),
    ParticipantListItemCompletionStatusDispatches:
      ParticipantListItemCompletionStatusDispatches(),
    ParticipantListItemCompletionStatusDispatchesSingularDispatch:
      ParticipantListItemCompletionStatusDispatchesSingularDispatch(),
  };
};

export default useLanguageOrganize;

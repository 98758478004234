import React from 'react';

import { useEffect, useRef, useState } from 'react';
//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//language

//components
import { DemoEventCardSkeleton, DemoGhostCards } from '../Demo/DemoSkeletons';
import DemoEventSettings from '../Demo/DemoEventSettings';

//utils
import { settings } from '../../../../assets/icons';
import {
  demoBigTags,
  demoTinyTagGroups,
} from '../Demo/exploreFeaturesDemoData';
import useLanguageData from '../../../../language/useLanguageData';
import FindEventsToolbarInteractionSummary from './FindEventsToolbarInteractionSummary';

const FindEventsToolbar = () => {
  //hooks
  const { width } = useSettings();
  const { DemoTinyTags, DemoCommunityFollowing, DemoCommunityFollowingGroups } =
    useLanguageData();

  //UI state
  const [toolbarActiveHighlight, setToolbarActiveHighlight] = useState();
  const [fullRowEvents, setFullRowEvents] = useState();
  const [remainingEvents, setRemainingEvents] = useState([]);

  //demo state
  const [evDemoCards, setEvDemoCards] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const [view, setView] = useState('bigtags');
  const [settingsListOpened, setSettingsListOpened] = useState(true);

  const [searchDirection, setSearchDirection] = useState('ascending');
  const [searchFormat, setSearchFormat] = useState('inPerson');
  const [searchCategory, setSearchCategory] = useState('distance');

  const [selectedMyEvents, setSelectedMyEvents] = useState([
    'saved',
    'registered',
  ]);
  const [selectedBigTags, setSelectedBigTags] = useState(['hist']);
  const [selectedTinyTags, setSelectedTinyTags] = useState(DemoTinyTags);
  const [selectedTinyTagGroupIds, setSelectedTinyTagGroupIds] = useState([
    11111,
  ]);
  const [selectedFollowingIds, setSelectedFollowingIds] = useState([
    '67d30c83f0c2c4752f6e3ad6',
    '67d30c83f0c2c4752f6e3c65',
    '67d30c83f0c2c4752f23f3c6',
  ]);
  const [selectedFollowingGroupIds, setSelectedFollowingGroupIds] = useState([
    123123123, 234243234, 3453453453,
  ]);

  const [selectAllBTBtn, setSelectAllBTBtn] = useState(true);
  const [selectAllTTBtn, setSelectAllTTBtn] = useState(true);
  const [selectAllAccountsFollowingBtn, setSelectAllAccountsFollowingBtn] =
    useState(true);
  const [selectAllMyEventsBtn, setSelectAllMyEventsBtn] = useState(true);

  //UI
  const [cardsPerRow, setCardsPerRow] = useState(4);

  const initializationTimer = useRef();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    initializationTimer.current = setTimeout(() => {
      setInitialized(true);
    }, 1200);
  }, []); //initialization timer to prevent

  useEffect(() => {
    if (width <= 520) {
      return setCardsPerRow(2);
    } else if (width <= 660) {
      return setCardsPerRow(3);
    } else {
      return setCardsPerRow(4);
    }
  }, [width]);

  useEffect(() => {
    if (initialized && width < 1285) {
      setToolbarActiveHighlight('filters');
    }
  }, [searchFormat, searchCategory, searchDirection]);

  useEffect(() => {
    if (initialized && width < 1285) {
      setToolbarActiveHighlight('tags');
    }
  }, [
    selectedMyEvents,
    selectedBigTags,
    selectedTinyTags,
    selectedTinyTagGroupIds,
    selectedFollowingIds,
    selectedFollowingGroupIds,
  ]);

  useEffect(() => {
    let newArr = [];

    if (view === 'bigtags') {
      if (selectedBigTags?.length === 0) {
        return setEvDemoCards([]);
      }

      if (selectedBigTags?.includes('hist')) {
        if (searchFormat === 'inPerson') {
          newArr.push({}, {});
        } else {
          newArr.push({}, {}, {});
        }
      }
      if (selectedBigTags?.includes('edu')) {
        if (searchFormat === 'inPerson') {
          newArr.push({});
        } else {
          newArr.push({}, {});
        }
      }
    }

    if (view === 'tinytags') {
      if (
        selectedTinyTags?.length === 0 &&
        selectedTinyTagGroupIds?.length === 0
      ) {
        return setEvDemoCards([]);
      }

      if (selectedTinyTags?.includes(DemoTinyTags[0])) {
        if (searchFormat === 'inPerson') {
          newArr.push({}, {});
        } else {
          newArr.push({}, {}, {});
        }
      }
      if (selectedTinyTags?.includes(DemoTinyTags[1])) {
        if (searchFormat === 'inPerson') {
          newArr.push({});
        } else {
          newArr.push({}, {});
        }
      }

      if (selectedTinyTagGroupIds?.includes(11111)) {
        if (searchFormat === 'inPerson') {
          newArr.push();
        } else {
          newArr.push({});
        }
      }

      if (selectedTinyTagGroupIds?.includes(22222)) {
        if (searchFormat === 'inPerson') {
          newArr.push({});
        } else {
          newArr.push({}, {});
        }
      }
    }

    if (view === 'community') {
      if (
        selectedFollowingIds?.length === 0 &&
        selectedFollowingGroupIds?.length === 0
      ) {
        return setEvDemoCards([]);
      }

      if (selectedFollowingIds?.includes('67d30c83f0c2c4752f6e3ad6')) {
        if (searchFormat === 'inPerson') {
          newArr.push({}, {});
        } else {
          newArr.push();
        }
      }
      if (selectedFollowingIds?.includes('67d30c83f0c2c4752f6e3c65')) {
        if (searchFormat === 'inPerson') {
          newArr.push({}, {});
        } else {
          newArr.push({});
        }
      }
      if (selectedFollowingIds?.includes('67d30c83f0c2c4752f23f3c6')) {
        if (searchFormat === 'inPerson') {
          newArr.push();
        } else {
          newArr.push({});
        }
      }

      if (selectedFollowingGroupIds?.includes(123123123)) {
        if (searchFormat === 'inPerson') {
          newArr.push({});
        } else {
          newArr.push({}, {});
        }
      }

      if (selectedFollowingGroupIds?.includes(234243234)) {
        if (searchFormat === 'inPerson') {
          newArr.push({});
        } else {
          newArr.push({}, {});
        }
      }

      if (selectedFollowingGroupIds?.includes(3453453453)) {
        if (searchFormat === 'inPerson') {
          newArr.push({}, {});
        } else {
          newArr.push({}, {});
        }
      }
    }

    if (width <= 660) {
      if (newArr?.length > 6) {
        newArr = [{}, {}, {}, {}, {}, {}];
      }
    }

    if (width <= 520) {
      if (newArr?.length > 4) {
        newArr = [{}, {}, {}, {}];
      }
    }

    setEvDemoCards(newArr);
  }, [
    view,
    searchFormat,
    selectedBigTags,
    selectedTinyTags,
    selectedTinyTagGroupIds,
    selectedFollowingIds,
    selectedFollowingGroupIds,
  ]);

  //UI functions
  useEffect(() => {
    if (evDemoCards?.length > 0) {
      const totalFullRows = Math.floor(evDemoCards.length / cardsPerRow);
      const fullEvents = evDemoCards.slice(0, totalFullRows * cardsPerRow);
      const remaining = evDemoCards.slice(totalFullRows * cardsPerRow);

      setFullRowEvents(fullEvents);
      setRemainingEvents(remaining);
    } else {
      setRemainingEvents([]);
      setFullRowEvents();
    }
  }, [
    evDemoCards,
    view,
    cardsPerRow,
    searchFormat,
    selectedBigTags,
    selectedTinyTags,
    selectedTinyTagGroupIds,
    selectedFollowingIds,
    selectedFollowingGroupIds,
  ]); //calculate full card rows + remaining cardsPerRow

  //bigtags
  function addSelectedBTs(bTs) {
    if (bTs === 'addAll') {
      setSelectedBigTags(demoBigTags);
    } else {
      const newArr = [...selectedBigTags];
      newArr.push(bTs);
      setSelectedBigTags(newArr);
    }
  } //different than redux version;

  function removeSelectedBTs(str) {
    const newArr = [...selectedBigTags];
    newArr.splice(newArr.indexOf(str), 1);
    setSelectedBigTags(newArr);
  }

  function removeAllSelectedBTs() {
    setSelectedBigTags([]);
  }

  //tinytags
  function addSelectedTinyTags(tTs) {
    if (tTs === 'addAll') {
      setSelectedTinyTags(DemoTinyTags);
    } else {
      const newArr = [...selectedTinyTags];
      newArr.push(tTs);
      setSelectedTinyTags(newArr);
    }
  } //different than redux version;

  function addSelectedTinyTagGroupIds(tGs) {
    if (tGs === 'addAll') {
      const groupIds = demoTinyTagGroups?.map((tG) => tG.id);
      setSelectedTinyTagGroupIds(groupIds);
    } else {
      const newArr = [...selectedTinyTagGroupIds];
      newArr.push(tGs);
      setSelectedTinyTagGroupIds(newArr);
    }
  } //different than redux version;

  function removeSelectedTinyTags(str) {
    const newArr = [...selectedTinyTags];
    newArr.splice(newArr.indexOf(str), 1);
    setSelectedTinyTags(newArr);
  }

  function removeSelectedTinyTagGroupIds(str) {
    const newArr = [...selectedTinyTagGroupIds];
    newArr.splice(newArr.indexOf(str), 1);
    setSelectedTinyTagGroupIds(newArr);
  }

  function removeAllSelectedTinyTags() {
    setSelectedTinyTags([]);
  }

  function removeAllSelectedTinyTagGroupIds() {
    setSelectedTinyTagGroupIds([]);
  }

  //community
  function addSelectedAccountsFollowingId(following) {
    if (following === 'addAll') {
      setSelectedFollowingIds([
        '67d30c83f0c2c4752f6e3ad6',
        '67d30c83f0c2c4752f6e3c65',
        '67d30c83f0c2c4752f23f3c6',
      ]);
    } else {
      const newArr = [...selectedFollowingIds];
      newArr.push(following);
      setSelectedFollowingIds(newArr);
    }
  } //different than redux version;

  function addSelectedAccountsFollowingGroupIds(followingGroup) {
    if (followingGroup === 'addAll') {
      setSelectedFollowingGroupIds([123123123, 234243234, 3453453453]);
    } else {
      const newArr = [...selectedFollowingGroupIds];
      newArr.push(followingGroup);
      setSelectedFollowingGroupIds(newArr);
    }
  } //different than redux version;

  function removeSelectedAccountsFollowingId(str) {
    const newArr = [...selectedFollowingIds];
    newArr.splice(newArr.indexOf(str), 1);
    setSelectedFollowingIds(newArr);
  }
  function removeSelectedAccountsFollowingGroupIds(str) {
    const newArr = [...selectedFollowingGroupIds];
    newArr.splice(newArr.indexOf(str), 1);
    setSelectedFollowingGroupIds(newArr);
  }

  function removeAllSelectedAccountsFollowingIds() {
    setSelectedFollowingIds([]);
  }

  function removeAllSelectedAccountsFollowingGroupIds() {
    setSelectedFollowingGroupIds([]);
  }

  return (
    <>
      <div className="demo-toolbar-info--padding">
        <div className="flex-row align-center mrg-t24">
          <img
            src={settings}
            alt=""
            className="filter-theme mrg-r12"
            style={{ height: '28px' }}
          />
          <h4 className="features-title access-ob access-o6" tabIndex="0">
            MyndFull's Events Toolbar
          </h4>
        </div>

        <p
          className="features-description mrg-tb24 access-ob access-o6"
          tabIndex="0"
        >
          Through MyndFull's unique integration of account preferences and
          filters, it has never been easier to find the events that interest you
          the most.
        </p>
        <h5
          className="fs21 fs18--ph text-center mrg-tb36 mrg-auto-lr italic access-ob access-o6"
          tabIndex="0"
        >
          Interact to learn more!
        </h5>
      </div>

      <div className="full-width flex-row justify-center">
        {width > 950 && (
          <FindEventsToolbarInteractionSummary
            toolbarActiveHighlight={toolbarActiveHighlight}
            side={'left'}
          />
        )}

        <div
          className="demo-events border-reg"
          onMouseLeave={() => setToolbarActiveHighlight()}
        >
          <DemoEventSettings
            view={view}
            setView={setView}
            toolbarActiveHighlight={toolbarActiveHighlight}
            setToolbarActiveHighlight={setToolbarActiveHighlight}
            searchDirection={searchDirection}
            setSearchDirection={setSearchDirection}
            searchFormat={searchFormat}
            setSearchFormat={setSearchFormat}
            searchCategory={searchCategory}
            setSearchCategory={setSearchCategory}
            settingsListOpened={settingsListOpened}
            setSettingsListOpened={setSettingsListOpened}
            demoBigTags={demoBigTags}
            demoTinyTags={DemoTinyTags}
            demoTinyTagGroups={demoTinyTagGroups}
            demoCommunityFollowing={DemoCommunityFollowing}
            demoCommunityFollowingGroups={DemoCommunityFollowingGroups}
            selectedMyEvents={selectedMyEvents}
            selectedBigTags={selectedBigTags}
            selectedTinyTags={selectedTinyTags}
            selectedTinyTagGroupIds={selectedTinyTagGroupIds}
            selectedFollowingIds={selectedFollowingIds}
            selectedFollowingGroupIds={selectedFollowingGroupIds}
            width={width}
            selectAllBTBtn={selectAllBTBtn}
            removeSelectedBTs={removeSelectedBTs}
            addSelectedBTs={addSelectedBTs}
            removeAllSelectedBTs={removeAllSelectedBTs}
            setSelectAllBTBtn={setSelectAllBTBtn}
            selectAllMyEventsBtn={selectAllMyEventsBtn}
            setSelectedMyEvents={setSelectedMyEvents}
            setSelectAllMyEventsBtn={setSelectAllMyEventsBtn}
            selectAllTTBtn={selectAllTTBtn}
            removeSelectedTinyTags={removeSelectedTinyTags}
            addSelectedTinyTags={addSelectedTinyTags}
            removeSelectedTinyTagGroupIds={removeSelectedTinyTagGroupIds}
            addSelectedTinyTagGroupIds={addSelectedTinyTagGroupIds}
            removeAllSelectedTinyTagGroupIds={removeAllSelectedTinyTagGroupIds}
            setSelectAllTTBtn={setSelectAllTTBtn}
            removeAllSelectedTinyTags={removeAllSelectedTinyTags}
            selectAllAccountsFollowingBtn={selectAllAccountsFollowingBtn}
            setSelectAllAccountsFollowingBtn={setSelectAllAccountsFollowingBtn}
            removeAllSelectedAccountsFollowingIds={
              removeAllSelectedAccountsFollowingIds
            }
            removeSelectedAccountsFollowingId={
              removeSelectedAccountsFollowingId
            }
            addSelectedAccountsFollowingId={addSelectedAccountsFollowingId}
            removeSelectedAccountsFollowingGroupIds={
              removeSelectedAccountsFollowingGroupIds
            }
            addSelectedAccountsFollowingGroupIds={
              addSelectedAccountsFollowingGroupIds
            }
            removeAllSelectedAccountsFollowingGroupIds={
              removeAllSelectedAccountsFollowingGroupIds
            }
          />
          <div className="demo-events-grid__wrapper">
            <div className="demo-events-grid">
              {evDemoCards?.length > 0 &&
                fullRowEvents?.map((_, index) => {
                  return <DemoEventCardSkeleton key={`demoCard-${index}`} />;
                })}
              {remainingEvents?.length > 0 &&
                remainingEvents?.map((_, index) => {
                  return <DemoEventCardSkeleton key={`demoCard-${index}`} />;
                })}
              {DemoGhostCards(remainingEvents?.length || 0, cardsPerRow)}
            </div>
          </div>
        </div>

        {width > 950 && (
          <FindEventsToolbarInteractionSummary
            toolbarActiveHighlight={toolbarActiveHighlight}
            side={'right'}
          />
        )}
      </div>
    </>
  );
};

export default FindEventsToolbar;

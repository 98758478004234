import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../../account/useAccountHandlers';
import { useGenericModal } from '../../../../../../context/GenericModalProvider';
import { useSettings } from '../../../../../../context/SettingsProvider';
import { useEventParticipantsHandlers } from '../../../../organize/eventParticipants/useEventParticipantsHandlers';
import { useNoticeHandlers } from '../../../useNoticeHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import Spinner from '../../../../../../components/Spinner/Spinner';
import ProfilePersonalModal from '../../../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';
import ButtonBlock from '../../../../../../components/ButtonBlock/ButtonBlock';

//utils
import { cancel, checkmark, visible } from '../../../../../../assets/icons';

const NoticeInviteParticipant = ({ n, openedNotice, setOpenedNotice }) => {
  //hooks
  const navigate = useNavigate();
  const { accountId } = useAccountReduxHandlers();
  const { setGenericModal } = useGenericModal();
  const { setOpenMobileNotificationsMenu } = useSettings();
  const {
    handleMyEventParticipationInviteResponse,
    eventParticipantHandlerIsLoading,
  } = useEventParticipantsHandlers();
  const { removeNoticeIsLoading } = useNoticeHandlers();

  //language
  const { IconAlts, NoticeSpeakerComponents } = useLanguageComponents();

  //ui
  const [isLoading, setIsLoading] = useState(false);

  //ui
  useEffect(() => {
    if (eventParticipantHandlerIsLoading || removeNoticeIsLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [eventParticipantHandlerIsLoading, removeNoticeIsLoading]);

  //function
  function handleViewEvent(n) {
    let eventId;

    if (n?.eventInvitation?.draftId) {
      eventId = n?.eventInvitation?.draftId;
    }
    if (n?.eventInvitation?.eventId) {
      eventId = n?.eventInvitation?.eventId;
    }

    if (n?.eventInvitation?.scheduledEventId) {
      eventId = n?.eventInvitation?.scheduledEventId;
    }
    navigate(`/events/sa/${eventId}`);
  }

  function acceptInvite() {
    if (!isLoading) {
      handleMyEventParticipationInviteResponse({
        accountId,
        notice: n,
        inviteResponse: 'accepted',
      });
      setOpenedNotice();
    }
  }

  function rejectInvite() {
    if (!isLoading) {
      handleMyEventParticipationInviteResponse({
        accountId,
        notice: n,
        inviteResponse: 'rejected',
      });
      setOpenedNotice();
    }
  }

  function viewProfile() {
    setOpenMobileNotificationsMenu(false);
    setGenericModal(
      <ProfilePersonalModal
        closeModalFocusId={`noticeContactRequest-${n?.from?.account?._id}`}
        profileData={n?.from?.account}
        reopenNotices={true}
      />
    );
  }

  function viewEvent() {
    setOpenMobileNotificationsMenu(false);
    handleViewEvent(n);
  }

  return (
    <>
      {isLoading ? (
        <Spinner minHeight={'44px'} />
      ) : (
        <>
          {openedNotice === n._id && (
            <div className="noticeOverlay-action-btns__container">
              {n?.noticeHistory && (
                <>
                  <div className="divider-dotted " />
                  {n?.noticeHistory === 'accepted' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.accepted}
                    </p>
                  )}
                  {n?.noticeHistory === 'rejected' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.rejected}
                    </p>
                  )}
                </>
              )}

              {!n?.noticeHistory && (
                <>
                  <div className="flex-row full-width align-center space-between mrg-b24">
                    <ButtonBlock
                      icon={checkmark}
                      btnText={NoticeSpeakerComponents.accept}
                      handler={acceptInvite}
                      customWrapper={
                        'fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard mrg-r12'
                      }
                      iconAlt={IconAlts.iconCheckmark}
                      iconCustomStyle={{ height: '21px' }}
                      iconCustomClass={'mrg-t6'}
                    />

                    <ButtonBlock
                      icon={cancel}
                      btnText={NoticeSpeakerComponents.reject}
                      handler={rejectInvite}
                      customWrapper={
                        'fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard'
                      }
                      tapHighlight={'highlight-it-theme--tap'}
                      iconAlt={IconAlts.iconCancel}
                      iconCustomStyle={{ height: '21px' }}
                      iconCustomClass={'mrg-t6'}
                    />
                  </div>

                  <ButtonBlock
                    icon={visible}
                    btnText={NoticeSpeakerComponents.viewProfile}
                    handler={viewProfile}
                    customWrapper={
                      'flex-row fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard align-center flex-center'
                    }
                    iconCustomStyle={{ height: '18px' }}
                    iconAlt={IconAlts.iconView}
                    iconAltPosition={true}
                    iconCustomClass={'mrg-r12'}
                  />

                  <ButtonBlock
                    icon={visible}
                    btnText={NoticeSpeakerComponents.viewEvent}
                    handler={viewEvent}
                    customWrapper={
                      'flex-row fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard align-center flex-center mrg-t24'
                    }
                    iconCustomStyle={{ height: '18px' }}
                    iconAlt={IconAlts.iconView}
                    iconAltPosition={true}
                    iconCustomClass={'mrg-r12'}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NoticeInviteParticipant;

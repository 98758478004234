import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

//Hooks
import { useLazyMyAdminAccountQuery } from '../account/adminAccountApiSlice';
import {
  useLazyAdminLogoutQuery,
  useLazyAdminRefreshQuery,
} from './adminAuthApiSlice';

//Components
import BlankScreen from '../../../layout/BlankScreen';
import { useAdminAuthReduxHandlers } from './useAdminAuthHandlers';

const PersistLoginAdmin = () => {
  //Hooks
  const [adminNoToken] = useLazyAdminRefreshQuery();
  const [adminLogout] = useLazyAdminLogoutQuery();
  const [myAdminAccount] = useLazyMyAdminAccountQuery();
  const { adminAuthCurrentToken, adminAuthisLoggingOut, adminAuthisLoggingIn } =
    useAdminAuthReduxHandlers();

  //Component functions
  const [isLoading, setIsLoading] = useState(true);

  //Initialize functions
  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        const token = await adminNoToken();

        if (!token.error) {
          const account = await myAdminAccount();

          if (account.status === 'rejected') {
            adminLogout();
          }
        }
      } catch (err) {
        return null;
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    !adminAuthCurrentToken ? verifyRefreshToken() : setIsLoading(false);
    return () => {
      isMounted = false;
    };

    /*eslint-disable-next-line*/
  }, []);

  //prettier-ignore
  return (
    <>
     {(isLoading || adminAuthisLoggingOut || adminAuthisLoggingIn)
        ? <BlankScreen />
        : <Outlet />
      }
    </>
  );
};

export default PersistLoginAdmin;

import { createContext, useContext, useEffect, useState } from 'react';
import { useSettings } from './SettingsProvider';

const GenericModal = createContext({});

export const GenericModalProvider = ({ children }) => {
  //Hooks
  const { openSlideMenu } = useSettings();

  //Context state
  const [genericModal, setGenericModal] = useState();
  const [genericModalMobileFloat, setGenericModalMobileFloat] = useState();

  const [genericModalSelectionState, setGenericModalSelectionState] =
    useState();
  const [genericModalSelectionStateId, setGenericModalSelectionStateId] =
    useState();
  const [invalidSelection, setInvalidSelection] = useState(false);
  //for invalid selection, modal close handler should setInvalidSelection(false) instead of continuing with selections if invalid selection is true. for ex., see SelectionsModal handleAddOptsModal

  //UI state
  const [closeModalFocusId, setCloseModalFocusId] = useState();

  let resetTimer;

  //UI functions
  useEffect(() => {
    if (openSlideMenu && genericModal) {
      setCloseModalFocusId();
      handleCloseModal();
    }
    /*eslint-disable-next-line*/
  }, [openSlideMenu]); //auto close BT list if slidemnu opens

  useEffect(() => {
    if (genericModal) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'scroll';
      };
    }
  }, [genericModal]); //removes scroll when component mounts so theres not two scroll bars

  //Context functions
  function handleCloseModal() {
    let el;
    if (closeModalFocusId) {
      el = document.getElementById(`${closeModalFocusId}`);
    }

    if (el) {
      el.focus();
    }
    setCloseModalFocusId();

    if (genericModal) {
      setGenericModal();
    } else if (genericModalMobileFloat) {
      setGenericModalMobileFloat();
    }

    if (invalidSelection) {
      resetTimer = setTimeout(() => {
        setInvalidSelection(false);
        clearTimeout(resetTimer);
      }, 1500);
    }
  }

  return (
    <GenericModal.Provider
      value={{
        genericModal,
        setGenericModal,
        handleCloseModal,
        setCloseModalFocusId,
        genericModalMobileFloat,
        setGenericModalMobileFloat,
        genericModalSelectionState,
        setGenericModalSelectionState,
        genericModalSelectionStateId,
        setGenericModalSelectionStateId,
        invalidSelection,
        setInvalidSelection,
      }}
    >
      {children}
    </GenericModal.Provider>
  );
};

export const useGenericModal = () => {
  return useContext(GenericModal);
};

import { useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useNavigate } from 'react-router-dom';
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import TextArea from '../../../../../components/TextArea/TextArea';
import PasswordInput from '../../../../../components/TextArea/PasswordInput';

//utility
import {
  flag,
  return_curved_arrow,
  warning,
} from '../../../../../assets/icons';
import { image_gears } from '../../../../../assets/images';
import moveCarotToEnd from '../../../../../utils/UI//moveCarotToEnd';
import { axiosLimited } from '../../../../../axios/axios';
import { useTranslationsHandlers } from '../../useTranslationsHandlers';

const TranslationCreateRoomAuth = ({ setPage }) => {
  //hooks
  const navigate = useNavigate();
  const { handleMobileTap, isChrome, isMobileDevice } = useSettings();
  const { handleError } = useApiStatus();
  const {
    setRoomCodeId,
    roomCode,
    setRoomCode,
    setSavedTranslationsRoomData,
    password,
    setPassword,
    connectTranslationRoomSocket,
    setDefaultAttendeePassword,
    setJoinRoomPasswordToggle,
    userSessionIdTranslations,
    translationsConnectionIsInitializing,
    setHostTimeZone,
  } = useTranslationRoomSocket();
  const { handleTranslationsLogout } = useTranslationsHandlers();

  //language
  const { TranslationCreateRoomAuth } = useLanguageTranslationRoom();

  //state
  const [isLoadingAuth, setIsLoadingAuth] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [roomCodeIsLocked, setRoomCodeIsLocked] = useState(false);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [tapHighlightGetRoomCode, setTapHighlightGetRoomCode] = useState(false);

  //API functions
  async function handleSubmit(roomCode, password) {
    setIsLoadingAuth(true);

    try {
      if (!isChrome) {
        setPasswordReset(!passwordReset);
        throw new Error('chromeIsRequired');
      }

      if (!roomCode || !password) {
        throw new Error('incompleteInformation');
      }

      //successful
      if (roomCode?.length === 0) throw new Error('tryAgain');
      let formattedRoomCode = roomCode.toUpperCase().trim();

      //authenticate roomcode/password
      setRoomCode(formattedRoomCode);
      setPasswordReset(!passwordReset);

      const response = await axiosLimited.post(
        '/api/main/auth/translations/hostLogin',
        {
          roomCode: formattedRoomCode,
          password: password,
        }
      );

      if (response?.data?.roomCodePasswordValid) {
        setJoinRoomPasswordToggle(true);
        setDefaultAttendeePassword(response?.data?.defaultAttendeePassword);
        setSavedTranslationsRoomData(response?.data?.savedTranslationsRoomData);
        setRoomCodeId(response?.data?.roomCodeId);
        setHostTimeZone(response?.data?.hostTimeZone);
        await connectTranslationRoomSocket(
          true,
          formattedRoomCode,
          userSessionIdTranslations,
          response?.data?.translationsAuth
        );
      }
      setPasswordReset(!passwordReset);
      setIsLoadingAuth(false);
    } catch (error) {
      setPasswordReset(!passwordReset);
      setIsLoadingAuth(false);

      console.log(error?.message);
      if (error?.message === 'roomCodeLocked') {
        setRoomCodeIsLocked(true);
      } else {
        setRoomCodeIsLocked(false);
        return handleError({
          message: error?.message || error?.response?.data?.message,
          id: Date.now(),
          origin: 'TranslationCreateRoomAuth.js/handleSubmit',
        });
      }
    }
  }

  function handleKeyDown(e, element) {
    if (e.key === 'Enter') {
      if (element === 'roomCode') {
        moveCarotToEnd(null, 'passwordInput');
      } else {
        handleSubmit(roomCode, password);
      }
    }
  }

  return (
    <div className={`translations__container ${!imgLoaded ? 'remove' : ''}`}>
      <img
        src={image_gears}
        alt="X"
        style={{ height: '200px' }}
        className="svg"
        aria-hidden={true}
        onLoad={() => setImgLoaded(true)}
      />
      <div className="translations-selections mrg-t24">
        <p
          className={`fs21 fwsb access-ob access-o6 ${
            isChrome ? 'mrg-b24' : 'mrg-b12'
          }`}
          tabIndex="0"
          aria-label={TranslationCreateRoomAuth.hostLogin}
          id={'createRoom'}
        >
          {TranslationCreateRoomAuth.hostLogin}
        </p>
        {roomCodeIsLocked && (
          <div className="banners__notice align-center full-width mrg-b24">
            <img
              src={flag}
              alt="flag"
              style={{ height: '24px' }}
              className="filter-red mrg-auto-bottom mrg-t6"
            />
            <p className="fs18 mrg-l12">
              {TranslationCreateRoomAuth.outstandingBalance}
            </p>
          </div>
        )}
        {!isChrome && (
          <div className="flex-row flex-start mrg-b24 full-width">
            <img
              src={warning}
              alt="img"
              style={{ height: '18px' }}
              className="mrg-r12 mrg-t2"
            />
            <p className="fs18 fwn ">
              {TranslationCreateRoomAuth.chromeIsRequired}
            </p>
          </div>
        )}
        {isMobileDevice && (
          <div className="flex-row flex-start mrg-b24">
            <img
              src={warning}
              alt="img"
              style={{ height: '18px' }}
              className="mrg-r12 mrg-t2"
            />
            <p className="fs18 fwn">
              {TranslationCreateRoomAuth.mobileDevicesNotPermittedToHost}
            </p>
          </div>
        )}

        <form
          action=""
          onSubmit={() => {
            handleSubmit(roomCode, password);
          }}
          className="full-width mrg-b24"
        >
          <TextArea
            disableEnter={true}
            asterisk={true}
            id={'roomCodeInput'}
            labelText={TranslationCreateRoomAuth.roomCode}
            handleChange={setRoomCode}
            limit={12}
            customWrapper={'mrg-b24'}
            hideWordCount={true}
            customKeyDownHandler={handleKeyDown}
            loadValue={
              process.env.REACT_APP_ENV === 'development' ? 'MYNDFULL' : ''
            }
            loadTrigger={
              process.env.REACT_APP_ENV === 'development' ? true : false
            }
          />
          <PasswordInput
            handleInput={setPassword}
            customKeyDownHandler={handleKeyDown}
            preventDefaultOff={true}
            id={'passwordInput'}
            resetTrigger={passwordReset}
            defaultValue={
              process.env.REACT_APP_ENV === 'development' ? 'test12345' : ''
            }
          />
        </form>

        <ItemButton
          text={TranslationCreateRoomAuth.submit}
          handler={() => {
            setIsLoadingAuth(true);
            handleSubmit(roomCode, password);
          }}
          icon={return_curved_arrow}
          iconStyle={{ height: '21px' }}
          iconOn={true}
          customWrapper={'full-width mrg-b24'}
          iconCustomClass={'reverse'}
          isLoading={isLoadingAuth || translationsConnectionIsInitializing}
        />

        <button
          className={`flex-center color-gray fs18 fwsb highlight-it-theme access-ob access-o6 ${
            tapHighlightGetRoomCode ? 'highlight-it-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightGetRoomCode(true),
                () => setTapHighlightGetRoomCode(false),
              ],
              [() => navigate('/register')]
            )
          }
        >
          {TranslationCreateRoomAuth.noRoomCode}
        </button>
        <div className="divider full-width mrg-t24 mrg-b12" />

        <ItemButton
          text={TranslationCreateRoomAuth.exit}
          handler={() => {
            handleTranslationsLogout('translationCreateRoomAuth');
            setPage(1);
          }}
          customWrapper={'full-width mrg-t24'}
        />
      </div>
    </div>
  );
};

export default TranslationCreateRoomAuth;

import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//language
import useLanguageEvents from '../../../../language/features/useLanguageEvents';
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageImages from '../../../../language/useLanguageImages';

//Components
import HeadingSummary from './HeadingSummary';
import ActionButtons from './ActionButtons/ActionButtons';
import EventPageAbout from './EventPageAbout';
import EventPageDescription from './EventPageDescription';
import HostingInPersonContent from './HostingInPersonContent';
import HostingVirtualContent from './HostingVirtualContent';
import AgendaContent from './AgendaContent';
import AccessibilityContent from './AccessibilityContent';
import TagsContent from '../../../../components/TagsContent/TagsContent';
import ParticipantsContent from './ParticipantsContent';
import EventAdditionalImages from './EventAdditionalImages';
import EventPageTranslations from './EventPageTranslations';
import EventPageContact from './EventPageContact';
import EventPageTitle from './EventPageTitle';

import ReportEvent from './ReportEvent';
import ScrollToEl from '../../../../components/ScrollToTop/ScrollToEl';
import LoadingScreenContent from '../../../../components/LoadingScreenContent/LoadingScreenContent';
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//Utility
import ScrollTo from '../../../../utils/UI/ScrollTo';

/*eslint-disable*/

const EventPage = ({
  getNewCommunityEventsList,
  handleCustomReturn,
  organizerViewOn,
  participantPreviewOn,
  previewEventData,
  editPreviewMode,
  updateEventDataListPostRegistration,
  //cropped
  eventDisplayImageCropped,
  participantImagesCropped,
  eventAdditionalImagesCropped,

  //pwd
  decryptedTranslationsPassword,
  decryptedVirtualPasscode,
}) => {
  //Hooks
  const {
    width,
    handleMobileTap,
    setSettingsSetViewEventDetails,
    settingsViewEventDetails,
  } = useSettings();
  const {
    accountSavedEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
    accountIgnoredEvents,
    accountArchivedEvents,
    accountId,
    accountParticipantDrafts,
    accountParticipantEvents,
  } = useAccountReduxHandlers();

  //language
  const { EventPage } = useLanguageEvents();
  const { IconAlts } = useLanguageComponents();
  const { PreviewBanner } = useLanguageImages();

  //Component state
  const [eventStatus, setEventStatus] = useState('neutral');
  const [eventData, setEventData] = useState();
  const [isParticipant, setIsParticipant] = useState(false);

  //ui
  const [isLoading, setIsLoading] = useState(true);
  const [displayParticipantsSection, setDisplayParticipantsSection] =
    useState(false);
  const [
    registrationActionBtnsDisplayOnly,
    setRegistrationActionBtnsDisplayOnly,
  ] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (!isLoading) {
      ScrollTo({
        focusId: 'eventPageTitle',
        scrollToElementId: 'eventPage-top',
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (previewEventData) {
      setEventData(previewEventData);
    } else {
      setEventData(settingsViewEventDetails);
    }
  }, [settingsViewEventDetails, previewEventData]);

  //Component functions
  useEffect(() => {
    //Generic status
    if (accountSavedEvents?.includes(eventData?._id)) {
      setRegistrationActionBtnsDisplayOnly(false);
      return setEventStatus('saved');
    }

    if (accountIgnoredEvents?.includes(eventData?._id)) {
      setRegistrationActionBtnsDisplayOnly(false);
      return setEventStatus('ignored');
    }
    if (accountArchivedEvents?.includes(eventData?._id)) {
      setRegistrationActionBtnsDisplayOnly(false);
      return setEventStatus('archived');
    }

    //InPerson
    if (accountPendingApprovalInPerson?.includes(eventData?._id)) {
      if (accountRegistrationsVirtual.includes(eventData?._id)) {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('pendingApprovalInPerson');
      }
    }

    if (accountRegistrationsInPerson.includes(eventData?._id)) {
      if (accountPendingApprovalVirtual.includes(eventData?._id)) {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('registeredInPerson');
      }
    }

    //Virtual
    if (accountPendingApprovalVirtual?.includes(eventData?._id)) {
      if (accountRegistrationsInPerson.includes(eventData?._id)) {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('pendingApprovalVirtual-registeredInPerson');
      } else {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('pendingApprovalVirtual');
      }
    }

    if (accountRegistrationsVirtual.includes(eventData?._id)) {
      if (accountPendingApprovalInPerson.includes(eventData?._id)) {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('pendingApprovalInPerson-registeredVirtual');
      } else {
        setRegistrationActionBtnsDisplayOnly(true);
        return setEventStatus('registeredVirtual');
      }
    }

    if (
      !accountRegistrationsVirtual.includes(eventData?._id) &&
      !accountRegistrationsInPerson.includes(eventData?._id) &&
      !accountPendingApprovalVirtual?.includes(eventData?._id) &&
      !accountPendingApprovalInPerson?.includes(eventData?._id)
    ) {
      setEventStatus('neutral');
    }
    return setRegistrationActionBtnsDisplayOnly(false);
    /*eslint-disable-next-line*/
  }, [
    eventData,
    accountSavedEvents,
    accountIgnoredEvents,
    accountArchivedEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]);

  useEffect(() => {
    let displayParticipants = eventData?.participants?.some(
      (p) =>
        p.organizedAs === 'create' ||
        (p.organizedAs === 'emailInvitation' &&
          p.dispatches.invitationStatus === 'accepted') ||
        (p.organizedAs === 'inviteContact' &&
          p.dispatches.invitationStatus === 'accepted') ||
        participantPreviewOn
    );

    setDisplayParticipantsSection(displayParticipants);
  }, [eventData, participantPreviewOn]); //display participants section

  useEffect(() => {
    if (eventData?.participants) {
      let eventHasAccountAsParticipant = false;
      let accountHasEventParticipation = false;

      eventData?.participants?.map((participant) => {
        if (
          participant?.organizedAs === 'inviteContact' &&
          participant?.participantData?.participantAccountId?.toString() ===
            accountId?.toString()
        ) {
          eventHasAccountAsParticipant = true;
        }
      });

      if (eventHasAccountAsParticipant) {
        if (
          accountParticipantDrafts.includes(eventData?._id) ||
          accountParticipantEvents.includes(eventData?._id)
        ) {
          accountHasEventParticipation = true;
        }
      }

      if (eventHasAccountAsParticipant && accountHasEventParticipation) {
        setIsParticipant(true);
      } else {
        setIsParticipant(false);
      }
    }
  }, [eventData, accountId]); //check if isParticipant;

  return (
    <>
      {eventData && (
        <>
          {isLoading && (
            <LoadingScreenContent
              numberOfLoadChecks={1}
              setLoadComplete={() => {
                setIsLoading(false);
              }}
              loadCheck1={eventData ? true : false}
              customWrapper={'min-full-height'}
            />
          )}

          <div
            className={`ev ${isLoading ? 'remove' : ''} pos-rel`}
            id={'eventPage-top'}
          >
            <LoadBucketImage
              altAriaText={eventData?.generic?.eventImageDisplay?.alt}
              backupPath={'event_img_default.png'}
              containerClassName={'ev-display-container'}
              croppedImage={
                previewEventData && eventDisplayImageCropped
                  ? eventDisplayImageCropped?.croppedImage?.croppedImage
                  : null
              }
              imagePath={eventData?.generic?.eventImageDisplay?.imagePath}
              imgClassName={'ev-display-image'}
              partialPath={'myndfull_event_images'}
            />

            {(eventData?.meta?.isDraft || editPreviewMode) && (
              <img
                src={PreviewBanner}
                alt={IconAlts.iconPreviewBanner}
                className="ev-preview-banner rotate-cw180"
              />
            )}

            <div className="ev-main__container">
              <EventPageTitle eventData={eventData} />
              <div className="h12" />

              <HeadingSummary
                eventData={eventData}
                width={width}
                EventPage={EventPage}
                getNewCommunityEventsList={getNewCommunityEventsList}
                previewMode={previewEventData}
                organizerViewOn={organizerViewOn}
              />
              <div className="h24" />

              <ActionButtons
                eventData={eventData}
                setEventData={setEventData}
                eventStatus={eventStatus}
                handleMobileTap={handleMobileTap}
                EventPage={EventPage}
                handleReturn={
                  handleCustomReturn
                    ? handleCustomReturn
                    : setSettingsSetViewEventDetails
                }
                isParticipant={isParticipant}
                setIsParticipant={setIsParticipant}
                setEventStatus={setEventStatus}
                organizerViewOn={organizerViewOn}
                participantPreviewOn={participantPreviewOn}
                registrationActionBtnsDisplayOnly={
                  registrationActionBtnsDisplayOnly
                }
                updateEventDataListPostRegistration={
                  updateEventDataListPostRegistration
                }
              />

              <div className="h36" />

              <EventPageAbout eventData={eventData} EventPage={EventPage} />

              <div className="h24" />

              <EventPageDescription
                eventData={eventData}
                EventPage={EventPage}
              />
              <div className="h36" />

              <EventAdditionalImages
                eventData={previewEventData ? previewEventData : eventData}
                eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                previewMode={previewEventData ? true : false}
              />

              <div className="h36" />

              {(eventData.hosting.hostingOption === 'inPerson' ||
                eventData.hosting.hostingOption === 'hybrid') && (
                <>
                  <HostingInPersonContent
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    handleMobileTap={handleMobileTap}
                    organizerViewOn={organizerViewOn}
                    previewMode={previewEventData}
                    editPreviewMode={editPreviewMode}
                    isParticipant={isParticipant}
                  />
                  <div className="h36" />
                </>
              )}

              {(eventData.hosting.hostingOption === 'online' ||
                eventData.hosting.hostingOption === 'hybrid') && (
                <>
                  <HostingVirtualContent
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    organizerViewOn={organizerViewOn}
                    decryptedVirtualPasscode={decryptedVirtualPasscode}
                    isParticipant={isParticipant}
                  />
                  <div className="h36" />
                </>
              )}

              {eventData?.translations?.translationsEnabled && (
                <>
                  <EventPageTranslations
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    organizerViewOn={organizerViewOn}
                    decryptedTranslationsPassword={
                      decryptedTranslationsPassword
                    }
                    isParticipant={isParticipant}
                  />
                  <div className="h36" />
                </>
              )}

              {displayParticipantsSection && (
                <>
                  <ParticipantsContent
                    participantsData={eventData?.participants}
                    participantImagesCropped={participantImagesCropped}
                    EventPage={EventPage}
                    previewMode={previewEventData ? true : false}
                    participantPreviewOn={participantPreviewOn}
                    organizerViewOn={organizerViewOn}
                  />
                  <div className="h36" />
                </>
              )}

              {eventData.generic?.agenda?.items?.length > 0 && (
                <>
                  <AgendaContent
                    eventData={eventData}
                    EventPage={EventPage}
                    agendaData={eventData.generic.agenda}
                  />
                  <div className="h36" />
                </>
              )}

              {(eventData.generic.wheelchairAccessibility ||
                eventData.generic.signLanguageInterpreter ||
                eventData.generic.accessibilityNotes?.length) > 0 && (
                <>
                  <AccessibilityContent
                    genericData={eventData.generic}
                    EventPage={EventPage}
                  />
                  <div className="h36" />
                </>
              )}

              {eventData?.contact?.provideContact && (
                <>
                  <EventPageContact
                    eventData={eventData}
                    EventPage={EventPage}
                    eventStatus={eventStatus}
                    organizerViewOn={organizerViewOn}
                    isParticipant={isParticipant}
                  />
                  <div className="h36" />
                </>
              )}
              <TagsContent
                bigTagsContent={eventData.tags.bigTags}
                tinyTagsContent={eventData.tags.tinyTags}
              />
              <div className="h72" />

              <ScrollToEl
                focusId={'eventPageTitle'}
                elementId={'eventPageTitle'}
              />
              <div className="h48" />
              <ReportEvent
                handleMobileTap={handleMobileTap}
                EventPage={EventPage}
                eventData={eventData}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EventPage;

const demoBigTags = ['hist', 'edu'];

const demoTinyTagGroups = [
  {
    id: 22222,
    label: 'WW2',
    tags: ['ww1', 'ww2'],
  },
  {
    id: 11111,
    label: 'WW1',
    tags: ['ww1', 'ww2'],
  },
];

export { demoBigTags, demoTinyTagGroups };

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useSettingsHandlers } from '../useSettingsHandlers';

//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Utility
import {
  organizer_cards,
  organizer_dash,
  organizer_table,
  date,
  direction,
  img_organize_active,
  img_organize_scheduled,
  img_organize_archived,
} from '../../../../assets/icons';

const OrganizeFilters = () => {
  //Hooks
  const { width } = useSettings();
  const {
    handleSettingsSetOrganizeOverviewDirection,
    handleSettingsSetOrganizeOverviewFormat,
    handleSettingsSetOrganizeOverviewSortCategory,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
    settingsOrganizeOverviewDirection: overviewDirection,
    settingsOrganizeOverviewFormat: format,
    settingsOrganizeOverviewSortCategory: sortCategory,
    settingsOrganizeView: view,
    settingsOrganizeStandaloneGridEvents: standaloneEvents,
  } = useSettingsHandlers();

  //language
  const { SettingsOrganizeLanguage } = useLanguageSettings();

  //Component variables
  let content;

  //Component functions
  function handleDirection() {
    if (overviewDirection === 'ascending') {
      handleSettingsSetOrganizeOverviewDirection('descending');
    } else {
      handleSettingsSetOrganizeOverviewDirection('ascending');
    }
  }

  function handleSortCategory() {
    handleSettingsSetOrganizeOverviewSortCategory('date');
  }

  function handleStandaloneGridEvents() {
    if (standaloneEvents === 'active') {
      handleSettingsSetOrganizeOverviewStandaloneGridEvents('scheduled');
    } else if (standaloneEvents === 'scheduled') {
      handleSettingsSetOrganizeOverviewStandaloneGridEvents('archived');
    } else if (standaloneEvents === 'archived') {
      handleSettingsSetOrganizeOverviewStandaloneGridEvents('active');
    }
  }

  function handleSearchFormat() {
    if (width > 480) {
      if (format === 'cards') {
        handleSettingsSetOrganizeOverviewFormat('table');
      } else if (format === 'table') {
        // handleSettingsSetOrganizeOverviewFormat('dash'); //replace when implementing dash
        handleSettingsSetOrganizeOverviewFormat('cards');
      }
      // else if(format === 'dash'){
      //   handleSettingsSetOrganizeOverviewFormat('cards');
      // }    //add dash when implementing dash
    } else {
      return handleSettingsSetOrganizeOverviewFormat('cards');
    }
  }

  if (view === 'overview') {
    content = (
      <div className="filters">
        <ul className="flex-row">
          {format === 'cards' && (
            <>
              <li className="flex-column">
                <button
                  className="access-ob access-o6"
                  onClick={handleStandaloneGridEvents}
                  tabIndex="0"
                  title={`${
                    standaloneEvents === 'active'
                      ? SettingsOrganizeLanguage.activeEvents
                      : standaloneEvents === 'scheduled'
                      ? SettingsOrganizeLanguage.scheduledEvents
                      : SettingsOrganizeLanguage.archivedEvents
                  }`}
                  aria-label={`${
                    standaloneEvents === 'active'
                      ? SettingsOrganizeLanguage.activeEvents
                      : standaloneEvents === 'scheduled'
                      ? SettingsOrganizeLanguage.scheduledEvents
                      : SettingsOrganizeLanguage.archivedEvents
                  }`}
                >
                  <img
                    src={
                      standaloneEvents === 'active'
                        ? img_organize_active
                        : standaloneEvents === 'scheduled'
                        ? img_organize_scheduled
                        : img_organize_archived
                    }
                    alt={
                      standaloneEvents === 'active'
                        ? SettingsOrganizeLanguage.activeImg
                        : standaloneEvents === 'scheduled'
                        ? SettingsOrganizeLanguage.scheduledImg
                        : SettingsOrganizeLanguage.archivedImg
                    }
                  />
                </button>
                <div className="flex-row flex-center mrg-t12">
                  <span
                    className={`dot mrg-r6 ${
                      standaloneEvents === 'active'
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    }`}
                  />
                  <span
                    className={`dot mrg-r6 ${
                      standaloneEvents === 'scheduled'
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    }`}
                  />
                  <span
                    className={`dot  ${
                      standaloneEvents === 'archived'
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    }`}
                  />
                </div>
              </li>
              <li className="flex-column">
                <button
                  className="access-ob access-o6"
                  onClick={handleDirection}
                  tabIndex="0"
                  title={`${
                    overviewDirection === 'ascending'
                      ? SettingsOrganizeLanguage.ascending
                      : SettingsOrganizeLanguage.descending
                  }`}
                  aria-label={`${
                    overviewDirection === 'ascending'
                      ? SettingsOrganizeLanguage.ariaAscending
                      : SettingsOrganizeLanguage.ariaDescending
                  }`}
                >
                  <img
                    src={direction}
                    alt={SettingsOrganizeLanguage.ascending}
                    className={`${
                      overviewDirection === 'ascending' && 'reverse'
                    }`}
                  />
                </button>
                <div className="flex-row flex-center mrg-t12">
                  <span
                    className={`dot mrg-r6 ${
                      overviewDirection === 'ascending'
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    }`}
                  />
                  <span
                    className={`dot  ${
                      overviewDirection === 'descending'
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    }`}
                  />
                </div>
              </li>
              <li className="flex-column">
                <button
                  className="access-ob access-o6"
                  onClick={handleSortCategory}
                  tabIndex="0"
                  title={`${SettingsOrganizeLanguage.date}`}
                  aria-label={`${SettingsOrganizeLanguage.ariaDate}`}
                >
                  <img
                    src={`${sortCategory === 'date' ? date : null}`}
                    alt={SettingsOrganizeLanguage.date}
                  />
                </button>
                <div className="flex-row flex-center mrg-t12">
                  <span
                    className={`dot ${
                      sortCategory === 'date'
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    }`}
                  />
                </div>
              </li>
            </>
          )}

          <li className="flex-column">
            <button
              className="access-ob access-o6"
              onClick={handleSearchFormat}
              tabIndex="0"
              title={`${
                format === 'cards'
                  ? SettingsOrganizeLanguage.cards
                  : format === 'table'
                  ? SettingsOrganizeLanguage.table
                  : SettingsOrganizeLanguage.dash
              }`}
              aria-label={`${
                format === 'cards'
                  ? SettingsOrganizeLanguage.cards
                  : format === 'table'
                  ? SettingsOrganizeLanguage.table
                  : SettingsOrganizeLanguage.dash
              }`}
            >
              <img
                src={
                  format === 'cards'
                    ? organizer_cards
                    : format === 'table'
                    ? organizer_table
                    : organizer_dash
                }
                alt={
                  format === 'cards'
                    ? SettingsOrganizeLanguage.cardsImg
                    : format === 'table'
                    ? SettingsOrganizeLanguage.tableImg
                    : SettingsOrganizeLanguage.dashImg
                }
              />
            </button>
            <div className="flex-row flex-center mrg-t12">
              <span
                className={`dot mrg-r6 ${
                  format === 'cards' ? 'bg-color-theme' : 'bg-color-lightgray'
                }`}
              />
              <span
                className={`dot  ${
                  format === 'table' ? 'bg-color-theme' : 'bg-color-lightgray'
                }`}
              />
              {/* <span
                  className={`dot  ${
                    format === 'dash' ? 'bg-color-theme' : 'bg-color-lightgray'
                  }`}
                /> */}
            </div>
          </li>
        </ul>
      </div>
    );
  }

  return content;
};

export default OrganizeFilters;

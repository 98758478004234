import React from 'react';

//utils
import { textUI } from '../../../../utils/UI/textUI';

const EventPageDescription = ({ eventData, EventPage }) => {
  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12 access-ob access-o6"
        id="descriptionLabel"
        tabIndex="0"
      >
        {EventPage.description}
      </label>
      <div className="fs18">{textUI(eventData.generic.description)}</div>
    </>
  );
};

export default EventPageDescription;

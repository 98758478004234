import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useSettings } from '../../../../../../context/SettingsProvider';
import useTimezoneData from '../../../../../../language/useTimezoneData';
import { useTranslationsHandlers } from '../../../useTranslationsHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';
import CheckBoxBoolean from '../../../../../../components/CheckBoxes/CheckBoxBoolean';
import TextArea from '../../../../../../components/TextArea/TextArea';
import ScrollToTop from '../../../../../../components/ScrollToTop/ScrollToTop';
import ButtonMeta from '../../../../../../components/ButtonMeta/ButtonMeta';
import TranslationsTranscriptParticipants from './TranslationsTranscriptParticipants';

//utility
import {
  formatDateFromCreatedAt,
  useDateTimeCalculations,
} from '../../../../../../utils/dateTime/DateCalc';
import {
  dropdown_arrow,
  flag,
  img_arrow_stubby,
  img_empty_box,
  img_examine_page,
} from '../../../../../../assets/icons/index';
import { image_transcripts } from '../../../../../../assets/images';
import ScrollTo from '../../../../../../utils/UI/ScrollTo';
import { extractCharactersFromString } from '../../../../../../utils/misc/extractCharactertsFromString';

const TranslationsTranscriptsOpen = () => {
  //hooks
  const {
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    translationsTranscripts,
    hostTimeZone,
    handleGetTranscript,
  } = useTranslationRoomSocket();
  const { handleDownloadTranscriptForHost } = useTranslationsHandlers();
  const { contentLanguage, handleMobileTap } = useSettings();
  const { TimeZonesAbbr } = useTimezoneData();
  const { getFormatTimeFromTimestamp } = useDateTimeCalculations();

  //language
  const { TranslationLanguagesSingular } = useLanguageData();
  const { IconAlts } = useLanguageComponents();
  const { TranslationTranscripts } = useLanguageTranslationRoom();
  //state
  const [selectedTranscript, setSelectedTranscript] = useState();
  const [openedTranscriptionLines, setOpenedTranscriptionLines] = useState([]);
  const [flaggedIndexes, setFlaggedIndexes] = useState([]);
  const [flaggedDescriptions, setFlaggedDescriptions] = useState({});
  const [reviewComments, setReviewComments] = useState('');
  const [displaySpeakerUpdateIndexes, setDisplaySpeakerUpdateIndexes] =
    useState([]);

  //UI
  // const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);
  const [tapHighlightTranscriptEntry, setTapHighlightTranscriptEntry] =
    useState();

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage && contentLanguage && hostTimeZone) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage, contentLanguage, hostTimeZone]); //will render the spinner if loading is taking longer than 500 ms

  ///API
  async function handleSelectTranscript(transcriptId) {
    if (!renderSpinner) {
      //1 check if the full transcript is already available
      const transcriptAlreadyFetched = translationsTranscripts.find(
        (transcript) => transcript?.meta?.sessionToken
      );

      //2 set it to selected if it is
      if (transcriptAlreadyFetched) {
        return setSelectedTranscript(transcriptAlreadyFetched);
      }

      //3 fetch full transcript
      setRenderSpinner(true);
      const transcript = await handleGetTranscript(transcriptId);

      if (transcript) {
        setSelectedTranscript(transcript);
      }

      setRenderSpinner(false);
    }
  }

  ////
  function handleFlagEntry(flaggedIndex) {
    if (!flaggedIndexes.includes(flaggedIndex)) {
      let newOpenedLines = [...flaggedIndexes, flaggedIndex];
      setFlaggedIndexes(newOpenedLines);
    } else {
      let newOpenedLines = flaggedIndexes.filter((l) => l !== flaggedIndex);
      setFlaggedIndexes(newOpenedLines);
    }
  }

  function handleFlaggedDescription(text, id) {
    const index = id.split('-')[1];
    setFlaggedDescriptions((prevState) => ({
      ...prevState,
      [index]: text,
    }));
  }

  function handleDisplaySpeakerIndex(speakerIndex) {
    if (!displaySpeakerUpdateIndexes.includes(speakerIndex)) {
      let newArr = [...displaySpeakerUpdateIndexes, speakerIndex];
      setDisplaySpeakerUpdateIndexes(newArr);
    } else {
      let newArr = displaySpeakerUpdateIndexes.filter(
        (l) => l !== speakerIndex
      );
      setDisplaySpeakerUpdateIndexes(newArr);
    }
  }

  function handleSubmitReview() {}

  function handleOpenTranscriptEntry(transcriptIndex) {
    if (!openedTranscriptionLines.includes(transcriptIndex)) {
      let newOpenedLines = [...openedTranscriptionLines, transcriptIndex];
      setOpenedTranscriptionLines(newOpenedLines);
    } else {
      let newOpenedLines = openedTranscriptionLines.filter(
        (l) => l !== transcriptIndex
      );
      setOpenedTranscriptionLines(newOpenedLines);
    }
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}

      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_transcripts}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections" style={{ maxWidth: '696px' }}>
          {!selectedTranscript ? (
            <>
              {translationsTranscripts?.length !== 0 ? (
                <p
                  className="fs21 fwsb mrg-tb24 access-ob access-o6"
                  tabIndex="0"
                >
                  {TranslationTranscripts.selectTranscript}
                </p>
              ) : (
                <>
                  <p
                    className="fs21 fwsb mrg-tb24 access-ob access-o6"
                    tabIndex="0"
                  >
                    {TranslationTranscripts.noTranscriptsSaved}
                  </p>
                  <img
                    src={img_empty_box}
                    style={{ height: '64px' }}
                    alt={IconAlts.iconEmptyBox}
                    className="svg filter-gray"
                  />
                </>
              )}
              {translationsTranscripts && hostTimeZone && contentLanguage && (
                <ul className="multi-selection-list">
                  {translationsTranscripts?.map((transcriptData, key) => {
                    return (
                      <li key={`translationsSessions-${key}`}>
                        <button
                          className={`multi-selection-item fs16`}
                          onClick={() =>
                            handleSelectTranscript(transcriptData._id)
                          }
                          tabIndex="0"
                          aria-label={`${transcriptData.meta.saveName}`}
                        >
                          <p className="flex-row">
                            {transcriptData.meta.saveName}
                          </p>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              )}
            </>
          ) : (
            <>
              {selectedTranscript?.speakersData?.length > 0 && (
                <div className="full-width">
                  <p
                    className="flex-center fs21 fwsb mrg-tb24 access-ob access-o6"
                    tabIndex="0"
                  >
                    {TranslationTranscripts.transcriptReview}
                  </p>
                  <div>
                    <p className="fs18 lh28 access-ob access-o6" tabIndex="0">
                      {
                        TranslationTranscripts.transcriptReviewGeneralInstructions1
                      }{' '}
                      <a
                        className="underline color-blue access-ob"
                        tabIndex="0"
                        href="https://myndfull.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={
                          TranslationTranscripts.transcriptReviewGeneralInstructionsAriaLink
                        }
                      >
                        {
                          TranslationTranscripts.transcriptReviewGeneralInstructions2
                        }
                      </a>
                      .
                    </p>

                    <div className="divider full-width mrg-tb24 opacity-05" />

                    <p
                      className="fs18 mrg-b12 access-ob access-o6"
                      tabIndex="0"
                    >
                      {TranslationTranscripts.saveName}:{' '}
                      {selectedTranscript.meta.saveName}
                    </p>

                    {selectedTranscript.meta.sessionToken && (
                      <p
                        className="fs18 mrg-b12 access-ob access-o6"
                        tabIndex="0"
                      >
                        {TranslationTranscripts.sessionId}:{' '}
                        {extractCharactersFromString({
                          input: selectedTranscript.meta.sessionToken,
                          charCount: 5,
                          toUpperCase: true,
                        })}
                      </p>
                    )}

                    {selectedTranscript.meta.createdAt && (
                      <p
                        className="fs18 mrg-b12 access-ob access-o6"
                        tabIndex="0"
                      >
                        {TranslationTranscripts.transcriptCreated}:{' '}
                        {formatDateFromCreatedAt(
                          selectedTranscript.meta.createdAt,
                          contentLanguage,
                          hostTimeZone
                        )}{' '}
                        ({TimeZonesAbbr[hostTimeZone]})
                      </p>
                    )}

                    {selectedTranscript.meta.lastUpdated && (
                      <p
                        className="fs18 mrg-b12 access-ob access-o6"
                        tabIndex="0"
                      >
                        {TranslationTranscripts.lastTranscriptUpdate}:{' '}
                        {formatDateFromCreatedAt(
                          selectedTranscript.meta.lastUpdated,
                          contentLanguage,
                          hostTimeZone
                        )}{' '}
                        ({TimeZonesAbbr[hostTimeZone]})
                      </p>
                    )}

                    <p
                      className="fs18 mrg-b12  access-ob access-o6"
                      tabIndex="0"
                    >
                      {TranslationTranscripts.totalTranscriptLines}:{' '}
                      <span>{selectedTranscript?.transcript?.length}</span>
                    </p>

                    <p
                      className="fs18 mrg-b12 access-ob access-o6"
                      tabIndex="0"
                    >
                      {TranslationTranscripts.flaggedTranscriptLines}:{' '}
                      <span>{flaggedIndexes?.length}</span>
                    </p>

                    <p className="fs18 access-ob access-o6" tabIndex="0">
                      {TranslationTranscripts.percentageOfIdentifiedProblems}:{' '}
                      <span>
                        {selectedTranscript?.transcript?.length
                          ? (
                              (flaggedIndexes?.length /
                                selectedTranscript?.transcript?.length) *
                              100
                            ).toFixed(1)
                          : 0}
                        %
                      </span>
                    </p>

                    <button
                      className="min-44 align-center access-ob access-o6 mrg-t12"
                      tabIndex="0"
                      onClick={() =>
                        handleDownloadTranscriptForHost({
                          downloadOption: 'basic',
                          transcriptData: selectedTranscript,
                          contentLanguage,
                          hostTimeZone,
                          sessionToken: selectedTranscript.meta.sessionToken,
                        })
                      }
                    >
                      <p className="fs18 underline">
                        {TranslationTranscripts.downloadTranscriptBasic}
                      </p>
                    </button>

                    <button
                      className="min-44 align-center access-ob access-o6"
                      tabIndex="0"
                      onClick={() =>
                        handleDownloadTranscriptForHost({
                          downloadOption: 'full',
                          transcriptData: selectedTranscript,
                          contentLanguage,
                          hostTimeZone,
                          sessionToken: selectedTranscript.meta.sessionToken,
                        })
                      }
                    >
                      <p className="fs18 underline">
                        {TranslationTranscripts.downloadTranscriptFull}
                      </p>
                    </button>

                    <p
                      className="text-left fs18 full-width mrg-tb24 lh28 access-ob access-o6"
                      tabIndex="0"
                    >
                      {TranslationTranscripts.eventDetailsDescription}
                    </p>
                    <TextArea
                      fullWidth={true}
                      disableEnter={true}
                      handleChange={setReviewComments}
                      customWrapper={'mrg-b24'}
                      hideWordCount={true}
                      id={`reviewComments`}
                      labelText={TranslationTranscripts.eventDetails}
                      limit={100}
                      onBlurHandlerOff={false}
                      asterisk={true}
                      large={true}
                      loadTrigger={true}
                      loadValue={reviewComments}
                    />

                    <ButtonMeta
                      handleClick={handleSubmitReview}
                      btnText={TranslationTranscripts.submitReview}
                      fullWidth={true}
                      customClassName={'mrg-t12 mrg-auto-lr'}
                      id={'translationsRoomLoadBtn'}
                      availabilityToggle={true}
                      unavailable={
                        flaggedIndexes?.length === 0 ||
                        reviewComments?.length === 0
                      }
                    />
                  </div>

                  <div className="divider mrg-tb24 full-width opacity-05" />

                  <p
                    className="flex-center fs21 fwsb mrg-b24 access-ob access-o6"
                    tabIndex="0"
                  >
                    {TranslationTranscripts.participants}
                  </p>
                  <TranslationsTranscriptParticipants
                    selectedTranscript={selectedTranscript}
                    TranslationTranscripts={TranslationTranscripts}
                    TranslationLanguagesSingular={TranslationLanguagesSingular}
                    IconAlts={IconAlts}
                    handleDisplaySpeakerIndex={handleDisplaySpeakerIndex}
                    displaySpeakerUpdateIndexes={displaySpeakerUpdateIndexes}
                    hostTimeZone={hostTimeZone}
                    contentLanguage={contentLanguage}
                    TimeZonesAbbr={TimeZonesAbbr}
                  />
                </div>
              )}

              {selectedTranscript?.transcript?.length === 0 ? (
                <>
                  <p
                    className="fs21 fwsb mrg-tb24 access-ob access-o6"
                    tabIndex="0"
                  >
                    {TranslationTranscripts.transcriptEmpty}
                  </p>
                  <img
                    src={img_examine_page}
                    style={{ height: '64px' }}
                    alt={IconAlts.iconEmptyBox}
                    className="svg filter-gray"
                  />
                </>
              ) : (
                <>
                  <div className="pos-rel flex-column align-center flex-center fs21 fwsb mrg-b12 mrg-t12 full-width">
                    <p tabIndex="0" className="access-ob access-o6">
                      {TranslationTranscripts.transcript}
                    </p>
                    {selectedTranscript?.transcript?.length > 10 && (
                      <button
                        className="transcripts-bottom-btn min-44 color-gray highlight-i-gt highlight-t-theme flex-row align-center pad-lr12 access-ob"
                        onClick={() =>
                          ScrollTo({
                            focusId: 'returnBtn',
                            behavior: 'smooth',
                            scrollToTop: false,
                            scrollToElementId: 'returnBtn',
                          })
                        }
                      >
                        <p className="fs16 fwsb">
                          {TranslationTranscripts.goToBottom}
                        </p>
                        <img
                          src={img_arrow_stubby}
                          className="filter-gray mrg-l6"
                          alt={IconAlts.iconArrow}
                        />
                      </button>
                    )}
                  </div>

                  <ul className="list-style-none flex-column full-width">
                    {selectedTranscript?.transcript?.map((t, index) => {
                      //display current speaker update event
                      let speakerUpdateTimes = [];
                      let displayUpdateEvent;

                      if (selectedTranscript?.speakerUpdates?.length > 0) {
                        selectedTranscript?.speakerUpdates?.map(
                          (speakerUpdate) => {
                            if (speakerUpdate.id === t.currentRoomSpeakerId) {
                              let updateTimestamp = new Date(
                                speakerUpdate.updatedAt
                              );
                              updateTimestamp = updateTimestamp.getTime();
                              return speakerUpdateTimes.push(updateTimestamp);
                            } else {
                              return null;
                            }
                          }
                        );
                      }
                      let prevTimeStamp =
                        selectedTranscript?.transcript[index - 1];

                      if (
                        prevTimeStamp?.timeStamp &&
                        speakerUpdateTimes?.length > 0
                      ) {
                        for (const speakerUpdateTime of speakerUpdateTimes ||
                          []) {
                          if (
                            speakerUpdateTime > prevTimeStamp?.timeStamp &&
                            speakerUpdateTime < t?.timeStamp
                          ) {
                            displayUpdateEvent = true;
                          }
                        }
                      }

                      //display participant name
                      let speakerName = '';

                      if (index === 0) {
                        for (const speaker of selectedTranscript?.speakersData ||
                          []) {
                          if (speaker?.id === t?.currentRoomSpeakerId) {
                            speakerName = speaker.name;
                          }
                        }
                      } else {
                        const prevT = selectedTranscript?.transcript[index - 1];

                        if (
                          prevT.currentRoomSpeakerId !== t.currentRoomSpeakerId
                        ) {
                          for (const speaker of selectedTranscript?.speakersData ||
                            []) {
                            if (speaker?.id === t?.currentRoomSpeakerId) {
                              speakerName = speaker.name;
                            }
                          }
                        }
                      }

                      return (
                        <li key={`transcript-${index}`}>
                          {speakerName && (
                            <p
                              className="fs18 fwsb mrg-b24 mrg-t12 access-ob access-o6"
                              tabIndex="0"
                            >
                              {TranslationTranscripts.participant}:{' '}
                              {speakerName}
                            </p>
                          )}

                          <div
                            className={`flex-column br--small border-thin mrg-b12 min-full-width highlight-bthin-theme fs18 ${
                              tapHighlightTranscriptEntry === index
                                ? 'highlight-b-theme--tap'
                                : ''
                            }`}
                          >
                            <button
                              className={`flex-column fs18 min-full-width text-left pad-12 pad-tb24 access-ob access-o6 `}
                              aria-pressed={openedTranscriptionLines.includes(
                                index
                              )}
                              aria-label={t.originalText}
                              onClick={() =>
                                handleMobileTap(
                                  [
                                    () => setTapHighlightTranscriptEntry(index),
                                    () => setTapHighlightTranscriptEntry(),
                                  ],
                                  [() => handleOpenTranscriptEntry(index)]
                                )
                              }
                            >
                              <div className="flex-row flex-column--ph full-width pos-rel">
                                <div className="flex-row mrg-auto-bottom">
                                  <img
                                    src={dropdown_arrow}
                                    alt={IconAlts.iconArrow}
                                    className={`filter-gray pad-r12 mrg-auto-bottom mrg-t8 ${
                                      openedTranscriptionLines.includes(index)
                                        ? 'reverse-vertical'
                                        : ''
                                    }`}
                                    style={{ height: '12px' }}
                                  />
                                  <p
                                    className="mrg-r12 mrg-auto-bottom access-ob access-o6"
                                    tabIndex={
                                      openedTranscriptionLines.includes(index)
                                        ? '0'
                                        : '-1'
                                    }
                                  >
                                    {getFormatTimeFromTimestamp(
                                      t.timeStamp,
                                      contentLanguage,
                                      hostTimeZone
                                    )}
                                  </p>
                                </div>
                                <p
                                  style={{ marginRight: '24px' }}
                                  className=" mrg-t6--ph access-ob access-o6"
                                  tabIndex={
                                    openedTranscriptionLines.includes(index)
                                      ? '0'
                                      : '-1'
                                  }
                                >
                                  {t.originalText}
                                </p>
                                {flaggedIndexes.includes(index) && (
                                  <img
                                    src={flag}
                                    alt={IconAlts.iconArrow}
                                    className="filter-red"
                                    style={{
                                      height: '24px',
                                      position: 'absolute',
                                      top: '0px',
                                      right: '0px',
                                    }}
                                  />
                                )}
                              </div>
                            </button>
                            {openedTranscriptionLines?.includes(index) && (
                              <div onClick={(e) => e.stopPropagation()}>
                                <div className="divider full-width mrg-b12 opacity-05" />
                                <div className="flex-column flex-start pad-l24 full-width">
                                  <p
                                    className="underline access-ob access-o6"
                                    tabIndex="0"
                                  >
                                    {TranslationTranscripts.originalText}
                                  </p>

                                  <div
                                    className="flex-row mrg-tb12 access-ob access-o6"
                                    tabIndex="0"
                                    aria-label={`${
                                      TranslationLanguagesSingular[
                                        t.speakerLang
                                      ]
                                    }. ${t.originalText}`}
                                  >
                                    <p className="mrg-r6 ">
                                      {
                                        TranslationLanguagesSingular[
                                          t.speakerLang
                                        ]
                                      }
                                      :
                                    </p>
                                    <p className="wrap">{t.originalText}</p>
                                  </div>

                                  <p
                                    className="underline access-ob access-o6"
                                    tabIndex="0"
                                  >
                                    {TranslationTranscripts.translations}
                                  </p>
                                  <div className="flex-column full-width">
                                    {t?.translationObj && (
                                      <>
                                        {Object.entries(t?.translationObj)?.map(
                                          ([key, value], subIndex) => {
                                            if (
                                              Object.entries(t?.translationObj)
                                                ?.length === 1
                                            ) {
                                              return (
                                                <p
                                                  className="wrap mrg-t12 access-ob access-o6"
                                                  key={`subIndex-${subIndex}`}
                                                  tabIndex="0"
                                                  aria-label={
                                                    TranslationTranscripts.noTranslationRequired
                                                  }
                                                >
                                                  [{' '}
                                                  {
                                                    TranslationTranscripts.noTranslationRequired
                                                  }{' '}
                                                  ]
                                                </p>
                                              );
                                            }

                                            if (key === t.speakerLang) {
                                              return null;
                                            } else {
                                              return (
                                                <p
                                                  className="wrap mrg-t12 access-ob access-o6"
                                                  key={`subIndex-${subIndex}`}
                                                  tabIndex="0"
                                                  aria-label={`${TranslationLanguagesSingular[key]}. ${value}`}
                                                >
                                                  {
                                                    TranslationLanguagesSingular[
                                                      key
                                                    ]
                                                  }
                                                  : {value}
                                                </p>
                                              );
                                            }
                                          }
                                        )}
                                      </>
                                    )}

                                    <div className="flex-row full-width mrg-b12 mrg-t24">
                                      <CheckBoxBoolean
                                        handler={() => handleFlagEntry(index)}
                                        customWrapper={
                                          'color-darkgray mrg-auto-bottom'
                                        }
                                        handleAccountId={true}
                                        loadTrigger={flaggedIndexes.includes(
                                          index
                                        )}
                                        loadValue={true}
                                        label={TranslationTranscripts.flag}
                                        id={`toggleTranscripts-${index}`}
                                      />
                                      {flaggedIndexes.includes(index) && (
                                        <TextArea
                                          fullWidth={true}
                                          disableEnter={true}
                                          handleChange={
                                            handleFlaggedDescription
                                          }
                                          customWrapper={
                                            'mrg-t6 mrg-l12 mrg-r24 mrg-b12'
                                          }
                                          hideWordCount={true}
                                          id={`entry-${index}`}
                                          labelText={
                                            TranslationTranscripts.problem
                                          }
                                          limit={100}
                                          onBlurHandlerOff={false}
                                          asterisk={true}
                                          large={true}
                                          loadTrigger={true}
                                          loadValue={
                                            flaggedDescriptions[index] || ''
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {displayUpdateEvent && (
                            <p
                              className="mrg-b12 fs16 italic mrg-l12 fit-content access-ob access-o6"
                              tabIndex="0"
                            >
                              {
                                TranslationTranscripts.participantInSessionUpdate
                              }
                            </p>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </>
          )}

          {selectedTranscript?.transcript?.length > 10 && (
            <ScrollToTop customWrapper={'mrg-t24'} />
          )}

          {selectedTranscript && (
            <div className="divider full-width mrg-tb36" />
          )}
          <div className="translations-selections">
            {!selectedTranscript && (
              <div className="divider full-width mrg-tb36" />
            )}
            <ItemButton
              text={TranslationTranscripts.back}
              handler={() => handleCreatePageChange('transcripts')}
              fullWidth={true}
              id={'returnBtn'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TranslationsTranscriptsOpen;

import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider.js';
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider.js';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import useLanguageComponents from '../../../../../language/useLanguageComponents.js';
import useLanguageData from '../../../../../language/useLanguageData';

//componets
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import Spinner from '../../../../../components/Spinner/Spinner';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import ButtonPlay from '../../../../../components/ButtonPlay/ButtonPlay.js';
import AutocorrectKeywords from '../../../../../components/AutocorrectKeywords/AutocorrectKeywords.js';
import TextArea from '../../../../../components/TextArea/TextArea.js';

//utility
import { image_create_list } from '../../../../../assets/images';
import {
  arrow_long_h,
  checkmark_green,
  img_enter,
  warning,
} from '../../../../../assets/icons';

const CreateRoomSpeakerProfiles = () => {
  //hooks
  const {
    speakersData,
    setSpeakersData,
    roomLanguages,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    keywordsListLengthMaximum,
    hostCreateRoom,
  } = useTranslationRoomSocket();
  const { contentLanguage, isMobileDevice } = useSettings();

  //language
  const { CreateRoomSpeakersVoiceAndLanguage, CreateRoomSpeakersAutocorrect } =
    useLanguageTranslationRoom();
  const { VoiceOpts, TranslationLanguageOpts } = useLanguageData();
  const { IconAlts } = useLanguageComponents();

  //state
  const [currentSpeakerIndex, setCurrentSpeakerIndex] = useState(0);
  const [speakerContentIsLoaded, setSpeakerContentIsLoaded] = useState(false);
  const [roomLanguageOpts, setRoomLanguageOpts] = useState([]);
  const [speakerCompletions, setSpeakerCompletions] = useState([]);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);
  const [
    speakerLanguageNotAvailableInRoomLanguageSelections,
    setSpeakerLanguageNotAvailableInRoomLanguageSelections,
  ] = useState(false);

  //Initialize
  useEffect(() => {
    const translationOpts = TranslationLanguageOpts;
    const filteredOpts = Object.keys(translationOpts)
      .filter((key) => roomLanguages.includes(key))
      .reduce((obj, key) => {
        obj[key] = translationOpts[key];
        return obj;
      }, {});
    setRoomLanguageOpts(filteredOpts);
  }, [contentLanguage, speakersData]); //set translated language options

  useEffect(() => {
    let roomLanguages = Object.keys(roomLanguageOpts);
    const currentSpeaker = speakersData[currentSpeakerIndex];
    if (
      currentSpeaker.languageOpt !== 'select' &&
      !roomLanguages.includes(currentSpeaker.languageOpt)
    ) {
      setSpeakerLanguageNotAvailableInRoomLanguageSelections(
        currentSpeaker.languageOpt
      );
    } else {
      setSpeakerLanguageNotAvailableInRoomLanguageSelections(false);
    }
  }, [currentSpeakerIndex, roomLanguageOpts]); //set warning that loaded data has incompatible language option

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSpeakerContentIsLoaded(true);
    }, 250);

    return () => clearTimeout(timerId);
  }, [speakerContentIsLoaded]); //delay content load for initialization

  //functions
  useEffect(() => {
    if (speakersData) {
      let newSpeakerCompletions = [...speakerCompletions];

      speakersData?.forEach((speaker, index) => {
        let roomLanguages = Object.keys(roomLanguageOpts);

        if (
          speaker?.languageOpt &&
          speaker?.languageOpt !== 'select' &&
          speaker?.voiceOpt &&
          speaker?.voiceOpt !== 'select' &&
          roomLanguages.includes(speaker?.languageOpt) &&
          speaker?.discussionContext?.length > 25
        ) {
          newSpeakerCompletions[index] = true;
        } else {
          newSpeakerCompletions[index] = false;
        }
        return setSpeakerCompletions(newSpeakerCompletions);
      });
    }
  }, [speakersData, roomLanguageOpts]); // set participant completion statuses

  function handleNext() {
    if (currentSpeakerIndex < 9) {
      setSpeakerContentIsLoaded(false);
      let newSpeakerIndex = currentSpeakerIndex + 1;
      return setCurrentSpeakerIndex(newSpeakerIndex);
    }
  }

  function handlePrevious() {
    if (currentSpeakerIndex > 0) {
      setSpeakerContentIsLoaded(false);
      let newSpeakerIndex = currentSpeakerIndex - 1;
      return setCurrentSpeakerIndex(newSpeakerIndex);
    }
  }

  function handleLanguageOpt(key) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[currentSpeakerIndex].languageOpt = key || 'select';
    setSpeakersData(newSpeakersData);
  }

  function handleVoiceOpt(key) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[currentSpeakerIndex].voiceOpt = key || 'select';
    setSpeakersData(newSpeakersData);
  }

  function handleDiscussionContext(value) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[currentSpeakerIndex].discussionContext = value;
    setSpeakersData(newSpeakersData);
  }

  function handleKeywordList(newKeywordList) {
    let newSpeakersData = [...speakersData];
    newSpeakersData[currentSpeakerIndex].keywordsList = newKeywordList;
    setSpeakersData(newSpeakersData);
  }

  return (
    <>
      {renderSpinner ? (
        <Spinner minHeight={'100%'} />
      ) : (
        <div
          className={`translations__container translations__container--no-box-shadow ${
            renderSpinner ? 'opacity-0' : ''
          } `}
        >
          <img
            src={image_create_list}
            alt="img"
            style={{ height: '200px' }}
            className="svg"
            aria-hidden={true}
            onLoad={() => setImgLoaded(true)}
          />
          <div className="translations-selections">
            <div className="translations-speaker-btn__container">
              <button
                className={`translations-speaker-btn mrg-auto-right highlight-i-lgt ${
                  currentSpeakerIndex === 0
                    ? 'opacity-0 pointer-default disable-select'
                    : ''
                }`}
                onClick={handlePrevious}
                aria-hidden={currentSpeakerIndex === 0 ? true : false}
                tabIndex={currentSpeakerIndex === 0 ? '-1' : '0'}
              >
                <img
                  src={arrow_long_h}
                  alt={CreateRoomSpeakersVoiceAndLanguage.next}
                  className="reverse"
                />
              </button>
              <p
                className="fs21 fwsb mrg-l24 mrg-r24 text-center access-ob access-o6"
                tabIndex="0"
              >
                {speakersData?.map((speaker, index) => {
                  if (index === currentSpeakerIndex) {
                    return `${
                      speaker?.name
                        ? speaker?.name
                        : `${CreateRoomSpeakersVoiceAndLanguage.speaker} ${
                            index + 1
                          }`
                    }`;
                  }
                })}
              </p>
              <button
                className={`translations-speaker-btn  mrg-auto-left highlight-i-lgt ${
                  currentSpeakerIndex === speakersData?.length - 1
                    ? 'opacity-0 pointer-default disable-select'
                    : ''
                }`}
                onClick={handleNext}
                aria-hidden={
                  currentSpeakerIndex === speakersData?.length - 1
                    ? true
                    : false
                }
                tabIndex={
                  currentSpeakerIndex === speakersData?.length - 1 ? '-1' : '0'
                }
              >
                <img
                  src={arrow_long_h}
                  alt={CreateRoomSpeakersVoiceAndLanguage.next}
                />
              </button>
            </div>

            <div className="translations-dot-row flex-row full-width flex-center mrg-t12">
              {speakersData?.map((speaker, index) =>
                speakerCompletions[index] === true ? (
                  <img
                    alt={CreateRoomSpeakersVoiceAndLanguage.checkmark}
                    src={checkmark_green}
                    className={`access-ob access-o6 ${
                      index !== 0 ? 'mrg-l18' : ''
                    }
             ${
               currentSpeakerIndex === index
                 ? 'translations-checkmark--selected'
                 : 'translations-checkmark'
             }
             `}
                    key={`checkmark-${index}`}
                  />
                ) : (
                  <div
                    className={`${
                      currentSpeakerIndex === index
                        ? 'bg-color-theme'
                        : 'bg-color-lightgray'
                    } ${index !== 0 && 'mrg-l18'} 
             ${
               currentSpeakerIndex === index
                 ? 'translations-dot--selected'
                 : 'translations-dot '
             }
             access-ob access-o6`}
                    key={`dot-${index}`}
                  />
                )
              )}
            </div>

            <div className="divider full-width mrg-t24 mrg-b12" />

            {speakerLanguageNotAvailableInRoomLanguageSelections && (
              <div
                className={`flex-row flex-center access-ob access-o6 ${
                  roomLanguages?.length === 1 ? 'mrg-t24' : ''
                }`}
                tabIndex="0"
              >
                <img
                  src={warning}
                  style={{ height: '21px' }}
                  alt={IconAlts.iconWarning}
                  className="mrg-r12 mrg-auto-bottom mrg-t2"
                />
                <p className={`fs18 fwsb color-gray`}>
                  {`${TranslationLanguageOpts[speakerLanguageNotAvailableInRoomLanguageSelections]}: ${CreateRoomSpeakersVoiceAndLanguage.incompatibleSavedLanguageWarning}`}
                </p>
              </div>
            )}

            <div className="h24" />
            {speakersData &&
              speakersData?.map((speakerData, index) => {
                if (currentSpeakerIndex === index) {
                  return (
                    <div
                      key={`speaker-info-${index}`}
                      className={`full-width`}
                      style={{ minHeight: '274px' }}
                    >
                      <div className={`flex-column full-width flex-center`}>
                        <SelectDropdown
                          id={'speakerLanguage'}
                          list={roomLanguageOpts}
                          handleSelection={handleLanguageOpt}
                          internalLabel={
                            CreateRoomSpeakersVoiceAndLanguage.participantsLanguage
                          }
                          asterisk={true}
                          defaultKey={
                            speakerData?.languageOpt !== 'select'
                              ? speakerData?.languageOpt
                              : 'select'
                          }
                        />

                        <SelectDropdown
                          autoUpdate={true}
                          id={'voiceOptions'}
                          list={VoiceOpts}
                          handleSelection={handleVoiceOpt}
                          internalLabel={
                            CreateRoomSpeakersVoiceAndLanguage.voiceSelection
                          }
                          defaultKey={
                            speakerData?.voiceOpt !== 'select'
                              ? speakerData?.voiceOpt
                              : 'select'
                          }
                          asterisk={true}
                          tempDisable={
                            !speakerData?.languageOpt ||
                            speakerData?.languageOpt === 'select'
                          }
                          temporaryDisableMessage={
                            'speakerLanguageSelectionRequired'
                          }
                          customWrapper={`mrg-t24`}
                        />

                        <ButtonPlay
                          speakerVoiceOpt={speakerData?.voiceOpt}
                          speakerLanguageOpt={speakerData?.languageOpt}
                        />
                      </div>

                      <p
                        className="fs21 fwsb mrg-tb24 text-center access-ob access-o6"
                        tabIndex="0"
                        aria-label={
                          CreateRoomSpeakersAutocorrect.discussionContext
                        }
                        id={'createRoom'}
                      >
                        {
                          CreateRoomSpeakersAutocorrect.aiAutocorrectionDescription
                        }
                      </p>

                      <TextArea
                        handleChange={handleDiscussionContext}
                        id={`${index}`}
                        labelText={
                          CreateRoomSpeakersAutocorrect.discussionContext
                        }
                        large={true}
                        limit={250}
                        loadTrigger={true}
                        loadValue={
                          speakerData?.discussionContext
                            ? speakerData?.discussionContext
                            : process.env.REACT_APP_ENV === 'development'
                            ? 'A history lecture on ww1.'
                            : ''
                        }
                        asterisk={true}
                        onBlurHandlerOff={true}
                        disableEnter={true}
                      />
                      {speakerData?.discussionContext?.length < 25 && (
                        <div className="flex-row flex-center mrg-t12">
                          <img
                            src={warning}
                            alt="img"
                            style={{ height: '18px' }}
                            className="mrg-r12"
                          />
                          <p className="fs18">
                            {
                              CreateRoomSpeakersAutocorrect.minimumCharacterRequirements
                            }
                          </p>
                        </div>
                      )}
                      <div className="h24" />

                      <p
                        className="flex-center fs21 fwsb mrg-tb24 access-ob access-o6"
                        tabIndex="0"
                        aria-label={
                          CreateRoomSpeakersAutocorrect.autoCorrectKeywords
                        }
                        id={'createRoom'}
                      >
                        {CreateRoomSpeakersAutocorrect.autoCorrectKeywords}
                      </p>
                      <AutocorrectKeywords
                        limit={keywordsListLengthMaximum}
                        index={index}
                        speakersKeywordsList={speakerData.keywordsList}
                        setSpeakersKeywordsList={handleKeywordList}
                      />
                    </div>
                  );
                }
              })}

            <ItemButton
              text={CreateRoomSpeakersAutocorrect.createRoom}
              handler={() => {
                handleCreatePageChange('hostRoom');
                hostCreateRoom(false);
              }}
              customWrapper={'mrg-t24 full-width'}
              iconOn={true}
              iconStyle={{ height: '26px' }}
              iconCustomClass={'mrg-t2'}
              icon={img_enter}
              unavailable={
                isMobileDevice || speakerCompletions?.includes(false)
              }
            />
            {isMobileDevice && (
              <div className="flex-row flex-center mrg-t12">
                <img
                  src={warning}
                  alt="img"
                  style={{ height: '18px' }}
                  className="mrg-r12"
                />
                <p className="fs18">
                  {
                    CreateRoomSpeakersAutocorrect.mobileDevicesNotPermittedToHost
                  }
                </p>
              </div>
            )}

            <div className="divider full-width mrg-tb36" />

            <ItemButton
              text={CreateRoomSpeakersVoiceAndLanguage.back}
              handler={() => handleCreatePageChange('createSpeakers')}
              customWrapper={'full-width'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CreateRoomSpeakerProfiles;

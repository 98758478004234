import { useEffect, useRef, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettings } from '../../../../context/SettingsProvider';
import { useNoticeHandlers } from '../useNoticeHandlers';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import NoticeBase from './notices/NoticeBase';
import NoticeOverlayOptions from './NoticeOverlayOptions';
import NoticeOverlayMobileBackground from './NoticeOverlayMobileBackground';
import NoticeOverlayHeader from './NoticeOverlayHeader';
import NoticeSettingsMenu from './NoticeSettingsMenu';

const NoticeOverlay = () => {
  //hooks
  const {
    width,
    contentLanguage,
    openMobileNotificationsMenu,
    setOpenMobileNotificationsMenu,
  } = useSettings();
  const { accountProfileImage, accountNotices } = useAccountReduxHandlers();
  const { handleSetAccountNoticesSeen } = useNoticeHandlers();

  //language
  const { NoticeOverlay } = useLanguageComponents();

  //state
  const [archiveMode, setArchiveMode] = useState(false);
  const [settingsMode, setSettingsMode] = useState(false);
  const [filteredNotices, setFilteredNotices] = useState([]);

  //UI
  const [noticeOverlayIsOpened, setNoticeOverlayIsOpened] = useState(false);
  const [noticeOverlayIsChanging, setNoticeOverlayIsChanging] = useState(false);
  const [archivedNoticesNum, setArchivedNoticesNum] = useState(0);
  const [unseenNoticesNum, setUnseenNoticesSum] = useState();

  //UI profile image
  const [profileImage, setProfileImage] = useState(accountProfileImage);
  const [initialized, setInitialized] = useState(false);
  const [resetProfileImage, setRefreshProfileImage] = useState(false);

  //variables
  const prevImage = useRef();
  const imageRefreshTimer = useRef();

  //initialize
  useEffect(() => {
    //set filtered
    let sortedNotices = [];

    if (accountNotices?.length > 0) {
      sortedNotices = [...accountNotices].sort(
        (a, b) => b.createdAt - a.createdAt
      );
    }

    if (!archiveMode) {
      let activeNotices = [];

      sortedNotices?.map((sortedNotice) => {
        if (
          sortedNotice?.noticeStatus !== 'archived' &&
          !sortedNotice?.noticeHistory
        ) {
          activeNotices.push(sortedNotice);
        }
      });

      setFilteredNotices(activeNotices);
    } else {
      let archivedNotices = [];
      sortedNotices?.map((sortedNotice) => {
        if (
          sortedNotice?.noticeStatus === 'archived' ||
          sortedNotice?.noticeHistory
        ) {
          archivedNotices.push(sortedNotice);
        }
      });
      setFilteredNotices(archivedNotices);
    }

    if (accountNotices) {
      //set unseen alert number
      let newUnseenNotices = 0;
      let newArchivedNoticesNum = 0;
      accountNotices?.map((n) => {
        if (n.noticeStatus === 'unseen') {
          newUnseenNotices++;
        }

        if (n.noticeStatus === 'archived' || n?.noticeHistory) {
          newArchivedNoticesNum++;
        }
      });

      setArchivedNoticesNum(newArchivedNoticesNum);
      setUnseenNoticesSum(newUnseenNotices);
    }
  }, [accountNotices, archiveMode]); //sets filtered notices

  useEffect(() => {
    if (!initialized && accountProfileImage) {
      setInitialized(true);
      prevImage.current = accountProfileImage;
      setProfileImage(accountProfileImage);
    }

    if (initialized) {
      if (accountProfileImage !== prevImage.current) {
        setRefreshProfileImage(true);

        imageRefreshTimer.current = setTimeout(() => {
          setProfileImage(accountProfileImage);
          setRefreshProfileImage(false);
          prevImage.current = accountProfileImage;
        }, 500);
      }
    }
  }, [accountProfileImage]); //refreshes profile image on notice header if acct updated

  //API
  useEffect(() => {
    if (unseenNoticesNum && !noticeOverlayIsOpened) {
      if (unseenNoticesNum > 0) {
        const delayTimer = setTimeout(() => {
          handleSetAccountNoticesSeen();
        }, 500);

        return () => {
          clearTimeout(delayTimer);
        };
      }
    }
  }, [noticeOverlayIsOpened]); //sets notices seen after opening + closing

  //UI
  useEffect(() => {
    if (width > 480) {
      setNoticeOverlayIsChanging(true);
      let animationTimeout = null;
      animationTimeout = setTimeout(() => {
        setNoticeOverlayIsChanging(false);
      }, 600); //must correspond with noticeOverlay__wrapper transition time
      return () => {
        clearTimeout(animationTimeout);
      };
    }
  }, [noticeOverlayIsOpened]); //used for the increase height on hover animation for when mouse is over notices

  useEffect(() => {
    if (openMobileNotificationsMenu && width < 480) {
      document.body.style.overflowY = 'hidden';
      return () => {
        document.body.style.overflowY = 'scroll';
      };
    }
  }, [openMobileNotificationsMenu, noticeOverlayIsOpened, width]); //ensures that mobile view of notifications removes main scrollbar and avoids double scrollbars

  useEffect(() => {
    if (openMobileNotificationsMenu && !noticeOverlayIsOpened) {
      setNoticeOverlayIsOpened(true);
    }
  }, [openMobileNotificationsMenu]); //fixes sequencing problem where clicking navbar in mobile view leads first click of settings or archive to not open corresponding containers

  return (
    <>
      <NoticeOverlayMobileBackground
        width={width}
        openMobileNotificationsMenu={openMobileNotificationsMenu}
        setOpenMobileNotificationsMenu={setOpenMobileNotificationsMenu}
        setSettingsMode={setSettingsMode}
        setArchiveMode={setArchiveMode}
      />

      <div
        className={`noticeOverlay__wrapper access-ob access-o-6 ${
          (width > 1280 && noticeOverlayIsOpened) || openMobileNotificationsMenu
            ? 'noticeOverlay__wrapper--opened'
            : 'noticeOverlay__wrapper--closed'
        }
          ${
            width > 1280 && !noticeOverlayIsChanging && !noticeOverlayIsOpened
              ? 'noticeOverlay__wrapper--hover-rise'
              : ''
          }
          `}
      >
        <NoticeOverlayHeader
          noticeOverlayIsOpened={noticeOverlayIsOpened}
          setNoticeOverlayIsOpened={setNoticeOverlayIsOpened}
          setArchiveMode={setArchiveMode}
          setSettingsMode={setSettingsMode}
          archiveMode={archiveMode}
          settingsMode={settingsMode}
          unseenNoticesNum={unseenNoticesNum}
          resetProfileImage={resetProfileImage}
          profileImage={profileImage}
        />

        <NoticeOverlayOptions
          archiveMode={archiveMode}
          settingsMode={settingsMode}
          noticeOverlayIsOpened={noticeOverlayIsOpened}
          setArchiveMode={setArchiveMode}
          setSettingsMode={setSettingsMode}
          setNoticeOverlayIsOpened={setNoticeOverlayIsOpened}
          archivedNoticesNum={archivedNoticesNum}
        />

        <div className="divider opacity-05 full-width" />

        <div className="noticeOverlay__container">
          {settingsMode ? (
            <NoticeSettingsMenu NoticeOverlay={NoticeOverlay} />
          ) : (
            contentLanguage && (
              <ul className="noticeOverlay__container">
                {filteredNotices
                  ?.map((n) => {
                    return (
                      <NoticeBase
                        n={n}
                        setNoticeOverlayIsOpened={setNoticeOverlayIsOpened}
                        noticeOverlayIsOpened={noticeOverlayIsOpened}
                        key={`account-notice-${n._id}`}
                      />
                    );
                  })
                  .reverse()}
              </ul>
            )
          )}
        </div>
      </div>
    </>
  );
};
export default NoticeOverlay;

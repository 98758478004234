import { useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//utils
import {
  img_archived,
  return_curved_arrow,
  settings,
} from '../../../../assets/icons';

const NoticeOverlayOptions = ({
  archiveMode,
  settingsMode,
  noticeOverlayIsOpened,
  setArchiveMode,
  setSettingsMode,
  setNoticeOverlayIsOpened,
  archivedNoticesNum,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //language
  const { IconAlts, NoticeOverlay, Generic } = useLanguageComponents();

  //ui
  const [highlightArchiveReturnBtnTap, setHighlightArchiveReturnBtnTap] =
    useState(false);

  return (
    <div className="noticeOverlay-settings__wrapper">
      {!settingsMode && !archiveMode && (
        <>
          <button
            className={`noticeOverlay-settings half-width fs18 color-gray access-ob ${
              highlightArchiveReturnBtnTap ? 'highlight-bg-lg--tap' : ''
            }`}
            tabIndex={noticeOverlayIsOpened ? '0' : '-1'}
            onClick={() =>
              handleMobileTap(
                [
                  () => setHighlightArchiveReturnBtnTap(true),
                  () => setHighlightArchiveReturnBtnTap(false),
                ],
                [
                  () => {
                    if (noticeOverlayIsOpened) {
                      setArchiveMode(true);
                    } else {
                      setNoticeOverlayIsOpened(true);
                    }
                  },
                ]
              )
            }
          >
            <img
              src={img_archived}
              alt={IconAlts.iconArchiveBox}
              className={`filter-gray mrg-r8`}
            />
            <p className="fwsb">({archivedNoticesNum})</p>
          </button>
          <button
            className={`noticeOverlay-settings half-width fs18 color-gray ${
              settingsMode ? 'noticeOverlay-settings--selected' : ''
            } access-ob`}
            tabIndex={noticeOverlayIsOpened ? '0' : '-1'}
            aria-expanded={settingsMode}
            onClick={() => {
              if (noticeOverlayIsOpened) {
                setSettingsMode(!settingsMode);
              } else {
                setNoticeOverlayIsOpened(true);
              }
            }}
          >
            <img
              src={settings}
              alt={IconAlts.iconSettings}
              className="filter-gray mrg-r12"
            />
            <p className="fwsb">{NoticeOverlay.settings}</p>
          </button>
        </>
      )}
      {(settingsMode || archiveMode) && (
        <button
          className={`noticeOverlay-settings full-width fs18 color-gray access-ob ${
            highlightArchiveReturnBtnTap ? 'highlight-bg-lg--tap' : ''
          }`}
          tabIndex={noticeOverlayIsOpened ? '0' : '-1'}
          onClick={() =>
            handleMobileTap(
              [
                () => setHighlightArchiveReturnBtnTap(true),
                () => setHighlightArchiveReturnBtnTap(false),
              ],
              [
                () => {
                  if (noticeOverlayIsOpened) {
                    setArchiveMode(false);
                    setSettingsMode(false);
                  } else {
                    setNoticeOverlayIsOpened(true);
                  }
                },
              ]
            )
          }
        >
          <img
            src={return_curved_arrow}
            alt={IconAlts.iconArrow}
            className={`filter-gray mrg-r8 `}
          />
          <p className="fwsb">{Generic.return}</p>
        </button>
      )}
    </div>
  );
};

export default NoticeOverlayOptions;

import { useState, useEffect } from 'react';

//Hooks
import { useSettings } from '../../../../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//Components
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import TextArea from '../../../../../components/TextArea/TextArea';

const Name = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const {
    accountPronouns: loadPronouns,
    accountPrefix: loadPrefix,
    accountFirstName: loadFirstName,
    accountLastName: loadLastName,
    accountPersonal,
  } = useAccountReduxHandlers();
  const { width } = useSettings();

  ///Component state
  const [pronouns, setPronouns] = useState(loadPronouns);
  const [prefix, setPrefix] = useState(loadPrefix);
  const [firstName, setFirstName] = useState(loadFirstName);
  const [lastName, setLastName] = useState(loadLastName);
  const [nameSaveValid, setNameSaveValid] = useState(false);

  //Component functions
  useEffect(() => {
    if (
      (pronouns !== loadPronouns ||
        prefix !== loadPrefix ||
        firstName !== loadFirstName ||
        lastName !== loadLastName) &&
      firstName?.length > 0 &&
      lastName?.length > 0
    ) {
      setNameSaveValid(true);
    } else {
      setNameSaveValid(false);
    }
  }, [
    pronouns,
    prefix,
    firstName,
    lastName,
    loadPronouns,
    loadPrefix,
    loadFirstName,
    loadLastName,
    nameSaveValid,
  ]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="nameLabel"
      >
        {Account.account.name}
      </label>
      <div className="h24" />

      <div className="flex-row flex-column--ph">
        <TextArea
          limit={20}
          id={'pronouns'}
          labelText={Account.account.pronouns}
          disableEnter={true}
          customWidth={'220px'}
          handleChange={setPronouns}
          loadTrigger={true}
          loadValue={loadPronouns}
          customWrapper={width > 480 ? 'mrg-r24 mrg-b24' : ' mrg-b24'}
          onBlurHandlerOff={true}
          hideWordCount={true}
        />
        <TextArea
          limit={8}
          id={'prefix'}
          labelText={Account.account.prefix}
          disableEnter={true}
          customWidth={'108px'}
          handleChange={setPrefix}
          loadTrigger={true}
          loadValue={loadPrefix}
          customWrapper={width > 480 ? 'mrg-r24 mrg-b24' : 'mrg-b24'}
          onBlurHandlerOff={true}
          hideWordCount={true}
        />
      </div>
      <TextArea
        limit={50}
        id={'firstName'}
        labelText={Account.account.firstName}
        disableEnter={true}
        customWidth={'100%'}
        handleChange={setFirstName}
        loadTrigger={true}
        loadValue={loadFirstName}
        asterisk={true}
        onBlurHandlerOff={true}
        hideWordCount={true}
      />
      <div className="h24" />
      <TextArea
        limit={50}
        id={'lastName'}
        labelText={Account.account.lastName}
        disableEnter={true}
        customWidth={'100%'}
        handleChange={setLastName}
        loadTrigger={true}
        loadValue={loadLastName}
        asterisk={true}
        onBlurHandlerOff={true}
        hideWordCount={true}
      />
      <div className="h24" />
      <SaveButton
        saveValid={nameSaveValid}
        saveAriaLanguage={Account.account.name}
        handler={() =>
          saveAccountChange(
            {
              personal: {
                ...accountPersonal,
                pronouns,
                prefix,
                firstName,
                lastName,
              },
            },
            'name'
          )
        }
        isLoading={loadingSaveBtnUI === 'name'}
      />
      <div className="h36" />
    </div>
  );
};

export default Name;

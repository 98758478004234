import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';

const ShowCard = ({
  showImage,
  customAnimationStart,
  customAnimationReverse,
  mainText,
  subText,
  customLayoutCardStyle,
  imageAlt,
  buttonVisible,
  buttonCustomWrapper,
  customLayoutCardWrapper,
  buttonText,
  customMainShowImage,
  //
  buttonImageOnly,
  buttonImageOnlyCustomWrapper,
  buttonImageOnlyAlt,
  customMainText,
  handler,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //state
  const [highlightButtonTap, setHighlightButtonTap] = useState(false);
  //just leaving the highlight state here to cause very short delay; may return to add click scaledown.

  //ui
  const [startedAnimation, setStartedAnimation] = useState(false);
  const [animationStartReverse, setAnimationReverse] = useState();
  const [animationStartFinished, setAnimationStartFinished] = useState(false);
  const [mouseExited, setMouseExited] = useState();

  const hoverDelayTimer = useRef();
  const [mouseInside, setMouseInside] = useState(false);

  const animationReverseTimer = useRef();
  const animationStartTimer = useRef();

  useEffect(() => {
    if (!startedAnimation && animationStartFinished && mouseExited) {
      setAnimationReverse(true);
      setAnimationStartFinished(false);

      animationReverseTimer.current = setTimeout(() => {
        setAnimationReverse(false);
      }, 650);
    }
  }, [mouseExited, animationStartFinished]);

  function handleMouseEnter() {
    if (!animationStartReverse) {
      setMouseInside(true);
    }
  }

  useEffect(() => {
    if (mouseInside) {
      hoverDelayTimer.current = setTimeout(() => {
        handleAnimationStart();
      }, 300);
    } else {
      clearTimeout(hoverDelayTimer.current);
    }
  }, [mouseInside]);

  function handleAnimationStart() {
    if (!animationStartReverse) {
      setMouseExited(false);
      setStartedAnimation(true);

      animationStartTimer.current = setTimeout(() => {
        setStartedAnimation(false);
        setAnimationStartFinished(true);
      }, 650);
    }
  }

  useEffect(() => {
    return () => {
      clearTimeout(hoverDelayTimer.current);
      clearTimeout(animationReverseTimer.current);
      clearTimeout(animationStartTimer.current);
    };
  }, []);

  return (
    <button
      className={`show-card ${
        customLayoutCardWrapper ? customLayoutCardWrapper : ''
      } ${
        startedAnimation || animationStartFinished ? customAnimationStart : ''
      } ${
        animationStartReverse ? customAnimationReverse : ''
      } access-ob access-o-6`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => {
        setMouseInside(false);
        setMouseExited(true);
      }}
      onTouchStart={handleMouseEnter}
      onTouchEnd={() => {
        setMouseInside(false);
        setMouseExited(true);
      }}
      onClick={() =>
        handleMobileTap(
          [
            () => setHighlightButtonTap(true),
            () => setHighlightButtonTap(false),
          ],
          [() => handler()],
          true
        )
      }
    >
      <img
        src={showImage}
        alt={imageAlt ? imageAlt : 'img'}
        className={` ${customMainShowImage ? customMainShowImage : ''} svg`}
        style={customLayoutCardStyle ? customLayoutCardStyle : null}
      />
      <h1 className={`"flex-center ${customMainText ? customMainText : ''}`}>
        {mainText}
      </h1>
      {buttonVisible && (
        <div
          className={`flex-row full-width ${
            buttonCustomWrapper ? buttonCustomWrapper : ''
          }`}
        >
          {buttonText && <p>{buttonText}</p>}
          {buttonImageOnly && (
            <div
              className={`${
                buttonImageOnlyCustomWrapper ? buttonImageOnlyCustomWrapper : ''
              }`}
            >
              <img
                src={buttonImageOnly}
                alt={buttonImageOnlyAlt ? buttonImageOnlyAlt : ''}
              />
            </div>
          )}
        </div>
      )}

      <p className="fs21 fwb color-gray mrg-t12">{subText}</p>
    </button>
  );
};

export default ShowCard;

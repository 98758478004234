import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//Components
import CustomTagsList from '../../../../../../components/CustomTagsList/CustomTagsList';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//Utility
import { checkmark_green } from '../../../../../../assets/icons';
import { deepEqual } from '../../../../../../utils/logic/deepEqual';
import useLanguageData from '../../../../../../language/useLanguageData';

const TinyTags = ({
  Account,
  saveAccountChange,
  updateAccountIsSuccess,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const {
    accountTinyTags: loadTinyTags,
    accountTinyTagGroups: loadTinyTagGroups,
    accountBigTags,
    accountAttendeeTags,
    accountAttendee,
  } = useAccountReduxHandlers();

  //language
  const { Generic, IconAlts } = useLanguageComponents();
  const { TinyTagIcon } = useLanguageData();

  //Component state
  const [tinyTags, setTinyTags] = useState(loadTinyTags || []);
  const [tinyTagGroups, setTinyTagGroups] = useState(loadTinyTagGroups || []);
  const [tinyTagsSaveValid, setTinyTagsSaveValid] = useState(false);
  const [tinyTagGroupsSaveValid, setTinyTagGroupsSaveValid] = useState(false);
  const [tinyTagGroupsModifyActive, setTinyTagGroupsModifyActive] =
    useState(false);

  //Initialize functions
  useEffect(() => {
    if (loadTinyTags) {
      setTinyTags(loadTinyTags);
    }
    if (loadTinyTagGroups) {
      setTinyTagGroups(loadTinyTagGroups);
    }
  }, [loadTinyTagGroups, loadTinyTags]);

  //Component functions
  useEffect(() => {
    const sameContentsTinyTags =
      tinyTags?.length === loadTinyTags?.length &&
      tinyTags.every((value) => loadTinyTags.includes(value));

    if (!sameContentsTinyTags && !tinyTagGroupsModifyActive) {
      setTinyTagsSaveValid(true);
    } else {
      setTinyTagsSaveValid(false);
    }
  }, [tinyTags, loadTinyTags, tinyTagGroupsModifyActive]); //checks if tinyTags save is valid

  useEffect(() => {
    const sameContentsTinyTagGroups =
      tinyTagGroups?.length === loadTinyTagGroups?.length &&
      tinyTagGroups.every((value, index) =>
        deepEqual(value, loadTinyTagGroups[index])
      );

    if (!sameContentsTinyTagGroups && !tinyTagGroupsModifyActive) {
      setTinyTagGroupsSaveValid(true);
    } else {
      setTinyTagGroupsSaveValid(false);
    }
  }, [tinyTagGroups, loadTinyTagGroups, tinyTagGroupsModifyActive]); //checks if tinyTagGroup save is valid

  useEffect(() => {
    let tagsCount = 0;

    tinyTagGroups?.forEach((tG) => {
      tagsCount = tagsCount + tG?.tags?.length;
    });
    tagsCount = tagsCount + tinyTags?.length;

    if (tagsCount === 0) {
      setTinyTagsSaveValid(false);
      setTinyTagGroupsSaveValid(false);
    }
  }, [
    tinyTags,
    loadTinyTags,
    tinyTagGroups,
    loadTinyTagGroups,
    tinyTagGroupsSaveValid,
    tinyTagsSaveValid,
  ]); //prevent save if there is less than one tag

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
        tabIndex="0"
        id="tinyTagsLabel"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={TinyTagIcon}
            alt={IconAlts.iconTinyTag}
            style={{ height: '28px' }}
            className="mrg-r12"
          />
          <p>{Account.attendee.tinyTags}</p>
        </div>

        <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>

          {(tinyTags?.length > 0 ||
            tinyTagGroups.some((tG) => tG.tags?.length > 0)) && (
            <img
              src={checkmark_green}
              alt={IconAlts.iconCheckmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.tinyTagsDescription}
      </p>
      <div className="h24" />
      <CustomTagsList
        limit={200}
        id={'tinyTags'}
        tinyTags={tinyTags}
        setTinyTags={setTinyTags}
        twoTagRows={true}
        tinyTagGroups={tinyTagGroups}
        setTinyTagGroups={setTinyTagGroups}
        setTinyTagGroupsModifyActive={setTinyTagGroupsModifyActive}
        bigTags={accountBigTags}
        updateAccountIsSuccess={updateAccountIsSuccess}
      />

      <div className="h24" />
      <SaveButton
        saveValid={tinyTagsSaveValid || tinyTagGroupsSaveValid}
        handler={() =>
          saveAccountChange(
            {
              attendee: {
                ...accountAttendee,
                tags: {
                  ...accountAttendeeTags,
                  tinyTags,
                  tinyTagGroups,
                },
              },
            },
            'tinyTags'
          )
        }
        saveAriaLanguage={Account.attendee.tinyTags}
        isLoading={loadingSaveBtnUI === 'tinyTags'}
      />
      <div className="h48" />
    </div>
  );
};

export default TinyTags;

const ExploreMyndFullIntroduction = ({ ExploreMyndFull }) => {
  return (
    <div className="explore-myndfull-introduction explore-base-text bg-cream">
      <h2 tabIndex="0" className="access-ob access-o6">
        {ExploreMyndFull.whatIsMyndFull}
      </h2>
      <p className="mrg-t48 mrg-t24--ph access-ob access-o6" tabIndex="0">
        MyndFull is your central platform for discovering, hosting, and
        participating in academic and educational events. We bring together the
        best tools and resources to connect educators and learners &ndash; all
        in one place.
      </p>
      <p className="mrg-t24 access-ob access-o6" tabIndex="0">
        Right now, these events are scattered across educational institution
        websites, email listservs, social media posts and event platforms not
        designed for educators and their audiences.
      </p>
      <p className="fwb italic mrg-t36 access-ob access-o6" tabIndex="0">
        This should've changed a long time ago &mdash; now it has.
      </p>
    </div>
  );
};

export default ExploreMyndFullIntroduction;

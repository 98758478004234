import { useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';

//utils
import { img_archived, img_bell, settings } from '../../../../assets/icons';

const NoticeOverlayHeader = ({
  noticeOverlayIsOpened,
  setNoticeOverlayIsOpened,
  setArchiveMode,
  setSettingsMode,
  archiveMode,
  settingsMode,
  unseenNoticesNum,
  resetProfileImage,
  profileImage,
}) => {
  const { handleMobileTap, setOpenMobileNotificationsMenu } = useSettings();

  //UI highlights
  const [highlightNoticeHeaderTap, setHighlightNoticeHeaderTap] =
    useState(false);

  //language
  const { IconAlts, NoticeOverlay } = useLanguageComponents();

  return (
    <button
      className={`noticeOverlay__header access-ob ${
        highlightNoticeHeaderTap ? 'highlight-bg-lg--tap' : ''
      }`}
      tabIndex="0"
      aria-expanded={noticeOverlayIsOpened}
      onClick={() =>
        handleMobileTap(
          [
            () => setHighlightNoticeHeaderTap(true),
            () => setHighlightNoticeHeaderTap(false),
          ],
          [
            () => setNoticeOverlayIsOpened(!noticeOverlayIsOpened),
            () => setArchiveMode(false),
            () => setSettingsMode(false),
            () => setOpenMobileNotificationsMenu(false),
          ]
        )
      }
    >
      <div className="flex-row align-center mrg-auto-right text-left">
        {archiveMode && !settingsMode ? (
          <div className="flex-row align-center">
            <img
              src={img_archived}
              alt={IconAlts.iconBell}
              className="filter-theme mrg-r12"
              style={{ height: '24px' }}
            />
            <p className="fs18 ff2 mrg-t2">{NoticeOverlay.archive}</p>
          </div>
        ) : !settingsMode ? (
          <div className="flex-row align-center">
            <img
              src={img_bell}
              alt={IconAlts.iconBell}
              className="filter-theme mrg-r12"
              style={{ height: '24px' }}
            />
            {unseenNoticesNum > 0 && (
              <div
                className={`noticeOverlay__header-notice-alert mrg-r12 ${
                  unseenNoticesNum > 9
                    ? 'msg-notice-2digit'
                    : 'msg-notice-1digit'
                }`}
              >
                <p className="mrg-t2">{unseenNoticesNum}</p>
              </div>
            )}
            <p className="fs18 ff2 mrg-t2">{NoticeOverlay.notices}</p>
          </div>
        ) : (
          settingsMode && (
            <div className="flex-row align-center">
              <img
                src={settings}
                alt={IconAlts.settings}
                className="filter-theme mrg-r12"
                style={{ height: '24px' }}
              />
              <p className="fs18 ff2 mrg-t2">{NoticeOverlay.settings}</p>
            </div>
          )
        )}
      </div>
      {!resetProfileImage && (
        <LoadBucketImage
          imagePath={profileImage}
          imgClassName={'noticeOverlay__header-profile-image'}
          backupPath={'account_img_default.png'}
          partialPath={'myndfull_account_images'}
        />
      )}
    </button>
  );
};

export default NoticeOverlayHeader;

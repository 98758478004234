import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import { useSettings } from '../../../../../context/SettingsProvider';

//api
import { useLazyCommunityEventsQuery } from '../../eventsApiSlice';

//components
import EventsGrid from '../EventsGrid';
import EventPage from '../../EventPage/EventPage';

const CommunityGrid = () => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { cards, settingsViewEventDetails } = useSettings();
  const {
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    //Community
    settingsSelectedAccountsFollowingGroupIds: searchAccountsFollowingGroupIds,
    settingsSelectedAccountsFollowingIds: searchAccountsFollowingIds,
    handleSettingsSetCommunityInPersonQueryPage,
    handleSettingsSetCommunityInPersonQueryHasMore,
    settingsCommunityInPersonQueryPage: communityInPersonQueryPage,
    settingsCommunityInPersonQueryHasMore: communityInPersonQueryHasMore,
    handleSettingsSetCommunityOnlineQueryPage,
    handleSettingsSetCommunityOnlineQueryHasMore,
    settingsCommunityOnlineQueryPage: communityOnlineQueryPage,
    settingsCommunityOnlineQueryHasMore: communityOnlineQueryHasMore,

    //utils
    handleSelectAllTagsIfLoadedEmpty,
  } = useSettingsHandlers();

  //api
  const [
    getCommuniyEvents,
    {
      data: communityData,
      isFetching: communityisFetching,
      isLoading: communityisLoading,
      isUninitialized: communityisUninitialized,
    },
  ] = useLazyCommunityEventsQuery();

  //state
  const [filteredCommunityInPersonEvents, setFilteredCommunityInPersonEvents] =
    useState([]);
  const [filteredCommunityOnlineEvents, setFilteredCommunityOnlineEvents] =
    useState([]);
  const [
    communityCardsIsFetchingToFillRow,
    setCommunityCardsIsFetchingToFillRow,
  ] = useState(false);

  //ui
  const [focusId, setFocusId] = useState();
  const [returnFromEventPageComplete, setReturnFromEventPageComplete] =
    useState(true);

  //variables
  let seenCommunityInPersonIds = new Set();
  let seenCommunityOnlineIds = new Set();
  let filteredEvents = [];

  const fetchDelayTimer = useRef();

  //initialize
  useEffect(() => {
    handleSelectAllTagsIfLoadedEmpty();
    /*eslint-disable-next-line*/
  }, []); //selects all tags if loaded empty

  useEffect(() => {
    if (settingsViewEventDetails) {
      setFocusId(settingsViewEventDetails?.meta?.eventReference);
    }
  }, [settingsViewEventDetails]); //sets card focus + works with eventsGrid

  //Component functions
  useEffect(() => {
    if (
      accountId &&
      communityisUninitialized &&
      searchAccountsFollowingIds &&
      searchAccountsFollowingGroupIds
    ) {
      if (
        accountId &&
        (searchAccountsFollowingIds?.length > 0 ||
          searchAccountsFollowingGroupIds?.length > 0) &&
        searchCategory &&
        searchDirection &&
        searchFormat &&
        cards
      ) {
        handleSettingsSetCommunityInPersonQueryPage(0);
        handleSettingsSetCommunityInPersonQueryHasMore(true);
        handleSettingsSetCommunityOnlineQueryPage(0);
        handleSettingsSetCommunityOnlineQueryHasMore(true);

        getCommuniyEvents({
          accountId,
          searchAccountsFollowingIds,
          searchAccountsFollowingGroupIds,
          searchCategory,
          searchDirection,
          searchFormat,
          queryCardsPerPage: cards,
          communityQueryPage: 0,
        });
      }
    }

    /*eslint-disable-next-line*/
  }, [
    view,
    cards,
    accountId,
    searchAccountsFollowingIds,
    searchAccountsFollowingGroupIds,
    searchDirection,
    searchFormat,
    communityisUninitialized,
  ]); //initial search

  useEffect(() => {
    if (view === 'community') {
      if (!communityisFetching && !communityisLoading) {
        if (searchFormat === 'inPerson') {
          getNewCommunityInPersonEventsList();
        } else if (searchFormat === 'online') {
          getNewCommunityOnlineEventsList();
        }
      }
    }
    /*eslint-disable-next-line*/
  }, [
    searchCategory,
    searchDirection,
    searchFormat,
    searchAccountsFollowingIds,
    searchAccountsFollowingGroupIds,
  ]);

  //Community EVENTS FUNCTIONS
  useEffect(() => {
    if (communityData?.data) {
      if (searchFormat === 'inPerson') {
        communityData.data.forEach((item) => {
          if (!seenCommunityInPersonIds.has(item._id)) {
            filteredEvents.push(item);
            seenCommunityInPersonIds.add(item._id);
          }
        });
        setFilteredCommunityInPersonEvents(filteredEvents);
        filteredEvents = [];
      } else if (searchFormat === 'online') {
        communityData.data.forEach((item) => {
          if (!seenCommunityOnlineIds.has(item._id)) {
            filteredEvents.push(item);
            seenCommunityOnlineIds.add(item._id);
          }
        });
        setFilteredCommunityOnlineEvents(filteredEvents);
        filteredEvents = [];
      }
    }
    return () => {
      seenCommunityInPersonIds.clear();
      seenCommunityOnlineIds.clear();
    };
  }, [communityData]); //removal of duplicate events

  useEffect(() => {
    if (
      filteredCommunityInPersonEvents?.length % cards !== 0 &&
      communityInPersonQueryHasMore &&
      !communityCardsIsFetchingToFillRow
    ) {
      getNextCommunityInPersonEventsPage();
    } else {
      fetchDelayTimer.current = setTimeout(() => {
        setCommunityCardsIsFetchingToFillRow(false);
      }, 500);
    }
  }, [filteredCommunityInPersonEvents]); //check to see if full row; if not need to continue loading state

  useEffect(() => {
    if (
      filteredCommunityOnlineEvents?.length % cards !== 0 &&
      communityOnlineQueryHasMore &&
      !communityCardsIsFetchingToFillRow
    ) {
      getNextCommunityOnlineEventsPage();
    } else {
      fetchDelayTimer.current = setTimeout(() => {
        setCommunityCardsIsFetchingToFillRow(false);
      }, 500);
    }
  }, [filteredCommunityOnlineEvents]); //check to see if full row; if not need to continue loading state

  function getNextCommunityInPersonEventsPage() {
    if (
      !communityisFetching &&
      !communityisLoading &&
      accountId &&
      (searchAccountsFollowingIds?.length > 0 ||
        searchAccountsFollowingGroupIds?.length > 0)
    ) {
      setCommunityCardsIsFetchingToFillRow(true);

      const newPage = communityInPersonQueryPage + 1;
      handleSettingsSetCommunityInPersonQueryPage(newPage);
      getCommuniyEvents({
        accountId,
        searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat: 'inPerson',
        queryCardsPerPage: cards,
        communityQueryPage: newPage,
      });
    }
  }

  function getNextCommunityOnlineEventsPage() {
    if (
      !communityisFetching &&
      !communityisLoading &&
      accountId &&
      (searchAccountsFollowingIds?.length > 0 ||
        searchAccountsFollowingGroupIds?.length > 0)
    ) {
      setCommunityCardsIsFetchingToFillRow(true);

      const newPage = communityOnlineQueryPage + 1;
      handleSettingsSetCommunityOnlineQueryPage(newPage);
      getCommuniyEvents({
        accountId,
        searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat: 'online',
        queryCardsPerPage: cards,
        communityQueryPage: newPage,
      });
    }
  }

  async function getNewCommunityInPersonEventsList(
    followButtonSearchAccountsFollowingIds,
    followButtonSearchAccountsFollowingGroupIds
  ) {
    if (!communityisFetching && !communityisLoading && accountId) {
      setCommunityCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetCommunityInPersonQueryPage(newPage);
      await getCommuniyEvents({
        accountId,
        searchAccountsFollowingIds:
          followButtonSearchAccountsFollowingIds || searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds:
          followButtonSearchAccountsFollowingGroupIds ||
          searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat: 'inPerson',
        queryCardsPerPage: cards,
        communityQueryPage: newPage,
      });
    }
  }

  async function getNewCommunityOnlineEventsList(
    followButtonSearchAccountsFollowingIds,
    followButtonSearchAccountsFollowingGroupIds
  ) {
    if (!communityisFetching && !communityisLoading && accountId) {
      setCommunityCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetCommunityOnlineQueryPage(newPage);
      await getCommuniyEvents({
        accountId,
        searchAccountsFollowingIds:
          followButtonSearchAccountsFollowingIds || searchAccountsFollowingIds,
        searchAccountsFollowingGroupIds:
          followButtonSearchAccountsFollowingGroupIds ||
          searchAccountsFollowingGroupIds,
        searchCategory,
        searchDirection,
        searchFormat: 'online',
        queryCardsPerPage: cards,
        communityQueryPage: newPage,
      });
    }
  }

  async function getNewCommunityEventLists() {
    await getNewCommunityInPersonEventsList();
    await getNewCommunityOnlineEventsList();
  }

  async function updateEventDataListPostRegistration(newEventData) {
    setFilteredCommunityInPersonEvents(
      filteredCommunityInPersonEvents?.map((ev) =>
        ev._id === newEventData._id ? newEventData : ev
      )
    );
    setFilteredCommunityOnlineEvents(
      filteredCommunityOnlineEvents?.map((ev) =>
        ev._id === newEventData._id ? newEventData : ev
      )
    );
  }

  return (
    <>
      {settingsViewEventDetails ? (
        <EventPage
          getNewCommunityEventsList={getNewCommunityEventLists}
          updateEventDataListPostRegistration={
            updateEventDataListPostRegistration
          }
        />
      ) : (
        <EventsGrid
          cards={cards}
          events={
            searchFormat === 'inPerson'
              ? filteredCommunityInPersonEvents
              : filteredCommunityOnlineEvents
          }
          isLoading={communityCardsIsFetchingToFillRow}
          isUninitialized={communityisUninitialized}
          nextPageFunction={
            searchFormat === 'inPerson'
              ? getNextCommunityInPersonEventsPage
              : getNextCommunityOnlineEventsPage
          }
          hasMore={
            searchFormat === 'inPerson'
              ? communityInPersonQueryHasMore
              : communityOnlineQueryHasMore
          }
          tagsCheck={
            searchAccountsFollowingIds?.length ||
            searchAccountsFollowingGroupIds?.length
          }
          eventsGridType={'community'}
          key={`eventsGrid-community-${searchFormat}`}
          focusId={focusId}
          setFocusId={setFocusId}
          returnFromEventPageComplete={returnFromEventPageComplete}
          setReturnFromEventPageComplete={setReturnFromEventPageComplete}
        />
      )}
    </>
  );
};

export default CommunityGrid;

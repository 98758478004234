import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider';
import { useGetInvoicePdfMutation } from '../../accountApiSlice';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//language
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import Spinner from '../../../../../components/Spinner/Spinner';
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utils
import { flag, img_download } from '../../../../../assets/icons';
import { priceLocaleConversion } from '../../../../../utils/currency/priceLocaleConversion';
import handleBillingSubscriptionProrationDifference from '../../../stripe/handleBillingSubscriptionProrationDifference';

const BillingSummary = () => {
  //hooks

  const { contentLanguage } = useSettings();
  const {
    accountId,
    accountBillingMyInvoices,
    accountBillingCustomerId,
    accountFailedInvoices,
  } = useAccountReduxHandlers();
  const [getInvociePdf] = useGetInvoicePdfMutation();

  //language
  const { InvoiceStatus } = useLanguageData();
  const { BillingSummary } = useLanguageAccount();

  //state
  const [listInvoiceDates, setListInvoiceDates] = useState();
  const [selectedInvoiceData, setSelectedInvoiceData] = useState();
  const [invoiceTaxRates, setInvoiceTaxRates] = useState([]);
  const [hasFailedInvoice, setHasFailedInvoice] = useState(false);

  const [roomCodesSubscriptionRange, setRoomCodesSubscriptionRange] =
    useState();
  const [roomCodeSubscriptionQuantity, setRoomCodeSubscriptionQuantity] =
    useState();
  const [roomCodeSubscriptionAmountDue, setRoomCodeSubscriptionAmountDue] =
    useState();
  const [roomCodeProrationAmount, setRoomCodeProrationAmount] = useState();

  const [roomCodesUsageRange, setRoomCodesUsageRange] = useState();

  //UI
  const [invoicesIsLoading, setInvoicesIsLoading] = useState(false);
  const [getInvoicePdfIsLoading, setGetInvoicePdfIsLoading] = useState(false);

  //variables
  const options = { day: '2-digit', month: 'short', year: 'numeric' };

  //initialize
  useEffect(() => {
    if (accountBillingMyInvoices?.length > 0) {
      setSelectionDates();

      for (const invoice of accountBillingMyInvoices || []) {
        if (accountFailedInvoices.includes(invoice?.id)) {
          setHasFailedInvoice(true);
          break;
        }
      }
    }
  }, [accountBillingMyInvoices]); //sets selection dates

  function setSelectionDates() {
    let listInvDates = {};

    if (accountBillingMyInvoices?.length > 0) {
      setInvoicesIsLoading(true);
      accountBillingMyInvoices.forEach((invoice, index) => {
        let invoiceNumber;
        if (invoice?.number) {
          invoiceNumber = `#${invoice.number.split('-')[1]}`;
        }
        if (!invoice?.number && invoice.status === 'draft') {
          invoiceNumber = BillingSummary.pending;
        }

        const endDate = new Date(invoice?.period_end * 1000);

        const options = {
          day: '2-digit',
          month: 'long',
          year: 'numeric',
        };

        const formattedEndDate = endDate.toLocaleDateString(
          contentLanguage,
          options
        );

        let invoiceSelectionTitle = invoice?.upcomingInvoice
          ? `${formattedEndDate} (${BillingSummary.upcoming})`
          : `${formattedEndDate} (${invoiceNumber})`;

        listInvDates[`${formattedEndDate} - ${invoice.id}`] =
          invoiceSelectionTitle;
      });

      setSelectedInvoiceData();
      setListInvoiceDates(listInvDates);
    }
    setInvoicesIsLoading(false);
  }

  function handleSelectInvoice(selectedDate) {
    //reset states
    setRoomCodesSubscriptionRange();
    setRoomCodeSubscriptionQuantity();
    setRoomCodesUsageRange();
    setInvoiceTaxRates();
    setSelectedInvoiceData();
    setRoomCodeProrationAmount();

    if (!selectedDate || !accountBillingMyInvoices) {
      return null;
    }

    // Extract the original date part from the selected date
    const invoiceId = selectedDate.split(' - ')[1];

    //find selectedInvoice
    const selectedInvoice = accountBillingMyInvoices.find((invoice) => {
      return invoice.id === invoiceId;
    });

    if (
      selectedInvoice?.roomCodeSubscriptions[0]?.period?.start &&
      selectedInvoice?.roomCodeSubscriptions[0]?.period?.end
    ) {
      const subscriptionStart = new Date(
        selectedInvoice.roomCodeSubscriptions[0]?.period?.start * 1000
      );

      const subscriptionEnd = new Date(
        selectedInvoice.roomCodeSubscriptions[0]?.period?.end * 1000
      );

      const formattedSubscriptionStart = subscriptionStart
        .toLocaleDateString(contentLanguage, options)
        .replace(/([A-Za-z]+)(\s)/, '$1.$2');

      const formattedSubscriptionEnd = subscriptionEnd
        .toLocaleDateString(contentLanguage, options)
        .replace(/([A-Za-z]+)(\s)/, '$1.$2');

      setRoomCodesSubscriptionRange(
        `${formattedSubscriptionStart} - ${formattedSubscriptionEnd}`
      );
    }

    let quantity;
    let translationRoomCodeSubscriptionAmountDue = 0;

    selectedInvoice?.roomCodeSubscriptions.forEach((rc) => {
      if (!quantity) {
        quantity = rc.quantity;
      } else {
        const a = Math.abs(rc.quantity);
        const b = Math.abs(quantity);
        quantity = a - b;
      }

      translationRoomCodeSubscriptionAmountDue =
        translationRoomCodeSubscriptionAmountDue + rc.amount;
    });
    setRoomCodeSubscriptionAmountDue(translationRoomCodeSubscriptionAmountDue);
    setRoomCodeSubscriptionQuantity(quantity);

    const prorationDifference =
      handleBillingSubscriptionProrationDifference(selectedInvoice);

    if (prorationDifference > 0) {
      setRoomCodeProrationAmount(prorationDifference);
    }

    //Usage calcs.
    const invoiceEndDate = new Date(selectedInvoice?.period_end * 1000);

    let formattedUsageStart;
    let formattedUsageEnd;
    let usageStart;
    let usageEnd;

    if (
      selectedInvoice?.roomCodeUsage?.period?.start &&
      selectedInvoice?.roomCodeUsage?.period?.end
    ) {
      usageStart = new Date(
        selectedInvoice.roomCodeUsage?.period?.start * 1000
      );

      formattedUsageStart = usageStart
        .toLocaleDateString(contentLanguage, options)
        .replace(/([A-Za-z]+)(\s)/, '$1.$2');

      usageEnd = new Date(selectedInvoice.roomCodeUsage?.period?.end * 1000);
      formattedUsageEnd = usageEnd
        .toLocaleDateString(contentLanguage, options)
        .replace(/([A-Za-z]+)(\s)/, '$1.$2');

      if (usageEnd > invoiceEndDate) {
        setRoomCodesUsageRange();
      } else {
        setRoomCodesUsageRange(`${formattedUsageStart} - ${formattedUsageEnd}`);
      }
    }

    // set taxes
    let invTaxRates = [];
    selectedInvoice?.default_tax_rates?.forEach((txObj) => {
      let txRate = {
        display_name: txObj?.display_name,
        percentage: txObj?.percentage,
      };
      invTaxRates.push(txRate);
    });
    setInvoiceTaxRates(invTaxRates);

    setSelectedInvoiceData(selectedInvoice);
  }

  async function downloadInvoicePdf() {
    if (!getInvoicePdfIsLoading) {
      setGetInvoicePdfIsLoading(true);
      try {
        const response = await getInvociePdf({
          accountId,
          invoiceId: selectedInvoiceData.id,
        });
        const invoicePdfFileName = response.data.invoicePdfFileName;
        const bufferPdf = response.data.contents.data;
        const uint8Array = new Uint8Array(bufferPdf);
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = invoicePdfFileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.log(error);
      }

      setGetInvoicePdfIsLoading(false);
    }
  }

  let invoiceStatus;

  if (selectedInvoiceData) {
    if (accountFailedInvoices.includes(selectedInvoiceData.id)) {
      invoiceStatus = `${BillingSummary.invoice} ${InvoiceStatus['unpaid']}`;
    } else if (
      InvoiceStatus[selectedInvoiceData.status] === 'Draft' &&
      !selectedInvoiceData.upcomingInvoice
    ) {
      //pending
      invoiceStatus = `${BillingSummary.invoice} (${BillingSummary.pending})`;
    } else if (selectedInvoiceData.upcomingInvoice) {
      //upcoming
      invoiceStatus = `${BillingSummary.invoice} (${BillingSummary.upcoming})`;
    } else if (
      InvoiceStatus[selectedInvoiceData.status] !== 'Draft' &&
      !selectedInvoiceData.upcomingInvoice
    ) {
      //paid + other
      invoiceStatus = `${BillingSummary.invoice} ${
        InvoiceStatus[selectedInvoiceData.status]
      }`;
    }
  }

  return (
    <>
      <div className="section">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="billingSummaryLabel"
        >
          {BillingSummary.billingSummary}
        </label>

        {hasFailedInvoice && (
          <div className="flex-row full-width align-center mrg-t24">
            <img
              src={flag}
              alt="flag"
              style={{ height: '24px' }}
              className="filter-red"
            />
            <p className="fs18 fwb color-red mrg-l12">
              {BillingSummary.unpaidInvoices}
            </p>
          </div>
        )}

        <div className="mrg-t24">
          {accountBillingCustomerId && invoicesIsLoading ? (
            <Spinner />
          ) : accountBillingCustomerId && listInvoiceDates ? (
            <SelectDropdown
              id={'invoiceSelect'}
              list={listInvoiceDates}
              handleSelection={handleSelectInvoice}
              internalLabel={BillingSummary.billingPeriod}
              ariaTitle={BillingSummary.billingPeriod}
              autoUpdate={true}
              customWrapper={'mrg-auto-lr'}
              fullWidth={true}
            />
          ) : (
            <p className="fs18 access-ob access-o6" tabIndex="0">
              {BillingSummary.noBillingSummaries}
            </p>
          )}

          {selectedInvoiceData && (
            <>
              <div className="divider mrg-tb24" />
              <div className="flex-column mrg-t12 fs18">
                <div
                  className="flex-row flex-column--ph mrg-t12 space-between access-ob access-o6"
                  tabIndex="0"
                >
                  <p className="fwsb fs18">{BillingSummary.monthlyOverview}</p>

                  <p
                    className={
                      accountFailedInvoices?.includes(selectedInvoiceData?.id)
                        ? 'color-red fwsb'
                        : ''
                    }
                  >
                    {invoiceStatus}
                  </p>
                </div>

                {roomCodesSubscriptionRange && (
                  <>
                    <div className="full-width divider-dotted mrg-tb24" />
                    <div
                      className="flex-row flex-column--ph space-between access-ob access-o6"
                      tabIndex="0"
                    >
                      <p className="fwsb fs18">
                        {BillingSummary.roomCodeSubscriptions}
                      </p>
                      <p>{roomCodesSubscriptionRange}</p>
                    </div>

                    <div
                      className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                      tabIndex="0"
                    >
                      <p>{BillingSummary.totalRoomCodes}:</p>
                      <p> {roomCodeSubscriptionQuantity}</p>
                    </div>

                    {roomCodeProrationAmount && (
                      <>
                        <div
                          className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                          tabIndex="0"
                        >
                          <p>{BillingSummary.prorationRefund}:</p>
                          <p>
                            ({' '}
                            {priceLocaleConversion(
                              roomCodeProrationAmount,
                              contentLanguage,
                              selectedInvoiceData.currency,
                              true
                            )}{' '}
                            )
                          </p>
                        </div>
                      </>
                    )}

                    <div
                      className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                      tabIndex="0"
                    >
                      <p>{BillingSummary.subscriptionCharge}:</p>
                      <p>
                        {priceLocaleConversion(
                          roomCodeSubscriptionAmountDue,
                          contentLanguage,
                          selectedInvoiceData.currency,
                          true
                        )}
                      </p>
                    </div>
                  </>
                )}

                {roomCodesUsageRange && (
                  <>
                    <div className="full-width divider-dotted mrg-t24 mrg-b12" />
                    <div
                      className="flex-row flex-column--ph mrg-t12 space-between access-ob access-o6"
                      tabIndex="0"
                    >
                      <p className="fwsb fs18">
                        {BillingSummary.roomCodeUsage}
                      </p>
                      <p>{roomCodesUsageRange}</p>
                    </div>

                    <div
                      className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                      tabIndex="0"
                    >
                      <p>{BillingSummary.totalWordCount}:</p>
                      <p>{selectedInvoiceData.roomCodeUsage?.quantity}</p>
                    </div>

                    <div
                      className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                      tabIndex="0"
                    >
                      <p>{BillingSummary.totalWordCount}:</p>
                      <p>{selectedInvoiceData.roomCodeUsage?.quantity}</p>
                    </div>

                    <div
                      className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                      tabIndex="0"
                    >
                      <p>{BillingSummary.usageCharge}:</p>
                      <p>
                        {priceLocaleConversion(
                          selectedInvoiceData.roomCodeUsage?.amount,
                          contentLanguage,
                          selectedInvoiceData.currency,
                          true
                        )}
                      </p>
                    </div>
                  </>
                )}

                <div className="full-width divider-dotted mrg-t24 mrg-b12 access-ob access-o6" />
                <div
                  className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                  tabIndex="0"
                >
                  <p>{BillingSummary.subtotal}:</p>
                  <p>
                    {priceLocaleConversion(
                      selectedInvoiceData.subtotal,
                      contentLanguage,
                      selectedInvoiceData.currency,
                      true
                    )}
                  </p>
                </div>

                <div
                  className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                  tabIndex="0"
                >
                  <div className="flex-row">
                    {invoiceTaxRates?.length > 0
                      ? invoiceTaxRates?.map((tx, index) => {
                          return (
                            <p
                              key={`tx-obj-${index}`}
                              className={`${index !== 0 ? 'mrg-l6' : ''}`}
                            >
                              {tx?.display_name}&nbsp;({tx?.percentage}%)
                              {index !== invoiceTaxRates?.length - 1
                                ? ' + '
                                : ''}
                            </p>
                          );
                        })
                      : BillingSummary.tax}
                    :
                  </div>
                  <p>
                    {priceLocaleConversion(
                      selectedInvoiceData.tax,
                      contentLanguage,
                      selectedInvoiceData.currency,
                      true
                    )}
                  </p>
                </div>

                <div
                  className="flex-row full-width space-between mrg-t12 access-ob access-o6"
                  tabIndex="0"
                >
                  <p>
                    {BillingSummary.total} (
                    {selectedInvoiceData.currency.toUpperCase()}):
                  </p>
                  <p>
                    {priceLocaleConversion(
                      selectedInvoiceData.total,
                      contentLanguage,
                      selectedInvoiceData.currency,
                      true
                    )}
                  </p>
                </div>
              </div>
              {selectedInvoiceData.invoice_pdf && (
                <ItemButton
                  handler={downloadInvoicePdf}
                  text={BillingSummary.downloadInvoice}
                  fullWidth={true}
                  customWrapper={'mrg-tb24'}
                  isLoading={getInvoicePdfIsLoading}
                  icon={img_download}
                  iconOn={true}
                />
              )}
              <div className="divider mrg-tb24" />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BillingSummary;

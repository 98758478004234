import { useEffect, useState } from 'react';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//utils
import { lock_locked } from '../../../../assets/icons';
import GetFlag from '../../../../utils/images/GetFlag';
import FieldValueMissing from './FieldValueMissing';

const EventPageContact = ({
  eventData,
  EventPage,
  organizerViewOn,
  eventStatus,
  isParticipant,
}) => {
  //hooks
  const { IconAlts } = useLanguageComponents();

  //state
  const [flag, setFlag] = useState();

  //variables
  const privateDetailsEnabled =
    eventData?.registrationData?.privateDetails?.enabled;

  const contactPhoneLocked =
    eventData?.registrationData?.privateDetails?.contactPhone;
  const contactEmailLocked =
    eventData?.registrationData?.privateDetails?.contactEmail;

  let contactPhoneInfo;
  let contactEmailInfo;

  useEffect(() => {
    setFlag(GetFlag(eventData?.contact?.contactCountryCode));
  }, [eventData]);

  //phone
  if (
    privateDetailsEnabled &&
    contactPhoneLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    contactPhoneInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.phoneNumber}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
        />
      </div>
    );
  } else if (organizerViewOn && privateDetailsEnabled && contactPhoneLocked) {
    if (eventData?.contact?.contactPhoneNumber?.length > 0) {
      contactPhoneInfo = (
        <>
          <div className="ev-info__row access-ob access-o6" tabIndex="0">
            <p>{EventPage.phoneNumber}</p>
            <img
              src={lock_locked}
              alt={IconAlts.iconLock}
              className="lock-img mrg-lr12"
            />
            {flag || eventData?.contact?.contactPhoneNumber?.length > 0 ? (
              <div className="flex-row previewMode-border outline-offset-2">
                <div className="align-center">{flag}</div>
                <p className="mrg-l6">
                  {eventData?.contact?.contactPhoneNumber}
                </p>
              </div>
            ) : (
              <FieldValueMissing />
            )}
          </div>
        </>
      );
    }
  } else {
    if (eventData?.contact?.contactPhoneNumber?.length > 0) {
      contactPhoneInfo = (
        <div className="ev-info__row access-ob access-o6" tabIndex="0">
          <p>{EventPage.phoneNumber}:</p>
          <div className="align-center mrg-l12">{flag}</div>
          <p className="mrg-l6">{eventData?.contact?.contactPhoneNumber}</p>
        </div>
      );
    }
  }

  if (
    privateDetailsEnabled &&
    contactEmailLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    contactEmailInfo = (
      <div className={`ev-info__row access-ob access-o6`} tabIndex="0">
        <p>{EventPage.email}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
        />
      </div>
    );
  } else if (organizerViewOn && privateDetailsEnabled && contactEmailLocked) {
    if (eventData?.contact?.contactEmail?.length > 0) {
      contactEmailInfo = (
        <div className={`ev-info__row access-ob access-o6`} tabIndex="0">
          <p>{EventPage.email}</p>
          <img
            src={lock_locked}
            alt={IconAlts.iconLock}
            className="lock-img mrg-lr12"
          />
          {eventData?.contact?.contactEmail ? (
            <p className="previewMode-border outline-offset-2">
              {eventData?.contact?.contactEmail}
            </p>
          ) : (
            <FieldValueMissing />
          )}
        </div>
      );
    }
  } else {
    if (eventData?.contact?.contactEmail?.length > 0) {
      contactEmailInfo = (
        <div className={`ev-info__row access-ob access-o6 `} tabIndex="0">
          <p>{EventPage.email}:</p>
          <p className="mrg-l12">{eventData?.contact?.contactEmail}</p>
        </div>
      );
    }
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="contactLabel"
      >
        {EventPage.eventContact}
      </label>
      <div className="ev-info__container">
        {contactPhoneInfo}
        {contactEmailInfo}
      </div>
    </>
  );
};

export default EventPageContact;

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';

//components
import CheckBoxObjSelections from '../../../../../../components/CheckBoxes/CheckBoxObjSelections';
import ParticipantListItem from '../../../../organize/eventSections/ParticipantSections/ParticipantListItem/ParticipantListItem';

//utils
import {
  cancel_red,
  checkmark_green,
  img_exclamation,
  img_hourglass,
} from '../../../../../../assets/icons';

const TranslationsDraftsDispatchesTable = ({
  participants,
  handleTranslationsReadyDispatch,
}) => {
  //language
  const { TranslationDrafts } = useLanguageTranslationRoom();
  const { IconAlts, Generic } = useLanguageComponents();

  function getDispatchesCount(participant) {
    let totalDispatchesAvailable = 0;
    let totalCurrentDispatches = 0;
    let allDispatchesIssued = false;

    if (!participant.dispatches?.translationsDispatched) {
      totalDispatchesAvailable++;
    }

    if (participant.dispatches?.translationsStatus === 'dispatching') {
      totalCurrentDispatches++;
    }

    if (totalDispatchesAvailable === 0) {
      if (participant?.dispatches?.translationsDispatched) {
        allDispatchesIssued = true;
      }
    }

    return {
      allDispatchesIssued,
      totalDispatchesAvailable,
      totalCurrentDispatches,
    };
  }

  return (
    <table className="dispatches-table">
      <thead>
        <tr>
          <th>{TranslationDrafts.emailInvitations}</th>
          <th>{TranslationDrafts.status}</th>
        </tr>
      </thead>
      <tbody>
        {participants?.map((participant, index) => {
          if (participant.organizedAs === 'create') return null;

          const {
            totalDispatchesAvailable,
            totalCurrentDispatches,
            allDispatchesIssued,
          } = getDispatchesCount(participant);

          return (
            <tr key={`communityContact-${index}`}>
              <td className="full-width">
                <ParticipantListItem
                  participant={participant}
                  listItemMode={'dispatches'}
                  //ui
                  turnOffAnimation={true}
                  borderBottomOff={true}
                  translationsNameOnly={true}
                  //dispatches
                  totalDispatchesAvailable={totalDispatchesAvailable}
                  totalCurrentDispatches={totalCurrentDispatches}
                  allDispatchesIssued={allDispatchesIssued}
                  translationsEnabled={true}
                  translationsDraft={true}
                />
              </td>

              <td>
                {participant?.dispatches?.translationsStatus === 'pending' && (
                  <img
                    src={img_hourglass}
                    alt={IconAlts.iconHourglass}
                    className="filter-theme"
                    style={{ height: '28px' }}
                  />
                )}

                {participant?.dispatches?.translationsStatus === 'accepted' && (
                  <img
                    src={checkmark_green}
                    alt={IconAlts.iconCheckmark}
                    className=""
                    style={{ height: '21px' }}
                  />
                )}

                {(participant?.dispatches?.translationsStatus === 'rejected' ||
                  participant?.dispatches?.translationsStatus ===
                    'withdrew') && (
                  <img
                    src={cancel_red}
                    alt={'X'}
                    className=""
                    style={{ height: '21px' }}
                  />
                )}

                {participant?.dispatches?.translationsStatus === 'error' && (
                  <>
                    <img
                      src={img_exclamation}
                      alt={IconAlts.iconExclamation}
                      className="filter-red"
                      style={{ height: '24px' }}
                    />
                    <p className="fs18 text-red fwsb">{Generic.error}</p>
                  </>
                )}

                {(participant.translationsLanguageConflict ||
                  participant.translationsRoomRequiresLanguage ||
                  participant.completionStatus !== 'complete') &&
                  participant.dispatches.translationsDispatched === false && (
                    <>
                      <img
                        src={img_exclamation}
                        alt={IconAlts.iconExclamation}
                        className="filter-red"
                        style={{ height: '24px' }}
                      />
                      <p className="fs18 text-red fwsb">{Generic.conflict}</p>
                    </>
                  )}

                {!participant?.dispatches?.translationsDispatched &&
                  participant.completionStatus === 'complete' &&
                  !participant.translationsLanguageConflict &&
                  !participant.translationsRoomRequiresLanguage && (
                    <CheckBoxObjSelections
                      visible={true}
                      selectionObj={{ dispatchReady: false }}
                      handler={handleTranslationsReadyDispatch}
                      customWrapper={'mrg-auto-lr'}
                      accountId={participant.id}
                      handleAccountId={true}
                      loadTrigger={true}
                      loadValue={
                        participant.dispatches.translationsStatus ===
                        'dispatching'
                      }
                      labelOff={true}
                    />
                  )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TranslationsDraftsDispatchesTable;

import { useSettings } from '../../context/SettingsProvider';

const useLanguageSettings = () => {
  const { contentLanguage } = useSettings();

  const SettingsEventsLanguage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        myEvents: 'My Events',
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',
        community: 'Community',
        communityEvents: 'Community Events',
        filters: 'Filters',
        date: 'Date',
        searchDate: 'Search Date',
        distance: 'Distance',
        searchDistance: 'Search Distance',
        searchFormatAll: 'Search All',
        allEvents: 'All Events',
        searchFormatInPerson: 'Search In Person',
        inPersonEvents: 'In Person Events',
        searchFormatOnline: 'Search Online',
        onlineEvents: 'Online Events',
        searching: 'Searching',
        sorting: 'Sorting',
        descending: 'Descending',
        searchDescending: 'Search Descending',
        ascending: 'Ascending',
        searchAscending: 'Search Ascending',
        selectAll: 'Select All',
        unselectAll: 'Unselect All',
        numberOfTagsSelected: 'Number of Tags Selected',
        numberOfTagGroupsSelected: 'Number of Tag Groups Selected',
        numberOfCommunityAccountsSelected:
          'Number of Community Accounts Selected',
        numberOfCommunityGroupsSelected: 'Number of Community Groups Selected',
        tags: 'Tags',
        by: 'by',
        earliest: 'Earliest',
        latest: 'Latest',
        furthest: 'Furthest',
        closest: 'Closest',
        savedEvents: 'Saved Events',
        registeredEvents: 'Registered Events',
        archivedEvents: 'Archived Events',
        participationEvents: 'Participation Events',
        participation: 'Participation',
        archived: 'Archived',
        registered: 'Registered',
        saved: 'Saved',
        ariaAscending: 'Event order is ascending.',
        ariaDescending: 'Event order is desscending.',
        ariaDate: 'Event order is date.',
        ariaDistance: 'Event order is distance.',
        ariaMyEventsSelectionToggle: 'Show the My Events selections.',
        ariaBigTagsSelectionToggle: 'Show your list of big tags.',
        ariaTinyTagsSelectionToggle: 'Show your list of tiny tags.',
        ariaCommunitySelectionToggle: 'Show your community list.',
        ariaMyEventsSelectionSavedEvents: 'Current selection is Saved Events.',
        ariaMyEventsSelectionRegisteredEvents:
          'Current selection is Registered Events.',
        ariaBigTagsSelectionSummary: 'Number of active big tags.',
        ariaTinyTagsSelectionSummaryFolders:
          'Number of active tiny tag groups.',
        ariaSelectionArchivedEvents: 'Current selection is Archived Events.',
        ariaSelectionParticipationEvents:
          'Current selection is Participation Events.',
        ariaTinyTagsSelectionSummaryTags: 'Number of active tiny tags',
        ariaCommunitySelectionSummary: 'Number of active community accounts',
        ariaCommunitySelectionSummaryFolders:
          'Number of active community groups.',
        ariaShowRemainingTags: 'Click to show remaining tags.',
        ariaMyEventsSelections: 'My events selections.',
        ariaEventFilters: 'Event filters.',
        ariaTinyTagGroup: 'Tiny Tag Group.',
        ariaCommunityGroup: 'Community Group.',
        ariaTag: 'Tag.',
        ariaSettingsIcon: 'Settings icon',
        buildingImg: 'Building Img',
        globeImg: 'Globe Img',
        noEventsSelected: 'No Events Selected',
        ariaCurrentSelections: 'Current Selections.',
        ariaNoSelectionsActive: 'No selections active.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        myEvents: 'Mes Événements',
        bigTags: 'Grandes Balises',
        tinyTags: 'Petit Balises',
        community: 'Communauté',
        communityEvents: 'Événements communautaires',
        filters: 'Filtres',
        date: 'Date',
        searchDate: 'Date de recherche',
        distance: 'Distance',
        searchDistance: 'Distance de recherche',
        searchFormatAll: 'Recherche tout',
        allEvents: `tous les événements`,
        searchFormatInPerson: 'Recherche en personne',
        inPersonEvents: 'événements en personne',
        searchFormatOnline: 'Recherche en ligne',
        onlineEvents: 'événements en ligne',
        searching: 'Recherche',
        sorting: 'Tri',
        searchDescending: 'Recherche décroissante',
        descending: 'Ordre décroissant',
        searchAscending: 'Recherche croissante',
        ascending: 'Ordre croissant',
        selectAll: 'Tout sélectionner',
        unselectAll: 'Tout déselectionner',
        numberOfTagsSelected: 'Nombre de balises sélectionnées',
        numberOfTagGroupsSelected: 'Nombre de groupes de balises sélectionnés',
        numberOfCommunityAccountsSelected:
          'Nombre de comptes communautaires sélectionnés',
        numberOfCommunityGroupsSelected:
          'Nombre de groupes communautaires sélectionnés',
        tags: 'Balises',
        by: 'par',
        earliest: 'Le plus tôt',
        latest: 'Le plus tard',
        furthest: 'Le plus éloigné',
        closest: 'Le plus proche',
        savedEvents: 'Événements sauvegardés',
        registeredEvents: 'Événements inscrits',
        archivedEvents: 'Événements archivés',
        participationEvents: 'Événements de participation',
        participation: 'Participation',
        archived: 'Archivés',
        registered: 'Inscrit',
        saved: 'Sauvegardé',
        ariaAscending: `L'ordre des événements est croissant.`,
        ariaDescending: `L'ordre des événements est décroissant.`,
        ariaDate: `L'ordre de l'événement est la date.`,
        ariaDistance: `L'ordre des événements est la distance.`,
        ariaMyEventsSelectionToggle: `Afficher les sélections Mes événements.`,
        ariaBigTagsSelectionToggle: `Afficher votre liste de Grandes Balises.`,
        ariaTinyTagsSelectionToggle: `Afficher votre liste de Petit Balises.`,
        ariaCommunitySelectionToggle: 'Affichez votre liste de communauté.',
        ariaMyEventsSelectionSavedEvents:
          'La sélection actuelle est Événements sauvegardés',
        ariaMyEventsSelectionRegisteredEvents:
          'La sélection actuelle est Événements enregistrés.',
        ariaSelectionParticipationEvents:
          'La sélection actuelle est Événements de participation.',
        ariaSelectionArchivedEvents:
          'La sélection actuelle est Événements archivés.',
        ariaBigTagsSelectionSummary: 'Le nombre de Grandes balises actives.',
        ariaTinyTagsSelectionSummaryFolders:
          'Le nombre de petits groupes de balises actifs.',
        ariaTinyTagsSelectionSummaryTags:
          'Le nombre de petites balises actives.',
        ariaCommunitySelectionSummary:
          'Nombre de comptes communautaires actifs.',
        ariaShowRemainingTags: 'Le nombre de petit balises actives.',
        ariaMyEventsSelections: `Mes sélections d'événements.`,
        ariaEventFilters: `Filtres d'événements.`,
        ariaTinyTagGroup: 'Petit groupe de balises.',
        ariaTag: 'balise.',
        ariaSettingsIcon: 'icône des paramètres',
        ariaCommunitySelectionSummaryFolders:
          'Nombre de groupes communautaires actifs.',
        ariaCommunityGroup: 'Groupe communautaire.',
        buildingImg: 'Bâtiment Img',
        globeImg: 'Globe Img',
        ariaOrganizerFilters: 'Organizer filters.',
        noEventsSelected: `Aucun événement sélectionné`,
        ariaCurrentSelections: 'Sélections actuelles.',
        ariaNoSelectionsActive: 'Aucune sélection active.',
      };
    }

    return translation;
  };

  const SettingsOrganizeLanguage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        organizerOverview: 'Organizer Overview',
        organizerSelection: 'Organizer Selection',
        createEvents: 'Create Events',
        findSpeakers: 'Find Speakers',
        createMultidayEvents: 'Create Multiday Events',
        descending: 'Descending',
        ascending: 'Ascending',
        date: 'Date',
        distance: 'Distance',
        views: 'Views',

        viewsAndSorting: 'Views & Sorting',
        sort: 'Sort',
        earliest: 'Earliest',
        latest: 'Latest',
        furthest: 'Furthest',
        closest: 'Closest',
        ariaDate: 'Event order is date.',
        ariaAscending: 'Event order is ascending.',
        ariaDescending: 'Event order is desscending.',
        cardsImg: 'Cards Img',
        tableImg: 'Table Img',
        dashImg: 'Dash Img',
        organizerSelections: 'Organizer Selections',
        ariaOrganizerSelections: `Use the organizer selection toggle to select personal and or your account's institutional affiliation to populate the organizer event list.`,
        by: 'by',
        cards: 'Quick Cards',
        table: 'Table Overview',
        dash: 'Dashboard',
        selectAll: 'Select All',
        unselectAll: 'Unselect All',
        ariaOrganizersFilter: 'Organizers filter.',
        overview: 'Overview',
        activeEvents: 'Active Events',
        scheduledEvents: 'Scheduled Events',
        archivedEvents: 'Archived Events',
        activeImg: 'Active Img',
        scheduledImg: 'Scheduled Img',
        archivedImg: 'Archived Img',
        tableOverviewUnavailable:
          'Table overview is unavaible on this screen size.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        organizerOverview: `Aperçu de l'organisateur`,
        organizerSelection: 'Sélection des organisateurs',
        createEvents: 'Créer des événements',
        findSpeakers: 'Trouver des conférenciers',
        createMultidayEvents: 'Créer des événements sur plusieurs jours',
        descending: 'Ordre décroissant',
        ascending: 'Ordre croissant',
        date: 'Date',
        distance: 'Distance',
        views: 'Vues',

        viewsAndSorting: 'Vues & Tri',
        sort: 'Trier',
        earliest: 'Le plus tôt',
        latest: 'Le plus tard',
        furthest: 'Le plus éloigné',
        closest: 'Le plus proche',
        ariaDate: `L'ordre de l'événement est la date.`,
        ariaAscending: `L'ordre des événements est croissant.`,
        ariaDescending: `L'ordre des événements est décroissant.`,
        cardsImg: 'Cartes Img',
        tableImg: 'Tableau Img',
        dashImg: 'Dash Img',
        organizerSelections: 'Sélections des organisateurs',
        ariaOrganizerSelections: `Utilisez le bouton de sélection de l'organisateur pour sélectionner l'affiliation personnelle et/ou institutionnelle de votre compte afin de remplir la liste des événements de l'organisateur.`,
        by: 'par',
        cards: 'Cartes rapides',
        table: 'Aperçu du tableau',
        dash: 'Tableau de bord',
        selectAll: 'Tout sélectionner',
        unselectAll: 'Tout déselectionner',
        ariaOrganizersFilter: 'filtre des organisateurs.',
        overview: 'Aperçu',
        activeEvents: 'Événements actifs',
        scheduledEvents: 'Événements planifiés',
        archivedEvents: 'Événements Archivés',
        activeImg: 'Actifs Img',
        scheduledImg: 'Planifiés Img',
        archivedImg: 'Archivés Img',
        tableOverviewUnavailable: `L'aperçu du tableau n'est pas disponible sur cette taille d'écran.`,
      };
    }

    return translation;
  };

  const SettingsOrganizeViewsSummaryMobile = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        manage: 'Manage',
        create: 'Create',
        findSpeakers: 'Find Speakers',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        manage: 'Gérer',
        create: 'Créer',
        findSpeakers: 'Trouver des conférenciers',
      };
    }

    return translation;
  };

  const SettingsEventsViewsSummaryMobile = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        myEvents: 'My Events',
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',
        community: 'Community',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        myEvents: 'Mes Événements',
        bigTags: 'Grandes Balises',
        tinyTags: 'Petit Balises',
        community: 'Communauté',
      };
    }

    return translation;
  };

  return {
    SettingsEventsLanguage: SettingsEventsLanguage(),
    SettingsOrganizeLanguage: SettingsOrganizeLanguage(),
    SettingsOrganizeViewsSummaryMobile: SettingsOrganizeViewsSummaryMobile(),
    SettingsEventsViewsSummaryMobile: SettingsEventsViewsSummaryMobile(),
  };
};

export default useLanguageSettings;

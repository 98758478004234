import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useAccountCommunityHandlers } from '../../useAccountCommunityHandlers';

//language
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import Selections from '../../../../../components/Selections/Selections';
import CommunityListAccountPersonal from './CommunityListAccountPersonal';
import SortingComponent from '../../../../../components/SortingComponent/SortingComponent';
import WordSearchInput from '../../../../../components/WordSearchInput/WordSearchInput';

const CommunityAccounts = () => {
  //hooks
  const {
    accountSpeakerSpeakerModeActive,
    accountOrganizerOrganizerModeActive,
    accountAttendeeModeActive,
    //community lists
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
    accountCommunityBlockedAccounts,
    accountCommunityContacts,
    accountCommunityContactsRequested,
    accountCommunityFollowers,
  } = useAccountReduxHandlers();
  const { handleGetAccountCommunityLists } = useAccountCommunityHandlers();

  //language
  const { Account } = useLanguageAccount();
  const { WordSearchInput: WordSearchInputLanguage } = useLanguageComponents();
  const { CommunityViewOpts, CommunityAccountSortingOpts } = useLanguageData();

  //state
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortingType, setSortingType] = useState('firstName');
  const [viewCommunitySelection, setViewCommunitySelection] = useState();
  const [communityOpts, setCommunityOpts] = useState({});
  const [communityAccounts, setCommunityAccounts] = useState();

  const [filteredSearchList, setFilteredSearchList] = useState([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    let newCommunityOpts = {};

    newCommunityOpts.blockedAccounts = `${CommunityViewOpts.blockedAccounts} (${
      accountCommunityBlockedAccounts?.length || 0
    })`;

    if (
      accountSpeakerSpeakerModeActive ||
      accountOrganizerOrganizerModeActive
    ) {
      let contactsTotal =
        accountCommunityContacts?.length +
        accountCommunityContactsRequested?.length;

      newCommunityOpts.contacts = `${CommunityViewOpts.contacts} (${
        contactsTotal || 0
      })`;
    }

    if (
      accountSpeakerSpeakerModeActive ||
      accountOrganizerOrganizerModeActive
    ) {
      newCommunityOpts.followers = `${CommunityViewOpts.followers} (${
        accountCommunityFollowers?.length || 0
      })`;
    }

    if (accountAttendeeModeActive) {
      let followingTotal = accountCommunityFollowing?.length;

      accountCommunityFollowingGroups?.map((group) => {
        const add = group?.following?.length || 0;
        followingTotal = followingTotal + add;
      });

      newCommunityOpts.following = `${CommunityViewOpts.following} (${
        followingTotal || 0
      })`;
    }

    setCommunityOpts(newCommunityOpts);
    /*eslint-disable-next-line*/
  }, [
    accountCommunityBlockedAccounts,
    accountCommunityContacts,
    accountCommunityContactsRequested,
    accountCommunityFollowers,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  ]); //sets community selections list

  useEffect(() => {
    //following
    let newFollowingArr = [];

    if (accountCommunityFollowing?.length > 0) {
      newFollowingArr = [...accountCommunityFollowing];
    }
    if (accountCommunityFollowingGroups?.length > 0) {
      accountCommunityFollowingGroups?.map((group) => {
        group?.following?.map((followingAcct) =>
          newFollowingArr.push(followingAcct)
        );
      });
    }

    //followers
    let newFollowersArr = [];

    if (accountCommunityFollowers?.length > 0) {
      newFollowersArr = [...accountCommunityFollowers];
    }

    //contacts + contacts requested
    let newContactsArr = [];

    if (accountCommunityContacts?.length > 0) {
      newContactsArr = [...accountCommunityContacts];
    }

    if (accountCommunityContactsRequested?.length > 0) {
      newContactsArr = [
        ...newContactsArr,
        ...accountCommunityContactsRequested,
      ];
    }

    //blocked
    let newBlockedArr = [];
    if (accountCommunityBlockedAccounts?.length > 0) {
      newBlockedArr = [...accountCommunityBlockedAccounts];
    }

    setCommunityAccounts({
      following: newFollowingArr,
      followers: newFollowersArr,
      contacts: newContactsArr,
      blocked: newBlockedArr,
    });
    /*eslint-disable-next-line*/
  }, [
    accountCommunityBlockedAccounts,
    accountCommunityContacts,
    accountCommunityContactsRequested,
    accountCommunityFollowers,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  ]);

  useEffect(() => {
    if (!sortingType || !sortOrder) return;

    const sortArray = (arr) => {
      return arr.slice().sort((a, b) => {
        const nameA = a.personal?.[sortingType]?.toLowerCase() || '';
        const nameB = b.personal?.[sortingType]?.toLowerCase() || '';

        if (sortOrder === 'asc') {
          return nameA.localeCompare(nameB);
        } else {
          return nameB.localeCompare(nameA);
        }
      });
    };

    setCommunityAccounts((prev) => ({
      following: sortArray(prev.following),
      followers: sortArray(prev.followers),
      contacts: sortArray(prev.contacts),
      blocked: sortArray(prev.blocked),
    }));
  }, [sortOrder, sortingType]);

  useEffect(() => {
    if (!searchText) {
      setFilteredSearchList([]);
      return;
    }

    const filterList = (list) => {
      return list.filter(
        (acct) =>
          acct.personal?.firstName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          acct.personal?.lastName
            ?.toLowerCase()
            .includes(searchText.toLowerCase())
      );
    };

    setFilteredSearchList(
      filterList(communityAccounts[viewCommunitySelection] || [])
    );
  }, [searchText, communityAccounts, viewCommunitySelection]);

  return (
    <div className="section">
      <p
        className="banners__notice align-center full-width fs16 mrg-tb24"
        tabIndex="0"
      >
        {Account.community.followersNotPublic}
      </p>

      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="communityAccountsLabel"
      >
        {Account.community.communityAccounts}
      </label>
      <div className="h24" />

      <Selections
        id={'viewCommunitySelections'}
        options={communityOpts}
        handleSelection={setViewCommunitySelection}
        customWrapper={'mrg-t12'}
        viewHorizontal={true}
        viewDoubleColumnPhone={true}
      />

      {viewCommunitySelection && (
        <>
          <SortingComponent
            sortOrder={sortOrder}
            sortingType={sortingType}
            setSortOrder={setSortOrder}
            setSortingType={setSortingType}
            sortingOpts={CommunityAccountSortingOpts}
            dropdDownId={'communityAccountSorting'}
          />
          <WordSearchInput
            placeHolder={WordSearchInputLanguage.searchByName}
            ariaLabel={WordSearchInputLanguage.ariaLabelSearchCommunityAccounts}
            setInputText={setSearchText}
            id={'wordSearchInput'}
          />
        </>
      )}

      {!searchText ? (
        <>
          {viewCommunitySelection === 'contacts' ? (
            <ul className="community__container mrg-b36">
              {communityAccounts?.contacts?.map((acct) => {
                return (
                  <li key={`communityContact-${acct?._id}`}>
                    <CommunityListAccountPersonal
                      profile={acct}
                      customProfileInteractionHandler={
                        handleGetAccountCommunityLists
                      }
                    />
                  </li>
                );
              })}
            </ul>
          ) : viewCommunitySelection === 'followers' ? (
            <ul className="community__container mrg-b36">
              {communityAccounts?.followers?.map((acct) => {
                return (
                  <li key={`communityFollowers-${acct?._id}`}>
                    <CommunityListAccountPersonal
                      profile={acct}
                      customProfileInteractionHandler={
                        handleGetAccountCommunityLists
                      }
                    />
                  </li>
                );
              })}
            </ul>
          ) : viewCommunitySelection === 'following' ? (
            <ul className="community__container mrg-b36">
              {communityAccounts?.following?.map((acct) => {
                return (
                  <li key={`communityFollowing-${acct?._id}`}>
                    <CommunityListAccountPersonal
                      profile={acct}
                      customProfileInteractionHandler={
                        handleGetAccountCommunityLists
                      }
                    />
                  </li>
                );
              })}
            </ul>
          ) : (
            viewCommunitySelection === 'blockedAccounts' && (
              <ul className="community__container mrg-b36">
                {communityAccounts?.blocked?.map((acct) => {
                  return (
                    <li key={`communityBlocked-${acct?._id}`}>
                      <CommunityListAccountPersonal
                        profile={acct}
                        customProfileInteractionHandler={
                          handleGetAccountCommunityLists
                        }
                      />
                    </li>
                  );
                })}
              </ul>
            )
          )}
        </>
      ) : (
        <ul className="community__container mrg-b36">
          {filteredSearchList?.map((acct) => {
            return (
              <li key={`communityBlocked-${acct?._id}`}>
                <CommunityListAccountPersonal
                  profile={acct}
                  customProfileInteractionHandler={
                    handleGetAccountCommunityLists
                  }
                />
              </li>
            );
          })}
        </ul>
      )}

      <div className="h48" />
    </div>
  );
};

export default CommunityAccounts;

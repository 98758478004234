import { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import throttle from 'lodash/throttle';
import MyndFullPatternCol from './MyndFullPatternCol';

const MyndFullPattern = ({ patternActive }) => {
  const [grid, setGrid] = useState({ columns: 0, rows: 0, totalHeight: 0 });
  const gridRef = useRef(grid);
  const observerRef = useRef(null);

  const [initialized, setInitialized] = useState(false);
  const initializationTimer = useRef();

  useEffect(() => {
    initializationTimer.current = setTimeout(() => {
      setInitialized(true);
    }, 500);

    return () => {
      clearTimeout(initializationTimer.current);
    };
  }, []);

  const updateGrid = useCallback(
    throttle(() => {
      const screenWidth = window.innerWidth;
      const isMobile = window.innerWidth < 768;

      const screenHeight = isMobile
        ? window.innerHeight
        : document.documentElement.scrollHeight;

      const colWidth = isMobile ? 60 : 80;
      const rowHeight = 400;

      const bufferRows = isMobile ? 4 : 3;
      const newGrid = {
        columns: Math.floor(screenWidth / colWidth),
        rows: Math.ceil(screenHeight / rowHeight) + bufferRows,
        totalHeight: screenHeight + rowHeight * bufferRows, // Extend total height
      };

      if (JSON.stringify(gridRef.current) !== JSON.stringify(newGrid)) {
        gridRef.current = newGrid;
        setGrid(newGrid);
      }
    }, 300),
    []
  );

  useEffect(() => {
    updateGrid();
    window.addEventListener('resize', updateGrid);

    // Use ResizeObserver to track height changes dynamically
    observerRef.current = new ResizeObserver(() => {
      updateGrid();
    });

    observerRef.current.observe(document.documentElement);

    return () => {
      window.removeEventListener('resize', updateGrid);
      if (observerRef.current) observerRef.current.disconnect();
    };
  }, [updateGrid]);

  const columnVariations = useMemo(
    () =>
      Array.from({ length: grid.columns }, () => {
        const isMobile = window.innerWidth < 768;
        return {
          yOffset: isMobile
            ? Math.random() * 100 - 50
            : Math.random() * 200 - 100, // Reduce movement range on mobile
          speed: isMobile ? 10 + Math.random() * 5 : 7 + Math.random() * 7, // Adjust animation speed
        };
      }),
    [grid.columns]
  );

  return (
    <div
      className={`mynd-pattern ${initialized ? '' : 'opacity-0'}`}
      style={{
        width: `${grid.columns * 80}px`,
        height: `${grid.totalHeight}px`, // Dynamically match content height
        overflow: 'visible', // Ensure icons don’t get clipped
      }}
    >
      {Array.from({ length: grid.columns }).map((_, colIndex) => {
        const { yOffset, speed } = columnVariations[colIndex] || {
          yOffset: 0,
          speed: 10,
        };

        return (
          <div
            key={colIndex}
            className={`mynd-pattern-column ${
              patternActive
                ? colIndex % 2 === 0
                  ? 'mynd-pattern-column--loop-up'
                  : 'mynd-pattern-column--loop-down'
                : ''
            }`}
            style={{
              height: `${grid.totalHeight}px`,
              transform: `translateY(${yOffset}px)`,
              animationDuration: `${speed}s`,
            }}
          >
            <div className="mynd-pattern-content">
              {Array.from({ length: grid.rows * 2 }).map((_, rowIndex) => (
                <MyndFullPatternCol key={`${colIndex}-${rowIndex}`} />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MyndFullPattern;

import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//hooks
import { useSettings } from '../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//components
import ProfilePersonalInfo from './sections/ProfilePersonalInfo';
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';
import ProfileBio from './sections/ProfileBio';
import ProfileSpeakerInfo from './sections/ProfileSpeakerInfo';
import ProfileContact from './sections/ProfileContact';
import ProfileLocation from './sections/ProfileLocation';
import ProfileSocials from './sections/ProfileSocials';
import ProfileInteractions from './sections/ProfileInteractions';

//utils
import ScrollTo from '../../utils/UI/ScrollTo';
import { return_curved_arrow } from '../../assets/icons';
import ProfilePersonalSkeleton from './ProfilePersonalSkeleton';

const ProfilePersonalBase = ({
  customWrapper,
  handleCloseModal,
  previewMode,
  profileData,
  reopenNotices,
  scrollToOn,
  bordersOn,
  customExitHandler,
  customProfileInteractionHandler,
  interactionsOff,
}) => {
  //hooks
  const {
    accountId,
    accountOrganizerOrganizerModeActive,
    accountCommunityContacts,
  } = useAccountReduxHandlers();
  const { setOpenMobileNotificationsMenu, handleMobileTap, isMobile, width } =
    useSettings();

  //language
  const { Generic, ProfilePersonal, IconAlts } = useLanguageComponents();

  //state
  const [profile, setProfile] = useState();
  const [speakerLocation, setSpeakerLocation] = useState();
  const [isViewerAccountHolder, setIsViewerAccountHolder] = useState(false);
  const [isContact, setIsContact] = useState(false);
  const [profileKey, setProfileKey] = useState();

  //ui
  const [mobileTapReturn, setMobileTapReturn] = useState(false);
  const [hasSocialLink, setHasSocialLink] = useState(false);

  //initialize
  const [initialized, setInitialized] = useState(false);
  const [bannerImgLoaded, setBannerImgLoaded] = useState(false);
  const [profileImgLoaded, setProfileImgLoaded] = useState();

  const prevProfileId = useRef();

  //initialize
  useEffect(() => {
    if (profileData?._id && profileData?._id !== prevProfileId.current) {
      prevProfileId.current = profileData?._id;
      setInitialized(false);
      setBannerImgLoaded(false);
      setProfileImgLoaded(false);
      setProfileKey(uuidv4);
    }
  }, [profileData]);

  useEffect(() => {
    if (!initialized && bannerImgLoaded && profileImgLoaded) {
      const initializationTimer = setTimeout(() => {
        setInitialized(true);
      }, 1000);

      return () => clearTimeout(initializationTimer);
    }
  }, [initialized, bannerImgLoaded, profileImgLoaded]);

  useEffect(() => {
    if (profileData && accountId) {
      setProfile(profileData);

      if (profileData?.speaker) {
        getSpeakerLocation();
      }

      if (profileData?._id === accountId) {
        setIsViewerAccountHolder(true);
      }

      if (profileData?.personal?.links?.length > 0) {
        const links = Object.values(profileData?.personal?.links);
        for (const link of links) {
          if (link?.length > 0) {
            setHasSocialLink(true);
            break;
          }
        }
      }
    } else {
      setProfile();
      setProfileKey();
    }
  }, [accountId, profileData]); //set profile, speaker location, current user

  useEffect(() => {
    if (profile?._id && accountCommunityContacts) {
      let contactIds = [];
      accountCommunityContacts?.map((acct) => contactIds.push(acct?._id));

      if (contactIds?.includes(profile?._id)) {
        setIsContact(true);
      } else {
        setIsContact(false);
      }
    } else {
      setIsContact(false);
    }
  }, [accountCommunityContacts, profile]);

  //UI
  useEffect(() => {
    if (scrollToOn) {
      ScrollTo({ scrollToTop: true });
    }
  }, [scrollToOn]);

  //functions
  async function getSpeakerLocation() {
    if (
      !profileData?.speaker?.speakerGeoPosition?.coordinates[0] ||
      !profileData?.speaker?.speakerGeoPosition?.coordinates[1]
    ) {
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    let latLng = {};

    if (previewMode) {
      latLng = {
        lat: profileData?.speaker?.speakerGeoPosition?.coordinates[0],
        lng: profileData?.speaker?.speakerGeoPosition?.coordinates[1],
      };
    } else {
      latLng = {
        lat: profileData?.speaker?.speakerGeoPosition?.coordinates[1],
        lng: profileData?.speaker?.speakerGeoPosition?.coordinates[0],
      };
    }

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results.length > 0) {
        const addressComponents = results[0]?.address_components;

        let city = '';
        let country = '';

        addressComponents.forEach((component) => {
          if (component.types.includes('locality')) {
            city = component?.long_name;
          }
          if (component.types.includes('country')) {
            country = component?.long_name;
          }
        });

        const loc = `${city}, ${country}`;
        setSpeakerLocation(loc);
      } else {
        if (process.env.REACT_APP_ENV === 'development') {
          console.error('geolocation error.');
        }
      }
    });
  }

  function handleReturn() {
    if (handleCloseModal) {
      handleCloseModal();
    }
    if (reopenNotices) {
      setOpenMobileNotificationsMenu(true);
    } //keep here because button is necessary to click for mobile
    if (customExitHandler) {
      customExitHandler();
    }
  }

  return (
    <>
      {profile && (
        <div key={profileKey} className={!initialized ? 'remove' : 'pos-rel'}>
          <LoadBucketImage
            containerClassName={`account-profile-banner`}
            imgClassName={`account-profile-banner--image`}
            backupPath={'banner_img_default.png'}
            partialPath={'myndfull_banner_images'}
            imagePath={profile?.personal?.bannerImage}
            altAriaText={profile?.personal.bannerImageAlt}
            setImageLoadConfirmation={setBannerImgLoaded}
          />

          <div
            className={`account-profile__wrapper pos-rel mrg-t0--ph mrg-b48--ph ${
              customWrapper ? customWrapper : ''
            } ${bordersOn ? 'account-profile__wrapper--borders' : ''} `}
          >
            {(width <= 480 || isMobile) && (
              <button
                className={`account-profile-return-btn highlight-i-white ${
                  mobileTapReturn ? 'click-scaledown-98' : ''
                }`}
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setMobileTapReturn(true),
                      () => setMobileTapReturn(false),
                    ],
                    [() => handleReturn()]
                  )
                }
                aria-label={Generic.return}
              >
                <img
                  src={return_curved_arrow}
                  alt={IconAlts.iconArrow}
                  className="filter-white"
                />
              </button>
            )}

            <div className={`account-profile-content`}>
              <ProfilePersonalInfo
                profile={profile}
                customWrapper={customWrapper}
                ProfilePersonal={ProfilePersonal}
                setProfileImgLoaded={setProfileImgLoaded}
              />

              {!interactionsOff && !isViewerAccountHolder && (
                <ProfileInteractions
                  profile={profile}
                  isViewerAccountHolder={isViewerAccountHolder}
                  previewMode={previewMode}
                  customProfileInteractionHandler={
                    customProfileInteractionHandler
                  }
                />
              )}
              {isViewerAccountHolder && (
                <p
                  className={`flex-center fs18 italic  ${
                    previewMode ? 'mrg-b36' : 'mrg-b12'
                  }`}
                >
                  {ProfilePersonal.isAccountHolder}
                </p>
              )}

              {profile?.speaker?.speakerModeActive &&
                speakerLocation &&
                (accountOrganizerOrganizerModeActive || previewMode) && (
                  <ProfileLocation
                    profile={profile}
                    previewMode={previewMode}
                    speakerLocation={speakerLocation}
                    ProfilePersonal={ProfilePersonal}
                  />
                )}

              {isContact &&
                profile?.speaker?.speakerModeActive &&
                (accountOrganizerOrganizerModeActive || previewMode) && (
                  <ProfileContact
                    profile={profile}
                    previewMode={previewMode}
                    ProfilePersonal={ProfilePersonal}
                  />
                )}
              {hasSocialLink && (
                <ProfileSocials
                  profile={profile}
                  ProfilePersonal={ProfilePersonal}
                />
              )}

              {profile?.personal?.bio?.length > 0 && (
                <ProfileBio
                  profile={profile}
                  ProfilePersonal={ProfilePersonal}
                />
              )}

              {profile?.speaker?.speakerModeActive &&
                (accountOrganizerOrganizerModeActive || previewMode) && (
                  <ProfileSpeakerInfo
                    profile={profile}
                    previewMode={previewMode}
                    ProfilePersonal={ProfilePersonal}
                  />
                )}
            </div>
          </div>
        </div>
      )}

      {!initialized && <ProfilePersonalSkeleton spinnerAnimationOn={true} />}
    </>
  );
};

export default ProfilePersonalBase;

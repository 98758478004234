import { useEffect, useRef, useState } from 'react';

//Hooks
import { useSettings } from '../../../context/SettingsProvider';
import { useAccountReduxHandlers } from './useAccountHandlers';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useApiStatus } from '../../../context/ApiStatusProvider';
import { useAuthReduxHandlers } from '../auth/useAuthHandlers';

//language
import useLanguageAccount from '../../../language/features/useLanguageAccount';

//api
import {
  useLazyMyInvoicesQuery,
  useUpdateAccountMutation,
  useUpdateRolesMutation,
} from './accountApiSlice';

//Components
import EventPage from '../events/EventPage/EventPage';
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';
import GeneralContent from './Content/GeneralContent/GeneralContent';
import AccountNav from './AccountMenus/AccountNav';
import AccountNavMobile from './AccountMenus/AccountNavMobile';
import AccountContent from './Content/AccountPersonalContent/AccountContent';
import AttendeeContent from './Content/AttendeeContent/AttendeeContent';
import SpeakerContent from './Content/SpeakerContent/SpeakerContent';
import OrganizerContent from './Content/OrganizerContent/OrganizerContent';
import TranslationRoomsContent from './Content/TranslationsRooms/TranslationRoomsContent';
import HelpContent from './Content/HelpContent/HelpContent';
import BillingContent from './Content/BillingContent/BillingContent';
import EmailPreferencesContent from './Content/EmailPreferences/EmailPreferencesContent';
import CommunityContent from './Content/CommunityContent/CommunityContent';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';
import { retryWrapper } from '../../../utils/logic/retryFns';
import { areArraysEqualIgnoreOrder } from '../../../utils/logic/arrayEqualIgnoreOrder';

const Account = () => {
  //Hooks
  const { handleError } = useApiStatus();
  const {
    setNavbar,
    width,
    settingsViewEventDetails,
    setSettingsSetViewEventDetails,
  } = useSettings();
  const {
    accountId,
    handleUpdateCustomerStripeData,
    accountBillingCustomerId,
    accountIgnoredEvents,
    accountArchivedEvents,
  } = useAccountReduxHandlers();
  const { authCurrentRoles: loadRoles } = useAuthReduxHandlers();
  const { settingsAccountSettingsView: view } = useSettingsHandlers();

  //language
  const { Account } = useLanguageAccount();

  //API
  const [
    updateAccount,
    {
      isLoading: updateAccountIsLoading,
      isFetching: updateAccountIsFetching,
      isSuccess: updateAccountIsSuccess,
    },
  ] = useUpdateAccountMutation();
  const [getInvoices] = useLazyMyInvoicesQuery();
  const [updateRoles] = useUpdateRolesMutation();

  //state
  const [roles, setRoles] = useState(loadRoles);

  //UI state
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [loadingSaveBtnUI, setLoadingSaveBtnUI] = useState('');
  const [rolesChangeValid, setRolesChangeValid] = useState(false);

  //roles UI for sign ups
  const [rolesSignUpIsLoading, setRolesSignUpIsLoading] = useState(false);
  const [speakerSignUpIsLoading, setSpeakerSignUpIsLoading] = useState(false);

  //variables
  const signUpCompleteRolesAuthRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    setNavbar('account');
    /*eslint-disable-next-line*/
  }, []);

  //Stripe functions initialize
  useEffect(() => {
    if (accountId && accountBillingCustomerId) {
      retryWrapper(getInvoices, [{ accountId }], 3, 2000);
    }
  }, [accountId]); //get invoices

  //UI
  useEffect(() => {
    ScrollTo({ scrollToTop: true });
  }, [view]); //auto scroll to top

  //validation
  useEffect(() => {
    if (roles && loadRoles) {
      const rolesUnchanged = areArraysEqualIgnoreOrder(roles, loadRoles);
      if (rolesUnchanged) {
        setRolesChangeValid(false);
      } else {
        setRolesChangeValid(true);
      }
    }
  }, [roles, loadRoles]);

  useEffect(() => {
    if (speakerSignUpIsLoading && rolesChangeValid) {
      if (signUpCompleteRolesAuthRan.current === false) {
        signUpCompleteRolesAuthRan.current = true;
        setSpeakerSignUpIsLoading(true);
      }
    }
  }, [speakerSignUpIsLoading, rolesChangeValid, loadRoles]); //required because of special sign up flow for speaker. used to render loading screen

  //API functions
  async function saveAccountChange(newData, updateCategory) {
    if (!updateAccountIsLoading && !updateAccountIsFetching) {
      try {
        setLoadingSaveBtnUI(updateCategory);
        const updateCustomerStripeData = await handleUpdateCustomerStripeData(
          newData,
          updateCategory
        );
        if (!updateCustomerStripeData?.error) {
          let successMessage = true;
          if (updateCategory === 'attendeeSignUp') successMessage = false;

          // console.log(newData);
          await updateAccount({
            accountId,
            newData,
            successMessage,
            updateCategory,
          });
          //note: speaker sign up is handled in speaker meta
          if (updateCategory === 'attendeeSignUp') {
            setRolesSignUpIsLoading(true);

            await updateRoles({
              accountId,
              newData: {
                attendeeRoleChange: true,
              },
            });
            ScrollTo({ focusId: 'attendeeActivationLabel', scrollToTop: true });

            const timerId = setTimeout(() => {
              setRolesSignUpIsLoading(false);
              setLoadingSaveBtnUI();
            }, 1000); //render auth changes

            return () => clearTimeout(timerId);
          }
          setLoadingSaveBtnUI();
        }
      } catch (error) {
        setRolesSignUpIsLoading(false);
        setLoadingSaveBtnUI();
        handleError({
          error,
          id: Date.now(),
          origin: 'Account.js/saveAccountChange',
        });
      }
    }
  }

  //functions
  function handleReturnFromEventPage() {
    let scrollToSection;
    let focusId = settingsViewEventDetails?._id;

    if (accountIgnoredEvents.includes(settingsViewEventDetails?._id)) {
      scrollToSection = 'ignoredEventsLabel';
    } else if (accountArchivedEvents.includes(settingsViewEventDetails?._id)) {
      scrollToSection = 'archivedEventsLabel';
    }
    setSettingsSetViewEventDetails();

    const timer = setTimeout(() => {
      ScrollTo({
        focusId,
        scrollToElementId: scrollToSection,
      });
    }, 150);

    return () => clearTimeout(timer);
  }

  return (
    <>
      {eventPageLoadComplete && !settingsViewEventDetails ? (
        <div className="account" id="account">
          {width > 1024 ? <AccountNav /> : <AccountNavMobile />}
          {view === 'general' && (
            <GeneralContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'account' && (
            <AccountContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'attendee' && (
            <AttendeeContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
              updateAccountIsSuccess={updateAccountIsSuccess}
              rolesSignUpIsLoading={rolesSignUpIsLoading}
            />
          )}
          {view === 'billing' && (
            <BillingContent saveAccountChange={saveAccountChange} />
          )}
          {view === 'community' && <CommunityContent />}

          {view === 'emailPreferences' && (
            <EmailPreferencesContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'speaker' && (
            <SpeakerContent
              setSpeakerSignUpIsLoading={setSpeakerSignUpIsLoading}
              speakerSignUpIsLoading={speakerSignUpIsLoading}
            />
          )}
          {view === 'organizer' && (
            <OrganizerContent
              saveAccountChange={saveAccountChange}
              loadingSaveBtnUI={loadingSaveBtnUI}
            />
          )}
          {view === 'translationRooms' && <TranslationRoomsContent />}
          {view === 'help' && <HelpContent />}
        </div>
      ) : settingsViewEventDetails ? (
        <div className="events">
          <EventPage handleCustomReturn={handleReturnFromEventPage} />
        </div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={3}
          setLoadComplete={() => setEventPageLoadComplete(true)}
          loadCheck1={accountId ? true : false}
          loadCheck2={width ? true : false}
          loadCheck3={Account ? true : false}
          customContainer={'min-full-height'}
        />
      )}
    </>
  );
};

export default Account;

import { ca, us, mx } from '../../assets/icons/flags/index';

const GetFlag = (country) => {
  let flagIcon;

  if (country === 'canada' || country === 'CA') {
    flagIcon = <img src={ca} alt="flag" className="flag" />;
  }
  if (country === 'unitedStates' || country === 'US') {
    flagIcon = <img src={us} alt="flag" className="flag" />;
  }
  if (country === 'mexico' || country === 'MX') {
    flagIcon = <img src={mx} alt="flag" className="flag" />;
  }
  return flagIcon;
};

export default GetFlag;

import { useSettings } from '../../context/SettingsProvider';

const useLanguageTranslationRoom = () => {
  const { contentLanguage } = useSettings();

  const TranslationInitialize = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        joinRoom: 'Join Room',
        hostLogin: 'Host',
        trial: 'Trial',
        join: 'Join',
        tutorial: 'Tutorial',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        joinRoom: 'Rejoindre la salle',
        hostLogin: `Hôte`,
        trial: 'Procès',
        join: 'Rejoindre',
        tutorial: 'Tutoriel',
      };
    }

    return translation;
  };

  const TranslationJoinRoom = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        roomCode: 'Room Code',
        usePrevious: 'Use Previous:',
        joinRoom: 'Join Room',
        submit: 'Submit',
        back: 'Back',
        exit: 'Exit',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        roomCode: 'Code de la salle',
        usePrevious: 'Utiliser le précédent:',
        joinRoom: 'Rejoindre la salle',
        submit: 'Soumettre',
        back: 'Retour',
        exit: 'Sortie',
      };
    }

    return translation;
  };

  const TranslationCreateRoomAuth = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        enterRoomCode: 'Enter Room Code',
        usePrevious: 'Use Previous:',
        exit: 'Exit',
        roomCode: 'Room Code',
        generate: 'Generate',
        back: 'Back',
        password: 'Password',
        submit: 'Submit',
        hostLogin: 'Host Log In',
        noRoomCode: `Don't have a Room Code?`,
        chromeIsRequired: 'Chrome is required for hosting',
        mobileDevicesNotPermittedToHost:
          'Mobile devices can log in but cannot host rooms.',
        outstandingBalance: `This Translation Room is locked. More details are available in the account holder's Room Code Manager.`,
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        enterRoomCode: 'Entrez le code de la salle',
        usePrevious: 'Utiliser le précédent:',
        exit: 'Sortie',
        roomCode: 'Code de la salle',
        generate: 'Générer',
        back: 'Retour',
        password: 'Mot de passe',
        submit: 'Soumettre',
        hostLogin: `Connexion de l'hôte`,

        noRoomCode: `Vous n'avez pas de code de la salle ?`,
        chromeIsRequired: `Chrome est requis pour l'hébergement`,
        mobileDevicesNotPermittedToHost: `Les appareils mobiles peuvent se connecter mais ne peuvent pas héberger de salles.`,
        outstandingBalance: `Cette salle de traduction est verrouillée. Plus de détails sont disponibles dans le gestionnaire de codes de salle du titulaire du compte.`,
      };
    }

    return translation;
  };

  const CreateRoomSpeakersAutocorrect = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        exit: 'Exit',
        autoCorrectKeywords: 'Autocorrect Keywords',
        submit: 'Submit',
        back: 'Back',
        discussionContext: 'Discussion Context',
        aiAutocorrectionDescription:
          'Tell the AI about your discussion for accurate autocorrections',
        autocorrectionForSpeakers: 'Autocorrections for Speakers',
        previous: 'Previous',
        checkmark: 'checkmark',
        speaker: 'Speaker',
        createRoom: 'Create Room',
        minimumCharacterRequirements: '(Minimum 25 characters required)',
        mobileDevicesNotPermittedToHost: 'Mobile devices cannot host rooms.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        discussionContext: `Contexte des discussions`,
        exit: 'Sortie',
        submit: 'Soumettre',
        autoCorrectKeywords: 'Mots clés à correction automatique',
        back: 'Retour',
        aiAutocorrectionDescription: `Informez l'IA de votre discussion pour des corrections automatiques précises.`,
        autocorrectionForSpeakers: 'Corrections automatiques pour les orateurs',
        next: 'Suivant',
        previous: 'Précédent',
        checkmark: 'coche',
        speaker: 'Intervenant',
        createRoom: 'Créer une salle',
        minimumCharacterRequirements: '( Minimum 25 caractères requis )',
        mobileDevicesNotPermittedToHost: `Les appareils mobiles ne peuvent pas héberger de salles.`,
      };
    }

    return translation;
  };

  const TranslationRoom = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        exit: 'Exit',
        enter: 'Enter',
        roomCode: 'Room Code',
        speakerLanguage: 'Speaker Language',
        translationLanguage: 'Translation Language',
        yourLanguage: 'Your Language',
        attendance: 'Attendance',
        hostIsReady: 'Host is Ready',
        waitingForHost: 'Waiting for Host',
        voiceSelection: 'Voice Selection',
        testVoice: 'Test Voice',
        textLanguage: 'Text Language',
        activateSpeechRecognition: 'Activate Speech Recognition',
        expandToFullscreen: 'Expand to Full Screen',
        pressF11ForFullScreen: 'Press F11 for full screen',
        expandedMode: 'Expanded Mode',
        report: 'Report',
        ariaVolumeToggle: 'Volume Toggle.',
        ariaExpandTextAreaToFullScreen: 'Expand text area to full screen.',
        ariaSpeechRecognitionActive: 'Speech recognition is active.',
        ariaSpeechRecognitionInactive: 'Speech recognition is not active.',
        selectLanguage: 'Select a translation language.',
        quickGuide: 'Quick Guide',
        beginListening: 'Begin Listening',
        currentSpeaker: 'Current Speaker',
        speaker: 'Speaker',
        speakersLanguage: `Speaker's Language`,
        noActiveSpeaker: 'No Active Speaker',
        downloadTranscript: 'Download Transcript',
        ariaLightModeActive: 'Light Mode is active.',
        ariaDarkModeActive: 'Dark Mode is active.',
        lightMode: 'Light Mode',
        darkMode: 'Dark Mode',
        openSpeakerDetails: 'Open Speaker Details',
        noScreenTimeout: 'No Screen Timeout',
        preventScreenTimeout: 'Click to prevent screen timeout',
        screenTimeoutIsPaused: 'Screen timeout is paused',
        saveTranscript: 'Save Transcript (Encrypted)',
        toggleVolume: 'Toggle Volume',

        checkmark: 'Checkmark',
        microphonePermission: 'Microphone Permission',
        grantAccess: 'Grant Access',
        granted: 'Granted',
        denied: 'Denied',
        active: 'active',
        inactive: 'inactive',
        logout: 'Logout',

        decreaseFontSize: 'Decrease Font Size',
        increaseFontSize: 'Increase Font Size',
        revealRoomPassword: 'Reveal Room Password',
        hideRoomPassword: 'Hide Room Password',
        yes: 'Yes',
        cancel: 'Cancel',
        revealPasswordModal: 'Reveal the room password on the popout display?',
        password: 'Password',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        exit: 'Sortie',
        enter: 'Entrer',
        roomCode: 'Code de la salle',
        speakerLanguage: `Langue de l'intervenant`,
        translationLanguage: 'Langue de traduction',
        yourLanguage: 'Votre langue',

        attendance: 'Présence',
        hostIsReady: `L'hôte est prêt`,
        waitingForHost: `En attente d'un hôte`,
        voiceSelection: 'Sélection vocale',
        testVoice: 'Tester la voix',
        textLanguage: 'Langue du texte',
        activateSpeechRecognition: 'Activer la reconnaissance vocale',
        expandToFullscreen: 'Développer en plein écran',
        pressF11ForFullScreen: 'Appuyez sur F11 pour un plein écran',
        expandedMode: 'Mode étendu',
        report: 'Signaler',
        ariaVolumeToggle: 'Basculement du volume.',
        ariaExpandTextAreaToFullScreen:
          'Agrandissez la zone de texte en plein écran.',
        ariaSpeechRecognitionActive: 'La reconnaissance vocale est active.',
        ariaSpeechRecognitionInactive: `La reconnaissance vocale n'est pas active.`,
        selectLanguage: 'Sélectionnez une langue de traduction.',
        quickGuide: 'Guide rapide',
        grantAudioPlayback: `Accorder l'autorisation de lecture audio (obligatoire pour Safari)`,
        toggleVolume: 'Basculer le volume',
        beginListening: 'Commencez à écouter',
        currentSpeaker: 'Actuel intervenant',
        speaker: 'Intervenant',
        speakersLanguage: `Langue du l'intervenant`,
        noActiveSpeaker: 'Aucun intervenant actif',
        downloadTranscript: 'Télécharger la transcription',
        ariaLightModeActive: 'Le mode lumière est actif.',
        ariaDarkModeActive: 'Le mode sombre est actif.',
        lightMode: 'Mode lumière',
        darkMode: 'Mode sombre',
        openSpeakerDetails: `Ouvrir les détails de l'Intervenant`,
        noScreenTimeout: `Pas de mise en veille de l'écran`,
        checkmark: 'Coche',
        microphonePermission: 'Autorisation du microphone',
        grantAccess: `Accorder l'accès`,
        granted: 'accordé',
        denied: 'refusé',
        active: 'activé',
        inactive: 'désactivé',
        logout: 'Déconnexion',
        increaseFontSize: 'Augmenter la taille de la police',
        decreaseFontSize: 'Diminuer la taille de la police',
        revealRoomPassword: 'Révéler le mot de passe de la salle',
        hideRoomPassword: 'Masquer le mot de passe de la salle',
        saveTranscript: 'Enregistrer la transcription (Crypté)',
        preventScreenTimeout: `Cliquez pour empêcher la mise en veille de l'écran`,
        screenTimeoutIsPaused: `La mise en veille de l'écran est en pause`,
        yes: 'Oui',
        cancel: 'Annuler',
        revealPasswordModal: `Révéler le mot de passe de la salle sur l'écran contextuel ?`,
        password: 'Mot de passe',
      };
    }

    return translation;
  };

  const TranslationRoomGuide = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        translationRoomGuide: 'Translations Room Quick Guide',
        technicalChecklist: 'Technical Checklist',
        supportedBrowsers: 'Your internet browser has Speech Recognition.',
        microphoneAccess: `MyndFull has permission to use the browser's microphone.`,
        basics: 'Basics',
        roomCode: 'Room Code',
        roomCode2: 'is used by attendees to connect to the Translation Room.',
        attendance: 'Attendance',
        attendance2: `tracks the number of live connections to the Translation Room. The
        host counts as 1.`,
        attendees: 'Attendees',
        attendeeSelectTranslation: `select a Translation Language. If it does not match the host's Speech Language, the attendee will receive an audio and text translation.`,
        translationText1: 'Translation Text',
        translationText2: `sets the language of the text in the host's text box. This text can be used to display to an audience. It is also used to populate the downloadable transcript.`,
        tips: 'Tips',
        tips1: 'Keep the microphone within range at all times.',
        tips2:
          'Pause briefly at the end of sentences to allow the audio to transfer.',
        tips3:
          'Very long sentences have a higher chance of causing translation errors.',
        tips4: 'Ensure minimal background noise.',
        tips5:
          'Avoid words that were not added as Keywords and could be considered difficult to translate (ex. slang and abbreviations).',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        translationRoomGuide: `Guide rapide de la salle de traduction`,
        technicalChecklist: `Liste de contrôle technique`,
        supportedBrowsers: `Votre navigateur Internet dispose de la reconnaissance vocale.`,
        microphoneAccess: `MyndFull a la permission d'utiliser le microphone du navigateur.`,
        basics: 'Les bases',
        roomCode: 'Code de la salle',
        roomCode2: `est utilisé par les participants pour se connecter à la salle de traduction.`,
        attendance: 'Présence',
        attendance2: `suit le nombre de connexions en direct à la salle de traduction. L'hôte compte pour 1.`,
        attendees: 'Les participants',
        attendeeSelectTranslation: `sélectionnez une langue de traduction. Si la langue parlée ne correspond pas à celle de l'hôte, le participant recevra une traduction audio et textuelle.`,
        translationText1: 'Texte de traduction',
        translationText2: `définit la langue du texte dans la zone de texte de l'hôte. Ce texte peut être utilisé pour être affiché à un public. Il est également utilisé pour remplir la transcription téléchargeable.`,
        tips: 'Conseils',

        tips1: 'Gardez le microphone à portée à tout moment.',
        tips2: `Faites une brève pause à la fin des phrases pour permettre le transfert de l'audio.`,
        tips3: `Les phrases très longues ont plus de chances de provoquer des erreurs de traduction.`,
        tips4: 'Garantit un bruit de fond minimal.',
        tips5: `Évitez les mots qui n’ont pas été ajoutés comme mots-clés et qui pourraient être considérés comme difficiles à traduire (par exemple, l’argot et les abréviations).`,
      };
    }

    return translation;
  };

  const TranslationRoomCreateRoomSpeakers = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        continue: 'Continue',
        back: 'Back',
        selectParticipants: 'Select Participants',
        participant: 'Participant',
        name: 'Name',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        continue: 'Continuer',
        back: 'Retour',
        selectSpeakers: 'Sélectionner les participants',
        participant: 'Participant',
        name: 'Nom',
      };
    }

    return translation;
  };

  const TranslationRoomCreateRoomLanguages = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        selectRoomLanguages: 'Select Room Languages',
        imageByPCH: 'Image by pch.vector on Freepik',
        roomLanguages: 'Room Languages',
        continue: 'Continue',
        manual: 'Manual',
        allAvailableLanguages: 'All Languages',
        back: 'Back',
        addLanguages: 'Add Languages',
        twoLanguagesRequired: 'At least two languages are required.',
        general: 'General',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        selectRoomLanguages: 'Sélectionner les langues de la salle',
        imageByPCH: 'Image par pch.vector sur Freepik',
        roomLanguages: 'Langues des salles',
        continue: 'Continuer',
        manual: 'Manuel',
        back: 'Retour',
        allAvailableLanguages: 'Toutes les langues',
        addLanguages: 'Ajouter des langues',
        twoLanguagesRequired: 'Au moins deux langues sont requises.',
        general: 'Informations générales',
      };
    }

    return translation;
  };

  const CreateRoomSpeakersVoiceAndLanguage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        exit: 'Exit',
        continue: 'Continue',
        back: 'Back',
        voiceSelection: 'Voice Selection',
        testVoice: 'Test Voice',
        participantsLanguage: `Participant's Language`,
        playing: 'Playing . . .',
        speaker: 'Speaker',
        incompatibleSavedLanguageWarning:
          'The saved language for this speaker is no longer compatible with the selected room languages. Please return to the room languages to include this language or choose a different language for the speaker.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        exit: 'Sortie',
        continue: 'Continuer',
        back: 'Retour',
        voiceSelection: 'Sélection vocale',
        testVoice: 'Tester la voix',
        participantsLanguage: `Langue du participant`,
        playing: `L'audio joue . . .`,
        speaker: 'Orateur',
        incompatibleSavedLanguageWarning: `La langue enregistrée pour cet intervenant n'est plus compatible avec les langues sélectionnées dans la salle. Veuillez revenir aux langues de la salle pour inclure cette langue ou choisir une autre langue pour l'orateur.`,
      };
    }

    return translation;
  };

  const CreateRoomInitializeRoomData = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        imageByPCH: 'Image by pch.vector on Freepik',
        exit: 'Exit',
        restorePreviousSession: 'Restore Previous Session',
        ignore: 'Ignore',
        restore: 'Restore',
        previousSessionLastSavedAt: 'Previous session last saved at',
        mainMenu: 'Main Menu',
        newSession: 'New Session',
        load: 'Load Session',
        sessionDrafts: 'Session Drafts',
        logout: 'Logout',
        transcripts: 'Transcripts',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        imageByPCH: 'Image par pch.vector sur Freepik',
        exit: 'Sortie',
        restorePreviousSession: 'Restaurer la session précédente',
        ignore: 'Ignorer',
        restore: 'Restaurer',
        previousSessionLastSavedAt:
          'Session précédente enregistrée pour la dernière fois à',
        mainMenu: 'Menu principal',
        load: 'Charger la session',
        newSession: 'Nouvelle session',
        sessionDrafts: 'Brouillons de session',
        logout: 'Déconnexion',
        transcripts: 'Transcriptions',
      };
    }

    return translation;
  };

  const TranslationsCreateRoomGeneralSettings = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        saveName: 'Save Name',
        continue: 'Continue',
        back: 'Back',
        generalSettings: 'General Settings',
        saveTranscript: 'Save Transcript (Encrypted)',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        saveName: 'Enregistrer le nom',
        continue: 'Continuer',
        back: 'Retour',
        generalSettings: 'Paramètres généraux',
        saveTranscript: 'Enregistrer la transcription (Crypté)',
      };
    }

    return translation;
  };

  const TranslationCreateRoomPassword = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        passwordOptions: 'Password Options',
        attendeesRequirePassword: 'Attendees Require Password',
        password: 'Password',
        exit: 'Exit',
        continue: 'Continue',
        back: 'Back',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        passwordOptions: 'Options de mot de passe',
        attendeesRequirePassword: `Les participants ont besoin d'un mot de passe`,
        password: 'Mot de passe',
        exit: 'Sortie',
        continue: 'Continuer',
        back: 'Retour',
      };
    }

    return translation;
  };

  const TranslationDrafts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        sessionDrafts: 'Session Drafts',
        newDraft: 'New Draft',
        loadDraft: 'Load Draft',
        back: 'Back',
        attendeesRequirePassword: 'Attendees Require Password',
        password: 'Password',
        draftSaveName: 'Draft Save Name',
        attendeePassword: 'Attendee Password',
        createManually: 'Create Manually',
        emailInvite: 'Email Invite',
        saveAndExit: 'Save & Exit',
        removeDraft: 'Remove Draft',
        removeDrafts: 'Remove Drafts',
        removeSelectedDrafts: 'Remove Selected Drafts',
        noSavedDrafts: 'No Saved Drafts',

        //dispatches
        dispatches: 'Dispatches',
        dispatchesDescription:
          'Select checkboxes to dispatch translation data requests to the corresponding participant. Dispatches will be sent during the next draft save.',
        noDispatchesAvailable: 'No dispatches available.',
        completedParticipants: 'Completed Participants',
        participantInvite: 'Participant Invite',
        translationRequest: 'Translation Request',
        status: 'Status',
        emailInvitations: 'Email Invitations',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        sessionDrafts: 'Brouillons de session',
        newDraft: 'Nouveau brouillon',
        loadDraft: 'Charger le brouillon',
        back: 'Retour',
        attendeesRequirePassword: `Les participants ont besoin d'un mot de passe`,
        password: 'Mot de passe',
        draftSaveName: 'Brouillon Enregistrer le nom',
        attendeePassword: 'Mot de passe du participant',
        createManually: 'Créer manuellement',
        emailInvite: 'Invitation par e-mail',
        saveAndExit: 'Enregistrer & quitter',
        removeDraft: 'Supprimer le brouillon',
        removeDrafts: 'Supprimer les brouillons',
        removeSelectedDrafts: 'Supprimer les brouillons sélectionnés',
        noSavedDrafts: 'Aucun brouillon enregistré',

        //dispatches
        dispatches: 'Dépêches',
        dispatchesDescription: `Cochez les cases pour envoyer les demandes de données de traduction au participant correspondant. Les envois seront effectués lors de la prochaine sauvegarde du brouillon.`,
        noDispatchesAvailable: `Aucune dépêche n'est disponible.`,
        completedParticipants: 'Participants terminés',
        participantInvite: 'Invitation des participants',
        translationRequest: 'Demande de traduction',
        status: 'Statut',
        emailInvitations: 'Invitations',
      };
    }

    return translation;
  };

  const TranslationTranscripts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        transcripts: 'Transcripts',
        openTranscripts: 'Open Transcripts',
        removeTranscripts: 'Remove Transcripts',
        removeSelectedTranscripts: 'Remove Selected Transcripts',
        back: 'Back',
        created: 'Created',
        lastUpdated: 'Last Updated',
        participants: 'Participants',
        participant: 'Participant',
        selectTranscript: 'Select Transcript',
        noTranscriptsSaved: 'No Saved Transcripts',
        speakersData: 'Speakers Data',
        language: 'Language',
        keywordsList: 'Keywords List',
        discussionContext: 'Discussion Context',
        transcript: 'Transcript',
        transcriptEmpty: 'Transcript Empty',
        transcriptCreated: 'Transcript Created',
        lastTranscriptUpdate: 'Last Transcript Update',
        totalTranscriptLines: 'Total Transcript Lines',
        //
        transcriptReview: 'Transcript Review',
        transcriptReviewGeneralInstructions1: `For information on the review process, please click`,
        transcriptReviewGeneralInstructions2: 'here',
        transcriptReviewGeneralInstructionsAriaLink:
          'Click here to visit our page on transcript reviews.',
        flaggedTranscriptLines: 'Flagged Transcript Lines',
        percentageOfIdentifiedProblems: 'Percentage of Identified Problems',
        submitReview: 'Submit Review',
        eventDetails: 'Event Details',
        eventDetailsDescription: `Please provide any relevant event details that may have impacted the performance of the translation tool.`,
        eventDetailsDescriptionHint: ` The event's technical set up (ex. hosting device, microphone device, etc.), environmental conditions (ex. background noise), event preparations (ex. whether the host and participants consulted guidelines), and whether any participants had distinctive speech characteristics (ex. dialects) can be included.`,
        originalText: 'Original Text',
        translations: 'Translations',
        noTranslationRequired: 'No Translation Required',
        flag: 'Flag',
        problem: 'Problem',
        saveName: 'Save Name',
        noKeywords: 'No Keywords',
        voice: 'Voice',
        //
        inSessionUpdates: 'In-Session Updates',
        changedFrom: 'Changed from',
        changedFromDiscussionContext: 'Changed from discussion context',
        removedKeywords: 'Removed Keywords',
        addedKeywords: 'Added Keywords',
        participantInSessionUpdate: 'Participant In-Session Update',

        //download
        myndfullTranscript: 'MyndFull Transcript',
        sessionId: 'Session Id',
        downloadTranscript: 'Download Transcript',
        downloadTranscriptBasic: 'Download Transcript ( Basic )',
        downloadTranscriptFull: 'Download Transcript ( Full )',
        transcriptBasic: 'Transcript ( Basic )',
        transcriptFull: 'Transcript ( Full )',
        goToBottom: 'Go to Bottom',
        updatedAt: 'Updated',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        transcripts: 'Transcriptions',
        openTranscripts: 'Transcriptions ouvertes',
        removeTranscripts: 'Supprimer les transcriptions',
        removeSelectedTranscripts: 'Supprimer les transcriptions sélectionnées',
        back: 'Retour',
        created: 'Créé',
        lastUpdated: 'Dernière mise à jour',
        participants: 'Participants',
        participant: 'Participant',
        selectTranscript: 'Sélectionner la transcription',
        noTranscriptsSaved: 'Aucune transcription enregistrée',
        speakersData: 'Données des orateurs',
        language: 'Langue',
        keywordsList: 'Liste de mots-clés',
        discussionContext: 'Contexte de la discussion',
        transcript: 'Transcription',
        transcriptEmpty: 'Transcription vide',
        transcriptCreated: 'Transcript créé',
        lastTranscriptUpdate: 'Dernière mise à jour de la transcription',
        totalTranscriptLines: 'Nombre total de lignes de transcription',
        //
        transcriptReview: 'Révision de la transcription',
        transcriptReviewGeneralInstructions1: `Pour plus d'informations sur le processus de révision, veuillez cliquer`,
        transcriptReviewGeneralInstructions2: 'ici',
        transcriptReviewGeneralInstructionsAriaLink:
          'Cliquez ici pour visiter notre page sur les révisions de relevés de notes.',
        flaggedTranscriptLines: 'Lignes de transcription signalées',
        percentageOfIdentifiedProblems: 'Pourcentage de problèmes identifiés',
        submitReview: 'Soumettre la révision',
        eventDetails: "Détails de l'événement",
        eventDetailsDescription: `Veuillez fournir tous les détails pertinents de l'événement qui pourraient avoir eu un impact sur la performance de l'outil de traduction.`,
        eventDetailsDescriptionHint: `Cela peut inclure la configuration technique de l'événement (ex. appareil hôte, dispositif de microphone, etc.), les conditions environnementales (ex. bruit de fond), les préparations de l'événement (ex. si l'hôte et les participants ont consulté les directives), et si certains participants avaient des caractéristiques de parole distinctives (ex. dialectes).`,
        originalText: 'Texte original',
        translations: 'Traductions',
        noTranslationRequired: 'Aucune traduction requise',
        flag: 'Signaler',
        problem: 'Problème',
        saveName: 'Enregistrer le nom',
        noKeywords: 'Aucun mot clé',
        goToBottom: 'Aller en bas',
        updatedAt: 'Mis à jour',
        voice: 'Voix',
        inSessionUpdates: 'Mises à jour en session',
        changedFrom: 'Anciennement',
        changedFromDiscussionContext: 'Changé du contexte de discussion',
        removedKeywords: 'Mots-clés supprimés',
        addedKeywords: 'Mots-clés ajoutés',
        participantInSessionUpdate:
          'Mise à jour en session pour le participant',

        //download
        myndfullTranscript: 'MyndFull Transcription',
        sessionId: 'ID de session',
        downloadTranscript: 'Télécharger la transcription',
        downloadTranscriptBasic: 'Télécharger la transcription ( basique )',
        downloadTranscriptFull: 'Télécharger la transcription ( complète )',
        transcriptBasic: 'Transcription ( basique )',
        transcriptFull: 'Transcription ( complète )',
      };
    }

    return translation;
  };

  return {
    CreateRoomInitializeRoomData: CreateRoomInitializeRoomData(),
    CreateRoomSpeakersAutocorrect: CreateRoomSpeakersAutocorrect(),
    CreateRoomSpeakersVoiceAndLanguage: CreateRoomSpeakersVoiceAndLanguage(),
    TranslationCreateRoomAuth: TranslationCreateRoomAuth(),
    TranslationCreateRoomPassword: TranslationCreateRoomPassword(),
    TranslationsCreateRoomGeneralSettings:
      TranslationsCreateRoomGeneralSettings(),
    TranslationDrafts: TranslationDrafts(),
    TranslationInitialize: TranslationInitialize(),
    TranslationJoinRoom: TranslationJoinRoom(),
    TranslationRoom: TranslationRoom(),
    TranslationRoomCreateRoomLanguages: TranslationRoomCreateRoomLanguages(),
    TranslationRoomCreateRoomSpeakers: TranslationRoomCreateRoomSpeakers(),
    TranslationRoomGuide: TranslationRoomGuide(),
    TranslationTranscripts: TranslationTranscripts(),
  };
};

export default useLanguageTranslationRoom;

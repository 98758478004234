import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import {
  useSaveSpeakerSignUpMutation,
  useUpdateRolesMutation,
} from '../../../accountApiSlice';
import { useSettings } from '../../../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';

//components
import ButtonMeta from '../../../../../../components/ButtonMeta/ButtonMeta';
import ConfirmSelection from '../../../../../../components/ConfirmSelection/ConfirmSelection';
import ValidationsList from '../../../../../../components/ValidationsList/ValidationsList';

//utils
import {
  save,
  trash,
  return_curved_arrow,
  checkmark,
} from '../../../../../../assets/icons';
import { getCountryISO } from '../../../../../../utils/spatial/getCountryISO';

const SpeakerMeta = ({ setResetSpeaker, setSpeakerSignUpIsLoading }) => {
  //hooks
  const {
    speakerSignUpValidation,
    accountSpeakerLastModified,
    handleAccountSpeakerReset,
    accountSpeakerGeoPositionCoordinates,
    accountId,
    accountSpeaker,
  } = useAccountReduxHandlers();
  const { handleMobileTap } = useSettings();

  //language
  const { SpeakerMeta } = useLanguageAccount();
  const { IconAlts, Generic } = useLanguageComponents();

  //Api
  const [saveSpeakerSignUp, { isLoading: saveSpeakerSignUpIsLoading }] =
    useSaveSpeakerSignUpMutation();
  const [updateRoles] = useUpdateRolesMutation();

  //state
  const [selectionBtn, setSelectionBtn] = useState();
  const [validations, setValidations] = useState();
  const [verifiedSuccessful, setVerifiedSuccessful] = useState(false);
  const [verifiedTimestamp, setVerifiedTimestamp] = useState();

  //UI
  const [publishMetaBtnIsLoading, setPublishMetaBtnIsLoading] = useState(false);
  const [tapHighlightMobileReturnBtn, setTapHighlightMobileReturnBtn] =
    useState(false);

  //initialize
  useEffect(() => {
    if (accountSpeakerLastModified > verifiedTimestamp || !verifiedTimestamp) {
      setVerifiedSuccessful(false);
      setSelectionBtn();
    }
  }, [accountSpeakerLastModified, verifiedTimestamp]); //if createEvent state in redux is updated, this will invalidate the users ability to publish event

  function handleResetSpeaker() {
    handleAccountSpeakerReset();
    handleResetDraft();
    setResetSpeaker(true);
    setSelectionBtn();
  }

  async function handleSaveDraft() {
    if (!saveSpeakerSignUpIsLoading) {
      setSelectionBtn();
      await saveSpeakerSignUp({ accountId, speakerData: accountSpeaker });
    }
  }

  async function handleResetDraft() {
    if (!saveSpeakerSignUpIsLoading) {
      setSelectionBtn();
      await saveSpeakerSignUp({ accountId, speakerData: {} });
    }
  }

  async function finalizeSpeakerSignUp() {
    if (!verifiedSuccessful || accountSpeakerLastModified > verifiedTimestamp) {
      return;
    } else if (!saveSpeakerSignUpIsLoading) {
      setSelectionBtn();
      setPublishMetaBtnIsLoading(true);
      setSpeakerSignUpIsLoading(true);
      const countryISO = await getCountryISO(
        accountSpeakerGeoPositionCoordinates
      );
      try {
        const response = await saveSpeakerSignUp({
          accountId,
          speakerData: {
            ...accountSpeaker,
            speakerCountryISO: countryISO,
          },
          signUpComplete: true,
        });

        if (response?.error) {
          throw new Error();
        }

        await updateRoles({
          accountId,
          newData: {
            speakerRoleChange: true,
          },
        });
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('finalizeSpeakerSignUp error,', error);
        }
      }
    }
    setPublishMetaBtnIsLoading(false);
  }

  function handleGetPublishValidations() {
    const speakerDataOrValidationsArray = speakerSignUpValidation();

    if (Array.isArray(speakerDataOrValidationsArray)) {
      setValidations(speakerDataOrValidationsArray);
      setVerifiedSuccessful(false);
    } else {
      setVerifiedTimestamp(Date.now());
      setVerifiedSuccessful(true);
      setValidations();
    }
  }

  return (
    <div className="flex-column mrg-t12 mrg-b36">
      {!selectionBtn && (
        <div
          className={`button-meta__container space-between access-ob access-o6 ${
            selectionBtn ? 'mrg-b24' : ''
          }`}
          id="speakerMetaBtns"
          tabIndex="0"
        >
          <ButtonMeta
            handleClick={() => {
              if (selectionBtn === 'restart') {
                setSelectionBtn('');
              } else {
                setSelectionBtn('restart');
              }
            }}
            btnText={SpeakerMeta.restart}
            ariaLabel={SpeakerMeta.ariaRestart}
            activeStateDependency={selectionBtn === 'restart'}
            icon={trash}
            availabilityToggle={true}
            unavailable={publishMetaBtnIsLoading}
            iconAlt={IconAlts.iconTrash}
          />
          <ButtonMeta
            handleClick={() => {
              if (selectionBtn === 'saveDraft') {
                setSelectionBtn('');
              } else {
                setSelectionBtn('saveDraft');
              }
            }}
            btnText={SpeakerMeta.saveDraft}
            ariaLabel={SpeakerMeta.ariaSaveDraft}
            activeStateDependency={selectionBtn === 'saveDraft'}
            icon={save}
            iconAlt={IconAlts.iconSave}
            availabilityToggle={true}
            unavailable={publishMetaBtnIsLoading}
          />

          <ButtonMeta
            handleClick={() => {
              if (selectionBtn === 'publish') {
                setSelectionBtn();
              } else {
                setSelectionBtn('publish');
                handleGetPublishValidations();
              }
            }}
            btnText={SpeakerMeta.complete}
            ariaLabel={SpeakerMeta.complete}
            activeStateDependency={selectionBtn === 'publish'}
            icon={checkmark}
            iconAlt={IconAlts.iconArrow}
            isLoading={publishMetaBtnIsLoading}
          />
        </div>
      )}

      <>
        {selectionBtn === 'restart' && (
          <>
            <ConfirmSelection
              text2={SpeakerMeta.removeAllSpeakerSignUpData}
              btn1Text={SpeakerMeta.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={SpeakerMeta.restart}
              btn2Color={'neg'}
              btn2Handler={handleResetSpeaker}
              ignoreMaxWidth={true}
              btnDescriptionTextVertical={true}
            />
          </>
        )}
        {selectionBtn === 'saveDraft' && (
          <ConfirmSelection
            text2={SpeakerMeta.confirmSave}
            btnDescriptionTextVertical={true}
            btn1Text={SpeakerMeta.cancel}
            btn1Color={'neutral'}
            btn1Handler={() => setSelectionBtn('')}
            btn2Text={SpeakerMeta.save}
            btn2Color={'pos'}
            btn2Handler={() => handleSaveDraft()}
            ignoreMaxWidth={true}
            isLoading={saveSpeakerSignUpIsLoading}
          />
        )}

        {selectionBtn === 'publish' && validations && (
          <>
            <button
              className={`close-btn highlight-i-lgt access-ob mrg-b24 ${
                tapHighlightMobileReturnBtn ? 'highlight-i-lgt--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightMobileReturnBtn(true),
                    () => setTapHighlightMobileReturnBtn(false),
                  ],
                  [() => setSelectionBtn()]
                )
              }
              type="button"
              title={Generic.return}
            >
              <img
                src={return_curved_arrow}
                alt={IconAlts.iconReturn}
                className="svg"
                style={{ maxHeight: '48px' }}
              />
            </button>

            <ValidationsList validations={validations} />
          </>
        )}

        {selectionBtn === 'publish' && verifiedSuccessful && (
          <ConfirmSelection
            text2={SpeakerMeta.confirmCompletion}
            btnDescriptionTextVertical={true}
            btn1Text={SpeakerMeta.cancel}
            btn1Color={'neutral'}
            btn1Handler={() => setSelectionBtn('')}
            btn2Text={SpeakerMeta.confirm}
            btn2Color={'pos'}
            btn2Handler={finalizeSpeakerSignUp}
            ignoreMaxWidth={true}
            isLoading={saveSpeakerSignUpIsLoading}
          />
        )}
      </>
    </div>
  );
};

export default SpeakerMeta;

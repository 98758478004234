import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import TextArea from '../../../../../components/TextArea/TextArea';
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import ParticipantActionBtns from './ParticipantActionBtns';

const ParticipantCreated = ({
  prevParticipant,
  handleSaveAndReturn,
  handleRemoveParticipant,
  //image
  pauseCrop,
  participantImage,
  handleParticipantImageChange,
}) => {
  //hooks
  const { width } = useSettings();

  //language
  const { ParticipantCreated } = useLanguageOrganize();
  const { Generic } = useLanguageComponents();

  //ui
  const [participant, setParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const setParticipantEffectRan = useRef(false);

  // //initialize
  useEffect(() => {
    if (prevParticipant) {
      const initializeTimer = setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return () => clearTimeout(initializeTimer);
    }
  }, [prevParticipant]); //required to prevent image crop from being overriden

  useEffect(() => {
    if (setParticipantEffectRan.current === false && prevParticipant?.id) {
      setParticipantEffectRan.current = true;
      setParticipant(prevParticipant);
    }
  }, [prevParticipant]);

  //functions

  function handleCompletionCheck(participantObj) {
    if (participantObj?.organizedAs === 'create') {
      if (
        participantObj?.participantData?.personal?.firstName?.length > 0 &&
        participantObj?.participantData?.personal?.lastName?.length > 0
      ) {
        return true;
      } else {
        return false;
      }
    }
  } //used in required fields

  function handleEventRoleChange(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
      },
    };
    newParticipantData.participantData.eventRole = val;

    const isComplete = handleCompletionCheck(newParticipantData);
    if (isComplete) {
      newParticipantData.completionStatus = 'complete';
    } else {
      newParticipantData.completionStatus = 'incomplete';
    }
    setParticipant(newParticipantData);
  }

  function handlePrefix(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
        personal: { ...participant.participantData.personal },
      },
    };
    newParticipantData.participantData.personal.prefix = val;
    setParticipant(newParticipantData);
  }

  function handlePronouns(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
        personal: { ...participant.participantData.personal },
      },
    };
    newParticipantData.participantData.personal.pronouns = val;
    setParticipant(newParticipantData);
  }

  function handleFirstName(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
        personal: { ...participant.participantData.personal },
      },
      translationsSpeakerData: {
        ...participant.translationsSpeakerData,
      },
    };
    newParticipantData.participantData.personal.firstName = val;
    newParticipantData.translationsSpeakerData.name = `${val} ${newParticipantData.participantData.personal.lastName}`;

    const isComplete = handleCompletionCheck(newParticipantData);
    if (isComplete) {
      newParticipantData.completionStatus = 'complete';
    } else {
      newParticipantData.completionStatus = 'incomplete';
    }

    setParticipant(newParticipantData);
  }

  function handleLastName(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
        personal: { ...participant.participantData.personal },
      },
      translationsSpeakerData: {
        ...participant.translationsSpeakerData,
      },
    };
    newParticipantData.participantData.personal.lastName = val;
    newParticipantData.translationsSpeakerData.name = `${newParticipantData.participantData.personal.firstName} ${val}`;

    const isComplete = handleCompletionCheck(newParticipantData);
    if (isComplete) {
      newParticipantData.completionStatus = 'complete';
    } else {
      newParticipantData.completionStatus = 'incomplete';
    }
    setParticipant(newParticipantData);
  }

  function handleBio(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
        personal: { ...participant.participantData.personal },
      },
    };
    newParticipantData.participantData.personal.bio = val;
    const isComplete = handleCompletionCheck(newParticipantData);
    if (isComplete) {
      newParticipantData.completionStatus = 'complete';
    } else {
      newParticipantData.completionStatus = 'incomplete';
    }
    setParticipant(newParticipantData);
  }

  function handleImageChange(e) {
    if (!isLoading) {
      let newParticipantImage = {
        ...participantImage,
        crop: { ...participantImage.crop },
      };
      newParticipantImage.croppedImage = e.croppedImage;
      newParticipantImage.imagePreview = e.imagePreview;
      newParticipantImage.crop = e.crop;
      newParticipantImage.zoom = e.zoom;
      handleParticipantImageChange(newParticipantImage);

      //if cropped image empty, need to restore participant to default account image.
      if (!newParticipantImage?.croppedImage?.croppedImage) {
        let newParticipantData = {
          ...participant,
          participantData: {
            ...participant.participantData,
            personal: {
              ...participant.participantData.personal,
              profileImage: 'account_img_default.png',
            },
          },
        };

        setParticipant(newParticipantData);
      }
    }
  }

  function handleImageAltChange(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
        personal: {
          ...participant.participantData.personal,
          profileImageAlt: val,
        },
      },
    };

    setParticipant(newParticipantData);
  }

  return (
    <>
      <ParticipantActionBtns
        participant={participant}
        handleSaveAndReturn={handleSaveAndReturn}
        handleRemoveParticipant={handleRemoveParticipant}
      />
      <div
        className={`participant-base__wrapper mrg-t36 border-reg br--standard pad-24 bg-color-light`}
      >
        <div className="participant-base__container full-width space-between align-center">
          <div className="flex-column mrg-r48 mrg-l24 mrg-r0--ph mrg-l0--ph mrg-auto-bottom">
            <ImageCropper
              ariaImageDescription={ParticipantCreated.profilePicture}
              cover={'horizontal-cover'}
              handleImage={handleImageChange}
              height={264}
              id={`participant-${participantImage?.id}`}
              imagePreview={participantImage?.imagePreview}
              pauseCrop={isLoading || pauseCrop}
              profileFrame={true}
              width={264}
              customFileUploaderWrapper={'file-uploader__wrapper--profile'}
              customFileUploaderContainer={'file-uploader__container--profile'}
              ignoreRemoveImageOnDismount={true}
              saveCroppedPos={true}
              loadCroppedPos={
                participantImage
                  ? {
                      crop: participantImage.crop,
                      zoom: participantImage.zoom,
                    }
                  : null
              }
            />
            <TextArea
              handleChange={handleImageAltChange}
              limit={200}
              id={'imageAltText'}
              labelText={Generic.imageAlt}
              disableEnter={true}
              loadTrigger={true}
              customWrapper={'br--none mrg-t24'}
              loadValue={participant.participantData?.personal?.profileImageAlt}
              onBlurHandlerOff={true}
              hideWordCount={true}
            />
          </div>

          <div className="full-width mrg-t24--ph">
            <TextArea
              disableEnter={true}
              handleChange={handleEventRoleChange}
              hideWordCount={true}
              id={'participantEventRole'}
              labelText={ParticipantCreated.eventRole}
              limit={50}
              loadTrigger={true}
              loadValue={participant?.participantData?.eventRole}
              placeHolder={ParticipantCreated.eventRolePlaceholder}
              onBlurHandlerOff={true}
            />
            <div className="flex-row flex-column--ph mrg-t24">
              <TextArea
                customWidth={'108px'}
                disableEnter={true}
                hideWordCount={true}
                id={'participantPrefix'}
                labelText={ParticipantCreated.prefix}
                limit={8}
                loadTrigger={true}
                customWrapper={width >= 480 ? 'mrg-r24 mrg-b24' : 'mrg-b24'}
                loadValue={participant?.participantData?.personal?.prefix}
                handleChange={handlePrefix}
                onBlurHandlerOff={true}
              />
              <TextArea
                customWidth={'100%'}
                customWrapper={'mrg-b24'}
                disableEnter={true}
                hideWordCount={true}
                id={'participantPronouns'}
                labelText={ParticipantCreated.pronouns}
                limit={20}
                loadTrigger={true}
                handleChange={handlePronouns}
                loadValue={participant?.participantData?.personal?.pronouns}
                onBlurHandlerOff={true}
              />
            </div>

            <TextArea
              asterisk={true}
              disableEnter={true}
              hideWordCount={true}
              id={'participantFirstName'}
              labelText={ParticipantCreated.firstName}
              limit={50}
              loadTrigger={true}
              handleChange={handleFirstName}
              loadValue={participant?.participantData?.personal?.firstName}
              onBlurHandlerOff={true}
            />
            <TextArea
              asterisk={true}
              disableEnter={true}
              hideWordCount={true}
              id={'participantLastName'}
              labelText={ParticipantCreated.lastName}
              limit={50}
              loadTrigger={true}
              handleChange={handleLastName}
              loadValue={participant?.participantData?.personal?.lastName}
              onBlurHandlerOff={true}
              customWrapper={'mrg-t24'}
            />
          </div>
        </div>
        <TextArea
          handleChange={handleBio}
          limit={3000}
          id={'participantPersonalBio'}
          medium={true}
          loadTrigger={true}
          loadValue={participant?.participantData?.personal?.bio}
          labelText={ParticipantCreated.personalBio}
          disableEnter={false}
          customWrapper={'mrg-t24'}
        />
      </div>
    </>
  );
};

export default ParticipantCreated;

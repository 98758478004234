import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//language
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//utils
import { getRandomColor } from '../../../../../utils/UI/randomColorCodes';

const TranslationRoomsUsage = ({ translationsBillingData }) => {
  //hooks
  const {
    accountTranslationRoomsRoomCodes,
    accountBillingRoomCodeSubscriptions,
  } = useAccountReduxHandlers();

  //language
  const { BillingRoomCodeSubscriptions } = useLanguageAccount();
  const { MonthsFromNumber } = useLanguageData();

  //state
  const [usageData, setUsageData] = useState([]);
  const [selectedRoomCodeObj, setSelectedRoomCodeObj] = useState();

  const [selectableYears, setSelectableYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState();

  const [selectableMonths, setSelectableMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();

  const [selectableDays, setSelectableDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState();

  const [selectedSessions, setSelectedSessions] = useState([]);

  //ui
  const [handleColors, setHandleColors] = useState([]);

  //initialize
  useEffect(() => {
    let newUsageData = [];

    if (translationsBillingData?.length > 0) {
      newUsageData = [...translationsBillingData];
    }

    if (accountTranslationRoomsRoomCodes?.length > 0) {
      accountTranslationRoomsRoomCodes?.forEach((subscription) => {
        const exists = newUsageData.some(
          (data) => data.roomCode === subscription.roomCode
        );

        if (!exists) {
          newUsageData.push({ roomCode: subscription.roomCode });
        }
      });
    }

    setUsageData(newUsageData);
  }, [translationsBillingData, accountTranslationRoomsRoomCodes]);

  //ui function
  function handleSelectRoomCode(newSelectedRoomCode) {
    setSelectedYear();
    setSelectedMonth();
    setSelectedDay();
    setSelectedSessions([]);

    if (newSelectedRoomCode === selectedRoomCodeObj?.roomCode) {
      setSelectedRoomCodeObj('');
      setSelectableYears([]);
      setSelectableMonths([]);
      setSelectableDays([]);
    } else {
      let newBillingObj;
      newBillingObj = translationsBillingData?.find(
        (billingObj) => billingObj.roomCode === newSelectedRoomCode
      );
      if (!newBillingObj) {
        newBillingObj = { roomCode: newSelectedRoomCode };
        setSelectableYears([]);
        setSelectableMonths([]);
        setSelectableDays([]);
      } else {
        let yearSelections = [];

        newBillingObj?.sessions?.map((s) => {
          const sessionDate = s.createdAt.split('T')[0];
          const sessionYear = sessionDate.split('-')[0];

          if (!yearSelections?.includes(sessionYear)) {
            yearSelections.push(sessionYear);
          }
        });

        setSelectableYears(yearSelections);
        setSelectableMonths([]);
        setSelectableDays([]);
      }
      setSelectedRoomCodeObj(newBillingObj);
    }
  }

  function handleSelectYear(y) {
    setSelectedSessions([]);
    if (y === selectedYear) {
      setSelectedYear();
      setSelectedMonth();
      setSelectedDay();

      setSelectableMonths([]);
      setSelectableDays([]);
    } else {
      let newMonths = [];

      selectedRoomCodeObj?.sessions?.map((s) => {
        if (s?.createdAt.startsWith(y)) {
          const date = s.createdAt.split('T')[0];
          const sessionMonth = date.split('-')[1];
          if (!newMonths.includes(sessionMonth)) {
            newMonths.push(sessionMonth);
          }
          setSelectableMonths(newMonths);
        }
      });
      setSelectedYear(y);
    }
  }

  function handleSelectedMonth(m) {
    setSelectedSessions([]);
    setSelectedDay();

    if (m === selectedMonth) {
      setSelectedMonth();
      setSelectableDays([]);
    } else {
      let newDays = [];

      selectedRoomCodeObj?.sessions?.map((s) => {
        const date = s.createdAt.split('T')[0];
        const sessionDay = date.split('-')[2];

        if (s.createdAt.startsWith(`${selectedYear}-${m}`)) {
          if (!newDays.includes(sessionDay)) {
            newDays.push(sessionDay);
          }
        }
      });

      setSelectableDays(newDays);
      setSelectedMonth(m);
    }
  }

  function handleSelectedDay(d) {
    setSelectedSessions([]);

    if (d === selectedDay) {
      setSelectedDay();
      setSelectedSessions();
    } else {
      let newSessions = [];
      let colorArr = [];

      selectedRoomCodeObj?.sessions?.map((s) => {
        const date = s.createdAt.split('T')[0];

        if (date === `${selectedYear}-${selectedMonth}-${d}`) {
          newSessions.push(s);
        }
      });

      // Generate a color for each selected session on this day
      newSessions.forEach(() => colorArr.push(getRandomColor()));

      setSelectedSessions(newSessions);
      setHandleColors(colorArr);
      setSelectedDay(d);
    }
  }

  return (
    <>
      <div className="section mrg-t48">
        <label
          className="content-heading-styled text-gray flex-row"
          tabIndex="0"
          id="translationRoomsBillingLabel"
        >
          {BillingRoomCodeSubscriptions.translationUsage}
        </label>

        {!accountBillingRoomCodeSubscriptions && (
          <div className="flex-row fs18 mrg-t24">
            <p>{BillingRoomCodeSubscriptions.noTranslationRoomSubscription}</p>
          </div>
        )}

        {usageData?.length === 0 && (
          <p className="fs18 mrg-t24 access-ob access-o6" tabIndex="0">
            {BillingRoomCodeSubscriptions.noTranslationsUsage}
          </p>
        )}

        {/*Select Room Code */}
        <ul className="flex-row flex-wrap list-style-none mrg-t24">
          {usageData?.map((roomCodeSub, index) => {
            return (
              <li
                key={`roomCodeSub-${index}`}
                className={`flex-column mrg-r12 flex-center `}
              >
                <button
                  className={`br--standard pad-12 access-ob access-o6 ${
                    roomCodeSub?.roomCode === selectedRoomCodeObj?.roomCode
                      ? 'border-theme-1 outline-theme-1'
                      : 'highlight-bthin-theme outline-invis-2'
                  }`}
                  onClick={() => handleSelectRoomCode(roomCodeSub?.roomCode)}
                  tabIndex="0"
                >
                  <p className="fwsb fs18">{roomCodeSub?.roomCode}</p>
                </button>
                <div
                  className={` ${
                    roomCodeSub?.roomCode === selectedRoomCodeObj?.roomCode
                      ? 'active-dot mrg-t12 bg-color-theme'
                      : 'hidden-dot mrg-t12'
                  }`}
                />
              </li>
            );
          })}
        </ul>

        {selectedRoomCodeObj && (
          <>
            <div className="full-width divider-dotted mrg-tb24" />
            {selectableYears?.length === 0 && (
              <p className="fs18 access-ob access-o6" tabIndex="0">
                {BillingRoomCodeSubscriptions.translationRoomHasNoUsage}
              </p>
            )}
          </>
        )}

        {selectedRoomCodeObj && (
          <p
            className={`banners__notice align-center full-width fs16 mrg-b24 ${
              selectableYears?.length === 0 ? 'mrg-t12' : ''
            }`}
          >
            {BillingRoomCodeSubscriptions.noteShortDelay}
          </p>
        )}

        {/*Select Year*/}
        <ul className="flex-row flex-wrap list-style-none">
          {selectableYears?.map((y, index) => {
            return (
              <div key={`${y}-${index}`}>
                <li className="flex-column flex-center">
                  <button
                    className={`br--standard highlight-i-gt align-center pad-12  access-ob access-o6 ${
                      selectedYear === y
                        ? 'border-theme-1 outline-theme-1'
                        : 'highlight-bthin-theme outline-invis-2'
                    }`}
                    onClick={() => handleSelectYear(y)}
                    tabIndex="0"
                  >
                    <p className="fwsb fs16">{y}</p>
                  </button>
                  <div
                    className={` ${
                      selectedYear === y
                        ? 'active-dot mrg-t12 bg-color-theme'
                        : 'hidden-dot mrg-t12'
                    }`}
                  />
                </li>
              </div>
            );
          })}
        </ul>

        {/*Select Month*/}
        <ul className="flex-row flex-wrap list-style-none mrg-t12">
          {selectableMonths?.map((m, index) => {
            return (
              <li
                key={`${m}-${index}`}
                className="flex-column flex-center mrg-r12"
              >
                <button
                  className={`br--standard highlight-i-gt align-center pad-12 access-ob access-o6 ${
                    selectedMonth === m
                      ? 'border-theme-1 outline-theme-1'
                      : 'highlight-bthin-theme outline-invis-2'
                  }`}
                  onClick={() => handleSelectedMonth(m)}
                  tabIndex="0"
                >
                  <p className="fwsb fs16">{MonthsFromNumber[m]}</p>
                </button>
                <div
                  className={` ${
                    selectedMonth === m
                      ? 'active-dot mrg-t12 bg-color-theme'
                      : 'hidden-dot mrg-t12'
                  }`}
                />
              </li>
            );
          })}
        </ul>

        {/*Select Day*/}
        <ul className="flex-row flex-wrap list-style-none mrg-tb12">
          {selectableDays?.map((d, index) => {
            return (
              <li
                key={`${d}-${index}`}
                className="flex-column flex-center mrg-r12"
              >
                <button
                  className={`br--standard highlight-i-gt align-center pad-12 access-ob access-o6 ${
                    selectedDay === d
                      ? 'border-theme-1 outline-theme-1'
                      : 'highlight-bthin-theme outline-invis-2'
                  }`}
                  onClick={() => handleSelectedDay(d)}
                  tabIndex="0"
                >
                  <p className="fwsb fs16">{d}</p>
                </button>
                <div
                  className={` ${
                    selectedDay === d
                      ? 'active-dot mrg-t12 mrg-b12 bg-color-theme'
                      : 'hidden-dot mrg-t12 mrg-b12'
                  }`}
                />
              </li>
            );
          })}
        </ul>

        <ul className="flex-column info-card-list fs18">
          {selectedSessions?.map((session, index) => {
            return (
              <li
                key={`session-${index}`}
                tabIndex="0"
                className="access-ob access-o6"
              >
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColors[index] }}
                />
                <p>
                  {BillingRoomCodeSubscriptions.sessionId}:{' '}
                  {session._id.slice(-8, -4).toUpperCase()}-
                  {session._id.slice(-4).toUpperCase()}
                </p>
                <p>
                  {BillingRoomCodeSubscriptions.transcriptLength}:{' '}
                  {session.transcriptLength}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default TranslationRoomsUsage;

import { useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

//hooks
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useSettings } from '../../../../../context/SettingsProvider';

//lanugage
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import NavigationArrows from '../../../../../components/NavigationArrows/NavigationArrows';
import RegistrationPaymentCard from '../Hybrid/RegistrationPaymentCard';

//utils
import { img_cart } from '../../../../../assets/icons';
import { axiosLimited } from '../../../../../axios/axios';
import { priceLocaleConversion } from '../../../../../utils/currency/priceLocaleConversion';
import handleCalculateTaxesFrontEndVersion from '../../../../../utils/currency/handleCalculateTaxesFrontEndVersion';
import { appearance } from '../../../../../data/stripeAppearance';

const PersonalBilling = ({
  paymentCardRef,
  clientSecret,
  setClientSecret,
  activePage,
}) => {
  //hooks
  const { handleError } = useApiStatus();
  const { contentLanguage } = useSettings();
  const {
    handleRegistrationPageDecrease,
    handleRegistrationPageIncrease,
    registrationTranslationRoomsRoomCodes,
    registrationPage,
    handleRegistrationPageCompletionStatus,
    registrationBillingTaxData,
    registrationBillingTotalCost,
    registrationBillingSubtotalCost,
    handleRegistrationSetBillingTaxData,
    handleRegistrationSetBillingTotalCost,
    handleRegistrationSetBillingSubtotalCost,
    handleRegistrationSoftResetRegistration,
    handleRegistrationSetGenericRegistrationPage,
  } = useRegistrationHandlers();
  const { PersonalBilling } = useLanguageRegistration();
  const { SalesTaxTitles } = useLanguageData();

  //state
  const [stripePromise, setStripePromise] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [key, setKey] = useState(0);
  const [continueBtnCheck, setContinueBtnCheck] = useState(false);

  //variables
  const roomCodePriceInCAD =
    process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD;
  const loadStripePromiseEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (loadStripePromiseEffectRan.current === false) {
      loadStripePromiseEffectRan.current = true;
      if (process.env.REACT_APP_ENV === 'development') {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST)
        );
      } else {
        setStripePromise(
          loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
        );
      }
    }
  }, []); //load stripe promise

  useEffect(() => {
    if (stripePromise) {
      createSetupIntent();
    }
  }, [stripePromise]); //create initial paymentIntent

  //Tax Calcs
  useEffect(() => {
    let newSubtotal =
      process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD *
      registrationTranslationRoomsRoomCodes?.length;

    //Subtotal Calc
    handleRegistrationSetBillingSubtotalCost(newSubtotal);
  }, [registrationTranslationRoomsRoomCodes, contentLanguage]); //display calc subtotal

  function resetTaxData() {
    handleRegistrationSetBillingTaxData();
    handleRegistrationSetBillingTotalCost();
  }

  async function caclulateBilling(taxCalculationAddress, roomCodes) {
    if (
      !taxCalculationAddress?.address?.city ||
      !taxCalculationAddress?.address?.country
    ) {
      return handleRegistrationSetBillingTaxData();
    }

    try {
      const taxCalcObj = await handleCalculateTaxesFrontEndVersion(
        taxCalculationAddress,
        roomCodes
      );
      //Total Cost Calc
      handleRegistrationSetBillingTaxData(taxCalcObj);
      handleRegistrationSetBillingTotalCost(taxCalcObj?.newTotalCost);
    } catch (error) {
      handleError({
        error,
        id: Date.now(),
        origin: 'PersonalBilling.js/caclulateBilling',
      });
      handleRegistrationSetBillingTaxData();
    }
  }

  //stripe funct
  async function createSetupIntent() {
    if (!isLoading) {
      setIsLoading(true);
      try {
        const response = await axiosLimited.post(
          '/api/stripe/createSetupIntent'
        );

        if (!response.data.clientSecret || !response.data.setupIntentId) {
          throw new Error();
        }
        setClientSecret(response.data.clientSecret);
        setKey((prevKey) => prevKey + 1);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        handleError({
          error,
          id: Date.now(),
          origin: 'PersonalBilling.js/createSetupIntent',
        });
        handleRegistrationSetGenericRegistrationPage(1);
        handleRegistrationSoftResetRegistration();
      }
    }
  }

  return (
    <>
      <p className="mrg-t12 mrg-b24 fs16 italic flex-center flex-row">
        {PersonalBilling.paymentsSecuredByStripe}
      </p>

      <div className={`flex-column`}>
        {stripePromise && clientSecret && (
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret,
              appearance,
              locale: contentLanguage,
            }}
            key={key}
          >
            <RegistrationPaymentCard
              ref={paymentCardRef}
              clientSecret={clientSecret}
              setContinueBtnCheck={setContinueBtnCheck}
              caclulateBilling={caclulateBilling}
              activePage={activePage}
              resetTaxData={resetTaxData}
            />
          </Elements>
        )}
      </div>

      <div className="border-reg pad-24 br--standard full-width mrg-t48">
        <div className="flex-row space-between">
          <label
            className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
            id="roomCodeListLabel"
            tabIndex="0"
          >
            <div className="flex-row full-width">
              <p>{PersonalBilling.roomCodeSubscriptions}</p>
              <img
                src={img_cart}
                alt={PersonalBilling.cart}
                style={{ height: '24px' }}
                className="filter-gray mrg-auto-left"
              />
            </div>
          </label>
        </div>
        <ul className="flex-column list-style-none fs21 fs18--ph mrg-t24">
          {registrationTranslationRoomsRoomCodes?.map((roomCode, index) => {
            return (
              <li
                className="flex-row align-center full-width space-between mrg-b6"
                key={`troom-code-${index}`}
              >
                <p>{roomCode}</p>
                <p>
                  {priceLocaleConversion(
                    roomCodePriceInCAD,
                    contentLanguage,
                    'cad',
                    true
                  )}
                </p>
              </li>
            );
          })}
        </ul>
        <div className="full-width divider-dotted mrg-t24 mrg-b12" />
        <div className="flex-column">
          <div className="flex-row space-between fs21 fs18--ph mrg-t12">
            <p>{PersonalBilling.subTotal}:</p>
            <p>
              {priceLocaleConversion(
                registrationBillingSubtotalCost,
                contentLanguage,
                'cad',
                true
              )}
            </p>
          </div>

          <div className="flex-column">
            {!registrationBillingTaxData?.newTaxCosts ? (
              <div className="flex-row space-between fs21 fs18--ph mrg-t12">
                <p>{PersonalBilling.taxes}:</p>
                <p>{PersonalBilling.requiresAddress}</p>
              </div>
            ) : (
              <div className="flex-column full-width">
                {registrationBillingTaxData.newTaxCosts?.map(
                  (taxCostObj, index) => {
                    return (
                      <div
                        className="flex-row space-between fs21 fs18--ph mrg-t12"
                        key={`taxTitle-${index}`}
                      >
                        <p>
                          {SalesTaxTitles[taxCostObj?.taxTitle]}
                          &nbsp;
                        </p>
                        <p>
                          {priceLocaleConversion(
                            taxCostObj?.taxCost,
                            contentLanguage,
                            'cad',
                            true
                          )}
                        </p>
                      </div>
                    );
                  }
                )}
              </div>
            )}

            <div className="flex-row space-between fs21 fs18--ph mrg-t12">
              <p>{PersonalBilling.monthlySubscriptionTotal}:</p>
              {registrationBillingTaxData?.newTaxCosts ? (
                <p>
                  {registrationBillingTotalCost &&
                    priceLocaleConversion(
                      registrationBillingTotalCost,
                      contentLanguage,
                      'cad',
                      true
                    )}
                </p>
              ) : (
                <p>{PersonalBilling.requiresAddress}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="h36" />
      <NavigationArrows
        handlePageDecrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageDecrease();
        }}
        handlePageIncrease={() => {
          handleRegistrationPageCompletionStatus(
            registrationPage,
            continueBtnCheck
          );
          handleRegistrationPageIncrease(registrationPage);
        }}
        continueBtnCheck={continueBtnCheck}
      />
    </>
  );
};

export default PersonalBilling;

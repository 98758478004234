import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useGenericModal } from '../../../../context/GenericModalProvider';

//api
import { useLazyFindSpeakersQuery } from '../organizeApiSlice';

//components
import FindSpeakersSelection from './FindSpeakersSelection';
import FindSpeakersQueryFilters from './QueryFilters/FindSpeakersQueryFilters';
import FindSpeakersQueryResults from './FindSpeakersQueryResults';
import ProfilePersonalModal from '../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//utils
const FindSpeakersContent = () => {
  //hooks
  const { width } = useSettings();
  const {
    accountId,
    accountGeoPositionCoordinates,
    accountCommunityContacts,
    accountCommunityContactsRequested,
  } = useAccountReduxHandlers();

  const { setGenericModal } = useGenericModal();
  const {
    handleValidateFindSpeakersQuery,
    handleSettingsSetFindSpeakersSearchCoordinates,
    settingsFindSpeakersHasMore: hasMore,
    settingsFindSpeakersQueryPage: queryPage,
    handleSettingsSetFindSpeakersHasMore,
    handleSettingsSetFindSpeakersQueryPage,
    settingsFindSpeakersDistanceFilter: distanceFilter,
    settingsFindSpeakersDistanceMeasurement: distanceMeasurement,
    settingsFindSpeakersSearchBigTags: searchBigTags,
    settingsFindSpeakersSearchCoordinates: searchCoordinates,
    settingsFindSpeakersSearchCountries: searchCountries,
    settingsFindSpeakersSearchLocationType: searchLocationType,
    settingsFindSpeakersSearchSpeakerLanguages: searchSpeakerLanguages,
    settingsFindSpeakersSearchSpeakerLanguagesNotListed:
      searchSpeakerLanguagesNotListed,
    settingsFindSpeakersSearchTinyTags: searchTinyTags,
  } = useSettingsHandlers();

  //api
  const [
    findSpeakersQuery,
    {
      isFetching: findSpeakersQueryisFetching,
      isLoading: findSpeakersQueryisLoading,
    },
  ] = useLazyFindSpeakersQuery();

  //state
  const [searchHasRun, setSearchHasRun] = useState(false);
  const [viewSpeaker, setViewSpeaker] = useState();
  const [reduxQueryEndpoint, setReduxQueryEndpoint] = useState();
  const [paginatedData, setPaginatedData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  //UI
  const [queryIsLoading, setQueryIsLoading] = useState(false);
  const [page, setPage] = useState(0);

  //initialize
  useEffect(() => {
    handleSettingsSetFindSpeakersHasMore(true);
    handleSettingsSetFindSpeakersQueryPage(0);
    const newEndpointString = uuidv4();
    setReduxQueryEndpoint(newEndpointString);
  }, []);

  useEffect(() => {
    let sortedResults = [];

    if (searchData?.length > 0) {
      let contactIds = [];
      accountCommunityContacts?.map((acct) => contactIds.push(acct?._id));

      let contactRequestedIds = [];
      accountCommunityContactsRequested?.map((acct) =>
        contactRequestedIds.push(acct?._id)
      );

      searchData?.map((speaker) => {
        const speakerId = speaker?._id;

        if (contactIds?.includes(speakerId)) {
          let newSpeaker = { ...speaker };
          newSpeaker.isContact = true;
          sortedResults.push(newSpeaker);
        } else if (contactRequestedIds?.includes(speakerId)) {
          let newSpeaker = { ...speaker };
          newSpeaker.isRequested = true;
          sortedResults.push(newSpeaker);
        } else {
          sortedResults.push(speaker);
        }
      });
    }

    let newPaginatedData = [];

    for (let i = 0; i < sortedResults?.length; i += 8) {
      const pageData = sortedResults.slice(i, i + 8);

      newPaginatedData.push(pageData);
    }

    setPaginatedData(newPaginatedData);
  }, [searchData, accountCommunityContacts, accountCommunityContactsRequested]); //handle updated data from notices socket + pagination retreival management

  //UI
  useEffect(() => {
    if (queryIsLoading) {
      const animationTimer = setTimeout(() => {
        setQueryIsLoading(false);
      }, 1000);

      return () => clearTimeout(animationTimer);
    }
  }, [queryIsLoading]); //provide minimum loading animation

  //Component functions
  useEffect(() => {
    if (accountGeoPositionCoordinates[0]) {
      handleSettingsSetFindSpeakersSearchCoordinates(
        accountGeoPositionCoordinates
      );
    }
  }, [accountGeoPositionCoordinates]); //sets default search position to user

  //api
  async function handleFindSpeakersQuery() {
    const validationFailed = handleValidateFindSpeakersQuery();
    if (validationFailed) return;

    try {
      if (!findSpeakersQueryisLoading || !findSpeakersQueryisFetching) {
        setQueryIsLoading(true);
        setPaginatedData([]);
        handleSettingsSetFindSpeakersQueryPage(0);
        setPage(0);
        setViewSpeaker();

        const reduxEndpoint = checkReduxQueryEndpoint();

        const res = await findSpeakersQuery({
          accountId,
          searchCountries,
          searchCoordinates,
          distanceFilter,
          searchSpeakerLanguages,
          searchSpeakerLanguagesNotListed,
          searchLocationType,
          distanceMeasurement,
          searchBigTags,
          searchTinyTags,
          queryPage: 0,
          reduxEndpoint,
        });

        setSearchData(res?.data?.data);
        setSearchHasRun(true);
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('DEV handleFindSpeakersQuery error,', error);
      }
    }
  }

  async function getNextSpeakersPage() {
    const validationFailed = handleValidateFindSpeakersQuery();
    if (validationFailed) return;

    if (
      !findSpeakersQueryisFetching &&
      !findSpeakersQueryisLoading &&
      accountId &&
      hasMore
    ) {
      try {
        const reduxEndpoint = checkReduxQueryEndpoint();

        const res = await findSpeakersQuery({
          accountId,
          searchCountries,
          searchCoordinates,
          distanceFilter,
          searchSpeakerLanguages,
          searchSpeakerLanguagesNotListed,
          searchLocationType,
          distanceMeasurement,
          searchBigTags,
          searchTinyTags,
          queryPage: queryPage + 1,
          reduxEndpoint,
        });

        let oldSearchData = [...searchData];
        const newSearchData = res?.data?.data;
        const combinedSearchData = oldSearchData.concat(newSearchData);

        setSearchData(combinedSearchData);

        //increase page
        handleSettingsSetFindSpeakersQueryPage(queryPage + 1);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log('handleFindSpeakersQuery error,', error);
        }
      }
    }
  }

  function handlePageIncrease() {
    const pagesOfData = paginatedData?.length || 0;

    if (page <= pagesOfData) {
      setPage(page + 1);
    }

    if (page + 2 >= pagesOfData) {
      getNextSpeakersPage();
    }
  }

  function handlePageDecrease() {
    if (page <= 0) {
      setPage(0);
    } else {
      setPage(page - 1);
    }
  }

  function checkReduxQueryEndpoint() {
    //used to create redux string for api queries rather than using all query parameters
    const queryParams = {
      searchCountries,
      searchCoordinates,
      distanceFilter,
      searchSpeakerLanguages,
      searchSpeakerLanguagesNotListed,
      distanceMeasurement,
      searchBigTags,
      searchTinyTags,
    };

    const filteredParams = Object.entries(queryParams)
      .filter(([_, value]) => value !== undefined && value !== null)
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const newReduxQueryEndpoint = `findSpeakers-${Object.entries(filteredParams)
      ?.map(([key, value]) => `${key}-${value}`)
      .join('-')}`;

    if (newReduxQueryEndpoint !== reduxQueryEndpoint) {
      const newEndpointString = uuidv4();
      setReduxQueryEndpoint(newEndpointString);
      return newEndpointString;
    } else {
      return reduxQueryEndpoint;
    }
  }

  function handleSpeakerSelection(id) {
    let speakerFound = false;

    for (const pageData of paginatedData || []) {
      for (const speaker of pageData || []) {
        if (speaker._id === id) {
          speakerFound = true;
          if (viewSpeaker?._id === id) {
            setViewSpeaker();
          } else {
            if (width > 1480) {
              setViewSpeaker(speaker);
            } else {
              setGenericModal(
                <ProfilePersonalModal
                  closeModalFocusId={`speaker-result-${id}`}
                  profileData={speaker}
                />
              );
            }
          }
          break;
        }
      }
      if (speakerFound) break;
    }
  }

  function handleViewSpeakerFromModalSelection(speaker) {
    setGenericModal();

    let timeout = null;
    timeout = setTimeout(() => {
      setGenericModal(
        <ProfilePersonalModal
          closeModalFocusId={'viewContactsButton'}
          profileData={speaker}
        />
      );
    }, 300);
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      } //timeout required otherwise modal closes instantly
    };
  }

  return (
    <div className="fs__wrapper mrg-b48">
      <FindSpeakersQueryFilters
        handleFindSpeakersQuery={handleFindSpeakersQuery}
        handleViewSpeakerFromModalSelection={
          handleViewSpeakerFromModalSelection
        }
        queryIsLoading={queryIsLoading || findSpeakersQueryisLoading}
        setViewSpeaker={setViewSpeaker}
        setPaginatedData={setPaginatedData}
      />
      {width > 1480 ? (
        <div className="flex-row full-width space-evenly mrg-b48">
          <FindSpeakersSelection
            viewSpeaker={viewSpeaker}
            setViewSpeaker={setViewSpeaker}
          />
          <FindSpeakersQueryResults
            handlePageDecrease={handlePageDecrease}
            handlePageIncrease={handlePageIncrease}
            handleSpeakerSelection={handleSpeakerSelection}
            page={page}
            searchHasRun={searchHasRun}
            paginatedData={paginatedData}
            viewSpeakerId={viewSpeaker?._id}
            queryIsLoading={queryIsLoading}
          />
        </div>
      ) : (
        <FindSpeakersQueryResults
          handlePageDecrease={handlePageDecrease}
          handlePageIncrease={handlePageIncrease}
          handleSpeakerSelection={handleSpeakerSelection}
          page={page}
          searchHasRun={searchHasRun}
          paginatedData={paginatedData}
          viewSpeakerId={viewSpeaker?._id}
          queryIsLoading={queryIsLoading}
        />
      )}
    </div>
  );
};

export default FindSpeakersContent;

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//utils
import { warning } from '../../../../assets/icons';

const FieldValueMissing = () => {
  //language
  const { IconAlts, FieldValueMissing } = useLanguageComponents();

  return (
    <div className="previewMode-border outline-offset-2 flex-row flex-center">
      <img
        src={warning}
        style={{ height: '21px' }}
        alt={IconAlts.iconWarning}
        className="mrg-lr12"
      />
      <p className="mrg-r12">{FieldValueMissing.fieldValueMissing}</p>
    </div>
  );
};

export default FieldValueMissing;

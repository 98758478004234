import { useSettings } from '../context/SettingsProvider';

const useLanguageLayout = () => {
  const { contentLanguage } = useSettings();

  const App = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        main: 'Primary Content',
        pageNotFound: 'Page not found',
        unauthorized: 'Unauthorized',
        updatingApp: 'Updating',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        main: 'Contenu principal',
        pageNotFound: 'Page non trouvée',
        unauthorized: 'Non autorisé',
        updatingApp: `Mise à jour`,
      };
    }

    return translation;
  };

  const Footer = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        about: 'About',
        contact: 'Contact',
        userAgreement: 'User Agreement',
        privacyPolicy: 'Privacy Policy',
        selectSiteLanguage: 'Select site language. Current selection',
        visitOurLinkedInPage: 'Visit our LinkedIn Page.',
        footer: 'Footer',
        comingSoon: 'Coming soon',
        recaptchaPt1: 'This site is protected by reCAPTCHA and Google.',
        recaptchaPt2: 'Privacy Policy',
        recaptchaPt3: 'and',
        recaptchaPt4: 'Terms of Service',
        recaptchaPt5: 'apply.',
        terms: 'Terms',
        followUs: 'Follow us',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        about: 'À propos',
        contact: 'Contactez-nous',
        userAgreement: `Contrat d'utilisateur`,
        privacyPolicy: 'Politique de confidentialité',
        selectSiteLanguage:
          'Sélectionnez la langue du site. Sélection actuelle',
        visitOurLinkedInPage: 'Visitez notre page LinkedIn.',
        footer: 'Bas de page',
        comingSoon: 'Bientôt disponible',
        recaptchaPt1: 'Ce site est protégé par reCAPTCHA et Google.',
        recaptchaPt2: 'Politique de confidentialité',
        recaptchaPt3: 'et',
        recaptchaPt4: `Conditions d'utilisation`,
        recaptchaPt5: 'appliquer.',
        terms: 'Termes',
        followUs: 'Suivez-nous',
      };
    }

    return translation;
  };

  const NavbarLoggedOut = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        contact: 'Contact',
        register: 'Register',
        signIn: 'Sign In',
        decorativeEnterIcon: 'Decorative enter icon',
        selectSiteLanguage: 'Select site language. Current selection',
        linkToHomepage: 'link to home page',
        navigationMenu: 'Navigation menu',
        menuIcon: 'Menu',
        header: 'Site Navigation',
        translations: 'Translations',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        contact: 'Contactez-nous',
        register: `S'inscrire`,
        signIn: 'Se connecter',
        decorativeEnterIcon: `Icône d'entrée décorative`,
        selectSiteLanguage:
          'Sélectionnez la langue du site. Sélection actuelle',
        linkToHomepage: `lien vers la page d'accueil`,
        navigationMenu: 'le menu de navigation',
        menuIcon: 'icône de menu',
        header: 'Naviguer sur le site',
        translations: 'Traductions',
      };
    }
    return translation;
  };

  const NavbarLoggedIn = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        account: 'Account',
        contact: 'Contact',
        events: 'Events',
        speaker: 'Speaker',
        organize: 'Organize',
        register: 'Register',
        signIn: 'Sign In',
        signOut: 'Sign Out',
        profilePicture: 'profile picture',
        linkToEvents: 'Link to Events',
        translations: 'Translations',
        logoAlt: 'MyndFull Logo',
        iconBell: 'Bell icon',
        attemptingToReconnect: 'Attempting to reconnect',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        account: 'Compte',
        contact: 'Contact',
        events: 'Événements',
        speaker: 'Orateur',
        organize: 'Organiser',
        register: `S'inscrire`,
        signIn: 'Se connecter',
        signOut: 'Se déconnecter',
        profilePicture: 'image de profil',
        linkToEvents: `lien vers les événements`,
        translations: 'Traductions',
        logoAlt: 'Logo MyndFull',
        iconBell: 'Icône de cloche',
        attemptingToReconnect: 'Tentative de reconnexion',
      };
    }
    return translation;
  };

  const SlideMenu = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        slideMenuOnScreen: 'Slide Navigation Menu (on-screen)',
        slideMenuOffScreen: 'Slide Navigation Menu (off-screen)',
        visitOurLinkedInPage: 'Visit our LinkedIn Page.',
        closeNavigationMenu: 'Close navigation menu.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        slideMenuOnScreen: `
        Menu de navigation des diapositives (à l'écran)`,
        slideMenuOffScreen: `Menu de navigation des diapositives (hors écran)`,
        visitOurLinkedInPage: 'Visitez notre page LinkedIn.',
        closeNavigationMenu: 'Fermer le menu de navigation.',
      };
    }
    return translation;
  };

  const ErrorPage = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        oops: 'Oops!',
        errorOccurred1: `Something went wrong. We're sorry about that. Try refreshing the page and let's hope for the best!`,
        errorOccurred2: `If the issue persists, please let us know at`,
        supportEmail: 'support@myndfull.com',
        errorOccurred3: `We're here to help!`,
        refreshButton: 'Refresh Page',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        oops: 'Oups!',
        errorOccurred1: `Un problème est survenu. Nous en sommes désolés. Essayez de rafraîchir la page et croisons les doigts!`,
        errorOccurred2: `Si le problème persiste, veuillez nous en informer à`,
        supportEmail: 'support@myndfull.com',
        errorOccurred3: `Nous sommes là pour vous aider!`,
        refreshButton: `Tentative d'actualisation de la page`,
      };
    }
    return translation;
  };

  const LandingPageShowCards = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        translations: 'Translations',
        translationsImgAlt:
          'A phone with chat bubbles that have greetings in different languages.',
        explore: 'Explore',
        comingSoon: '(Coming Soon)',
        exploreImgAlt: 'A person sitting down on a chair with a tablet.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        translations: 'Traductions',
        translationsImgAlt:
          'Un téléphone avec des bulles de discussion contenant des salutations dans différentes langues.',
        explore: 'Explorer',
        comingSoon: '(À venir)',
        exploreImgAlt: 'Une personne assise sur une chaise avec une tablette.',
      };
    }
    return translation;
  };

  const ExploreMyndFull = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        exploreHeading1: 'Your Platform for Academic',
        exploreHeading2: '& Educational Events',
        signUp: 'Sign Up',
        whatIsMyndFull: 'What is MyndFull?',
        demoImgMobile: `An image displaying the MyndFull web app on a mobile device.`,
        demoImgDesktop: `An image displaying the MyndFull web app on a dekstop device.`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {};
    }
    return translation;
  };

  return {
    App: App(),
    Footer: Footer(),
    NavbarLoggedOut: NavbarLoggedOut(),
    NavbarLoggedIn: NavbarLoggedIn(),
    SlideMenu: SlideMenu(),
    ErrorPage: ErrorPage(),
    LandingPageShowCards: LandingPageShowCards(),
    ExploreMyndFull: ExploreMyndFull(),
  };
};

export default useLanguageLayout;

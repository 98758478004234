import App from './App';
import './Css/App.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/es/integration/react';
import { Provider } from 'react-redux';
import { persistor, store } from './app/store';
import { SettingsProvider } from './context/SettingsProvider';
import { ApiStatusProvider } from './context/ApiStatusProvider';
import { GenericModalProvider } from './context/GenericModalProvider';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Workbox } from 'workbox-window';

//Socket providers
import { TranslationRoomSocketProvider } from './context/sockets/main/TranslationRoomSocketProvider';
import { EventVerificationsSocketProvider } from './context/sockets/admin/EventVerificationsSocketProvider';
import { TicketsSocketProvider } from './context/sockets/admin/TicketsSocketProvider';
import { OrganizeEventsSocketProvider } from './context/sockets/main/OrganizeEventsSocketProvider';
import { TechnicalLogsSocketProvider } from './context/sockets/admin/TechnicalLogsSocketProvider';
import { AccountPersonalSocketProvider } from './context/sockets/main/AccountPersonalProvider';

//app redux state cache control see store

function registerSW() {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      return null;
    }

    window.addEventListener('load', () => {
      let swUrl;
      if (process.env.REACT_APP_ENV === 'development') {
        swUrl = `${process.env.PUBLIC_URL}/sw-dev.js`;
      } else if (process.env.REACT_APP_ENV === 'production') {
        swUrl = `${process.env.PUBLIC_URL}/sw-${process.env.REACT_APP_REDUX_V}.js`;
      }
      const workbox = new Workbox(swUrl);

      workbox
        .register()
        .then((registration) => {
          if (registration) {
            console.log('Service Worker registered.', registration);
          }
        })
        .catch((error) => {
          console.error('Error during service worker registration:', error);
        });
    });
  }
}

registerSW();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate load={null} persistor={persistor}>
        <BrowserRouter>
          <SettingsProvider>
            <ApiStatusProvider>
              <GenericModalProvider>
                <GoogleReCaptchaProvider
                  reCaptchaKey={'6LdgoncnAAAAAA-TKzvQ7KOujolBRx1H-nCOBYtR'}
                  language={'en-GB'}
                  useEnterprise={true}
                  scriptProps={{
                    async: false,
                    defer: false,
                    appendTo: 'head',
                    nonce: '__SERVER_NONCE__',
                  }}
                >
                  <TranslationRoomSocketProvider>
                    <TechnicalLogsSocketProvider>
                      <EventVerificationsSocketProvider>
                        <TicketsSocketProvider>
                          <AccountPersonalSocketProvider>
                            <OrganizeEventsSocketProvider>
                              <Routes>
                                <Route path="/*" element={<App />} />
                              </Routes>
                            </OrganizeEventsSocketProvider>
                          </AccountPersonalSocketProvider>
                        </TicketsSocketProvider>
                      </EventVerificationsSocketProvider>
                    </TechnicalLogsSocketProvider>
                  </TranslationRoomSocketProvider>
                </GoogleReCaptchaProvider>
              </GenericModalProvider>
            </ApiStatusProvider>
          </SettingsProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../features/main/account/useAccountHandlers';

//langauge
import useLanguageComponents from '../../../language/useLanguageComponents';
import useLanguageData from '../../../language/useLanguageData';

//utils
import GetFlag from '../../../utils/images/GetFlag';
import { contact, mail } from '../../../assets/icons';

//keep acct status logic within component for rerendering

const ProfileContact = ({ profile, previewMode, ProfilePersonal }) => {
  //hooks
  const { accountId, accountCommunityContacts } = useAccountReduxHandlers();

  //language
  const { IconAlts } = useLanguageComponents();
  const { CountryCodeOpts } = useLanguageData();

  //state
  const [isContact, setIsContact] = useState(false);
  const [countryCode, setCountryCode] = useState();
  const [flag, setFlag] = useState();

  useEffect(() => {
    if (isContact) {
      if (
        profile?.personal?.countryCode &&
        profile?.personal?.phoneNumber?.length > 0
      ) {
        const phoneData = CountryCodeOpts[profile?.personal?.countryCode];
        setCountryCode(phoneData);
        setFlag(GetFlag(profile?.personal?.countryCode));
      }
    }
  }, [isContact]); //determine if speakerData is connected or not -- ui + set flag for countryCode

  useEffect(() => {
    if (profile && accountId) {
      let contactIds = [];
      accountCommunityContacts?.map((acct) => contactIds.push(acct?._id));
      if (contactIds.includes(profile?._id)) {
        setIsContact(true);
      } else {
        setIsContact(false);
      }
    }
  }, [accountCommunityContacts, profile]); //determine interaction status

  return (
    <>
      <div
        className={`mrg-b36 ${
          previewMode ? 'account-previewMode__outline' : ''
        }`}
      >
        {previewMode && (
          <div className="account-previewMode--outline-header">
            <p>{ProfilePersonal.viewableByContacts}</p>
          </div>
        )}

        {!isContact && (
          <>
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="tagsLabel"
            >
              {ProfilePersonal.contactInfo}
            </label>
            <div className="h12" />
            <div className="flex-row">
              <img
                src={mail}
                alt={IconAlts.iconEmail}
                className="filter-theme mrg-r12"
                style={{ height: '24px', width: '24px' }}
              />
              <p>
                {ProfilePersonal.email}: {profile?.personal?.email}
              </p>
            </div>

            <div className="flex-row align-center mrg-t12">
              <img
                src={contact}
                alt={IconAlts.iconEmail}
                className="filter-theme mrg-r12"
                style={{ height: '24px', width: '24px' }}
              />
              <p>{ProfilePersonal.phoneNumber}:</p>
              {flag && profile?.personal?.phoneNumber && countryCode ? (
                <div className="mrg-l12 flex-row align-center">
                  <div className="align-center">{flag}</div>
                  <p className="mrg-l6 mrg-r6">{countryCode?.code}</p>
                  <p>{profile?.personal?.phoneNumber}</p>
                </div>
              ) : (
                <p className="mrg-l12">{ProfilePersonal.nA}</p>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ProfileContact;

import { useEffect, useState } from 'react';

//hooks
import { useGenericModal } from '../../../../context/GenericModalProvider';
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//utils
import {
  checkmark_green,
  return_curved_arrow,
  warning,
} from '../../../../assets/icons';

const ListModal = ({
  handleAddOptsModal,
  iconModal,
  iconModalClass,
  iconModalStyle,
  id,
  limit,
  listData,
  modalTitle,
  selectionState,
  subTextOn,
  listItemIconsFunction,
}) => {
  //hooks
  const {
    handleCloseModal,
    setCloseModalFocusId,
    setGenericModalSelectionState,
    setGenericModalSelectionStateId,
    invalidSelection,
    setInvalidSelection,
  } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //language
  const { Generic, IconAlts } = useLanguageComponents();

  //state
  const [filteredList, setFilteredList] = useState();
  const [addedSelections, setAddedSelections] = useState([]);

  //UI state
  const [tapHighlightExitBtn, setTapHighlightExitBtn] = useState(false);

  //Component variables
  let optsList;

  //Initialize
  useEffect(() => {
    setCloseModalFocusId(`${id}-itemButton`);
  }, []); //focus on item button when modal closes

  useEffect(() => {
    setGenericModalSelectionStateId(id);
  }, [id]); //focus on modal when it opens

  useEffect(() => {
    if (filteredList && id) {
      document.getElementById(`${id}-label`).focus();
    }
  }, [filteredList]); //focus on modal label when opened

  useEffect(() => {
    if (selectionState) {
      const filteredList = Object.keys(listData).reduce((acc, key) => {
        if (!selectionState.includes(key)) {
          acc[key] = listData[key];
        }
        return acc;
      }, {});
      setFilteredList(filteredList);
    }
  }, [selectionState]); //removes selections from list

  useEffect(() => {
    if (selectionState?.length + addedSelections?.length > limit) {
      setInvalidSelection(true);
    } else {
      if (invalidSelection) {
        setInvalidSelection(false);
      }
    }
  }, [addedSelections]); //toggle invalid selection

  //Component functions
  function handleClickOpt(key) {
    if (addedSelections?.includes(key)) {
      let updatedAddedSelections = addedSelections.filter(
        (existingTag) => existingTag !== key
      );
      return setAddedSelections(updatedAddedSelections);
    }

    let updatedAddedSelections = [...addedSelections, key];
    setAddedSelections(updatedAddedSelections);
  }

  useEffect(() => {
    if (setGenericModalSelectionState) {
      setGenericModalSelectionState(addedSelections);
    }
  }, [addedSelections]);

  function getlistItemIcons(selection) {
    if (listItemIconsFunction && selection) {
      const iconElement = listItemIconsFunction(selection);
      if (iconElement) {
        return iconElement;
      }
    }
  }

  //JSX conditional
  if (filteredList) {
    let lastItemIndex = Object.entries(filteredList).length - 1;

    optsList = (
      <>
        {Object.entries(filteredList)?.map(([key, value], index) => {
          const isLastItem = index === lastItemIndex;
          if (key === 'select') return;
          return (
            <li
              key={`${key}`}
              style={isLastItem ? { marginBottom: '160px' } : null}
            >
              <button
                className={`item item--w-subText space-between access-ob access-o6 ${
                  addedSelections.includes(key) ? 'item-selected-multiple' : ''
                }`}
                onClick={() => handleClickOpt(key)}
                type="button"
                tabIndex="0"
                aria-label={
                  subTextOn ? `${value.mainText} ${value.subText}` : value
                }
                id={`list-item-${index}`}
                aria-pressed={addedSelections.includes(key)}
              >
                <div className="flex-row align-center">
                  {getlistItemIcons(key)}
                  {subTextOn ? (
                    <div
                      className={`flex-column ${
                        getlistItemIcons ? 'mrg-l6' : ''
                      }`}
                    >
                      <p className="fs18 fwsb">{value.mainText}</p>
                      <p className="fs16 italic">{value.subText}</p>
                    </div>
                  ) : (
                    <div
                      className={`flex-column ${
                        getlistItemIcons ? 'mrg-l6' : ''
                      }`}
                    >
                      <p className="fs18 fwsb">{value}</p>
                    </div>
                  )}
                </div>

                {addedSelections?.includes(key) && (
                  <img
                    src={checkmark_green}
                    alt={IconAlts.iconCheckmark}
                    className="mrg-r12"
                  />
                )}
              </button>
            </li>
          );
        })}
      </>
    );
  }

  return (
    <div className="modal-basic__wrapper access-ow access-o12" tabIndex="0">
      <div className="heading">
        <div className="flex-row flex-center">
          <img
            src={iconModal}
            alt={modalTitle}
            className={`mrg-r12 ${iconModalClass ? iconModalClass : ''}`}
            style={iconModalStyle}
          />
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`${id}-label`}
          >
            {modalTitle}
          </label>
        </div>
        {invalidSelection ? (
          <img
            src={warning}
            alt={IconAlts.iconWarning}
            className="svg"
            style={{ maxHeight: '28px' }}
          />
        ) : (
          <button
            className={`close-btn highlight-i-lgt access-ob  ${
              tapHighlightExitBtn ? 'highlight-i-lgt--tap' : ''
            }`}
            onClick={
              invalidSelection
                ? null
                : filteredList
                ? () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightExitBtn(true),
                        () => setTapHighlightExitBtn(false),
                      ],
                      [
                        () => handleAddOptsModal(addedSelections),
                        () => handleCloseModal(),
                      ]
                    )
                : () =>
                    handleMobileTap(
                      [
                        () => setTapHighlightExitBtn(true),
                        () => setTapHighlightExitBtn(false),
                      ],
                      [() => handleCloseModal()]
                    )
            }
            type="button"
            title={Generic.return}
          >
            <img
              src={return_curved_arrow}
              alt={IconAlts.iconReturn}
              className="svg"
              style={{ maxHeight: '48px' }}
            />
          </button>
        )}
      </div>
      {invalidSelection && (
        <div
          className="modal-warning-banner access-ob"
          tabIndex="0"
          aria-label={Generic.overLimit}
        >
          <p className="fwsb fs18">{Generic.overLimit}</p>
        </div>
      )}
      <ul className="modal-basic__container list-style-none" id="bigTagsList">
        {optsList}
      </ul>
    </div>
  );
};

export default ListModal;

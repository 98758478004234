import React from 'react';
import { myndfull_pattern } from '../../../assets/icons/patternIcons/index';

const MyndFullPatternCol = React.memo(() => {
  return (
    <div
      className="myndfull-pattern-icon__container svg"
      style={{ backgroundImage: `url(${myndfull_pattern})` }}
    />
  );
});

export default MyndFullPatternCol;

import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

//hooks
import { useAccountReduxHandlers } from '../../../features/main/account/useAccountHandlers';
import { useNoticeHandlers } from '../../../features/main/notices/useNoticeHandlers';
import { useAccountCommunityHandlers } from '../../../features/main/account/useAccountCommunityHandlers';

const AccountPersonalSocketContext = createContext({});

export const AccountPersonalSocketProvider = ({ children }) => {
  //Hooks
  const { accountId } = useAccountReduxHandlers();
  const { checkNoticeDataForDataRefresh, handleGetAccountNotices } =
    useNoticeHandlers();
  const { handleGetAccountCommunityLists } = useAccountCommunityHandlers();

  //state
  const [accountPersonalSocketStatus, setAccountPersonalSocketStatus] =
    useState('closed');

  //variables
  const logsOn = true;

  const socketRef = useRef(null);

  const ioAccountPersonalSocketURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/accountPersonal'
      : 'https://myndfull.com/ws/accountPersonal';
  const withCredentials =
    process.env.REACT_APP_ENV === 'development' ? false : true;

  const connectAccountPersonalSocket = () => {
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    if (!socketRef.current && accountPersonalSocketStatus !== 'connected') {
      setAccountPersonalSocketStatus('initializing');
      socketRef.current = io(ioAccountPersonalSocketURL, {
        query: {
          accountId,
        },
        reconnectionAttempts: 10,
        withCredentials: withCredentials,
      });

      socketRef.current.on('connect', async () => {
        logsOn && console.log('AccountPersonal socket, connected');
        setAccountPersonalSocketStatus('connected');

        if (accountId) {
          logsOn &&
            console.log(
              'AccountPersonal socket, Fetching account notices on reconnect...'
            );
          handleGetAccountNotices();
          handleGetAccountCommunityLists();
        }
      });

      socketRef.current.on('reconnect', async () => {
        logsOn && console.log('reconnect');
        setAccountPersonalSocketStatus('connected');
      });

      socketRef.current.on(
        'accountPersonalNoticesData',
        async (accountPersonalNoticesData) => {
          if (accountPersonalNoticesData && accountId) {
            logsOn && console.log('AccountPersonal socket, noticesData');

            const accountNotices = await handleGetAccountNotices();
            checkNoticeDataForDataRefresh(accountNotices?.data?.notices || []);
          }
        }
      );

      socketRef.current.on('disconnect', (reason, details) => {
        logsOn &&
          console.error(
            `AccountPersonal socket disconnect, Reason: ${reason}, Details: ${details}`
          );

        setAccountPersonalSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn &&
            console.error('AccountPersonal socket, no automatic reconnect');
          closeAccountPersonalSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn &&
            console.error('AccountPersonal socket, automatic reconnect');
        } else {
          logsOn &&
            console.error(
              `AccountPersonal socket, Unexpected disconnect reason: ${reason}`
            );
          closeAccountPersonalSocket();
        }
      });

      socketRef.current.on('error', (error) => {
        logsOn && console.error('AccountPersonal socket, socket error:', error);
        setAccountPersonalSocketStatus('closed');
        closeAccountPersonalSocket();
      });
    }
  };

  const getAccountPersonalSocket = () => {
    return socketRef.current;
  };

  const closeAccountPersonalSocket = () => {
    logsOn &&
      console.log('AccountPersonal socket, closing Account Personal socket...');

    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      setAccountPersonalSocketStatus('closed');
    }
  };

  return (
    <AccountPersonalSocketContext.Provider
      value={{
        connectAccountPersonalSocket,
        getAccountPersonalSocket,
        closeAccountPersonalSocket,
        accountPersonalSocketStatus,
      }}
    >
      {children}
    </AccountPersonalSocketContext.Provider>
  );
};

export const useAccountPersonalSocket = () => {
  return useContext(AccountPersonalSocketContext);
};

import { useState, useEffect, useRef } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
//Components
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//Utility
import { img_country } from '../../../../../../assets/icons';
import GetFlag from '../../../../../../utils/images/GetFlag';

const EventSearchOnline = ({
  Account,
  saveAccountChange,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const {
    accountEventSearchOnlineCountries: loadEventSearchOnlineCountries,
    accountAttendee,
  } = useAccountReduxHandlers();

  //language
  const { IconAlts } = useLanguageComponents();
  const { CountriesOpts } = useLanguageData();

  //Component state
  const [eventSearchOnlineCountries, setEventSearchOnlineCountries] = useState(
    loadEventSearchOnlineCountries
  );
  const [
    eventSearchOnlineCountriesSaveValid,
    setEventSearchOnlineCountriesSaveValid,
  ] = useState(false);

  //Component variables
  const initializeEffectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (initializeEffectRan.current === false) {
      setEventSearchOnlineCountries(loadEventSearchOnlineCountries);

      initializeEffectRan.current = true;
    }
  }, [loadEventSearchOnlineCountries]);

  //Component functions

  useEffect(() => {
    const sameContents =
      eventSearchOnlineCountries.length ===
        loadEventSearchOnlineCountries.length &&
      eventSearchOnlineCountries.every(
        (value, index) => value === loadEventSearchOnlineCountries[index]
      );
    if (!sameContents && !eventSearchOnlineCountries.includes('select')) {
      setEventSearchOnlineCountriesSaveValid(true);
    } else {
      setEventSearchOnlineCountriesSaveValid(false);
    }
  }, [eventSearchOnlineCountries, loadEventSearchOnlineCountries]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="eventSearchOnlineLabel"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={img_country}
            alt={IconAlts.iconFlag}
            style={{ height: '26px' }}
            className="mrg-r12 filter-theme"
          />

          <p>{Account.attendee.eventLimitOnlineSearches}</p>
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.eventSearchOnlineDescription}
      </p>

      <div className="h24" />

      <SelectionsModal
        addButtonText={Account.attendee.addCountries}
        DataOpts={CountriesOpts}
        fullWidth={true}
        iconModal={img_country}
        iconModalStyle={{ height: '24px' }}
        iconModalClass={'filter-theme'}
        iconOff={true}
        modalTitle={Account.attendee.eventLimitOnlineSearches}
        selectionState={eventSearchOnlineCountries}
        setSelectionState={setEventSearchOnlineCountries}
        subTextOn={false}
        id={'locationCountrySelections'}
        itemButtonCustomWrapper={'item-button-full'}
        listItemIconsFunction={GetFlag}
      />

      <div className="h24" />

      <SaveButton
        saveValid={eventSearchOnlineCountriesSaveValid}
        handler={() =>
          saveAccountChange(
            {
              attendee: {
                ...accountAttendee,
                eventSearchOnlineCountries,
              },
            },
            'eventSearchOnline'
          )
        }
        saveAriaLanguage={Account.attendee.eventSearchOnline}
        isLoading={loadingSaveBtnUI === 'eventSearchOnline'}
      />
      <div className="h36" />
    </div>
  );
};

export default EventSearchOnline;

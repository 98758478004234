import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../../account/useAccountHandlers';
import { useGenericModal } from '../../../../../../context/GenericModalProvider';
import { useSettings } from '../../../../../../context/SettingsProvider';
import { useAccountCommunityHandlers } from '../../../../account/useAccountCommunityHandlers';
import { useNoticeHandlers } from '../../../useNoticeHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import ButtonTextSimple from '../../../../../../components/ButtonTextSimple/ButtonTextSimple';
import Spinner from '../../../../../../components/Spinner/Spinner';
import ProfilePersonalModal from '../../../../../../components/Modal/ModalComponents/ProfileModals/ProfilePersonalModal';

//utils
import {
  cancel,
  checkmark,
  trash,
  visible,
} from '../../../../../../assets/icons';
import ButtonBlock from '../../../../../../components/ButtonBlock/ButtonBlock';

const NoticeContactRequest = ({ n, openedNotice, setOpenedNotice }) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { setGenericModal } = useGenericModal();
  const { setOpenMobileNotificationsMenu, handleMobileTap } = useSettings();
  const { handleAcceptContactRequest, acceptContactRequestIsLoading } =
    useAccountCommunityHandlers();
  const {
    handleRemoveNotice,
    handleIgnoreRequestSpeakerContact,
    removeNoticeIsLoading,
    ignoreRequestSpeakerContactIsLoading,
  } = useNoticeHandlers();

  //language
  const { IconAlts, NoticeSpeakerComponents } = useLanguageComponents();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [highlightViewProfileBtnTap, setHighlightViewProfileBtnTap] =
    useState(false);

  useEffect(() => {
    if (
      acceptContactRequestIsLoading ||
      removeNoticeIsLoading ||
      ignoreRequestSpeakerContactIsLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    acceptContactRequestIsLoading,
    removeNoticeIsLoading,
    ignoreRequestSpeakerContactIsLoading,
  ]);

  function acceptRequest() {
    if (!isLoading) {
      handleAcceptContactRequest(accountId, n._id);
      setOpenedNotice();
    }
  }

  function ignoreRequest() {
    if (!isLoading) {
      handleIgnoreRequestSpeakerContact({ noticeId: n._id });
      setOpenedNotice();
    }
  }

  function viewProfile() {
    setOpenMobileNotificationsMenu(false);
    setGenericModal(
      <ProfilePersonalModal
        closeModalFocusId={`noticeContactRequest-${n?.from?.account?._id}`}
        profileData={n?.from?.account}
        reopenNotices={true}
      />
    );
  }

  return (
    <>
      {isLoading ? (
        <Spinner minHeight={'44px'} />
      ) : (
        <>
          {openedNotice === n._id && (
            <div className="noticeOverlay-action-btns__container full-width flex-center">
              {n?.noticeHistory && (
                <>
                  <div className="divider-dotted " />
                  {n?.noticeHistory === 'accepted' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.accepted}
                    </p>
                  )}
                  {n?.noticeHistory === 'ignored' && (
                    <p className="noticeHistory">
                      {NoticeSpeakerComponents.history}:{' '}
                      {NoticeSpeakerComponents.ignored}
                    </p>
                  )}
                </>
              )}

              {!n?.noticeHistory && (
                <>
                  <div className="flex-row full-width align-center space-between mrg-b24">
                    <ButtonBlock
                      icon={checkmark}
                      btnText={NoticeSpeakerComponents.accept}
                      handler={acceptRequest}
                      customWrapper={
                        'fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard mrg-r12'
                      }
                      iconAlt={IconAlts.iconCheckmark}
                      iconCustomStyle={{ height: '21px' }}
                      iconCustomClass={'mrg-t6'}
                    />

                    <ButtonBlock
                      icon={cancel}
                      btnText={NoticeSpeakerComponents.ignore}
                      handler={ignoreRequest}
                      customWrapper={
                        'fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard'
                      }
                      tapHighlight={'highlight-it-theme--tap'}
                      iconAlt={IconAlts.iconCancel}
                      iconCustomStyle={{ height: '21px' }}
                      iconCustomClass={'mrg-t6'}
                    />
                  </div>

                  <ButtonBlock
                    icon={visible}
                    btnText={NoticeSpeakerComponents.viewProfile}
                    handler={viewProfile}
                    customWrapper={
                      'flex-row fs18 color-gray fwsb highlight-i-lgt highlight-t-theme br--standard align-center flex-center'
                    }
                    iconCustomStyle={{ height: '18px' }}
                    iconAlt={IconAlts.iconView}
                    iconAltPosition={true}
                    iconCustomClass={'mrg-r12'}
                  />
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NoticeContactRequest;

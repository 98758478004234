//language
import useLanguageComponents from '../../language/useLanguageComponents';

const Spinner = ({
  maxWidth,
  minHeight,
  minWidth,
  maxHeight,
  ariaLabel,
  scale,
  whiteDots,
  customClassName,
  smallDots,
}) => {
  //language
  const { Spinner } = useLanguageComponents();

  return (
    <div
      className={`flex-column flex-center ${customClassName}`}
      style={{
        maxWidth: `${maxWidth}`,
        minHeight: `${minHeight}`,
        minWidth: `${minWidth}`,
        maxHeight: `${maxHeight}`,
        transform: `scale(${scale})`,
      }}
      aria-label={ariaLabel ? `${ariaLabel}` : `${Spinner.loading}`}
    >
      <div
        className={`loading-dots loading-dots--regular ${
          whiteDots ? 'loading-dots--white' : 'loading-dots--pink'
        } ${smallDots ? 'loading-dots--small' : ''}`}
      >
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Spinner;

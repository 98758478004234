import { useEffect, useRef, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useApiStatus } from '../../../../../../context/ApiStatusProvider';
import { useTranslationsHandlers } from '../../../useTranslationsHandlers';

//language
import useLanguageOrganize from '../../../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';
import TextArea from '../../../../../../components/TextArea/TextArea';
import ButtonSwitch from '../../../../../../components/ButtonSwitch/ButtonSwitch';
import PasswordInput from '../../../../../../components/TextArea/PasswordInput';
import ParticipantListItem from '../../../../organize/eventSections/ParticipantSections/ParticipantListItem/ParticipantListItem';
import ButtonToggle from '../../../../../../components/ButtonSwitch/ButtonToggle';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';
import TranslationsSpeaker from './TranslationsSpeaker';
import TranslationsDraftsDispatchesTable from './TranslationsDraftsDispatchesTable';

//utility
import { image_draft } from '../../../../../../assets/images';
import { img_language, save } from '../../../../../../assets/icons';
import ScrollTo from '../../../../../../utils/UI/ScrollTo';

const TranslationsOrganizeDrafts = () => {
  const { handleWarning, handleError } = useApiStatus();
  const {
    handleCreatePageChange,
    selectedSaveDraftId,
    setSelectedSaveDraftId,
    savedTranslationsRoomData,
  } = useTranslationRoomSocket();
  const {
    handleSaveTranslationsDraft,
    checkValidParticipantLanguageSelection,
    handleTranslationsParticipantValidation,
  } = useTranslationsHandlers();

  //language hooks
  const { TranslationDrafts, TranslationRoomCreateRoomLanguages } =
    useLanguageTranslationRoom();
  const { TranslationLanguageOpts } = useLanguageData();
  const { CreateEvent } = useLanguageOrganize();

  //state
  const [isLoadedDraft, setIsLoadedDraft] = useState(false);
  const [loadedDraftId, setLoadedDraftId] = useState();

  const [saveName, setSaveName] = useState('');
  const [allLanguagesActive, setAllLanguagesActive] = useState(false);
  const [roomLanguages, setRoomLanguages] = useState([]);
  const [
    translationsJoinRoomPasswordToggle,
    setTranslationsJoinRoomPasswordToggle,
  ] = useState(false);
  const [attendeePassword, setAttendeePassword] = useState();
  const [participants, setParticipants] = useState([]);

  //UI
  const [isInitialized, setIsInitialized] = useState(false);
  const [saveDraftIsLoading, setSaveDraftIsLoading] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [showParticipantData, setShowParticipantData] = useState(false);

  const initializeDraftDataEffectRan = useRef(false);

  useEffect(() => {
    if (initializeDraftDataEffectRan.current === false) {
      initializeDraftDataEffectRan.current = true;

      savedTranslationsRoomData?.translationsSessions?.map((session) => {
        if (session._id === selectedSaveDraftId) {
          setSaveName(session.meta.saveName);
          setLoadedDraftId(session._id);

          const availableLanguagesTotalNum =
            Object.values(TranslationLanguageOpts)?.length - 1;
          if (
            session.translations.roomLanguages?.length ===
            availableLanguagesTotalNum
          ) {
            setAllLanguagesActive(true);
          }
          setRoomLanguages(session.translations.roomLanguages);
          setTranslationsJoinRoomPasswordToggle(
            session.translations.joinRoomPasswordToggle
          );
          setAttendeePassword(session.translations.password);
          setParticipants(session.participants);

          setIsLoadedDraft(true);
        }
      });
      setSelectedSaveDraftId();
    }
  }, [selectedSaveDraftId]); //load draft data if page loaded with a session draft

  //UI
  useEffect(() => {
    if (imgLoaded) {
      let initializeTimer = setTimeout(() => {
        setIsInitialized(true);
      }, 500);

      return () => clearTimeout(initializeTimer);
    }
  }, [imgLoaded]); //initialization for loading UI + delay useEffect that wipes loaded participants

  useEffect(() => {
    if (isInitialized) {
      if (roomLanguages?.length >= 2 || allLanguagesActive) {
        let newParticipants = JSON.parse(JSON.stringify(participants));

        for (let participant of newParticipants) {
          participant.translationsRoomRequiresLanguage = false;
        }
        setParticipants(newParticipants);
      } else if (roomLanguages?.length < 2 && !allLanguagesActive) {
        let newParticipants = JSON.parse(JSON.stringify(participants));

        for (let participant of newParticipants) {
          participant.translationsRoomRequiresLanguage = true;

          if (participant?.dispatches?.translationsStatus === 'dispatching') {
            participant.dispatches.translationsStatus = 'notDispatched';
          }
        }

        setParticipants(newParticipants);
      }
    }
  }, [roomLanguages, allLanguagesActive]); //adjusts warning for requiring room languages

  //API
  async function saveDraft() {
    if (!saveDraftIsLoading) {
      setSaveDraftIsLoading(true);
      try {
        const success = await handleSaveTranslationsDraft({
          saveName,
          participants,
          translationsJoinRoomPasswordToggle,
          attendeePassword,
          roomLanguages,
          isLoadedDraft,
          loadedDraftId,
        });
        setSaveDraftIsLoading(false);

        if (success) {
          handleCreatePageChange('drafts');
        }
      } catch (error) {
        handleError({
          id: Date.now(),
          origin: 'TranslationsOrganizeDrafts/saveDraft',
          error,
        });
        return setSaveDraftIsLoading(false);
      }
    }
  }

  //Functions
  function handleRoomLanguages(allLanguages) {
    if (allLanguages) {
      let newRoomLanguages = [];
      const languageKeys = Object.keys(TranslationLanguageOpts);
      newRoomLanguages = languageKeys.filter((opt) => opt !== 'select');
      setRoomLanguages(newRoomLanguages);
      setAllLanguagesActive(true);
    } else {
      setAllLanguagesActive(false);
      setRoomLanguages([]);
    }
  }

  function handleSelectedParticipant(participantId) {
    const newSelectedParticipant = participants.find(
      (p) => participantId === p?.id
    );
    setSelectedParticipant(newSelectedParticipant);
    setShowParticipantData(true);

    const delay = setTimeout(() => {
      ScrollTo({
        focusId: 'translationsParticipantsLabel',
        behavior: 'smooth',
        scrollToTop: false,
        scrollToElementId: 'translationsParticipantsLabel',
      });
    }, 250);

    return () => clearTimeout(delay);
  }

  function checkTranslationRoomLanguages() {
    if (roomLanguages?.length === 0 || roomLanguages.includes('select')) {
      return true;
    } else {
      return false;
    }
  }

  function handleAddParticipant(selectionType) {
    if (participants?.length >= 10) {
      return handleWarning({
        message: 'limitReached',
        origin: 'TranslationsOrganizeDrafts.js/handleAddParticipant',
        id: Date.now(),
      });
    }

    let newParticipants = [];
    if (participants?.length > 0) {
      newParticipants = [...participants];
    }

    let newId = Math.floor(Math.random() * 9000000000) + 1000000000;
    let newIndex = participants?.length;
    const requiresRoomLanguageStatus = checkTranslationRoomLanguages();

    let newParticipant = {
      id: newId,
      index: newIndex,
      organizedAs: selectionType,
      completionStatus: 'incomplete',
      translationsCompletionStatus: 'incomplete',
      translationsLanguageConflict: false,
      translationsRoomRequiresLanguage: requiresRoomLanguageStatus,
      translationsSpeakerData: {
        name: '',
        keywordsList: [],
        languageOpt: 'select',
        voiceOpt: 'select',
        discussionContext: '',
      },
    };

    if (selectionType === 'emailInvitation') {
      newParticipant = {
        ...newParticipant,
        dispatches: {
          translationsStatus: 'notDispatched',
          translationsDispatched: false,
        },
        emailInvitation: {
          email: '',
          emailLanguage: 'select',
          emailValid: false,
          duplicateEmail: false,
          token: '',
          tokenExpires: '',
        },
      };
    }

    newParticipants.push(newParticipant);
    setParticipants(newParticipants);
  }

  function handleSaveAndReturn(participantData) {
    //only update relevant data to avoid stale data issues when data was loaded (dispatches/translations can change)
    const foundParticipant = participants.find(
      (p) => p.id === participantData.id
    );
    let newParticipant = JSON.parse(JSON.stringify(foundParticipant));
    newParticipant.translationsSpeakerData =
      participantData.translationsSpeakerData;

    newParticipant.completionStatus = participantData.completionStatus;

    if (newParticipant.organizedAs === 'emailInvitation') {
      newParticipant.emailInvitation = participantData.emailInvitation;
    }

    let newParticipants = [];

    for (let p of participants) {
      if (p.id === newParticipant.id) {
        //check for language conflict
        const hasLanguageConflict = checkValidParticipantLanguageSelection(
          newParticipant,
          roomLanguages
        );
        newParticipant.translationsLanguageConflict = hasLanguageConflict;

        //check for completion
        const isComplete =
          handleTranslationsParticipantValidation(newParticipant);

        newParticipant.translationsCompletionStatus = isComplete
          ? 'complete'
          : 'incomplete';

        if (
          !isComplete &&
          newParticipant.organizedAs === 'emailInvitation' &&
          newParticipant.dispatches.translationsStatus === 'dispatching'
        ) {
          newParticipant.dispatches.translationsStatus = 'notDispatched';
        }

        //save
        newParticipants.push(newParticipant);
      } else {
        newParticipants.push(p);
      }
    }

    setParticipants(newParticipants);

    //clean up
    setSelectedParticipant();
    setShowParticipantData(false);
  }

  function handleRemoveSpeaker(participantId) {
    let newParticipants = participants?.filter((p) => p?.id !== participantId);

    newParticipants = newParticipants?.map((p, index) => {
      let newP = { ...p, participantData: { ...p.participantData } };
      newP.index = index;
      return newP;
    });
    setParticipants(newParticipants);
    setSelectedParticipant();
    setShowParticipantData(false);
  }

  //dispatches
  function handleTranslationsReadyDispatch(_, val, participantId) {
    let newParticipants = [];
    let newP;

    participants?.map((p) => {
      if (p?.id === participantId) {
        newP = JSON.parse(JSON.stringify(p));
        if (val) {
          newP.dispatches.translationsStatus = 'dispatching';
        } else {
          newP.dispatches.translationsStatus = 'notDispatched';
        }
        newParticipants.push(newP);
      } else {
        newParticipants.push(p);
      }
    });

    setParticipants(newParticipants);
  }

  return (
    <>
      <div className="translations__container translations__container--no-box-shadow">
        <img
          src={image_draft}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-create-draft__wrapper">
          <div className="h24" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="generalLabel"
          >
            {TranslationRoomCreateRoomLanguages.general}
          </label>
          <TextArea
            handleChange={setSaveName}
            limit={100}
            id={'saveName'}
            labelText={TranslationDrafts.draftSaveName}
            disableEnter={true}
            loadValue={saveName}
            loadTrigger={true}
            customWrapper={'mrg-t24 full-width'}
            asterisk={true}
            onBlurHandlerOff={true}
          />
          <div className="h96 h72--ph" />

          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="translationRoomLanguagesLabel"
          >
            {TranslationRoomCreateRoomLanguages.roomLanguages}
          </label>
          <div className="h24" />
          <ButtonToggle
            textLeft={TranslationRoomCreateRoomLanguages.manual}
            textRight={TranslationRoomCreateRoomLanguages.allAvailableLanguages}
            handleSwitch={handleRoomLanguages}
            loadValue={allLanguagesActive}
            loadTrigger={isLoadedDraft}
            customWrapper={'flex-center'}
          />
          {!allLanguagesActive && (
            <SelectionsModal
              addButtonText={TranslationRoomCreateRoomLanguages.addLanguages}
              DataOpts={TranslationLanguageOpts}
              iconModal={img_language}
              iconModalClass={'filter-theme'}
              iconOff={true}
              modalTitle={TranslationRoomCreateRoomLanguages.roomLanguages}
              selectionState={roomLanguages}
              setSelectionState={setRoomLanguages}
              subTextOn={false}
              id={'roomLanguages'}
              itemButtonCustomWrapper={'full-width mrg-t24'}
            />
          )}

          <div className="h96 h72--ph" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="attendeePasswordLabel"
          >
            {TranslationDrafts.attendeePassword}
          </label>
          <div className="h24" />

          <ButtonSwitch
            id={'attendeePasswordToggle'}
            title={TranslationDrafts.attendeesRequirePassword}
            handleSwitch={setTranslationsJoinRoomPasswordToggle}
            loadValue={translationsJoinRoomPasswordToggle}
            loadTrigger={isLoadedDraft}
            horizontal={true}
          />

          {translationsJoinRoomPasswordToggle && (
            <PasswordInput
              defaultValue={attendeePassword}
              handleInput={setAttendeePassword}
              preventDefaultOff={true}
              id={'attendeePasswordInput'}
              customTitle={TranslationDrafts.password}
              customKeyDownHandler={(e) => {
                if (e.key === 'Enter') {
                  setAttendeePassword(e.target.value);
                }
              }}
              customWrapper={'mrg-t24'}
            />
          )}
          <div className="h96 h72--ph" />
          <label
            className="content-heading-styled text-gray flex-row"
            tabIndex="0"
            id="translationsParticipantsLabel"
          >
            {CreateEvent.eventTranslations.participants}
          </label>

          {!showParticipantData && (
            <div className="flex-row flex-column--ph full-width space-evenly flex-center--ph mrg-t24 mrg-b12">
              <ItemButton
                text={TranslationDrafts.createManually}
                handler={() => handleAddParticipant('create')}
                fullWidth={true}
                customWrapper={'mrg-r24 mrg-r0--ph mrg-b24--ph'}
              />
              <ItemButton
                text={TranslationDrafts.emailInvite}
                handler={() => handleAddParticipant('emailInvitation')}
                fullWidth={true}
              />
            </div>
          )}

          {selectedParticipant && showParticipantData ? (
            <>
              <div className="h24" />
              {selectedParticipant && (
                <TranslationsSpeaker
                  prevSpeaker={selectedParticipant}
                  handleSaveAndReturn={handleSaveAndReturn}
                  roomLanguages={roomLanguages}
                  speakerNameOn={true}
                  displaySpeakerName={false}
                  handleRemoveSpeaker={handleRemoveSpeaker}
                  actionButtonsOff={
                    selectedParticipant.organizedAs === 'emailInvitation' &&
                    !selectedParticipant.dispatches.translationsDispatched
                      ? true
                      : false
                  }
                />
              )}
            </>
          ) : (
            <>
              {!isInitialized && <Spinner />}
              <ul
                className={`community__container ${
                  !isInitialized ? 'remove' : ''
                }`}
              >
                {participants?.length > 0 && (
                  <>
                    {participants?.map((participant, index) => {
                      return (
                        <li key={`communityContact-${index}`}>
                          <ParticipantListItem
                            participant={participant}
                            handler={handleSelectedParticipant}
                            customWrapper={'mrg-t12'}
                            listItemMode={'translations'}
                            nameInput={true}
                            translationsNameOnly={true}
                            borderBottomOff={true}
                          />
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>

              {participants.some(
                (participantData) =>
                  participantData.organizedAs === 'emailInvitation'
              ) && (
                <>
                  <div className="h36" />
                  <label
                    className="content-heading-styled text-gray flex-row"
                    tabIndex="0"
                    id="dispatchesLabel"
                  >
                    {TranslationDrafts.dispatches}
                  </label>
                  <div className="h24" />

                  <>
                    <div className="banners__notice mrg-b24 fs16">
                      {TranslationDrafts.dispatchesDescription}
                    </div>

                    <TranslationsDraftsDispatchesTable
                      participants={participants}
                      handleTranslationsReadyDispatch={
                        handleTranslationsReadyDispatch
                      }
                    />
                  </>
                </>
              )}
            </>
          )}

          <div className="h96 h72--ph" />
          <div className="divider full-width mrg-tb36" />
          <ItemButton
            text={TranslationDrafts.saveAndExit}
            handler={saveDraft}
            fullWidth={true}
            customWrapper={'mrg-b24'}
            iconOn={true}
            icon={save}
            iconStyle={{ height: '21px' }}
            unavailable={!saveName.trim() || selectedParticipant}
            isLoading={saveDraftIsLoading}
          />
          <ItemButton
            text={TranslationDrafts.back}
            handler={() => handleCreatePageChange('drafts')}
            fullWidth={true}
            unavailable={saveDraftIsLoading}
          />
        </div>
      </div>
    </>
  );
};

export default TranslationsOrganizeDrafts;

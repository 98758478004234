//hooks

//components
import { useSettings } from '../../../../context/SettingsProvider';
import MyndFullPattern from '../../../../layout/shared/MyndFullPattern/MyndFullPattern';
import LoginDesktop from './LoginDesktop';
import LoginMobile from './LoginMobile';

const Login = () => {
  const { width } = useSettings();

  return (
    <div className="layout">
      <MyndFullPattern patternActive={true} key={'myndfullPattern-login'} />

      <div className="login-layout flex-center min-width-full min-full-width full-height full-width align-center flex-row">
        {width > 1250 ? <LoginDesktop /> : <LoginMobile />}
      </div>
    </div>
  );
};
export default Login;

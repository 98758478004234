import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

//hooks
import { useAccountReduxHandlers } from '../../../features/main/account/useAccountHandlers';
import { useOrganizeHandlers } from '../../../features/main/organize/useOrganizeHandlers';

const OrganizeEventsSocketContext = createContext({});

export const OrganizeEventsSocketProvider = ({ children }) => {
  // Hooks
  const { accountId } = useAccountReduxHandlers();
  const { handleSocketOrganizedEventData } = useOrganizeHandlers();

  const [organizeEventsSocketStatus, setOrganizeEventsSocketStatus] =
    useState('closed');

  // Refs
  const socketRef = useRef(null);

  //variables
  const logsOn = false;

  const ioOrganizeEventsSocketURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/organizeEvents'
      : 'https://myndfull.com/ws/organizeEvents';
  const withCredentials =
    process.env.REACT_APP_ENV === 'development' ? false : true;

  const connectOrganizeEventsSocket = () => {
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    if (!socketRef.current && organizeEventsSocketStatus !== 'connected') {
      setOrganizeEventsSocketStatus('initializing');
      let newSocket;

      newSocket = io(ioOrganizeEventsSocketURL, {
        query: {
          accountId,
        },
        reconnectionAttempts: 10,
        withCredentials: withCredentials,
      });

      newSocket.on('connect', () => {
        logsOn &&
          console.log(
            `Connected to OrganizeEvents WebSocket, id: ${newSocket.id}`
          );
        setOrganizeEventsSocketStatus('connected');
      });

      newSocket.on('organizeEventsData', (organizeEventsData) => {
        handleSocketOrganizedEventData(organizeEventsData);
      });

      newSocket.on('disconnect', (reason, details) => {
        logsOn &&
          console.error(
            `Organize Events socket disconnect, Reason: ${reason}, Details: ${details}`
          );
        setOrganizeEventsSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn &&
            console.error(
              'Organize Events socket disconnect, no automatic reconnect'
            );
          closeOrganizeEventsSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn &&
            console.error(
              'Organize Events socket disconnect, automatic reconnect'
            );
        } else {
          logsOn &&
            console.error(
              `Organize Events socket disconnect, Unexpected disconnect reason: ${reason}`
            );
          closeOrganizeEventsSocket();
        }
      });

      newSocket.on('error', (error) => {
        logsOn && console.error('Organize Events socket, socket error:', error);
        setOrganizeEventsSocketStatus('closed');
        closeOrganizeEventsSocket();
      });

      return (socketRef.current = newSocket);
    }
  };

  const getOrganizeEventsSocket = () => {
    return socketRef.current;
  };

  const closeOrganizeEventsSocket = () => {
    const socket = socketRef.current;

    if (socket) {
      socket.close();
      socketRef.current = null;
      setOrganizeEventsSocketStatus('closed');
    }
  };

  return (
    <OrganizeEventsSocketContext.Provider
      value={{
        connectOrganizeEventsSocket,
        closeOrganizeEventsSocket,
        getOrganizeEventsSocket,
        organizeEventsSocketStatus,
      }}
    >
      {children}
    </OrganizeEventsSocketContext.Provider>
  );
};

export const useOrganizeEventsSocket = () => {
  return useContext(OrganizeEventsSocketContext);
};

import { v4 as uuidv4 } from 'uuid';

//hooks
import { useApiStatus } from '../../context/ApiStatusProvider';

const useLanguageNotListedHandlers = () => {
  const { handleWarning } = useApiStatus();

  function handleAddNotListedLanguage({
    currentLanguagesNotListed,
    handleSetLanguagesNotListed,
  }) {
    if (currentLanguagesNotListed?.length > 5)
      return handleWarning({
        message: 'limitReached',
        origin: 'FindSpeakersQueryFilters/addNotListedLanguage',
        id: Date.now(),
      });

    let newArr = [...currentLanguagesNotListed];
    newArr.push({ id: uuidv4(), language: '' });
    handleSetLanguagesNotListed(newArr);
  }

  function handleRemoveNotListedLanguage({
    id,
    currentLanguagesNotListed,
    handleSetLanguagesNotListed,
    currentLanguages,
    handleSetLanguages,
  }) {
    let newNotListedLanguages = currentLanguagesNotListed.filter(
      (lang) => lang.id !== id
    );
    handleSetLanguagesNotListed(newNotListedLanguages);

    if (newNotListedLanguages?.length === 0) {
      let newArr = [...currentLanguages];

      newArr = newArr.filter((lang) => lang !== 'notListed');
      handleSetLanguages(newArr);
    }
  }

  function handleNotListedLanguages({
    text,
    langId,
    currentLanguagesNotListed,
    handleSetLanguagesNotListed,
  }) {
    let newNotListedLanguages = currentLanguagesNotListed?.map((lang) => {
      if (lang.id === langId) {
        return { ...lang, language: text?.trim() };
      }
      return lang;
    });
    handleSetLanguagesNotListed(newNotListedLanguages);
  }

  return {
    handleAddNotListedLanguage,
    handleRemoveNotListedLanguage,
    handleNotListedLanguages,
  };
};

export default useLanguageNotListedHandlers;

import { useEffect } from 'react';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../../utils/UI/ScrollTo';

const HostSubPageZero = ({ setHostSubPage, setSubPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <>
      <h1 className="fwsb fs21 lh36">Learn as a Host</h1>
      <p className="fs18 lh28 mrg-t24">
        Hosting a multilingual event can feel daunting, but MyndFull
        Translations equips you with cutting-edge, easy-to-use tools designed to
        ensure your success. By learning how to use these tools, you'll be able
        to run multilingual sessions smoothly and help make academic and
        educational events more accessible.
      </p>

      <p className="fs18 lh28 mrg-t24">
        Please explore the tutorial modules below, and if you have any
        questions, feel free to contact us at{' '}
        <span className="color-blue">support@myndfull.com</span>.
      </p>

      <div className="translations-selections mrg-auto-lr mrg-t24">
        <ItemButton
          text={'1. Room Code Subscriptions'}
          handler={() => setHostSubPage(1)}
          customWrapper={'item-button-standard mrg-b36 mrg-t12 fs18'}
        />
        <ItemButton
          text={'2. Creating a Session'}
          handler={() => setHostSubPage(2)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />
        <ItemButton
          text={'3. Running a Session'}
          handler={() => setHostSubPage(3)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />
        <ItemButton
          text={'4. Reviewing a Session'}
          handler={() => setHostSubPage(4)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />
        <ItemButton
          text={'Return'}
          handler={() => setSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </>
  );
};

export default HostSubPageZero;

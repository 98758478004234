//language
import useLanguageComponents from '../../language/useLanguageComponents';

//utils
import ScrollToComponent from '../ScrollToComponent/ScrollToComponent';

const ValidationsList = ({ validations, customWrapper }) => {
  //language
  const { ValidationsList } = useLanguageComponents();

  return (
    <div
      className={`validations__wrapper ${customWrapper ? customWrapper : ''}`}
    >
      <div className="validations-title fs16 fwsb color-red ">
        <p className="access-ob  access-o6" tabIndex="0">
          {ValidationsList.fieldsThatRequireCompletion}
        </p>
      </div>
      <ul className="scrollToComponent__container mrg-t24">
        {validations?.map((item, index) => {
          const validationLabel = Object.values(item);
          const element = Object.keys(item);
          return (
            <div key={`scrollToComponent-${index}`}>
              <ScrollToComponent
                text={validationLabel[0]}
                elementId={element[0]}
              />
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default ValidationsList;

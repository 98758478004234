import { useEffect, useState } from 'react';

//hooks
import useLanguageAdmin from '../../../../../../language/features/useLanguageAdmin';

//components

//utils
import { dropdown_arrow } from '../../../../../../assets/icons';
import { getRandomColor } from '../../../../../../utils/UI/randomColorCodes';
import { getDateOrTimeFromUTZstring } from '../../../../../../utils/dateTime/DateCalc';

const InfoCardContainerEvents = ({ accountEventsData }) => {
  //hooks
  const { AccountEvents } = useLanguageAdmin();
  //state
  const [toggleArrOpen, setToggleArrOpen] = useState([]);

  //UI
  const [handleColors, setHandleColors] = useState([]);

  //variables
  const eventsDataArray = Object.entries(accountEventsData);

  //initialize
  useEffect(() => {
    if (accountEventsData) {
      let newToggleArr = [];
      const eventKeysArr = Object.keys(accountEventsData);
      eventKeysArr?.map((key) => {
        const obj = { [key]: false };
        newToggleArr.push(obj);
      });
      setToggleArrOpen(newToggleArr);

      //colors
      let colorArr = [];
      eventsDataArray?.map((events) => {
        let colorArrNested = [];
        events[1].forEach((ev) => colorArrNested.push(getRandomColor()));
        colorArr.push(colorArrNested);
      });
      setHandleColors(colorArr);
    }
  }, [accountEventsData]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Events</p>

      {eventsDataArray?.map(([eventsKey, eventsArr], index) => {
        if (eventsKey === 'draftEvents') return null;

        return (
          <div key={`accountEvents-${eventsKey}`} className="flex-column">
            <div className="mrg-tb6">
              <div className="flex-row">
                <p className="fs18 fwsb">
                  {`${AccountEvents[eventsKey]}`}: ({eventsArr?.length})
                </p>
                {eventsArr?.length > 0 && (
                  <button
                    className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
                    style={{ height: '24px' }}
                    onClick={() => {
                      const updatedToggleArr = toggleArrOpen?.map((item) => {
                        const key = Object.keys(item)[0];
                        return {
                          [key]: key === eventsKey ? !item[key] : item[key],
                        };
                      });
                      setToggleArrOpen(updatedToggleArr);
                    }}
                  >
                    <img
                      src={dropdown_arrow}
                      alt="arrow"
                      style={{ height: '10px' }}
                      className={`${
                        toggleArrOpen.find(
                          (item) => Object.keys(item)[0] === eventsKey
                        )?.[eventsKey] === false
                          ? 'reverse-vertical'
                          : ''
                      }`}
                    />
                  </button>
                )}
              </div>
              {toggleArrOpen.find(
                (item) => Object.keys(item)[0] === eventsKey
              )?.[eventsKey] === true && (
                <ul className="info-card-list">
                  {eventsArr?.map((event, index2) => {
                    const colors = handleColors[index];

                    return (
                      <li
                        className="mrg-tb6"
                        key={`${eventsKey}-${event.meta?.eventReference}`}
                      >
                        <div
                          className="list-handle"
                          style={{
                            backgroundColor: colors[index2],
                          }}
                        />
                        <p>Title: {event.generic?.title},</p>
                        <p>
                          Event Reference: &nbsp;[{event.meta?.eventReference}]
                        </p>
                        <p>
                          Created:&nbsp;
                          {`${getDateOrTimeFromUTZstring(
                            event.meta?.createdAt,
                            'date'
                          )}`}
                        </p>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InfoCardContainerEvents;

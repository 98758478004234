import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettings } from '../../../../context/SettingsProvider';
import { useEventHandlers } from '../useEventHandlers';

//components
import EventPage from './EventPage';

const StandaloneEventPage = () => {
  //hooks
  const { eventId } = useParams();
  const { accountId } = useAccountReduxHandlers();
  const { setNavbar } = useSettings();
  const navigate = useNavigate();
  const { handleGetEvent, handleGetEventWithAccount } = useEventHandlers();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [eventData, setEventData] = useState();

  //var
  const initializeGetEventEffectRan = useRef(false);

  useEffect(() => {
    if (initializeGetEventEffectRan.current === false && eventId && accountId) {
      initializeGetEventEffectRan.current = true;
      setNavbar('');

      if (eventId && accountId) {
        handleGetEventWithAccount({
          accountId,
          eventId,
          setEventData,
          returnEventData: false,
        });
      } else if (eventId && !accountId) {
        handleGetEvent({ setEventData, setIsLoading, isLoading, eventId });
      }
    }
  }, [eventId, accountId]);

  return (
    <div className="flex-center mrg-auto-lr full-width">
      {eventData && (
        <EventPage
          previewEventData={eventData}
          participantPreviewOn={true}
          handleCustomReturn={() => {
            navigate('/');
          }}
        />
      )}
    </div>
  );
};

export default StandaloneEventPage;

//Hooks
import { useEffect, useState } from 'react';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import { useSettings } from '../../../context/SettingsProvider';

//components
import LoadingScreenContent from '../../../components/LoadingScreenContent/LoadingScreenContent';

const AdminAnalytics = () => {
  //hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { setNavbar } = useSettings();

  //component state
  const [pageLoadComplete, setPageLoadComplete] = useState();

  //variables

  //Initialize
  useEffect(() => {
    setNavbar('analytics');
  }, []);

  return (
    <>
      {pageLoadComplete ? (
        <div className="admin">AdminAnalytics</div>
      ) : (
        <LoadingScreenContent
          numberOfLoadChecks={1}
          setLoadComplete={() => setPageLoadComplete(true)}
          loadCheck1={adminAccountId ? true : false}
          customWrapper={'min-full-height'}
        />
      )}
    </>
  );
};

export default AdminAnalytics;

import { useState } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useGenericModal } from '../../../../context/GenericModalProvider';
import { useSettings } from '../../../../context/SettingsProvider';

//langauge
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import ActionContainer from './ActionContainer';
import InfoContainer from './InfoContainer';
import ScrollTo from '../../../../utils/UI/ScrollTo';
import ConfirmationModal from '../../../../components/Modal/ModalComponents/ConfirmationModal';

//Utility

const OrganizeEventCard = ({
  eventData,
  setEditEvent,
  organizeType,
  handleRemoveEvent,
  index,
  setEventPreview,
}) => {
  //Hooks
  const { OrganizerEventCard: OrganizerEventCardLanguage } =
    useLanguageOrganize();
  const { setGenericModalMobileFloat } = useGenericModal();
  const { accountId } = useAccountReduxHandlers();
  const { width } = useSettings();

  //Component state
  const [actionPortView, setActionPortView] = useState();

  //UI
  const [imageLoadConfirmation, setImageLoadConfirmation] = useState(false);

  //UI Functions
  function handleActionClick(id) {
    if (id === 'virtualRegistrations') {
      if (actionPortView === 'virtualRegistrations') {
        setActionPortView();
      } else {
        setActionPortView('virtualRegistrations');
      }
    }
    if (id === 'inPersonRegistrations') {
      if (actionPortView === 'inPersonRegistrations') {
        setActionPortView();
      } else {
        setActionPortView('inPersonRegistrations');
      }
    }
    if (id === 'messages') {
      if (actionPortView === 'messages') {
        setActionPortView();
      } else {
        setActionPortView('messages');
      }
    }
    if (id === 'announcements') {
      if (actionPortView === 'announcements') {
        setActionPortView();
      } else {
        setActionPortView('announcements');
      }
    }
    if (id === 'viewEvent') {
      setEventPreview(eventData);
      setActionPortView();
    }
    if (id === 'editEvent') {
      setActionPortView();
      setEditEvent(eventData);
    }
    if (id === 'eventDashboard') {
      if (actionPortView === 'eventDashboard') {
        setActionPortView();
      } else {
        setActionPortView('eventDashboard');
      }
    }

    if (id === 'removeArchivedEvent') {
      setActionPortView('removeArchivedEvent');

      setGenericModalMobileFloat(
        <ConfirmationModal
          headingText={OrganizerEventCardLanguage.confirmArchivedEventRemoval}
          button1Text={OrganizerEventCardLanguage.remove}
          button2Text={OrganizerEventCardLanguage.cancel}
          button1Color={'red'}
          focusId={'organizeGrid'}
          handler={() => {
            setActionPortView();
            handleRemoveEvent({
              eventId: eventData?._id,
              organizerId: accountId,
              eventType: 'archived',
            });
          }}
        />
      );
    }
  }

  function handleMobileViewCloseBtn(eventId) {
    const elementId = eventId.substring(0, 8);
    setActionPortView();
    ScrollTo({
      focusId: elementId,
      behavior: 'smooth',
      scrollToTop: false,
      scrollToElementId: elementId,
    });
  }

  return (
    <>
      {eventData && (
        <div
          className={`organize-event-card__wrapper mrg-b48 access-ob access-o6 ${
            !imageLoadConfirmation && width > 480 ? 'remove' : ''
          }`}
          tabIndex="0"
          id={`${eventData?._id.substring(0, 8)}`}
        >
          <InfoContainer
            eventData={eventData}
            organizeType={organizeType}
            index={index}
            setImageLoadConfirmation={setImageLoadConfirmation}
          />
          <ActionContainer
            eventData={eventData}
            handleActionClick={handleActionClick}
            actionPortView={actionPortView}
            OrganizerEventCardLanguage={OrganizerEventCardLanguage}
            handleMobileViewCloseBtn={handleMobileViewCloseBtn}
            organizeType={organizeType}
          />
        </div>
      )}
    </>
  );
};

export default OrganizeEventCard;

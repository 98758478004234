import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useAuthReduxHandlers } from '../../features/main/auth/useAuthHandlers';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useNoticeHandlers } from '../../features/main/notices/useNoticeHandlers';

//language
import useLanguageLayout from '../../language/useLanguageLayout';

//Components
import NavLoggedOut from './NavLoggedOut';
import NavLoggedIn from './NavLoggedIn';

//Utils
import { img_bell, img_hamburger } from '../../assets/icons';

const Navbar = ({ layoutIsMounting }) => {
  //Hooks
  const {
    setOpenSlideMenu,
    openSlideMenu,
    width,
    setOpenMobileNotificationsMenu,
    openMobileNotificationsMenu,
    handleMobileTap,
    setSettingsSetViewEventDetails,
  } = useSettings();
  const { accountId, accountNotices } = useAccountReduxHandlers();
  const { authCurrentRoles } = useAuthReduxHandlers();
  const { handleSetAccountNoticesSeen } = useNoticeHandlers();

  //language
  const { NavbarLoggedOut, NavbarLoggedIn } = useLanguageLayout();

  //state
  const [componentIsMounting, setComponentIsMounting] = useState(true);
  const [authRoles, setAuthRoles] = useState([]);
  const [unseenNoticesSum, setUnseenNoticesSum] = useState(0);

  //ui
  const [highlightNotificationsIconTap, setHighlightNotificationsIconTap] =
    useState(false);
  const [highlightHamburgerIconTap, setHighlightHamburgerIconTap] =
    useState(false);

  //initialize
  useEffect(() => {
    setAuthRoles(authCurrentRoles);
  }, [authCurrentRoles]);

  useEffect(() => {
    if (accountNotices) {
      let newUnseenNotices = 0;
      accountNotices?.map((n) => {
        if (n.noticeStatus === 'unseen') {
          newUnseenNotices++;
        }
      });

      setUnseenNoticesSum(newUnseenNotices);
    }
  }, [accountNotices]);

  useEffect(() => {
    if (unseenNoticesSum && !openMobileNotificationsMenu && accountId) {
      if (unseenNoticesSum > 0) {
        const delayTimer = setTimeout(() => {
          handleSetAccountNoticesSeen();
        }, 500);

        return () => {
          clearTimeout(delayTimer);
        };
      }
    }
  }, [openMobileNotificationsMenu]);

  //UI Transitions
  useEffect(() => {
    if (!layoutIsMounting) {
      const pageAnimationTimer = setTimeout(() => {
        setComponentIsMounting(false);
      }, 100);
      return () => clearTimeout(pageAnimationTimer);
    }
  }, [layoutIsMounting]);

  return (
    <header
      className={`${
        componentIsMounting ? 'offscreen' : 'navbar onscreen-fade-in-100ms'
      }`}
      id="navbar"
      aria-label={NavbarLoggedOut.header}
    >
      {width <= 1280 ? (
        <div className="navbar__container">
          <Link
            to={'/'}
            onClick={() => {
              if (accountId && authRoles) {
                setSettingsSetViewEventDetails();
              }
            }}
            className="logo__link access-ob access-o12 pos-rel"
            aria-label={
              authRoles && accountId
                ? NavbarLoggedIn.linkToEvents
                : NavbarLoggedOut.linkToHomepage
            }
            tabIndex="0"
          >
            <div className="logo__color1 disable-select" aria-hidden="true">
              Mynd
            </div>
            <div className="logo__color2 disable-select" aria-hidden="true">
              Full
            </div>
          </Link>
          <div className="flex-row">
            {authRoles?.length > 0 && (
              <button
                className="nav-bar__notices access-ob access-o6 mrg-r36"
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setHighlightNotificationsIconTap(true),
                      () => setHighlightNotificationsIconTap(false),
                    ],
                    [
                      () =>
                        setOpenMobileNotificationsMenu(
                          !openMobileNotificationsMenu
                        ),
                    ]
                  )
                }
                aria-pressed={openMobileNotificationsMenu}
              >
                <img
                  src={img_bell}
                  alt={NavbarLoggedIn.iconBell}
                  className={`nav-bar__notices-bell  ${
                    highlightNotificationsIconTap
                      ? 'filter-white--tap'
                      : 'filter-white'
                  }`}
                />
                {unseenNoticesSum > 0 && (
                  <div
                    className={`nav-bar__notices-alert ${
                      unseenNoticesSum > 9
                        ? 'nav-bar__msg-notice-2digit'
                        : 'nav-bar__msg-notice-1digit'
                    }`}
                  >
                    {unseenNoticesSum}
                  </div>
                )}
              </button>
            )}
            <button
              className={'hamburger access-ob access-o6'}
              tabIndex="0"
              aria-label={NavbarLoggedOut.navigationMenu}
              aria-expanded={openSlideMenu}
              id="hamburger"
              onClick={() =>
                handleMobileTap(
                  [
                    () => setHighlightHamburgerIconTap(true),
                    () => setHighlightHamburgerIconTap(false),
                  ],
                  [() => setOpenSlideMenu(!openSlideMenu)]
                )
              }
            >
              <img
                src={img_hamburger}
                alt={NavbarLoggedOut.menuIcon}
                aria-hidden="true"
                className={`${
                  highlightHamburgerIconTap
                    ? 'filter-white--tap'
                    : 'filter-white'
                }`}
              />
            </button>
          </div>
        </div>
      ) : (
        <div className="navbar__container">
          <Link
            to={'/'}
            onClick={() => {
              if (accountId && authRoles) {
                setSettingsSetViewEventDetails();
              }
            }}
            className="logo__link access-ob access-o12"
            aria-label={
              authRoles && accountId
                ? NavbarLoggedIn.linkToEvents
                : NavbarLoggedOut.linkToHomepage
            }
            tabIndex="0"
          >
            <div className="logo__color1 disable-select" aria-hidden="true">
              Mynd
            </div>
            <div className="logo__color2 disable-select" aria-hidden="true">
              Full
            </div>
          </Link>
          {authRoles ? (
            <NavLoggedIn currentRoles={authRoles} />
          ) : (
            <NavLoggedOut />
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;

import { useEffect, useRef, useState } from 'react';

//language
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import TextArea from '../../../../../components/TextArea/TextArea';
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import RadialButtonList from '../../../../../components/RadialButtonList/RadialButtonList';
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';
import CheckBoxBoolean from '../../../../../components/CheckBoxes/CheckBoxBoolean';

//utils
import { cancel } from '../../../../../assets/icons';

const EventAdditionalImages = ({
  initialize,
  pauseCrop,
  handleEventAdditionalChange,
  reduxEventImgObj,
  eventAdditionalImagesCropped,
  setEventAdditionalImagesCropped,
  handleRemove,
  handleEventAdditionalImageFileChangeTracking,
  id,
}) => {
  //language
  const { EventAdditionalImages } = useLanguageOrganize();
  const { ImageAltRadialSelections } = useLanguageData();

  //ui
  const [isLoading, setIsLoading] = useState(true);
  const [cropperImg, setCropperImg] = useState();

  //variable
  const loadImageIntoCropperEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    const initializeTimer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(initializeTimer);
  }, []); //required to prevent image crop from being overriden

  useEffect(() => {
    if (
      initialize &&
      eventAdditionalImagesCropped?.length > 0 &&
      loadImageIntoCropperEffectRan.current === false
    ) {
      loadImageIntoCropperEffectRan.current = true;
      const currentEvImg = eventAdditionalImagesCropped?.find((ev) => {
        if (ev.id === reduxEventImgObj.id) {
          return ev;
        }
      });
      setCropperImg(currentEvImg);
    }
  }, [initialize, eventAdditionalImagesCropped]); //load image into component cropper

  function handleEventAdditionalImageChange(e) {
    if (!isLoading && e) {
      let newEventAdditionalImg = {
        ...cropperImg,
        crop: { ...cropperImg?.crop },
      };

      newEventAdditionalImg.croppedImage = e.croppedImage;
      newEventAdditionalImg.imagePreview = e.imagePreview;
      newEventAdditionalImg.crop = e.crop;
      newEventAdditionalImg.zoom = e.zoom;

      const newArr = eventAdditionalImagesCropped?.map((ev) => {
        if (ev.id === reduxEventImgObj.id) {
          return newEventAdditionalImg;
        } else {
          return ev;
        }
      });

      setCropperImg(newEventAdditionalImg);
      setEventAdditionalImagesCropped(newArr);
    }
  }

  function handleEventAdditionalImageCaptionToggle(newKey) {
    let newImgObj = { ...reduxEventImgObj };
    newImgObj.altCaptionToggle = newKey;
    handleEventAdditionalChange(newImgObj);
  }

  function handleEventAdditionalImageAltCaptionChange(val) {
    let newImgObj = { ...reduxEventImgObj };
    newImgObj.altCaption = val;
    handleEventAdditionalChange(newImgObj);
  }

  function handleEventAdditionalImageStandaloneToggle(boolean) {
    let newImgObj = { ...reduxEventImgObj };
    newImgObj.standalone = boolean;
    handleEventAdditionalChange(newImgObj);
  }

  return (
    <>
      <div
        className="event-additional__container"
        key={`evAdditionalImg-${id}`}
      >
        <ButtonTextSimple
          text={EventAdditionalImages.removeImage}
          icon={cancel}
          iconOn={true}
          iconStyle={{ height: '21px' }}
          iconClass={'mrg-r12 filter-gray'}
          customWrapper={
            'highlight-i-theme highlight-t-theme mrg-b12 mrg-auto-lr color-gray'
          }
          hideBorderOn={true}
          handler={() => handleRemove(reduxEventImgObj.id)}
        />
        <ImageCropper
          ariaImageDescription={EventAdditionalImages.ariaCropperDescription}
          center={true}
          cover={'horizontal-cover'}
          handleImage={handleEventAdditionalImageChange}
          height={198}
          id={'eventAdditionalImage'}
          imagePreview={cropperImg?.imagePreview}
          pauseCrop={pauseCrop}
          width={352}
          spinnerMinWidth={'352px'}
          spinnerMinHeight={'192px'}
          saveCroppedPos={true}
          loadCroppedPos={
            cropperImg
              ? {
                  crop: cropperImg.crop,
                  zoom: cropperImg.zoom,
                }
              : null
          }
          spinnerOff={true}
          trashButtonSpacerOn={true}
          setFileUploadTracker={() =>
            handleEventAdditionalImageFileChangeTracking(reduxEventImgObj.id)
          }
        />

        <TextArea
          handleChange={handleEventAdditionalImageAltCaptionChange}
          limit={200}
          id={reduxEventImgObj.id}
          labelText={
            reduxEventImgObj.altCaptionToggle === 'alt'
              ? EventAdditionalImages.imageAltText
              : EventAdditionalImages.imageCaptionAndAlt
          }
          disableEnter={true}
          loadTrigger={true}
          customWrapper={'br--none mrg-b12'}
          loadValue={reduxEventImgObj?.altCaption}
          onBlurHandlerOff={true}
        />
        <RadialButtonList
          onChange={handleEventAdditionalImageCaptionToggle}
          options={ImageAltRadialSelections}
          id={reduxEventImgObj.id}
          loadValue={reduxEventImgObj?.altCaptionToggle}
          loadTrigger={true}
          preventNoSelection={true}
        />
        <CheckBoxBoolean
          loadTrigger={true}
          loadValue={reduxEventImgObj?.standalone}
          label={EventAdditionalImages.standalone}
          handler={handleEventAdditionalImageStandaloneToggle}
          customWrapper={'mrg-t12 fwn fs18'}
          id={`imageStandaloneCheckbox-${reduxEventImgObj.id}`}
        />
      </div>
    </>
  );
};

export default EventAdditionalImages;

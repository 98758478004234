import { useRef, useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//components
import handleInputClick from '../TextArea/handleInputClick';

//utils
import { search_dark } from '../../assets/icons';

const WordSearchInput = ({
  ariaLabel,
  customWrapper,
  placeHolder,
  setInputText,
  id,
}) => {
  //Hooks
  const { dismissKeypad, handleMobileTap } = useSettings();

  //language
  const { IconAlts, Generic } = useLanguageComponents();

  //UI state
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [tapHighlightClearBtn, setTapHighlightClearBtn] = useState(false);
  //Component variables
  const textarea = useRef();

  //Component Functions
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
    dismissKeypad(e);
  }

  function handleClearBtn() {
    setInputText('');
    const el = document.getElementById(`${id}`);
    if (el) {
      el.value = '';
    }
  }

  return (
    <div
      className={`basic-text-input__wrapper pos-rel ${
        customWrapper ? customWrapper : ''
      }
      ${focusHighlight ? 'basic-text-input__wrapper--focus' : ''}
      `}
      onClick={() => handleInputClick(textarea.current)}
    >
      <input
        className="basic-text-input__container pad-l36 pad-r48 full-width"
        ref={textarea}
        id={id}
        type="text"
        onInput={(e) => setInputText(e.target.value)}
        onFocus={() => setFocusHighlight(true)}
        onBlur={(e) => setFocusHighlight(false)}
        onKeyDown={(e) => handleKeyDown(e)}
        tabIndex="0"
        placeholder={placeHolder}
        enterKeyHint={'enter'}
        aria-label={ariaLabel ? ariaLabel : ''}
      ></input>
      <img
        src={search_dark}
        className="filter-gray mrg-auto-right"
        alt={IconAlts.iconSearch}
        style={{ position: 'absolute', zIndex: '3', top: '12px' }}
      />
      <button
        style={{
          position: 'absolute',
          top: '12px',
          right: '24px',
          zIndex: '3',
        }}
        className={`highlight-t-theme ${
          tapHighlightClearBtn ? 'color-theme' : ''
        }`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightClearBtn(true),
              () => setTapHighlightClearBtn(false),
            ],
            [() => handleClearBtn()]
          )
        }
      >
        <p className="fs18">{Generic.clear}</p>
      </button>
    </div>
  );
};

export default WordSearchInput;

//Hooks
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useOrganizeHandlers } from './useOrganizeHandlers';
// import useLanguageComponents from '../../language/useLanguageComponents';
// import useLanguageOrganize from '../../language/features/useLanguageOrganize'

//Components
import OrganizeEventCard from './OrganizeEventCard/OrganizeEventCard';
import {
  img_checklist,
  img_empty_box,
  img_examine_page,
  img_file,
  img_plus,
} from '../../../assets/icons';
import { image_create_list } from '../../../assets/images';
import ButtonTextSimple from '../../../components/ButtonTextSimple/ButtonTextSimple';
import useLanguageOrganize from '../../../language/features/useLanguageOrganize';

//Utility
// import { dropdown_arrow } from '../../assets/icons';

const OrganizeOverview = ({
  setEditEvent,
  handleRemoveEvent,
  setEventPreview,
}) => {
  //Hooks
  // const { Generic } = useLanguageComponents();
  // const { OrganizeOverview } = useLanguageOrganize();
  const {
    organizeSortedMyOrganizedEvents,
    organizeSortedMyScheduledOrganizedEvents,
    organizeSortedMyArchivedOrganizedEvents,
  } = useOrganizeHandlers();

  const {
    settingsOrganizeOverviewContent,
    settingsOrganizeStandaloneGridEvents,
    handleSettingsSetOrganizeView,
  } = useSettingsHandlers();

  //language
  const { OrganizeOverview } = useLanguageOrganize();

  //Component variables
  let overviewContent;

  //JSX
  if (settingsOrganizeOverviewContent === 'standalone') {
    overviewContent = (
      <div className="events-grid__wrapper" id="organizeGrid">
        <div className="active-events">
          <div className="h48" />

          {settingsOrganizeOverviewContent === 'standalone' &&
          settingsOrganizeStandaloneGridEvents === 'active' &&
          organizeSortedMyOrganizedEvents?.length > 0 ? (
            <>
              {organizeSortedMyOrganizedEvents?.map((eventData, index) => {
                return (
                  <OrganizeEventCard
                    eventData={eventData}
                    key={`organize-event-card-${index}`}
                    setEditEvent={setEditEvent}
                    organizeType={'active'}
                    index={index}
                    setEventPreview={setEventPreview}
                  />
                );
              })}
            </>
          ) : (
            settingsOrganizeOverviewContent === 'standalone' &&
            settingsOrganizeStandaloneGridEvents === 'active' && (
              <ButtonTextSimple
                icon={img_plus}
                iconOn={true}
                iconAlt={'+'}
                text={OrganizeOverview.createEvent}
                id={'createEventBtn'}
                iconClass={'filter-gray'}
                customWrapper={'mrg-t48'}
                customClassText={'mrg-l12 fs21 fs18--ph'}
                handler={() => handleSettingsSetOrganizeView('create')}
              />
            )
          )}

          {settingsOrganizeOverviewContent === 'standalone' &&
          settingsOrganizeStandaloneGridEvents === 'scheduled' &&
          organizeSortedMyScheduledOrganizedEvents?.length > 0 ? (
            <>
              {organizeSortedMyScheduledOrganizedEvents?.map(
                (eventData, index) => {
                  return (
                    <OrganizeEventCard
                      eventData={eventData}
                      key={`organize-event-card-${index}`}
                      setEditEvent={setEditEvent}
                      organizeType={'scheduled'}
                      setEventPreview={setEventPreview}
                    />
                  );
                }
              )}
            </>
          ) : (
            settingsOrganizeOverviewContent === 'standalone' &&
            settingsOrganizeStandaloneGridEvents === 'scheduled' && (
              <ButtonTextSimple
                icon={img_plus}
                iconOn={true}
                iconAlt={'+'}
                text={OrganizeOverview.createEvent}
                id={'createEventBtn'}
                iconClass={'filter-gray'}
                customWrapper={'mrg-t48'}
                customClassText={'mrg-l12 fs21 fs18--ph'}
                handler={() => handleSettingsSetOrganizeView('create')}
              />
            )
          )}

          {settingsOrganizeOverviewContent === 'standalone' &&
          settingsOrganizeStandaloneGridEvents === 'archived' &&
          organizeSortedMyArchivedOrganizedEvents?.length > 0 ? (
            <>
              {organizeSortedMyArchivedOrganizedEvents?.map(
                (eventData, index) => {
                  return (
                    <OrganizeEventCard
                      eventData={eventData}
                      key={`organize-event-card-${index}`}
                      setEditEvent={setEditEvent}
                      organizeType={'archived'}
                      handleRemoveEvent={handleRemoveEvent}
                    />
                  );
                }
              )}
            </>
          ) : (
            settingsOrganizeOverviewContent === 'standalone' &&
            settingsOrganizeStandaloneGridEvents === 'archived' && (
              <ButtonTextSimple
                icon={img_plus}
                iconOn={true}
                iconAlt={'+'}
                text={OrganizeOverview.createEvent}
                id={'createEventBtn'}
                iconClass={'filter-gray'}
                customWrapper={'mrg-t48'}
                customClassText={'mrg-l12 fs21 fs18--ph'}
                handler={() => handleSettingsSetOrganizeView('create')}
              />
            )
          )}
        </div>
      </div>
    );
  }

  return overviewContent;
};

export default OrganizeOverview;

//JSX for series integration
// let cardsHeading;

// if (settingsOrganizeOverviewContent === 'standalone') {
//   cardsHeading = (
//     <h1 className="heading">
//       {OrganizeOverview.standaloneEvents} ({organizeMyOrganizedEvents?.length}
//       )
//     </h1>
//   );
// }

//   <div className="flex-row mrg-b12">
//   <div className="current-grid-bar mrg-auto-lr mrg-tb24">
//     <button className="arrow filter-gray mrg-r12">
//       <img
//         src={dropdown_arrow}
//         className="rotate-cw90"
//         alt={Generic.arrow}
//       />
//     </button>
//     <div className="flex-column mrg-l12 mrg-r12">
//       {cardsHeading}

//       <div className="flex-row flex-center mrg-t12 mrg-b6">
//         <span
//           className={`dot mrg-r6 ${
//             settingsOrganizeOverviewContent === 'standalone'
//               ? 'bg-color-theme'
//               : 'bg-color-lightgray'
//           }`}
//         />
//       </div>
//     </div>
//     <button className="arrow filter-gray mrg-l12">
//       <img
//         src={dropdown_arrow}
//         alt={Generic.arrow}
//         className="rotate-ccw90"
//       />
//     </button>
//   </div>
// </div>

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';

//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';
import useLanguageData from '../../../../language/useLanguageData';

//Utility
import { saved, social } from '../../../../assets/icons';

const Views = ({ view }) => {
  //Hooks
  const { handleSettingsSetEventView } = useSettingsHandlers();

  //language
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { BigTagIcon, TinyTagIcon } = useLanguageData();

  //Component variables
  let content;

  //JSX conditional
  if (SettingsEventsLanguage) {
    content = (
      <ul className="view__container">
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('myevents')}
            title={SettingsEventsLanguage.myEvents}
            aria-label={SettingsEventsLanguage.myEvents}
            id="view-myevents"
            aria-pressed={view === 'myevents'}
          >
            <div
              className={`view-img__container ${
                view === 'myevents' ? 'selected' : ''
              }`}
            >
              <img src={saved} alt={SettingsEventsLanguage.myEvents} />
            </div>
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className={`access-ob access-o6`}
            onClick={() => handleSettingsSetEventView('bigtags')}
            title={SettingsEventsLanguage.bigTags}
            aria-label={SettingsEventsLanguage.bigTags}
            id="view-bigtags"
            aria-pressed={view === 'bigtags'}
          >
            <div
              className={`view-img__container ${
                view === 'bigtags' ? 'selected' : ''
              }`}
            >
              <img
                src={BigTagIcon}
                alt={SettingsEventsLanguage.bigTags}
                style={{ height: '21px' }}
              />
            </div>
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('tinytags')}
            title={SettingsEventsLanguage.tinyTags}
            aria-label={SettingsEventsLanguage.tinyTags}
            id="view-tinytags"
            aria-pressed={view === 'tinytags'}
          >
            <div
              className={`view-img__container ${
                view === 'tinytags' ? 'selected' : ''
              }`}
            >
              <img
                src={TinyTagIcon}
                alt={SettingsEventsLanguage.tinyTags}
                style={{ height: '30px' }}
              />
            </div>
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetEventView('community')}
            title={SettingsEventsLanguage.community}
            aria-label={SettingsEventsLanguage.community}
            id="view-community"
            aria-pressed={view === 'community'}
          >
            <div
              className={`view-img__container ${
                view === 'community' ? 'selected' : ''
              }`}
            >
              <img src={social} alt={SettingsEventsLanguage.community} />
            </div>
          </button>
        </li>
      </ul>
    );
  }

  return content;
};

export default Views;

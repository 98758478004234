import { useEffect, useRef, useState } from 'react';
import { useCreateEventReduxHandlers } from '../create/useCreateEventHandlers';
import { useRemoveDraftEventMutation } from '../draftEventApiSlice';

//Hooks
import { useApiStatus } from '../../../../context/ApiStatusProvider';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useOrganizeHandlers } from '../useOrganizeHandlers';
import useDelayedHander from '../../../../hooks/useDelayedHandler';
import { useEventParticipantsHandlers } from '../eventParticipants/useEventParticipantsHandlers';
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//api
import {
  useUpdateInviteContactParticipantDraftEventsFromDraftRemovalMutation,
  useEventDraftRemovalNoticesUpdateForInviteContactParticipantsMutation,
} from '../eventParticipants/eventParticipantsApiSlice';

//Components
import SelectionList from '../../../../components/Selections/SelectionList';
import ConfirmSelection from '../../../../components/ConfirmSelection/ConfirmSelection';
import TextArea from '../../../../components/TextArea/TextArea';
import ButtonMeta from '../../../../components/ButtonMeta/ButtonMeta';
import Spinner from '../../../../components/Spinner/Spinner';

//Utility
import {
  img_create,
  img_empty_box,
  img_load,
  img_restore,
  return_curved_arrow,
  save,
  virtual,
} from '../../../../assets/icons';
import { DateFromTimeStamp } from '../../../../utils/dateTime/DateCalc';

const Initialize = ({
  handleSubmitEventDraft,
  participants,
  clearImages,
  //loading state
  setInitialize,
  initialize,
  setOrganizeIsLoading,
  setSubmitIsLoading,
  submitIsLoading,
  organizeIsLoading,
  //pwd
  decryptedTranslationsPassword,
  decryptedVirtualPasscode,
  setDecryptedTranslationsPassword,
  setDecryptedVirtualPasscode,
  translationsPassword,
  virtualPasscode,
  //
}) => {
  //Hooks
  const { width, handleMobileTap } = useSettings();
  const { handleError, handleSuccess, handleWarning } = useApiStatus();
  const { delayedHandler } = useDelayedHander();
  const {
    organizeMyDraftEvents,
    handleReduxPasswordEncryption,
    handleReduxPasswordDecryption,
  } = useOrganizeHandlers();
  const { accountContentLanguage: locale, accountId } =
    useAccountReduxHandlers();
  const {
    handleCreateEventReturnEventData,
    handleCreateEventReset,
    handleCreateEventSetEvent,
    handleCreateEventSetSaveName,
    lastModified,
    title,
    saveName,
    createEventId,
    createEventEventReference,
    handleCreateEventSetTranslationsPassword,
    handleCreateEventSetVirtualPasscode,
  } = useCreateEventReduxHandlers();
  const {
    eventDataHasAcceptedInviteContactParticipants,
    eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts,
    handleResetParticipantInvitesAndTranslations,
  } = useEventParticipantsHandlers();

  //language
  const { Generic, IconAlts } = useLanguageComponents();
  const { CreateEvent } = useLanguageOrganize();

  //API
  const [removeDraft] = useRemoveDraftEventMutation();
  const [updateInviteContactParticipantDraftEventsFromDraftRemoval] =
    useUpdateInviteContactParticipantDraftEventsFromDraftRemovalMutation();

  const [eventDraftRemovalNoticesUpdateForInviteContactParticipants] =
    useEventDraftRemovalNoticesUpdateForInviteContactParticipantsMutation();

  //Component state
  const [initialSaveName, setInitialSaveName] = useState();
  const [loadDraft, setLoadDraft] = useState();
  const [deleteDraftData, setDeleteDraftData] = useState();
  const [selectionBtn, setSelectionBtn] = useState('');
  const [tapHighlightMobileReturnBtn, setTapHighlightMobileReturnBtn] =
    useState(false);

  //ui
  const [renderQuickSaveButton, setRenderQuickSaveButton] = useState(false);
  const [participantInviteResetWarning, setParticipantInviteResetWarning] =
    useState(false);
  const [displayMetaBtns, setDisplayMetaBtns] = useState(true);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  //Component variables
  const deleteTimer = useRef(null);
  const isMatch = organizeMyDraftEvents.some(
    (obj) => obj.meta.saveName === saveName
  );
  const lastModifiedConverted = DateFromTimeStamp(
    lastModified,
    {
      weekday: 'off',
    },
    locale
  );

  //UI
  useEffect(() => {
    if (
      lastModified &&
      createEventId &&
      saveName &&
      selectionBtn !== 'saveAs'
    ) {
      //handle case where the user had a save file but deleted it
      const draftSaveExists = organizeMyDraftEvents.some(
        (draft) =>
          draft?._id === createEventId && draft?.meta?.saveName === saveName
      );
      setRenderQuickSaveButton(draftSaveExists);
    } else {
      setRenderQuickSaveButton(false);
    }
  }, [lastModified, createEventId, saveName, selectionBtn]);

  useEffect(() => {
    if (selectionBtn) {
      setSelectionBtn();
      setLoadDraft();
      setDeleteDraftData();
    }
  }, [lastModified]); //clears selection btn if user makes changes

  useEffect(() => {
    if (!deleteIsLoading && deleteTimer.current) {
      clearTimeout(deleteTimer.current);
    }
  }, [deleteIsLoading]); //delete spinner UI

  useEffect(() => {
    if (width >= 480) {
      setDisplayMetaBtns(true);
    } else {
      if (selectionBtn) {
        setDisplayMetaBtns(false);
      } else {
        setDisplayMetaBtns(true);
      }
    }
  }, [width, selectionBtn, loadDraft]); //toggle some UI display options

  //API functions
  async function draftSaveAs(exit) {
    let draftData = handleCreateEventReturnEventData();
    //determine if this doc is overwriting another doc via save name
    const currentEventDataId = draftData?.meta?._id?.toString();
    let overwriteIdentified;

    organizeMyDraftEvents?.map((obj) => {
      if (draftData?.meta?.saveName === obj?.meta?.saveName) {
        if (obj?._id?.toString() !== currentEventDataId) {
          overwriteIdentified = obj?._id;
        }
      }
    });

    if (overwriteIdentified) {
      return handleWarning({
        message: 'cannotOverwriteFile',
        origin: 'Initialize.js/draftSaveAs',
        id: Date.now(),
      });
    }

    if (organizeMyDraftEvents?.length >= 5) {
      return handleWarning({
        message: 'draftLimit',
        origin: 'Initialize.js/draftSaveAs',
        id: Date.now(),
      });
    }

    //determine whether to reset dispatches + participant data
    if (initialSaveName !== saveName) {
      draftData = await handleResetParticipantInvitesAndTranslations(draftData);
    }
    setOrganizeIsLoading(true);
    setSelectionBtn();
    setInitialize('save');

    //pwd
    let newDraftData = JSON.parse(JSON.stringify(draftData));
    newDraftData.hosting.virtual.passcode = decryptedVirtualPasscode;
    newDraftData.translations.password = decryptedTranslationsPassword;

    const savedDraftData = await handleSubmitEventDraft({
      eventData: newDraftData,
      handleType: isMatch ? 'updateDraft' : 'createDraft',
      exit,
    });

    if (savedDraftData) {
      if (!exit) {
        clearImages();

        if (savedDraftData?.translations?.password?.length > 0) {
          await handleReduxPasswordEncryption(
            'translationsPassword',
            savedDraftData?.translations?.password,
            decryptedTranslationsPassword,
            setDecryptedTranslationsPassword,
            handleCreateEventSetTranslationsPassword
          );
        }

        if (savedDraftData?.hosting?.virtual?.passcode?.length > 0) {
          await handleReduxPasswordEncryption(
            'virtualPasscode',
            savedDraftData?.hosting?.virtual?.passcode,
            decryptedVirtualPasscode,
            setDecryptedVirtualPasscode,
            handleCreateEventSetVirtualPasscode
          );
        }

        handleSuccess({
          message: 'draftSaved',
          id: Date.now(),
          origin: 'CreateEvent.js/handleSubmitEvent',
        });
      }

      setSelectionBtn();
      setInitialSaveName(saveName);
      handleCreateEventSetEvent(savedDraftData);
      setOrganizeIsLoading(false);
      setSubmitIsLoading(false);
    }
  }

  async function draftSave() {
    setOrganizeIsLoading(true);
    setSelectionBtn();
    setInitialize('save');

    let createEventSliceData = handleCreateEventReturnEventData();
    let draftData = JSON.parse(JSON.stringify(createEventSliceData));

    //ensure save name is set from the doc data not create event slice
    organizeMyDraftEvents?.map((obj) => {
      if (obj?.meta?.eventReference === createEventEventReference) {
        draftData.meta.saveName = obj.meta.saveName;
      }
    });

    //pwd
    let newDraftData = JSON.parse(JSON.stringify(draftData));
    newDraftData.hosting.virtual.passcode = decryptedVirtualPasscode;
    newDraftData.translations.password = decryptedTranslationsPassword;

    const savedDraftData = await handleSubmitEventDraft({
      eventData: newDraftData,
      handleType: 'updateDraft',
      exit: false,
    });

    if (savedDraftData) {
      if (savedDraftData?.translations?.password?.length > 0) {
        await handleReduxPasswordEncryption(
          'translationsPassword',
          savedDraftData?.translations?.password,
          decryptedTranslationsPassword,
          setDecryptedTranslationsPassword,
          handleCreateEventSetTranslationsPassword
        );
      }

      if (savedDraftData?.hosting?.virtual?.passcode?.length > 0) {
        await handleReduxPasswordEncryption(
          'virtualPasscode',
          savedDraftData?.hosting?.virtual?.passcode,
          decryptedVirtualPasscode,
          setDecryptedVirtualPasscode,
          handleCreateEventSetVirtualPasscode
        );
      }

      handleCreateEventSetEvent(savedDraftData);

      handleSuccess({
        message: 'draftSaved',
        id: Date.now(),
        origin: 'CreateEvent.js/handleSubmitEvent',
      });

      setOrganizeIsLoading(false);
      setSubmitIsLoading(false);
    }
  }

  async function deleteDraft(accountId, draftId) {
    try {
      setDeleteIsLoading(true);
      const draftParticipants = deleteDraftData?.participants;

      const hasAcceptedInviteContactParticipants =
        eventDataHasAcceptedInviteContactParticipants(
          deleteDraftData?.participants
        );

      if (hasAcceptedInviteContactParticipants) {
        //remove draft from accountEvent.participantDrafts
        await updateInviteContactParticipantDraftEventsFromDraftRemoval({
          accountId,
          draftId,
          draftParticipants,
        });
      }
      const hasAcceptedOrPendingDispatches =
        eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts(
          deleteDraftData?.participants
        );

      if (hasAcceptedOrPendingDispatches) {
        //remove pending notices for participants +notify accepted participants that draft was removed
        await eventDraftRemovalNoticesUpdateForInviteContactParticipants({
          accountId,
          draftId,
        });
      }

      await removeDraft({ accountId, draftId });

      setDeleteDraftData();

      deleteTimer.current = setTimeout(() => {
        setDeleteIsLoading(false);
      }, 300);
    } catch (error) {
      handleError({
        error,
        id: Date.now(),
        origin: 'Initialize.js/deleteDraft',
      });

      deleteTimer.current = setTimeout(() => {
        setDeleteIsLoading(false);
      }, 300);
    }
  }

  // functions
  function changeSaveDraftName(v) {
    handleParticipantInviteResetWarning(v);
    handleCreateEventSetSaveName(v);
  }

  function handleParticipantInviteResetWarning(selectedSaveName) {
    if (selectedSaveName?.length > 0 && participants?.length > 0) {
      let resetParticipantInvitesWarningValid = false;

      if (selectedSaveName !== initialSaveName) {
        participants?.map((p) => {
          if (
            p.organizedAs === 'inviteContact' ||
            p.organizedAs === 'emailInvitation'
          ) {
            if (p?.dispatches?.inviteDispatched === true) {
              resetParticipantInvitesWarningValid = true;
            }
          }
        });
        return setParticipantInviteResetWarning(
          resetParticipantInvitesWarningValid
        );
      } else {
        return setParticipantInviteResetWarning(false);
      }
    } else {
      return setParticipantInviteResetWarning(false);
    }
  } //check if participant invites have been dispatched; used to ensure a new draft or overwrite will clear dispatched status and new invites will be created

  function handleMetaBtnSelection(selection) {
    if (selection === 'saveAs') {
      if (selectionBtn === 'saveAs') {
        //need to reset the saveName when leaving saveAs
        handleCreateEventSetSaveName(initialSaveName);
      } else {
        handleParticipantInviteResetWarning('');
        handleCreateEventSetSaveName('');
      }
    }

    if (selection === 'load' && selectionBtn !== 'load') {
      handleCreateEventSetSaveName('');
    } else {
      handleCreateEventSetSaveName(initialSaveName);
    } //need to ensure the menu doesnt start with a selection -- selection list uses saveName

    if (selection === selectionBtn) {
      setSelectionBtn();
    } else {
      setSelectionBtn(selection);
      setLoadDraft();
      setDeleteDraftData();
    }
  }

  function handleCreateNewConfirm() {
    setOrganizeIsLoading(true);
    setDecryptedTranslationsPassword();
    setDecryptedVirtualPasscode();
    handleCreateEventReset();
    clearImages();
    setInitialize('create');
    setSelectionBtn();
    setInitialSaveName();

    let delay = setTimeout(() => {
      setOrganizeIsLoading(false);
    }, 1000);
    return () => clearTimeout(delay);
  } //resets components then calls load function

  function cancelSaveDraft() {
    setLoadDraft();
    handleCreateEventSetSaveName('');
  }

  function handleSelectDraft(draft, selectionType) {
    if (selectionType === 'select') {
      setDeleteDraftData();

      if (selectionBtn === 'load') {
        handleCreateEventSetSaveName();
        setLoadDraft(draft);
      } else if (selectionBtn === 'saveAs') {
        handleCreateEventSetSaveName(draft.meta.saveName);
        handleParticipantInviteResetWarning(draft.meta.saveName);
      }
    } else if (selectionType === 'delete') {
      setLoadDraft();
      setDeleteDraftData(draft);
    }
  } //clicking draft entry in table

  async function handleLoadDraftConfirm() {
    setOrganizeIsLoading(true);
    handleCreateEventReset();

    setInitialize('load');
    setSelectionBtn();
    setInitialSaveName(loadDraft?.meta?.saveName);
    handleCreateEventSetEvent(loadDraft);

    //////
    setDecryptedTranslationsPassword(loadDraft.translations?.password || '');
    await handleReduxPasswordEncryption(
      'translationsPassword',
      loadDraft.translations?.password,
      decryptedTranslationsPassword,
      setDecryptedTranslationsPassword,
      handleCreateEventSetTranslationsPassword
    );

    setDecryptedVirtualPasscode(loadDraft.hosting?.virtual?.passcode || '');
    await handleReduxPasswordEncryption(
      'virtualPasscode',
      loadDraft.hosting?.virtual?.passcode,
      decryptedVirtualPasscode,
      setDecryptedVirtualPasscode,
      handleCreateEventSetVirtualPasscode
    );

    //////
    setLoadDraft();
    setDeleteDraftData();
    clearImages();

    let delay = setTimeout(() => {
      setOrganizeIsLoading(false);
    }, 1000);
    return () => clearTimeout(delay);
  }

  function setRestoreData() {
    setOrganizeIsLoading(true);
    setInitialize('restore');
    setSelectionBtn();

    organizeMyDraftEvents?.map((ev) => {
      if (ev._id === createEventId) {
        setInitialSaveName(ev.meta?.saveName);
        handleCreateEventSetSaveName(ev.meta?.saveName);
      }
    });

    if (translationsPassword) {
      handleReduxPasswordDecryption(
        'translationsPassword',
        translationsPassword,
        setDecryptedTranslationsPassword
      );
    }

    if (virtual) {
      handleReduxPasswordDecryption(
        'virtualPasscode',
        virtualPasscode,
        setDecryptedVirtualPasscode
      );
    }

    let delay = setTimeout(() => {
      setOrganizeIsLoading(false);
    }, 1000);
    return () => clearTimeout(delay);
  }

  function handleMobileReturnBtn() {
    if (loadDraft) {
      setLoadDraft();
    } else {
      setSelectionBtn();
    }
  }

  return (
    <fieldset className="fieldset" id="fieldset-initialize">
      <legend id="aria-fieldset-instructions-initialize">
        {CreateEvent.initialize.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="initializeLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-initialize"
      >
        {CreateEvent.initialize.initialize}
      </label>

      <div className={`flex-column full-width `}>
        {displayMetaBtns && (
          <>
            <div
              className={`button-meta__container space-evenly ${
                selectionBtn ? 'mrg-b24' : ''
              }`}
            >
              {lastModified && !initialize && (
                <ButtonMeta
                  handleClick={() => handleMetaBtnSelection('restore')}
                  btnText={CreateEvent.initialize.restore}
                  ariaLabel={CreateEvent.initialize.restore}
                  activeStateDependency={selectionBtn === 'restore'}
                  icon={img_restore}
                  iconAlt={IconAlts.iconRestore}
                />
              )}
              {initialize &&
                lastModified &&
                initialSaveName &&
                renderQuickSaveButton && (
                  <ButtonMeta
                    handleClick={() => delayedHandler(draftSave)}
                    btnText={CreateEvent.initialize.save}
                    ariaLabel={CreateEvent.initialize.save}
                    activeStateDependency={selectionBtn === 'save'}
                    icon={save}
                    iconAlt={IconAlts.iconSave}
                    unavailable={
                      submitIsLoading || deleteIsLoading || organizeIsLoading
                    }
                    availabilityToggle={true}
                  />
                )}

              {initialize && lastModified && (
                <ButtonMeta
                  handleClick={() => handleMetaBtnSelection('saveAs')}
                  btnText={CreateEvent.initialize.saveAs}
                  ariaLabel={CreateEvent.initialize.saveAs}
                  activeStateDependency={selectionBtn === 'saveAs'}
                  icon={save}
                  iconAlt={IconAlts.iconSave}
                  availabilityToggle={true}
                  unavailable={
                    submitIsLoading || deleteIsLoading || organizeIsLoading
                  }
                />
              )}

              <ButtonMeta
                handleClick={() => handleMetaBtnSelection('load')}
                btnText={CreateEvent.initialize.load}
                ariaLabel={CreateEvent.initialize.load}
                activeStateDependency={selectionBtn === 'load'}
                icon={img_load}
                iconAlt={IconAlts.iconLoad}
                availabilityToggle={true}
                unavailable={
                  submitIsLoading || deleteIsLoading || organizeIsLoading
                }
              />

              <ButtonMeta
                handleClick={
                  !initialize && !lastModified
                    ? () => {
                        setOrganizeIsLoading(true);
                        handleCreateNewConfirm();
                      }
                    : () => {
                        handleMetaBtnSelection('new');
                      }
                }
                btnText={CreateEvent.initialize.create}
                ariaLabel={CreateEvent.initialize.create}
                activeStateDependency={selectionBtn === 'new'}
                icon={img_create}
                iconAlt={IconAlts.iconCreate}
                availabilityToggle={true}
                unavailable={
                  submitIsLoading || deleteIsLoading || organizeIsLoading
                }
              />
            </div>
          </>
        )}

        {selectionBtn === 'restore' && (
          <>
            {width > 480 && <div className="divider mrg-t24" />}
            <ConfirmSelection
              text={CreateEvent.initialize.restoreDataMessage}
              text2={`${CreateEvent.initialize.lastModified}: ${lastModifiedConverted}`}
              btn1Text={CreateEvent.initialize.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => setSelectionBtn('')}
              btn2Text={CreateEvent.initialize.restore}
              btn2Color={'pos'}
              btn2Handler={setRestoreData}
              ignoreMaxWidth={true}
              customWrapper={'mrg-t24 mrg-t0--ph text-center--ph'}
            />
          </>
        )}

        {selectionBtn === 'new' && (
          <>
            {width > 480 && <div className="divider mrg-t24" />}
            <ConfirmSelection
              text={CreateEvent.initialize.createNewEventAndLoseProgress}
              btn1Text={CreateEvent.initialize.cancel}
              btn1Color={'neutral'}
              btn1Handler={() => {
                setSelectionBtn('');
              }}
              btn2Text={CreateEvent.initialize.create}
              btn2Color={'pos'}
              btn2Handler={() => {
                setOrganizeIsLoading(true);
                handleCreateNewConfirm();
              }}
              ignoreMaxWidth={true}
              customWrapper={'mrg-t24 mrg-t0--ph text-center--ph'}
            />
          </>
        )}

        {deleteIsLoading && <Spinner />}
        {(selectionBtn === 'load' || selectionBtn === 'saveAs') &&
          !deleteIsLoading && (
            <>
              {width > 480 && <div className="divider mrg-t24" />}

              {organizeMyDraftEvents?.length > 0 && (
                <>
                  {width <= 480 && selectionBtn && (
                    <button
                      className={`close-btn highlight-i-lgt access-ob  ${
                        tapHighlightMobileReturnBtn
                          ? 'highlight-i-lgt--tap'
                          : ''
                      }`}
                      onClick={() =>
                        handleMobileTap(
                          [
                            () => setTapHighlightMobileReturnBtn(true),
                            () => setTapHighlightMobileReturnBtn(false),
                          ],
                          [() => handleMobileReturnBtn()]
                        )
                      }
                      type="button"
                      title={Generic.return}
                    >
                      <img
                        src={return_curved_arrow}
                        alt={IconAlts.iconReturn}
                        className="svg"
                        style={{ maxHeight: '48px' }}
                      />
                    </button>
                  )}

                  <SelectionList
                    dataList={organizeMyDraftEvents}
                    id={'draft'}
                    col1Heading={CreateEvent.initialize.draftFiles}
                    col1DataPath1={'meta'}
                    col1DataPath2={'saveName'}
                    col2DataPath1={'meta'}
                    col2DataPath2={'lastModified'}
                    handler={handleSelectDraft}
                    itemSelection={
                      loadDraft
                        ? loadDraft
                        : deleteDraftData
                        ? deleteDraftData
                        : null
                    }
                    ariaText={CreateEvent.initialize}
                    deleteSelection={deleteDraftData}
                    selectedFile={saveName}
                    activeFile={initialSaveName}
                    customWrapper={width < 480 ? 'mrg-tb24' : ''}
                  />
                </>
              )}

              {organizeMyDraftEvents?.length === 0 && (
                <>
                  {width <= 480 && selectionBtn && (
                    <button
                      className={`close-btn highlight-i-lgt access-ob mrg-b24 ${
                        tapHighlightMobileReturnBtn
                          ? 'highlight-i-lgt--tap'
                          : ''
                      }`}
                      onClick={() =>
                        handleMobileTap(
                          [
                            () => setTapHighlightMobileReturnBtn(true),
                            () => setTapHighlightMobileReturnBtn(false),
                          ],
                          [() => handleMobileReturnBtn()]
                        )
                      }
                      type="button"
                      title={Generic.return}
                    >
                      <img
                        src={return_curved_arrow}
                        alt={IconAlts.iconReturn}
                        className="svg"
                        style={{ maxHeight: '48px' }}
                      />
                    </button>
                  )}
                  <div
                    className="flex-column flex-center access-ob access-o6 fit-content mrg-auto-lr mrg-t24"
                    tabIndex="0"
                  >
                    <img
                      src={img_empty_box}
                      alt={IconAlts.iconEmptyBox}
                      className="mrg-b12 filter-gray"
                      style={{ height: '48px' }}
                    />
                    <p className="fs18 fwsb color-gray">
                      {CreateEvent.initialize.noDrafts}
                    </p>
                  </div>
                </>
              )}

              {/*CONFIRMATION BTNS & INFO */}
              {selectionBtn === 'saveAs' && !deleteDraftData && (
                <>
                  <ul>
                    {participantInviteResetWarning && (
                      <li
                        className="banners__notice fs16 fwn access-ob access-o6 mrg-t24"
                        tabIndex="0"
                      >
                        {initialSaveName ? (
                          <>
                            <span className="fwsb no-wrap">
                              "{initialSaveName}":
                            </span>
                            &nbsp;
                            {
                              CreateEvent.initialize
                                .participantInvitesResetWarning
                            }
                          </>
                        ) : (
                          <>
                            {/*Occurrs when user restores saved data from a deleted file */}
                            {
                              CreateEvent.initialize
                                .participantInvitesResetWarningWithNoSaveName
                            }
                          </>
                        )}
                      </li>
                    )}
                  </ul>
                  <div className="h24" />
                  <TextArea
                    handleChange={changeSaveDraftName}
                    limit={75}
                    id={'saveAsText'}
                    labelText={CreateEvent.initialize.saveAs}
                    disableEnter={true}
                    defaultInput={title ? title : null}
                    loadValue={saveName}
                    reload={true}
                    onBlurHandlerOff={true}
                  />

                  {saveName && (
                    <>
                      <div className="h24" />
                      <ConfirmSelection
                        btn1Text={CreateEvent.initialize.cancel}
                        btn1Color={'neutral'}
                        btn1Handler={cancelSaveDraft}
                        btn2Text={CreateEvent.initialize.save}
                        btn2Color={'pos'}
                        btn2Handler={() => draftSaveAs(false)}
                        btn3Text={CreateEvent.initialize.saveAndExit}
                        btn3Color={'pos'}
                        btn3Handler={() => draftSaveAs(true)}
                        ignoreMaxWidth={true}
                        customWrapper={'mrg-t24 mrg-t0--ph text-center--ph'}
                      />
                    </>
                  )}
                </>
              )}

              {loadDraft && (
                <>
                  <ConfirmSelection
                    text={`${CreateEvent.initialize.loadDraftPt1}${loadDraft.meta.saveName}${CreateEvent.initialize.loadDraftPt2}`}
                    btn1Color={'neutral'}
                    btn1Handler={() => {
                      setDeleteDraftData();
                      setLoadDraft();
                    }}
                    btn1Text={CreateEvent.initialize.cancel}
                    btn1Id={'cancelLoadBtn'}
                    btn2Color={'pos'}
                    btn2Handler={() => {
                      handleLoadDraftConfirm();
                      setDeleteDraftData();
                    }}
                    btn2Text={CreateEvent.initialize.load}
                    btn2Id={'confirmLoadBtn'}
                    ignoreMaxWidth={true}
                    customWrapper={'mrg-t24 mrg-t0--ph text-center--ph'}
                  />
                </>
              )}

              {deleteDraftData && (
                <>
                  {selectionBtn === 'saveAs' && <div className="h12" />}
                  <ConfirmSelection
                    text={`${CreateEvent.initialize.delete} '${deleteDraftData.meta.saveName}' ?`}
                    btn1Text={CreateEvent.initialize.cancel}
                    btn1Color={'neutral'}
                    btn1Handler={() => {
                      setLoadDraft();
                      setDeleteDraftData();
                    }}
                    btn2Text={CreateEvent.initialize.delete}
                    btn2Color={'neg'}
                    btn2Handler={() => {
                      setLoadDraft();
                      deleteDraft(accountId, deleteDraftData._id);
                    }}
                    // isLoading={removeDraftisLoading}
                    ignoreMaxWidth={true}
                    customWrapper={'mrg-t24 mrg-t0--ph text-center--ph'}
                  />
                </>
              )}
            </>
          )}
      </div>
    </fieldset>
  );
};

export default Initialize;

import { useState, useEffect } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useGenericModal } from '../../../../context/GenericModalProvider';
import { useAccountReduxHandlers } from '../../../../features/main/account/useAccountHandlers';

//API
import { useLazyGetContactedSpeakersQuery } from '../../../../features/main/organize/organizeApiSlice';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import LoadBucketImage from '../../../LoadBucketImage/LoadBucketImage';
import Spinner from '../../../Spinner/Spinner';

//Utility
import {
  cancel,
  img_folder_closed,
  img_folder_open,
  img_empty_box,
  arrow_circular_hallow,
} from '../../../../assets/icons';

//showAcceptedListOnly used for invite participant list in organize/create/participants
//else used in organize/find speakers

const ViewContactedSpeakersModal = ({ handler, showAcceptedListOnly }) => {
  //hooks
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();
  const { accountId } = useAccountReduxHandlers();

  //language
  const { Generic, IconAlts, ViewContactedSpeakersModal } =
    useLanguageComponents();

  //API
  const [
    getContactedSpeakers,
    {
      isLoading: getContactedSpeakersIsLoading,
      data: getContactedSpeakersData,
    },
  ] = useLazyGetContactedSpeakersQuery();

  //Component state
  const [openedEventsFolder, setOpenedEventsFolder] = useState();
  const [filteredList, setFilteredList] = useState([]);

  const [contactsData, setContactsData] = useState([]);
  const [contactsRequestedData, setContactsRequestedData] = useState([]);

  //UI state
  const [tapHighlightExitBtn, setTapHighlightExitBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();

  //initialize
  useEffect(() => {
    getContactedSpeakers({ accountId });
    setCloseModalFocusId('viewContactsButton');
    document.getElementById(`viewContactedSpeakersModal`).focus();
  }, []);

  useEffect(() => {
    if (showAcceptedListOnly) {
      setFilteredList(contactsData);
    }
  }, [showAcceptedListOnly, contactsData]);

  //component functions
  useEffect(() => {
    if (openedEventsFolder === 'requested') {
      setFilteredList(contactsRequestedData);
    }
    if (openedEventsFolder === 'accepted') {
      setFilteredList(contactsData);
    }

    if (!openedEventsFolder && !showAcceptedListOnly) {
      setFilteredList();
    }
  }, [openedEventsFolder, getContactedSpeakersData]);

  useEffect(() => {
    setContactsData(getContactedSpeakersData?.contacts);
    setContactsRequestedData(getContactedSpeakersData?.contactsRequested);
  }, [getContactedSpeakersData]);

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="viewContactedSpeakersModal"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`viewContactedSpeakersModalLabel`}
          >
            {ViewContactedSpeakersModal.contacts}
            {showAcceptedListOnly && filteredList && (
              <>{` (${filteredList?.length})`}</>
            )}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob ${
            tapHighlightExitBtn ? 'filter-img-red' : 'filter-img-lightgray'
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightExitBtn(true),
                () => setTapHighlightExitBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img src={cancel} alt={IconAlts.iconClose} className="svg" />
        </button>
      </div>

      <div
        className="modal-basic__container list-style-none hide-scrollbar"
        id="viewContactedSpeakersModalContainer"
      >
        {getContactedSpeakersIsLoading ? (
          <Spinner height={'100%'} />
        ) : (
          <>
            {!showAcceptedListOnly && (
              <>
                <button
                  onClick={() => {
                    if (openedEventsFolder === 'requested') {
                      setOpenedEventsFolder();
                    } else {
                      setOpenedEventsFolder('requested');
                    }
                  }}
                  aria-pressed={openedEventsFolder === 'requested'}
                  tabIndex="0"
                  className="access-ob"
                >
                  <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
                    {openedEventsFolder === 'requested' ? (
                      <img
                        src={img_folder_open}
                        className="filter-theme mrg-r12 svg"
                        alt={IconAlts.iconFolder}
                        style={{ height: '32px', width: '48px' }}
                      />
                    ) : (
                      <img
                        src={img_folder_closed}
                        className="filter-theme mrg-r12 svg"
                        alt={IconAlts.iconFolder}
                        style={{ height: '32px', width: '48px' }}
                      />
                    )}
                    <p className="mrg-t2">
                      {ViewContactedSpeakersModal.requested} (
                      {contactsRequestedData?.length})
                    </p>
                  </div>
                </button>
                <button
                  onClick={() => {
                    if (openedEventsFolder === 'accepted') {
                      setOpenedEventsFolder();
                    } else {
                      setOpenedEventsFolder('accepted');
                    }
                  }}
                  aria-pressed={openedEventsFolder === 'accepted'}
                  tabIndex="0"
                  className="access-ob"
                >
                  <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
                    {openedEventsFolder === 'accepted' ? (
                      <img
                        src={img_folder_open}
                        className="filter-theme mrg-r12 svg"
                        alt={IconAlts.iconFolder}
                        style={{ height: '32px', width: '48px' }}
                      />
                    ) : (
                      <img
                        src={img_folder_closed}
                        className="filter-theme mrg-r12 svg"
                        alt={IconAlts.iconFolder}
                        style={{ height: '32px', width: '48px' }}
                      />
                    )}
                    <p className="mrg-t2">
                      {ViewContactedSpeakersModal.accepted} (
                      {contactsData?.length})
                    </p>
                  </div>
                </button>
                <div className="divider mrg-t12 mrg-b24 opacity-05" />
              </>
            )}
          </>
        )}
        {showAcceptedListOnly && (
          <div className="flex-column banners__notice full-width fs16 mrg-b24  mrg-t12 mrg-t0--ph">
            {ViewContactedSpeakersModal.invitedContactsViewEventPreview}
          </div>
        )}

        <ul
          className="contacted-speakers-modal hide-scrollbar pad-l12 pad-r12  overflow-y-scroll overflow-x-hidden list-style-none "
          id="viewContactedSpeakersModalList"
        >
          {filteredList?.length > 0 ? (
            <>
              {filteredList?.map((speaker, index) => {
                let isLastItem = filteredList?.length === index + 1;

                return (
                  <li
                    key={`viewContactedSpeakersModal-${index}`}
                    style={
                      isLastItem
                        ? { marginBottom: '160px' }
                        : { marginBottom: '12px' }
                    }
                    className="mrg-t6"
                  >
                    <button
                      className={`item fs16 fwsb  access-ob ${
                        tapHighlightSelectedKey === speaker._id
                          ? 'item-selected'
                          : ''
                      }`}
                      key={`list-item-${speaker?.personal?.profileImage}`}
                      tabIndex="0"
                      onClick={() =>
                        handleMobileTap(
                          [
                            () => setTapHighlightSelectedKey(speaker._id),
                            () => setTapHighlightSelectedKey(),
                          ],
                          [() => handler(speaker), () => handleCloseModal()]
                        )
                      }
                      type="button"
                      aria-label={`${speaker.personal.firstName} ${speaker.personal.lastName}.`}
                      id={`list-item-${index}`}
                    >
                      <LoadBucketImage
                        imagePath={speaker?.personal?.profileImage}
                        imgClassName={
                          'contacted-speakers-modal--profile mrg-r6'
                        }
                        backupPath={'account_img_default.png'}
                        partialPath={'myndfull_account_images'}
                      />
                      {speaker.personal.prefix}&nbsp;
                      {speaker.personal.firstName}&nbsp;
                      {speaker.personal.lastName}
                    </button>
                  </li>
                );
              })}
            </>
          ) : openedEventsFolder ? (
            <div className="flex-row align-center justify-center">
              <img
                src={img_empty_box}
                alt={IconAlts.iconEmptyBox}
                className="mrg-r12 filter-gray svg"
                style={{ height: '28px' }}
              />
              <p className="fs18 fwn">
                {ViewContactedSpeakersModal.emptyFolder}
              </p>
            </div>
          ) : (
            !showAcceptedListOnly && (
              <div className="flex-row align-center justify-center">
                <img
                  src={arrow_circular_hallow}
                  alt={IconAlts.iconArrow}
                  className="mrg-r12 filter-gray svg"
                  style={{ height: '28px' }}
                />
                <p className="fs18 fwn">
                  {ViewContactedSpeakersModal.selectFolder}
                </p>
              </div>
            )
          )}
        </ul>
      </div>
    </div>
  );
};

export default ViewContactedSpeakersModal;

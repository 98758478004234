import { createSlice } from '@reduxjs/toolkit';

const adminSupportSlice = createSlice({
  name: 'adminSupport',
  initialState: {
    tickets: [],
    eventVerifications: [],
    searchQueries: [],
  },
  reducers: {
    //TICKETS
    setAdminSupportTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setAdminSupportTicket: (state, action) => {
      const updatedTicket = action.payload;
      const index = state.tickets.findIndex(
        (ticket) => ticket._id === updatedTicket._id
      );
      if (index !== -1) {
        state.tickets[index] = updatedTicket;
      } else {
        state.tickets.push(updatedTicket);
      }
    },
    removeAdminSupportTicketById: (state, action) => {
      state.tickets = state.tickets.filter((ev) => ev._id !== action.payload);
    },

    //SEARCH QUERY
    addAdminSupportSearchQuery: (state, action) => {
      const queryEntry = action.payload;
      const index = state.searchQueries.findIndex(
        (entry) => entry._id === queryEntry._id
      );
      if (index === -1) {
        state.searchQueries = [...state.searchQueries, queryEntry];
      }
    },

    //EVENT VERIFICATIONS
    setAdminSupportEventVerifications: (state, action) => {
      state.eventVerifications = action.payload;
    },
    setAdminSupportEventVerification: (state, action) => {
      const updatedEvent = action.payload;
      const index = state.eventVerifications?.findIndex(
        (ev) => ev._id === updatedEvent._id
      );
      if (index !== -1) {
        state.eventVerifications[index] = updatedEvent;
      } else {
        state.eventVerifications.push(updatedEvent);
      }
    },
    removeAdminSupportEventVerificationById: (state, action) => {
      state.eventVerifications = state.eventVerifications.filter(
        (ev) => ev._id !== action.payload
      );
    },
  },
});

export const {
  //TICKETS
  setAdminSupportTickets,
  setAdminSupportTicket,
  removeAdminSupportTicketById,

  //QUERY
  addAdminSupportSearchQuery,

  //EVENT VERIFICATION
  setAdminSupportEventVerification,
  setAdminSupportEventVerifications,
  removeAdminSupportEventVerificationById,
} = adminSupportSlice.actions;

export default adminSupportSlice.reducer;

//TICKETS
export const selectAdminSupportTickets = (state) => state.adminSupport?.tickets;

//QUERY
export const selectAdminSupportSearchQueries = (state) =>
  state.adminSupport?.searchQueries;

//EVENT VERIFICATIONS
export const selectAdminSupportEventVerifications = (state) =>
  state.adminSupport?.eventVerifications;

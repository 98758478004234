export async function getCountryISO(coordinates) {
  try {
    const geocodePromise = new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = {
        lat: coordinates[0],
        lng: coordinates[1],
      };

      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK' && results.length > 0) {
          const addressComponents = results[0].address_components;
          for (const component of addressComponents || []) {
            if (component.types.includes('country')) {
              resolve(component.short_name); // Resolve with country ISO code
              return;
            }
          }
          reject(new Error('Country not found in address components'));
        } else {
          reject(new Error('Geocoding failed'));
        }
      });
    });

    const countryISO = await geocodePromise;
    return countryISO;
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Geolocation error:', error);
    }
  }
}

const IconModalButton = ({
  iconName,
  iconSrc,
  altText,
  currentSelection,
  tapHighlightIconSelection,
  handleSelection,
}) => {
  const isSelected = currentSelection?.icon === iconName;
  const isHighlighted = tapHighlightIconSelection === iconName;

  return (
    <button
      className={`pos-rel highlight-b-theme ${
        isSelected || isHighlighted ? 'highlight-b2-theme--tap' : ''
      } access-ob access-o6`}
      onClick={() => handleSelection(iconName)}
      tabIndex="0"
    >
      <img
        src={iconSrc}
        alt={altText}
        className="select-agenda-icon filter-gray"
      />
      {isSelected && (
        <div className="agenda-icon-selection-dot__container">
          <div className="agenda-icon-selection-dot" />
        </div>
      )}
    </button>
  );
};
export default IconModalButton;

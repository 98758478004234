import { useState, useRef, useEffect } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//*use textAlign to align text
const Counter = ({
  handleCounter,
  id,
  loadValue,
  increment,
  ariaCounterInputLabel,
  minValue,
  maxValue,
  textAlign,
  loadTrigger,
  customWrapper,
}) => {
  //Hooks
  const { handleMobileTap, dismissKeypad } = useSettings();

  //language
  const { Generic } = useLanguageComponents();

  //Component state
  const [num, setNum] = useState(loadValue ? loadValue : minValue);

  //UI state
  const [tapHighlightIncrement, setTapHighlightIncrement] = useState(false);
  const [tapHighlightDecrement, setTapHighlightDecrement] = useState(false);
  const [inputCounterFocus, setInputCounterFocus] = useState(false);

  //Component variables
  const inputRef = useRef();

  useEffect(() => {
    if (loadTrigger) {
      setNum(loadValue);
    }
  }, [loadTrigger]);

  //Component functions
  function selectText() {
    inputRef.current.focus();
    inputRef.current.select();
  }

  function handleAdd() {
    const val = inputRef.current.value;
    if (val < maxValue) {
      const newNum = num + increment;
      setNum(newNum);
      handleCounter(newNum);
    }
  }

  function handleSubtract() {
    if (num !== minValue) {
      let newNum = num - increment;
      if (newNum < minValue) {
        newNum = minValue;
      }
      setNum(newNum);
      handleCounter(newNum);
    }
  }

  function handleChange() {
    let val = inputRef.current.value;

    if (val === '' || isNaN(val) || val < minValue) {
      setNum(minValue);
      handleCounter(minValue);
    } else if (val > maxValue) {
      setNum(maxValue);
      handleCounter(maxValue);
    } else {
      const roundedNum = Math.round(val);
      setNum(roundedNum);
      handleCounter(roundedNum);
    }
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      // Check if the click event occurred outside of the input field
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        inputRef.current.blur(); // Remove focus from the input field
      }
    };

    document.body.addEventListener('click', handleDocumentClick);

    return () => {
      document.body.removeEventListener('click', handleDocumentClick);
    };
  }, []); //used to remove focus when you click off the counter; selectText function is not being called in response to the click outside the input field.

  return (
    <div
      className={`counter__wrapper ${customWrapper ? `${customWrapper}` : ''}`}
    >
      <button
        className={`counter-button fwsb fs21 highlight-bthin-theme highlight-it-theme access-ob access-o6 ${
          tapHighlightDecrement ? 'highlight-tb-theme--tap' : ''
        }`}
        id={`-${increment}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightDecrement(true),
              () => setTapHighlightDecrement(false),
            ],
            [() => handleSubtract()]
          )
        }
        type="button"
        aria-label={`${Generic.subtract} ${increment}.`}
      >
        -
      </button>
      <div className={`count__wrapper `}>
        <input
          type="number"
          className={`count__container highlight-o-theme fs18 ${
            textAlign ? textAlign : ''
          }`}
          onChange={handleChange}
          onKeyDown={(e) => dismissKeypad(e)}
          id={id}
          value={num ? num : ''}
          onClick={selectText}
          ref={inputRef}
          placeholder={!inputCounterFocus && !num ? `${minValue}` : ''}
          onFocus={() => setInputCounterFocus(true)}
          onBlur={() => setInputCounterFocus(false)}
          aria-label={ariaCounterInputLabel}
          enterKeyHint="done"
        />
      </div>
      <button
        type="button"
        className={`counter-button fwsb fs21 highlight-it-theme highlight-bthin-theme access-ob access-o6 ${
          tapHighlightIncrement ? 'highlight-tb-theme--tap' : ''
        }`}
        id={`+${increment}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightIncrement(true),
              () => setTapHighlightIncrement(false),
            ],
            [() => handleAdd()]
          )
        }
        aria-label={`${Generic.add} ${increment}.`}
      >
        +
      </button>
    </div>
  );
};

export default Counter;

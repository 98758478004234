import { useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';

//component
import Spinner from '../Spinner/Spinner';

//utils
import { textUI } from '../../utils/UI/textUI';

const ButtonTextSimple = ({
  text,
  handler,
  ariaText,
  id,
  iconOn,
  icon,
  iconAlt,
  iconClass,
  iconRight,
  iconStyle,
  customClassText,
  customWrapper,
  hideBorderOn,
  title,
  isLoading,
  //
}) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //UI
  const [tapHighlightOrderBtn, setTapHighlightOrderBtn] = useState(false);

  //func
  function handleBtn() {
    handleMobileTap(
      [
        () => setTapHighlightOrderBtn(true),
        () => setTapHighlightOrderBtn(false),
      ],
      [() => handler(id)],
      true
    );
  }

  return (
    <button
      onClick={handleBtn}
      className={`button-text-simple ${
        tapHighlightOrderBtn && !hideBorderOn
          ? 'button-text-simple-border--tap '
          : !hideBorderOn
          ? 'button-text-simple-border'
          : ''
      } ${customWrapper ? customWrapper : ''} `}
      aria-label={`${ariaText}`}
      title={title || null}
    >
      {isLoading && (
        <div className="button-text-simple__spinner-wrapper">
          <Spinner minHeight={'100%'} maxHeight={'42px'} />
        </div>
      )}
      <div
        className={`flex-row nowrap align-center ${
          isLoading ? 'opacity-0' : ''
        }`}
      >
        {iconOn && !iconRight && (
          <img
            src={icon}
            alt={iconAlt || ''}
            className={iconClass}
            style={iconStyle ? iconStyle : null}
          />
        )}
        {text && <div>{textUI(text, customClassText, true)}</div>}
        {iconOn && iconRight && (
          <img
            src={icon}
            alt={iconAlt || ''}
            className={iconClass}
            style={iconStyle ? iconStyle : null}
          />
        )}
      </div>
    </button>
  );
};

export default ButtonTextSimple;

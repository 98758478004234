//language
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';

//utils
import {
  edit,
  refresh,
  return_curved_arrow,
  trash,
} from '../../../../../assets/icons';

//used in organize participants, in which case this component does not handle speaker removes (organize/participants manages add/removing participants)

const SpeakerActionBtns = ({
  speaker,
  handleSaveAndReturn,
  setEditObj,
  editObj,
  handleRemoveSpeaker,
}) => {
  //language
  const { EventParticipant } = useLanguageOrganize();

  return (
    <div className="flex-row flex-column--ph full-width space-evenly flex-center">
      <ButtonTextSimple
        handler={() => {
          handleSaveAndReturn({ ...speaker });
          setEditObj();
        }}
        icon={return_curved_arrow}
        iconOn={true}
        iconClass={'filter-gray mrg-r12'}
        iconStyle={{ height: '18px' }}
        text={
          speaker?.organizedAs === 'create' || editObj
            ? EventParticipant.saveAndReturn
            : EventParticipant.return
        }
        customWrapper={'text-gray full-width'}
      />

      {handleRemoveSpeaker && !editObj && (
        <ButtonTextSimple
          handler={() => handleRemoveSpeaker(speaker?.id)}
          icon={trash}
          iconOn={true}
          iconClass={'filter-gray mrg-r12'}
          iconStyle={{ height: '18px' }}
          text={EventParticipant.remove}
          id={'removeButton'}
          customWrapper={'text-gray mrg-t36--ph'}
        />
      )}

      {speaker?.organizedAs !== 'create' &&
        speaker?.dispatches?.translationsStatus === 'accepted' &&
        !editObj && (
          <ButtonTextSimple
            handler={() => setEditObj(speaker)}
            icon={edit}
            iconOn={true}
            iconClass={'filter-gray mrg-r12'}
            iconStyle={{ height: '18px' }}
            text={EventParticipant.editMode}
            customWrapper={'text-gray highlight-it-theme'}
          />
        )}
      {editObj && (
        <ButtonTextSimple
          handler={() => handleSaveAndReturn({ ...editObj })}
          icon={refresh}
          iconOn={true}
          iconClass={'filter-gray mrg-r12'}
          iconStyle={{ height: '18px' }}
          text={EventParticipant.resetToPreviousData}
          customWrapper={'text-gray highlight-it-theme'}
        />
      )}
    </div>
  );
};

export default SpeakerActionBtns;

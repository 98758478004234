let lastSelectedColor = null;

export function getRandomColor() {
  const preSelectedHexColors = [
    '#0AD591',
    '#016D73',
    '#FF5714',
    '#071E22',
    '#7E4E60',
    '#0E0E52',
    '#DB2955',
    '#011936',
    '#592E83',
  ];

  let randomIndex;
  let newColor;

  do {
    randomIndex = Math.floor(Math.random() * preSelectedHexColors.length);
    newColor = preSelectedHexColors[randomIndex];
  } while (newColor === lastSelectedColor);

  lastSelectedColor = newColor;

  return newColor;
}

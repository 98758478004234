import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider.js';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom.js';
import useLanguageData from '../../../../../language/useLanguageData.js';
import useLanguageComponents from '../../../../../language/useLanguageComponents.js';

//components
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple.js';

//utility
import NoSleep from 'nosleep.js';
import {
  clock,
  icon_profile,
  img_checklist,
  img_refresh_two_circular,
  speaker_dark,
  checkmark_green,
  img_examine_page,
  img_no_sleep,
  img_microphone_on,
  cancel,
  img_sleep,
} from '../../../../../assets/icons';

const RoomContentMobile = ({
  changeSpeaker,
  currentSpeaker,
  Generic,
  handleGuide,
  handleOpenSpeakerDetails,
  isHost,
  isHostPresent,
  isListening,
  microphoneAccess,
  occupantsNum,
  promptMicrophonePermission,
  roomCode,
  roomLanguageOpts,
  setTranslationLang,
  speakersData,
  toggleListening,
  translationLang,
  translationRoomSocketStatus,
}) => {
  //hooks
  const { contentLanguage, width, handleMobileTap, isMobileDevice } =
    useSettings();

  //language
  const { TranslationRoom } = useLanguageTranslationRoom();
  const { TranslationLanguagesSingular } = useLanguageData();
  const { IconAlts } = useLanguageComponents();

  //state
  const [speakersOpts, setSpeakersOpts] = useState();
  const [filteredHostTextLanguageOpts, setFilteredHostTextLanguageOpts] =
    useState();
  const [noScreenTimeout, setNoScreenTimeout] = useState(false);
  const [noSleep, setNoSleep] = useState(new NoSleep());

  //UI
  const [tapHighlightOpenSpeakerDetails, setTapHighlightOpenSpeakerDetails] =
    useState(false);

  //Initialize
  useEffect(() => {
    if (roomLanguageOpts) {
      const newRoomLanguageOpts = Object.entries(roomLanguageOpts).reduce(
        (acc, [key, value]) => {
          if (key !== translationLang) {
            acc[key] = value;
          }
          return acc;
        },
        {}
      );
      setFilteredHostTextLanguageOpts(newRoomLanguageOpts);
    }
  }, [roomLanguageOpts, contentLanguage, translationLang]);

  useEffect(() => {
    let newSpeakerOpts = {};
    speakersData?.map((speaker) => {
      return (newSpeakerOpts = {
        ...newSpeakerOpts,
        [speaker.id]: `${speaker.name}`,
      });
    });
    setSpeakersOpts(newSpeakerOpts);
  }, [speakersData, currentSpeaker]);

  function handleNoScreenTimeout() {
    if (noScreenTimeout) {
      noSleep.disable();
      setNoScreenTimeout(false);
    } else {
      noSleep.enable();
      setNoScreenTimeout(true);
    }
  }

  useEffect(() => {
    return () => {
      noSleep.disable();
    };
  }, []);

  return (
    <>
      <div
        className={`translations-room__container access-ob access-o6 mrg-t24 ${
          isHost ? 'translations-max-width' : ''
        }`}
        tabIndex="0"
        id={'translationRoomHeader'}
      >
        <div className="flex-column">
          <div className="translations-selections mrg-auto">
            {isHost && (
              <ItemButton
                text={TranslationRoom.quickGuide}
                handler={handleGuide}
                icon={img_checklist}
                iconOn={true}
                iconStyle={{ height: '26px' }}
                customWrapper={'action-btns mrg-b24'}
              />
            )}
          </div>

          {roomCode && (
            <div className="flex-column align-center full-width mrg-b24 border-thin pad-12">
              <div className="flex-row">
                <div className="translations-room-code-container full-width fwb">
                  <div
                    className="space-around ff2 access-ob access-o6"
                    tabIndex="0"
                  >
                    <span>{roomCode[0]}</span>
                    <span className="mrg-l6">{roomCode[1]}</span>
                    <span className="mrg-l6">{roomCode[2]}</span>
                    <span className="mrg-l6">{roomCode[3]}</span>
                    {roomCode[4] && (
                      <span className="mrg-l6">{roomCode[4]}</span>
                    )}
                    {roomCode[5] && (
                      <span className="mrg-l6">{roomCode[5]}</span>
                    )}
                    {roomCode[6] && (
                      <span className="mrg-l6">{roomCode[6]}</span>
                    )}
                    {roomCode[7] && (
                      <span className="mrg-l6">{roomCode[7]}</span>
                    )}
                    {roomCode[8] && (
                      <span className="mrg-l6">{roomCode[8]}</span>
                    )}
                    {roomCode[9] && (
                      <span className="mrg-l6">{roomCode[9]}</span>
                    )}
                    {roomCode[10] && (
                      <span className="mrg-l6">{roomCode[10]}</span>
                    )}
                    {roomCode[11] && (
                      <span className="mrg-l6">{roomCode[11]}</span>
                    )}
                  </div>
                </div>

                <div className="live-connection-icon mrg-l12">
                  <img
                    src={img_refresh_two_circular}
                    alt={IconAlts.iconReconnect}
                    className={`svg ${
                      translationRoomSocketStatus !== 'connected'
                        ? 'live-connection-status--pending'
                        : 'remove'
                    }`}
                    title={Generic.attemptingToReconnect}
                    style={{ top: '0px', bottom: '0px' }}
                  />
                </div>
              </div>

              {isHost ? (
                <div
                  className="flex-column full-width access-ob access-o6"
                  tabIndex="0"
                >
                  <div className="flex-row  flex-column--ph space-evenly align-center full-width">
                    <div className="flex-row align-center flex-end mrg-t12">
                      <p className="mrg-r12 fs18 fwsb">
                        {TranslationRoom.attendance}
                      </p>
                      <p className="fs18 fwsb no-select">{occupantsNum || 0}</p>
                      <img
                        src={icon_profile}
                        alt="img"
                        className="svg filter-gray mrg-l12"
                        aria-hidden="true"
                      />
                    </div>
                    <div
                      className="flex-row mrg-t12"
                      style={{ maxWidth: '180px', width: '180px' }}
                      title={
                        microphoneAccess
                          ? `${TranslationRoom.microphonePermission} ${TranslationRoom.granted}`
                          : `${TranslationRoom.microphonePermission} ${TranslationRoom.denied}`
                      }
                    >
                      {!microphoneAccess ? (
                        <button
                          className={`border-call-to-action pad-12 flex-center fs18 fwsb highlight-it-theme access-ob access-o6`}
                          onClick={promptMicrophonePermission}
                        >
                          {TranslationRoom.grantAccess}
                        </button>
                      ) : (
                        <p className="fs18 fwsb flex-end align-center text-center">
                          {TranslationRoom.microphonePermission}
                        </p>
                      )}
                      <img
                        src={img_microphone_on}
                        alt="img"
                        style={{ minHeight: '36px' }}
                        className="svg filter-gray flex-center mrg-l6"
                        aria-hidden="true"
                      />
                      <div
                        className="flex-center mrg-l6"
                        style={{ minWidth: '36px' }}
                      >
                        {microphoneAccess ? (
                          <img
                            src={checkmark_green}
                            alt={TranslationRoom.checkmark}
                            className="mrg-auto-tb"
                            style={{ height: '18px' }}
                          />
                        ) : (
                          <img
                            src={cancel}
                            alt={'X'}
                            className="mrg-auto-tb filter-red"
                            style={{ height: '18px' }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {isHostPresent ? (
                    <div
                      className="flex-row justify-center align-center align-center access-ob access-o6"
                      tabIndex="0"
                    >
                      <p className="mrg-r12 fs18 fwsb">
                        {TranslationRoom.hostIsReady}
                      </p>
                      <img
                        src={speaker_dark}
                        alt="img"
                        style={{ minHeight: '36px' }}
                        className="svg filter-gray"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <div
                      className="flex-row justify-center align-center access-ob access-o6"
                      tabIndex="0"
                    >
                      <p className="mrg-r12 fs18 fwsb">
                        {TranslationRoom.waitingForHost}
                      </p>
                      <img
                        src={speaker_dark}
                        alt="img"
                        style={{ minHeight: '36px' }}
                        className="svg filter-gray"
                        aria-hidden="true"
                      />
                      <img
                        src={clock}
                        className="svg mrg-l12 filter-red"
                        alt="img"
                        style={{ minHeight: '18px' }}
                        aria-hidden="true"
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {!isHost && (
            <div className="translations-header-mobile">
              <div className="flex-row flex-column--ph">
                {!isHost && isMobileDevice && (
                  <>
                    <ButtonTextSimple
                      customWrapper={`no-screen-timeout min-width-full highlight-b-theme br--standard mrg-b24--ph`}
                      icon={noScreenTimeout ? img_no_sleep : img_sleep}
                      iconAlt={
                        noScreenTimeout
                          ? TranslationRoom.screenTimeoutIsPaused
                          : TranslationRoom.preventScreenTimeout
                      }
                      iconClass={`mrg-r12 filter-gray`}
                      iconOn={true}
                      text={
                        noScreenTimeout
                          ? TranslationRoom.screenTimeoutIsPaused
                          : TranslationRoom.preventScreenTimeout
                      }
                      handler={handleNoScreenTimeout}
                    />
                  </>
                )}
              </div>

              {!isHost && !currentSpeaker ? (
                <div className="flex-column">
                  <p className="fs18 fwn mrg-b12">
                    {TranslationRoom.noActiveSpeaker}
                  </p>
                  <p className="fs18 fwn">
                    {TranslationRoom.yourLanguage}:{' '}
                    {TranslationLanguagesSingular[translationLang]}
                  </p>
                </div>
              ) : (
                !isHost &&
                currentSpeaker && (
                  <div className="flex-column">
                    <p className="fs18 fwn mrg-b12">
                      {TranslationRoom.speaker}: {currentSpeaker.name}&nbsp;(
                      {TranslationLanguagesSingular[currentSpeaker.languageOpt]}
                      )
                    </p>
                    <p className="fs18 fwn">
                      {TranslationRoom.yourLanguage}:{' '}
                      {TranslationLanguagesSingular[translationLang]}
                    </p>
                  </div>
                )
              )}
            </div>
          )}

          {isHost && speakersOpts && (
            <div className="flex-column mrg-b24">
              <SelectDropdown
                id={'selectSpeaker'}
                list={speakersOpts}
                handleSelection={changeSpeaker}
                internalLabel={TranslationRoom.currentSpeaker}
                asterisk={true}
                fullWidth={true}
                tempDisable={isListening}
                temporaryDisableMessage={'cannotSelectWhileRecording'}
                defaultKey={currentSpeaker?.id ? currentSpeaker?.id : 'select'}
                secondButtonToggle={currentSpeaker ? true : false}
                secondButtonId={'openSpeakerDetailsBtn'}
                secondButtonClass={'openSpeakerDetails highlight-i-lgt'}
                secondButtonTitle={TranslationRoom.openSpeakerDetails}
                secondButtonImg={img_examine_page}
                secondButtonFunction={handleOpenSpeakerDetails}
                secondButtonPadding={'9px'}
              />
            </div>
          )}
          {isHost && width < 480 && currentSpeaker && (
            <button
              className={`align-center mrg-auto-lr fs16 fwn mrg-b24 highlight-i-lgt ${
                tapHighlightOpenSpeakerDetails ? 'highlight-i-theme--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightOpenSpeakerDetails(true),
                    () => setTapHighlightOpenSpeakerDetails(false),
                  ],
                  [() => handleOpenSpeakerDetails()]
                )
              }
            >
              <img
                src={img_examine_page}
                alt={TranslationRoom.openSpeakerDetails}
                className="mrg-r12"
              />
              {TranslationRoom.openSpeakerDetails}
            </button>
          )}

          {isHost && roomLanguageOpts && (
            <div className="flex-column space-evenly full-width">
              <SelectDropdown
                id={'textLanguage'}
                list={roomLanguageOpts}
                filteredList={filteredHostTextLanguageOpts}
                handleSelection={setTranslationLang}
                internalLabel={TranslationRoom.textLanguage}
                defaultKey={
                  translationLang && translationLang !== 'select'
                    ? translationLang
                    : 'select'
                }
                asterisk={true}
                fullWidth={true}
                tempDisable={isListening}
                temporaryDisableMessage={'cannotSelectWhileRecording'}
                customWrapper={'mrg-b24'}
              />

              <button
                className={`translations-btn__container border-reg highlight-b-theme ${
                  !currentSpeaker ||
                  translationLang === 'select' ||
                  !translationLang
                    ? 'opacity-03'
                    : ''
                }`}
                onClick={toggleListening}
                aria-label={
                  isListening
                    ? TranslationRoom.ariaSpeechRecognitionActive
                    : TranslationRoom.ariaSpeechRecognitionInactive
                }
              >
                <div
                  id="activate-translations-btn"
                  className={`activate-translations-btn color-black ${
                    isListening
                      ? 'translations-btn-active'
                      : 'bg-color-lightgray'
                  }`}
                />
                <p className="fs16 fwsb mrg-l12">
                  {TranslationRoom.activateSpeechRecognition}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default RoomContentMobile;

// {!isHost && translationLang && translationLang !== 'select' && (
//   <button
//     onClick={() =>
//       handleMobileTap(
//         [
//           () => setTapHighlightVolumeBtn(true),
//           () => setTapHighlightVolumeBtn(false),
//         ],
//         [() => setVolumeOn(!volumeOn)]
//       )
//     }
//     className={`no-screen-timeout highlight-b-theme br--standard mrg-b24--ph  highlight-i-theme ${
//       tapHighlightVolumeBtn ? 'highlight-b2-theme--tap' : ''
//     }
//     `}
//     style={{ top: '48px', right: '36px' }}
//     aria-pressed={volumeOn}
//     aria-label={TranslationRoom.ariaVolumeToggle}
//   >
//     <p className="fwsb color-gray fs16 mrg-r12">
//       {TranslationRoom.toggleVolume}
//     </p>
//     {volumeOn ? (
//       <img
//         src={img_volume_on}
//         className="svg filter-gray"
//         alt="img"
//         aria-hidden="true"
//       />
//     ) : (
//       <img
//         src={img_volume_off}
//         className="svg filter-gray"
//         alt="img"
//         aria-hidden="true"
//       />
//     )}
//   </button>
// )}

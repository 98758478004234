import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useGenericModal } from '../../../../../../context/GenericModalProvider';

//language
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';
import ButtonMeta from '../../../../../../components/ButtonMeta/ButtonMeta';
import ImportTranslationsRoomDataModal from '../../../../../../components/Modal/ModalComponents/FolderModals/ImportTranslationsRoomDataModal';

//utility
import { image_draft } from '../../../../../../assets/images';

const TranslationsDrafts = () => {
  //hooks
  const {
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    setSelectedSaveDraftId,
  } = useTranslationRoomSocket();
  const { setGenericModal } = useGenericModal();

  //language
  const { TranslationDrafts } = useLanguageTranslationRoom();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  function handleImportTranslationsRoomDataModal() {
    setGenericModal(
      <ImportTranslationsRoomDataModal
        handler={(key) => {
          setSelectedSaveDraftId(key);
          handleCreatePageChange('organizeDrafts');
        }}
        sessionDraftsOnly={true}
      />
    );
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_draft}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p className="fs21 fwsb mrg-tb24 access-ob access-o6" tabIndex="0">
            {TranslationDrafts.sessionDrafts}
          </p>

          <ButtonMeta
            btnText={TranslationDrafts.newDraft}
            fullWidth={true}
            customClassName={'min-full-width mrg-b24'}
            handleClick={() => handleCreatePageChange('organizeDrafts')}
          />

          <ButtonMeta
            handleClick={handleImportTranslationsRoomDataModal}
            btnText={TranslationDrafts.loadDraft}
            fullWidth={true}
            customClassName={'min-full-width mrg-b24'}
            id={'translationsRoomLoadBtn'}
          />

          <ButtonMeta
            handleClick={() => handleCreatePageChange('removeDrafts')}
            btnText={TranslationDrafts.removeDraft}
            fullWidth={true}
            customClassName={'min-full-width'}
            id={'translationsRoomLoadBtn'}
          />

          <div className="divider full-width mrg-tb36" />

          <ItemButton
            text={TranslationDrafts.back}
            handler={() => handleCreatePageChange('createMainMenu')}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
};

export default TranslationsDrafts;

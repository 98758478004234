import { useEffect, useState } from 'react';

//components
import ParticipantListItemCompletionStatus from '../Generic/ParticipantListItemCompletionStatus';
import ParticipantListItemInvitationStatus from '../Generic/ParticipantListItemInvitationStatus';
import ParticipantListItemOrganizedAs from '../Generic/ParticipantListItemOrganizedAs';

const ParticipantListItemStatusParticipant = ({ p }) => {
  //state
  const [invitationHasExpired, setInvitationHasExpired] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    if (p) {
      let tokenExpiration = p.emailInvitation?.tokenExpires;
      if (tokenExpiration) {
        let expirationTimestamp = new Date(tokenExpiration).getTime();
        if (expirationTimestamp < Date.now()) {
          setInvitationHasExpired(true);
        }
      }
      setIsDispatched(p?.dispatches?.inviteDispatched);
    }
  }, [p]); //check token expiration.

  return (
    <>
      {p?.organizedAs === 'create' && (
        <div className="flex-row flex-column--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={'mrg-r6'}
          />
          <ParticipantListItemCompletionStatus
            completionStatus={p.completionStatus}
            organizedAs={p.organizedAs}
          />
        </div>
      )}

      {p?.organizedAs === 'inviteContact' && (
        <div className="flex-row flex-column--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={'mrg-r6'}
          />
          {!p?.dispatches?.inviteDispatched ? (
            <ParticipantListItemCompletionStatus
              completionStatus={p.completionStatus}
              organizedAs={p.organizedAs}
              inviteIsDispatched={isDispatched}
            />
          ) : (
            <ParticipantListItemInvitationStatus
              invitationStatus={p.dispatches.invitationStatus}
            />
          )}
        </div>
      )}
      {p?.organizedAs === 'emailInvitation' && (
        <div className="flex-row flex-column--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={'mrg-r6'}
          />

          {p?.dispatches?.invitationStatus !== 'accepted' &&
            p?.dispatches?.invitationStatus !== 'pending' && (
              <ParticipantListItemCompletionStatus
                completionStatus={p.completionStatus}
                duplicateEmail={p?.emailInvitation?.duplicateEmail}
                invalidEmail={
                  p?.emailInvitation?.email?.length > 0 &&
                  p?.emailInvitation?.emailValid === false
                }
                organizedAs={p.organizedAs}
                inviteIsDispatched={isDispatched}
              />
            )}

          {p?.dispatches?.inviteDispatched && (
            <ParticipantListItemInvitationStatus
              invitationStatus={p.dispatches.invitationStatus}
              invitationHasExpired={invitationHasExpired}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ParticipantListItemStatusParticipant;

import { useState } from 'react';
//hooks
import { useSettings } from '../../context/SettingsProvider';

const ShowCardHorizontalBtn = ({
  customWrapper,
  showCardImage,
  customShowCardImageClass,
  text,
  customText,
  handler,
  subText,
  customSubText,
  customButtonTextWrapper,
  buttonImage,
  buttonImageAlt,
  buttonImageClass,
  showCardImageAlt,
  customContainer,
  customButtonImageWrapper,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //state
  const [highlightButtonTap, setHighlightButtonTap] = useState(false);

  return (
    <button
      className={`show-card-horizontal-btn access-ob access-o6 ${
        customWrapper || ''
      } ${highlightButtonTap ? 'click-scaledown-98' : ''} `}
      onClick={() =>
        handleMobileTap(
          [
            () => setHighlightButtonTap(true),
            () => setHighlightButtonTap(false),
          ],
          [() => handler()],
          true
        )
      }
    >
      <div className={`mrg-auto-left mrg-auto-right ${customContainer || ''}`}>
        <img
          src={showCardImage}
          alt={showCardImageAlt ? showCardImageAlt : 'img'}
          className={`${
            customShowCardImageClass ? customShowCardImageClass : ''
          }`}
        />
        <div
          className={`flex-column flex-center ${
            customButtonTextWrapper ? customButtonTextWrapper : ''
          }`}
        >
          <h1 className={`${customText || ''}`}>{text}</h1>
          <h2 className={`${customSubText || ''}`}>{subText}</h2>
        </div>

        {buttonImage && (
          <div className={`${customButtonImageWrapper || ''}`}>
            <img
              className={`${buttonImageClass || ''}`}
              src={buttonImage}
              alt={buttonImageAlt}
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default ShowCardHorizontalBtn;

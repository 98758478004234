import { useState } from 'react';

//hooks
import { useAdminAccountReduxHandlers } from '../../../account/useAdminAccountHandlers';
import { useAdminSupportReduxHandlers } from '../../../support/useAdminSupportHandlers';
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useAdminSupportEventVerificationHandlers from '../../../support/EventVerification/useAdminSupportEventVerificationHandlers';
import useLanguageData from '../../../../../language/useLanguageData';

//components
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import TextArea from '../../../../../components/TextArea/TextArea';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import Spinner from '../../../../../components/Spinner/Spinner';
import CheckBoxObjSelections from '../../../../../components/CheckBoxes/CheckBoxObjSelections';
import RadialButtonList from '../../../../../components/RadialButtonList/RadialButtonList';

//ui
import { edit, preview } from '../../../../../assets/icons';

const InfoCardActionsEventVerification = ({
  idData,
  ticketData,
  supportData,
  verificationEventData,
  cardsRowIds,
  setCardsRowIds,
}) => {
  //hooks
  const { adminAccountAdminReference, adminAccountId } =
    useAdminAccountReduxHandlers();
  const { ReportEventOpts } = useLanguageData();
  const { handleViewEvent, searchReferenceisLoading } =
    useAdminSupportReduxHandlers();
  const {
    handleApproveEventVerification,
    handleDisapproveEventVerification,
    approveEventVerificationisLoading,
    adminCreateTicketisLoading,
    removeAdminEventVerificationisLoading,
    disapproveEventVerificationisLoading,
    createInactiveEventVerificationisLoading,
  } = useAdminSupportEventVerificationHandlers();
  const { ReportModal } = useLanguageComponents();

  //state
  const [notes, setNotes] = useState('');
  const [adminActionSelection, setAdminActionSelection] = useState('');

  //create ticket
  const [ticketKey, setTicketKey] = useState();
  const [otherTicketDescription, setOtherTicketDescription] = useState();
  const [ticketDetailedDescription, setTicketDetailedDescription] = useState();
  const [claimAssignment, setClaimAssignment] = useState(false);

  const [suspendEvent, setSuspendEvent] = useState(false);

  //functions
  function handleAdminActionSubmit() {
    if (adminActionSelection === 'approve') {
      handleApproveEventVerification(idData, notes, adminAccountAdminReference);
    }

    if (adminActionSelection === 'disapprove') {
      handleDisapproveEventVerification(
        idData,
        adminAccountAdminReference,
        'event',
        verificationEventData?.eventReference,
        ticketKey,
        otherTicketDescription,
        ticketDetailedDescription,
        claimAssignment
      );
    }

    setAdminActionSelection('');
    return setNotes('');
  }

  return (
    <>
      {approveEventVerificationisLoading ||
      adminCreateTicketisLoading ||
      removeAdminEventVerificationisLoading ||
      disapproveEventVerificationisLoading ||
      createInactiveEventVerificationisLoading ? (
        <Spinner />
      ) : (
        <>
          {supportData?.current?._id !== adminAccountId &&
            ticketData?.status !== 'resolved' && (
              <div className="flex-row">
                <div className="support-ticket__actions mrg-b48">
                  <ButtonMeta
                    handleClick={() =>
                      handleViewEvent(
                        verificationEventData?.eventReference,
                        cardsRowIds,
                        setCardsRowIds
                      )
                    }
                    btnText={'View Event'}
                    icon={preview}
                    customClassName={'highlight-i-theme mrg-r24'}
                    isLoading={searchReferenceisLoading}
                  />

                  <ButtonMeta
                    handleClick={() => {
                      if (adminActionSelection === 'approve') {
                        return setAdminActionSelection('');
                      } else {
                        setAdminActionSelection('approve');
                      }
                    }}
                    activeStateDependency={adminActionSelection === 'approve'}
                    btnText={'Approve'}
                    customClassName={'mrg-r24'}
                  />

                  <ButtonMeta
                    handleClick={() => {
                      if (adminActionSelection === 'disapprove') {
                        return setAdminActionSelection('');
                      } else {
                        setAdminActionSelection('disapprove');
                      }
                    }}
                    activeStateDependency={
                      adminActionSelection === 'disapprove'
                    }
                    btnText={'Disapprove'}
                  />
                </div>
              </div>
            )}

          {adminActionSelection && (
            <div className="flex-column mrg-b48">
              <div className="flex-row">
                {adminActionSelection === 'disapprove' ? (
                  <div className="flex-column full-width">
                    <div className="mrg-l48">
                      <p className="fs21 fwsb mrg-b12">Ticket Violation</p>
                      <RadialButtonList
                        onChange={setTicketKey}
                        options={ReportEventOpts}
                        id={'reportViolations'}
                      />
                      {ticketKey === 'other' && (
                        <>
                          <div className="h12" />
                          <TextArea
                            customWidth={'100%'}
                            disableEnter={true}
                            handleChange={setOtherTicketDescription}
                            hideWordCount={true}
                            id={'otherViolationTitle'}
                            labelText={ReportModal.otherViolationTitle}
                            limit={100}
                            onBlurHandlerOff={true}
                            asterisk={true}
                          />
                        </>
                      )}
                    </div>

                    <div className="flex-row mrg-t24">
                      <img
                        src={edit}
                        alt="editIcon"
                        className="mrg-r12 filter-gray mrg-auto-bottom"
                      />

                      <TextArea
                        customWidth={'100%'}
                        handleChange={setTicketDetailedDescription}
                        hideWordCount={true}
                        labelText={ReportModal.description}
                        id={'ticketDetailedDescription'}
                        limit={2000}
                        large={true}
                        customMinHeight={'360px'}
                        onBlurHandlerOff={true}
                        asterisk={true}
                      />
                    </div>
                    <div className="h24" />
                    <div className="mrg-l48">
                      <CheckBoxObjSelections
                        visible={true}
                        handler={() => setSuspendEvent(!suspendEvent)}
                        selectionObj={{ suspendEvent: 'Suspend Event' }}
                        loadTrigger={false}
                        mrgCenter={true}
                        handleAccountId={true}
                      />
                      <CheckBoxObjSelections
                        visible={true}
                        handler={() => setClaimAssignment(!claimAssignment)}
                        selectionObj={{ claimAssignment: 'Claim Assignment' }}
                        loadTrigger={false}
                        mrgCenter={true}
                        handleAccountId={true}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="flex-row full-width">
                    <img
                      src={edit}
                      alt="editIcon"
                      className="mrg-r12 filter-gray mrg-auto-bottom"
                    />
                    <TextArea
                      id={'reportNotes'}
                      limit={10000}
                      hideWordCount={true}
                      labelText={'Action Notes'}
                      handleChange={setNotes}
                    />
                  </div>
                )}
              </div>
              <div className="flex-row">
                <ConfirmSelection
                  btn1Text={'Cancel'}
                  btn1Color={'neutral'}
                  btn1Handler={() => setAdminActionSelection('')}
                  btn2Text={'Confirm'}
                  btn2Color={'pos'}
                  btn2Handler={handleAdminActionSubmit}
                  ignoreMaxWidth={true}
                  customWrapper={'mrg-t24'}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfoCardActionsEventVerification;

import { useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';

//Utility
import { cancel } from '../../assets/icons';

const FollowTagButton = ({
  modifyGroup,
  accountObj,
  handleTagClick,
  CustomLanguage,
  index,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //language
  const { Generic, IconAlts } = useLanguageComponents();

  //UI state
  const [tapHighlightTag, setTapHighlightTag] = useState();

  function handleClick() {
    handleMobileTap(
      [() => setTapHighlightTag(true), () => setTapHighlightTag(false)],
      [() => handleTagClick(accountObj)]
    );
  }

  return (
    <div
      className="follow-tag-button"
      key={`follow-tag-${accountObj?.personal?.profileImage}`}
    >
      <LoadBucketImage
        imagePath={accountObj?.personal?.profileImage}
        imgClassName={'tag-profile-img tag-profile-community mrg-r6'}
        backupPath={'account_img_default.png'}
        partialPath={'myndfull_account_images'}
      />
      <button
        className={`tag__list-item highlight-bthin-theme fwsb fs16 fs14-phn no-select access-ob access-o6 ${
          modifyGroup
            ? 'tag__list-item--add highlight-i-lgg'
            : 'highlight-i-lgr'
        } ${tapHighlightTag ? 'highlight-b-theme--tap' : ''}`}
        onClick={handleClick}
        aria-label={`${accountObj?.firstName} ${accountObj?.lastName} ${
          modifyGroup ? CustomLanguage.ariaAddAccount : Generic.remove
        } `}
      >
        <p>
          {accountObj?.personal?.firstName} {accountObj?.personal?.lastName}
        </p>
        <img
          alt={IconAlts.iconClose}
          className={`disable-select tag-remove-btn-img ${
            modifyGroup ? 'add-tag rotate-cw45' : ''
          }`}
          src={cancel}
        />
      </button>
    </div>
  );
};

export default FollowTagButton;

import {
  useAddSaveByUserMutation,
  useRemoveSaveByUserMutation,
  useAddIgnoreByUserMutation,
  useRemoveIgnoreByUserMutation,
  useAddRegInPersonByUserMutation,
  useRemoveRegInPersonByUserMutation,
  useAddApprovalInPersonByUserMutation,
  useRemoveApprovalInPersonByUserMutation,
  useRemoveArchivedEventMutation,
  useAddRegVirtualByUserMutation,
  useRemoveRegVirtualByUserMutation,
  useAddApprovalVirtualByUserMutation,
  useRemoveApprovalVirtualByUserMutation,
  useLazyGetEventWithAccountQuery,
} from './eventsApiSlice';

//Hooks
import { useApiStatus } from '../../../context/ApiStatusProvider';
import { useGenericModal } from '../../../context/GenericModalProvider';
import useLanguageEvents from '../../../language/features/useLanguageEvents';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';

//Components
import ConfirmationModal from '../../../components/Modal/ModalComponents/ConfirmationModal';

//Utility
import { sortEventsByDateTime } from '../../../utils/dateTime/DateCalc';
import { sortEventsByDistance } from '../../../utils/spatial/DistanceCalc';
import { axiosLimited } from '../../../axios/axios';

export const useEventHandlers = () => {
  //hooks
  const { handleWarning, handleError } = useApiStatus();
  const { setGenericModalMobileFloat } = useGenericModal();
  const { RemoveArchivedEventModal } = useLanguageEvents();
  const {
    accountGeoPosition,
    accountDistanceMeasurement,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountSavedEvents,
    accountParticipantDrafts,
    accountParticipantEvents,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  } = useAccountReduxHandlers();
  const {
    settingsEventSearchCategory,
    settingsSelectedMyEvents,
    settingsEventSearchDirection,
    settingsEventSearchView,
    settingsEventSearchFormat,
    handleSettingsSetEventSearchFormat,
    handleSettingsSetEventView,
  } = useSettingsHandlers();

  //eventsApi
  const [addSaveByUser] = useAddSaveByUserMutation();
  const [removeSaveByUser] = useRemoveSaveByUserMutation();
  const [addIgnoreByUser] = useAddIgnoreByUserMutation();
  const [removeIgnoreByUser] = useRemoveIgnoreByUserMutation();
  const [addRegInPersonByUser] = useAddRegInPersonByUserMutation();
  const [removeRegInPersonByUser] = useRemoveRegInPersonByUserMutation();
  const [addApprovalInPersonByUser] = useAddApprovalInPersonByUserMutation();
  const [removeApprovalInPersonByUser] =
    useRemoveApprovalInPersonByUserMutation();
  const [removeArchivedEvent] = useRemoveArchivedEventMutation();
  const [addRegVirtualByUser] = useAddRegVirtualByUserMutation();
  const [removeRegVirtualByUser] = useRemoveRegVirtualByUserMutation();
  const [addApprovalVirtualByUser] = useAddApprovalVirtualByUserMutation();
  const [removeApprovalVirtualByUser] =
    useRemoveApprovalVirtualByUserMutation();

  const [getEventWithAccount, { isLoading: getEventWithAccountIsLoading }] =
    useLazyGetEventWithAccountQuery();

  //note: adding save and adding ignore has extra precautions in backend to ensure if the user is pendingapproval or registered it wont execute both backend and front end UI changes

  //sorted myEvents

  //event ids
  function filterMyEvents(myEvents) {
    let selectedEventIds = [];
    let selectedEvents = [];

    if (settingsSelectedMyEvents.includes('registered')) {
      accountPendingApprovalInPerson.forEach((ev) => selectedEventIds.push(ev));
      accountPendingApprovalVirtual.forEach((ev) => selectedEventIds.push(ev));
      accountRegistrationsInPerson.forEach((ev) => selectedEventIds.push(ev));
      accountRegistrationsVirtual.forEach((ev) => selectedEventIds.push(ev));
    }

    if (settingsSelectedMyEvents.includes('participationEvents')) {
      accountParticipantDrafts.forEach((ev) => selectedEventIds.push(ev));
      accountParticipantEvents.forEach((ev) => selectedEventIds.push(ev));
    }

    if (settingsSelectedMyEvents.includes('saved')) {
      accountSavedEvents.forEach((ev) => selectedEventIds.push(ev));
    }

    for (const myEvent of myEvents || []) {
      if (selectedEventIds.includes(myEvent?._id)) {
        selectedEvents.push(myEvent);
      }
    }

    return selectedEvents;
  }

  function sortMyEvents(eventsData) {
    if (settingsEventSearchCategory === 'date') {
      const sortedArr = sortEventsByDateTime(
        eventsData,
        settingsEventSearchDirection
      );
      return sortedArr;
    } else if (settingsEventSearchCategory === 'distance') {
      const sortedArr = sortEventsByDistance(
        eventsData,
        settingsEventSearchDirection,
        accountGeoPosition,
        accountDistanceMeasurement
      );
      return sortedArr;
    }
  }

  function handleMyEventsSorted(myEventsData) {
    //use to be selector but was having trouble force refetching/invalidating with same args of accountId
    const filteredEvents = filterMyEvents(myEventsData);
    const sortedEvents = sortMyEvents(filteredEvents);
    return sortedEvents || [];
  }

  //API calls through Redux
  async function handleSaveEventAPI(eventId, accountId, setEventStatus) {
    try {
      const res = await addSaveByUser({ eventId, accountId });
      if (res.data.status === 'success' && setEventStatus) {
        setEventStatus('saved');
      }
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveSavedEventAPI(eventId, accountId) {
    try {
      const res = await removeSaveByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveArchivedEventAPI(eventId, accountId) {
    try {
      const res = await removeArchivedEvent({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleIgnoreEventAPI(eventId, accountId, setEventStatus) {
    try {
      const res = await addIgnoreByUser({ eventId, accountId });
      if (res.data.status === 'success' && setEventStatus) {
        setEventStatus('ignored');
      }
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRemoveIgnoredEventAPI(eventId, accountId) {
    try {
      const res = await removeIgnoreByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
    }
  }

  async function handleRegisterInPersonAPI(eventId, accountId) {
    try {
      const res = await addRegInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  async function handleUnregisterInPersonAPI(eventId, accountId) {
    try {
      const res = await removeRegInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  async function handleAddApprovalInPersonAPI(eventId, accountId) {
    try {
      const res = await addApprovalInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  async function handleRemoveApprovalInPersonAPI(eventId, accountId) {
    try {
      const res = await removeApprovalInPersonByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  /////VIRTUAL ONLY
  async function handleAddApprovalVirtualAPI(eventId, accountId) {
    try {
      const res = await addApprovalVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  async function handleRemoveApprovalVirtualAPI(eventId, accountId) {
    try {
      const res = await removeApprovalVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  async function handleRegisterVirtualAPI(eventId, accountId) {
    try {
      const res = await addRegVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  async function handleUnregisterVirtualAPI(eventId, accountId) {
    try {
      const res = await removeRegVirtualByUser({ eventId, accountId });
      return res.data.status;
    } catch (err) {
      if (process.env.REACT_APP_ENV === 'production') {
        console.log(err);
      }
      throw err;
    }
  }

  /////////////Front end handlers
  function handleRemoveArchivedEvent(eventId, accountId, eventReference) {
    setGenericModalMobileFloat(
      <ConfirmationModal
        headingText={RemoveArchivedEventModal.removeArchivedEventWarning}
        button1Text={RemoveArchivedEventModal.yes}
        button2Text={RemoveArchivedEventModal.no}
        focusId={`${eventReference}`}
        handler={() => handleRemoveArchivedEventAPI(eventId, accountId)}
      />
    );
  }

  function handleUserSaveEvent(
    eventId,
    accountId,
    eventStatus,
    setEventStatus
  ) {
    if (
      accountRegistrationsInPerson?.includes(eventId) ||
      accountPendingApprovalInPerson?.includes(eventId)
    ) {
      return handleWarning({
        message: 'cannotPerformActionWhenRegisteredForThisEvent',
        id: Date.now(),
        origin: 'useEventHandlers.js/handleUserSaveEvent',
      });
    }
    if (eventStatus !== 'saved') {
      //setEventStatus passed to this function bec its an important function to ensure there is no desync data
      handleSaveEventAPI(eventId, accountId, setEventStatus);
    } else {
      setEventStatus('neutral');
      handleRemoveSavedEventAPI(eventId, accountId);
    }
  }

  async function handleUserIgnoreEvent(
    eventId,
    accountId,
    eventStatus,
    setEventStatus
  ) {
    if (
      accountRegistrationsInPerson?.includes(eventId) ||
      accountPendingApprovalInPerson?.includes(eventId)
    ) {
      return handleWarning({
        message: 'cannotPerformActionWhenRegisteredForThisEvent',
        id: Date.now(),
        origin: 'useEventHandlers.js/handleUserIgnoreEvent',
      });
    }
    if (eventStatus !== 'ignored') {
      //setEventStatus passed to this function bec its an important function to ensure there is no desync data
      await handleIgnoreEventAPI(eventId, accountId, setEventStatus);
    }
    if (eventStatus === 'ignored') {
      setEventStatus('neutral');
      await handleRemoveIgnoredEventAPI(eventId, accountId);
    }
  }

  async function handleUserInPersonRegistration(
    eventData,
    accountId,
    eventStatus,
    setActionBtnsIsLoading,
    setEventData,
    updateEventDataListPostRegistration
  ) {
    setActionBtnsIsLoading(true);

    async function getUpdatedData() {
      try {
        //get event data
        const newEventData = await handleGetEventWithAccount({
          accountId,
          eventId: eventData._id,
          setEventData,
          returnEventData: true,
        });
        //return event data
        updateEventDataListPostRegistration(newEventData);
      } catch (error) {
        throw error;
      }
    }

    try {
      //switch pendingApproval from Virtual to InPerson
      if (eventStatus === 'pendingApprovalVirtual') {
        //check for add approve
        if (eventData.registrationData.approveRegistrations) {
          // event status should become ('pendingApprovalInPerson');
          await handleRemoveApprovalVirtualAPI(eventData._id, accountId);
          await handleAddApprovalInPersonAPI(eventData._id, accountId);

          await getUpdatedData();
          return setActionBtnsIsLoading(false);
        } else {
          //no approve but there is maximum
          if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
            //below current max -- add reg
            if (
              eventData.registrationData.registeredAttendeesInPerson?.length <
              eventData.registrationData.maxRegistrationsInPersonAmount
            ) {
              // event status should become ('registeredInPerson');
              await handleRemoveApprovalVirtualAPI(eventData._id, accountId);
              await handleRegisterInPersonAPI(eventData._id, accountId);
              await getUpdatedData();
              return setActionBtnsIsLoading(false);
            } else {
              //equal or above current max -- add approval
              // event status should become ('pendingApprovalInPerson');
              await handleAddApprovalInPersonAPI(eventData._id, accountId);
              await handleRemoveApprovalVirtualAPI(eventData._id, accountId);
              await getUpdatedData();
              return setActionBtnsIsLoading(false);
            }
          } else {
            // event status should become ('registeredInPerson');
            await handleRemoveApprovalVirtualAPI(eventData._id, accountId);
            await handleRegisterInPersonAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          }
        }
      }

      //switch registration from Virtual to InPerson
      if (eventStatus === 'registeredVirtual') {
        if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
          if (
            eventData.registrationData.registeredAttendeesInPerson?.length <
            eventData.registrationData.maxRegistrationsInPersonAmount
          ) {
            // event status should become ('registeredInPerson');
            await handleUnregisterVirtualAPI(eventData._id, accountId);
            await handleRegisterInPersonAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          } else {
            // event status should become ('pendingApprovalInPerson-registeredVirtual');
            await handleAddApprovalInPersonAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          }
        } else {
          // event status should become ('pendingApprovalInPerson-registeredVirtual');
          await handleUnregisterVirtualAPI(eventData._id, accountId);
          await handleRegisterInPersonAPI(eventData._id, accountId);
          await getUpdatedData();
          return setActionBtnsIsLoading(false);
        }
      }

      //if pendingApprovalVirtual-registeredInPerson
      if (eventStatus === 'pendingApprovalVirtual-registeredInPerson') {
        // event status should become ('pendingApprovalVirtual');
        await handleUnregisterInPersonAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      }

      if (eventStatus === 'registeredInPerson') {
        //remove if already registered
        // event status should become ('neutral');
        await handleUnregisterInPersonAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      } else if (eventStatus === 'pendingApprovalInPerson') {
        //remove if already pendingApproval
        // event status should become ('neutral');
        await handleRemoveApprovalInPersonAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      } else if (!eventData.registrationData.approveRegistrations) {
        //event does not require approval; but there is max registrations
        if (eventData.registrationData.maxRegistrationsInPersonEnabled) {
          if (
            eventData.registrationData.registeredAttendeesInPerson.length <
            eventData.registrationData.maxRegistrationsInPersonAmount
          ) {
            // event status should become ('registeredInPerson');
            await handleRegisterInPersonAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          } else {
            // event status should become ('pendingApprovalInPerson');
            await handleAddApprovalInPersonAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          }
        } else {
          // event status should become ('registeredInPerson');
          await handleRegisterInPersonAPI(eventData._id, accountId);
          await getUpdatedData();
          return setActionBtnsIsLoading(false);
        }
      } else if (eventData.registrationData.approveRegistrations) {
        //approve registrations required + wont matter if maxReg is full or not
        // event status should become ('pendingApprovalInPerson');
        await handleAddApprovalInPersonAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('DEV handleSetNewEventData error', error);
      }
      handleError({
        message: 'error',
        id: Date.now(),
        origin: 'handleUserInPersonRegistration',
        error,
      });
    }
  }

  async function handleUserVirtualRegistration(
    eventData,
    accountId,
    eventStatus,
    setActionBtnsIsLoading,
    setEventData,
    updateEventDataListPostRegistration
  ) {
    setActionBtnsIsLoading(true);

    async function getUpdatedData() {
      try {
        //get event data
        const newEventData = await handleGetEventWithAccount({
          accountId,
          eventId: eventData._id,
          setEventData,
          returnEventData: true,
        });
        //return event data
        updateEventDataListPostRegistration(newEventData);
      } catch (error) {
        throw error;
      }
    }

    try {
      //switch pendingApproval from InPerson to Virtual
      if (eventStatus === 'pendingApprovalInPerson') {
        //check for add approve
        if (eventData.registrationData.approveRegistrations) {
          //event status should become ('pendingApprovalVirtual');
          await handleRemoveApprovalInPersonAPI(eventData._id, accountId);
          await handleAddApprovalVirtualAPI(eventData._id, accountId);
          await getUpdatedData();
          return setActionBtnsIsLoading(false);
        } else {
          //no approve but there is maximum
          if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
            //below current max -- add reg
            if (
              eventData.registrationData.registeredAttendeesVirtual?.length <
              eventData.registrationData.maxRegistrationsVirtualAmount
            ) {
              //event status should become ('registeredVirtual');
              await handleRemoveApprovalInPersonAPI(eventData._id, accountId);
              await handleRegisterVirtualAPI(eventData._id, accountId);
              await getUpdatedData();
              return setActionBtnsIsLoading(false);
            } else {
              //equal or above current max -- add approval
              //event status should become ('pendingApprovalVirtual');
              await handleAddApprovalVirtualAPI(eventData._id, accountId);
              await handleRemoveApprovalInPersonAPI(eventData._id, accountId);
              await getUpdatedData();
              return setActionBtnsIsLoading(false);
            }
          } else {
            //event status should become ('registeredVirtual');
            await handleRemoveApprovalInPersonAPI(eventData._id, accountId);
            await handleRegisterVirtualAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          }
        }
      }

      //switch registration from InPerson to Virtual
      if (eventStatus === 'registeredInPerson') {
        if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
          if (
            eventData.registrationData.registeredAttendeesVirtual?.length <
            eventData.registrationData.maxRegistrationsVirtualAmount
          ) {
            //event status should become ('registeredVirtual');
            await handleUnregisterInPersonAPI(eventData._id, accountId);
            await handleRegisterVirtualAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          } else {
            //event status should become ('pendingApprovalVirtual-registeredInPerson');
            await handleAddApprovalVirtualAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          }
        } else {
          //event status should become ('pendingApprovalVirtual-registeredInPerson');
          await handleUnregisterInPersonAPI(eventData._id, accountId);
          await handleRegisterVirtualAPI(eventData._id, accountId);
          await getUpdatedData();
          return setActionBtnsIsLoading(false);
        }
      }

      //if pendingApprovalInPerson-registeredVirtual
      if (eventStatus === 'pendingApprovalVirtual-registeredInPerson') {
        //event status should become ('registeredInPerson');
        await handleRemoveApprovalVirtualAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      }

      if (eventStatus === 'registeredVirtual') {
        //remove if already registered
        //event status should become ('neutral');
        await handleUnregisterVirtualAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      } else if (eventStatus === 'pendingApprovalVirtual') {
        //remove if already pendingApproval
        //event status should become ('neutral');
        await handleRemoveApprovalVirtualAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      } else if (!eventData.registrationData.approveRegistrations) {
        //event does not require approval; but there is max registrations
        if (eventData.registrationData.maxRegistrationsVirtualEnabled) {
          if (
            eventData.registrationData.registeredAttendeesVirtual.length <
            eventData.registrationData.maxRegistrationsVirtualAmount
          ) {
            //event status should become ('registeredVirtual');
            await handleRegisterVirtualAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          } else {
            //event status should become ('pendingApprovalVirtual');
            await handleAddApprovalVirtualAPI(eventData._id, accountId);
            await getUpdatedData();
            return setActionBtnsIsLoading(false);
          }
        } else {
          //event status should become ('registeredVirtual');
          await handleRegisterVirtualAPI(eventData._id, accountId);
          await getUpdatedData();
          return setActionBtnsIsLoading(false);
        }
      } else if (eventData.registrationData.approveRegistrations) {
        //approve registrations required + wont matter if maxReg is full or not
        //event status should become ('pendingApprovalVirtual');
        await handleAddApprovalVirtualAPI(eventData._id, accountId);
        await getUpdatedData();
        return setActionBtnsIsLoading(false);
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('DEV handleSetNewEventData error', error);
      }
      handleError({
        message: 'error',
        id: Date.now(),
        origin: 'handleUserVirtualRegistration',
        error,
      });
    }
  }

  async function handleGetEvent({
    eventId,
    setEventData,
    setIsLoading,
    isLoading,
  }) {
    if (!isLoading) {
      try {
        setIsLoading(true);

        const res = await axiosLimited.get(`/api/public/events/${eventId}`);
        setEventData(res?.data?.event);
      } catch (error) {
        handleError({
          message: 'error',
          id: Date.now(),
          origin: 'useEventHandlers.js/handleGetEvent',
          error,
        });
      }
      setIsLoading(false);
    }
  }

  async function handleGetEventWithAccount({
    accountId,
    eventId,
    setEventData,
    returnEventData,
  }) {
    try {
      if (!getEventWithAccountIsLoading && eventId && accountId) {
        const response = await getEventWithAccount({ accountId, eventId });
        if (response?.data?.event) {
          setEventData(response.data.event);
          if (returnEventData) {
            return response.data.event;
          }
        }
      }
    } catch (error) {
      handleError({
        message: 'error',
        id: Date.now(),
        origin: 'useEventHandlers.js/handleGetEventWithAccount',
        error,
      });
    }
  }

  function handleGuidedEventNavigation() {
    //will skip community searches if community accounts is empty.
    let hasCommunity =
      accountCommunityFollowing?.length > 0 ||
      accountCommunityFollowingGroups?.some((g) => g?.following?.length > 0);

    if (settingsEventSearchView === 'myevents') {
      handleSettingsSetEventView('bigtags');
      handleSettingsSetEventSearchFormat('inPerson');
    }

    if (
      settingsEventSearchView === 'bigtags' &&
      settingsEventSearchFormat === 'inPerson'
    ) {
      handleSettingsSetEventSearchFormat('online');
    }
    if (
      settingsEventSearchView === 'bigtags' &&
      settingsEventSearchFormat === 'online'
    ) {
      handleSettingsSetEventSearchFormat('inPerson');
      handleSettingsSetEventView('tinytags');
    }
    if (
      settingsEventSearchView === 'tinytags' &&
      settingsEventSearchFormat === 'inPerson'
    ) {
      handleSettingsSetEventSearchFormat('online');
    }
    if (
      settingsEventSearchView === 'tinytags' &&
      settingsEventSearchFormat === 'online'
    ) {
      if (hasCommunity) {
        handleSettingsSetEventSearchFormat('inPerson');
        handleSettingsSetEventView('community');
      } else {
        handleSettingsSetEventSearchFormat('inPerson');
        handleSettingsSetEventView('bigtags');
      }
    }

    if (
      settingsEventSearchView === 'community' &&
      settingsEventSearchFormat === 'inPerson'
    ) {
      if (hasCommunity) {
        handleSettingsSetEventSearchFormat('online');
      } else {
        handleSettingsSetEventView('bigtags');
        handleSettingsSetEventSearchFormat('inPerson');
      }
    }
    if (
      settingsEventSearchView === 'community' &&
      settingsEventSearchFormat === 'online'
    ) {
      handleSettingsSetEventView('bigtags');
      handleSettingsSetEventSearchFormat('inPerson');
    }
  }

  // function handleGetIconsForEventNavigation() {
  //   let iconsContent;
  //   if (settingsEventSearchView === 'myevents') {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={bigtag_nobg} alt="" style={{ height: '21px' }} />
  //         <img src={building} alt="" />
  //       </div>
  //     );
  //   }

  //   if (
  //     settingsEventSearchView === 'bigtags' &&
  //     settingsEventSearchFormat === 'inPerson'
  //   ) {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={bigtag_nobg} alt="" style={{ height: '21px' }} />
  //         <img src={globe} alt="" />
  //       </div>
  //     );
  //   }
  //   if (
  //     settingsEventSearchView === 'bigtags' &&
  //     settingsEventSearchFormat === 'online'
  //   ) {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={tinytag_nobg} alt="" style={{ height: '24px' }} />
  //         <img src={building} alt="" />
  //       </div>
  //     );
  //   }
  //   if (
  //     settingsEventSearchView === 'tinytags' &&
  //     settingsEventSearchFormat === 'inPerson'
  //   ) {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={tinytag_nobg} alt="" style={{ height: '24px' }} />
  //         <img src={globe} alt="" />
  //       </div>
  //     );
  //   }
  //   if (
  //     settingsEventSearchView === 'tinytags' &&
  //     settingsEventSearchFormat === 'online'
  //   ) {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={social} alt="" />
  //         <img src={building} alt="" />
  //       </div>
  //     );
  //   }
  //   if (
  //     settingsEventSearchView === 'community' &&
  //     settingsEventSearchFormat === 'inPerson'
  //   ) {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={social} alt="" />
  //         <img src={globe} alt="" />
  //       </div>
  //     );
  //   }
  //   if (
  //     settingsEventSearchView === 'community' &&
  //     settingsEventSearchFormat === 'online'
  //   ) {
  //     iconsContent = (
  //       <div className="mrg-l6 flex-row align-center">
  //         <img src={star} alt="" className="filter-theme" />
  //       </div>
  //     );
  //   }
  //   return iconsContent;
  // }//currently not in use

  return {
    handleIgnoreEventAPI,
    handleRemoveIgnoredEventAPI,
    handleRemoveArchivedEvent,
    handleUserSaveEvent,
    handleUserIgnoreEvent,
    handleUserInPersonRegistration,
    handleUserVirtualRegistration,

    //utils
    handleMyEventsSorted,
    handleGetEvent,
    handleGetEventWithAccount,
    handleGuidedEventNavigation,
    // handleGetIconsForEventNavigation,
  };
};

import { useEffect, useRef, useState } from 'react';

//Hooks
import { useApiStatus } from '../../../../context/ApiStatusProvider';

//Components
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';
import ImageCropper from '../../../../components/ImageCropper/ImageCropper';
import TextArea from '../../../../components/TextArea/TextArea';
import EventAdditionalImages from './EventAdditionalImages/EventAdditionalImages';
import ItemButton from '../../../../components/ItemButton/ItemButton';

//Utility
import { handleDBImageBlob } from '../../../../utils/images/handleDBImageBlob';
import { deepEqual } from '../../../../utils/logic/deepEqual';

const Images = ({
  editEventMode,
  skipToId,
  skipToLabel,
  CreateEvent,
  //
  initialize,
  pauseCrop,
  loadEventImageDisplay,
  loadEventAdditionalImages,

  //images
  eventImageDisplayCropped,
  eventAdditionalImagesCropped,
  setEventAdditionalImagesCropped,
  setEventImageDisplayCropped,

  //redux
  handleSetEventImageDisplay,
  handleSetEventAdditionalImages,

  //change tracker
  setEventImageDisplayChangeTracker,
  setEventAdditionalImagesChangeTracker,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();

  //Component state
  const [trackImgChangesActive, setTrackImgChangesActive] = useState(false);
  const [
    initialEventImageDisplayDataForChangeList,
    setInitialEventImageDisplayDataForChangeList,
  ] = useState(false);
  const [
    initialEventAdditionalImagesDataForChangeList,
    setInitialEventAdditionalImagesDataForChangeList,
  ] = useState(false);

  //Variables
  const eventDisplayImageCreateBlobRan = useRef(false);
  const eventAdditionalImagesCreateBlobRan = useRef(false);

  const [hasChangedEventImageDisplay, setHasChangedEventImageDisplay] =
    useState(false);
  const [
    hasChangedEventAdditionalImagesIds,
    setHasChangedEventAdditionalImagesIds,
  ] = useState([]);

  //Initialize functions
  useEffect(() => {
    if (editEventMode) {
      let timeout1 = null;

      timeout1 = setTimeout(() => {
        setTrackImgChangesActive(true);
      }, 2000);

      return () => {
        if (timeout1 !== null) {
          clearTimeout(timeout1);
        }
      };
    }
  }, [editEventMode]); //prevents initializing components to trigger adding event image change to change list for edit event

  //creates image blobs for loaded evnent display image so it can be adjusted in the cropper;
  useEffect(() => {
    if (eventDisplayImageCreateBlobRan.current === false) {
      eventDisplayImageCreateBlobRan.current = true;

      handleCreateBlobForEventDisplayImage();
    }
  }, [loadEventImageDisplay]); //loads event display image

  useEffect(() => {
    if (eventAdditionalImagesCreateBlobRan.current === false) {
      eventAdditionalImagesCreateBlobRan.current = true;
      handleCreateBlobForEventAdditionalImages();
    }
  }, [loadEventAdditionalImages]); //loads event additional images

  //Edit Event Change List
  useEffect(() => {
    if (editEventMode) {
      setInitialEventImageDisplayDataForChangeList({
        crop: {
          x: 0,
          y: 0,
        },
        zoom: 1,
        alt: loadEventImageDisplay?.alt || '',
      });

      //event additional
      let initialEVAdditionalData = [];

      for (const evImg of loadEventAdditionalImages || []) {
        let newInitData = {
          id: evImg?.id,
          altCaption: evImg?.altCaption,
          altCaptionToggle: evImg?.altCaptionToggle,
          crop: {
            x: 0,
            y: 0,
          },
          zoom: 1,
          standalone: evImg?.standalone,
        };
        initialEVAdditionalData.push(newInitData);
      }

      setInitialEventAdditionalImagesDataForChangeList(initialEVAdditionalData);
    }
  }, []); //Sets initial data for change list for both event image display + event additional images

  useEffect(() => {
    if (
      editEventMode &&
      trackImgChangesActive &&
      setEventImageDisplayChangeTracker
    ) {
      if (hasChangedEventImageDisplay) {
        return setEventImageDisplayChangeTracker(true);
      }

      let equalityCheck = deepEqual(initialEventImageDisplayDataForChangeList, {
        crop: {
          x: 0,
          y: 0,
        },
        zoom: eventImageDisplayCropped?.zoom,
        alt: loadEventImageDisplay?.alt,
      });
      if (equalityCheck) {
        setEventImageDisplayChangeTracker(false);
      } else {
        setEventImageDisplayChangeTracker(true);
      }
    }
  }, [
    eventImageDisplayCropped,
    loadEventImageDisplay,
    hasChangedEventImageDisplay,
  ]); //checks for changes to event image display

  useEffect(() => {
    if (
      editEventMode &&
      trackImgChangesActive &&
      setEventAdditionalImagesChangeTracker
    ) {
      if (
        loadEventAdditionalImages?.length !==
        initialEventAdditionalImagesDataForChangeList?.length
      ) {
        return setEventAdditionalImagesChangeTracker(true);
      } //change if length of evAdditional images is changed

      if (eventAdditionalImagesCropped?.length > 0) {
        for (const evImg of eventAdditionalImagesCropped || []) {
          if (hasChangedEventAdditionalImagesIds.includes(evImg.id)) {
            return setEventAdditionalImagesChangeTracker(true);
          }

          //find cropppedData
          const initialEvImgCroppedData =
            initialEventAdditionalImagesDataForChangeList?.find((initEvImg) => {
              if (initEvImg?.id === evImg?.id) return initEvImg;
            });

          //find new image data
          const newEvImgData = loadEventAdditionalImages?.find((newEvImg) => {
            if (newEvImg?.id === evImg?.id) return newEvImg;
          });

          let newEvImgForComparison = {
            id: evImg?.id,
            altCaption: newEvImgData?.altCaption,
            altCaptionToggle: newEvImgData?.altCaptionToggle,
            crop: {
              x: evImg?.crop?.x,
              y: evImg?.crop?.y,
            },
            zoom: evImg?.zoom,
            standalone: newEvImgData?.standalone,
          };

          if (newEvImgForComparison?.crop?.x === -0) {
            newEvImgForComparison.crop.x = 0;
          }
          if (newEvImgForComparison?.crop?.y === -0) {
            newEvImgForComparison.crop.y = 0;
          }
          if (!newEvImgForComparison?.zoom) {
            newEvImgForComparison.zoom = 1;
          }

          let croppedDataEqualityCheck = deepEqual(
            initialEvImgCroppedData,
            newEvImgForComparison
          );

          if (!croppedDataEqualityCheck) {
            return setEventAdditionalImagesChangeTracker(true);
          }
        }
      }

      //if this code is reached it means no changes
      setEventAdditionalImagesChangeTracker(false);
    }
  }, [
    eventAdditionalImagesCropped,
    loadEventAdditionalImages,
    hasChangedEventAdditionalImagesIds,
  ]); //checks for changes to event additional images

  //FUNCTIONS
  function handleEventAdditionalImageFileChangeTracking(
    eventAdditionalImageId
  ) {
    if (editEventMode && trackImgChangesActive) {
      if (
        !hasChangedEventAdditionalImagesIds.includes(eventAdditionalImageId)
      ) {
        let newArr = [
          ...hasChangedEventAdditionalImagesIds,
          eventAdditionalImageId,
        ];
        setHasChangedEventAdditionalImagesIds(newArr);
      }
    }
  } //if a user removes an event additional image the id will be stored in an array and used for checking whether changes has occurred in edit event.

  async function handleCreateBlobForEventDisplayImage() {
    try {
      if (
        loadEventImageDisplay?.imagePath !== 'event_img_default.png' &&
        loadEventImageDisplay?.imagePath
      ) {
        const imageObj = await handleDBImageBlob({
          imagePath: loadEventImageDisplay?.imagePath,
          imageType: 'event',
          returnImageObj: true,
        });

        let newImg = {
          croppedImage: imageObj.croppedImage,
          imagePreview: imageObj.imagePreview,
          crop: {
            x: 0,
            y: 0,
          },
          zoom: 1,
        };
        setEventImageDisplayCropped(newImg);
      } else {
        const newEventDisplayImageCropped = {
          croppedImage: '',
          imagePreview: '',
          crop: {
            x: 0,
            y: 0,
          },
          zoom: 1,
        };
        setEventImageDisplayCropped(newEventDisplayImageCropped);
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('DEV handleCreateBlobForEventDisplayImage', error);
      }
    }
  }

  async function handleCreateBlobForEventAdditionalImages() {
    try {
      let newEventAdditionalImagesCropped = [];

      for (const evtImg of loadEventAdditionalImages || []) {
        if (
          evtImg?.imagePath !== 'event_img_default.png' &&
          evtImg?.imagePath
        ) {
          const imageObj = await handleDBImageBlob({
            imagePath: evtImg?.imagePath,
            imageType: 'event_additional',
            returnImageObj: true,
          });
          let newImageObj = {
            id: evtImg.id,
            index: evtImg.index,
            croppedImage: imageObj.croppedImage,
            imagePreview: imageObj.imagePreview,
            crop: {
              x: 0,
              y: 0,
            },
            zoom: 1,
          };
          newEventAdditionalImagesCropped.push(newImageObj);
        } else {
          let newEvAdditionalImg = {
            id: evtImg.id,
            index: evtImg.index,
            croppedImage: '',
            imagePreview: '',
            crop: {
              x: 0,
              y: 0,
            },
            zoom: 1,
          };

          newEventAdditionalImagesCropped.push(newEvAdditionalImg);
        }
      }
      setEventAdditionalImagesCropped(newEventAdditionalImagesCropped);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log('DEV handleCreateBlobForEventAdditionalImages', error);
      }
    }
  }

  function handleAddEventAdditionalImage() {
    if (loadEventAdditionalImages?.length >= 4) {
      return handleWarning({
        message: 'limitReached',
        origin: 'Images.js/handleAddEventAdditionalImage',
        id: Date.now(),
      });
    }

    let newEventAdditionalImages = [...loadEventAdditionalImages];
    let newEventAdditionalImagesCropper = [...eventAdditionalImagesCropped];

    let newId = Math.floor(Math.random() * 9000000000) + 1000000000;
    let newIndex = loadEventAdditionalImages?.length;

    let newEventAdditionalImage = {
      id: newId,
      index: newIndex,
      imagePath: '',
      altCaption: '',
      altCaptionToggle: 'alt',
      standalone: false,
    };

    let newImageCropper = {
      id: newId,
      index: newIndex,
      croppedImage: '',
      imagePreview: '',
      crop: {
        x: 0,
        y: 0,
      },
      zoom: 1,
    };
    newEventAdditionalImages.push(newEventAdditionalImage);
    handleSetEventAdditionalImages(newEventAdditionalImages);
    //
    newEventAdditionalImagesCropper.push(newImageCropper);
    setEventAdditionalImagesCropped(newEventAdditionalImagesCropper);
  }

  //Event Display Image
  function handleEventImageDisplayChange(newImgData) {
    let newImg = { ...newImgData, crop: { ...newImgData.crop } };
    setEventImageDisplayCropped(newImg);
  }

  function handleEventImageDisplayCaption(newText) {
    let newEventImageDisplay = { ...loadEventImageDisplay };
    newEventImageDisplay.alt = newText;
    handleSetEventImageDisplay(newEventImageDisplay);
  }

  function handleRemoveEventDisplay() {
    //remove redux
    handleSetEventImageDisplay({
      imagePath: null,
      alt: loadEventImageDisplay.alt,
    });

    //remove preview
    setEventImageDisplayCropped();

    //edit tracking
    if (editEventMode && trackImgChangesActive) {
      setHasChangedEventImageDisplay(true);
    }
  }

  //Event Additional
  function handleEventAdditionalChange(newEventAdditionalData) {
    let newEventAdditional = loadEventAdditionalImages?.map((evImg) => {
      if (evImg?.id === newEventAdditionalData?.id) {
        return newEventAdditionalData;
      } else {
        return evImg;
      }
    });

    handleSetEventAdditionalImages(newEventAdditional);
  }

  function handleRemoveEventAdditional(eventAdditionalId) {
    //remove redux
    let newEventAdditionalImages = loadEventAdditionalImages?.filter(
      (evImg) => evImg?.id !== eventAdditionalId
    );

    newEventAdditionalImages = newEventAdditionalImages?.map((evImg, index) => {
      let newEvImg = { ...evImg };
      newEvImg.index = index;
      return newEvImg;
    });

    //remove preview
    let newEventAdditionalImagesCropper = eventAdditionalImagesCropped?.filter(
      (evImg) => evImg?.id !== eventAdditionalId
    );

    newEventAdditionalImagesCropper = newEventAdditionalImagesCropper?.map(
      (evImg, index) => {
        let newEvImg = { ...evImg };
        newEvImg.index = index;
        return newEvImg;
      }
    );

    handleEventAdditionalImageFileChangeTracking(eventAdditionalId);

    setEventAdditionalImagesCropped(newEventAdditionalImagesCropper);
    handleSetEventAdditionalImages(newEventAdditionalImages);
  }

  return (
    <fieldset className="fieldset" id="fieldset-images">
      <legend id="aria-fieldset-instructions-images">
        {CreateEvent.images.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="imagesLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-images"
      >
        {CreateEvent.images.images}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />

      <label className="content-heading-styled text-gray flex-row" tabIndex="0">
        {CreateEvent.images.eventDisplay}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.images.eventDisplayDescription}
      </p>
      <div className="h24" />
      <ImageCropper
        ariaImageDescription={CreateEvent.images.ariaImageDescription}
        center={true}
        cover={'horizontal-cover'}
        customFileUploaderWrapper={'file-uploader__wrapper--event'}
        handleImage={handleEventImageDisplayChange}
        height={198}
        id={'eventDisplayImage'}
        imagePreview={eventImageDisplayCropped?.imagePreview}
        pauseCrop={pauseCrop}
        width={352}
        saveCroppedPos={true}
        loadCroppedPos={
          eventImageDisplayCropped
            ? {
                crop: eventImageDisplayCropped.crop,
                zoom: eventImageDisplayCropped.zoom,
              }
            : null
        }
        setFileUploadTracker={
          editEventMode && trackImgChangesActive
            ? setHasChangedEventImageDisplay
            : null
        }
        customRemove={handleRemoveEventDisplay}
        ignoreRemoveImageOnDismount={true}
      />
      <TextArea
        handleChange={handleEventImageDisplayCaption}
        limit={200}
        id={'eventDisplayAltText'}
        labelText={CreateEvent.images.eventDisplayAltText}
        disableEnter={true}
        loadTrigger={initialize}
        customWrapper={'br--none mrg-t24'}
        loadValue={loadEventImageDisplay?.alt}
        onBlurHandlerOff={true}
      />

      <div className="h36" />

      <label className="content-heading-styled text-gray flex-row" tabIndex="0">
        {CreateEvent.images.additionalImages}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {CreateEvent.images.additionalImagesDescription}
      </p>
      <div className="h24" />

      <div className="event-additional__wrapper">
        {loadEventAdditionalImages?.map((reduxEventImgObj, index) => {
          return (
            <EventAdditionalImages
              initialize={initialize}
              pauseCrop={pauseCrop}
              key={`eventAdditionalImage-${reduxEventImgObj?.id}`}
              id={reduxEventImgObj?.id}
              reduxEventImgObj={reduxEventImgObj}
              handleEventAdditionalChange={handleEventAdditionalChange}
              setEventAdditionalImagesCropped={setEventAdditionalImagesCropped}
              eventAdditionalImagesCropped={eventAdditionalImagesCropped}
              handleRemove={handleRemoveEventAdditional}
              handleEventAdditionalImageFileChangeTracking={
                handleEventAdditionalImageFileChangeTracking
              }
            />
          );
        })}
      </div>
      {loadEventAdditionalImages?.length < 4 && (
        <ItemButton
          handler={handleAddEventAdditionalImage}
          text={CreateEvent.images.addImage}
          customWrapper={'mrg-t36 mrg-t24--ph'}
        />
      )}
      <div className="h48" />
    </fieldset>
  );
};

export default Images;

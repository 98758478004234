import { useEffect, useState } from 'react';

//components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import FollowButton from '../../../../components/FollowButton/FollowButton';

//utils
import { textUI } from '../../../../utils/UI/textUI';

const ParticipantsContent = ({
  participantsData,
  EventPage,
  participantImagesCropped,
  previewMode,
  organizerViewOn,
}) => {
  //state
  const [participants, setParticipants] = useState([]);

  //ui

  useEffect(() => {
    if (participantImagesCropped?.length > 0) {
      let newArr = [];

      //add participants that have a cropped image (can only be created participants)
      participantImagesCropped?.map((croppedObj) => {
        let newParticipant;

        if (previewMode) {
          newParticipant = participantsData?.find((p) => {
            if (p?.id === croppedObj?.id) {
              return p;
            }
          });
          newParticipant = { ...newParticipant, ...croppedObj };
        }
        newArr.push(newParticipant);
      });

      for (const p of participantsData || []) {
        //add participants that dont have a cropped image
        if (
          p?.organizedAs === 'inviteContact' ||
          p?.organizedAs === 'emailInvitation'
        ) {
          if (p?.dispatches?.invitationStatus === 'accepted') {
            newArr.push(p);
          }
        } else {
          if (!newArr.some((pCropped) => pCropped.id === p.id)) {
            newArr.push(p);
          }
        }
      }

      setParticipants(newArr);
    } else if (participantsData?.length > 0) {
      let newArr = [];

      for (const p of participantsData || []) {
        //add participants that dont have a cropped image
        if (
          p?.organizedAs === 'inviteContact' ||
          p?.organizedAs === 'emailInvitation'
        ) {
          if (p?.dispatches?.invitationStatus === 'accepted') {
            newArr.push(p);
          }
        } else {
          if (!newArr.some((arrParticipant) => arrParticipant.id === p.id)) {
            newArr.push(p);
          }
        }
      }
      setParticipants(newArr);
    }
  }, [participantsData, participantImagesCropped]); //display created participants or those who've accepted invite

  return (
    <>
      {participants?.length > 0 && (
        <div className="flex-column">
          <label
            className="content-heading-styled text-gray mrg-b12"
            tabIndex="0"
            id="participantsLabel"
          >
            {EventPage.eventParticipants}
          </label>
          <div className="h12" />
          {participants?.map((p, index) => {
            let participantData = p?.participantData;
            let partialPath =
              p?.organizedAs === 'create' ||
              p?.organizedAs === 'emailInvitation'
                ? 'myndfull_participant_images'
                : 'myndfull_account_images';

            let invitePendingInPreviewMode = false;

            let participantDivider = false;

            if (
              (p?.organizedAs === 'inviteContact' ||
                p?.organizedAs === 'emailInvitation') &&
              p?.dispatches?.invitationStatus !== 'accepted'
            ) {
              if (!organizerViewOn || !previewMode) return null;

              invitePendingInPreviewMode = true;
            }

            if (
              participants?.length > 1 &&
              index !== participants?.length - 1
            ) {
              participantDivider = true;
            }

            return (
              <div key={`participant-${index}`} className="">
                <div
                  className={`participant__wrapper ${
                    index !== 0 ? 'mrg-t24' : ''
                  } ${
                    invitePendingInPreviewMode
                      ? 'previewMode-border outline-offset-12'
                      : ''
                  }`}
                  key={`participant-${index}`}
                >
                  <div className="flex-column mrg-auto-bottom">
                    <div className="participant-img__container">
                      <LoadBucketImage
                        croppedImage={p?.croppedImage?.croppedImage}
                        imagePath={participantData?.personal?.profileImage}
                        containerClassName={'ev-display-container'}
                        imgClassName={'participant-img'}
                        backupPath={'account_img_default.png'}
                        partialPath={partialPath}
                        imagePreview={p?.imagePreview}
                        altAriaText={participantData?.personal?.profileImageAlt}
                      />
                    </div>
                    {participantData?.participantAccountId && (
                      <FollowButton
                        followableAccountId={
                          participantData?.participantAccountId
                        }
                        customWrapperStyle={{ minWidth: '124px' }}
                        noFollowMrgBtn={'mrg-b24'}
                        customFollowIcon={'mrg-t12'}
                      />
                    )}
                  </div>
                  <div className="participant-info full-width mrg-l12">
                    <div className="flex-column full-width">
                      <p className="fs18 fwsb access-ob" tabIndex="0">
                        {participantData?.personal?.prefix &&
                          `${participantData?.personal?.prefix} `}
                        {participantData?.personal?.firstName &&
                          `${participantData?.personal?.firstName} `}
                        {participantData?.personal?.lastName &&
                          `${participantData?.personal?.lastName}`}
                        {participantData?.eventRole &&
                          ` | ${participantData?.eventRole}`}
                      </p>

                      <p className="italic fs16">
                        {participantData?.personal?.pronouns &&
                          `${participantData?.personal?.pronouns}`}
                      </p>
                    </div>

                    {participantData?.personal?.bio && (
                      <div className="fs16">
                        {textUI(participantData?.personal?.bio)}
                      </div>
                    )}
                  </div>
                </div>
                {participantDivider && (
                  <div className={`divider opacity-05 full-width mrg-t24`} />
                )}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default ParticipantsContent;

import { apiSlice } from '../../../app/api/apiSlice';
import {
  setAccount,
  setEmail,
  setProfileImage,
  setMyInvoices,
  setAccountPersonalBannerImage,
} from './accountSlice';
import { setCredentials } from '../auth/authSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';
import {
  settingsSetSelectedAccountsFollowingGroupIds,
  settingsSetSelectedAccountsFollowingIds,
  settingsSetSelectedBigTags,
  settingsSetSelectedTinyTagGroupIds,
  settingsSetSelectedTinyTags,
} from '../settings/settingsSlice';

export const accountApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myAccount: builder.query({
      query: () => `/api/main/account/myaccount`,
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const {
            _id,
            accountEvents,
            affiliations,
            appSettings,
            attendee,
            community,
            billing,
            emailPreferences,
            meta,
            notices,
            organizer,
            personal,
            speaker,
            tags,
            translationRooms,
          } = data.myAccount;
          dispatch(
            setAccount({
              _id,
              accountEvents,
              affiliations,
              appSettings,
              attendee,
              community,
              billing,
              emailPreferences,
              meta,
              notices,
              organizer,
              personal,
              speaker,
              tags,
              translationRooms,
            })
          );
        } catch (error) {
          //no account error - too frequent with refresh
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, myAccount error:', error);
          }
        }
      },
      providesTags: ['Account'],
    }),
    updateAccount: builder.mutation({
      query: ({ accountId, newData, successMessage, updateCategory }) => ({
        url: `/api/main/account/myaccount/update`,
        method: 'PUT',
        body: { accountId, newData, updateCategory },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(
        { successMessage, updateCategory },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const {
            _id,
            accountEvents,
            affilaitions,
            appSettings,
            attendee,
            billing,
            community,
            emailPreferences,
            meta,
            notices,
            organizer,
            personal,
            speaker,
            tags,
            translationRooms,
          } = data;
          dispatch(
            setAccount({
              _id,
              accountEvents,
              affilaitions,
              appSettings,
              attendee,
              billing,
              community,
              emailPreferences,
              meta,
              notices,
              organizer,
              personal,
              speaker,
              tags,
              translationRooms,
            })
          );

          //settings attendee
          if (
            updateCategory === 'bigTags' ||
            updateCategory === 'attendeeSignUp'
          ) {
            dispatch(settingsSetSelectedBigTags(tags?.bigTags || []));
          }
          if (
            updateCategory === 'tinyTags' ||
            updateCategory === 'attendeeSignUp'
          ) {
            dispatch(settingsSetSelectedTinyTags(tags?.tinyTags || []));

            const tGIds = tags?.tinyTagGroups?.map((tG) => tG.id);
            dispatch(settingsSetSelectedTinyTagGroupIds(tGIds || []));
          }

          if (updateCategory === 'community') {
            const accountFollowingIds = community?.following?.map(
              (accountFollowing) => accountFollowing._id
            ); //account returns personal + id data in array of objects

            dispatch(
              settingsSetSelectedAccountsFollowingIds(accountFollowingIds || [])
            );

            const accountsFollowingGroupIds = community?.followingGroups?.map(
              (accountGroup) => accountGroup.id
            );
            dispatch(
              settingsSetSelectedAccountsFollowingGroupIds(
                accountsFollowingGroupIds || []
              )
            );
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, myAccount error:', error);
          }
        }
      },
      invalidatesTags: ['Event'],
    }),

    updateEmail: builder.mutation({
      query: ({ accountId, newData }) => ({
        url: `/api/main/account/myaccount/updateEmail`,
        method: 'PATCH',
        body: { accountId, newData },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { email, roles, accessToken } = data;
          dispatch(setCredentials({ email, accessToken, roles }));
          dispatch(setEmail(email));
          const success = {
            id: Date.now(),
            status: 'success',
            message: 'emailUpdated',
            origin: 'AccountApiSlice/updateEmail',
          };
          dispatch(addReduxApiStatus(success));
          return data;
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AccountApiSlice/updateEmail',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    updatePassword: builder.mutation({
      query: ({ accountId, newData }) => ({
        url: `/api/main/account/myaccount/updatePassword`,
        method: 'PATCH',
        body: { accountId, newData },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            message: 'passwordUpdated',
            origin: 'AccountApiSlice/updatePassword',
          };
          dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AccountApiSlice/updatePassword',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    updatePhoto: builder.mutation({
      query: (body) => ({
        url: `/api/main/account/myaccount/updatePhoto`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { profileImage } = data;
          dispatch(setProfileImage(profileImage));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, updatePhoto error:', error);
          }
        }
      },
    }),
    updateRoles: builder.mutation({
      query: ({ accountId, newData }) => ({
        url: `/api/main/account/myaccount/updateRoles`,
        method: 'PATCH',
        body: { accountId, newData },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          return data;
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, updateRoles error:', error);
          }
        }
      },
      invalidatesTags: ['Auth', 'Account'],
    }),
    myIgnoredEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/main/account/myaccount/myignoredevents',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, myIgnoredEvents error:', error);
          }
        }
      },
    }),
    getTranslationRoomCodePassword: builder.mutation({
      query: ({ accountId, translationRoomCodePwd }) => ({
        url: `/api/main/account/myaccount/getTranslationRoomCodePassword`,
        method: 'POST',
        body: {
          accountId,
          translationRoomCodePwd,
        },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, getTranslationRoomCodePassword error:',
              error
            );
          }
        }
      },
    }),
    updateTranslationRoomsHostPassword: builder.mutation({
      query: ({ accountId, newTranslationRoomsRoomCodeData }) => ({
        url: `/api/main/account/myaccount/updateTranslationRoomsHostPassword`,
        method: 'POST',
        body: { accountId, newTranslationRoomsRoomCodeData },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, updateTranslationRoomHostPassword error:',
              error
            );
          }
        }
      },
    }),
    updateTranslationRoomsAttendeePassword: builder.mutation({
      query: ({ accountId, newTranslationRoomsRoomCodeData }) => ({
        url: `/api/main/account/myaccount/updateTranslationRoomsAttendeePassword`,
        method: 'POST',
        body: { accountId, newTranslationRoomsRoomCodeData },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, updateTranslationRoomsAttendeePassword error:',
              error
            );
          }
        }
      },
    }),
    retrieveAccountCustomerDetails: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/stripe/retrieveCustomerDetails',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'accountApiSlice, retrieveAccountCustomerDetails error:',
              error
            );
          }
        }
      },
    }),
    updateCustomer: builder.mutation({
      query: ({
        accountId,
        newEmail,
        newPhone,
        newName,
        newContentLanguage,
      }) => ({
        url: `/api/stripe/updateCustomer`,
        method: 'POST',
        body: { accountId, newEmail, newPhone, newName, newContentLanguage },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AccountApiSlice/updateCustomer',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    myTranslationsBilling: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/main/account/myTranslationsBilling',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {}
      },
      providesTags: ['Account'],
    }),
    myInvoices: builder.query({
      query: ({ accountId }) => {
        return {
          url: '/api/stripe/myInvoices',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setMyInvoices(data?.invoices));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('accountApiSlice, myInvoices error:', error);
          }
        }
      },
      providesTags: ['Account'],
    }),
    getInvoicePdf: builder.mutation({
      query: ({ accountId, invoiceId }) => ({
        url: `/api/stripe/getInvoicePdf`,
        method: 'POST',
        body: { accountId, invoiceId },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const response = await queryFulfilled;

          if (response?.error) {
            throw response.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, getInvoicePDF error:', error);
          }
        }
      },
    }),
    addRoomCodeSubscripton: builder.mutation({
      query: ({ accountId, newRoomCodes }) => ({
        url: `/api/stripe/addRoomCodeSubscription`,
        method: 'POST',
        body: { accountId, newRoomCodes },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const success = {
            id: Date.now(),
            status: 'success',
            message: 'accountUpdated',
            origin: 'AccountApiSlice/addRoomCodeSubscripton',
          };
          dispatch(addReduxApiStatus(success));
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AccountApiSlice/addRoomCodeSubscripton',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Account'],
    }),
    changeRoomCodeSubscription: builder.mutation({
      query: ({ accountId, newRoomCode, oldRoomCode }) => ({
        url: `/api/stripe/changeRoomCodeSubscription`,
        method: 'POST',
        body: { accountId, newRoomCode, oldRoomCode },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, changeRoomCodeSubscription error:',
              error
            );
          }
        }
      },
      invalidatesTags: ['Account'],
    }),
    removeRoomCodeSubscription: builder.mutation({
      query: ({ accountId, roomCode }) => ({
        url: `/api/stripe/removeRoomCodeSubscription`,
        method: 'POST',
        body: { accountId, roomCode },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, removeRoomCodeSubscription error:',
              error
            );
          }
        }
      },
      invalidatesTags: ['Account'],
    }),
    restoreRoomCodeSubscription: builder.mutation({
      query: ({ accountId, roomCode }) => ({
        url: `/api/stripe/restoreRoomCodeSubscription`,
        method: 'POST',
        body: { accountId, roomCode },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, restoreRoomCodeSubscription error:',
              error
            );
          }
        }
      },
      invalidatesTags: ['Account'],
    }),
    removePaymentMethod: builder.mutation({
      query: ({ accountId, paymentMethodId }) => ({
        url: `/api/stripe/detachPaymentMethod`,
        method: 'POST',
        body: { accountId, paymentMethodId },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error?.error,
            origin: 'AccountApiSlice/removePaymentMethod',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Account'],
    }),
    setPrimaryPaymentMethod: builder.mutation({
      query: ({ accountId, newPrimaryPaymentMethodId }) => ({
        url: `/api/stripe/setPrimaryPaymentMethod`,
        method: 'POST',
        body: { accountId, newPrimaryPaymentMethodId },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error?.error,
            origin: 'AccountApiSlice/setPrimaryPaymentMethod',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Account'],
    }),
    saveSpeakerSignUp: builder.mutation({
      query: ({ accountId, speakerData, signUpComplete }) => ({
        url: `/api/main/account/myaccount/speaker/speakerSignUp`,
        method: 'POST',
        body: { accountId, speakerData, signUpComplete },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          // const { signUpComplete } = arg;
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, saveSpeakerSignUp error:', error);
          }
        }
      },
      invalidatesTags: ['Account'],
    }),
    updateAccountSpeaker: builder.mutation({
      query: ({ accountId, newData }) => ({
        url: `/api/main/account/myaccount/updateSpeaker`,
        method: 'PUT',
        body: { accountId, newData },
      }),
      transformResponse: (response, meta, arg) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const {
            _id,
            accountEvents,
            attendee,
            affilaitions,
            appSettings,
            billing,
            community,
            emailPreferences,
            meta,
            personal,
            speaker,
            tags,
            translationRooms,
          } = data;
          dispatch(
            setAccount({
              _id,
              accountEvents,
              attendee,
              affilaitions,
              appSettings,
              billing,
              community,
              emailPreferences,
              meta,
              personal,
              speaker,
              tags,
              translationRooms,
            })
          );
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV accountApiSlice, updateAccountSpeaker error:',
              error
            );
          }
        }
      },
    }),
    updateBannerImage: builder.mutation({
      query: (body) => ({
        url: `/api/main/account/myaccount/updateBannerImage`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { bannerImage } = data;
          dispatch(setAccountPersonalBannerImage(bannerImage));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV accountApiSlice, updateBannerImage error:', error);
          }
        }
      },
    }),
  }),
});

export const {
  useLazyMyAccountQuery,
  useMyAccountQuery,
  useUpdateAccountMutation,
  useUpdatePasswordMutation,
  useUpdateEmailMutation,
  useUpdateRolesMutation,
  useUpdatePhotoMutation,
  useUpdateBannerImageMutation,
  useLazyMyIgnoredEventsQuery,

  //speaker
  useSaveSpeakerSignUpMutation,
  useUpdateAccountSpeakerMutation,

  //translation
  useGetTranslationRoomCodePasswordMutation,
  useUpdateTranslationRoomsHostPasswordMutation,
  useUpdateTranslationRoomsAttendeePasswordMutation,
  useChangeRoomCodeSubscriptionMutation,
  useRemoveRoomCodeSubscriptionMutation,
  useRestoreRoomCodeSubscriptionMutation,

  //billing
  useLazyRetrieveAccountCustomerDetailsQuery,
  useUpdateCustomerMutation,
  useLazyMyTranslationsBillingQuery,
  useLazyMyInvoicesQuery,
  useAddRoomCodeSubscriptonMutation,
  useGetInvoicePdfMutation,
  useRemovePaymentMethodMutation,
  useSetPrimaryPaymentMethodMutation,
} = accountApiSlice;

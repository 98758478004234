import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//Hooks
import { useApiStatus } from '../../../context/ApiStatusProvider';
import { useLazyMyAdminAccountQuery } from '../account/adminAccountApiSlice';
import { useLazyAdminLogoutQuery } from './adminAuthApiSlice';
import {
  selectAdminAuthIsLoggingIn,
  selectAdminAuthIsLoggingOut,
  selectAdminCurrentRoles,
  selectAdminCurrentToken,
  selectAdminCurrentUser,
  adminLogOut,
  setAdminCredentials,
  setAdminAuthIsLoggingOut,
  setAdminAuthIsLoggingIn,
} from './adminAuthSlice';

export const useAdminAuthReduxHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const adminAuthCurrentUser = useSelector(selectAdminCurrentUser);
  const adminAuthCurrentRoles = useSelector(selectAdminCurrentRoles);
  const adminAuthCurrentToken = useSelector(selectAdminCurrentToken);
  const adminAuthisLoggingOut = useSelector(selectAdminAuthIsLoggingOut);
  const adminAuthisLoggingIn = useSelector(selectAdminAuthIsLoggingIn);

  const [myAdminAccount] = useLazyMyAdminAccountQuery();
  const [adminLogout] = useLazyAdminLogoutQuery();

  //API functions
  const handleAdminGetMyAccount = async (navigateRoute) => {
    handleAdminAuthIsLoggingIn(true);

    try {
      await myAdminAccount();
      handleAdminAuthIsLoggingIn(false);

      if (navigateRoute) {
        return navigate(navigateRoute);
      }
    } catch (error) {
      const newError = {
        error,
        id: Date.now(),
        origin: 'Login.js/getMyAccount',
      };
      handleAdminAuthIsLoggingIn(false);
      await adminLogout();
      handleError(newError);
    }
  };

  //Component functions
  function handleAdminAuthSetCredentials(obj) {
    dispatch(setAdminCredentials(obj));
  }

  function handleAdminAuthLogOut() {
    dispatch(adminLogOut());
  }

  function handleAdminAuthIsLoggingOut(boolean) {
    dispatch(setAdminAuthIsLoggingOut(boolean));
  }

  function handleAdminAuthIsLoggingIn(boolean) {
    dispatch(setAdminAuthIsLoggingIn(boolean));
  }

  return {
    adminAuthCurrentUser,
    adminAuthCurrentRoles,
    adminAuthCurrentToken,
    adminAuthisLoggingOut,
    adminAuthisLoggingIn,
    handleAdminAuthSetCredentials,
    handleAdminAuthLogOut,
    handleAdminAuthIsLoggingOut,
    handleAdminAuthIsLoggingIn,
    handleAdminGetMyAccount,
  };
};

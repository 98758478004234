import { useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//Utility
import { return_curved_arrow } from '../../assets/icons';
import ScrollTo from '../../utils/UI/ScrollTo';

const ScrollToComponent = ({ text, elementId, key }) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //UI state
  const [tapHighlight, setTapHighlight] = useState(false);

  return (
    <li key={key}>
      <button
        type="button"
        className={`scrollToComponent highlight-bthin-theme br--small fs16  access-ob access-o6 ${
          tapHighlight ? 'highlight-b-theme--tap' : ''
        }`}
        onClick={() =>
          handleMobileTap(
            [() => setTapHighlight(true), () => setTapHighlight(false)],
            [
              () =>
                ScrollTo({
                  focusId: elementId,
                  behavior: 'smooth',
                  scrollToElementId: elementId,
                }),
            ]
          )
        }
        aria-label={text}
      >
        {text}
        <img
          src={return_curved_arrow}
          alt="arrow"
          className={`mrg-l12 ${tapHighlight ? 'filter-theme' : 'filter-gray'}`}
        />
      </button>
    </li>
  );
};

export default ScrollToComponent;

import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';

//components
import JoinRoom from './JoinRoom';
import TranslationRoom from '../Room/TranslationRoom';

const TranslationJoinRoom = ({ setPage, setRemoveAnimationBackground }) => {
  //hooks
  const { translationRoomSocketStatus } = useTranslationRoomSocket();

  //state
  const [joinRoom, setJoinRoom] = useState(false);

  useEffect(() => {
    if (translationRoomSocketStatus === 'connected') {
      setJoinRoom(true);
    }
  }, [translationRoomSocketStatus]);

  return (
    <>
      {!joinRoom ? (
        <JoinRoom setPage={setPage} />
      ) : (
        <TranslationRoom
          setPage={setPage}
          isHost={false}
          setRemoveAnimationBackground={setRemoveAnimationBackground}
        />
      )}
    </>
  );
};

export default TranslationJoinRoom;

import { useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

const ChangeButton = ({ handler, customWrapper }) => {
  //hooks
  const { handleMobileTap } = useSettings();

  //language
  const { Generic } = useLanguageComponents();

  //state
  const [tapHighlightChangeBtn, setTapHighlightChangeBtn] = useState(false);

  return (
    <div className={`${customWrapper ? '' : ''}`}>
      <button
        className={`change-btn access-ob access-o6 highlight-ob-theme  ${
          tapHighlightChangeBtn ? 'highlight-ob-theme--tap' : ''
        }`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightChangeBtn(true),
              () => setTapHighlightChangeBtn(false),
            ],
            [() => handler(true)]
          )
        }
      >
        <p className="pad-t2">{Generic.change}</p>
      </button>
    </div>
  );
};

export default ChangeButton;

import { useState, useRef } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import { visible_off, visible } from '../../assets/icons/index';
import handleInputClick from './handleInputClick';

//useLanguageComponents to pass in label text as prop
//password elements need to be in form or throws warning
const PasswordCheck = ({
  asterisk,
  customWidth,
  handleChange,
  id,
  labelText,
  handleSubmit,
  customWrapper,
}) => {
  //Hooks
  const { dismissKeypad, handleMobileTap } = useSettings();
  const { PasswordInputs } = useLanguageComponents();

  //Component state
  const [showPassword, setShowPassword] = useState(false);

  //UI state
  const [tapHighlightShowPassword, setTapHighlightShowPassword] =
    useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);

  //Variables
  const passwordInput = useRef();

  return (
    <form
      className={`text-input__wrapper ${customWrapper ? customWrapper : ''}`}
      style={customWidth ? { width: `${customWidth}` } : null}
      onSubmit={(e) => (!handleSubmit ? e.preventDefault() : handleSubmit(e))}
      autoComplete="off"
      onMouseEnter={() => setBorderHighlight(true)}
      onMouseLeave={() => setBorderHighlight(false)}
    >
      <label
        id={`${id}-label`}
        className="text-input-label no-select"
        onClick={() => handleInputClick(passwordInput.current)}
      >
        <p>
          {labelText}&nbsp;
          {asterisk && <span className="highlight">*</span>}
        </p>
      </label>

      <input
        id={id}
        name={id}
        type={!showPassword ? 'password' : 'text'}
        className={`text-input text-input-password ${
          borderHighlight ? 'highlight-o-theme--tap' : ''
        } ${
          focusHighlight
            ? 'highlight-ob-theme--thin--active'
            : 'highlight-ob-theme--thin'
        }`}
        ref={passwordInput}
        defaultValue=""
        onChange={(e) => handleChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setFocusHighlight(false);
          }
          dismissKeypad(e);
        }}
        onFocus={() => {
          setFocusHighlight(true);
        }}
        onBlur={(e) => {
          setFocusHighlight(false);
        }}
        autoComplete="new-password"
        aria-label={PasswordInputs.currentPassword}
        maxLength="255"
        tabIndex="0"
        enterKeyHint="done"
      />
      <button
        className={`highlight-i-lgt access-ob access-o6 ${
          showPassword ? 'show-password-btn' : 'show-password-btn visible-off'
        } ${tapHighlightShowPassword ? 'highlight-i-theme--tap' : ''}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightShowPassword(true),
              () => setTapHighlightShowPassword(false),
            ],
            [() => setShowPassword(!showPassword)]
          )
        }
        tabIndex="0"
        type="button"
        id={`${id}-show-password`}
      >
        {showPassword ? (
          <img src={visible} alt={PasswordInputs.showPasswordOn} />
        ) : (
          <img src={visible_off} alt={PasswordInputs.showPasswordOff} />
        )}
      </button>
    </form>
  );
};

export default PasswordCheck;

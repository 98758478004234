import { cancel } from '../../../../assets/icons';

//Hooks
import { useGenericModal } from '../../../../context/GenericModalProvider';

//Components
import LogTargetAdminDetails from './LogTargetDetails/LogTargetAdminDetails';

//Utility
import { getDateOrTimeFromUTZstring } from '../../../../utils/dateTime/DateCalc';

const AdminLogModal = ({ adminLogData }) => {
  //Hooks
  const { handleCloseModal } = useGenericModal();

  return (
    <div className="activity-log-modal access-ow access-o12" tabIndex="0">
      <div className="activity-log-modal__heading">
        <div className="flex-row flex-center">
          <label className=" fs21 fwsb access-ob access-o6" tabIndex="0">
            Activity Log
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob `}
          onClick={handleCloseModal}
          type="button"
        >
          <img src={cancel} alt="cancel" className="svg" />
        </button>
      </div>
      <div className="activity-log-modal__container">
        <p className="mrg-b6 fs21 fwsb">Admin Profile</p>
        <div className="flex-row fs18 fwn">
          <p>Name:</p>&nbsp;
          <p>
            {adminLogData.logData.actionBy?.adminId?.personal?.prefix}{' '}
            {adminLogData.logData.actionBy?.adminId?.personal?.firstName}{' '}
            {adminLogData.logData.actionBy?.adminId?.personal?.lastName}
          </p>
          {adminLogData.logData.actionBy?.adminId?.personal?.pronouns && (
            <p>
              &nbsp;(
              {adminLogData.logData.actionBy?.adminId?.personal?.pronouns})
            </p>
          )}
        </div>
        <div className="flex-row fs18 fwn">
          <p>Position:</p>&nbsp;
          <p>{adminLogData.logData.actionBy?.adminId?.company?.position}</p>
        </div>
        <div className="flex-row fs18 fwn">
          <p>Email:</p>&nbsp;
          <p>{adminLogData.logData.actionBy?.adminId?.personal?.email}</p>
        </div>
        <div className="activity-log-modal__divider mrg-tb24" />

        <p className="mrg-b6 fs21 fwsb">Log Meta</p>
        <div className="flex-row fs18 fwn">
          <p>Log ID:</p>&nbsp;
          <p>{adminLogData?._id}</p>
        </div>

        <div className="flex-row fs18 fwn">
          <p>Created At:</p>&nbsp;
          <p>{adminLogData?.meta?.createdAt}</p>
        </div>
        <div className="flex-row fs18 fwn">
          <p>Date:</p>&nbsp;
          <p>
            {getDateOrTimeFromUTZstring(adminLogData.meta.createdAt, 'date')}
          </p>
        </div>
        <div className="flex-row fs18 fwn">
          <p>Time:</p>&nbsp;
          <p>
            {getDateOrTimeFromUTZstring(adminLogData.meta.createdAt, 'time')}
          </p>
        </div>
        <div className="flex-row fs18 fwn">
          <p>Version:</p>&nbsp;
          <p>{adminLogData.meta.version}</p>
        </div>

        <div className="activity-log-modal__divider mrg-tb24" />
        <p className="mrg-b6 fs21 fwsb">Log Data </p>
        <div className="flex-row fs18 fwn">
          <p>Action:</p>&nbsp;
          <p>{adminLogData.logData.action}</p>
        </div>
        <div className="flex-row fs18 fwn">
          <p>Notes:</p>&nbsp;
          <p>{adminLogData.logData.notes}</p>
        </div>
        <div className="flex-row fs18 fwn mrg-t24">
          <p>Action Target:</p>&nbsp;
          {adminLogData.logData?.actionTarget?.accountId && <p>Account</p>}
          {adminLogData.logData?.actionTarget?.adminId && <p>Admin</p>}
          {adminLogData.logData?.actionTarget?.eventId && <p>Event</p>}
          {adminLogData.logData?.actionTarget?.eventId && (
            <p>Scheduled Event</p>
          )}
          {adminLogData.logData?.actionTarget?.eventId && <p>Ticket</p>}
        </div>

        {adminLogData.logData?.actionTarget?.adminId && (
          <LogTargetAdminDetails
            logData={adminLogData.logData?.actionTarget?.adminId}
          />
        )}

        {/*Add other Details */}
      </div>
      <div className="activity-log-modal__footer flex-center"></div>
    </div>
  );
};

export default AdminLogModal;

import { useEffect, useState } from 'react';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//components
import Spinner from '../Spinner/Spinner';

//utils
import {
  checkmark_green,
  img_arrow_order,
  img_refresh_two_circular,
  img_resize,
} from '../../assets/icons/index';

//Notes
// data: each row is an object in an array; each cell/value for the table rows needs to be sent as a key-value pair, where the value is used for displaying

//use hide columns to hide the last column in the table which should contain the id for selection purposes

const Table = ({
  id,
  data,
  parentHeadings,
  subHeadings,
  subHeadingPos,
  loadTableHeadings,
  handleSetTableHeadings,
  loadTableSubHeadings,
  handleSetTableSubHeadings,
  handleViewDetails,
  currentViewDetailsSelections,
  viewDetailsText,
  cellTranslations,
  hiddenColumns,
  isLoadingData,
  isFetchingData,
  cellElements,
  sortingHandlers,
  sortingState,
  tableTitle,
  tableTitleIcon,
  handleResetTableSizes,
  connectionStatus,
  connectionStatusTrackerOn,
}) => {
  //language
  const { Generic, IconAlts } = useLanguageComponents();

  // state
  const [resizeHeight, setResizeHeight] = useState();
  const [resizeActive, setResizeActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //variables
  //Initialize

  useEffect(() => {
    if (parentHeadings && data) {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 500);

      return () => clearTimeout(timer);
    }
  }, [parentHeadings, isLoadingData]);

  //UI functions
  useEffect(() => {
    document.body.style.cursor = resizeActive ? 'col-resize' : 'auto';
  }, [resizeActive]); //handles cursor appearance

  useEffect(() => {
    const tdBodyEl = document?.getElementById(`tbody-${id}`);
    setResizeHeight(tdBodyEl.offsetHeight);
  }, [isLoading]); //gets height for resize-handler

  //Component functions
  const handleMouseDown = (index, e) => {
    setResizeActive(true);
    let startX = e.pageX;
    const initialWidth = loadTableHeadings[index] || 100;

    const handleMouseMove = (e) => {
      const width = initialWidth + (e.pageX - startX);

      const newWidths = [...loadTableHeadings];
      newWidths[index] = width;
      handleSetTableHeadings(newWidths);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      setResizeActive(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDownSubheading = (
    parentIndex,
    index1,
    index2,
    subHeadingId,
    e
  ) => {
    const headingEl = document.getElementById(`heading-${parentIndex}`);
    const subHeadingEl = document.getElementById(subHeadingId);
    const columnWidth = headingEl.offsetWidth;
    const initialSubHeadingWidth = subHeadingEl.offsetWidth;
    let startX = e.pageX;

    setResizeActive(true);

    const handleMouseMove = (e) => {
      const width = e.pageX - startX;
      // Ensure the new subheading width doesn't exceed the parent heading width

      const newSubHeadingWidth = Math.min(
        columnWidth,
        initialSubHeadingWidth + width
      );
      const newWidths = loadTableSubHeadings?.map((subArray) => [...subArray]); //deep clone
      newWidths[index1][index2] = newSubHeadingWidth;
      handleSetTableSubHeadings(newWidths);
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      setResizeActive(false);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      {isLoading && <Spinner minWidth={'80%'} maxHeight={'100%'} />}

      <div
        className="full-width"
        style={{
          display: `${
            isLoading || isLoadingData || isFetchingData ? 'none' : ''
          }`,
        }}
      >
        <table className="table-component">
          <thead className="access-ob">
            {tableTitle && (
              <tr>
                <th
                  colSpan={
                    subHeadings?.length > 0
                      ? parentHeadings.length + subHeadings.flat().length
                      : parentHeadings.length
                  }
                >
                  <div className="flex-row space-between">
                    <div className="flex-row align-center ">
                      {tableTitleIcon && (
                        <img
                          src={tableTitleIcon}
                          alt={IconAlts.icon}
                          className="mrg-auto-tb mrg-r12 filter-gray"
                        />
                      )}
                      <div className="live-connection-icon mrg-l6 mrg-t6">
                        {connectionStatusTrackerOn && (
                          <img
                            src={img_refresh_two_circular}
                            alt={IconAlts.icon}
                            className={`svg ${
                              connectionStatus !== 'connected'
                                ? 'live-connection-status--pending--staging'
                                : 'remove'
                            }`}
                            title={Generic.attemptingToReconnect}
                          />
                        )}
                      </div>
                    </div>
                    <p className="fs21 align-center">{tableTitle}</p>

                    <button
                      className="highlight-i-gt"
                      title={Generic.resetTableSize}
                      onClick={handleResetTableSizes}
                    >
                      <img src={img_resize} alt={IconAlts.iconResize} />
                    </button>
                  </div>
                </th>
              </tr>
            )}
            <tr>
              {parentHeadings?.map((heading, index) => {
                let sortingFunction;
                let sortingBoolean;

                sortingHandlers?.map((obj) => {
                  const objIndex = Object.keys(obj);
                  const numericIndex = parseInt(objIndex[0]);

                  if (numericIndex === index) {
                    const convertSortingFunctArr = Object.values(obj);
                    sortingFunction = convertSortingFunctArr[0];
                  }
                });

                sortingState?.map((obj) => {
                  const objIndex = Object.keys(obj);
                  const numericIndex = parseInt(objIndex[0]);

                  if (numericIndex === index) {
                    const convertSortingBoolean = Object.values(obj);

                    sortingBoolean = convertSortingBoolean[0];
                  }
                });

                return (
                  <th
                    rowSpan={`${!subHeadingPos?.includes(index) ? 2 : 1}`}
                    key={`heading-${index}`}
                    colSpan={`${subHeadingPos?.includes(index) ? 2 : 0}`}
                    style={{ width: loadTableHeadings[index] || 'auto' }}
                    className={`no-select access-ob ${
                      hiddenColumns?.includes(index) ? 'remove' : ''
                    } ${sortingFunction ? 'cursor-pointer' : ''}`}
                    id={`heading-${index}`}
                    tabIndex="0"
                    onClick={
                      sortingFunction
                        ? () => sortingFunction(sortingBoolean)
                        : null
                    }
                  >
                    {heading}
                    {sortingFunction && (
                      <img
                        src={img_arrow_order}
                        alt={IconAlts.iconArrow}
                        className={`mrg-l12 ${
                          sortingBoolean ? 'reverse-vertical' : ''
                        }`}
                      />
                    )}

                    {resizeHeight && (
                      <div
                        className="resize-handle"
                        style={{
                          height: `${
                            subHeadingPos
                              ? `${resizeHeight + 100 + 'px'}`
                              : `${resizeHeight + 50 + 'px'}`
                          }`,
                        }}
                        onMouseDown={(e) => handleMouseDown(index, e)}
                      />
                    )}
                  </th>
                );
              })}
            </tr>
            <tr>
              {subHeadings?.map((headings, index1) =>
                headings?.map((heading, index2) => {
                  return (
                    <th
                      key={`subheading-${index1}-${index2}`}
                      className="no-select access-ob"
                      id={`subheading-${index1}-${index2}`}
                      style={{
                        width: `${loadTableSubHeadings[index1][index2]}px`,
                      }}
                      tabIndex="0"
                    >
                      {resizeHeight && index2 < subHeadings?.length && (
                        <div
                          className="resize-handle"
                          style={{
                            height: `${resizeHeight + 50 + 'px'}`,
                          }}
                          onMouseDown={(e) =>
                            handleMouseDownSubheading(
                              subHeadingPos[index1],
                              index1,
                              index2,
                              `subheading-${index1}-${index2}`,
                              e
                            )
                          }
                        />
                      )}
                      {heading}
                    </th>
                  );
                })
              )}
            </tr>
          </thead>

          <tbody id={`tbody-${id}`}>
            {data?.map((dataRow, index1) => {
              return (
                <tr key={`${id}-${index1}-tr`}>
                  {handleViewDetails && (
                    <td>
                      <button
                        onClick={() => handleViewDetails(dataRow?.id)}
                        tabIndex="0"
                        className="fs18 color-blue no-select access-ob access-o6"
                      >
                        {viewDetailsText}
                        {currentViewDetailsSelections?.includes(
                          dataRow?.id
                        ) && (
                          <img
                            src={checkmark_green}
                            alt="check"
                            className="mrg-l12"
                          />
                        )}
                      </button>
                    </td>
                  )}
                  {Object.values(dataRow)?.map((cell, index2) => {
                    const currentIndex2 = handleViewDetails
                      ? index2 + 1
                      : index2;

                    let cellData;

                    cellTranslations?.map((obj) => {
                      const objIndex = Object.keys(obj);
                      const numericIndex = parseInt(objIndex[0]);

                      if (numericIndex === currentIndex2) {
                        const transArr = Object.values(obj);
                        const transObj = transArr[0];

                        cellData = transObj[cell];
                      }
                    });

                    cellElements?.map((obj) => {
                      const objIndex = Object.keys(obj);
                      const numericIndex = parseInt(objIndex[0]);

                      if (numericIndex === currentIndex2) {
                        const convertElFunctArr = Object.values(obj);
                        const convertElFunct = convertElFunctArr[0];

                        cellData = convertElFunct(cell);
                      }
                    });

                    if (!cellData) {
                      cellData = cell;
                    }

                    return (
                      <td
                        key={`${id}-${index2}-td`}
                        tabIndex="0"
                        className={`access-ob ${
                          hiddenColumns?.includes(index2) ? 'remove' : ''
                        }`}
                      >
                        {cellData}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;

import { useState } from 'react';
//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import ScrollTo from '../../utils/UI/ScrollTo';
import { double_arrow } from '../../assets/icons';

const ScrollToTop = ({ focusId, customWrapper }) => {
  //Hooks
  const { handleMobileTap } = useSettings();

  //language
  const { BackToTop } = useLanguageComponents();

  //UI state
  const [tapHighlight, setTapHighlight] = useState(false);

  return (
    <button
      type="button"
      className={`flex-row flex-center color-gray mrg-auto-lr highlight-bthin-theme br--small pad-tb12 pad-lr12 fit-content fwsb   fwn br--standard access-ob access-o6 fs18 ${
        tapHighlight ? 'highlight-b-theme--tap' : ''
      } ${customWrapper ? customWrapper : ''}`}
      onClick={() =>
        handleMobileTap(
          [() => setTapHighlight(true), () => setTapHighlight(false)],
          [() => ScrollTo({ focusId, behavior: 'smooth', scrollToTop: true })]
        )
      }
      tabIndex="0"
      aria-label={BackToTop.ariaBackToTop}
    >
      {BackToTop.backToTop}
      <img
        src={double_arrow}
        className="mrg-l8 filter-gray"
        alt="arrow"
        style={{ height: `18px`, marginTop: '1px' }}
      />
    </button>
  );
};

export default ScrollToTop;

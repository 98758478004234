//Language
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import { arrow_long, img_building3, img_virtual } from '../../assets/icons';

const EventStatusBarSwitch = ({ inPersonToVirtual }) => {
  //Language
  const { EventStatusBarSwitch, IconAlts } = useLanguageComponents();

  return (
    <div
      className="status-bar status-color-registration-neutral access-ob access-o6"
      tabIndex="0"
      aria-label={
        inPersonToVirtual
          ? EventStatusBarSwitch.inPersonToVirtual
          : EventStatusBarSwitch.virtualToInPerson
      }
      title={
        inPersonToVirtual
          ? EventStatusBarSwitch.inPersonToVirtual
          : EventStatusBarSwitch.virtualToInPerson
      }
    >
      {inPersonToVirtual ? (
        <img
          src={img_building3}
          alt={IconAlts.iconInPerson}
          className="filter-registration-registered"
        />
      ) : (
        <img
          src={img_virtual}
          alt={IconAlts.iconVirtual}
          className="filter-registration-pending"
        />
      )}

      <img
        src={arrow_long}
        alt={IconAlts.iconArrow}
        className="filter-gray rotate-cw90 mrg-lr12"
      />

      {inPersonToVirtual ? (
        <img
          src={img_virtual}
          alt={IconAlts.iconVirtual}
          className="filter-registration-pending"
        />
      ) : (
        <img
          src={img_building3}
          alt={IconAlts.iconInPerson}
          className="filter-registration-registered"
        />
      )}
    </div>
  );
};

export default EventStatusBarSwitch;

import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

//hooks
import useAdminSupportEventVerificationHandlers from '../../../features/admin/support/EventVerification/useAdminSupportEventVerificationHandlers';
import { useAdminAccountReduxHandlers } from '../../../features/admin/account/useAdminAccountHandlers';

const EventVerificationsSocketContext = createContext({});

export const EventVerificationsSocketProvider = ({ children }) => {
  //Hooks
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleSocketEventVerificationsData } =
    useAdminSupportEventVerificationHandlers();

  const [eventVerificationSocketStatus, setEventVerificationSocketStatus] =
    useState('closed');

  //Refs
  const socketRef = useRef(null);

  //variables
  const logsOn = false;

  const ioEventVerificationsSocketURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/eventVerification'
      : 'https://myndfull.com/ws/eventVerification';
  const withCredentials =
    process.env.REACT_APP_ENV === 'development' ? false : true;

  const connectEventVerificationsSocket = () => {
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    if (!socketRef.current && eventVerificationSocketStatus !== 'connected') {
      setEventVerificationSocketStatus('initializing');
      let newSocket;

      newSocket = io(ioEventVerificationsSocketURL, {
        query: {
          adminAccountId,
        },
        reconnectionAttempts: 10,
        withCredentials: withCredentials,
      });

      newSocket.on('connect', () => {
        logsOn &&
          console.log(
            `Connected to EventVerifications Socket, id: ${newSocket.id}`
          );
        setEventVerificationSocketStatus('connected');
      });

      newSocket.on('eventVerificationData', (eventVerificationData) => {
        handleSocketEventVerificationsData(eventVerificationData);
      });

      newSocket.on('disconnect', (reason, details) => {
        logsOn &&
          console.error(
            `Event Verification socket disconnect, Reason: ${reason}, Details: ${details}`
          );
        setEventVerificationSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn &&
            console.error('Event Verification socket, no automatic reconnect');
          closeEventVerificationsSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn &&
            console.error('Event Verification socket, automatic reconnect');
        } else {
          logsOn &&
            console.error(
              `Event Verification socket, Unexpected disconnect reason: ${reason}`
            );
          closeEventVerificationsSocket();
        }
      });

      newSocket.on('error', (error) => {
        logsOn &&
          console.error('Event Verification socket, socket error:', error);
        setEventVerificationSocketStatus('closed');
        closeEventVerificationsSocket();
      });

      return (socketRef.current = newSocket);
    }
  };

  const getEventVerificationsSocket = () => {
    return socketRef.current;
  };

  const closeEventVerificationsSocket = () => {
    const socket = socketRef.current;
    if (socket) {
      socket.close();
      socketRef.current = null;
      setEventVerificationSocketStatus('closed');
    }
  };

  return (
    <EventVerificationsSocketContext.Provider
      value={{
        connectEventVerificationsSocket,
        closeEventVerificationsSocket,
        getEventVerificationsSocket,
        eventVerificationSocketStatus,
      }}
    >
      {children}
    </EventVerificationsSocketContext.Provider>
  );
};

export const useEventVerificationsSocket = () => {
  return useContext(EventVerificationsSocketContext);
};

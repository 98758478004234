//language
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../../../../language/useLanguageComponents';

//utils
import {
  checkmark_green,
  img_exclamation,
  img_dispatch,
} from '../../../../../../../assets/icons';

const ParticipantListItemCompletionStatus = ({
  completionStatus,
  organizedAs,
  duplicateEmail,
  invalidEmail,
  translationsLanguageConflict,
  translationsRoomRequiresLanguage,
  translationsIsDispatched,
  inviteIsDispatched,
}) => {
  //language
  const { IconAlts } = useLanguageComponents();
  const {
    ParticipantListItem,
    ParticipantListItemCompletionStatusNonDispatchable,
    ParticipantListItemCompletionStatusTranslationsEmailInvitation,
  } = useLanguageOrganize();

  //variables
  let content = null;
  let icon = null;

  switch (true) {
    case invalidEmail:
      content = ParticipantListItem.invalidEmail;
      icon = img_exclamation;
      break;
    case duplicateEmail:
      content = ParticipantListItem.duplicateEmail;
      icon = img_exclamation;
      break;
    case translationsRoomRequiresLanguage:
      content = ParticipantListItem.requiresRoomLanguages;
      icon = img_exclamation;
      break;
    case translationsLanguageConflict:
      content = ParticipantListItem.languageConflict;
      icon = img_exclamation;
      break;
    default:
      switch (organizedAs) {
        case 'create':
          content =
            ParticipantListItemCompletionStatusNonDispatchable[
              completionStatus
            ];
          if (completionStatus === 'incomplete') {
            icon = img_exclamation;
          } else {
            icon = checkmark_green;
          }
          break;
        case 'emailInvitation':
          if (!translationsIsDispatched) {
            content =
              ParticipantListItemCompletionStatusTranslationsEmailInvitation[
                completionStatus
              ];
            if (completionStatus === 'incomplete') {
              icon = img_exclamation;
            }
            if (completionStatus === 'complete') {
              content = ParticipantListItem.readyToDispatch;
              icon = img_dispatch;
            }
          } else {
            if (translationsIsDispatched || inviteIsDispatched) {
              content =
                ParticipantListItemCompletionStatusNonDispatchable[
                  completionStatus
                ];
              if (completionStatus === 'incomplete') {
                icon = img_exclamation;
              }
            }
          }
          break;
        default:
          if (translationsIsDispatched || inviteIsDispatched) {
            content =
              ParticipantListItemCompletionStatusNonDispatchable[
                completionStatus
              ];
          } else {
            content = ParticipantListItem.readyToDispatch;
            icon = img_dispatch;
          }
          break;
      }
      break;
  }

  return (
    <div className={`participant-list-item__status-box mrg-r6`}>
      <p>{content}</p>
      {icon && (
        <img
          src={icon}
          alt={
            icon === img_exclamation
              ? IconAlts.iconExclamation
              : IconAlts.iconCheckmark
          }
          style={{
            height:
              icon === checkmark_green
                ? '12px'
                : icon === img_dispatch
                ? '21px'
                : '18px',
          }}
          className={`mrg-l6 ${icon === img_dispatch ? '' : 'mrg-auto-tb'} ${
            icon === img_exclamation ? 'filter-red' : ''
          } ${icon === img_dispatch ? 'filter-blue' : ''}`}
        />
      )}
    </div>
  );
};

export default ParticipantListItemCompletionStatus;

import LoadBucketImage from '../LoadBucketImage/LoadBucketImage';
import Spinner from '../Spinner/Spinner';

const ProfilePersonalSkeleton = ({ customWrapper, spinnerAnimationOn }) => {
  return (
    <div
      className={`account-profile__wrapper--empty ${
        customWrapper ? customWrapper : ''
      }`}
    >
      <div className="full-width">
        <LoadBucketImage
          containerClassName={'account-profile-banner '}
          imgClassName={'account-profile-banner--image'}
          backupPath={'banner_img_default.png'}
          partialPath={'myndfull_banner_images'}
        />
      </div>
      <div
        className={`account-profile__container--empty ${
          !spinnerAnimationOn ? 'opacity-03' : ''
        }`}
      >
        {spinnerAnimationOn && <Spinner />}
      </div>
    </div>
  );
};

export default ProfilePersonalSkeleton;

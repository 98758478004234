import { useEffect, useState } from 'react';

//Hooks
import { useGetDuration } from '../../../../utils/dateTime/GetDuration';

//Langauge
import useLanguageData from '../../../../language/useLanguageData';

const EventPageAbout = ({ eventData, EventPage }) => {
  //Hooks
  const {
    AudienceTypesEventPage,
    EventTypes,
    LanguageSimpleOpts,
    HostingOptions,
  } = useLanguageData();
  const { getDurationWithUTC, getDurationWithoutUTC } = useGetDuration();

  //state
  const [languages, setLangauges] = useState([]);
  const [duration, setDuration] = useState();

  useEffect(() => {
    let durationString;

    if (
      eventData.dateTime?.dateTimeStartUTC &&
      eventData.dateTime?.dateTimeEndUTC
    ) {
      durationString = getDurationWithUTC(eventData.dateTime);
    } else if (eventData.dateTime?.timeStart && eventData.dateTime?.timeEnd) {
      durationString = getDurationWithoutUTC(eventData.dateTime);
    }
    if (durationString) {
      setDuration(`${EventPage.duration} ${durationString}`);
    }

    let newLanguageArr = [];
    for (const lang of eventData?.generic?.languages || []) {
      if (lang !== 'notListed') {
        newLanguageArr.push(LanguageSimpleOpts[lang]);
      }
    }

    for (const langObj of eventData?.generic?.languagesNotListed || []) {
      newLanguageArr.push(langObj.language);
    }

    setLangauges(newLanguageArr);
  }, [eventData]);

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="aboutLabel"
      >
        {EventPage.aboutThisEvent}
      </label>
      <div className="ev-info__container">
        <div className="ev-info__row access-ob access-o6" tabIndex="0">
          {HostingOptions[eventData.hosting.hostingOption]}
        </div>
        <div className="ev-info__row access-ob access-o6" tabIndex="0">
          {EventTypes[eventData.generic.eventType]}
        </div>
        <div className="ev-info__row access-ob access-o6" tabIndex="0">
          {AudienceTypesEventPage[eventData.generic.audienceType]}
        </div>
        {duration && (
          <div className="ev-info__row access-ob access-o6" tabIndex="0">
            {duration}
          </div>
        )}
        {languages?.length > 0 && (
          <div className="ev-info__row access-ob access-o6" tabIndex="0">
            {EventPage.languages}:&nbsp;
            {languages?.map((lang, index) => {
              return (
                <p key={lang}>
                  {lang}
                  {languages?.length - 1 > index && ','}
                  &nbsp;
                </p>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default EventPageAbout;

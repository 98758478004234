import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useGenericModal } from '../../../../../context/GenericModalProvider';
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../components/Spinner/Spinner';
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import ImportTranslationsRoomDataModal from '../../../../../components/Modal/ModalComponents/FolderModals/ImportTranslationsRoomDataModal';

//utility
import { image_gears } from '../../../../../assets/images';
import { formatTimestampWithTimeZone } from '../../../../../utils/dateTime/DateCalc';

const CreateRoomInitializeRoomData = ({ setPage }) => {
  const {
    restoreData,
    restoreDataStatus,
    handleRestoreData,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    handleEjectAttendees,
    savedTranslationsRoomData,
    setJoinRoomPasswordToggle,
    setAttendeeJoinRoomPassword,
    setSpeakersData,
    setRoomLanguages,
    handleResetCurrentHostSessionData,
    setSessionSaveName,
    hostTimeZone,
  } = useTranslationRoomSocket();
  const { setGenericModal } = useGenericModal();
  const { contentLanguage } = useSettings();

  //language
  const { CreateRoomInitializeRoomData } = useLanguageTranslationRoom();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  function handleImportTranslationsRoomDataModal() {
    setGenericModal(
      <ImportTranslationsRoomDataModal
        handler={handleImportTranslationsRoomDataSelectionKey}
      />
    );
  }

  function handleImportTranslationsRoomDataSelectionKey(key) {
    const categories = [
      savedTranslationsRoomData?.drafts,
      savedTranslationsRoomData?.events,
      savedTranslationsRoomData?.scheduledEvents,
      savedTranslationsRoomData?.archived,
      savedTranslationsRoomData?.translationsSessions,
    ];
    const saveData = categories.flat().find((ev) => ev?._id === key);

    setSessionSaveName(saveData?.meta?.saveName || saveData?.generic?.title);

    //set password toggle
    setJoinRoomPasswordToggle(saveData?.translations?.joinRoomPasswordToggle);

    //set password
    if (
      saveData?.translations?.joinRoomPasswordToggle &&
      saveData?.translations?.password?.length
    ) {
      setAttendeeJoinRoomPassword(saveData?.translations?.password);
    }
    //set languages
    setRoomLanguages(saveData?.translations?.roomLanguages);

    //set speakers
    let newSpeakers = [];
    saveData?.participants?.map((speakerData) => {
      let newSpeaker = {
        id: speakerData._id,
        ...speakerData.translationsSpeakerData,
      };
      newSpeakers.push(newSpeaker);
    });
    setSpeakersData(newSpeakers);

    //nav
    handleCreatePageChange('createTitle');
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_gears}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p
            className="fs21 fwsb mrg-tb24 access-ob access-o6"
            tabIndex="0"
            id={'createRoom'}
          >
            {CreateRoomInitializeRoomData.mainMenu}
          </p>

          {restoreDataStatus === 'restoreDataFound' &&
            restoreData?.sessionEnded && (
              <div className="flex-column full-width mrg-b24">
                <ButtonMeta
                  handleClick={() => {
                    handleRestoreData('restore');
                    handleCreatePageChange('hostRoom');
                  }}
                  btnText={CreateRoomInitializeRoomData.restorePreviousSession}
                  customClassName={'min-full-width'}
                  fullWidth={true}
                />
                <p className="fs18 fwn mrg-auto-lr mrg-tb12 mrg-t0--ph">
                  {formatTimestampWithTimeZone({
                    timestamp: restoreData?.sessionEnded,
                    locale: contentLanguage,
                    timeZone: hostTimeZone,
                  })}
                </p>
              </div>
            )}

          <ButtonMeta
            btnText={CreateRoomInitializeRoomData.newSession}
            fullWidth={true}
            customClassName={'min-full-width mrg-b24'}
            handleClick={() => {
              handleRestoreData('ignore');
              handleResetCurrentHostSessionData();
              handleEjectAttendees();
              handleCreatePageChange('createTitle');
            }}
          />

          <ButtonMeta
            handleClick={handleImportTranslationsRoomDataModal}
            btnText={CreateRoomInitializeRoomData.load}
            fullWidth={true}
            customClassName={'min-full-width mrg-b24'}
            id={'translationsRoomLoadBtn'}
          />

          <ButtonMeta
            handleClick={() => handleCreatePageChange('drafts')}
            btnText={CreateRoomInitializeRoomData.sessionDrafts}
            fullWidth={true}
            customClassName={'min-full-width mrg-b24'}
            id={'translationsRoomDraftsBtn'}
          />

          <ButtonMeta
            handleClick={() => handleCreatePageChange('transcripts')}
            btnText={CreateRoomInitializeRoomData.transcripts}
            fullWidth={true}
            customClassName={'min-full-width'}
            id={'translationsTranscriptsBtn'}
          />

          <div className="divider full-width mrg-t36 mrg-b12" />
          <div className="h24" />

          <ItemButton
            text={CreateRoomInitializeRoomData.logout}
            handler={() => setPage(1)}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
};

export default CreateRoomInitializeRoomData;

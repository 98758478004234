import { organize, search_dark, speaker_dark } from '../../../assets/icons';

const ExploreFeaturesButtons = ({ exploreFeature, changeExploreTopic }) => {
  return (
    <ul className="explore-features-btns">
      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'findEvents'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => {
            changeExploreTopic('findEvents');
          }}
          aria-label={'Select for viewing finding events features below.'}
          aria-pressed={exploreFeature === 'findEvents'}
        >
          <img src={search_dark} alt="" aria-hidden={true} />
          <p>Finding Events</p>
        </button>
      </li>

      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'organize'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => changeExploreTopic('organize')}
          aria-pressed={exploreFeature === 'organize'}
          aria-label={'Select for viewing organize features below.'}
        >
          <img src={organize} alt="" aria-hidden={true} />
          <p>Organizing</p>
        </button>
      </li>

      <li>
        <button
          className={`explore-features-button access-ob access-o6 ${
            exploreFeature === 'speaker'
              ? 'explore-features-button--active'
              : ''
          }`}
          onClick={() => changeExploreTopic('speaker')}
          aria-pressed={exploreFeature === 'speaker'}
          aria-label={'Select for viewing speaker features below.'}
        >
          <img src={speaker_dark} alt="" aria-hidden={true} />
          <p>Speaking</p>
        </button>
      </li>
    </ul>
  );
};

export default ExploreFeaturesButtons;

import { useEffect, useState } from 'react';

//language
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import ScrollToTop from '../../../../../components/ScrollToTop/ScrollToTop';
import SpeakerCredentials from './sections/SpeakerCredentials';
import SpeakerLanguages from './sections/SpeakerLanguages';
import SpeakerLocation from './sections/SpeakerLocation';
import SpeakerMeta from './sections/SpeakerMeta';
import SpeakerTags from './sections/SpeakerTags';
import SpeakerCommunitySettings from './sections/SpeakerCommunitySettings';
import ProfilePreview from '../ProfilePreview/ProfilePreview';

//utils

const SpeakerSignUp = ({ setSpeakerSignUpIsLoading }) => {
  //language
  const { SpeakerSignUp } = useLanguageAccount();

  //state
  const [resetSpeaker, setResetSpeaker] = useState(); //used in conjunction with redux state reset, but this is to refresh components

  useEffect(() => {
    if (resetSpeaker) {
      setResetSpeaker(false);
    }
  }, [resetSpeaker]);

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="speakerSignUpLabel"
      >
        {SpeakerSignUp.speakerSignUp}
      </label>
      <div className="h12" />
      <ul className="mrg-l12 fs18">
        <li>
          <p className="description-text" tabIndex="0">
            {SpeakerSignUp.instructions1}
          </p>
        </li>
        <li>
          <p className="description-text mrg-t12" tabIndex="0">
            {SpeakerSignUp.instructions2}
          </p>
        </li>

        <li>
          <p className="description-text mrg-t12" tabIndex="0">
            {SpeakerSignUp.instructions3}
          </p>
        </li>
      </ul>

      <div className="h36" />
      <div className="border-reg pad-24 br--standard" id="fieldset-access">
        <SpeakerMeta
          setResetSpeaker={setResetSpeaker}
          setSpeakerSignUpIsLoading={setSpeakerSignUpIsLoading}
        />
        <ProfilePreview previewMode={true} speakerPreview={true} />

        <div className="h36" />
        <SpeakerCommunitySettings />

        <div className="h36" />
        <SpeakerLanguages />

        <div className="h36" />
        <SpeakerLocation />

        <div className="h36" />
        <SpeakerCredentials resetSpeaker={resetSpeaker} />

        <div className="h36" />
        <SpeakerTags />

        <div className="h36" />
      </div>
      <div className="h36" />
      <ScrollToTop focusId={'speakerMetaBtns'} />
    </>
  );
};

export default SpeakerSignUp;

import { useEffect, useRef, useState } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useEventHandlers } from '../useEventHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';

//language
import useLanguageEvents from '../../../../language/features/useLanguageEvents';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import EventStatusBar from '../../../../components/EventStatusBar/EventStatusBar';
import AddressContent from './AddressContent';

//Utility
import {
  building2,
  calendar,
  eventcard_follow,
  eventcard_ignore,
  eventcard_star,
  eventcard_accessibility,
  globe2,
  hybrid2,
  organize,
  star,
  trash,
  view_ignore,
  bell,
} from '../../../../assets/icons';
import { useDateTimeCalculations } from '../../../../utils/dateTime/DateCalc';

const EventCard = ({ eventData, setViewEvent }) => {
  //Hooks
  const { width, isTouchDevice, handleMobileTap } = useSettings();
  const { getDateShortMonth, getDateTimeSummary } = useDateTimeCalculations();
  const {
    accountContentLanguage,
    accountTimeZone,
    accountId,
    accountSavedEvents,
    accountRegistrationsInPerson,
    accountPendingApprovalInPerson,
    accountIgnoredEvents,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
    accountRegistrationsVirtual,
    accountPendingApprovalVirtual,
    accountParticipantDrafts,
    accountParticipantEvents,
  } = useAccountReduxHandlers();
  const {
    handleRemoveArchivedEvent,
    handleUserSaveEvent,
    handleUserIgnoreEvent,
  } = useEventHandlers();
  const { settingsMyEventsView, settingsEventSearchView } =
    useSettingsHandlers();

  //language
  const { EventCard } = useLanguageEvents();

  //Component state
  const [eventStatus, setEventStatus] = useState('neutral');
  const [organizerName, setOrganizerName] = useState('');

  const [img1Loaded, setImg1Loaded] = useState();
  const [img2Loaded, setImg2Loaded] = useState();
  const [img3Loaded, setImg3Loaded] = useState();
  const [img4Loaded, setImg4Loaded] = useState();

  const [isParticipant, setIsParticipant] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [isCommunityAccount, setIsCommunityAccount] = useState(false);

  //UI state
  const [overlayDisplay, setOverlayDisplay] = useState(false);
  const [tapHighlightSave, setTapHighlightSave] = useState(false);
  const [tapHighlightIgnore, setTapHighlightIgnore] = useState(false);
  const [tapHighlightRemoveArchived, setTapHighlightRemoveArchived] =
    useState(false);
  const [tapHighlightAnnouncements, setTapHighlightAnnouncements] =
    useState(false);
  const [tapCardScaleDown, setTapCardScaleDown] = useState(false);
  const [imageLoadConfirmation, setImageLoadConfirmation] = useState(false);

  const [renderDelayComplete, setRenderDelayComplete] = useState(false);

  //Component variables
  const initializationEffectRan = useRef(false);
  const imageContainerRef = useRef(null);
  const blurTimerRef = useRef(null);
  const minimumRenderTimerRef = useRef(null);

  //Initialize functions
  useEffect(() => {
    minimumRenderTimerRef.current = setTimeout(() => {
      setRenderDelayComplete(true);
    }, 250);

    return () => {
      if (minimumRenderTimerRef.current) {
        clearTimeout(minimumRenderTimerRef.current);
      }
      if (blurTimerRef.current) {
        clearTimeout(blurTimerRef.current);
      }
    };
  }, []); //clean up timers

  useEffect(() => {
    if (eventData?.organizers) {
      if (initializationEffectRan.current === false) {
        initializationEffectRan.current = true;

        setOrganizerName(
          `${eventData?.organizers?.organizerData?.personal?.firstName} ${eventData?.organizers?.organizerData?.personal?.lastName}`
        );

        if (eventData?.meta?.isDraft === true) {
          setIsDraft(true);
        }
      }
    }
  }, [eventData]); //organizer initialize

  useEffect(() => {
    if (eventData?.participants?.length > 0) {
      if (
        accountParticipantDrafts.includes(eventData._id) ||
        accountParticipantEvents.includes(eventData._id)
      ) {
        setIsParticipant(true);
      }
    }
  }, [eventData, accountParticipantDrafts, accountParticipantEvents]); //check if participant

  useEffect(() => {
    if (eventData) {
      //check following organizer
      if (
        accountCommunityFollowing?.some(
          (account) =>
            account._id?.toString() ===
            eventData.organizers.organizerId?.toString()
        ) ||
        accountCommunityFollowingGroups?.some((group) =>
          group.following.some(
            (following) =>
              following._id?.toString() ===
              eventData.organizers.organizerId?.toString()
          )
        )
      ) {
        return setIsCommunityAccount(true);
      }

      //check following participant
      if (eventData?.participants?.length > 0) {
        let isFollowed = false;
        let isFollowedInGroup = false;

        for (const participantObj of eventData?.participants || []) {
          //ensure participant has accepted
          if (
            participantObj?.participantData?.participantAccountId &&
            participantObj?.dispatches?.invitationStatus === 'accepted'
          ) {
            isFollowed = accountCommunityFollowing?.some(
              (account) =>
                account._id?.toString() ===
                participantObj?.participantData?.participantAccountId?.toString()
            );

            isFollowedInGroup = accountCommunityFollowingGroups?.some((group) =>
              group.following.some(
                (following) =>
                  following._id?.toString() ===
                  participantObj?.participantData?.participantAccountId?.toString()
              )
            );
          }
        }

        if (isFollowed || isFollowedInGroup) {
          return setIsCommunityAccount(true);
        }
      }
    }
  }, [eventData, accountCommunityFollowing, accountCommunityFollowingGroups]); //following status

  //Component functions
  useEffect(() => {
    if (eventData) {
      //Generic status
      if (accountSavedEvents?.includes(eventData._id)) {
        return setEventStatus('saved');
      }
      if (accountIgnoredEvents?.includes(eventData._id)) {
        return setEventStatus('ignored');
      }

      //InPerson
      if (accountPendingApprovalInPerson?.includes(eventData._id)) {
        if (accountRegistrationsVirtual.includes(eventData._id)) {
          return setEventStatus('pendingApprovalInPerson-registeredVirtual');
        } else {
          return setEventStatus('pendingApprovalInPerson');
        }
      }

      if (accountRegistrationsInPerson.includes(eventData._id)) {
        if (accountPendingApprovalVirtual.includes(eventData._id)) {
          return setEventStatus('pendingApprovalVirtual-registeredInPerson');
        } else {
          return setEventStatus('registeredInPerson');
        }
      }

      //Virtual
      if (accountPendingApprovalVirtual?.includes(eventData._id)) {
        if (accountRegistrationsInPerson.includes(eventData._id)) {
          return setEventStatus('pendingApprovalVirtual-registeredInPerson');
        } else {
          return setEventStatus('pendingApprovalVirtual');
        }
      }

      if (accountRegistrationsVirtual.includes(eventData._id)) {
        if (accountPendingApprovalInPerson.includes(eventData._id)) {
          return setEventStatus('pendingApprovalInPerson-registeredVirtual');
        } else {
          return setEventStatus('registeredVirtual');
        }
      }
    }

    /*eslint-disable-next-line*/
  }, [
    eventData,
    accountSavedEvents,
    accountIgnoredEvents,
    accountRegistrationsInPerson,
    accountRegistrationsVirtual,
    accountPendingApprovalInPerson,
    accountPendingApprovalVirtual,
  ]); //event status management

  function handleActionOverlayClick(selection) {
    if (!isTouchDevice || overlayDisplay) {
      if (selection === 'removeArchived') {
        handleMobileTap(
          [
            () => setTapHighlightRemoveArchived(true),
            () => setTapHighlightRemoveArchived(false),
          ],
          [
            () =>
              handleRemoveArchivedEvent(
                eventData._id,
                accountId,
                eventData.meta.eventReference
              ),
            () => setOverlayDisplay(false),
          ],
          true
        );
      } else if (selection === 'save') {
        handleMobileTap(
          [() => setTapHighlightSave(true), () => setTapHighlightSave(false)],
          [
            () =>
              handleUserSaveEvent(
                eventData._id,
                accountId,
                eventStatus,
                setEventStatus
              ),
            () => setOverlayDisplay(false),
          ],
          true
        );
      } else if (selection === 'ignore') {
        handleMobileTap(
          [
            () => setTapHighlightIgnore(true),
            () => setTapHighlightIgnore(false),
          ],
          [
            () =>
              handleUserIgnoreEvent(
                eventData._id,
                accountId,
                eventStatus,
                setEventStatus
              ),
            () => setOverlayDisplay(false),
          ],
          true
        );
      } else if (selection === 'announcements') {
        handleMobileTap(
          [
            () => setTapHighlightAnnouncements(true),
            () => setTapHighlightAnnouncements(false),
          ],
          [
            () => console.log('Open announcements'),
            () => setOverlayDisplay(false),
          ],
          true
        );
      }
    }
  }

  function handleOverlayClick() {
    if (isTouchDevice) {
      let timeout = null;
      timeout = setTimeout(() => {
        setOverlayDisplay(true);
      }, 250);
      return () => {
        if (timeout !== null) {
          clearTimeout(timeout);
        }
      };
    }
  }

  function handleBlur() {
    // Set a timer to check if the focus has moved outside the container or its children
    if (document?.activeElement) {
      blurTimerRef.current = setTimeout(() => {
        if (!imageContainerRef.current?.contains(document.activeElement)) {
          setOverlayDisplay(false);
        }
      }, 500);

      return () => {
        clearTimeout(blurTimerRef.current);
      };
    }
  }

  return (
    <>
      {eventData ? (
        <div
          key={`${eventData?.meta?.eventReference}`}
          className={`transition-opacity--500ms ${
            !imageLoadConfirmation || !renderDelayComplete
              ? 'opacity-0'
              : 'opacity-1'
          }`}
        >
          <article
            className={`ec__wrapper no-select access-ob access-o6 ${
              tapCardScaleDown ? 'click-scaledown-98' : ''
            } `}
            tabIndex="0"
            id={`${eventData.meta.eventReference}`}
            onFocus={(e) => {
              e.stopPropagation();
              handleOverlayClick();
            }}
            onMouseLeave={() => setOverlayDisplay(false)}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Event Image */}
            <div
              className="ec__image-container access-ob"
              onClick={(e) => setOverlayDisplay(true)}
              tabIndex="0"
              ref={imageContainerRef}
              onBlur={handleBlur} //problem area for overlay UI
            >
              <div className="ec__click-overlay" />
              <LoadBucketImage
                imagePath={eventData.generic.eventImageDisplay?.imagePath}
                imgClassName="ec-display-image"
                backupPath={'event_img_default.png'}
                partialPath={'myndfull_event_images'}
                setImageLoadConfirmation={setImageLoadConfirmation}
              />
              {/* Event Overlay Actions */}
              {eventStatus === 'registeredInPerson' ||
              eventStatus === 'pendingApprovalInPerson' ||
              eventStatus === 'registeredVirtual' ||
              eventStatus === 'pendingApprovalVirtual' ||
              eventStatus === 'pendingApprovalInPerson-registeredVirtual' ||
              eventStatus === 'pendingApprovalVirtual-registeredInPerson' ? (
                <div
                  className={`actions-overlay  ${
                    overlayDisplay ? 'opacity-1 ' : 'opacity-0'
                  }`}
                >
                  <button
                    type="button"
                    className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
                      tapHighlightAnnouncements
                        ? 'highlight-bg-theme-light--tap click-scaledown-90'
                        : ''
                    }`}
                    style={
                      img4Loaded ? { display: 'flex' } : { display: 'none' }
                    }
                    onClick={() => handleActionOverlayClick('announcements')}
                    tabIndex={overlayDisplay ? '0' : '-1'}
                    aria-label={EventCard.clickToOpenAnnouncements}
                    id="alerts-btn"
                  >
                    <img
                      src={bell}
                      alt={EventCard.openAnnouncements}
                      title={EventCard.openAnnouncements}
                      onLoad={() => setImg4Loaded(true)}
                      style={{ padding: '8px' }}
                      className="filter-white"
                    />
                  </button>
                </div>
              ) : settingsMyEventsView !== 'archived' ||
                settingsEventSearchView !== 'myevents' ? (
                <div
                  className={`actions-overlay  ${
                    overlayDisplay ? 'opacity-1 ' : 'opacity-0'
                  }`}
                >
                  <button
                    type="button"
                    className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
                      tapHighlightSave
                        ? 'highlight-bg-theme-light--tap click-scaledown-90'
                        : ''
                    }`}
                    style={
                      img1Loaded ? { display: 'flex' } : { display: 'none' }
                    }
                    onClick={() => handleActionOverlayClick('save')}
                    tabIndex={overlayDisplay ? '0' : '-1'}
                    aria-pressed={eventStatus === 'saved'}
                    id="save-btn"
                  >
                    <img
                      src={star}
                      alt={EventCard.saveButton}
                      id="status-save"
                      title={
                        eventStatus === 'saved'
                          ? `${EventCard.clickToUnsave}`
                          : `${EventCard.clickToSave}`
                      }
                      onLoad={() => setImg1Loaded(true)}
                      style={{ padding: '8px' }}
                      className="filter-white"
                    />
                  </button>

                  <button
                    type="button"
                    className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
                      tapHighlightIgnore
                        ? 'highlight-bg-theme-light--tap click-scaledown-90'
                        : ''
                    }`}
                    style={
                      img2Loaded ? { display: 'flex' } : { display: 'none' }
                    }
                    tabIndex={overlayDisplay ? '0' : '-1'}
                    onClick={() => handleActionOverlayClick('ignore')}
                    aria-pressed={eventStatus === 'ignored'}
                    id="ignore-btn"
                  >
                    <img
                      src={view_ignore}
                      alt={EventCard.ignoreButton}
                      id="status-ignore"
                      title={
                        eventStatus === 'ignored'
                          ? `${EventCard.clickToUnignore}`
                          : `${EventCard.clickToIgnore}`
                      }
                      onLoad={() => setImg2Loaded(true)}
                      style={{ padding: '8px' }}
                      className="filter-white"
                    />
                  </button>
                </div>
              ) : (
                <div
                  className={`actions-overlay  ${
                    overlayDisplay ? 'opacity-1' : 'opacity-0'
                  }`}
                >
                  <button
                    type="button"
                    className={`actions-overlay__button highlight-bg-theme-theme-light access-o6 ${
                      tapHighlightRemoveArchived
                        ? 'highlight-bg-theme-light--tap highlight-click-scaledown-90'
                        : ''
                    }`}
                    style={
                      img3Loaded ? { display: 'flex' } : { display: 'none' }
                    }
                    onClick={() => handleActionOverlayClick('removeArchived')}
                    tabIndex={overlayDisplay ? '0' : '-1'}
                    id="removeArchived-btn"
                  >
                    <img
                      src={trash}
                      alt={EventCard.removeFromArchive}
                      id="removeArchived"
                      title={EventCard.removeFromArchive}
                      onLoad={() => setImg3Loaded(true)}
                      style={{ padding: '12px' }}
                      className="filter-white"
                    />
                  </button>
                </div>
              )}
            </div>

            <div
              className="ec__info-container min-full-width access-ob"
              tabIndex="0"
              onMouseDown={() => setTapCardScaleDown(true)}
              onMouseUp={() => setTapCardScaleDown(false)}
              onMouseLeave={() => setTapCardScaleDown(false)}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapCardScaleDown(true),
                    () => setTapCardScaleDown(false),
                  ],
                  [() => setViewEvent(eventData)],
                  true
                )
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleMobileTap(
                    [
                      () => setTapCardScaleDown(true),
                      () => setTapCardScaleDown(false),
                    ],
                    [() => setViewEvent(eventData)],
                    true
                  );
                }
              }}
            >
              {/* Event Title */}
              <div className="ec__header-section">
                <h2
                  className={`${
                    eventData.generic.title?.length <= 30
                      ? 'clamp-1'
                      : 'clamp-2'
                  }`}
                >
                  {eventData.generic.title.substring(0, 50)}
                  {eventData.generic.title?.length > 50 && '...'}
                </h2>
                <div className="dividing-line" />
              </div>
              <div className="flex-column min-full-width pad-lr14 fs16 fwsb fs14--phn mrg-t12">
                <div className="ec__row mrg-b12">
                  <img
                    src={calendar}
                    alt={EventCard.calendarIcon}
                    className="icon-container calendar"
                  />
                  <div className="ec__row-info ">
                    <p>
                      {getDateShortMonth(
                        eventData.dateTime.date,
                        accountContentLanguage,
                        true
                      )}
                    </p>
                    <span className="text-right">
                      {getDateTimeSummary(eventData.dateTime, accountTimeZone)}
                    </span>
                  </div>
                </div>

                <div className="ec__row mrg-b12">
                  {/*hostingOption icon */}
                  {eventData.hosting.hostingOption === 'inPerson' && (
                    <img
                      src={building2}
                      alt={EventCard.locationIcon}
                      className="icon-container pad-3"
                    />
                  )}
                  {eventData.hosting.hostingOption === 'online' && (
                    <img
                      src={globe2}
                      alt={EventCard.locationIcon}
                      className="icon-container filter-theme"
                    />
                  )}
                  {eventData.hosting.hostingOption === 'hybrid' && (
                    <img
                      src={hybrid2}
                      alt={EventCard.locationIcon}
                      className="icon-container"
                    />
                  )}
                  <div className="ec__row-info address-content">
                    <p>
                      <AddressContent
                        eventData={eventData}
                        accountRegistrationsInPerson={
                          accountRegistrationsInPerson
                        }
                        accountRegistrationsVirtual={
                          accountRegistrationsVirtual
                        }
                      />
                    </p>
                  </div>
                </div>

                <div className="ec__row mrg-b12">
                  {eventData?.organizers?.organizerData?.personal
                    ?.profileImage ? (
                    <LoadBucketImage
                      imagePath={
                        eventData?.organizers?.organizerData?.personal
                          ?.profileImage
                      }
                      imgClassName="tag-profile-img profile-img mrg-r12"
                      backupPath={'account_img_default.png'}
                      partialPath={'myndfull_account_images'}
                    />
                  ) : (
                    <img
                      src={organize}
                      alt={EventCard.organizerIcon}
                      className="icon-container filter-theme"
                    />
                  )}

                  <div className="ec__row-info">
                    <div className="flex-column">
                      <p>
                        {width > 480
                          ? organizerName.substring(0, 54)
                          : organizerName.substring(0, 32)}
                        {width <= 480 && organizerName?.length > 54 && '...'}
                        {width > 480 && organizerName?.length > 32 && '...'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ec__row-footer">
                  <div className="info-icons-row">
                    <div className="flex-row full-width">
                      {eventData.generic.wheelchairAccessibility && (
                        <img
                          className="mrg-r6"
                          alt={EventCard.wheelchairAccessibility}
                          title={EventCard.wheelchairAccessibility}
                          src={eventcard_accessibility}
                        />
                      )}
                      {isCommunityAccount && (
                        <img
                          className="mrg-r6 filter-green-light"
                          alt={EventCard.followedAccount}
                          title={EventCard.followedAccount}
                          src={eventcard_follow}
                        />
                      )}
                      {eventStatus === 'saved' && (
                        <img
                          className="mrg-r6"
                          alt={EventCard.savedEvent}
                          title={EventCard.savedEvent}
                          src={eventcard_star}
                        />
                      )}
                      {eventStatus === 'ignored' && (
                        <img
                          className="mrg-r6"
                          alt={EventCard.ignoredEvent}
                          title={EventCard.ignoredEvent}
                          src={eventcard_ignore}
                        />
                      )}
                      <EventStatusBar
                        eventData={eventData}
                        inPerson={
                          eventData?.hosting?.hostingOption === 'hybrid' ||
                          eventData?.hosting?.hostingOption === 'inPerson'
                        }
                        virtual={
                          eventData?.hosting?.hostingOption === 'hybrid' ||
                          eventData?.hosting?.hostingOption === 'online'
                        }
                        isParticipant={isParticipant}
                        isDraft={isDraft}
                        eventStatus={eventStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      ) : (
        <div className="ec__ghost" />
      )}
    </>
  );
};

export default EventCard;

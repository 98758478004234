import React, { useEffect, useState } from 'react';

//hooks
import { useGenericModal } from '../../../../context/GenericModalProvider';
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import IconModalButton from './IconModalButton';

//utils
import { return_curved_arrow } from '../../../../assets/icons';
import { getAgendaItemIcon } from '../../../../utils/images/agendaItems';

const IconSelectModal = ({
  closeModalFocusId,
  handleSelectIcon,
  itemIndex,
  currentSelection,
}) => {
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();
  const {
    IconSelectModal: IconSelectModalText,
    AgendaIconAlts,
    Generic,
    IconAlts,
  } = useLanguageComponents();

  const [tapHighlightClose, setTapHighlightClose] = useState(false);
  const [tapHighlightIconSelection, setTapHighlightIconSelection] = useState();

  useEffect(() => {
    const labelEl = document.getElementById(`iconSelectLabel`);
    if (labelEl) {
      labelEl.focus();
    }
    setCloseModalFocusId(closeModalFocusId);
  }, []);

  const handleSelection = (iconCode) => {
    handleMobileTap(
      [
        () => setTapHighlightIconSelection(iconCode),
        () => setTapHighlightIconSelection(),
      ],
      [() => handleSelectIcon(iconCode, itemIndex), () => handleCloseModal()]
    );
  };

  const iconNames = [
    'muffin',
    'hourglass',
    'drink',
    'speechBubble',
    'exchangeArrows',
    'penSigning',
    'clock',
    'microphone',
    'pieChart',
    'people',
    'lightBulb',
    'star',
    'playButton',
    'enterDoor',
    'tools',
    'questionMark',
    'beaker',
    'award',
  ];

  return (
    <div className="modal-basic__wrapper" id="iconSelectModal">
      <div className="heading">
        <div className="flex-column full-width">
          <div className="flex-row space-between">
            <div className="flex-row flex-center ">
              <label
                className="fs18 fwsb text-center access-ob access-o6"
                tabIndex="0"
                id="iconSelectLabel"
              >
                {IconSelectModalText.selectAgendaIcon}
              </label>
            </div>
            <button
              className={`close-btn highlight-i-lgt access-ob  ${
                tapHighlightClose ? 'highlight-i-lgt--tap' : ''
              }`}
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightClose(true),
                    () => setTapHighlightClose(false),
                  ],
                  [() => handleCloseModal()]
                )
              }
              type="button"
              title={Generic.close}
            >
              <img
                src={return_curved_arrow}
                alt={IconAlts.iconClose}
                className="svg"
                style={{ maxHeight: '48px' }}
              />
            </button>
          </div>
        </div>
      </div>

      <p className="flex-center fs21 mrg-b12"></p>
      <div className="flex-center mrg-auto-lr mrg-t12">
        <div className="select-icons-modal">
          {iconNames?.map((iconName) => (
            <IconModalButton
              key={iconName}
              iconName={iconName}
              iconSrc={getAgendaItemIcon(iconName)}
              altText={AgendaIconAlts[iconName]}
              currentSelection={currentSelection}
              tapHighlightIconSelection={tapHighlightIconSelection}
              handleSelection={handleSelection}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IconSelectModal;

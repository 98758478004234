export const DemoEventCardSkeleton = ({ index }) => {
  return (
    <div
      className="demo-ec-skeleton__container"
      key={`eventCardSkeleton-${index}`}
    >
      <div className="demo-ec__header " />
      <div className="demo-ec__image" />
      <div className="demo-ec__data" />
      <div className="demo-ec__icon" />
      <div className="demo-ec__icon" />
      <div className="demo-ec__icon" />
    </div>
  );
};

//used to fill in grid and prevent cards from auto centering
//-1 used to account for the search completed card.
export function DemoGhostCards(eventsLength, cards) {
  if (eventsLength !== 0 && eventsLength < cards) {
    let ghosts = cards - eventsLength;
    let ghostsArr = [];

    for (let i = 0; i <= ghosts - 1; i++) {
      ghostsArr.push(
        <div className="demo-ec__ghost" key={`eventGhostCard-${i}`} />
      );
    }

    return ghostsArr;
  }
}

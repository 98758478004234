import { useState } from 'react';

//Hooks
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';

//Components
import Spinner from '../../../../../components/Spinner/Spinner';
import { useRegistrationHandlers } from '../../useRegistrationHandlers';

//Utility

const PersonalCompletionPage = ({ email }) => {
  //Hooks
  const { handleMobileTap } = useSettings();
  const {
    resendVerifyAccountEmailToken,
    registrationPersonalEmail,
    registrationResendVerificationEmailIsLoading: isLoading,
  } = useRegistrationHandlers();

  //language
  const { PersonalAccountCompletionPage } = useLanguageRegistration();

  //UI components
  const [tapHighlightResendEmail, setTapHighlightResendEmail] = useState(false);

  return (
    <>
      <div className="flex-center flex-column">
        <div
          className="sign-up-header flex-center access-ob access-o6"
          tabIndex="0"
        >
          {PersonalAccountCompletionPage.heading}
        </div>
        <div className="h24" />
        <div className="fs18 fwsb flex-center access-ob access-o6" tabIndex="0">
          {PersonalAccountCompletionPage.part1}
        </div>
        <div className="h24" />
        <div className="fs18 fwsb flex-center access-ob access-o6" tabIndex="0">
          <p>
            {PersonalAccountCompletionPage.part2}&nbsp;
            <span className="underline">
              {registrationPersonalEmail || email}
            </span>
            .&nbsp;
            {PersonalAccountCompletionPage.part3}
          </p>
        </div>
      </div>
      <div className="h48" />

      <button
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightResendEmail(true),
              () => setTapHighlightResendEmail(false),
            ],
            [() => resendVerifyAccountEmailToken()]
          )
        }
        className={`btn-action highlight-tb-theme mrg-auto-left mrg-auto-right access-ob access-o6 ${
          tapHighlightResendEmail ? 'highlight-tb-theme--tap' : ''
        }`}
      >
        {isLoading ? (
          <Spinner maxHeight={'28px'} />
        ) : (
          `${PersonalAccountCompletionPage.resendEmail}`
        )}
      </button>
    </>
  );
};

export default PersonalCompletionPage;

//hooks

import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';

//language
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import AutocompletePlacesNoAddress from '../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';
import DistanceInput from '../../../../../components/DistanceInput/DistanceInput';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import MapContainerWithDistanceFilter from '../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import RadialButtonList from '../../../../../components/RadialButtonList/RadialButtonList';
import SelectionsModal from '../../../../../components/Selections/SelectionsModal';
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';
import LanguageNotListed from '../../../../../components/LanguageNotListed/LanguageNotListed';

//utils
import {
  checkmark_green,
  img_country,
  img_download,
  img_language,
  img_location,
  img_refresh_two_circular,
  star,
} from '../../../../../assets/icons';
import GetFlag from '../../../../../utils/images/GetFlag';

const QueryFilterDesktop = ({
  isLoaded,
  reset,
  FindSpeakersQueryFilters,
  queryIsLoading,
  //functions
  handleResetFilters,
  handleImportEventDraft,
  handleFindSpeakersQuery,
  handleAutocompleteCoordinates,
  handleViewContactedSpeakers,
}) => {
  const {
    handleSettingsSetFindSpeakersDistanceFilter,
    handleSettingsSetFindSpeakersDistanceMeasurement,
    handleSettingsSetFindSpeakersSearchBigTags,
    handleSettingsSetFindSpeakersSearchCoordinates,
    handleSettingsSetFindSpeakersSearchCountries,
    handleSettingsSetFindSpeakersSearchLocationType,
    handleSettingsSetFindSpeakersSearchSpeakerLanguages,
    handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed,
    handleSettingsSetFindSpeakersSearchTinyTags,
    settingsFindSpeakersDistanceFilter,
    settingsFindSpeakersDistanceMeasurement,
    settingsFindSpeakersSearchBigTags,
    settingsFindSpeakersSearchCoordinates,
    settingsFindSpeakersSearchCountries,
    settingsFindSpeakersSearchLocationType,
    settingsFindSpeakersSearchSpeakerLanguages,
    settingsFindSpeakersSearchSpeakerLanguagesNotListed,
    settingsFindSpeakersSearchTinyTags,
  } = useSettingsHandlers();

  //language
  const {
    LanguageSimpleOpts,
    CountryISOCodesSupported,
    BigTagsOptsWithSubText,
    BigTagsOpts,
    FindSpeakersLocationOpts,
    BigTagIcon,
    TinyTagIcon,
  } = useLanguageData();
  const { Generic, IconAlts } = useLanguageComponents();

  return (
    <>
      <div className="flex-row full-width">
        <div className="query-location">
          <div className="sub-label">
            <img
              src={img_location}
              alt={IconAlts.iconLocationPin}
              className="filter-theme mrg-r12"
            />
            <p>{FindSpeakersQueryFilters.searchByLocation}</p>
          </div>

          {settingsFindSpeakersSearchCoordinates[0] !== 0 &&
            settingsFindSpeakersSearchCoordinates[0] &&
            settingsFindSpeakersSearchLocationType === 'locationMap' && (
              <>
                {settingsFindSpeakersSearchCoordinates && (
                  <MapContainerWithDistanceFilter
                    loadTrigger={true}
                    loadValue={settingsFindSpeakersSearchCoordinates}
                    handler={handleSettingsSetFindSpeakersSearchCoordinates}
                    distanceFilter={settingsFindSpeakersDistanceFilter}
                    distanceMeasurement={
                      settingsFindSpeakersDistanceMeasurement
                    }
                  />
                )}
                <div className="h24" />
              </>
            )}
          {settingsFindSpeakersSearchLocationType === 'locationMap' && (
            <>
              <AutocompletePlacesNoAddress
                setAutocompleteCoordinates={handleAutocompleteCoordinates}
              />
              {isLoaded && (
                <>
                  <div className="h24" />
                  <DistanceInput
                    distanceFilter={settingsFindSpeakersDistanceFilter}
                    setDistanceFilter={
                      handleSettingsSetFindSpeakersDistanceFilter
                    }
                    distanceMeasurement={
                      settingsFindSpeakersDistanceMeasurement
                    }
                    setDistanceMeasurement={
                      handleSettingsSetFindSpeakersDistanceMeasurement
                    }
                  />
                  <div className="h24" />
                </>
              )}
            </>
          )}

          {settingsFindSpeakersSearchLocationType === 'locationCountry' && (
            <SelectionsModal
              addButtonText={FindSpeakersQueryFilters.addCountries}
              DataOpts={CountryISOCodesSupported}
              fullWidth={true}
              iconModal={img_country}
              iconModalStyle={{ height: '24px' }}
              iconModalClass={'filter-theme'}
              iconOff={true}
              limit={5}
              modalTitle={FindSpeakersQueryFilters.searchByCountry}
              selectionState={settingsFindSpeakersSearchCountries}
              setSelectionState={handleSettingsSetFindSpeakersSearchCountries}
              subTextOn={false}
              id={'locationCountrySelections'}
              customWrapper={'mrg-b24'}
              itemButtonCustomWrapper={'item-button-full'}
              listItemIconsFunction={GetFlag}
            />
          )}

          <RadialButtonList
            id={'locationSearch'}
            options={FindSpeakersLocationOpts}
            onChange={handleSettingsSetFindSpeakersSearchLocationType}
            reset={reset}
            loadValue={settingsFindSpeakersSearchLocationType}
            loadTrigger={true}
            customWrapper={'mrg-b24'}
          />

          <div className="required-label mrg-auto-top">
            <p>{Generic.required}</p>
            {settingsFindSpeakersSearchLocationType === 'locationMap' &&
              settingsFindSpeakersSearchCoordinates[0] &&
              settingsFindSpeakersSearchCoordinates[0] !== 0 && (
                <img src={checkmark_green} alt={IconAlts.iconCheckmark} />
              )}
            {settingsFindSpeakersSearchLocationType === 'locationCountry' &&
              settingsFindSpeakersSearchCountries?.length > 0 && (
                <img src={checkmark_green} alt={IconAlts.iconCheckmark} />
              )}
          </div>
        </div>

        <div className="divider-vertical opacity-05" />

        <div className="flex-column full-width">
          <div className="flex-row space-between full-width min-full-height">
            <div className="query-category">
              <div className="sub-label">
                <img
                  src={img_language}
                  alt={IconAlts.iconLanguage}
                  className="filter-theme mrg-r12"
                />
                <p>{FindSpeakersQueryFilters.languages}</p>
              </div>

              <SelectionsModal
                addButtonText={FindSpeakersQueryFilters.addLanguages}
                DataOpts={LanguageSimpleOpts}
                fullWidth={true}
                iconModal={img_language}
                iconModalClass={'filter-theme'}
                iconOff={true}
                limit={5}
                modalTitle={FindSpeakersQueryFilters.languages}
                selectionState={settingsFindSpeakersSearchSpeakerLanguages}
                setSelectionState={
                  handleSettingsSetFindSpeakersSearchSpeakerLanguages
                }
                subTextOn={false}
                id={'languageSelections'}
                customWrapper={'mrg-b24'}
                itemButtonCustomWrapper={'item-button-full'}
              />

              <LanguageNotListed
                languageNotListed={
                  settingsFindSpeakersSearchSpeakerLanguagesNotListed
                }
                setLanguagesNotListed={
                  handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed
                }
                languages={settingsFindSpeakersSearchSpeakerLanguages}
                setLanguages={
                  handleSettingsSetFindSpeakersSearchSpeakerLanguages
                }
                TextAreaOnBlurHandlerOff={true}
                customWrapper={'mrg-b24'}
              />

              <div className="required-label  mrg-auto-top">
                <p>1 {Generic.required}</p>
                {!settingsFindSpeakersSearchSpeakerLanguages?.includes(
                  'notListed'
                ) &&
                  settingsFindSpeakersSearchSpeakerLanguages?.length > 0 && (
                    <img src={checkmark_green} alt={IconAlts.iconCheckmark} />
                  )}
                {settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length >
                  0 &&
                  settingsFindSpeakersSearchSpeakerLanguagesNotListed.some(
                    (nL) => nL.language && nL.language?.length > 0
                  ) && (
                    <img src={checkmark_green} alt={IconAlts.iconCheckmark} />
                  )}
              </div>
            </div>
            <div className="divider-vertical opacity-05" />

            <div className="flex-column full-width">
              <div className="flex-row full-width full-height">
                <div className="query-category query-category--alt-width">
                  <div className="sub-label">
                    <img
                      src={BigTagIcon}
                      alt={IconAlts.iconBigTag}
                      className="mrg-r12"
                      style={{ padding: '2px' }}
                    />
                    <p>{FindSpeakersQueryFilters.bigTags}</p>
                  </div>
                  <SelectionsModal
                    addButtonText={FindSpeakersQueryFilters.addBigTags}
                    DataOpts={BigTagsOptsWithSubText}
                    DataOptsSubTextTranslations={BigTagsOpts}
                    fullWidth={true}
                    iconModal={BigTagIcon}
                    iconOff={true}
                    limit={10}
                    modalTitle={FindSpeakersQueryFilters.bigTags}
                    selectionState={settingsFindSpeakersSearchBigTags}
                    setSelectionState={
                      handleSettingsSetFindSpeakersSearchBigTags
                    }
                    subTextOn={true}
                    id={'bigTagSelections'}
                    customWrapper={'mrg-b24'}
                    itemButtonCustomWrapper={'item-button-full'}
                    iconModalStyle={{ maxHeight: '28px' }}
                  />
                </div>

                <div className="divider-vertical opacity-05" />
                <div className="query-category query-category--alt-width">
                  <div className="sub-label">
                    <img
                      src={TinyTagIcon}
                      alt={IconAlts.iconTinyTag}
                      className="mrg-r12"
                    />
                    <p>{FindSpeakersQueryFilters.tinyTags}</p>
                  </div>
                  <CustomTagsList
                    limit={100}
                    id={'tinyTags'}
                    tinyTags={settingsFindSpeakersSearchTinyTags}
                    setTinyTags={handleSettingsSetFindSpeakersSearchTinyTags}
                    twoTagRows={true}
                    bigTags={settingsFindSpeakersSearchBigTags}
                    tagGroupsOff={true}
                    iconOff={true}
                  />
                </div>
              </div>

              <div className="required-label mrg-auto-top pad-t12">
                <p>1 {Generic.required}</p>
                {(settingsFindSpeakersSearchBigTags?.length > 0 ||
                  settingsFindSpeakersSearchTinyTags?.length > 0) && (
                  <img src={checkmark_green} alt={IconAlts.iconCheckmark} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="divider mrg-tb24 opacity-05" />

      <div className="flex-column full-width flex-center">
        <div className="flex-row flex-center full-width mrg-t12">
          <ButtonTextSimple
            text={FindSpeakersQueryFilters.resetFilters}
            icon={img_refresh_two_circular}
            customWrapper={'mrg-r24 mrg-b24 min-44 highlight-t-theme text-gray'}
            handler={handleResetFilters}
            iconOn={true}
            iconClass={'admin-btn-img mrg-r12 filter-gray'}
            hideBorderOn={true}
          />
          <ButtonTextSimple
            text={FindSpeakersQueryFilters.importEventData}
            icon={img_download}
            customWrapper={'mrg-r24 mrg-b24 min-44 highlight-t-theme text-gray'}
            handler={handleImportEventDraft}
            iconOn={true}
            iconClass={'admin-btn-img mrg-r12 filter-gray'}
            hideBorderOn={true}
            id={'findspeakers-importEventData'}
          />

          <ButtonTextSimple
            text={FindSpeakersQueryFilters.viewContacts}
            icon={star}
            customWrapper={'mrg-r24 mrg-b24 min-44 highlight-t-theme text-gray'}
            handler={handleViewContactedSpeakers}
            iconOn={true}
            iconClass={'admin-btn-img mrg-r12 filter-gray'}
            hideBorderOn={true}
            id={'viewContactsButton'}
          />
        </div>

        <div className="flex-column fit-content">
          <ItemButton
            handler={handleFindSpeakersQuery}
            text={FindSpeakersQueryFilters.search}
            customWrapper={'mrg-t12 item-button-standard'}
            isLoading={queryIsLoading}
          />
        </div>
      </div>
    </>
  );
};

export default QueryFilterDesktop;

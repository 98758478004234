async function handleCalculateTaxesFrontEndVersion(
  taxCalculationAddress,
  roomCodes
) {
  const addressData = taxCalculationAddress;
  const numberOfRoomCodes = roomCodes?.length;
  const roomCodePrice = process.env.REACT_APP_TRANSLATION_ROOM_PRICE_IN_CAD;

  // console.log('roomCodes', roomCodes);
  // console.log('addressData', addressData);

  // This object needs the country and province codes from addressData to match in order for the code to work - e.g. Canada must come out as CA and Nunavut as NU etc. //
  const salesTaxes = {
    CA: {
      name: 'Canada',
      AB: {
        name: 'Alberta',
        taxData: {
          salesTaxes: [{ taxTitle: 'GST', taxRate: '5%' }],
        },
      },
      BC: {
        name: 'British Columbia',
        taxData: {
          salesTaxes: [
            { taxTitle: 'GST', taxRate: '5%' },
            { taxTitle: 'PST', taxRate: '7%' },
          ],
        },
      },
      MB: {
        name: 'Manitoba',
        taxData: {
          salesTaxes: [{ taxTitle: 'GST', taxRate: '5%' }],
        },
      },
      NB: {
        name: 'New Brunswick',
        taxData: {
          salesTaxes: [{ taxTitle: 'HST', taxRate: '15%' }],
        },
      },
      NL: {
        name: 'Newfoundland and Labrador',
        taxData: {
          salesTaxes: [{ taxTitle: 'HST', taxRate: '15%' }],
        },
      },
      NT: {
        name: 'Northwest Territories',
        taxData: {
          salesTaxes: [{ taxTitle: 'GST', taxRate: '5%' }],
        },
      },
      NS: {
        name: 'Nova Scotia',
        taxData: {
          salesTaxes: [{ taxTitle: 'HST', taxRate: '15%' }],
        },
      },
      NU: {
        name: 'Nunavut',
        taxData: {
          salesTaxes: [{ taxTitle: 'GST', taxRate: '5%' }],
        },
      },
      ON: {
        name: 'Ontario',
        taxData: {
          salesTaxes: [{ taxTitle: 'HST', taxRate: '13%' }],
        },
      },
      PE: {
        name: 'Prince Edward Island',
        taxData: {
          salesTaxes: [{ taxTitle: 'HST', taxRate: '15%' }],
        },
      },
      QC: {
        name: 'Quebec',
        taxData: {
          salesTaxes: [
            { taxTitle: 'GST', taxRate: '5%' },
            { taxTitle: 'QST', taxRate: '9.975%' },
          ],
        },
      },
      SK: {
        name: 'Saskatchewan',
        taxData: {
          salesTaxes: [
            { taxTitle: 'GST', taxRate: '5%' },
            { taxTitle: 'PST', taxRate: '6%' },
          ],
        },
      },
      YT: {
        name: 'Yukon',
        taxData: {
          salesTaxes: [{ taxTitle: 'GST', taxRate: '5%' }],
        },
      },
    },
  };

  let newSubtotalCost = numberOfRoomCodes * roomCodePrice;
  let newTaxData = [];
  let newTaxCosts = [];
  let newTotalCost = null;

  //// Shopping Cart Calculations ////
  // Cart Subtotal Calculations
  // Cart Applicable Taxes
  function calcTaxData(addressData) {
    const addressCountry = addressData?.address?.country;
    const addressState = addressData?.address?.state;

    if (
      salesTaxes[addressCountry] &&
      salesTaxes[addressCountry][addressState]
    ) {
      const taxData =
        salesTaxes[addressCountry][addressState].taxData.salesTaxes;
      return taxData?.map((item) => ({
        taxTitle: item.taxTitle,
        taxRate: item.taxRate,
      }));
    }
    return [];
  }

  newTaxData = calcTaxData(addressData);

  // Cart Tax Cost Caclulations
  function calcTaxCosts(newSubtotalCost, newTaxData) {
    return newTaxData?.map((tax) => {
      const taxCost = newSubtotalCost * (parseFloat(tax.taxRate) / 100);
      const roundedTaxCost = Math.round(taxCost);

      return {
        taxTitle: tax.taxTitle,
        taxCost: parseFloat(roundedTaxCost),
      };
    });
  }
  newTaxCosts = calcTaxCosts(newSubtotalCost, newTaxData);

  // Cart Total Cost Calculations
  function calcTotalCost(newSubtotalCost, newTaxCosts) {
    const newTaxCostsSum = newTaxCosts.reduce((accumulator, currentTax) => {
      return accumulator + currentTax.taxCost;
    }, 0);
    const totalCost = newSubtotalCost + newTaxCostsSum;
    return parseFloat(totalCost);
  }

  newTotalCost = calcTotalCost(newSubtotalCost, newTaxCosts);
  newTotalCost = Math.round(newTotalCost);

  let taxObj = {
    newSubtotalCost,
    newTaxData,
    newTaxCosts,
    newTotalCost,
  };

  return taxObj;
}

export default handleCalculateTaxesFrontEndVersion;

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Utility
import clockConversion from '../../../../utils/dateTime/clockConversion';
import { getAgendaItemIcon } from '../../../../utils/images/agendaItems';

const AgendaContent = ({ agendaData, EventPage }) => {
  //language
  const { AgendaIconAlts } = useLanguageComponents();

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="agendaLabel"
      >
        {EventPage.agenda}
      </label>
      <div className="agenda__wrapper">
        {agendaData.items.map((item) => {
          return (
            <div
              className="agenda__container flex-row access-ob access-o6"
              tabIndex="0"
              key={item.id}
            >
              <div className="agenda-bar" />
              <div className="agenda-item">
                <div className="flex-column">
                  <div className="flex-row align-center">
                    {item.icon && (
                      <img
                        className="filter-gray mrg-r12 "
                        src={getAgendaItemIcon(item.icon)}
                        alt={AgendaIconAlts[item.icon]}
                        style={{ height: '18px' }}
                      />
                    )}
                    <p className="fs18">{item.text}</p>
                  </div>

                  {agendaData.format === 'minutes' ? (
                    <p className="fs16 mrg-t6">
                      {item.value} {EventPage.minutes}
                    </p>
                  ) : (
                    <p className="fs16 mrg-t6 flex-row">
                      {clockConversion(item.startTime)}
                      &nbsp;&#8211;&nbsp; {clockConversion(item.endTime)}
                    </p>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default AgendaContent;

import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider';
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';

//api
import { useLazyBigTagEventsQuery } from '../../eventsApiSlice';

//components
import EventsGrid from '../EventsGrid';
import EventPage from '../../EventPage/EventPage';

const BigTagsGrid = () => {
  const { accountId } = useAccountReduxHandlers();
  const { cards, settingsViewEventDetails } = useSettings();
  const {
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    //bT
    settingsSelectedBigTags: searchBTs,
    handleSettingsSetBTInPersonQueryPage,
    handleSettingsSetBTInPersonQueryHasMore,
    settingsBTInPersonQueryPage: bTInPersonQueryPage,
    settingsBTInPersonQueryHasMore: bTInPersonQueryHasMore,
    handleSettingsSetBTOnlineQueryPage,
    handleSettingsSetBTOnlineQueryHasMore,
    settingsBTOnlineQueryPage: bTOnlineQueryPage,
    settingsBTOnlineQueryHasMore: bTOnlineQueryHasMore,

    //utils
    handleSelectAllTagsIfLoadedEmpty,
  } = useSettingsHandlers();

  //state
  const [filteredBTInPersonEvents, setFilteredBTInPersonEvents] = useState([]);
  const [filteredBTOnlineEvents, setFilteredBTOnlineEvents] = useState([]);
  const [BTCardsIsFetchingToFillRow, setBTCardsIsFetchingToFillRow] =
    useState(false);

  //api
  const [
    getBigTagEvents,
    {
      data: bTData,
      isFetching: bTisFetching,
      isLoading: bTisLoading,
      isUninitialized: bTisUninitialized,
    },
  ] = useLazyBigTagEventsQuery();

  //ui
  const [focusId, setFocusId] = useState();
  const [returnFromEventPageComplete, setReturnFromEventPageComplete] =
    useState(true);

  //variables
  let filteredEvents = [];
  let seenBTInPersonIds = new Set();
  let seenBTOnlineIds = new Set();

  const fetchDelayTimer = useRef();

  //initialize
  useEffect(() => {
    handleSelectAllTagsIfLoadedEmpty();
    /*eslint-disable-next-line*/
  }, []); //selects all tags if loaded empty

  useEffect(() => {
    if (settingsViewEventDetails) {
      setFocusId(settingsViewEventDetails?.meta?.eventReference);
    }
  }, [settingsViewEventDetails]); //sets card focus + works with eventsGrid

  useEffect(() => {
    if (accountId && bTisUninitialized && searchBTs) {
      if (
        accountId &&
        searchBTs?.length > 0 &&
        searchCategory &&
        searchDirection &&
        searchFormat &&
        cards
      ) {
        //put ref variable check here?
        handleSettingsSetBTInPersonQueryPage(0);
        handleSettingsSetBTInPersonQueryHasMore(true);
        handleSettingsSetBTOnlineQueryPage(0);
        handleSettingsSetBTOnlineQueryHasMore(true);

        getBigTagEvents({
          accountId,
          searchBTs,
          searchCategory,
          searchDirection,
          searchFormat,
          queryCardsPerPage: cards,
          bTQueryPage: 0,
        });
      }
    }
    /*eslint-disable-next-line*/
  }, [
    view,
    cards,
    accountId,
    searchBTs,
    searchDirection,
    searchFormat,
    bTisUninitialized,
  ]); //initial search

  //functions
  useEffect(() => {
    if (view === 'bigtags') {
      if (!bTisFetching && !bTisLoading) {
        if (searchFormat === 'inPerson') {
          getNewBTInPersonEventsList();
        } else if (searchFormat === 'online') {
          getNewBTOnlineEventsList();
        }
      }
    }
  }, [searchCategory, searchDirection, searchFormat, searchBTs]);

  useEffect(() => {
    if (bTData?.data) {
      if (searchFormat === 'inPerson') {
        bTData.data.forEach((item) => {
          if (!seenBTInPersonIds.has(item._id)) {
            filteredEvents.push(item);
            seenBTInPersonIds.add(item._id);
          }
        });

        setFilteredBTInPersonEvents(filteredEvents);
        filteredEvents = [];
      } else if (searchFormat === 'online') {
        bTData.data.forEach((item) => {
          if (!seenBTOnlineIds.has(item._id)) {
            filteredEvents.push(item);
            seenBTOnlineIds.add(item._id);
          }
        });

        setFilteredBTOnlineEvents(filteredEvents);
        filteredEvents = [];
      }
    }
    return () => {
      seenBTInPersonIds.clear();
      seenBTOnlineIds.clear();
    };
  }, [bTData]); //removal of duplicate events

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (
      filteredBTInPersonEvents?.length % cards !== 0 &&
      bTInPersonQueryHasMore &&
      !BTCardsIsFetchingToFillRow
    ) {
      getNextBTInPersonEventsPage();
    } else {
      fetchDelayTimer.current = setTimeout(() => {
        setBTCardsIsFetchingToFillRow(false);
      }, 500); // need time out as precaution against rapid queries
    }
  }, [filteredBTInPersonEvents]);

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (
      filteredBTOnlineEvents?.length % cards !== 0 &&
      bTOnlineQueryHasMore &&
      !BTCardsIsFetchingToFillRow
    ) {
      getNextBTOnlineEventsPage();
    } else {
      fetchDelayTimer.current = setTimeout(() => {
        setBTCardsIsFetchingToFillRow(false);
      }, 500); // need time out as precaution against rapid queries
    }
  }, [filteredBTOnlineEvents]);

  function getNextBTInPersonEventsPage() {
    if (!bTisFetching && !bTisLoading && accountId && searchBTs?.length > 0) {
      setBTCardsIsFetchingToFillRow(true);
      const newPage = bTInPersonQueryPage + 1;
      handleSettingsSetBTInPersonQueryPage(newPage);
      getBigTagEvents({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat: 'inPerson',
        queryCardsPerPage: cards,
        bTQueryPage: newPage,
      });
    }
  }

  function getNextBTOnlineEventsPage() {
    if (!bTisFetching && !bTisLoading && accountId && searchBTs?.length > 0) {
      setBTCardsIsFetchingToFillRow(true);
      const newPage = bTOnlineQueryPage + 1;
      handleSettingsSetBTOnlineQueryPage(newPage);
      getBigTagEvents({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat: 'online',
        queryCardsPerPage: cards,
        bTQueryPage: newPage,
      });
    }
  }

  function getNewBTInPersonEventsList() {
    if (!bTisFetching && !bTisLoading && accountId) {
      setBTCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetBTInPersonQueryPage(newPage);
      getBigTagEvents({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat: 'inPerson',
        queryCardsPerPage: cards,
        bTQueryPage: newPage,
      });
    }
  }

  function getNewBTOnlineEventsList() {
    if (!bTisFetching && !bTisLoading && accountId) {
      setBTCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetBTOnlineQueryPage(newPage);
      getBigTagEvents({
        accountId,
        searchBTs,
        searchCategory,
        searchDirection,
        searchFormat: 'online',
        queryCardsPerPage: cards,
        bTQueryPage: newPage,
      });
    }
  }

  async function updateEventDataListPostRegistration(newEventData) {
    setFilteredBTInPersonEvents(
      filteredBTInPersonEvents?.map((ev) =>
        ev._id === newEventData._id ? newEventData : ev
      )
    );
    setFilteredBTOnlineEvents(
      filteredBTOnlineEvents?.map((ev) =>
        ev._id === newEventData._id ? newEventData : ev
      )
    );
  }

  return (
    <>
      {settingsViewEventDetails ? (
        <EventPage
          updateEventDataListPostRegistration={
            updateEventDataListPostRegistration
          }
        />
      ) : (
        <EventsGrid
          cards={cards}
          events={
            searchFormat === 'inPerson'
              ? filteredBTInPersonEvents
              : filteredBTOnlineEvents
          }
          isLoading={BTCardsIsFetchingToFillRow}
          isUninitialized={bTisUninitialized}
          nextPageFunction={
            searchFormat === 'inPerson'
              ? getNextBTInPersonEventsPage
              : getNextBTOnlineEventsPage
          }
          hasMore={
            searchFormat === 'inPerson'
              ? bTInPersonQueryHasMore
              : bTOnlineQueryHasMore
          }
          tagsCheck={searchBTs?.length}
          eventsGridType={'bigtags'}
          key={`eventsGrid-bigtags-${searchFormat}`}
          focusId={focusId}
          setFocusId={setFocusId}
          returnFromEventPageComplete={returnFromEventPageComplete}
          setReturnFromEventPageComplete={setReturnFromEventPageComplete}
        />
      )}
    </>
  );
};

export default BigTagsGrid;

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  selectCurrentRoles,
  selectCurrentUser,
  selectCurrentToken,
  selectAuthIsLoggingOut,
  selectAuthIsLoggingIn,
  setCredentials,
  setAuthIsLoggingIn,
  setAuthIsLoggingOut,
} from './authSlice';

//Hooks
import { useApiStatus } from '../../../context/ApiStatusProvider';
import { useLazyMyAccountQuery } from '../account/accountApiSlice';
import { useCreateEventReduxHandlers } from '../organize/create/useCreateEventHandlers';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useLazyLogoutQuery, useLoginMutation } from './authApiSlice';
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useSettings } from '../../../context/SettingsProvider';
import { axiosLimited } from '../../../axios/axios';
import { useAccountPersonalSocket } from '../../../context/sockets/main/AccountPersonalProvider';

//Components

//Utility
export const useAuthReduxHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dismissKeypad } = useSettings();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleError, handleWarning, handleSuccess } = useApiStatus();
  const { handleCreateEventReset, createEventIdVerification } =
    useCreateEventReduxHandlers();
  const { handleSettingsResetRedux, settingsAccountIdVerification } =
    useSettingsHandlers();
  const {
    connectAccountPersonalSocket,
    closeAccountPersonalSocket,
    accountPersonalSocketStatus,
  } = useAccountPersonalSocket();

  //API
  const [myAccount] = useLazyMyAccountQuery();
  const [logout] = useLazyLogoutQuery();

  //Selectors
  const authCurrentRoles = useSelector(selectCurrentRoles);
  const authCurrentUser = useSelector(selectCurrentUser);
  const authCurrentToken = useSelector(selectCurrentToken);
  const authIsLoggingOut = useSelector(selectAuthIsLoggingOut);
  const authIsLoggingIn = useSelector(selectAuthIsLoggingIn);

  //API
  const [login] = useLoginMutation();

  //Component functions
  function handleAuthSetCredentials(obj) {
    dispatch(setCredentials(obj));
  }

  function handleAuthIsLoggingOut(boolean) {
    dispatch(setAuthIsLoggingOut(boolean));
  }

  function handleAuthIsLoggingIn(boolean) {
    dispatch(setAuthIsLoggingIn(boolean));
  }

  //API functions
  const handleGetMyAccount = async (args) => {
    let navRoute = args?.navigateRoute;
    let ignoreAuthDisplay = args?.ignoreAuthLoadingDisplay;
    let loginAccountId = args?.loginAccountId;

    !ignoreAuthDisplay && handleAuthIsLoggingIn(true);

    try {
      const res = await myAccount();
      const accountId = res.data.myAccount._id;

      if (accountId?.toString() !== loginAccountId?.toString()) {
        handleAuthIsLoggingIn(false);
        handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'Login.js/handleLogin',
        });
        return await handleUserLogout();
      }

      if (
        accountId !== settingsAccountIdVerification ||
        (createEventIdVerification && accountId !== createEventIdVerification)
      ) {
        handleCreateEventReset(accountId);
        handleSettingsResetRedux(accountId);
      }

      !ignoreAuthDisplay && handleAuthIsLoggingIn(false);

      if (accountPersonalSocketStatus === 'closed') {
        connectAccountPersonalSocket();
      }

      if (navRoute) {
        return navigate(navRoute);
      }
    } catch (error) {
      const newError = {
        error,
        id: Date.now(),
        origin: 'Login.js/getMyAccount',
      };
      if (process.env.REACT_APP_ENV === 'development') {
        console.log(newError);
      }
      handleAuthIsLoggingIn(false);
      await handleUserLogout();
    }
  };

  async function handleLogin(email, pwd) {
    const recaptchaVerified = await handleReCaptchaVerify('LOGIN', false);

    if (recaptchaVerified !== true) return false;

    const res = await login({ email, pwd });

    if (
      !res.data?.accessToken &&
      res.data?.message === 'accountRequiresEmailVerification'
    ) {
      return handleWarning({
        message: 'accountRequiresEmailVerification',
        id: Date.now(),
        origin: 'Login.js/handleLogin',
      });
    }
    if (res?.data?.accountId) {
      return res.data.accountId;
    } else {
      return null;
    }
  }

  async function handleForgotPasswordRequest(
    validAccountEmail,
    isLoading,
    setIsLoading,
    restrictSend,
    setPage,
    resendEmailLimit,
    setResendEmailLimit
  ) {
    if (!isLoading) {
      if (restrictSend) {
        const newError = {
          message: 'pleaseWaitOneMinute',
          id: Date.now(),
          origin: 'ForgotPassword.js/handleForgotPasswordRequest',
        };

        handleError(newError);
      } else if (resendEmailLimit >= 9) {
        const newError = {
          message: 'pleaseContactSupport',
          id: Date.now(),
          origin: 'ForgotPassword.js/handleForgotPasswordRequest',
        };

        handleError(newError);
        //TODO: should just navigate to support once page exists
      } else {
        setIsLoading(true);
        try {
          const res = await axiosLimited.post('/api/main/auth/forgotPassword', {
            email: validAccountEmail,
          });

          if (res.status === 200) {
            handleSuccess({
              message: 'emailSent',
            });
            setPage(2);
            let newLimit = resendEmailLimit + 1;
            setResendEmailLimit(newLimit);
          }
        } catch (error) {
          const newError = {
            error,
            id: Date.now(),
            origin: 'ForgotPassword.js/handleForgotPasswordRequest',
          };
          handleError(newError);
        }

        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 500);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }

  async function handleResetTokenValidation(resettoken, setPage) {
    try {
      const { data } = await axiosLimited.get(
        `/api/main/auth/resetTokenValidation/${resettoken}`
      );
      if (data.resetTokenExists) {
        setPage(2);
      } else {
        setPage(1);
      }
    } catch (error) {
      const newError = {
        error,
        id: Date.now(),
        origin: 'ResetPassword.js/resetTokenValidation',
      };

      handleError(newError);
    }
  } //check to see if /:token is valid before rendering page

  async function handleSaveAccountPasswordChange(
    resettoken,
    newPassword,
    passwordConfirm,
    passwordValid,
    passwordsMatch,
    isLoading,
    setIsLoading,
    setResEmail,
    setPage,
    setWarningHighlight
  ) {
    const recaptchaVerified = await handleReCaptchaVerify(
      'RESET_PASSWORD',
      false
    );

    if (recaptchaVerified !== true) return false;

    if (!isLoading) {
      if (passwordValid && passwordsMatch) {
        setIsLoading(true);

        try {
          const response = await axiosLimited.patch(
            `/api/main/auth/resetPassword/${resettoken}`,
            {
              password: newPassword,
              passwordConfirm,
            }
          );
          if (response?.data.status === 'success') {
            handleSuccess({
              message: 'success',
              id: Date.now(),
              origin: 'ResetPassword.js/saveAccountPasswordChange',
            });
            setResEmail(response.data.email);
            setPage(3);
          }
        } catch (error) {
          handleError({
            message: error?.response?.data?.message || 'error',
            id: Date.now(),
            origin: 'ResetPassword.js/saveAccountPasswordChange',
          });
        }

        setIsLoading(false);
      } else {
        setWarningHighlight(true);
      }
    }
  }

  async function handleUserLogout() {
    logout();
    closeAccountPersonalSocket();
  }

  return {
    authCurrentRoles,
    authCurrentToken,
    authCurrentUser,
    authIsLoggingIn,
    authIsLoggingOut,
    handleAuthIsLoggingIn,
    handleAuthIsLoggingOut,
    handleAuthSetCredentials,
    handleGetMyAccount,
    handleLogin,
    handleForgotPasswordRequest,
    handleSaveAccountPasswordChange,
    handleResetTokenValidation,
    handleUserLogout,
  };
};

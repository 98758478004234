//components
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';
import EmailInput from '../../../../components/TextArea/EmailInput';
import PhoneInput from '../../../../components/TextArea/PhoneInput';
import FocusSkip from '../../../../components/FocusSkip/FocusSkip';

const Contact = ({
  initialize,
  skipToId,
  skipToLabel,
  CreateEvent,
  //state
  loadContactProvideContact,
  loadContactEmail,
  loadContactPhoneNumber,
  loadContactCountryCode,
  //handlers
  handleEventSetProvideContact,
  handleEventSetContactEmail,
  handleEventSetContactPhoneNumber,
  handleEventSetContactCountryCode,
}) => {
  return (
    <fieldset className="fieldset" id="fieldset-contact">
      <legend id="aria-fieldset-instructions-contact">
        {CreateEvent.contact.ariaFieldsetInstructions}
      </legend>
      <div className="h0-ph24" />
      <label
        className="label"
        id="contactLabel"
        aria-describedby="aria-fieldset-instructions-contact"
        tabIndex="0"
      >
        {CreateEvent.contact.contact}
      </label>
      <FocusSkip
        skipToLabel={skipToLabel}
        skipToId={skipToId}
        topPos={'-24px'}
      />

      <ButtonSwitch
        horizontal={true}
        id={'provideContact'}
        title={CreateEvent.contact.provideContact}
        handleSwitch={handleEventSetProvideContact}
        loadTrigger={initialize}
        loadValue={loadContactProvideContact}
      />

      {loadContactProvideContact && (
        <>
          <div className="h24" />
          <EmailInput
            handleInput={handleEventSetContactEmail}
            id={'contactEmail'}
            loadValue={loadContactEmail}
            componentAccountValidationOff={true}
            checkRanAfterBlurToggle={true}
            initialize={initialize}
          />
          <div className="h24" />
          <PhoneInput
            id={'contactPhoneInput'}
            setPhoneNumber={handleEventSetContactPhoneNumber}
            phoneNumber={loadContactPhoneNumber}
            countryCode={loadContactCountryCode}
            setCountryCode={handleEventSetContactCountryCode}
          />
        </>
      )}
    </fieldset>
  );
};

export default Contact;

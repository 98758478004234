import { useEffect, useRef, useState } from 'react';

//utils
import {
  image_demo_mobile,
  image_demo_desktop,
} from '../../assets/images/explore';

const ExploreHeading = ({
  ExploreMyndFull,
  setExploreHeadingIsInitialized,
}) => {
  //state
  const [backgroundHeight, setBackgroundHeight] = useState(780);
  const [img1Loaded, setImg1Loaded] = useState(false);
  const [img2Loaded, setImg2Loaded] = useState(false);

  //variables
  const headingRef = useRef(null);
  const signUpRef = useRef(null);
  const imgsRef = useRef(null);

  //initialize
  useEffect(() => {
    if (img1Loaded && img2Loaded) {
      const raf1 = requestAnimationFrame(() => {
        const raf2 = requestAnimationFrame(() => {
          adjustHeight();
          setExploreHeadingIsInitialized(true);
        });
      });

      return () => {
        cancelAnimationFrame(raf1);
      };
    }
  }, [img1Loaded, img2Loaded]);

  // adjust height if layout changes due to font loading / window resizing / image quirks
  useEffect(() => {
    if (!headingRef.current || !signUpRef.current || !imgsRef.current) return;
    const observer = new ResizeObserver(() => {
      adjustHeight();
    });
    observer.observe(headingRef.current);
    observer.observe(signUpRef.current);
    observer.observe(imgsRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  //UI

  const adjustHeight = () => {
    const heading = headingRef.current;
    const signUp = signUpRef.current;
    const imgs = imgsRef.current;

    if (heading && signUp && imgs) {
      const headingHeight = heading.clientHeight;
      const signUpHeight = signUp.clientHeight;
      const imgsHeight = imgs.clientHeight;

      const combinedHeight = headingHeight + signUpHeight + imgsHeight;

      const calculatedHeight = combinedHeight * 0.9;

      setBackgroundHeight(calculatedHeight);
    }
  };

  return (
    <div
      className="explore-heading bg-radial-light-theme"
      style={{ minHeight: `${backgroundHeight}px` }}
    >
      <h1
        className="color-darkgray pad-t48 "
        id="exploreHeading"
        ref={headingRef}
      >
        <span className="br--standard access-ob access-o6" tabIndex="0">
          {ExploreMyndFull.exploreHeading1} <br />
          {ExploreMyndFull.exploreHeading2}
        </span>
      </h1>
      <div className="explore-signUp" id="exploreSignUp" ref={signUpRef}>
        <button className="explore-button access-ob access-o6" tabIndex="0">
          {ExploreMyndFull.signUp}
        </button>
      </div>

      <div
        className="explore-heading-img__wrapper access-ob access-o6"
        id="exploreImgs"
        tabIndex="0"
        aria-label={
          'An image of MyndFull on a mobile device and desktop device displaying the find events view.'
        }
        ref={imgsRef}
      >
        <img
          src={image_demo_mobile}
          alt={ExploreMyndFull.demoImgMobile}
          className="explore-header-img--mobile"
          onLoad={() => setImg1Loaded(true)}
        />
        <img
          src={image_demo_desktop}
          alt={ExploreMyndFull.demoImgDesktop}
          className="explore-header-img--desktop"
          onLoad={() => setImg2Loaded(true)}
        />
      </div>
    </div>
  );
};

export default ExploreHeading;

import { useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';

//components
import EventPageParticipantButton from './EventPageParticipantButton';
import GeneralActionButtons from './GeneralActionButtons';
import RegistrationButtons from './RegistrationButtons';
import Spinner from '../../../../../components/Spinner/Spinner';

const ActionButtons = ({
  eventData,
  setEventData,
  eventStatus,
  handleMobileTap,
  EventPage,
  handleReturn,
  setEventStatus,
  organizerViewOn,
  participantPreviewOn,
  registrationActionBtnsDisplayOnly,
  isParticipant,
  setIsParticipant,
  updateEventDataListPostRegistration,
}) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();

  //state
  const [actionBtnsIsLoading, setActionBtnsIsLoading] = useState(false);

  return (
    <div
      className={`ev__action-btns ${
        !actionBtnsIsLoading ? 'ev__action-btns--bg' : ''
      }`}
      id="actionBtns"
    >
      <div
        className={`flex-row full-width ${
          !registrationActionBtnsDisplayOnly ? 'flex-column' : ''
        } ${actionBtnsIsLoading ? 'remove' : ''}`}
      >
        {(eventStatus === 'neutral' ||
          eventStatus === 'saved' ||
          eventStatus === 'ignored') && (
          <>
            {!isParticipant && !registrationActionBtnsDisplayOnly && (
              <GeneralActionButtons
                accountId={accountId}
                eventData={eventData}
                EventPage={EventPage}
                eventStatus={eventStatus}
                handleMobileTap={handleMobileTap}
                handleReturn={handleReturn}
                organizerViewOn={organizerViewOn}
                participantPreviewOn={participantPreviewOn}
                registrationActionBtnsDisplayOnly={
                  registrationActionBtnsDisplayOnly
                }
                setEventStatus={setEventStatus}
              />
            )}
          </>
        )}

        {!isParticipant && eventData.registrationData?.registrationsActive && (
          <RegistrationButtons
            eventData={eventData}
            setEventData={setEventData}
            eventStatus={eventStatus}
            EventPage={EventPage}
            handleMobileTap={handleMobileTap}
            organizerViewOn={organizerViewOn}
            participantPreviewOn={participantPreviewOn}
            registrationActionBtnsDisplayOnly={
              registrationActionBtnsDisplayOnly
            }
            handleReturn={handleReturn}
            setActionBtnsIsLoading={setActionBtnsIsLoading}
            actionBtnsIsLoading={actionBtnsIsLoading}
            updateEventDataListPostRegistration={
              updateEventDataListPostRegistration
            }
          />
        )}

        {isParticipant && (
          <EventPageParticipantButton
            eventData={eventData}
            EventPage={EventPage}
            organizerViewOn={organizerViewOn}
            participantPreviewOn={participantPreviewOn}
            accountId={accountId}
            setIsParticipant={setIsParticipant}
            handleReturn={handleReturn}
            setActionBtnsIsLoading={setActionBtnsIsLoading}
          />
        )}
      </div>

      {actionBtnsIsLoading && <Spinner />}
    </div>
  );
};

export default ActionButtons;

import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import { useEventHandlers } from '../../../../events/useEventHandlers';
import { useSettings } from '../../../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//api
import { useLazyMyIgnoredEventsQuery } from '../../../accountApiSlice';

//Components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';

//Utility
import { refresh, view_ignore } from '../../../../../../assets/icons/index';

const IgnoredEvents = ({ Account, width }) => {
  //Hooks
  const { handleRemoveIgnoredEventAPI, handleIgnoreEventAPI } =
    useEventHandlers();
  const { accountId, accountIgnoredEvents: loadIgnoredEvents } =
    useAccountReduxHandlers();

  const { handleMobileTap, setSettingsSetViewEventDetails } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();

  //api
  const [getMyIgnoredEvents, { isFetching, isLoading, isSuccess }] =
    useLazyMyIgnoredEventsQuery();

  //Component state
  const [ignoredEvents, setIgnoredEvents] = useState();
  const [ignoredEventsStatusCheck, setIgnoredEventsStatusCheck] = useState([]);

  //UI
  const [highlightViewTap, setHighlightViewTap] = useState(false);

  //Component variables
  const fetchedIgnoreList = document.getElementById('fetched-list');
  let myIgnoredEvents;

  //Initialize  & UI functions
  useEffect(() => {
    const sameContents =
      ignoredEventsStatusCheck?.length === loadIgnoredEvents?.length &&
      ignoredEventsStatusCheck.every(
        (value, index) => value === loadIgnoredEvents[index]
      );
    if (!sameContents) {
      setIgnoredEventsStatusCheck(loadIgnoredEvents);
    }
  }, [loadIgnoredEvents]);

  useEffect(() => {
    if (myIgnoredEvents) {
      fetchedIgnoreList.focus();
    }
  }, [myIgnoredEvents]);

  //API functions
  async function handleGetMyIgnoredEvents() {
    try {
      const res = await getMyIgnoredEvents(accountId);
      setIgnoredEvents(res.data.data.ignoredEvents);

      let ignoredEventsList = [];
      res.data.data.ignoredEvents.forEach((ev) => {
        ignoredEventsList.push(ev._id);
      });
      setIgnoredEventsStatusCheck(ignoredEventsList);
    } catch (error) {}
  }

  async function ignoreEventClick(eventId) {
    const ignoredStatus = ignoredEventsStatusCheck.includes(eventId);

    if (ignoredStatus) {
      handleRemoveIgnoredEventAPI(eventId, accountId);
      let ignoredEventsList = [...ignoredEventsStatusCheck];
      const filteredEventsList = ignoredEventsList.filter(
        (event) => event !== eventId
      );
      setIgnoredEventsStatusCheck(filteredEventsList);
    } else {
      handleIgnoreEventAPI(eventId, accountId);
      let ignoredEventsList = [...ignoredEventsStatusCheck];
      ignoredEventsList.push(eventId);
      setIgnoredEventsStatusCheck(ignoredEventsList);
    }
  }

  //JSX conditional
  if (ignoredEvents?.length > 0) {
    myIgnoredEvents = (
      <div id="fetched-list">
        <div className="h24" />
        <ul
          className="ignored-event__container access-ob access-o6"
          tabIndex="0"
          aria-label={Account.attendee.ariaIgnoredList}
        >
          {[...ignoredEvents]?.reverse()?.map((ev) => {
            return (
              <li key={ev._id} className="ignored-event access-ob" tabIndex="0">
                {width > 580 ? (
                  <p
                    className="fs18 fwn access-ob access-o6"
                    tabIndex="0"
                    aria-label={`${ev.generic.title}.`}
                  >
                    {ev.generic.title.substring(0, 100)}
                    {ev.generic.title.length > 100 && ' ...'}
                  </p>
                ) : (
                  <p
                    className="fs18 fwn access-ob access-o6"
                    tabIndex="0"
                    aria-label={`${ev.title}.`}
                  >
                    {ev.generic.title.substring(0, 28)}
                    {ev.generic.title.length > 28 && ' ...'}
                  </p>
                )}
                <div className="flex-row space-between mrg-t6">
                  <button
                    className={`fs16 highlight-it-theme fwn access-ob ${
                      highlightViewTap ? 'highlight-it-theme--tap' : ''
                    }`}
                    aria-label={`${Account.attendee.viewEvent}.`}
                    onClick={() =>
                      handleMobileTap(
                        [
                          () => setHighlightViewTap(true),
                          () => setHighlightViewTap(false),
                        ],
                        [() => setSettingsSetViewEventDetails(ev)]
                      )
                    }
                  >
                    {Account.attendee.viewEvent}
                  </button>
                  <button
                    onClick={() => ignoreEventClick(ev._id)}
                    className="access-ob access-o6"
                    aria-label={
                      ignoredEventsStatusCheck.find(
                        (eventId) => eventId === ev._id
                      )
                        ? `${Account.attendee.ariaEventIsIgnored}.`
                        : `${Account.attendee.ariaEventIsNotIgnored}.`
                    }
                  >
                    <img
                      src={view_ignore}
                      alt={Account.attendee.ignoreToggle}
                      className={
                        ignoredEventsStatusCheck.find(
                          (eventId) => eventId === ev._id
                        )
                          ? 'filter-theme'
                          : 'filter-lightgray'
                      }
                      title={
                        ignoredEventsStatusCheck.find(
                          (eventId) => eventId === ev._id
                        )
                          ? Account.attendee.ignoredEventsClickToRemoveIgnore
                          : Account.attendee.ignoredEventsClickToIgnore
                      }
                    />
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray"
        tabIndex="0"
        id="ignoredEventsLabel"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={view_ignore}
            alt={IconAlts.iconIgnore}
            style={{ height: '26px' }}
            className="mrg-r12 filter-theme"
          />

          <p> {Account.attendee.ignoredEvents}</p>
        </div>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.ignoredEventsDescription}
      </p>

      <div className="flex-column flex-center">
        {(isFetching || isLoading) && (
          <>
            <div className="h24" />
            <Spinner />
          </>
        )}
      </div>

      {isSuccess && ignoredEvents?.length === 0 ? (
        <p className="fs18 italic mrg-t36 mrg-b24 flex-center border-thin br--small pad-12">
          {Account.attendee.noEventsFound}
        </p>
      ) : (
        isSuccess && <>{myIgnoredEvents}</>
      )}

      {!isSuccess && !isFetching && !isLoading && (
        <>
          <div className="h24" />
          <ItemButton
            altText={Account.attendee.fetchList}
            ariaLabel={Account.attendee.fetchList}
            handler={handleGetMyIgnoredEvents}
            icon={refresh}
            iconOn={true}
            text={Account.attendee.fetchList}
            fullWidth={true}
            iconStyle={{ height: '24px' }}
          />
        </>
      )}

      <div className="h36" />
    </div>
  );
};

export default IgnoredEvents;

import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

//language
//language of map is set by the user's browser settings

const MapContainerWithDistanceFilter = ({
  placeCoordinates,
  loadValue,
  loadTrigger,
  handler,
  distanceFilter,
  distanceMeasurement,
}) => {
  //Component state
  const [currentPosition, setCurrentPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [zoomLevel, setZoomLevel] = useState(9);
  const [radiusInMeters, setRadiusInMeters] = useState();
  const [circlePosition, setCirclePosition] = useState();
  const [markerLoaded, setMarkerLoaded] = useState(false);

  //Component variables
  const initializeEffectRan = useRef(false);
  const circleRef = useRef();
  const markerRef = useRef();

  const options = {
    styles: [
      {
        featureType: 'poi.business',
        elementType: 'labels.icon',
        stylers: [{ visibility: 'off' }],
        language: 'fr',
      },
    ],
    disableDefaultUI: true,
    zoomControl: true,
    keyboardShortcuts: false,
  };
  const containerStyle = {
    width: '100%',
    height: '340px',
    border: '1px solid rgba(0, 0, 0, 0.4)',
    margin: '0 0 0 0',
  };
  const circleOptions = {
    color: '#f67512',
    fillColor: '#f67512',
    strokeColor: '#f67512',
    fillOpacity: '0.1',
    strokeWeight: '2',
  };

  //Initialize functions
  useEffect(() => {
    if (loadTrigger && loadValue) {
      if (initializeEffectRan.current === false) {
        if (loadValue[0] !== 0 && loadValue[1] !== 0) {
          let coords = {
            lat: loadValue[0],
            lng: loadValue[1],
          };
          setCurrentPosition(coords);
        }
        initializeEffectRan.current = true;
      }
    }
  }, [loadValue, loadTrigger]); //load from saved data

  useEffect(() => {
    if (
      loadValue[0] === 0 &&
      loadValue[1] === 0 &&
      navigator?.geolocation &&
      currentPosition.lat === 0 &&
      currentPosition.lng === 0
    ) {
      const successCallback = (position) => {
        setCurrentPos(position);
      };

      navigator.geolocation.getCurrentPosition(successCallback);
    }
  }, []); //load from navigator if there is no saved data and no currentPosition set.

  //Component functions
  useEffect(() => {
    if (radiusInMeters <= 2000 && radiusInMeters > 0) {
      setZoomLevel(13);
    }
    if (radiusInMeters <= 4000 && radiusInMeters > 2000) {
      setZoomLevel(12);
    }
    if (radiusInMeters <= 8000 && radiusInMeters > 4000) {
      setZoomLevel(11);
    }
    if (radiusInMeters <= 16000 && radiusInMeters > 8000) {
      setZoomLevel(10);
    }
    if (radiusInMeters <= 32000 && radiusInMeters > 16000) {
      setZoomLevel(9);
    }
    if (radiusInMeters <= 68000 && radiusInMeters > 32000) {
      setZoomLevel(8);
    }
    if (radiusInMeters <= 128000 && radiusInMeters > 68000) {
      setZoomLevel(7);
    }
    if (radiusInMeters <= 290000 && radiusInMeters > 128000) {
      setZoomLevel(6);
    }
    if (radiusInMeters <= 500000 && radiusInMeters > 290000) {
      setZoomLevel(5);
    }
    if (radiusInMeters <= 1000000 && radiusInMeters > 500000) {
      setZoomLevel(4);
    }
    if (radiusInMeters <= 2000000 && radiusInMeters > 1000000) {
      setZoomLevel(3);
    }
    if (radiusInMeters > 2000000) {
      setZoomLevel(2);
    }
  }, [radiusInMeters]);

  useEffect(() => {
    if (markerLoaded && markerRef.current) {
      const markerPosition = markerRef.current;
      setCirclePosition({
        lat: markerPosition.props.position.lat,
        lng: markerPosition.props.position.lng,
      });
    }
  }, [markerLoaded, loadValue]);

  useEffect(() => {
    if (distanceFilter && distanceMeasurement) {
      if (distanceMeasurement === 'km') {
        setRadiusInMeters(distanceFilter * 1000);
      } else if (distanceMeasurement === 'mi') {
        setRadiusInMeters(distanceFilter * 1609.344);
      }
    }
  }, [distanceFilter, distanceMeasurement]);

  useEffect(() => {
    if (
      placeCoordinates &&
      placeCoordinates?.lat !== 0 &&
      placeCoordinates?.lat !== '' &&
      placeCoordinates?.lng !== 0 &&
      placeCoordinates?.lng !== ''
    ) {
      setCurrentPosition({
        lat: placeCoordinates?.lat,
        lng: placeCoordinates?.lng,
      });
    }
  }, [placeCoordinates]);

  const setCurrentPos = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    }; ///function to set default position to user's location

    setCurrentPosition(currentPosition);
    handler([currentPosition.lat, currentPosition.lng]);
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    setCirclePosition({ lat, lng });
    let coords = [lat, lng];
    handler(coords);
  };

  return (
    <GoogleMap
      id={'gmap'}
      mapContainerStyle={containerStyle}
      options={options}
      zoom={zoomLevel}
      center={
        loadValue && loadValue[0] !== 0 && loadValue[1] !== 0
          ? { lat: loadValue[0], lng: loadValue[1] }
          : currentPosition
      }
    >
      {(currentPosition.lat && radiusInMeters > 0) ||
      (loadValue && radiusInMeters > 0) ? (
        <>
          <Marker
            position={
              loadValue && loadValue[0] !== 0 && loadValue[1] !== 0
                ? { lat: loadValue[0], lng: loadValue[1] }
                : currentPosition
            }
            onDragEnd={(e) => onMarkerDragEnd(e)}
            draggable={true}
            ref={markerRef}
            onLoad={() => setMarkerLoaded(true)}
          />

          <Circle
            ref={circleRef}
            center={circlePosition}
            options={circleOptions}
            radius={radiusInMeters}
          />
        </>
      ) : null}
    </GoogleMap>
  );
};

export default MapContainerWithDistanceFilter;

function extractCharactersFromString({ input, charCount, toUpperCase }) {
  if (!input) {
    return '';
  }

  let extractedStr = '';

  for (let i = 0; i < input.length; i++) {
    if ((i + 1) % charCount === 0) {
      extractedStr += input[i];
    }
  }

  if (toUpperCase) {
    return extractedStr.toUpperCase();
  }

  return extractedStr;
}

export { extractCharactersFromString };

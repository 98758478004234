import { useEffect } from 'react';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../../utils/UI/ScrollTo';
import { warning } from '../../../../../../assets/icons/index';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

const HostSubPageThree = ({ setHostSubPage }) => {
  const { IconAlts } = useLanguageComponents();
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <>
      <h1 className="fwsb fs21 lh36">Running a Session</h1>
      <p className="fs18 lh28 mrg-t24">
        To run a MyndFull Translations session, you'll need the following:
      </p>

      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">A desktop computer running Google Chrome</li>
        <li className="mrg-t12">A microphone connected to the computer</li>
        <li className="mrg-t12">A stable internet connection</li>
      </ul>

      <p className="fs18 lh28 mrg-t24">
        When prompted through your browser, you must allow MyndFull Translations
        access to your microphone. Microphone access is visually confirmed by a
        checkmark next to the microphone icon in the Session Room.
      </p>

      <p className="fs18 lh28 mrg-t24">Once your Session Room has loaded:</p>

      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          Select the current speaker from the dropdown menu.
        </li>
        <li className="mrg-t12">
          Choose the Text Language you want to populate your host text box.
        </li>
        <li className="mrg-t12">
          Toggle the Activate Speech Recognition button to start or pause
          translations.
        </li>
      </ul>

      <div className="flex-row align-center mrg-t24">
        <img
          src={warning}
          alt={IconAlts.iconWarning}
          style={{ height: '21px' }}
        />
        <p className="fs18 lh28 mrg-l12">
          Be sure to match the selected speaker profile with the person who is
          currently speaking.
        </p>
      </div>

      <h1 className="fwsb fs21 lh36 mrg-t24">Change Speaker Data</h1>

      <p className="fs18 lh28 mrg-t24">
        You can update a speaker's profile during a live session if needed. This
        is especially useful if a key term isn't translating correctly and it
        wasn't included in the speaker's original keywords list.
      </p>

      <p className="fs18 lh28 mrg-t24">To edit a speaker profile:</p>

      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">Pause speech recognition.</li>
        <li className="mrg-t12">Open the speaker dropdown.</li>
        <li className="mrg-t12">Click the View Data icon.</li>
        <li className="mrg-t12">
          Make your edits and click Save — changes will take effect immediately.
        </li>
      </ul>

      <h1 className="fwsb fs21 lh36 mrg-t24">Pop-out Display</h1>

      <p className="fs18 lh28 mrg-t24">
        Hosts have the option to launch a pop-out window that displays
        translated text in real time, based on the host's selected Text
        Language. This minimalist display is perfect for projecting translations
        on an external monitor without showing the full user interface. The
        display includes an option to toggle dark/light mode, as well as options
        to adjust the font-size.
      </p>

      <p className="fs18 lh28 mrg-t24 fwsb">Key Takeaways:</p>
      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          Hosting requires Google Chrome on desktop, a microphone, and an
          internet connection.
        </li>
        <li className="mrg-t12">
          Always match the active speaker to the correct profile.
        </li>
        <li className="mrg-t12">
          Speaker profiles can be edited mid-session — no need to restart or
          recreate the room.
        </li>
        <li className="mrg-t12">
          A customizable pop-out window allows you to display translated text
          cleanly and clearly.
        </li>
      </ul>

      <div className="translations-selections mrg-auto-lr mrg-t36">
        <ItemButton
          text={'Continue'}
          handler={() => setHostSubPage(4)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />

        <ItemButton
          text={'Return'}
          handler={() => setHostSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </>
  );
};

export default HostSubPageThree;

//hooks
import { useSettings } from '../../context/SettingsProvider';
//utils
import {
  image_community,
  image_community_mobile,
} from '../../assets/images/explore';

const ExploreCommunity = ({ ExploreMyndFull }) => {
  const { width } = useSettings();

  return (
    <div className="explore-community bg-color-white">
      {width > 480 ? (
        <img
          src={image_community}
          alt={
            'Some friends gathered together to emphasize the communal bonds that can be formed through education.'
          }
          className="explore-community-img access-ob"
          tabIndex="0"
          loading="lazy"
        />
      ) : (
        <img
          src={image_community_mobile}
          alt={
            'Some friends gathered together to emphasize the communal bonds that can be formed through education.'
          }
          className="explore-community-img access-ob"
          tabIndex="0"
          loading="lazy"
        />
      )}

      <div className="explore-community__container">
        <p
          className="explore-community-text-box access-ob access-o-6"
          tabIndex="0"
        >
          The absence of a dedicated educational event ecosystem is stifling. At
          stake is not only great ideas but also great communities. Exchanging
          ideas, perspectives and knowledge is a powerful way to build
          meaningful and lasting connections.
        </p>
      </div>
    </div>
  );
};

export default ExploreCommunity;

import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';

//components
import { MapContainer } from '../../../../../../components/MapContainer/MapContainer';
import AutocompletePlacesNoAddress from '../../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utils
import { checkmark_green, img_location } from '../../../../../../assets/icons';

const SpeakerLocation = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const {
    accountGeoPositionCoordinates,
    accountSpeakerGeoPositionCoordinates,
    handleAccountSpeakerSetGeoPositionCoordinates,
    accountSpeakerSignUpComplete,
  } = useAccountReduxHandlers();

  //language
  const { Generic, IconAlts } = useLanguageComponents();
  const { SpeakerLocation } = useLanguageAccount();

  //state
  const [placeCoordinates, setPlaceCoordinates] = useState({
    lat: 0,
    lng: 0,
  }); //used to avoid infinite loop in Map Container
  const [saveValid, setSaveValid] = useState(false);
  const [speakerNewCoordinates, setSpeakerNewCoordiantes] = useState(
    accountGeoPositionCoordinates
  );

  //initialize
  useEffect(() => {
    if (
      accountSpeakerGeoPositionCoordinates[0] === 0 ||
      accountSpeakerGeoPositionCoordinates[1] === 0
    ) {
      if (
        accountGeoPositionCoordinates[0] !== 0 &&
        accountGeoPositionCoordinates[1] !== 0
      ) {
        handleAccountSpeakerSetGeoPositionCoordinates(
          accountGeoPositionCoordinates
        );
      }
    }
  }, [accountGeoPositionCoordinates, accountSpeakerGeoPositionCoordinates]);

  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      setSpeakerNewCoordiantes(accountSpeakerGeoPositionCoordinates);
    }
  }, [accountSpeakerGeoPositionCoordinates]);

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    if (accountSpeakerSignUpComplete) {
      setSpeakerNewCoordiantes(newCoords);
    } else {
      handleAccountSpeakerSetGeoPositionCoordinates(newCoords);
    }
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  useEffect(() => {
    if (accountSpeakerSignUpComplete) {
      if (
        speakerNewCoordinates[0] !== 0 &&
        speakerNewCoordinates[0] !== accountSpeakerGeoPositionCoordinates[0] &&
        speakerNewCoordinates[1] !== 0 &&
        speakerNewCoordinates[1] !== accountSpeakerGeoPositionCoordinates[1]
      ) {
        setSaveValid(true);
      } else {
        setSaveValid(false);
      }
    }
  }, [speakerNewCoordinates, accountSpeakerGeoPositionCoordinates]);

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
        id="speakerLocationLabel"
        tabIndex="0"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={img_location}
            alt={IconAlts.iconLocationPin}
            style={{ height: '28px' }}
            className="mrg-r12 filter-theme"
          />
          <p>{SpeakerLocation.location}</p>
        </div>

        <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
          <p className="fs14 mrg-auto-left">{Generic.required}</p>
          {accountGeoPositionCoordinates[0] !== 0 &&
            accountGeoPositionCoordinates[1] !== 0 && (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            )}
        </div>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {SpeakerLocation.description}
      </p>

      <div className="gmap-wrapper flex-center-column mrg-t24">
        {accountSpeakerSignUpComplete ? (
          <>
            {speakerNewCoordinates && (
              <MapContainer
                loadTrigger={true}
                loadValue={speakerNewCoordinates}
                placeCoordinates={placeCoordinates}
                handler={setSpeakerNewCoordiantes}
              />
            )}
          </>
        ) : (
          <>
            {accountSpeakerGeoPositionCoordinates && (
              <MapContainer
                loadTrigger={true}
                loadValue={accountSpeakerGeoPositionCoordinates}
                placeCoordinates={placeCoordinates}
                handler={handleAccountSpeakerSetGeoPositionCoordinates}
              />
            )}
          </>
        )}
      </div>
      <div className="h24" />
      <AutocompletePlacesNoAddress
        setAutocompleteCoordinates={setAutocompleteCoordinates}
      />

      {accountSpeakerSignUpComplete && (
        <>
          <div className="h24" />
          <SaveButton
            saveValid={saveValid}
            saveAriaLanguage={SpeakerLocation.location}
            handler={() =>
              saveAccountChange(
                {
                  speakerGeoPosition: {
                    type: 'Point',
                    coordinates: speakerNewCoordinates,
                  },
                },
                'speakerGeoPositionCoordinates'
              )
            }
            isLoading={loadingSaveBtnUI === 'speakerGeoPositionCoordinates'}
          />
        </>
      )}
      <div className="h36" />
    </>
  );
};

export default SpeakerLocation;

import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import { dropdown_arrow, trash } from '../../assets/icons/index';
import { DateFromTimeStamp } from '../../utils/dateTime/DateCalc';

//need state in parent component to have itemSelection so that the selected item can be highlighted
const SelectionList = ({
  dataList,
  id,
  handler,
  itemSelection,
  ariaText,
  deleteSelection,
  selectedFile,
  col1Heading,
  col1DataPath1,
  col1DataPath2,
  col2DataPath1,
  col2DataPath2,
  activeFile,
  customWrapper,
}) => {
  //Hooks
  const { width, handleMobileTap } = useSettings();
  const { accountContentLanguage: locale } = useAccountReduxHandlers();

  //language
  const { SelectionList } = useLanguageComponents();

  //Component state
  const [sortSelection, setSortSelection] = useState();
  const [descending, setDescending] = useState(false);

  //UI
  const [hover, setHover] = useState();
  const [tapHighlightMobileRemoveBtn, setTapHighlightMobileRemoveBtn] =
    useState();
  const [removeIdForMobile, setRemoveIdForMobile] = useState();

  //Component variables
  let startX;
  let col1Width;
  let col2Width;

  useEffect(() => {
    if (!itemSelection && !selectedFile) return setRemoveIdForMobile();
    if (itemSelection?._id) {
      setRemoveIdForMobile(itemSelection?._id);
    } else {
      if (selectedFile) {
        setRemoveIdForMobile(selectedFile); //from save selection
      }
    }
  }, [selectedFile, itemSelection]);

  //Component functions
  function handleDividerMouseDown(event) {
    event.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    startX = event.pageX;
    col1Width = document.querySelector(
      '.explorer-column:first-child'
    ).offsetWidth;
    col2Width = document.querySelector(
      '.explorer-column:last-child'
    ).offsetWidth;
  }

  function handleMouseMove(event) {
    event.preventDefault();
    const dx = event.pageX - startX;
    const newCol1Width = col1Width + dx;
    const newCol2Width = col2Width - dx;
    document.querySelector(
      '.explorer-column:first-child'
    ).style.width = `${newCol1Width}px`;
    document.querySelector(
      '.explorer-column:last-child'
    ).style.width = `${newCol2Width}px`;
  }

  function handleMouseUp() {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }

  function handleHeadingClick(heading) {
    if (heading === 'lastModified') {
      setSortSelection('lastModified');
    } else {
      setSortSelection(col1DataPath2 || col1DataPath1);
    }
    return setDescending(!descending);
  }

  function handleKeyDownEnter(e, item, select) {
    if (e.key === 'Enter') {
      handler(item, select);
    }
  }
  function handleMainSelectionClick(item, clickType) {
    handler(item, clickType);
  }

  function sortList(dataList) {
    if (sortSelection === 'lastModified') {
      dataList.sort(function (a, b) {
        return (
          b[col2DataPath1][col2DataPath2] - a[col2DataPath1][col2DataPath2]
        );
      });
    } else {
      dataList.sort(function (a, b) {
        const propertyA = a[col1DataPath1][col1DataPath2]?.toUpperCase();
        const propertyB = b[col1DataPath1][col1DataPath2]?.toUpperCase();

        if (propertyA < propertyB) {
          return -1;
        }
        if (propertyA > propertyB) {
          return 1;
        }
        return 0;
      });
    }

    if (!descending) {
      return dataList.reverse();
    } else {
      return dataList;
    }
  }

  return (
    <div
      className={`explorer-window access-ob ${
        customWrapper ? customWrapper : ''
      }`}
      tabIndex="0"
      aria-label={ariaText?.ariaTableTitle}
    >
      <div className="explorer-column">
        <button
          className="explorer-heading no-select access-ob"
          onClick={() => handleHeadingClick(col1DataPath2 || col1DataPath1)}
          tabIndex="0"
          aria-label={`${col1Heading}. ${SelectionList.sort} ${
            descending
              ? SelectionList.ariaOrderDescending
              : SelectionList.ariaOrderAsecending
          } ${SelectionList.ariaColumnHeading}`}
        >
          <h1>{col1Heading}</h1>
          {sortSelection !== 'lastModified' ? (
            <img
              src={dropdown_arrow}
              alt={SelectionList.sort}
              className={descending ? 'sort reverse-vertical' : 'sort'}
            />
          ) : (
            <div className="sort-ghost" />
          )}
        </button>

        {sortList(dataList)?.map((item, index) => {
          return (
            <div
              role="button"
              className={`table-row access-ob fs18 ${
                hover === index ||
                selectedFile === item[col1DataPath1][col1DataPath2]
                  ? 'active'
                  : ''
              } ${
                itemSelection?._id === item._id ||
                selectedFile === item[col1DataPath1][col1DataPath2]
                  ? 'selected'
                  : ''
              }`}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover()}
              key={`${id}-0-${index}`}
              onClick={() => handleMainSelectionClick(item, 'select')}
              tabIndex="0"
              aria-label={`${item[col1DataPath1][col1DataPath2]}. ${
                SelectionList.lastModified
              }
              ${DateFromTimeStamp(
                item[col2DataPath1][col2DataPath2],
                {
                  weekday: 'off',
                },
                locale
              )}
              `}
              onKeyDown={(e) => handleKeyDownEnter(e, item, 'select')}
              aria-pressed={itemSelection?._id === item._id}
            >
              <div className="flex-column">
                <p>{item[col1DataPath1][col1DataPath2]}</p>
                <p className="fs14 italic">
                  {activeFile === item[col1DataPath1][col1DataPath2] &&
                    'Active File'}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="explorer-divider"
        onMouseDown={handleDividerMouseDown}
      ></div>
      {/* last modified column */}
      <div className="explorer-column">
        <div
          className="explorer-heading no-select access-ob"
          onClick={() => handleHeadingClick('lastModified')}
          tabIndex="0"
          aria-label={`${SelectionList.lastModified}. ${SelectionList.ariaColumnHeading}`}
        >
          <h1>{SelectionList.lastModified}</h1>
          {sortSelection === 'lastModified' ? (
            <img
              src={dropdown_arrow}
              alt={SelectionList.sort}
              className={descending ? 'sort reverse-vertical' : 'sort'}
            />
          ) : (
            <div className="sort-ghost" />
          )}
        </div>
        {sortList(dataList)?.map((item, index) => {
          return (
            <div
              key={`${id}-1-${index}`}
              className={`table-row access-ob fs18 ${
                hover === index ||
                selectedFile === item[col1DataPath1][col1DataPath2]
                  ? 'active'
                  : ''
              } ${
                itemSelection?._id === item._id ||
                selectedFile === item[col1DataPath1][col1DataPath2]
                  ? 'selected'
                  : ''
              }`}
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover()}
              tabIndex="0"
              onKeyDown={(e) => handleKeyDownEnter(e, item, 'select')}
              aria-pressed={itemSelection?._id === item._id}
              onClick={() => handleMainSelectionClick(item, 'select')}
            >
              <div className="full-width">
                {DateFromTimeStamp(
                  item[col2DataPath1][col2DataPath2],
                  {
                    weekday: 'off',
                  },
                  locale
                )}
              </div>
              {/* remove button -- media responsive */}
              {width > 480 ? (
                <button
                  className="trash__container access-ob access-o-6"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMainSelectionClick(item, 'delete');
                  }}
                  tabIndex={
                    hover === index ||
                    itemSelection?._id === item._id ||
                    selectedFile === item[col1DataPath1][col1DataPath2]
                      ? '0'
                      : '-1'
                  }
                  aria-label={SelectionList.delete}
                  aria-pressed={deleteSelection?._id === item?._id}
                >
                  {(hover === index ||
                    itemSelection?._id === item._id ||
                    selectedFile === item[col1DataPath1][col1DataPath2]) && (
                    <img
                      src={trash}
                      className="trashbin-selection-list filter-lightgray"
                      alt={SelectionList.delete}
                    />
                  )}
                </button>
              ) : (
                <button
                  className={`trash__container--mobile access-ob access-o-6 ${
                    tapHighlightMobileRemoveBtn === item._id
                      ? 'highlight-i-lgr--tap highlight-b-red--tap'
                      : ''
                  }`}
                  onClick={(e) => {
                    if (
                      removeIdForMobile === item?._id ||
                      removeIdForMobile === item?.meta?.saveName
                    ) {
                      handleMobileTap(
                        [
                          () => setTapHighlightMobileRemoveBtn(item._id),
                          () => setTapHighlightMobileRemoveBtn(),
                        ],
                        [() => handleMainSelectionClick(item, 'delete')]
                      );
                    } else {
                      return;
                    }
                  }}
                  tabIndex={
                    hover === index ||
                    itemSelection?._id === item._id ||
                    selectedFile === item[col1DataPath1][col1DataPath2]
                      ? '0'
                      : '-1'
                  }
                  aria-label={SelectionList.delete}
                  aria-pressed={deleteSelection?._id === item?._id}
                >
                  {(hover === index ||
                    itemSelection?._id === item._id ||
                    selectedFile === item[col1DataPath1][col1DataPath2]) && (
                    <img
                      src={trash}
                      className="trashbin-selection-list filter-lightgray"
                      alt={SelectionList.delete}
                    />
                  )}
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SelectionList;

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';

//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Utility
import {
  organize_create,
  organize_find_speakers,
  img_organize_overview,
} from '../../../../assets/icons';

const OrganizeViews = () => {
  //Hooks
  const { settingsOrganizeView: view, handleSettingsSetOrganizeView } =
    useSettingsHandlers();

  //language
  const { SettingsOrganizeLanguage } = useLanguageSettings();

  //Component variables
  let content;

  if (SettingsOrganizeLanguage) {
    content = (
      <ul className="view__container">
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetOrganizeView('overview')}
            title={SettingsOrganizeLanguage.organizerOverview}
            aria-label={SettingsOrganizeLanguage.organizerOverview}
            id="view-overview"
            aria-pressed={view === 'overview'}
          >
            <div
              className={`view-img__container ${
                view === 'overview' ? 'selected' : ''
              }`}
            >
              <img
                src={img_organize_overview}
                alt={SettingsOrganizeLanguage.organizerOverview}
              />
            </div>
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetOrganizeView('create')}
            title={SettingsOrganizeLanguage.createEvents}
            aria-label={SettingsOrganizeLanguage.createEvents}
            id="view-create"
            aria-pressed={view === 'create'}
          >
            <div
              className={`view-img__container ${
                view === 'create' ? 'selected' : ''
              }`}
            >
              <img
                src={organize_create}
                alt={SettingsOrganizeLanguage.createEvents}
              />
            </div>
          </button>
        </li>
        <li>
          <button
            tabIndex="0"
            className="access-ob access-o6"
            onClick={() => handleSettingsSetOrganizeView('speakers')}
            title={SettingsOrganizeLanguage.findSpeakers}
            aria-label={SettingsOrganizeLanguage.findSpeakers}
            id="view-speakers"
            aria-pressed={view === 'speakers'}
          >
            <div
              className={`view-img__container ${
                view === 'speakers' ? 'selected' : ''
              }`}
            >
              <img
                src={organize_find_speakers}
                alt={SettingsOrganizeLanguage.findSpeakers}
              />
            </div>
          </button>
        </li>
      </ul>
    );
  }
  return content;
};

export default OrganizeViews;

import { useEffect, useRef, useState } from 'react';
import {
  AddressElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { v4 as uuidv4 } from 'uuid';

//hooks
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../components/Spinner/Spinner';

//utils
import { axiosLimited } from '../../../../../axios/axios';
import { retryWrapper } from '../../../../../utils/logic/retryFns';
import { checkmark_green } from '../../../../../assets/icons';

const BillingPaymentNewCard = ({
  saveAccountChange,
  handleCancelNewCard,
  reset,
  hasPrimaryCard,
  setupIntentIsLoading,
  setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices,
}) => {
  //hooks
  const elements = useElements();
  const stripe = useStripe();
  const { handleError } = useApiStatus();
  const {
    accountId,
    accountBillingPaymentMethodIds,
    accountBilling,
    accountBillingCustomerId,
    accountFailedInvoices,
  } = useAccountReduxHandlers();

  //language
  const { BillingPaymentMethods } = useLanguageAccount();
  const { Generic, IconAlts } = useLanguageComponents();

  //state
  const [initialized, setInitialized] = useState(false);
  const [isProcessingSaveNewCard, setIsProcessingSaveNewCard] = useState(false);
  const [
    isProcessingSaveNewCardAsPrimary,
    setIsProcessingSaveNewCardAsPrimary,
  ] = useState(false);

  const [saveValid, setSaveValid] = useState(false);
  const [paymentInformationComplete, setPaymentInformationComplete] =
    useState(false);
  const [addressInformationComplete, setAddressInformationComplete] =
    useState(false);

  //variables
  const initializeEffectRan = useRef(false);

  useEffect(() => {
    if (!setupIntentIsLoading) {
      const initializeTimer = setTimeout(() => {
        setInitialized(true);
      }, 1200);

      return () => clearTimeout(initializeTimer);
    }
  }, [setupIntentIsLoading]);

  useEffect(() => {
    if (!elements || setupIntentIsLoading) return;

    if (initializeEffectRan.current === false) {
      initializeEffectRan.current = true;
      const paymentElement = elements?.getElement(PaymentElement);
      const addressElement = elements?.getElement(AddressElement);

      const handlePaymentChange = (event) => {
        setPaymentInformationComplete(event.complete);
      };

      const handleAddressChange = (event) => {
        setAddressInformationComplete(event.complete);
      };

      paymentElement.on('change', handlePaymentChange);
      addressElement.on('change', handleAddressChange);

      return () => {
        paymentElement.off('change', handlePaymentChange);
        addressElement.off('change', handleAddressChange);
      };
    }
  }, [elements, setupIntentIsLoading]);

  //UI
  useEffect(() => {
    if (paymentInformationComplete && addressInformationComplete) {
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
  }, [paymentInformationComplete, addressInformationComplete]);

  async function saveNewPaymentMethod(saveAsPrimary) {
    if (!isProcessingSaveNewCard && !isProcessingSaveNewCardAsPrimary) {
      if (!elements || !stripe) {
        return handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'PaymentCard.js/updatePaymentIntent-1',
        });
      }

      let confirmSetupResponse;

      try {
        if (saveAsPrimary === true) {
          setIsProcessingSaveNewCardAsPrimary(true);

          if (accountFailedInvoices?.length > 0) {
            setNoticeForDelayedPaymentMethodUpdateAddressingOutstandingInvoices(
              true
            );
          }
        } else {
          setIsProcessingSaveNewCard(true);
        }

        await elements.submit();

        const addressElement = elements.getElement(AddressElement);
        const addressObj = await addressElement.getValue();

        const billing_details = {
          name: addressObj.value.name,
          address: {
            line1: addressObj.value.address.line1,
            line2: addressObj.value.address.line2,
            city: addressObj.value.address.city,
            state: addressObj.value.address.state,
            country: addressObj.value.address.country,
            postal_code: addressObj.value.address.postal_code,
          },
        };

        const saveAsPrimaryCard =
          saveAsPrimary || !hasPrimaryCard ? true : false;

        let return_url =
          process.env.REACT_APP_ENV === 'development'
            ? `http://localhost:3000/account/paymentConfirmation?saveAsPrimaryCard=${saveAsPrimaryCard}`
            : `https://myndfull.com/account/paymentConfirmation?saveAsPrimaryCard=${saveAsPrimaryCard}`;

        async function stripeConfirmSetup({ idempotencyKey }) {
          try {
            confirmSetupResponse = await stripe.confirmSetup(
              {
                elements,
                redirect: 'if_required',
                confirmParams: {
                  payment_method_data: {
                    billing_details,
                  },
                  return_url,
                },
              },
              { idempotencyKey }
            );
            const paymentMethodId =
              confirmSetupResponse.setupIntent?.payment_method;
            return paymentMethodId;
          } catch (error) {
            throw error;
          }
        }

        const idempotencyKey = uuidv4();
        const paymentMethodId = await retryWrapper(stripeConfirmSetup, [
          { idempotencyKey },
        ]);

        if (confirmSetupResponse?.error) {
          throw new Error('stripe');
        }

        if (!paymentMethodId) throw new Error();

        if (!accountBillingCustomerId) {
          let newCustomerId;

          const createCustomerResponse = await axiosLimited.post(
            '/api/stripe/createCustomer',
            {
              accountId,
              paymentMethodId,
            }
          );

          newCustomerId = createCustomerResponse?.data?.customerId;
          if (!newCustomerId) throw new Error('No customer Id.');

          await saveAccountChange({
            billing: {
              ...accountBilling,
              customerId: newCustomerId,
              paymentMethodIds: [paymentMethodId],
            },
          });
        } else {
          async function attachPaymentMethod() {
            try {
              await axiosLimited.post('/api/stripe/attachPaymentMethod', {
                accountId,
                paymentMethodId: paymentMethodId,
              });
            } catch (error) {
              throw error;
            }
          }

          await retryWrapper(attachPaymentMethod);

          const newPaymentMethodIds = [
            ...accountBillingPaymentMethodIds,
            paymentMethodId,
          ];

          await saveAccountChange({
            billing: {
              ...accountBilling,
              paymentMethodIds: newPaymentMethodIds,
            },
          });

          if (saveAsPrimary && hasPrimaryCard) {
            async function setPrimaryPaymentMethod() {
              try {
                await axiosLimited.post('/api/stripe/setPrimaryPaymentMethod', {
                  accountId,
                  newPrimaryPaymentMethodId: paymentMethodId,
                });
              } catch (error) {
                throw error;
              }
            }

            await retryWrapper(setPrimaryPaymentMethod);
          }
        }

        reset(); //reset stripe row
        if (saveAsPrimary) {
          setIsProcessingSaveNewCardAsPrimary(false);
        } else {
          setIsProcessingSaveNewCard(false);
        }
      } catch (error) {
        if (saveAsPrimary) {
          setIsProcessingSaveNewCardAsPrimary(false);
        } else {
          setIsProcessingSaveNewCard(false);
        }

        if (confirmSetupResponse?.error) {
          return handleError({
            message: `${confirmSetupResponse?.error?.code}`,
            id: Date.now(),
            origin: 'BillingPaymentNewCard.js/saveNewPaymentMethod',
          });
        } else {
          return handleError({
            message: 'tryAgain',
            id: Date.now(),
            origin: 'BillingPaymentNewCard.js/saveNewPaymentMethod',
            error,
          });
        }
      }
    }
  }

  return (
    <div className="account-card__wrapper mrg-t24">
      <div id="payment-form" className="account-card__container">
        {!initialized && <Spinner minWidth={'100%'} />}
        <div
          style={{ height: `${!initialized ? '0px' : ''}` }}
          className={`${!initialized ? 'visibility-hidden' : ''}`}
        >
          <div className="space-between mrg-b24">
            <label
              className="content-label flex-row flex-column--ph align-center  min-full-width"
              tabIndex="0"
            >
              <div className="flex-row align-center mrg-auto-right">
                <p>{BillingPaymentMethods.newCard}</p>
              </div>

              <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
                <p className="fs14 mrg-auto-left">{Generic.required}</p>
                {paymentInformationComplete && (
                  <img
                    src={checkmark_green}
                    alt={IconAlts.iconCheckmark}
                    className="mrg-l6"
                  />
                )}
              </div>
            </label>
          </div>

          <PaymentElement
            options={{
              style: {
                base: {
                  display: 'block',
                },
              },
            }}
          />
          <div className="flex-column mrg-t24 color-gray flex-start full-width">
            <label
              className="content-label  flex-row flex-column--ph mrg-b24 align-center  min-full-width"
              tabIndex="0"
            >
              <div className="flex-row align-center mrg-auto-right">
                <p>{BillingPaymentMethods.billingAddress}</p>
              </div>

              <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
                <p className="fs14 mrg-auto-left">{Generic.required}</p>
                {addressInformationComplete && (
                  <img
                    src={checkmark_green}
                    alt={IconAlts.iconCheckmark}
                    className="mrg-l6"
                  />
                )}
              </div>
            </label>

            <div className="mrg-tb12 full-width">
              <AddressElement
                options={{
                  mode: 'billing',
                  allowedCountries: ['CA'],
                  autocomplete: {
                    mode: 'google_maps_api',
                    apiKey: '{AIzaSyBo7CkkuI8rLc8D9zzIzYIV2NCFP_E-JM8}',
                  },
                }}
              />
            </div>
          </div>

          <div className="divider-dotted pad-tb24" />
          <div className="flex-column full-width space-evenly">
            {hasPrimaryCard && (
              <ItemButton
                handler={() => saveNewPaymentMethod(false)}
                text={BillingPaymentMethods.save}
                fullWidth={true}
                customWrapper={'mrg-tb24'}
                isLoading={isProcessingSaveNewCard}
                unavailable={!saveValid || isProcessingSaveNewCardAsPrimary}
              />
            )}

            <ItemButton
              handler={() => saveNewPaymentMethod(true)}
              text={BillingPaymentMethods.saveAsPrimaryCard}
              fullWidth={true}
              customWrapper={'mrg-b24'}
              isLoading={isProcessingSaveNewCardAsPrimary}
              unavailable={!saveValid || isProcessingSaveNewCard}
            />
            <ItemButton
              handler={handleCancelNewCard}
              text={BillingPaymentMethods.cancel}
              fullWidth={true}
              unavailable={
                isProcessingSaveNewCard ||
                isProcessingSaveNewCardAsPrimary ||
                isProcessingSaveNewCard
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPaymentNewCard;

//language
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';

//components
import ButtonTextSimple from '../../../../../components/ButtonTextSimple/ButtonTextSimple';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import { return_curved_arrow, trash } from '../../../../../assets/icons';

const ParticipantActionBtns = ({
  participant,
  handleSaveAndReturn,
  handleRemoveParticipant,
}) => {
  //language
  const { EventParticipant } = useLanguageOrganize();

  return (
    <div className="flex-row flex-column--ph full-width space-evenly">
      <ButtonTextSimple
        handler={() => {
          handleSaveAndReturn({ ...participant });
        }}
        icon={return_curved_arrow}
        iconOn={true}
        iconClass={'filter-gray mrg-r12'}
        iconStyle={{ height: '18px' }}
        text={EventParticipant.saveAndReturn}
        id={'returnButton'}
        customWrapper={'mrg-auto-lr mrg-b36--ph text-gray'}
      />
      <ButtonTextSimple
        handler={() => {
          handleRemoveParticipant(participant?.id);
          ScrollTo({
            focusId: 'participantsLabel',
            behavior: 'smooth',
            scrollToTop: false,
            scrollToElementId: 'participantsLabel',
          });
        }}
        icon={trash}
        iconOn={true}
        iconClass={'filter-gray mrg-r12'}
        iconStyle={{ height: '18px' }}
        text={EventParticipant.removeParticipant}
        id={'removeButton'}
        customWrapper={'mrg-auto-lr text-gray'}
      />
    </div>
  );
};

export default ParticipantActionBtns;

import { useState, useRef, useEffect } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useApiStatus } from '../../context/ApiStatusProvider';

const CheckBoxBoolean = ({
  handler,
  loadTrigger,
  loadValue,
  maxWidth,
  reload,
  customWrapper,
  temporaryDisable,
  temporaryDisableMessage,
  id,
  label,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { handleMobileTap } = useSettings();

  //Component state
  const [val, setVal] = useState(false);

  //UI state
  const [tapHighlight, setTapHighlight] = useState(false);

  //Component variables
  const initializeEffectRan = useRef(false);

  //Initialize
  useEffect(() => {
    if (loadTrigger) {
      if (initializeEffectRan.current === false) {
        if (loadValue) {
          setVal(loadValue);
        }
        initializeEffectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]);

  useEffect(() => {
    if (reload) {
      setVal(loadValue);
    }
  }, [loadValue, reload]);

  //Component functions
  function handleCheckBox() {
    if (temporaryDisable) {
      return handleWarning({
        message: temporaryDisableMessage,
        statusCode: 500,
        origin: `CheckboxBoolean.js/${id}`,
      });
    }

    handleMobileTap(
      [() => setTapHighlight(true), () => setTapHighlight(false)],
      [
        () => {
          setVal(!val);
          handler(!val);
        },
      ]
    );
  }

  function handleKeyDownCheckBox(e) {
    if (temporaryDisable) {
      return handleWarning({
        message: temporaryDisableMessage,
        statusCode: 500,
        origin: `Checkbox.js/${id}`,
      });
    }
    if (e.key === 'Enter' || e.key === ' ') {
      handleCheckBox(e);
    }
  }

  return (
    <div
      className={`checkbox__container highlight-it-theme fs16 ${
        tapHighlight ? 'highlight-it-theme--tap' : ''
      }  ${customWrapper ? customWrapper : ''} ${
        temporaryDisable ? 'opacity-03' : ''
      }`}
    >
      <input
        type="checkbox"
        id={`checkbox-${id}`}
        checked={val}
        aria-checked={val}
        tabIndex="0"
        onKeyDown={(e) => handleKeyDownCheckBox(e)}
        className={`${
          tapHighlight ? 'highlight-o-theme--tap' : 'highlight-o-theme'
        }`}
        onChange={(e) => handleCheckBox(e)}
        onClick={(e) => e.stopPropagation()}
      />
      <label
        htmlFor={`checkbox-${id}`}
        className={`no-select`}
        onClick={(e) => e.stopPropagation()}
        style={maxWidth ? { maxWidth: `${maxWidth}` } : null}
      >
        {label}
      </label>
    </div>
  );
};

export default CheckBoxBoolean;

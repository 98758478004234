//Hooks

import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//lanugae

const DemoSearchSummary = ({
  width,
  searchCategory,
  searchDirection,
  searchFormat,
  view,
  myEventsView,
}) => {
  //Hooks
  const { SettingsEventsLanguage } = useLanguageSettings();

  //Component variables
  let searchSummary;
  let formatSummary;

  if (searchCategory === 'distance') {
    if (searchDirection === 'ascending') {
      searchSummary = `${SettingsEventsLanguage.closest} ${SettingsEventsLanguage.distance}`;
    }
    if (searchDirection === 'descending') {
      searchSummary = `${SettingsEventsLanguage.furthest} ${SettingsEventsLanguage.distance}`;
    }
  }

  if (searchCategory === 'date') {
    if (searchDirection === 'ascending') {
      searchSummary = `${SettingsEventsLanguage.earliest} ${SettingsEventsLanguage.date}`;
    }
    if (searchDirection === 'descending') {
      searchSummary = `${SettingsEventsLanguage.latest} ${SettingsEventsLanguage.date}`;
    }
  }

  if (view !== 'myevents') {
    if (searchFormat === 'inPerson') {
      formatSummary = `${SettingsEventsLanguage.inPersonEvents}`;
    } else if (searchFormat === 'online') {
      formatSummary = `${SettingsEventsLanguage.onlineEvents}`;
    }
  }

  return (
    <div
      className={`demo-search-summary mrg-tb24 fwsb color-gray no-select access-ob access-o12 no-mrg-lr`}
      tabIndex="0"
    >
      <p className="fwb">{formatSummary}</p>&nbsp;
      {width <= 848 && <br />}
      {view !== 'myevents' && (
        <>
          <p>{SettingsEventsLanguage.by}</p>&nbsp;
        </>
      )}
      <p className="fwb">{searchSummary}</p>
    </div>
  );
};

export default DemoSearchSummary;

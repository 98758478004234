import { useEffect, useState } from 'react';

//hooks
import { useAdminSettingsHandlers } from '../../settings/useAdminSettingsHandlers';
import useAdminSupportEventVerificationHandlers from './useAdminSupportEventVerificationHandlers';
import { useEventVerificationsSocket } from '../../../../context/sockets/admin/EventVerificationsSocketProvider';

//language
import useLanguageAdmin from '../../../../language/features/useLanguageAdmin';

//Components
import Table from '../../../../components/Table/Table';

//Utils
import { DateFromTimeStamp } from '../../../../utils/dateTime/DateCalc';
import { img_checklist } from '../../../../assets/icons';

const EventVerification = ({
  eventVerificationsData,
  isLoading,
  isFetching,
  handleCardRowSelection,
  currentViewDetailsSelections,
}) => {
  //hooks
  const {
    connectEventVerificationsSocket,
    closeEventVerificationsSocket,
    eventVerificationSocketStatus,
  } = useEventVerificationsSocket();
  const {
    handleAdminSettingsEventVerificationsHeadingsSize,
    adminSettingsEventVerificationsHeadingsSize,
    handleAdminSettingsEventVerificationsSubHeadingsSize,
    adminSettingsEventVerificationsSubHeadingsSize,
    adminSettingsEventVerificationsSortingDate,
    handleAdminSettingsResetEventVerificationsHeadingsSize,
    handleAdminSettingsResetEventVerificationsSubHeadingsSize,
  } = useAdminSettingsHandlers();
  const { handleAdminSupportEventVerificationsDateSorting } =
    useAdminSupportEventVerificationHandlers();

  //language
  const { EventVerificationStatus } = useLanguageAdmin();

  //state
  const [formattedEventVerificationsData, setFormattedEventVerificationsData] =
    useState([]);

  useEffect(() => {
    connectEventVerificationsSocket();

    return () => {
      closeEventVerificationsSocket();
    };
  }, []); //web socket for eventVerifications

  //variables
  useEffect(() => {
    let newEventVerificationsData = [];

    eventVerificationsData?.map((ev) => {
      const lastActionEntry =
        ev?.verificationHistory[ev?.verificationHistory?.length - 1];

      let formattedTicket = {
        data1: ev?.verifications?.currentVerifications?.length,
        data2: ev?.verifications?.requiredVerifications,
        data3: EventVerificationStatus[lastActionEntry?.action],
        data4: `${DateFromTimeStamp(ev?.event?.eventId?.meta?.lastModified, {
          weekday: 'off',
          month: 'short',
        })}`,
        data5: ev?.event?.eventId?.meta?.tickets?.length,
        data6: ev?.event?.eventReference,
        id: ev?._id,
      };
      //need to keep last data field in obj as _id, hide it in the table with hiddenColumn so that way it can be used for selection handler
      return newEventVerificationsData.push(formattedTicket);
    });

    if (newEventVerificationsData?.length > 0) {
      setFormattedEventVerificationsData(newEventVerificationsData);
    } else {
      //add blank data to fill table
      setFormattedEventVerificationsData([
        {
          data0: '',
          data1: '',
          data2: '',
          data3: '',
          data4: '',
          data5: '',
          data6: '',
          data7: '',
        },
      ]);
    }
  }, [eventVerificationsData]); //prep data for table

  //functions
  return (
    <Table
      id={'eventVerificationsTable'}
      data={formattedEventVerificationsData}
      handleViewDetails={
        eventVerificationsData?.length > 0 ? handleCardRowSelection : null
      }
      viewDetailsText={'Select Event Verification'}
      currentViewDetailsSelections={currentViewDetailsSelections}
      tableTitle={'Event Verification'}
      tableTitleIcon={img_checklist}
      parentHeadings={[
        '',
        'Verifications',
        'Last Action',
        'Last Modified (UTC)',
        'Event Tickets',
        'Event Reference',
      ]}
      subHeadingPos={[1]}
      loadTableHeadings={adminSettingsEventVerificationsHeadingsSize}
      handleSetTableHeadings={handleAdminSettingsEventVerificationsHeadingsSize}
      subHeadings={[['Current', 'Required']]}
      loadTableSubHeadings={adminSettingsEventVerificationsSubHeadingsSize}
      handleSetTableSubHeadings={
        handleAdminSettingsEventVerificationsSubHeadingsSize
      }
      hiddenColumns={[6]}
      isLoadingData={isLoading}
      isFetchingData={isFetching}
      sortingHandlers={[{ 3: handleAdminSupportEventVerificationsDateSorting }]}
      sortingState={[
        {
          3:
            adminSettingsEventVerificationsSortingDate === 'ascending'
              ? true
              : false,
        },
      ]}
      handleResetTableSizes={() => {
        handleAdminSettingsResetEventVerificationsHeadingsSize();
        handleAdminSettingsResetEventVerificationsSubHeadingsSize();
      }}
      connectionStatusTrackerOn={true}
      connectionStatus={eventVerificationSocketStatus}
    />
  );
};

export default EventVerification;

//Hooks

//Components

//Utility
export const EventCardSkeleton = ({ index }) => {
  return (
    <div className="ec-skeleton__container" key={`eventCardSkeleton-${index}`}>
      <div className="ec__header " />
      <div className="ec__image skeleton" />
      <div className="ec__data skeleton" />
      <div className="ec__icon skeleton" />
      <div className="ec__icon skeleton" />
      <div className="ec__icon skeleton" />
    </div>
  );
};

export const EventGridSkeleton = ({ skeletons }) => {
  let content = Array.from({ length: skeletons }, (_, index) => (
    <EventCardSkeleton key={`skeleton-${index}`} index={index} />
  ));

  return <>{content}</>;
};

//used to fill in grid and prevent cards from auto centering
//-1 used to account for the search completed card.
export function GhostCards(eventsLength, cards) {
  if (eventsLength < cards) {
    let ghosts = cards - eventsLength - 1;
    let ghostsArr = [];

    for (let i = 0; i <= ghosts - 1; i++) {
      ghostsArr.push(<div className="ec__ghost" key={`eventGhostCard-${i}`} />);
    }
    return ghostsArr;
  }
}

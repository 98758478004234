import { useState } from 'react';

//Hooks
import { useGenericModal } from '../../../../context/GenericModalProvider';

//Components
import ReportModal from '../../../../components/Modal/ModalComponents/ReportModal/ReportModal';

//Utility
import { flag } from '../../../../assets/icons';

const ReportEvent = ({ handleMobileTap, EventPage, eventData }) => {
  //Hooks
  const { setGenericModal } = useGenericModal();

  //UI state
  const [tapHighlightReport, setTapHighlightReport] = useState(false);

  return (
    <div className="mrg-auto-lr mrg-b24">
      <button
        id="report"
        className={`highlight-i-lgr pad-tb6 pad-lr12 flex-row flex-center mrg-auto-left ${
          tapHighlightReport ? 'color-red highlight-t-red--tap' : 'color-gray'
        }`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightReport(true),
              () => setTapHighlightReport(false),
            ],
            [
              () =>
                setGenericModal(
                  <ReportModal type={'event'} targetData={eventData} />
                ),
            ]
          )
        }
      >
        <p className="fwsb fs16 mrg-r12">{EventPage.report}</p>
        <img src={flag} alt="flag" style={{ height: '24px' }} />
      </button>
    </div>
  );
};

export default ReportEvent;

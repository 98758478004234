import { useState, useRef, useEffect } from 'react';

//Hooks
import { useApiStatus } from '../../context/ApiStatusProvider';
import { useSettings } from '../../context/SettingsProvider';

const ButtonToggle = ({
  id,
  title,
  handleSwitch,
  loadTrigger,
  loadValue,
  ariaDescriptiveLabel,
  horizontal,
  temporaryDisable,
  temporaryDisableMessage,
  textLeft,
  textRight,
  customWrapper,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { handleMobileTap } = useSettings();

  //Component State
  const [active, setActive] = useState(false);

  //UI state
  const [tapHighlightTextLeft, setTapHighlightTextLeft] = useState(false);
  const [tapHighlightTextRight, setTapHighlightTextRight] = useState(false);
  const [animationsOn, setAnimationsOn] = useState(false);

  //Component variables
  const initializeEffectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    if (loadTrigger) {
      if (initializeEffectRan.current === false) {
        if (loadValue) {
          setActive(loadValue);
        }
        initializeEffectRan.current = true;
      }
    }
    /*eslint-disable-next-line*/
  }, [loadTrigger]);

  useEffect(() => {
    const animationTimer = setTimeout(() => {
      if (!animationsOn) {
        setAnimationsOn(true);
      }
    }, 500);

    return () => clearTimeout(animationTimer);
  }, []); //prevent the button's animations on load

  //Component functions
  function handleClick(e, side) {
    e.preventDefault();
    if (side === 'right') {
      handleMobileTap(
        [
          () => setTapHighlightTextRight(true),
          () => setTapHighlightTextRight(false),
        ],
        [() => setActive(true), () => handleSwitch(true)]
      );
    } else if (side === 'left') {
      handleMobileTap(
        [
          () => setTapHighlightTextLeft(true),
          () => setTapHighlightTextLeft(false),
        ],
        [() => setActive(false), () => handleSwitch(false)]
      );
    } else {
      setActive(!active);
      handleSwitch(!active);
    }
  }

  return (
    <div
      className={`btn-toggle__wrapper no-select ${
        horizontal ? 'btn-toggle-horizontal' : ''
      }  ${temporaryDisable ? 'opacity-03' : ''} ${
        customWrapper ? customWrapper : ''
      }`}
    >
      <p
        className={`btn-toggle__description  mrg-r24 ${
          tapHighlightTextLeft ? 'highlight-it-theme--tap' : ''
        } `}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning({
                  message: temporaryDisableMessage,
                  statusCode: 500,
                  origin: `ButtonToggle.js/${id}`,
                })
            : (e) => handleClick(e, 'left')
        }
      >
        {textLeft}
      </p>

      <button
        id={id}
        name={id}
        type="checkbox"
        className={`btn-toggle access-ob access-o6 ${
          active ? ' btn-toggle--active' : ''
        }`}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning({
                  message: temporaryDisableMessage,
                  statusCode: 500,
                  origin: `ButtonToggle.js/${id}`,
                })
            : (e) => handleClick(e)
        }
        aria-label={ariaDescriptiveLabel ? ariaDescriptiveLabel : title}
        role="switch"
        aria-checked={active}
      >
        <div
          className={`btn-toggle__circle ${
            active ? ' btn-toggle__circle--active' : ''
          }`}
          style={{ transition: `${animationsOn ? '0.4s' : '0s'}` }}
        />
      </button>
      <p
        className={`btn-toggle__description mrg-l24 ${
          tapHighlightTextRight ? 'highlight-it-theme--tap' : ''
        } `}
        onClick={
          temporaryDisable
            ? () =>
                handleWarning({
                  message: temporaryDisableMessage,
                  statusCode: 500,
                  origin: `ButtonToggle.js/${id}`,
                })
            : (e) => handleClick(e, 'right')
        }
      >
        {textRight}
      </p>
    </div>
  );
};

export default ButtonToggle;

import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//language
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import TranslationRoomCard from './TranslationRoomCard';
import ButtonOrder from '../../../../../components/ButtonOrder/ButtonOrder';

//utilities

const TranslationRoomCodeManager = ({ handleResetPage, pageReset }) => {
  //hooks
  const { accountTranslationRoomsRoomCodes: loadTranslationRoomCodes } =
    useAccountReduxHandlers();

  const { TranslationRoomCodeManager } = useLanguageAccount();

  //state
  const [sortedRoomCodes, setSortedRoomCodes] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');

  //ui

  //variables

  useEffect(() => {
    let newSortedRoomCodes;

    if (sortOrder === 'asc') {
      newSortedRoomCodes = [...loadTranslationRoomCodes].sort((a, b) => {
        if (a.roomCode < b.roomCode) return -1;
        if (a.roomCode > b.roomCode) return 1;
        return 0;
      });
    } else {
      newSortedRoomCodes = [...loadTranslationRoomCodes].sort((a, b) => {
        if (a.roomCode < b.roomCode) return 1;
        if (a.roomCode > b.roomCode) return -1;
        return 0;
      });
    }

    setSortedRoomCodes(newSortedRoomCodes);
  }, [loadTranslationRoomCodes, sortOrder, pageReset]);

  return (
    <>
      {!pageReset && (
        <div className="section mrg-t24">
          <div className="flex-row space-between">
            <label
              className="content-heading-styled text-gray flex-row"
              tabIndex="0"
              id="translationRoomsLabel"
            >
              {TranslationRoomCodeManager.roomCodeManager}
            </label>
            <ButtonOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
          </div>

          <div className="account-card__wrapper mrg-t24">
            {sortedRoomCodes?.map((translationRoomCode, index) => {
              return (
                <TranslationRoomCard
                  translationRoomCode={translationRoomCode}
                  key={`acct-tr-container-${index}`}
                  customWrapper={
                    index + 1 !== translationRoomCode?.length ? 'mrg-b24' : ''
                  }
                  handleResetPage={handleResetPage}
                />
              );
            })}
          </div>

          <div className="h96" />
        </div>
      )}
    </>
  );
};

export default TranslationRoomCodeManager;

import { createContext, useContext, useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
import { v4 as uuidv4 } from 'uuid';

//Hooks
import { useApiStatus } from '../../ApiStatusProvider';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useTranslationsHandlers } from '../../../features/main/translations/useTranslationsHandlers';

//utility
import { axiosLimited } from '../../../axios/axios';

//Reminder: any code inside the socket becomes permanent upon initialization; cannot use conditional variables outside the socket code or even conditional variables inside functions called from inside the socket code.

const TranslationRoomSocketContext = createContext({});

export const TranslationRoomSocketProvider = ({ children }) => {
  //Hooks
  const { handleWarning, handleError } = useApiStatus();
  const {
    handleTranslationsLogout,
    handleTranslationsRestoreSession,
    translationsLogoutIsLoading,
  } = useTranslationsHandlers();

  //connection state
  const [translationRoomSocketStatus, setTranslationRoomSocketStatus] =
    useState('closed');

  const [
    translationsConnectionIsInitializing,
    setTranslationsConnectionIsInitializing,
  ] = useState(false);
  const [userSessionIdTranslations, setUserSessionIdTranslations] =
    useLocalStorage('userSessionIdTranslations', '');

  //Nav
  const [createRoomPage, setCreateRoomPage] = useState('home');
  const [isLoadingCreatePage, setIsLoadingCreatePage] = useState(false);
  const [subPage, setSubPage] = useState(0);

  //Host
  const [roomCode, setRoomCode] = useState();
  const [hostTimeZone, setHostTimeZone] = useState();
  const [password, setPassword] = useState(); //password required by host
  const [sessionSaveName, setSessionSaveName] = useState('');
  const [sessionToken, setSessionToken] = useState('');
  const [restoreData, setRestoreData] = useState();
  const [restoreDataStatus, setRestoreDataStatus] = useState(false);
  const [restoreFromReconnect, setRestoreFromReconnect] = useState(false);
  const [joinRoomPasswordToggle, setJoinRoomPasswordToggle] = useState(false);

  const [defaultAttendeePassword, setDefaultAttendeePassword] = useState();
  const [attendeeJoinRoomPassword, setAttendeeJoinRoomPassword] = useState();

  //host - drafts
  const [savedTranslationsRoomData, setSavedTranslationsRoomData] = useState(
    {}
  );
  const [selectedSaveDraftId, setSelectedSaveDraftId] = useState();

  //host transcripts
  const [saveTranslationTranscript, setSaveTranslationTranscript] =
    useState(true);
  const [translationsTranscripts, setTranslationsTranscripts] = useState([]);

  //attendee password settings
  const [attendeeAuthToken, setAttendeeAuthToken] = useLocalStorage(
    'attendeeAuthToken',
    ''
  );
  const [attendeeRequiresPassword, setAttendeeRequiresPassword] =
    useState(false); //set by hosted socket data used on attendee side only
  const [attendeePasswordInput, setAttendeePasswordInput] = useState();

  //socket room
  const [isHostPresent, setIsHostPresent] = useState(false);
  const [occupantsNum, setOccupantsNum] = useState();

  //Translations Room
  const [roomCodeId, setRoomCodeId] = useState();
  const [roomLanguages, setRoomLanguages] = useState([]);
  const [translationLang, setTranslationLang] = useState('select'); //also used for host text translation lang (display)
  const [translationData, setTranslationData] = useState([]);
  const [hostTranslationData, setHostTranslationData] = useState([]);
  const [reportTranslationData, setReportTranslationData] = useState([]);
  const [currentSpeaker, setCurrentSpeaker] = useState();
  const [speakerCount, setSpeakerCount] = useState(1);
  const [speakersData, setSpeakersData] = useState([
    {
      name: '',
      discussionContext: '',
      keywordsList: [],
      languageOpt: 'select',
      voiceOpt: 'select',
      id: Date.now(),
    },
  ]);

  //variables
  let logsOn = false;
  let ioTranslationRoomURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/translation'
      : 'https://www.myndfull.com/ws/translation';

  //host queue
  let hostActionQueue = useRef([]);
  let isDispatchingFromQueue = useRef(false);
  let hostActionQueueInterval = useRef(null); //interval must be ref for useEffect
  let hostActionQueueRetryInterval;

  let socketRef = useRef(null);
  let inSession = useRef(false);
  let isHostRef = useRef(null);

  let keywordsListLengthMaximum = 50;
  let HOST_LEFT_KICK_TO_STAGING_ROOM_DELAY = 30000;
  let hostLeftTimer;

  ///Reconnects
  const RECONNECT_INTERVAL = 2000;
  const MAX_RECONNECT_RETRIES = 8;
  let connectionRetryInterval;

  //check for duplicate text
  let checkTextForDuplicates = useRef([]);
  let MAX_ENTRIES_FOR_CHECK_DUPLICATES = 10;

  //initialize
  useEffect(() => {
    if (!userSessionIdTranslations) {
      const sessionId = uuidv4();
      setUserSessionIdTranslations(sessionId);
    }

    return () => {
      closeTranslationRoomSocket({
        source: 'TranslationRoomSocketProvider unmount useEffect.',
      });
    };
  }, []); //gerenate sessionId + close socket.

  useEffect(() => {
    if (restoreFromReconnect && restoreData) {
      handleRestoreData('reconnect');
    }
  }, [restoreFromReconnect, restoreData]);

  function handleCreatePageChange(page) {
    setIsLoadingCreatePage(true);
    setCreateRoomPage(page);
  }

  const connectTranslationRoomSocket = (
    isHost,
    newRoomCode,
    userSessionId,
    translationsAuth
  ) => {
    //ensure socket is disconnected if it already exists
    if (socketRef.current) {
      socketRef.current.off();
      socketRef.current.disconnect();
      socketRef.current = null;
    }

    let isHostPresentRef = false;
    let attendeeAuthTokenRef = '';
    isHostRef.current = isHost;

    let hostReconnectRef = false;
    let attendeeReconnectRef = false;

    //need to pass roomcode directly as argument to make sure its formatted
    if (
      !socketRef.current &&
      translationRoomSocketStatus !== 'connected' &&
      !translationsConnectionIsInitializing &&
      newRoomCode?.length > 3 &&
      newRoomCode?.length <= 12 &&
      userSessionId
    ) {
      if (inSession.current === false) {
        setTranslationsConnectionIsInitializing(true);
      }

      setRoomCode(newRoomCode);

      socketRef.current = io(ioTranslationRoomURL, {
        query: {
          roomCode: newRoomCode,
          userSessionIdTranslations: userSessionId,
          isHost,
        },
        auth: {
          token: translationsAuth,
        },
        reconnectionAttempts: 10,
      });

      //Host
      socketRef.current.on('hostRestoreData', ({ hostRestoreObj }) => {
        logsOn && console.log('hostRestoreData');

        setTranslationRoomSocketStatus('connected');
        clearInterval(connectionRetryInterval);
        connectionRetryInterval = null;

        let restoreObj;

        if (hostRestoreObj) {
          try {
            restoreObj = JSON.parse(hostRestoreObj);
          } catch (error) {
            if (process.env.ENV === 'development')
              console.error('DEV: hostRestoreData parsing failed.', error);
          }
        }

        if (restoreObj?.sessionInitialized) {
          if (restoreObj?.passwordRequired) {
            handleTranslationsRestoreSession({
              roomCode,
              sessionToken: restoreObj?.sessionToken,
              setAttendeeJoinRoomPassword,
            });
          }

          setRestoreData(restoreObj);
          setSessionToken(restoreObj?.sessionToken);
          setRestoreDataStatus('restoreDataFound');
        } else {
          setRestoreDataStatus('restoreDataNotFound');
        }

        if (inSession.current === false) {
          inSession.current = true;
          handleCreatePageChange('createMainMenu');
        }
      });

      socketRef.current.on('connect', () => {
        logsOn && console.log('connect');

        if (isHost) {
          if (!hostReconnectRef) {
            handleSendHostRestoreData();
          } else {
            handleHostReconnectionEvent();
          }
        } else {
          handleAttendeeReconnectionEvent(
            attendeeAuthTokenRef,
            attendeeReconnectRef
          );
        }
      });

      socketRef.current.on('hostNoRestoreConnection', () => {
        logsOn && console.log('hostNoRestoreConnection');
        setTranslationRoomSocketStatus('connected');

        clearInterval(connectionRetryInterval);
        connectionRetryInterval = null;

        handleCreatePageChange('createMainMenu');
      });

      socketRef.current.on('hostReconnect', ({ hostRestoreObj }) => {
        logsOn && console.log('hostReconnect');
        setTranslationRoomSocketStatus('connected');

        clearInterval(connectionRetryInterval);
        connectionRetryInterval = null;

        let restoreObj;

        if (hostRestoreObj) {
          try {
            restoreObj = JSON.parse(hostRestoreObj);
          } catch (error) {
            logsOn &&
              console.error('DEV: hostReconnect parsing failed.', error);
          }
        }

        setRestoreData(restoreObj);
        setRestoreFromReconnect(true);
        setSessionToken(restoreObj?.sessionToken);
      });

      socketRef.current.on(
        'hostJoined',
        ({ currentRoomLanguages, currentPasswordRequired }) => {
          // logsOn && console.log('hostJoined');
          if (hostLeftTimer) {
            clearTimeout(hostLeftTimer);
          }

          setAttendeeRequiresPassword(currentPasswordRequired);
          setRoomLanguages(currentRoomLanguages);
          setIsHostPresent(true);
          isHostPresentRef = true;
        }
      ); //hostJoined

      socketRef.current.on('roomHasNewHost', () => {
        logsOn && console.log('roomHasNewHost');
        handleWarning({
          message: 'roomHasNewHost',
          statusCode: 500,
          origin: 'TranslationRoomSocketProvider.js',
        });
        translationRoomSocketCleanUp();
      });

      socketRef.current.on('hostLeft', () => {
        logsOn && console.log('hostLeft');
        setIsHostPresent(false);
        isHostPresentRef = false;
        if (!isHost) {
          hostLeftTimer = setTimeout(() => {
            setSubPage(0);
            setRoomLanguages([]);
            setTranslationLang();
            socketRef.current.emit('removeAttendeeAuth');
          }, HOST_LEFT_KICK_TO_STAGING_ROOM_DELAY);
        }
      });

      socketRef.current.on('hostSuccess', () => {
        logsOn && console.log('hostSuccess');
        setTranslationRoomSocketStatus('connected');
        setTranslationsConnectionIsInitializing(false);
      });

      socketRef.current.on('reauthAttendees', () => {
        logsOn && console.log('reauthAttendees');
        if (!isHost) {
          setCurrentSpeaker();
          setTranslationLang();
          setSubPage(0);
        }
      });

      socketRef.current.on('roomIsHosted', () => {
        logsOn && console.log('roomIsHosted');
        handleWarning({
          message: 'roomCodeAlreadyHosted',
          origin: 'TranslationRoomSocketProvider/roomIsHosted',
          id: Date.now(),
        });
        closeTranslationRoomSocket({
          source: 'connectTranslationRoomSocket/roomIsHosted',
        });
      });

      socketRef.current.on('hostTranslationData', (newHostTranslationData) => {
        logsOn && console.log('hostTranslationData', newHostTranslationData);
        handleNewTranscriptData(newHostTranslationData, isHost);
      });

      socketRef.current.on('speakerChanged', (newCurrentRoomSpeaker) => {
        logsOn && console.log('speakerChanged');
        const currentSpeakerData = Object.values(newCurrentRoomSpeaker);

        setCurrentSpeaker(currentSpeakerData[0]);
      });

      socketRef.current.on(
        'speakerUpdated',
        ({ newCurrentRoomSpeaker, newSpeakersData }) => {
          logsOn && console.log('speakerUpdated');
          setSpeakersData(newSpeakersData);
          setCurrentSpeaker(newCurrentRoomSpeaker);
        }
      ); //speakerUpdated

      socketRef.current.on(
        'roomSuccess',
        ({
          roomIsHosted,
          currentRoomLanguages,
          currentRoomSpeaker,
          passwordRequired,
          authToken,
          reconnect,
        }) => {
          logsOn && console.log('roomSuccess');

          clearInterval(connectionRetryInterval);
          connectionRetryInterval = null;

          if (roomIsHosted) {
            setIsHostPresent(true);
            isHostPresentRef = true;
          }
          if (currentRoomLanguages) {
            setRoomLanguages(currentRoomLanguages);
          }
          if (currentRoomSpeaker) {
            setCurrentSpeaker(currentRoomSpeaker);
          }

          if (passwordRequired) {
            setAttendeeRequiresPassword(true);
          }

          setTranslationsConnectionIsInitializing(false);
          setTranslationRoomSocketStatus('connected');

          if (reconnect && authToken) {
            attendeeEnterRoom(authToken, reconnect);
          }
        }
      ); //roomSuccess

      /////////////
      socketRef.current.on('occupantsCountUpdate', (occupantsCount) => {
        // logsOn && console.log('occupantsCountUpdate');
        setOccupantsNum(occupantsCount);
      });

      socketRef.current.on(
        'attendeeAuthenticated',
        ({ confirmedAttendeeAuthToken, confirmedTranslationLang }) => {
          logsOn && console.log('attendeeAuthenticated');
          attendeeReconnectRef = false;
          attendeeAuthTokenRef = confirmedAttendeeAuthToken;

          if (isHostPresentRef && confirmedTranslationLang) {
            if (!translationLang) setTranslationLang(confirmedTranslationLang);
            setSubPage(1);
          }
        }
      ); //attendeeAuthenticated

      socketRef.current.on(
        'attendeeTranslationData',
        (attendeeTranslationData) => {
          logsOn && console.log('attendeeTranslationData');
          handleNewTranscriptData(attendeeTranslationData, isHost);
        }
      ); //attendeeTranslationData

      //Error + Reconnection handlers
      socketRef.current.on('error', (error) => {
        logsOn && console.log('error');
        logsOn && console.log(`Dev Socket: error ${error}.`);
      });

      socketRef.current.on('serverError', () => {
        logsOn && console.log('serverError');
        closeTranslationRoomSocket({
          source: 'connectTranslationRoomSocket/serverError',
        });
        handleError({
          id: Date.now(),
          origin:
            'TranslationRoomSocketProvider/connectTranslationRoomSocket/serverError',
          message: 'internalServerError',
        });
      });

      socketRef.current.on('reconnect', () => {
        logsOn && console.log('reconnect');

        if (isHost) {
          handleHostReconnectionEvent();
        } else {
          handleAttendeeReconnectionEvent(
            attendeeAuthTokenRef,
            attendeeReconnectRef
          );
        }
      });

      socketRef.current.on('reconnect_failed', () => {
        logsOn && console.log('reconnect_failed');
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
        closeTranslationRoomSocket({
          source: 'connectTranslationRoomSocket/reconnect_failed',
        });
      });

      socketRef.current.on('authenticationError', () => {
        logsOn && console.log('authenticationError');
        closeTranslationRoomSocket({
          source: 'connectTranslationRoomSocket/authenticationError',
        });
        handleError({
          message: 'authenticationError',
          id: Date.now(),
          origin:
            'TranslationRoomSocketProvider/connectTranslationRoomSocket/authenticationError',
        });
      });

      socketRef.current.on('server_not_ready', () => {
        logsOn && console.log('server_not_ready');

        if (isHost) {
          handleHostReconnectionEvent();
        } else {
          handleAttendeeReconnectionEvent(
            attendeeAuthTokenRef,
            attendeeReconnectRef
          );
        }
      });

      socketRef.current.on('disconnect', (reason, details) => {
        logsOn &&
          console.log(
            `TranslationRoom socket disconnect, Reason: ${reason}, Details: ${details}`
          );

        setTranslationRoomSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn && console.log('no automatic reconnect');
          closeTranslationRoomSocket({
            checkSaveTranscript: isHost ? true : false,
            source:
              'connectTranslationRoomSocket/disconnect/reason io client or server disconnect',
          });
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn && console.log('automatic reconnect');
          if (isHost) {
            hostReconnectRef = true;
          } else {
            attendeeReconnectRef = true;
          }
        } else {
          logsOn && console.error(`Unexpected disconnect reason: ${reason}`);
          closeTranslationRoomSocket({
            checkSaveTranscript: isHost ? true : false,
            source:
              'connectTranslationRoomSocket/disconnect/unexpected disconnect reason',
          });
        }
      });
    }
  };

  const handleSendHostRestoreData = () => {
    logsOn && console.log('handleHostReconnectionEvent');

    const socket = socketRef.current;
    let CURRENT_ATTEMPT = 0;

    if (connectionRetryInterval) {
      clearInterval(connectionRetryInterval);
      connectionRetryInterval = null;
    }

    connectionRetryInterval = setInterval(() => {
      if (CURRENT_ATTEMPT < MAX_RECONNECT_RETRIES) {
        CURRENT_ATTEMPT++;
        socket.emit('sendHostRestoreData', {}, (ack) => {
          if (ack) {
            logsOn && console.log('handleSendHostRestoreData ack received');
          }
        });
      } else {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
        closeTranslationRoomSocket({
          source: 'handleHostReconnectionEvent',
        });
      }
    }, RECONNECT_INTERVAL);
  };

  const handleHostReconnectionEvent = () => {
    logsOn && console.log('handleHostReconnectionEvent');

    const socket = socketRef.current;
    let CURRENT_ATTEMPT = 0;

    if (connectionRetryInterval) {
      clearInterval(connectionRetryInterval);
      connectionRetryInterval = null;
    }

    connectionRetryInterval = setInterval(() => {
      if (CURRENT_ATTEMPT < MAX_RECONNECT_RETRIES) {
        CURRENT_ATTEMPT++;
        socket.emit('hostReconnect', {}, (ack) => {
          if (ack) {
            logsOn && console.log('handleHostReconnectionEvent ack received');
          }
        });
      } else {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
        closeTranslationRoomSocket({
          source: 'handleHostReconnectionEvent',
        });
      }
    }, RECONNECT_INTERVAL);
  };

  const handleAttendeeReconnectionEvent = (authToken, reconnect) => {
    logsOn && console.log('handleAttendeeReconnectionEvent');

    if (!authToken && reconnect) {
      closeTranslationRoomSocket();
      return handleError({
        message: 'authenticationError',
        id: Date.now(),
        origin:
          'TranslationRoomSocketProvider/connectTranslationRoomSocket/authenticationError',
      });
    }

    const socket = socketRef.current;
    let CURRENT_ATTEMPT = 0;

    if (connectionRetryInterval) {
      clearInterval(connectionRetryInterval);
      connectionRetryInterval = null;
    }

    connectionRetryInterval = setInterval(() => {
      if (CURRENT_ATTEMPT < MAX_RECONNECT_RETRIES) {
        CURRENT_ATTEMPT++;

        socket.emit(
          'attendeeJoinRoom',
          {
            reconnect,
            authToken,
          },
          (ack) => {
            if (ack) {
              logsOn &&
                console.log('handleAttendeeReconnectionEvent ack received');
            }
          }
        );
      } else {
        handleError({
          message: 'connectionError',
          id: Date.now(),
          origin: 'TranslationRoomSocketProvider.js',
        });
        closeTranslationRoomSocket({
          source: 'handleHostReconnectionEvent',
        });
      }
    }, RECONNECT_INTERVAL);
  };

  function handleRestoreData(selection) {
    logsOn && console.log('handleRestoreData ran.');

    if (selection === 'ignore') {
      setRestoreData();
      setRestoreDataStatus(false);
      setRestoreFromReconnect(false);
      setRoomLanguages([]);
      setSessionToken();
      setSessionSaveName('');
      setIsHostPresent(false);
      setOccupantsNum();
      setTranslationLang('select');
      setTranslationData([]);
      setHostTranslationData([]);
      setReportTranslationData([]);
      setSaveTranslationTranscript(true);
      setCurrentSpeaker();
      setSpeakerCount(1);
      setSpeakersData([
        {
          name: '',
          discussionContext: '',
          keywordsList: [],
          languageOpt: 'select',
          voiceOpt: 'select',
          id: Date.now(),
        },
      ]);
    } else if (selection === 'restore' || selection === 'reconnect') {
      if (restoreFromReconnect) {
        setRestoreFromReconnect(false);
      }

      setRoomLanguages(restoreData.currentRoomLanguages);
      setSpeakersData(restoreData.speakersData);
      setHostTranslationData(restoreData.savedHostTranslationData);
      setCurrentSpeaker(restoreData.currentRoomSpeaker);
      setTranslationLang(restoreData.hostTextTranslationLang);
      setSaveTranslationTranscript(restoreData.saveTranslationTranscript);

      if (selection === 'restore') {
        hostCreateRoom(true);
      }
    }
  }

  const hostCreateRoom = async (restoreSession) => {
    const socket = socketRef.current;
    if (socket) {
      if (restoreSession) {
        try {
          socket.emit('hostCreateRoom', {
            isHost: true,
            roomLanguages: restoreData.currentRoomLanguages,
            speakersData: restoreData.speakersData,
            restoreSession: true,
          });
        } catch (error) {
          handleError({
            id: Date.now(),
            origin: 'TranslationRoomSocketProvider/hostCreateRoom',
            error: error?.message,
          });
        }
      } else {
        try {
          const response = await axiosLimited.post(
            '/api/main/auth/translations/hostRoom',
            {
              roomCode,
              attendeeJoinRoomPassword,
              joinRoomPasswordToggle,
            }
          );

          const newSessionToken = response?.data?.sessionToken;

          setSessionToken(newSessionToken);

          if (newSessionToken) {
            socket.emit('hostCreateRoom', {
              roomLanguages,
              speakersData,
              restoreSession: false,
              passwordRequired: joinRoomPasswordToggle,
              sessionToken: newSessionToken,
              saveTranslationTranscript,
            });
          } else {
            throw new Error('noSessionToken');
          }
        } catch (error) {
          handleError({
            id: Date.now(),
            origin: 'TranslationRoomSocketProvider/hostCreateRoom',
            error: error?.message,
          });
        }
      }
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.error('Dev WS hostCreateRoom: Socket is not connected.');
      }
    }
  };

  const getAttendeeAuthentication = async () => {
    try {
      const response = await axiosLimited.post(
        '/api/main/auth/translations/attendeeAuth',
        {
          translationLang,
          roomCode,
          attendeePasswordInput,
        }
      );
      if (response?.data?.attendeeAuthToken) {
        setAttendeeAuthToken(response?.data?.attendeeAuthToken);
        attendeeEnterRoom(response?.data?.attendeeAuthToken);
      }
    } catch (error) {
      setAttendeePasswordInput();
      const roomCodeInput = document.getElementById('roomCodePasswordInput');
      if (roomCodeInput) roomCodeInput.value = '';
      handleError({
        id: Date.now(),
        error: 'wrongPassword',
        origin: 'TranslationRoomSocketProvider.js/getAttendeeAuthentication',
      });
    }
  };

  const handleEjectAttendees = () => {
    const socket = socketRef.current;
    if (socket && translationRoomSocketStatus === 'connected') {
      socket.emit('ejectAttendees');
    }
  };

  const attendeeEnterRoom = (authToken, reconnect) => {
    const socket = socketRef.current;

    if (socket && (translationRoomSocketStatus === 'connected' || reconnect)) {
      socket.emit('authenticatedAttendeeEnterRoom', {
        attendeeAuthToken: authToken,
      });
    }
  };

  const handleNewTranscriptData = (newTranslationObj, isHost) => {
    let duplicateData = false;

    for (const translationData of checkTextForDuplicates?.current || []) {
      if (translationData?.timeStamp === newTranslationObj?.timeStamp) {
        duplicateData = true;
        break;
      }
    }

    if (!duplicateData) {
      if (checkTextForDuplicates.current?.length === 0) {
        checkTextForDuplicates.current = [
          {
            timeStamp: newTranslationObj.timeStamp,
          },
        ];
      } else {
        checkTextForDuplicates.current.push({
          timeStamp: newTranslationObj.timeStamp,
        });

        if (
          checkTextForDuplicates.current.length >
          MAX_ENTRIES_FOR_CHECK_DUPLICATES
        ) {
          checkTextForDuplicates.current.shift();
        }
      }
    }

    if (!duplicateData) {
      if (isHost) {
        setHostTranslationData((currentHostTranslationData) => [
          ...currentHostTranslationData,
          newTranslationObj,
        ]);
      } else {
        setTranslationData(newTranslationObj);
      }
    }
  };

  //host action queue functions
  useEffect(() => {
    if (isHostRef.current === true) {
      logsOn && console.log('Establishing new host action queue process.');

      if (
        translationRoomSocketStatus === 'connected' &&
        !hostActionQueueInterval.current
      ) {
        hostActionQueueInterval.current = setInterval(() => {
          hostActionQueueProcessing();
        }, 500);
      }
    }
    /*eslint-disable-next-line*/
  }, [translationRoomSocketStatus, socketRef.current]);

  const addToHostActionQueue = (action, actionData) => {
    const confirmationTimestamp = Date.now();
    let newHostActionQueue = [
      ...hostActionQueue.current,
      { confirmationTimestamp, action, actionData },
    ];
    hostActionQueue.current = newHostActionQueue;
  };

  const hostActionQueueProcessing = () => {
    const socket = socketRef.current;

    if (
      socket &&
      translationRoomSocketStatus === 'connected' &&
      !isDispatchingFromQueue.current &&
      hostActionQueue.current?.length > 0
    ) {
      const nextDispatch = hostActionQueue.current[0];

      if (!nextDispatch) return (isDispatchingFromQueue.current = false);

      let CURRENT_ATTEMPT = 0;

      if (hostActionQueueRetryInterval) {
        clearInterval(hostActionQueueRetryInterval);
        hostActionQueueRetryInterval = null;
      }

      isDispatchingFromQueue.current = true;

      hostActionQueueRetryInterval = setInterval(() => {
        if (CURRENT_ATTEMPT < MAX_RECONNECT_RETRIES) {
          CURRENT_ATTEMPT++;

          hostActionDispatch(nextDispatch);
        }
      }, RECONNECT_INTERVAL);
    }
  };

  const hostActionDispatch = (nextDispatch) => {
    const socket = socketRef.current;

    if (translationRoomSocketStatus === 'closed' || !socket) {
      clearInterval(hostActionQueueRetryInterval);
      hostActionQueueRetryInterval = null;
      return;
    } else if (translationRoomSocketStatus === 'pending') {
      return;
    }

    if (nextDispatch.action === 'changeHostTextLanguage') {
      socket.emit(
        'changeHostTextLanguage',
        {
          translationLang: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        },
        (ack) => {
          if (ack) {
            console.log('ack', ack);
            hostActionQueueConfirmation(ack);
          }
        }
      );
    }

    if (nextDispatch.action === 'changeSpeaker') {
      socket.emit(
        'changeSpeaker',
        {
          speakerId: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        },
        (ack) => {
          if (ack) {
            console.log('ack', ack);
            hostActionQueueConfirmation(ack);
          }
        }
      );
    }

    if (nextDispatch.action === 'updateSpeakerData') {
      socket.emit(
        'updateSpeakerData',
        {
          updateSpeakerData: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        },
        (ack) => {
          if (ack) {
            console.log('ack', ack);
            hostActionQueueConfirmation(ack);
          }
        }
      );
    }

    if (nextDispatch.action === 'changeSaveTranscriptToggle') {
      socket.emit(
        'changeSaveTranscriptToggle',
        {
          changeSaveTranscriptToggle: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        },
        (ack) => {
          if (ack) {
            console.log('ack', ack);
            hostActionQueueConfirmation(ack);
          }
        }
      );
    }

    if (nextDispatch.action === 'translationData') {
      socket.emit(
        'translationData',
        {
          speechText: nextDispatch?.actionData,
          confirmationTimestamp: nextDispatch?.confirmationTimestamp,
        },
        (ack) => {
          if (ack) {
            console.log('ack', ack);
            hostActionQueueConfirmation(ack);
          }
        }
      );
    }
  };

  const hostActionQueueConfirmation = (confirmationObj) => {
    clearInterval(hostActionQueueRetryInterval);
    hostActionQueueRetryInterval = null;

    isDispatchingFromQueue.current = false;

    //this function is set within the socket so must have self-directing references
    const index = hostActionQueue.current.findIndex(
      (actionIndex) =>
        actionIndex.confirmationTimestamp ===
        confirmationObj?.confirmationTimestamp
    );

    if (index === -1) {
      if (logsOn) {
        return console.error(
          `hostActionQueueConfirmation ${confirmationObj?.confirmationTimestamp} not found.`
        );
      } else {
        return;
      }
    }

    let newHostActionQueue = [...hostActionQueue.current];
    newHostActionQueue.splice(index, 1);

    hostActionQueue.current = newHostActionQueue;
  };

  //HOST QUEUE ACTIONS
  const changeHostTextLanguage = (translationLang) => {
    logsOn && console.log('changeHostTextLanguage func ran.');

    addToHostActionQueue('changeHostTextLanguage', translationLang);
  };

  const changeSpeaker = (speakerId) => {
    logsOn && console.log('changeSpeaker func ran.');

    addToHostActionQueue('changeSpeaker', speakerId);
  };

  const changeSaveTranscriptToggle = (saveTranscript) => {
    setSaveTranslationTranscript(saveTranscript);

    logsOn && console.log('changeSaveTranscript func ran.');

    addToHostActionQueue('changeSaveTranscriptToggle', saveTranscript);
  };

  const updateSpeakerData = (updateSpeakerData) => {
    logsOn && console.log('updateSpeakerData func ran.');

    addToHostActionQueue('updateSpeakerData', updateSpeakerData);

    //update transcript with old speaker data for tracking in transcript
    if (saveTranslationTranscript) {
      for (let speaker of speakersData) {
        let updateSpeaker = JSON.parse(JSON.stringify(speaker));
        updateSpeaker.updatedAt = Date.now();
        if (speaker.id === updateSpeakerData.id) {
          handleUpdatedSpeakerDataForSaveTranscript(updateSpeaker);
        }
      }
    }
  };

  const sendTranslationData = (speechText) => {
    logsOn && console.log('sendTranslationData func ran.');
    addToHostActionQueue('translationData', speechText);
  };

  //SOCKET GENERAL
  const closeTranslationRoomSocket = async (args) => {
    const checkSaveTranscript = args?.checkSaveTranscript || false;
    const source = args?.source || '';

    logsOn && console.log('closeTranslationRoomSocket func ran.');
    logsOn && console.log('closeTranslationRoomSocket Source:', source);

    clearInterval(hostActionQueueInterval.current);
    clearInterval(connectionRetryInterval);
    clearInterval(hostActionQueueRetryInterval);
    hostActionQueueInterval.current = null;
    connectionRetryInterval = null;
    hostActionQueueRetryInterval = null;

    isHostRef.current = null;
    checkTextForDuplicates.current = [];

    setTranslationsConnectionIsInitializing(false);

    if (
      checkSaveTranscript &&
      saveTranslationTranscript &&
      sessionToken &&
      roomCode &&
      roomCodeId
    ) {
      await handleSaveTranscript({
        sessionToken,
        roomCode,
        roomCodeId,
        hostTranslationData,
        speakersData,
        sessionSaveName,
      });
    }

    const socket = socketRef.current;

    if (socket) {
      socket.disconnect();
      socketRef.current = null;
    }
    translationRoomSocketCleanUp();
    setTranslationRoomSocketStatus('closed');

    if (hostLeftTimer) {
      clearTimeout(hostLeftTimer);
    }
  };

  async function translationRoomSocketCleanUp() {
    logsOn && console.log('translationRoomSocketCleanUp ran.');
    let currentRoomCode = roomCode;

    checkTextForDuplicates.current = [];
    inSession.current = false;

    //Nav
    setSubPage(0);
    setCreateRoomPage('home');

    //attendee
    setAttendeeAuthToken();

    //host
    setRoomCodeId();
    setRoomCode();
    setHostTimeZone();
    setPassword();
    setRestoreData();
    setRestoreDataStatus(false);
    setRestoreFromReconnect(false);
    setSavedTranslationsRoomData();

    //Host Translations Room
    handleResetCurrentHostSessionData();

    //attendee password settings
    setAttendeeRequiresPassword(false);
    setAttendeePasswordInput();

    //socket room
    setIsHostPresent(false);
    setOccupantsNum();

    //transcripts
    setTranslationsTranscripts([]);

    //API logout to remove host cookie
    if (currentRoomCode && !translationsLogoutIsLoading) {
      await handleTranslationsLogout('translationRoomSocketProvider');
    }
  }

  function handleResetCurrentHostSessionData() {
    setRoomLanguages([]);
    setTranslationLang('select');
    setTranslationData([]);
    setHostTranslationData([]);
    setReportTranslationData([]);
    setSessionSaveName('');
    setSessionToken();
    setCurrentSpeaker();
    setSpeakerCount(1);
    setSpeakersData([
      {
        name: '',
        discussionContext: '',
        keywordsList: [],
        languageOpt: 'select',
        voiceOpt: 'select',
        id: Date.now(),
      },
    ]);
    setJoinRoomPasswordToggle(false);
    setDefaultAttendeePassword();
    setAttendeeJoinRoomPassword();

    hostActionQueue.current = [];
    isDispatchingFromQueue.current = false;
  }

  const getTranslationRoomSocket = () => {
    return socketRef.current;
  };

  //TRANSCRIPTS
  async function handleSaveTranscript(sessionData) {
    try {
      await axiosLimited.post('/api/public/translations/transcripts', {
        sessionToken: sessionData?.sessionToken || sessionToken,
        roomCode: sessionData?.roomCode || roomCode,
        roomCodeId: sessionData?.roomCodeId || roomCodeId,
        transcriptData: sessionData?.hostTranslationData || hostTranslationData,
        speakersData: sessionData?.speakersData || speakersData,
        sessionSaveName: sessionData?.sessionSaveName || sessionSaveName,
      });
    } catch (error) {
      //dont display error notice otherwise error notices will appear during disconnects in translation
      // handleError({
      //   id: Date.now(),
      //   origin: 'TranslationRoomSocketProvider/handleSaveTranscript',
      //   error: error?.message,
      // });
    }
  }

  async function handleUpdatedSpeakerDataForSaveTranscript(updatedSpeakerData) {
    try {
      await axiosLimited.post(
        '/api/public/translations/updateTranscriptSpeakerData',
        {
          sessionToken: sessionToken,
          roomCode: roomCode,
          roomCodeId: roomCodeId,
          updatedSpeakerData,
        }
      );
    } catch (error) {
      handleError({
        id: Date.now(),
        origin:
          'TranslationRoomSocketProvider/handleUpdatedSpeakerDataForSaveTranscript',
        error: error?.message,
      });
    }
  }

  async function handleGetTranscripts() {
    try {
      const response = await axiosLimited.post(
        '/api/public/translations/getTranscripts',
        {
          roomCode,
          roomCodeId,
        }
      );
      if (response?.data?.translationsTranscripts) {
        setTranslationsTranscripts(response?.data?.translationsTranscripts);
      }
    } catch (error) {
      handleError({
        id: Date.now(),
        origin: 'TranslationRoomSocketProvider/handleGetTranscripts',
        error: error?.message,
      });
    }
  }

  async function handleGetTranscript(transcriptId) {
    try {
      const response = await axiosLimited.post(
        '/api/public/translations/getTranscript',
        {
          roomCode,
          roomCodeId,
          transcriptId,
        }
      );

      if (response?.data?.translationsTranscript) {
        let newTranscriptsArr = translationsTranscripts?.map((transcript) => {
          if (transcript?._id === response?.data?.translationsTranscript?._id) {
            return response?.data?.translationsTranscript;
          } else {
            return transcript;
          }
        });
        setTranslationsTranscripts(newTranscriptsArr);

        return response?.data?.translationsTranscript;
      }
    } catch (error) {
      handleError({
        id: Date.now(),
        origin: 'TranslationRoomSocketProvider/handleGetTranscript',
        error: error?.message,
      });
    }
  }

  return (
    <TranslationRoomSocketContext.Provider
      value={{
        //connection state
        translationsConnectionIsInitializing,
        translationRoomSocketStatus,
        userSessionIdTranslations,

        //Nav
        createRoomPage,
        isLoadingCreatePage,
        setCreateRoomPage,
        setIsLoadingCreatePage,
        setSubPage,
        subPage,

        //Host
        attendeeJoinRoomPassword,
        defaultAttendeePassword,
        joinRoomPasswordToggle,
        password,
        sessionToken,
        sessionSaveName,
        setSessionSaveName,
        restoreData,
        restoreDataStatus,
        roomCode,
        hostTimeZone,
        setHostTimeZone,
        setAttendeeJoinRoomPassword,
        setDefaultAttendeePassword,
        setJoinRoomPasswordToggle,
        setPassword,
        setRestoreData,
        setRestoreDataStatus,
        setRoomCode,
        isHostRef,

        //host create room/drafts
        setSavedTranslationsRoomData,
        savedTranslationsRoomData,
        roomCodeId,
        setRoomCodeId,
        selectedSaveDraftId,
        setSelectedSaveDraftId,

        //host transcripts
        translationsTranscripts,
        setTranslationsTranscripts,
        saveTranslationTranscript,
        setSaveTranslationTranscript,
        handleSaveTranscript,
        handleGetTranscripts,
        handleGetTranscript,

        //attendee password settings
        attendeeAuthToken,
        attendeePasswordInput,
        attendeeRequiresPassword,
        setAttendeeAuthToken,
        setAttendeePasswordInput,
        setAttendeeRequiresPassword,

        //socket room
        isHostPresent,
        occupantsNum,

        //translations room
        currentSpeaker,
        hostTranslationData,
        reportTranslationData,
        roomLanguages,
        setCurrentSpeaker,
        setReportTranslationData,
        setRoomLanguages,
        setSpeakerCount,
        setSpeakersData,
        setTranslationData,
        setTranslationLang,
        speakerCount,
        speakersData,
        translationData,
        translationLang,

        //variables
        keywordsListLengthMaximum,

        //functions
        changeHostTextLanguage,
        changeSpeaker,
        closeTranslationRoomSocket,
        connectTranslationRoomSocket,
        getAttendeeAuthentication,
        getTranslationRoomSocket,
        handleCreatePageChange,
        handleEjectAttendees,
        handleRestoreData,
        hostCreateRoom,
        sendTranslationData,
        handleResetCurrentHostSessionData,
        updateSpeakerData,
        changeSaveTranscriptToggle,
      }}
    >
      {children}
    </TranslationRoomSocketContext.Provider>
  );
};

export const useTranslationRoomSocket = () => {
  return useContext(TranslationRoomSocketContext);
};

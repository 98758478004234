import { useState, useEffect } from 'react';
//Hooks
import { useUpdatePhotoMutation } from '../../accountApiSlice';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useAccountReduxHandlers } from '../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//Components
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import ChangeButton from '../../../../../components/ChangeButton/ChangeButton';
import Spinner from '../../../../../components/Spinner/Spinner';
import TextArea from '../../../../../components/TextArea/TextArea';

//Utility
import convertBase64ToJPEG from '../../../../../utils/images/convertBase64ToJPEG';
import { handleDBImageBlob } from '../../../../../utils/images/handleDBImageBlob';
import { retryWrapper } from '../../../../../utils/logic/retryFns';

const ProfilePhoto = ({ Account }) => {
  //Hooks
  const { handleError } = useApiStatus();
  const {
    accountId,
    accountProfileImage,
    accountProfileImageAlt,
    handleAccountSetProfileImageAlt,
  } = useAccountReduxHandlers();
  const [updateAccountPhoto, { isLoading: updateAccountPhotoIsLoading }] =
    useUpdatePhotoMutation();

  //language
  const { Generic } = useLanguageComponents();

  //Component state
  const [croppedImg, setCroppedImg] = useState({
    croppedImage: '',
    fileName: '',
    imagePreview: '',
  });
  const [convertingImage, setConvertingImage] = useState(false);
  const [changeProfileImage, setChangeProfileImage] = useState(false);
  const [profileImageAlt, setProfileImageAlt] = useState(
    accountProfileImageAlt || ''
  );
  const [isLoading, setIsLoading] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (
      accountProfileImage !== 'account_img_default.png' &&
      changeProfileImage
    ) {
      retryWrapper(initializeProfileImage);
    }
  }, [accountProfileImage, changeProfileImage]); //gets new blob for new profileImage or if user cancels change

  useEffect(() => {
    return () => {
      handleCancelSaveImage();
    };
  }, []);

  async function initializeProfileImage() {
    try {
      await handleDBImageBlob({
        imagePath: accountProfileImage,
        imageType: 'account',
        setImage: setCroppedImg,
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        handleError({
          origin: 'ProfilePhoto/initializeProfileImage',
          message: 'notFound',
          id: Date.now(),
        });
      }
    }
  }

  async function saveAccountProfilePhotoChange(e) {
    e.preventDefault();

    if (!updateAccountPhotoIsLoading) {
      setIsLoading(true);
      const formData = new FormData();
      const profileImage = croppedImg;
      let profileImgJPG;

      if (profileImage.croppedImage.croppedImage) {
        profileImgJPG = await convertBase64ToJPEG(
          profileImage.croppedImage.croppedImage,
          'profileImage.jpg'
        );
      }

      //5 mb max.
      if (profileImgJPG?.size >= 5242880) {
        return handleError({
          message: 'imageSizeTooLarge',
          id: Date.now(),
          origin: 'ProfilePhoto.js/saveAccountProfilePhotoChange',
        });
      }

      formData.append('profileImage', profileImgJPG);
      formData.append('accountId', accountId);
      formData.append('profileImageAlt', profileImageAlt);

      await updateAccountPhoto(formData);
      setChangeProfileImage(false);

      handleAccountSetProfileImageAlt(profileImageAlt);
      setIsLoading(false);
    }
  }

  //Component functions
  function handleProfileImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);
  }

  function handleCancelSaveImage() {
    if (croppedImg.imagePreview) {
      URL.revokeObjectURL(croppedImg.imagePreview);
    }
    setCroppedImg({
      croppedImage: '',
      fileName: '',
      imagePreview: '',
    });
    setChangeProfileImage(false);
  }

  return (
    <section className="section">
      <label
        className="content-heading-styled text-gray "
        tabIndex="0"
        id="profilePhotoLabel"
      >
        {Account.account.profilePhoto}
      </label>
      {changeProfileImage && (
        <>
          <div className="h24" />
          <ImageCropper
            ariaImageDescription={Account.account.profilePhoto}
            center={true}
            cover={'horizontal-cover'}
            handleImage={handleProfileImg}
            height={264}
            id={'profile'}
            imagePreview={croppedImg.imagePreview}
            profileFrame={true}
            setHOActionBtnsUnavailable={setConvertingImage}
            width={264}
            customFileUploaderWrapper={'file-uploader__wrapper--profile'}
            customFileUploaderContainer={'file-uploader__container--profile'}
            spinnerMinWidth={'314px'}
            spinnerMinHeight={'314px'}
          />
        </>
      )}

      {changeProfileImage && (
        <>
          <TextArea
            handleChange={setProfileImageAlt}
            limit={200}
            id={'profileImageAlt'}
            labelText={Generic.altText}
            disableEnter={true}
            loadTrigger={true}
            customWrapper={'br--none mrg-t24'}
            loadValue={accountProfileImageAlt}
            onBlurHandlerOff={true}
            hideWordCount={true}
          />
          <div className="h24" />
        </>
      )}

      {changeProfileImage && !convertingImage && !isLoading && (
        <ConfirmSelection
          btn1Text={Account.generic.cancel}
          btn1Color={'neutral'}
          btn1Handler={handleCancelSaveImage}
          btn2Text={Account.generic.save}
          btn2Color={'pos'}
          btn2Handler={saveAccountProfilePhotoChange}
          center={true}
          customWrapper={
            changeProfileImage ? 'mrg-t24 mrg-auto-lr' : 'mrg-auto-lr'
          }
        />
      )}

      <div className="flex-center flex-column">
        {!changeProfileImage && (
          <div
            key={`profileImage-${accountProfileImage}`}
            className="flex-center flex-column"
          >
            <LoadBucketImage
              imagePath={accountProfileImage}
              imgClassName={'profile-image'}
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
              altAriaText={accountProfileImageAlt}
            />
            <div className="h24" />
            {isLoading ? (
              <Spinner minHeight={'45px'} maxHeight={'45px'} />
            ) : (
              <ChangeButton handler={setChangeProfileImage} />
            )}
          </div>
        )}
        {isLoading && changeProfileImage && (
          <Spinner minHeight={'45px'} maxHeight={'45px'} />
        )}
      </div>
      <div className="h36" />
    </section>
  );
};

export default ProfilePhoto;

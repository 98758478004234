import { useNavigate } from 'react-router-dom';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageLayout from '../../../../language/useLanguageLayout';

//components
import LoginContainer from './LoginContainer';
import ShowCardHorizontalBtn from '../../../../components/ShowCard/ShowCardHorizontalBtn';

//utils
import {
  image_explore_myndfull,
  image_translations,
} from '../../../../assets/images';

const LoginMobile = () => {
  //hooks
  const navigate = useNavigate();

  //language
  const { IconAlts } = useLanguageComponents();
  const { LandingPageShowCards } = useLanguageLayout();

  function handleTranslationsShowCard() {
    navigate('/tr');
  }

  function handleExploreShowCard() {
    navigate('/explore');
  }

  return (
    <div className="login-mobile__wrapper">
      <LoginContainer />
      <div className="landing-show-card-horizontal-btn__wrapper ">
        <ShowCardHorizontalBtn
          customWrapper={'landing-show-card-horizontal-btn'}
          showCardImage={image_translations}
          customShowCardImageClass={'landing-show-card-horizontal-btn--image'}
          text={LandingPageShowCards.translations}
          imageAlt={LandingPageShowCards.translationsImgAlt}
          customText={
            'landing-show-card-horizontal-btn--text color-gray mrg-l24'
          }
          customButtonTextWrapper={'mrg-l24'}
          customButtonImageWrapper={
            'landing-show-card-horizontal-btn__image-wrapper'
          }
          buttonImageAlt={IconAlts.iconArrow}
          buttonImageClass={
            'landing-show-card-horizontal-btn--button-arrow filter-red rotate-cw90'
          }
          showCardImageAlt={LandingPageShowCards.translationsImgAlt}
          handler={handleTranslationsShowCard}
          customContainer={'flex-row '}
        />
        <ShowCardHorizontalBtn
          customWrapper={'landing-show-card-horizontal-btn mrg-b48'}
          showCardImage={image_explore_myndfull}
          customShowCardImageClass={'landing-show-card-horizontal-btn--image'}
          text={LandingPageShowCards.explore}
          customText={'landing-show-card-horizontal-btn--text color-gray'}
          customSubText={
            'landing-show-card-horizontal-btn--subText color-gray mrg-t4'
          }
          customButtonTextWrapper={'mrg-l24'}
          customButtonImageWrapper={
            'landing-show-card-horizontal-btn__image-wrapper'
          }
          buttonImageAlt={IconAlts.iconArrow}
          buttonImageClass={
            'landing-show-card-horizontal-btn--button-arrow filter-red rotate-cw90'
          }
          showCardImageAlt={LandingPageShowCards.exploreImgAlt}
          handler={handleExploreShowCard}
          customContainer={'flex-row'}
        />
      </div>
    </div>
  );
};

export default LoginMobile;

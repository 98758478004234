import { useSettings } from '../../context/SettingsProvider';

const useLanguageAccount = () => {
  const { contentLanguage } = useSettings();

  const Account = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        generic: {
          change: 'Change',
          cancel: 'Cancel',
          save: 'Save',
          remove: 'Remove',
          help: 'Help',
          arrow: 'Arrow',
          learnMore: 'Learn More',
          roles: 'Roles',
          events: 'Events',
          tools: 'Tools',
          ariaMainMenu: 'Account Menu',
          ariaSaveUnavailable:
            'Save Button. No changed data to save, or requirements are not met.',
          ariaSaveAvailable: 'Save Button. Changed data can be saved.',
          link: 'Link',
          links: 'Links',
          ariaCloseAccountNavigationMenu: 'Close account navigation menu.',
          ariaAccountNavigationMenu: ' Account Navigation Menu.',
          ariaAccountNavigationMenuSelected: 'selected.',
          ariaPrimaryAccountNavigationButtonsMenu:
            'Primary account navigation buttons. To jump to the sub-sections for the current selection, see the list of  sub-selection buttons below.',
          ariaCurrentSelection: 'Current selection.',
        },
        general: {
          general: 'General',
          appSettings: 'App Settings',
          ariaPrimaryNavButton: 'General Settings primary navigation button.',
          ariaSecondaryMenu: 'General Settings sub-menu for quick navigation.',
        },
        account: {
          account: 'Account',
          accountCode: 'Account Code:',
          profilePhoto: 'Profile Photo',
          banner: 'Banner',
          profilePreview: 'Profile Preview',

          personalBio: 'Personal Bio',
          badges: {
            label: 'Badges',
            OGTester: 'Soft Launch Tester',
          },
          socialLinks: 'Social Links',
          name: 'Name',
          password: 'Password',
          phone: 'Phone',
          installWebApp: 'Install Web App',
          seeAll: 'See All',
          personalWebsite: 'Personal Website',
          pronouns: 'Pronouns',
          prefix: 'Prefix',
          firstName: 'First Name',
          lastName: 'Last Name',
          forgotPassword: 'Forgot Password?',
          emailAddress: 'Email',
          appLanguage: 'App Language',
          timeZone: 'Time Zone:',
          soundEffects: 'Sound Effects',
          activateAttendeeSection: 'Activate Attendee Section',
          activateSpeakerSection: 'Activate Speaker Section',
          activateOrganizerSection: 'Activate Organizer Section',
          installMyndFullWebApp: 'Install MyndFull Web App',
          ariaPrimaryNavButton: 'Account primary navigation button.',
          ariaSecondaryMenu: 'Account sub-menu for quick navigation.',
          ariaSocialLinks:
            'Add social links to be viewed in your profile. Use the button list below to change the input field to enter different social media links.',
          personalBioDescription: `Provide a bio for your personal attendee, speaker and organizer profiles.`,
        },
        billing: {
          billing: 'Billing',
          billingSummary: 'Billing Summary',
          ariaPrimaryNavButton: 'Billing primary navigation button.',
          ariaSecondaryMenu: 'Billing secondary menu for quick navigation.',
          paymentMethods: 'Payment Methods',
          roomCodes: 'Room Codes',
          invoices: 'Invoices',
          translationRooms: 'Translation Rooms',
          translationsUsage: 'Translations Usage',
        },
        community: {
          community: 'Community',
          communityAccounts: 'Community Accounts',
          communityContacts: 'Community Contacts',
          blockedAccounts: 'Blocked',
          communityFollowers: 'Followers',
          communityFollowing: 'Following',
          ariaPrimaryNavButton: 'Community primary navigation button.',
          followersNotPublic:
            'Please note that your community details (contacts, followers etc.) are not publicly visible.',
        },
        emailPreferences: {
          emailPreferences: 'Email Preferences',
          email: 'Email',
          ariaPrimaryNavButton: 'Email Preferences primary navigation button.',
          ariaSecondaryMenu:
            'Email Preferences secondary menu for quick navigation.',
        },
        attendee: {
          archivedEvents: 'Archived Events',
          archivedEventsDescription: 'View a list of your archived events.',
          attendee: 'Attendee',
          attendeeActivation: 'Attendee Activation',
          activation: 'Activation',
          attendeeActivationDescription: 'Toggle attendee find events',
          attendeeSignUp: 'Sign Up',
          bigTags: 'Big Tags',
          bigTagsDescription:
            'Choose scholastic fields/disciplines to find corresponding events.',
          ariaBigTagsInputInstructions:
            'Choose scholastic fields / disciplines to find corresponding events. For the best accessibility, use the See Full List button located below the input field to directly add big tags from a list. Alternatively, use this autocomplete field to find tags from the Big Tags list. Use the arrow up and arrow down keys to scroll through the generated list. Press enter to add the highlighted tag or backspace to remove characters from the input field.',
          seeFullList: 'See Full List',
          tinyTags: 'Tiny Tags',
          tinyTagsDescription:
            'Create tags that match your specific interests. These will be used for populating a list of curated events with matching tags. Only use letters and numbers. At least one tag is required.',
          eventSearchInPerson: 'Search Distance (In Person)',
          eventSearchInPersonDescription:
            'In-Person event searches will be populated according to your search location radius.',
          switchToMiles: 'Switch to Miles',
          switchToKilometers: 'Switch to Kilometers',
          eventSearchOnline: 'Search Countries (Online)',
          eventLimitOnlineSearches: 'Limit Online Searches',
          eventSearchOnlineDescription:
            'Limit your online event searches to one or more countries.',
          country: 'Country',
          searchLanguages: 'Search Languages',
          addCountries: 'Add Countries',
          addLanguages: 'Add Languages',
          searchLanguagesDescription:
            'Events must include at least one of your selected search languages.',
          ignoredEvents: 'Ignored Events',
          ignoredEventsDescription:
            'View a list of your ignored events that are still active.',
          ignoredEventsClickToRemoveIgnore: 'Click to remove ignore',
          ignoredEventsClickToIgnore: 'Click to ignore',
          languageBtn: 'Language',
          fetchList: 'Fetch List',
          viewEvent: 'View Event Page',
          ignoreToggle: 'Ignore Toggle',
          noEventsFound: 'No events were found.',
          ariaPrimaryNavButton: 'Searches primary navigation button.',
          ariaSecondaryMenu: 'Searches secondary menu for quick navigation.',
          ariaEventIsIgnored: 'Event is set to ignored.',
          ariaEventIsNotIgnored: 'Event is not ignored.',
          ariaIgnoredList: 'List of ignored events that are still active.',
          community: 'Community',
          communityDescription:
            'Adjust your Community Event searches by adding, removing or grouping MyndFull accounts.',
          importUnfollowedContacts: 'Import Unfollowed Contacts',
        },
        speaker: {
          speaker: 'Speaker',
          speakerSignUp: 'Sign Up',
          speakerActivation: 'Activation',
          speakerBannerImage: 'Profile Banner',
          viewProfile: 'View Profile',
          speakerPreview: 'Preview',
          speakerProfile: 'Speaker Profile',
          speakerPersonal: 'Personal',
          speakerBio: 'Speaker Bio',
          speakerSocials: 'Social Links',
          speakerLanguages: 'Languages',
          speakerLocation: 'Location',
          speakerCredentials: 'Credentials',
          speakerBigTags: 'Big Tags',
          speakerTinyTags: 'Tiny Tags',
          ariaPrimaryNavButton: 'Speaker primary navigation button.',
          ariaSecondaryMenu: 'Speaker secondary menu for quick navigation.',
        },
        organizer: {
          organizer: 'Organizer',
          ariaPrimaryNavButton: 'Organizer primary navigation button.',
          ariaSecondaryMenu: 'Organizer secondary menu for quick navigation.',
          organizerActivation: 'Activation',
          organizerCurrentPlan: 'Current Plan',
          organizerProfile: 'Profile',
          communitySettings: 'Community Settings',
        },
        translationRooms: {
          translations: 'Translations',
          roomCodeManager: 'Room Code Manager',
          newRoomCodeSubscriptions: 'New Room Code Subscriptions',
          translationRoomCodes: 'Translation Room Codes',
          ariaPrimaryNavButton: 'Translation Rooms primary navigation button.',
          ariaSecondaryMenu: 'Translation Rooms sub-menu for quick navigation.',
          info: 'When hosting a room, you can create a password that attendees will be required to enter. To streamline room host setup, toggle this option on and create a default attendee password. When a host creates a room with this room code, the host will have the option to choose the default attendee password that you created rather than create a new password each session.',
        },
        help: {
          help: 'Help',
          ariaPrimaryNavButton: 'Help primary navigation button.',
          ariaSecondaryMenu: 'Help secondary menu for quick navigation.',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        generic: {
          change: 'Changement',
          cancel: 'Annuler',
          save: 'Sauvegarder',
          remove: 'Supprimer',
          help: 'Aider',
          arrow: 'Flèche',
          learnMore: 'Apprendre encore plus',
          tools: 'Outils',
          ariaMainMenu: 'Menu du compte',
          ariaSaveUnavailable:
            'Bouton Sauvegarder. Aucune donnée modifiée à enregistrer, ou les exigences ne sont pas remplies.',
          ariaSaveAvailable:
            'Bouton Sauvegarder. Les données modifiées peuvent être enregistrées.',
          link: 'Lien',
          links: 'Liens',
          roles: 'Rôles',
          events: 'Événements',
          ariaCloseAccountNavigationMenu:
            'Fermer le menu de navigation du compte.',
          ariaAccountNavigationMenu: 'Menu de navigation du compte.',
          ariaAccountNavigationMenuSelected: 'choisie.',
          ariaPrimaryAccountNavigationButtonsMenu:
            'Boutons de navigation du compte principal. Pour accéder aux sous-sections de la sélection actuelle, consultez la liste des boutons de sous-sélection ci-dessous.',
          ariaCurrentSelection: 'Sélection actuelle.',
        },
        general: {
          general: 'Général',
          appSettings: `Paramètres de l'application`,
          ariaPrimaryNavButton:
            'Bouton de navigation principale des paramètres généraux.',
          ariaSecondaryMenu:
            'Menu secondaire des paramètres généraux pour une navigation rapide.',
        },
        account: {
          account: 'Compte',
          accountCode: 'Code de compte:',
          profilePhoto: 'Photo de profil',
          banner: 'Bannière',
          personalBio: 'Bio personnelle',
          profilePreview: `Aperçu du profil`,
          badges: {
            label: 'Insignes',
            OGTester: 'Testeur de Soft Launch',
          },
          socialLinks: 'Liens sociaux',
          name: 'Nom',
          password: 'Mot de passe',
          emailAddress: 'E-mail',
          phone: 'Numéro de téléphone',
          installWebApp: 'Installer',
          seeAll: 'Voir tout',
          personalWebsite: 'Site web personnel',
          pronouns: 'Pronoms',
          prefix: 'Préfixe',
          firstName: 'Prénom',
          lastName: 'Nom de famille',
          forgotPassword: 'Mot de passe oublié?',
          appLanguage: `Langue de l'application`,
          timeZone: 'Fuseau horaire:',
          soundEffects: 'Effets sonores',
          activateAttendeeSection: 'Activer la section des participants',
          activateSpeakerSection: 'Activer la section conférencier',
          activateOrganizerSection: 'Activer la section organisateur',
          installMyndFullWebApp: 'Installer MyndFull Web App',
          ariaPrimaryNavButton: 'Bouton de navigation principale du compte.',
          ariaSecondaryMenu:
            'Menu secondaire du compte pour une navigation rapide.',
          ariaSocialLinks: `Ajoutez des liens sociaux à afficher dans votre profil. Utilisez la liste de boutons ci-dessous pour modifier le champ de saisie afin d'entrer différents liens de médias sociaux.`,
          personalBioDescription: `Fournissez une biographie pour vos profils d'attendant, d'orateur et d'organisateur personnels.`,
        },
        attendee: {
          archivedEvents: 'Événements archivés',
          archivedEventsDescription:
            'Afficher la liste de vos événements archivés',
          attendee: 'Participant',
          attendeeSignUp: `S'inscrire`,
          activation: 'Activation',
          attendeeActivationDescription: `Basculer la recherche d'événements par les participants.`,
          attendeeActivation: 'Activation du participant',
          bigTags: 'Grand Balises',
          bigTagsDescription: `Sélectionnez les domaines/disciplines scolaires pour trouver les événements correspondant`,
          seeFullList: 'Voir la liste complète',
          ariaBigTagsInputInstructions: `Choisissez les domaines scolaires / disciplines pour trouver les événements correspondants. Pour une meilleure accessibilité, utilisez le bouton Voir la liste complète situé sous le champ de saisie pour ajouter directement de grandes balises à partir d'une liste. Vous pouvez également utiliser ce champ de saisie semi-automatique pour rechercher des balises dans la liste Grande Balises. Community Verified icon Utilisez les flèches vers le haut et vers le bas pour faire défiler la liste générée. Appuyez sur Entrée pour ajouter la balise en surbrillance ou sur Retour arrière pour supprimer des caractères du champ de saisie.`,
          tinyTags: 'Petit Balises',
          tinyTagsDescription: `Créez des balise qui correspondent à vos intérêts spécifiques. Ceux-ci seront utilisés pour remplir une liste d'événements organisés avec des balise correspondantes. Utilisez uniquement des lettres et des chiffres. Au moins une étiquette est nécessaire.`,
          eventSearchInPerson: `Distance de recherche (en personne)`,
          eventSearchInPersonDescription: `Les recherches d'événements en personne seront remplies en fonction du rayon de votre emplacement de recherche.`,
          switchToMiles: 'Changer en Miles',
          switchToKilometers: 'Changer to Kilomètres',
          eventSearchOnline: `Rechercher des pays (en ligne)`,
          eventLimitOnlineSearches: 'Limiter les recherches en ligne',
          eventSearchOnlineDescription: `Limitez vos recherches d’événements en ligne à un ou plusieurs pays.`,
          country: 'un pays',
          searchLanguages: 'Langues de recherche',
          addCountries: 'Ajouter des pays',
          addLanguages: 'Adjouter des langues',
          searchLanguagesDescription:
            'Les événements doivent inclure au moins une des langues de recherche que vous avez sélectionnées.',
          ignoredEvents: `Événements ignorés`,
          ignoredEventsDescription: `Affichez une liste de vos événements ignorés qui sont toujours actifs.`,
          ignoredEventsClickToRemoveIgnore: 'Cliquez pour supprimer ignorer',
          ignoredEventsClickToIgnore: 'Cliquez pour ignorer',
          languageBtn: 'une langue',
          fetchList: 'Récupérer la liste',
          viewEvent: `Voir la page de l'événement`,
          ignoreToggle: 'Bascule Ignorer',
          noEventsFound: `Aucun événement n'a été trouvé.`,
          ariaPrimaryNavButton: 'Recherche le bouton de navigation principal.',
          ariaSecondaryMenu:
            'Recherche le menu secondaire pour une navigation rapide.',
          ariaEventIsIgnored: `L'événement est défini sur ignoré.`,
          ariaEventIsNotIgnored: `L'événement n'est plus ignoré.`,
          ariaIgnoredList: `Liste des événements ignorés qui sont toujours actifs.`,
          community: 'Communauté',
          communityDescription: `Ajustez vos recherches d'événements communautaires en ajoutant, supprimant ou regroupant des comptes MyndFull.`,
          importUnfollowedContacts: 'Importer les contacts non suivis',
        },
        billing: {
          billing: 'Facturation',
          billingSummary: 'Récapitulatif de facturation',
          ariaPrimaryNavButton:
            'Bouton de navigation principal de facturation.',
          ariaSecondaryMenu:
            'Menu secondaire de facturation pour une navigation rapide.',
          paymentMethods: 'Méthodes de payement',
          roomCodes: 'Codes de la salle',
          invoices: 'Factures',
          translationRooms: 'Salles de traduction',
          translationsUsage: 'Utilisation des traductions',
        },
        community: {
          community: 'Communauté',
          communityContacts: 'Contacts communautaires',
          communityAccounts: 'Comptes communautaires',
          ariaPrimaryNavButton:
            'Bouton de navigation principal de la communauté.',
          blockedAccounts: 'Bloqués',
          communityFollowers: 'Abonnés',
          communityFollowing: 'Abonnements',
          followersNotPublic: `Veuillez noter que les détails de votre communauté (contacts, abonnés, etc.) ne sont pas visibles publiquement.`,
        },
        emailPreferences: {
          emailPreferences: 'Préférences de courriel',
          email: 'Courriel',
          ariaPrimaryNavButton:
            'Bouton de navigation principal pour les préférences de courriel.',
          ariaSecondaryMenu:
            'Menu secondaire pour une navigation rapide des préférences de courriel.',
        },
        speaker: {
          speaker: 'Orateurs',
          speakerSignUp: `S'inscrire`,
          speakerActivation: 'Activation',
          speakerPreview: 'Aperçu',
          viewProfile: 'Voir le profil',
          speakerBannerImage: 'Bannière de profil',
          speakerProfile: `Profil de l'orateur`,
          speakerPersonal: 'Informations personnelles',
          speakerBio: `Biographie de l'orateur`,
          speakerSocials: 'Liens sociaux',
          speakerLanguages: 'Langues',
          speakerLocation: 'Emplacement',
          speakerCredentials: 'Credentials',
          speakerBigTags: 'Grand Balises',
          speakerTinyTags: 'Petit Balises',
          ariaPrimaryNavButton: 'Bouton de navigation principal du orateurs.',
          ariaSecondaryMenu:
            'Menu secondaire du orateurs pour une navigation rapide.',
        },
        organizer: {
          organizer: 'Organisateur',
          ariaPrimaryNavButton: `Bouton de navigation principal de l'organiseur.`,
          ariaSecondaryMenu: `Menu secondaire de l'organisateur pour une navigation rapide.`,
        },
        translationRooms: {
          translations: 'Traductions',
          roomCodeManager: 'Gestionnaire de codes de la salle',
          newRoomCodeSubscriptions: 'Nouveaux abonnements à code de la salle',
          translationRoomCodes: 'Codes des salles de traduction',
          ariaPrimaryNavButton: `Bouton de navigation principal des salles de traduction.`,
          ariaSecondaryMenu:
            'Sous-menu des salles de traduction pour une navigation rapide.',
        },
        help: {
          help: 'Aider',
          ariaPrimaryNavButton: `Bouton de navigation principal de l'aide.`,
          ariaSecondaryMenu: `Menu secondaire d'aide pour une navigation rapide.`,
        },
      };
    }

    return translation;
  };

  const AccountBadges = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        earlySupporter: 'Early Supporter',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        earlySupporter: 'Supporteur précoce',
      };
    }
    return translation;
  };

  const TranslationRoomCard = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        ariaValidLengthSuccess:
          'Success. Your password has 8 or more characters.',
        ariaValidLengthFail:
          'Warning. Your password does not have 8 or more characters.',
        valid: 'checkmark.',
        invalid: 'X',
        passwordRequirements: 'At least 8 Characters',
        roomCode: 'Room Code',
        hostPassword: 'Host password',
        defaultAttendeePasswordToggle: 'Create A Reusable Attendee Password',
        attendeePassword: 'Attendee Password',
        unsubscribe: 'Unsubscribe',
        newRoomCode: 'New Room Code',
        remove: 'Remove',
        subscription: 'Subscription',
        subscriptionEnding:
          'This subscription is scheduled to end at your next billing cycle.',
        restore: 'Restore',
        roomCodeLocked:
          'This room code is locked because it has an unpaid invoice. Please see Billing for more details.',
        save: 'Save',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        ariaValidLengthSuccess:
          'Succès. Votre mot de passe contient 8 caractères ou plus.',
        ariaValidLengthFail:
          'Avertissement. Votre mot de passe ne contient pas 8 caractères ou plus.',
        invalid: 'X',
        valid: 'coche.',
        passwordRequirements: 'Au moins 8 caractères.',
        roomCode: 'Code de la salle',
        hostPassword: `Mot de passe de l'hôte`,
        defaultAttendeePasswordToggle:
          'Créer un mot de passe de participant réutilisable',
        attendeePassword: 'Mot de passe du participant',
        unsubscribe: 'Se désabonner',
        newRoomCode: 'Nouveau code de la salle',
        remove: 'Retirer',
        subscription: 'Abonnement',
        subscriptionEnding:
          'Cet abonnement devrait prendre fin lors de votre prochain cycle de facturation.',
        restore: 'Restaurer',
        roomCodeLocked:
          'Ce code de salle est verrouillé car il comporte une facture impayée. Veuillez consulter Facturation pour plus de détails.',
        save: 'Sauvegarder',
      };
    }
    return translation;
  };

  const BillingPaymentMethods = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        paymentMethods: 'Payment Methods',
        addNewPaymentMethod: 'Add New Payment Method',
        cancel: 'Cancel',
        save: 'Save',
        billingAddress: 'Billing Address',
        newCard: 'New Card',
        primaryCard: 'Primary Card',
        secondaryCard: 'Secondary Card',
        expires: 'Expires',
        setAsPrimaryCard: 'Set as Primary Card',
        remove: 'Remove',
        saveAsPrimaryCard: 'Save as Primary Card',
        defaultPaymentMethod: 'Default payment method',
        paymentMethodFailed: 'Payment method failed',
        noticePrimaryCardOutstandingInvoices:
          'Set a new Primary Card to pay all outstanding invoices.',
        noticeWaitForPaymentToProcess: `Please allow a moment for your payment to process. Your outstanding invoices will show as paid after refreshing your web browser.`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        paymentMethods: 'Méthodes de payement',
        addNewPaymentMethod: 'Ajouter un nouveau mode de paiement',
        cancel: 'Annuler',
        save: 'Sauvegarder',
        billingAddress: 'Adresse de facturation',
        newCard: 'Nouvelle carte',
        primaryCard: 'Carte principale',
        secondaryCard: 'Carte secondaire',
        expires: 'Expire',
        setAsPrimaryCard: 'Définir comme carte principale',
        remove: 'Retirer',
        saveAsPrimaryCard: 'Enregistrer comme carte principale',
        defaultPaymentMethod: 'Mode de paiement par défaut',
        paymentMethodFailed: 'Échec du mode de paiement',
        noticePrimaryCardOutstandingInvoices:
          'Définissez une nouvelle carte principale pour payer toutes les factures impayées.',
        noticeWaitForPaymentToProcess: `Veuillez patienter un moment pour que votre paiement soit traité. Vos factures impayées apparaîtront comme payées après avoir actualisé votre navigateur Web.`,
      };
    }

    return translation;
  };

  const BillingSummary = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        billingSummary: 'Billing Summary',
        billingPeriod: 'Billing period',
        monthlyOverview: 'Monthly Overview',
        invoice: 'Invoice',
        noBillingSummaries: 'No Billing Summaries',
        roomCodeSubscriptions: 'Room Code Subscriptions',
        totalRoomCodes: 'Total Room Codes',
        subscriptionCharge: 'Subscription Charge',
        roomCodeUsage: 'Room Code Usage',
        totalWordCount: 'Total Word Count',
        usageCharge: 'Usage Charge',
        subtotal: 'Subtotal',
        total: 'Total',
        downloadInvoice: 'Download Full Invoice PDF',
        arrow: 'Arrow',
        upcoming: 'Upcoming',
        pending: 'Pending',
        prorationRefund: 'Proration Refund',
        tax: 'Tax',
        unpaidInvoices: 'You have unpaid invoices.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        billingSummary: 'Résumé de la facturation',
        billingPeriod: 'Période de facturation',
        monthlyOverview: 'Aperçu mensuel',
        invoice: 'Facture',
        noBillingSummaries: 'Aucun résumé de facturation',
        roomCodeSubscriptions: 'Abonnements aux codes de salle',
        totalRoomCodes: 'Total des codes de salle',
        subscriptionCharge: `Frais d'abonnement`,
        roomCodeUsage: 'Utilisation des codes de salle',
        totalWordCount: 'Nombre total de mots',
        usageCharges: "Frais d'utilisation",
        subtotal: 'Sous-total',
        total: 'Total',
        downloadInvoice: 'Télécharger la facture complète en PDF',
        arrow: 'Flèche',
        upcoming: 'À venir',
        pending: 'en cours',
        prorationRefund: 'Remboursement au prorata',
        tax: 'Impôt',
        unpaidInvoices: 'Vous avez des factures impayées.',
      };
    }

    return translation;
  };

  const BillingRoomCodeSubscriptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        translationUsage: 'Translations Usage',
        translationRoomHasNoUsage: 'Translation Room has no usage.',
        noTranslationRoomSubscription: 'No Translation Room subscription.',
        sessionId: 'Session Id',
        transcriptLength: 'Transcript Length',
        noteShortDelay:
          'Please note that there is a short delay to update Translation Rooms Usage.',
        noTranslationsUsage: 'No Translations Usage',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        translationUsage: 'Utilisation des traduction',
        translationRoomHasNoUsage: `La salle de traduction n'a aucune utilité.`,
        noTranslationRoomSubscription: `Pas d'abonnement à la Salle de Traduction.`,
        sessionId: 'ID de session',
        transcriptLength: 'Longueur de la transcription',
        noteShortDelay: `Veuillez noter qu'il y a un court délai pour mettre à jour l'utilisation des salles de traduction.`,
        noTranslationsUsage: 'Aucune utilisation de traductions',
      };
    }

    return translation;
  };

  const TranslationsNewRoomCodeSubscriptions = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        newRoomCodeSubscriptions: 'New Room Code Subscriptions',
        newRoomCode: 'New Room Code',
        addSubscriptions: 'Add Subscriptions',
        accountPaymentMethodRequired:
          'Your account requires a Credit Card to add Room Code Subscriptions.',
        remove: 'Remove',
        newRoomCodeSubscriptionsUnavailableWithUnpaidInvoices:
          'New room code subscriptions are not available while this account has unpaid invoices.',
        confirmNewSubscriptions: 'Confirm New Subscriptions',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        newRoomCodeSubscriptions: 'Nouveaux abonnements à code de la salle',
        newRoomCode: 'Nouveau code de la salle',
        addSubscriptions: 'Ajouter des abonnements',
        accountPaymentMethodRequired:
          'Votre compte nécessite une carte de crédit pour ajouter des abonnements à code de la salle.',
        remove: 'Retirer',
        newRoomCodeSubscriptionsUnavailableWithUnpaidInvoices:
          'Les nouveaux abonnements à code de salle ne sont pas disponibles tant que ce compte a des factures impayées.',
        confirmNewSubscriptions: 'Confirmer les nouveaux abonnements',
      };
    }

    return translation;
  };

  const TranslationRoomCodeManager = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        roomCodeManager: 'Room Code Manager',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        roomCodeManager: 'Gestionnaire de codes de la salle',
      };
    }

    return translation;
  };

  const AttendeeSignUp = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        attendeeSignUp: 'Attendee Sign Up',
        instructions:
          'Sign up as an attendee by completing the required fields below then save.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        attendeeSignUp: 'Inscription des participants',
        instructions: `Inscrivez-vous en tant que participant en remplissant les champs obligatoires ci-dessous, puis enregistrez.`,
      };
    }

    return translation;
  };

  const SpeakerSignUp = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        speakerSignUp: 'Speaker Sign Up',
        instructions1: `Create a speaker profile for organizers to find and invite you to participate in events.`,
        instructions2: `Your speaker profile details and visibility can be changed at any time.`,
        instructions3: `Please complete all required fields below then Publish, or use Save Draft to save unfinished progress.`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        speakerSignUp: 'Inscription des orateurs',
        instructions1: `Créez un profil d'intervenant pour que les organisateurs puissent vous trouver et vous inviter à participer à des événements.`,
        instructions2: `Les détails et la visibilité de votre profil d'intervenant peuvent être modifiés à tout moment.`,
        instructions3: `Veuillez remplir tous les champs obligatoires ci-dessous puis publier, ou utilisez Enregistrer le brouillon pour enregistrer la progression inachevée.`,
      };
    }

    return translation;
  };

  const SpeakerRoleToggle = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        speakerActivation: 'Speaker Activation',
        description: 'Toggle speaker profile visibility.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        speakerActivation: `Activation d'orateur`,
        description: `Basculer la visibilité du profil de l'orateur.`,
      };
    }

    return translation;
  };
  const SpeakerAccessibility = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        accessibility: 'Accessibility',
        description:
          'Viewable by organizers to ensure they can provide accommodations.',
        notes: 'Notes',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        accessibility: 'Accessibilité',
        description: `Visible par les organisateurs pour s'assurer qu'ils peuvent fournir un hébergement.`,
        notes: 'Notes',
      };
    }

    return translation;
  };

  const SpeakerCredentials = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        credentials: 'Credentials',
        description:
          'Customize your credentials by adding categories to your speaker profile. Viewable by organizers only.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        credentials: 'Qualifications',
        description: `
        Personnalisez vos informations de qualifications en ajoutant des catégories à votre profil d'orateur. Visible uniquement par les organisateurs.`,
      };
    }

    return translation;
  };
  const SpeakerDietAllergies = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        dietaryRestrictionsFoodAllergies:
          'Dietary Restrictions & Food Allergies',
        dietaryRestrictions: 'Dietary Restrictions',
        description: 'Viewable by organizers to ensure your safety.',
        foodAllergies: 'Food Allergies',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        dietaryRestrictionsFoodAllergies:
          'Restrictions alimentaires et allergies alimentaires',
        dietaryRestrictions: 'Restrictions alimentaires',
        description:
          'Visible par les organisateurs pour assurer votre sécurité.',
        foodAllergies: 'Allergies alimentaires',
      };
    }

    return translation;
  };
  const SpeakerLanguages = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        addLanguages: 'Add Languages',
        languages: 'Languages',
        description: 'Provide a list of your fluent languages.',
        language: 'Language',
        addAnotherNonListedLanguage: 'Add Another Non-listed Language',
        ariaNotListedLanguageTextInput: 'Add a non-listed language here.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        addLanguages: 'Ajouter des langues',
        languages: 'Langues',
        description: 'Fournissez une liste de vos langues parlées couramment.',
        language: 'Langue',
        addAnotherNonListedLanguage: 'Ajouter une autre langue non répertoriée',
        ariaNotListedLanguageTextInput:
          'Ajoutez ici une langue non répertoriée.',
      };
    }

    return translation;
  };
  const SpeakerLocation = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        location: 'Location',
        description:
          'Provide an approximate location to match organizer searches. Your city and country will be displayed to organizers and attendees.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        location: 'Emplacement',
        description: `Indiquez un emplacement approximatif pour correspondre aux recherches des organisateurs. Votre ville et votre pays seront affichés pour les organisateurs et les participants.`,
      };
    }

    return translation;
  };

  const SocialLinks = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        socialLinks: 'Social Links',
        description: `Socials can be viewed by organizers and attendees who are viewing an event that you are participating in as a speaker.`,
        mustStartWithHTTPS: 'Link must start with https://',
        handleMustStartWithAt: 'Social handle must start with @',
        incorrectFormat: 'Incorrect Format',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        socialLinks: 'Liens sociaux',
        description:
          "Les liens sociaux peuvent être consultés par les organisateurs et les participants qui regardent un événement auquel vous participez en tant qu'orateur.",
        mustStartWithHTTPS: 'Le lien doit commencer par https://',
        handleMustStartWithAt: `Le nom d'utilisateur doit commencer par @`,
        incorrectFormat: 'Format incorrect',
      };
    }

    return translation;
  };
  const SpeakerTags = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',
        bigTagsDescription: `Provide Big Tags related to your specializations. Used for matching organizers' searches.`,
        tinyTagsDescription: `Provide Tiny Tags related to your specializations. Used for matching organizers' searches.`,
        transferTags: 'Import Attendee Tags',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        bigTags: 'Grand Balises',
        tinyTags: 'Petit Balises',
        bigTagsDescription:
          'Fournissez des grandes balises liées à vos spécialisations. Utilisées pour correspondre aux recherches des organisateurs.',
        tinyTagsDescription:
          'Fournissez des petites balises liées à vos spécialisations. Utilisées pour correspondre aux recherches des organisateurs.',
        transferTags: 'Importer des balises de participants',
      };
    }

    return translation;
  };

  const SpeakerSignUpValidation = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        speakerBio: 'Speaker Bio',
        linkFacebook: 'Facebook Link',
        linkLinkedIn: 'LinkedIn Link',
        linkX: 'X Link',
        linkPersonalWebsite: 'Personal Website Link',
        linkInstagram: 'Instagram Link',
        speakerLanguages: 'Speaker Languages',
        speakerLocation: 'Speaker Location',
        artisticActivities: 'Artistic Activities',
        certificatesLicenses: 'Certificates / Licenses',
        education: 'Education',
        employment: 'Employment',
        honoursAwards: 'Honours / Awards',
        jobExperience: 'Job Experience',
        patentDesigns: 'Patent Designs',
        presentations: 'Presentations',
        projects: 'Projects',
        publications: 'Publications',
        skillsExpertise: 'Skills / Expertise',
        volunteerExperience: 'Volunteer Experience',
        dietaryRestrictions: 'Dietary Restrictions',
        foodAllergies: 'Food Allergies',
        accessibility: 'Accessibility',
        bigTags: 'Big Tags',
        tinyTags: 'Tiny Tags',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        speakerBio: `Biographie de l'orateur`,
        linkFacebook: 'Lien Facebook',
        linkLinkedIn: 'Lien LinkedIn',
        linkX: 'Lien X',
        linkPersonalWebsite: 'Lien vers un site Web personnel',
        linkInstagram: 'Lien Instagram',
        speakerLanguages: `Langues de l'orateur`,
        speakerLocation: `Emplacement de l'orateur`,
        artisticActivities: 'Activités artistiques',
        certificatesLicenses: 'Certificats / Licences',
        education: 'Éducation',
        employment: 'Emploi',
        honorsAwards: 'Distinctions / Récompenses',
        jobExperience: 'Expérience professionnelle',
        patentDesigns: 'Brevets / Conceptions',
        presentations: 'Présentations',
        projects: 'Projets',
        publications: 'Publications',
        skillsExpertise: 'Compétences / Expertise',
        volunteerExperience: 'Expérience bénévole',
        dietaryRestrictions: 'Restrictions alimentaires',
        foodAllergies: 'Allergies alimentaires',
        accessibility: 'Accessibilité',
        bigTags: 'Grand Balises',
        tinyTags: 'Petit Balises',
      };
    }

    return translation;
  };

  const SpeakerMeta = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        ariaRestart: 'Restart speaker sign up.',
        ariaSaveDraft: 'Save progress as a draft.',
        ariaPublish: 'Publish data and sign up.',
        restart: 'Restart',
        saveDraft: 'Save Draft',
        publish: 'Publish',
        removeAllSpeakerSignUpData: 'Remove all Speaker Sign Up data?',
        cancel: 'Cancel',
        save: 'Save',
        previewAsAttendee: 'Preview as Attendee',
        previewAsOrganizer: 'Preview as Organizer',
        complete: 'Complete',
        fieldsRequireCompletion: 'Fields that Require Completion',
        preview: 'Preview',
        confirmSave: 'Confirm Save',
        confirmPublish: 'Confirm publication',
        confirmCompletion: 'Confirm completion',
        confirm: 'Confirm',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        ariaRestart: `Redémarrer l'inscription de l'orateur.`,
        ariaSaveDraft: 'Sauvegarder les progrès en tant que brouillon.',
        ariaPublish: `Publier les données et s'inscrire.`,
        restart: 'Redémarrer',
        saveDraft: 'Sauvegarder',
        publish: 'Publier',
        removeAllSpeakerSignUpData: `Supprimer toutes les données d'inscription de l'orateur?`,
        cancel: 'Annuler',
        save: 'Sauvegarder',
        previewAsAttendee: 'Aperçu en tant que participant',
        previewAsOrganizer: `Aperçu en tant qu'organisateur`,
        complete: `Compléter`,
        fieldsRequireCompletion: 'Champs obligatoires',
        preview: 'Aperçu',
        confirmSave: 'Confirmer sauvegarder',
        confirmPublish: 'Confirmer la publication',
        confirmCompletion: 'Confirmer la finalisation',
        confirm: 'Confirmer',
      };
    }

    return translation;
  };

  const SpeakerViewProfile = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        previewAsAttendee: 'Preview as Attendee',
        previewAsOrganizer: 'Preview as Organizer',
        preview: 'Preview',
        viewAsAttendee: 'View as Attendee',
        viewAsOrganizer: 'View as Organizer',
        view: 'View',
        viewProfile: 'View Profile',
        description:
          'Unsaved progress will be lost when viewing your speaker profile.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        previewAsAttendee: 'Aperçu en tant que participant',
        previewAsOrganizer: `Aperçu en tant qu'organisateur`,
        preview: 'Aperçu',
        viewAsAttendee: `Voir en tant que Participant`,
        viewAsOrganizer: `Voir en tant qu'Organisateur`,
        view: 'Voir',
        viewProfile: 'Voir le profil',
        description: `Les modifications non enregistrées seront perdues lorsque vous consulterez votre profil d'orateur.`,
      };
    }

    return translation;
  };

  const ProfileBanner = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        bannerImage: 'Profile Banner',
        description: 'Customize your profile by adding a banner.',
        ariaImageDescription: 'Cropper tool for your banner image.',
        change: 'Change',
        save: 'Save',
        cancel: 'Cancel',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        bannerImage: 'Bannière de profil',
        description: `Personnalisez votre profil en ajoutant une bannière.`,
        ariaImageDescription: `Outil de recadrage pour votre image de bannière.`,
        change: 'Changement',
        save: 'Sauvegarder',
        cancel: 'Annuler',
      };
    }

    return translation;
  };

  const SpeakerCommunitySettings = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        communitySettings: 'Community Settings',
        description:
          'Allow attendees to follow you. Your events as a speaker will appear in their community events feed. Please note that your following count is not publicly visible.',
        allowFollowers: 'Allow followers',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        communitySettings: 'Paramètres de la communauté',
        description: `Permettez aux participants de vous suivre. Vos événements en tant qu'orateur apparaîtront dans leur fil d'événements communautaires. Veuillez noter que votre nombre d'abonnés n'est pas visible publiquement.`,
        allowFollowers: 'Autoriser les abonnés',
      };
    }

    return translation;
  };

  const OrganizerProfile = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        organizerProfile: 'Organizer Profile',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        organizerProfile: `Profil de l'organisateur`,
      };
    }

    return translation;
  };

  const OrganizerRoleToggle = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        organizerActivation: 'Organizer Activation',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        organizerActivation: `Activation de l'organisateur`,
      };
    }

    return translation;
  };

  const OrganizerCommunitySettings = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        communitySettings: 'Community Settings',
        description:
          'Your organizer profile will display your email and phone number.',
        publicContactInfo: 'Public Contact Info',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        communitySettings: 'Paramètres de la communauté',
        description: `Votre profil d'organisateur affichera votre e-mail et votre numéro de téléphone.`,
        publicContactInfo: 'Coordonnées publiques',
      };
    }

    return translation;
  };

  const ProfilePreview = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        profilePreview: 'Profile Preview',
        preview: 'Preview',
        description: `If your account has multiple roles (i.e. attendee, speaker, organizer) then the display of your profile information can vary dependng on who is viewing your profile.`,
        speakerPreviewDescription:
          'Preview your profile with your speaker data. Please note that only organizers can view your speaker data and only contacts can view your contact information.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        profilePreview: `Aperçu du profil`,
        preview: 'Aperçu',
        description: `Si votre compte possède plusieurs rôles (c.-à-d. participant, orateur, organisateur), l'affichage de vos informations de profil peut varier en fonction de qui consulte votre profil.`,
        speakerPreviewDescription: `Prévisualisez votre profil avec vos données d'intervenant. Veuillez noter que seuls les organisateurs peuvent consulter vos données d'intervenant et que seuls les contacts peuvent consulter vos coordonnées.`,
      };
    }

    return translation;
  };

  return {
    Account: Account(),
    AccountBadges: AccountBadges(),
    AttendeeSignUp: AttendeeSignUp(),
    TranslationRoomCard: TranslationRoomCard(),
    BillingPaymentMethods: BillingPaymentMethods(),
    BillingSummary: BillingSummary(),
    BillingRoomCodeSubscriptions: BillingRoomCodeSubscriptions(),
    TranslationRoomCodeManager: TranslationRoomCodeManager(),
    TranslationsNewRoomCodeSubscriptions:
      TranslationsNewRoomCodeSubscriptions(),
    SpeakerSignUp: SpeakerSignUp(),
    SpeakerRoleToggle: SpeakerRoleToggle(),
    SpeakerAccessibility: SpeakerAccessibility(),
    SpeakerCredentials: SpeakerCredentials(),
    SpeakerDietAllergies: SpeakerDietAllergies(),
    SpeakerLanguages: SpeakerLanguages(),
    SpeakerLocation: SpeakerLocation(),
    SocialLinks: SocialLinks(),
    SpeakerTags: SpeakerTags(),
    SpeakerSignUpValidation: SpeakerSignUpValidation(),
    SpeakerMeta: SpeakerMeta(),
    SpeakerViewProfile: SpeakerViewProfile(),
    ProfileBanner: ProfileBanner(),
    SpeakerCommunitySettings: SpeakerCommunitySettings(),
    OrganizerProfile: OrganizerProfile(),
    OrganizerRoleToggle: OrganizerRoleToggle(),
    OrganizerCommunitySettings: OrganizerCommunitySettings(),
    ProfilePreview: ProfilePreview(),
  };
};

export default useLanguageAccount;

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useSettingsHandlers } from '../useSettingsHandlers';

//Components
import OrganizeViews from './OrganizeViews';
import OrganizeFilters from './OrganizeFilters';
import OrganizeTagsToggle from './OrganizeTagsToggle';
import OrganizeFiltersSummary from './OrganizeFiltersSummary';
import OrganizeSelectionTags from './OrganizeSelectionTags';
import OrganizeFiltersMobile from './OrganizeFiltersMobile';
import OrganizeViewsSummaryMobile from './OrganizeViewsSummaryMobile';

const SettingsOrganize = () => {
  //Hooks
  const { width } = useSettings();
  const { settingsOrganizeView: view, settingsOrganizeOverviewListOpened } =
    useSettingsHandlers();

  //Component variables
  let settingsOrganizeComponent;

  if (width > 1280) {
    settingsOrganizeComponent = (
      <>
        <div className="search-sort no-select mrg-t12">
          <OrganizeViews />
          <OrganizeFilters />
        </div>
        <div className="summary-heading color-gray fs18 fwsb mrg-t16">
          <OrganizeTagsToggle view={view} />
          <OrganizeFiltersSummary width={width} />
        </div>
        {settingsOrganizeOverviewListOpened && view === 'overview' && (
          <OrganizeSelectionTags />
        )}
      </>
    );
  } else {
    settingsOrganizeComponent = (
      <div className="search-sort--mobile no-select mrg-t12">
        <OrganizeViews view={view} />
        <OrganizeViewsSummaryMobile view={view} />

        {view === 'overview' && (
          <>
            <OrganizeFiltersSummary width={width} />
            <OrganizeFiltersMobile />
          </>
        )}
      </div>
    );
  }

  return (
    <div role="complementary">
      <div className="h24" />
      {settingsOrganizeComponent}
    </div>
  );
};

export default SettingsOrganize;

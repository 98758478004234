import { useEffect, useState, useRef } from 'react';

//language
import useLanguageTranslationRoom from '../../../../../../language/features/useLanguageTranslationRoom';
import { useTranslationRoomSocket } from '../../../../../../context/sockets/main/TranslationRoomSocketProvider';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../../components/Spinner/Spinner';
import ButtonMeta from '../../../../../../components/ButtonMeta/ButtonMeta';

//utility
import { image_transcripts } from '../../../../../../assets/images';

const TranslationsTranscripts = () => {
  //hooks
  const {
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
    handleGetTranscripts,
    translationsTranscripts,
  } = useTranslationRoomSocket();
  //language
  const { TranslationTranscripts } = useLanguageTranslationRoom();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //variables
  const initializeGetTranscriptsEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (initializeGetTranscriptsEffectRan.current === false) {
      initializeGetTranscriptsEffectRan.current = true;
      //avoid overriding fetched transcript data with only the basic data provided by the api call below
      if (translationsTranscripts?.length === 0) {
        handleGetTranscripts();
      }
    }
  }, []); //fetch host transcripts

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}
      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_transcripts}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p className="fs21 fwsb mrg-tb24 access-ob access-o6" tabIndex="0">
            {TranslationTranscripts.transcripts}
          </p>

          <ButtonMeta
            handleClick={() => handleCreatePageChange('openTranscripts')}
            btnText={TranslationTranscripts.openTranscripts}
            fullWidth={true}
            customClassName={'min-full-width mrg-b24'}
            id={'translationsOpenTranscriptsBtn'}
          />

          <ButtonMeta
            handleClick={() => handleCreatePageChange('removeTranscripts')}
            btnText={TranslationTranscripts.removeTranscripts}
            fullWidth={true}
            customClassName={'min-full-width'}
            id={'translationsRemoveTranscriptsBtn'}
          />

          <div className="divider full-width mrg-tb36" />

          <ItemButton
            text={TranslationTranscripts.back}
            handler={() => handleCreatePageChange('createMainMenu')}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
};

export default TranslationsTranscripts;

//language
import useLanguageEvents from '../../../../language/features/useLanguageEvents';

const EventsLoadMore = () => {
  //language
  const { EventsGrid } = useLanguageEvents();

  return (
    <div className="flex-row flex-center align-center no-select color-lightgray mrg-t48">
      <div className="loadMore-divider mrg-r24" />
      <p className="no-wrap fs21 fwsb">{EventsGrid.loadMore}</p>
      <div className="loadMore-divider mrg-l24" />
    </div>
  );
};

export default EventsLoadMore;

//language

//utils
import { logo_cbg } from '../../assets/images/explore';

const ExploreMyndFullBanner = () => {
  //language
  return (
    <div className="explore-banner bg-light-purp">
      <div className="explore-banner-heading explore-base-text">
        <img
          src={logo_cbg}
          alt="MyndFull Logo"
          className="explore-logo access-ob access-o6"
          tabIndex="0"
        />
        <h3 tabIndex="0" className="access-ob access-o6">
          Education is a great way to connect to others and grow as a person.
        </h3>
      </div>
    </div>
  );
};

export default ExploreMyndFullBanner;

import { useState, useEffect, useRef } from 'react';

//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import { useUpdateCommunityFollowingMutation } from '../../../accountCommunityApiSlice';
import { useSettingsHandlers } from '../../../../settings/useSettingsHandlers';
import { useAccountCommunityHandlers } from '../../../useAccountCommunityHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//Components
import FollowTagButton from '../../../../../../components/FollowTagButton/FollowTagButton';
import SearchCommunityInput from '../../../../../../components/SearchCommunityInput/SearchCommunityInput';
import CommunityGroups from '../../../../../../components/CommunityGroups/CommunityGroups';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import ButtonTextSimple from '../../../../../../components/ButtonTextSimple/ButtonTextSimple';

//Utility
import { deepEqual } from '../../../../../../utils/logic/deepEqual';
import { img_download, img_social } from '../../../../../../assets/icons';
import { arrayIncludesAll } from '../../../../../../utils/logic/arrayIncludesAll';

const CommunityEventSearch = ({ Account }) => {
  //Hooks
  const {
    accountCommunityFollowing: loadAccountAttendeeFollowing,
    accountCommunityFollowingGroups: loadAccountAttendeeFollowingGroups,
    accountId,
    accountCommunityContacts,
    accountCommunityContactsRequested,
    accountCommunity,
  } = useAccountReduxHandlers();
  const { handleGetAccountCommunityLists, getAccountCommunityListsIsLoading } =
    useAccountCommunityHandlers();
  const {
    handleSettingsSetSelectAllAccountsFollowingBtn,
    handleSettingsSetSelectedAccountsFollowingIds,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();

  //language
  const { CommunityGroups: CommunityGroupsLanguage, IconAlts } =
    useLanguageComponents();

  //api
  const [
    updateAccountCommunityFollowing,
    { isLoading: updateAccountCommunityFollowingIsLoading },
  ] = useUpdateCommunityFollowingMutation();

  //Component state
  const [following, setFollowing] = useState([]);
  const [followingGroups, setFollowingGroups] = useState([]);
  const [resetDefault, setResetDefault] = useState(false);

  const [attendeeFollowsAllContacts, setAttendeeFollowsAllContacts] =
    useState(false);

  //group
  const [modifyGroup, setModifyGroup] = useState();
  const [communitySaveValid, setCommunitySaveValid] = useState(false);
  const [communityGroupsSaveValid, setCommunityGroupsSaveValid] =
    useState(false);

  //UI state
  const [communitySaveIsLoading, setCommunitySaveIsLoading] = useState(false); //use as parent loading state
  const [communityContainerRefHeight, setCommunityContainerRefHeight] =
    useState();

  const communitySaveIsLoadingAnimationTimerRef = useRef();
  const communityContainerRef = useRef(); //container with user profile tags will depopulate during update and their width will decrease, causing the height to change, and thus causing a janky shift during the save

  //Initialize functions
  useEffect(() => {
    const newAccountCommunityPopulated = accountCommunity;

    if (newAccountCommunityPopulated?.following) {
      setFollowing(newAccountCommunityPopulated.following);
    }
    if (newAccountCommunityPopulated?.followingGroups) {
      setFollowingGroups(newAccountCommunityPopulated.followingGroups);
    }
  }, [accountCommunity]); //set component state with populated account data

  useEffect(() => {
    if (
      getAccountCommunityListsIsLoading ||
      updateAccountCommunityFollowingIsLoading
    ) {
      setCommunitySaveIsLoading(true);
    } else if (communitySaveIsLoading) {
      communitySaveIsLoadingAnimationTimerRef.current = setTimeout(() => {
        setCommunityContainerRefHeight();
        setCommunitySaveIsLoading(false);
      }, 500);
    }
  }, [
    getAccountCommunityListsIsLoading,
    updateAccountCommunityFollowingIsLoading,
  ]);

  // return () => clearTimeout(communitySaveIsLoadingAnimationTimerRef.current);

  //Component funcitons
  useEffect(() => {
    let followingWithNoPopulatedData = [];
    let accountFollowingWithNoPopulatedData = [];

    following?.map((acct) => {
      if (acct?._id) followingWithNoPopulatedData.push(acct?._id);
    });

    loadAccountAttendeeFollowing?.map((acct) => {
      if (acct?._id) accountFollowingWithNoPopulatedData.push(acct?._id);
    });

    const sameContentsAccountFollowing = followingWithNoPopulatedData.every(
      (value) => accountFollowingWithNoPopulatedData.includes(value)
    );

    if (
      (!sameContentsAccountFollowing ||
        followingWithNoPopulatedData?.length !==
          loadAccountAttendeeFollowing?.length) &&
      !modifyGroup
    ) {
      setCommunitySaveValid(true);
    } else {
      setCommunitySaveValid(false);
    }
  }, [following, loadAccountAttendeeFollowing, modifyGroup]); //checks valid save

  useEffect(() => {
    let followingGroupWithNoPopulatedData = [];
    let accountFollowingGroupWithNoPopulatedData = [];

    for (const group of followingGroups || []) {
      let followingWithNoPopulatedData = [];

      if (group?.following?.length > 0) {
        for (const acct of group?.following) {
          followingWithNoPopulatedData.push(acct?._id);
        }
      }

      let formattedGroup = {
        ...group,
        following: followingWithNoPopulatedData,
      };
      followingGroupWithNoPopulatedData.push(formattedGroup);
    }

    for (const group of loadAccountAttendeeFollowingGroups || []) {
      let newGroupArr = [];

      for (const acct of group?.following || []) {
        if (acct?._id) newGroupArr.push(acct?._id);
        if (acct && !acct?._id) newGroupArr.push(acct);
      }

      let formattedGroup = {
        ...group,
        following: newGroupArr,
      };
      accountFollowingGroupWithNoPopulatedData.push(formattedGroup);
    }

    const sameContentsAccountFollowingGroups =
      followingGroupWithNoPopulatedData.every((value, index) =>
        deepEqual(value, accountFollowingGroupWithNoPopulatedData[index])
      );

    // console.log(
    //   'followingGroupWithNoPopulatedData',
    //   followingGroupWithNoPopulatedData
    // );
    // console.log(
    //   'accountFollowingGroupWithNoPopulatedData',
    //   accountFollowingGroupWithNoPopulatedData
    // );

    if (
      (!sameContentsAccountFollowingGroups ||
        followingGroups?.length !==
          loadAccountAttendeeFollowingGroups?.length) &&
      !modifyGroup
    ) {
      // console.log('communityGroupsSaveValid(TRUE)');
      setCommunityGroupsSaveValid(true);
    } else {
      // console.log('communityGroupsSaveValid(FALSE)');
      setCommunityGroupsSaveValid(false);
    }
  }, [followingGroups, loadAccountAttendeeFollowingGroups, modifyGroup]); //checks valid save

  useEffect(() => {
    checkForUnfollowedContacts();
    /*eslint-disable-next-line*/
  }, [
    followingGroups,
    following,
    accountCommunityContacts,
    accountCommunityContactsRequested,
    loadAccountAttendeeFollowingGroups,
  ]); //check to render import unfollowed contacts

  function checkForUnfollowedContacts() {
    let allFollowingIds = [];

    following?.map((acct) => allFollowingIds.push(acct?._id));
    followingGroups?.map((group) => {
      group?.following?.map((acct) => allFollowingIds.push(acct?._id));
    });

    // accountCommunityPopulated
    const populatedCommunity = accountCommunity;
    let allContactIdsThatCanBeFollowed = [];

    populatedCommunity?.contacts?.map((acct) => {
      if (
        acct?.organizer?.organizerModeActive ||
        (acct?.speaker?.speakerModeActive &&
          acct?.speaker?.communitySettings?.attendeeFollowRequests)
      ) {
        allContactIdsThatCanBeFollowed.push(acct?._id);
      }
    });

    populatedCommunity?.contactsRequested?.map((acct) => {
      if (
        acct?.organizer?.organizerModeActive ||
        (acct?.speaker?.speakerModeActive &&
          acct?.speaker?.communitySettings?.attendeeFollowRequests)
      ) {
        allContactIdsThatCanBeFollowed.push(acct?._id);
      }
    });

    const followsAllIds = arrayIncludesAll(
      allContactIdsThatCanBeFollowed,
      allFollowingIds
    );

    setAttendeeFollowsAllContacts(followsAllIds);
  }

  function handleTransferCommunityContacts() {
    let allFollowingIds = [];

    following?.map((acct) => allFollowingIds.push(acct?._id));
    followingGroups?.map((group) => {
      group?.following?.map((acct) => allFollowingIds.push(acct?._id));
    });

    let newFollowing = [];

    following?.map((followingAcct) => newFollowing.push(followingAcct));

    //add contacts
    const populatedCommunity = accountCommunity;

    populatedCommunity?.contacts?.map((contact) => {
      if (!allFollowingIds.includes(contact?._id)) {
        newFollowing.push(contact);
      }
    });

    populatedCommunity?.contactsRequested?.map((contactRequested) => {
      if (!allFollowingIds.includes(contactRequested?._id)) {
        newFollowing.push(contactRequested);
      }
    });
    setFollowing(newFollowing);
  }

  //api
  async function handleSaveAccountFollowing() {
    if (communitySaveValid || communityGroupsSaveValid) {
      if (!updateAccountCommunityFollowingIsLoading) {
        const communityLoadingHeight =
          communityContainerRef.current.clientHeight;
        setCommunityContainerRefHeight(communityLoadingHeight);

        let newIds = [];
        let newGroups = [];

        following?.map((accountObj) => newIds.push(accountObj._id));

        followingGroups?.map((communityGroup) => {
          let followingGroupArr = [];

          communityGroup.following?.map((following) => {
            followingGroupArr.push(following?._id);
          });

          let filteredGroupWithIds = {
            ...communityGroup,
            following: followingGroupArr,
          };
          newGroups.push(filteredGroupWithIds);
        });

        await updateAccountCommunityFollowing({
          accountId,
          newFollowing: newIds,
          newFollowingGroups: newGroups,
        });

        await handleGetAccountCommunityLists();

        //activate all selections in event's settings tag list
        handleSettingsSetSelectAllAccountsFollowingBtn(true);
        handleSettingsSetSelectedAccountsFollowingIds(newIds);
        const communityGroupsIds = newGroups?.map((groupObj) => {
          return groupObj.id;
        });
        handleSettingsSetSelectedAccountsFollowingGroupIds(communityGroupsIds);

        setResetDefault(!resetDefault);
      }
    }
  }

  //functs
  function handleTagClick(accountObj) {
    handleRemoveAccount(accountObj);
    if (modifyGroup) {
      handleCommunityGroupAdd(accountObj);
    }
  }

  function handleAddAccount(accountObj) {
    let newArr = [...following, accountObj];

    setFollowing(newArr);
  }

  function handleRemoveAccount(accountObj) {
    let newArr = [...following];
    const newArrWithRemoved = newArr.filter(
      (obj) => obj._id !== accountObj._id
    );
    setFollowing(newArrWithRemoved);
  }

  function handleCommunityGroupAdd(accountObj) {
    let newArr = [...followingGroups];

    const adjustedAccountFollowingGroups = newArr?.map((communityGroup) => {
      if (modifyGroup === communityGroup.id) {
        return {
          ...communityGroup,
          following: [...communityGroup.following, accountObj],
        };
      } else {
        return communityGroup;
      }
    });
    setFollowingGroups(adjustedAccountFollowingGroups);
  }

  function handleTransferAccountFollowing(accountObj) {
    handleAddAccount(accountObj);
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="communityLabel"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={img_social}
            alt={IconAlts.iconCommunity}
            style={{ height: '22px' }}
            className="filter-theme mrg-r12"
          />

          <p>{Account.attendee.community}</p>
        </div>
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.communityDescription}
      </p>

      <div
        className={`${communitySaveIsLoading ? 'opacity-0' : ''}`}
        ref={communityContainerRef}
        style={
          communityContainerRefHeight
            ? { minHeight: `${communityContainerRefHeight}px` }
            : null
        }
      >
        {!attendeeFollowsAllContacts && (
          <ButtonTextSimple
            iconOn={true}
            icon={img_download}
            iconClass={'filter-gray mrg-r12 mrg-t2'}
            iconStyle={{ height: '18px' }}
            text={Account.attendee.importUnfollowedContacts}
            handler={handleTransferCommunityContacts}
            customClassText={'color-gray'}
            customWrapper={'mrg-t12'}
          />
        )}

        <div className="h24" />
        <SearchCommunityInput
          accountId={accountId}
          handleSelection={handleAddAccount}
          accountFollowing={following}
          accountFollowingGroups={followingGroups}
        />

        <div className="dropdown-tags__wrapper">
          <ul className="tags__container">
            {following?.map((accountObj, index) => {
              return (
                <li
                  key={`follow-tag-btn-${index}-${accountObj?._id?.slice(
                    0,
                    8
                  )}`}
                >
                  <FollowTagButton
                    accountObj={accountObj}
                    modifyGroup={modifyGroup}
                    handleTagClick={handleTagClick}
                    CustomLanguage={CommunityGroupsLanguage}
                    index={index}
                    key={`random-${index}`}
                  />
                </li>
              );
            })}
          </ul>

          <CommunityGroups
            accountFollowing={following}
            setAccountFollowing={setFollowing}
            accountFollowingGroups={followingGroups}
            setAccountFollowingGroups={setFollowingGroups}
            modifyGroup={modifyGroup}
            setModifyGroup={setModifyGroup}
            handleTransferAccountFollowing={handleTransferAccountFollowing}
            resetDefault={loadAccountAttendeeFollowingGroups}
          />
        </div>
      </div>
      <div className="h24" />
      <SaveButton
        saveValid={communitySaveValid || communityGroupsSaveValid}
        handler={() => handleSaveAccountFollowing()}
        saveAriaLanguage={Account.attendee.community}
        isLoading={communitySaveIsLoading}
      />
      <div className="h48" />
    </div>
  );
};

export default CommunityEventSearch;

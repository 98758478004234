import { useEffect, useState } from 'react';

//Hooks
import { useApiStatus } from '../../../context/ApiStatusProvider';
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useSettings } from '../../../context/SettingsProvider';

//language
import useLanguageAuth from '../../../language/features/useLanguageAuth';
import useLanguageComponents from '../../../language/useLanguageComponents';

//Components
import TextArea from '../../../components/TextArea/TextArea';
import ItemButton from '../../../components/ItemButton/ItemButton';

import Spinner from '../../../components/Spinner/Spinner';

//Utility
import {
  cancel_lightgray,
  checkmark_green,
  search_dark,
} from '../../../assets/icons';
import { axiosLimited } from '../../../axios/axios';
import ScrollTo from '../../../utils/UI/ScrollTo';

const ForgotPassword = () => {
  //Hooks
  const { handleError, handleSuccess } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { handleMobileTap } = useSettings();

  //language
  const { Auth } = useLanguageAuth();
  const { Generic, EmailInput, IconAlts } = useLanguageComponents();

  //Component state
  const [page, setPage] = useState(0);
  const [email, setEmail] = useState();
  const [validityCheck, setValidityCheck] = useState(false);
  const [validAccountEmail, setValidAccountEmail] = useState();
  const [resetOption, setResetOption] = useState('primaryEmail');
  const [resendEmailLimit, setResendEmailLimit] = useState(0);
  const [restrictSend, setRestrictSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //UI state
  const [warningHighlight, setWarningHighlight] = useState(false);
  const [tapHighlightSend, setTapHighlightSend] = useState(false);

  //Component variables
  const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const findAccountEmailInputEl = document.getElementById(
    'findAccountEmailInput'
  );
  const findAccountLabelEl = document.getElementById('findAccountLabel');
  const foundAccountLabelEl = document.getElementById('foundAccountLabel');
  const pleaseCheckEmailLabelEl = document.getElementById(
    'pleaseCheckEmailLabel'
  );

  //variables
  let contents;

  //Initialize & UI functions
  useEffect(() => {
    ScrollTo({ scrollToTop: true });
  }, []); //auto scroll to top

  useEffect(() => {
    if (page === 0) {
      if (findAccountLabelEl) {
        findAccountLabelEl.focus();
      }
    }
    if (page === 1) {
      if (foundAccountLabelEl) {
        foundAccountLabelEl.focus();
      }
    }
    if (page === 2) {
      if (pleaseCheckEmailLabelEl) {
        pleaseCheckEmailLabelEl.focus();
      }
    }
  }, [page, foundAccountLabelEl, pleaseCheckEmailLabelEl]); //set focus on page changes

  //API functions

  async function handleFindAccount() {
    const recaptchaVerified = await handleReCaptchaVerify(
      'FIND_ACCOUNT',
      false
    );

    let lowercaseCheckEmail = email?.toLowerCase();

    if (recaptchaVerified !== true) return false;

    if (!isLoading) {
      if (validityCheck) {
        setIsLoading(true);
        try {
          const { data } = await axiosLimited.get(
            `/api/main/auth/emailValidation/${lowercaseCheckEmail}/Account`
          );
          if (data.emailExists) {
            setValidAccountEmail(lowercaseCheckEmail);
            setPage(1);
          } else {
            setValidAccountEmail();
            setEmail();
            findAccountEmailInputEl.value = '';
            const newError = {
              message: 'accountNotFound',
              id: Date.now(),
              origin: 'ForgotPassword.js/handleFindAccount',
            };

            handleError(newError);
          }
        } catch (error) {
          const newError = {
            error,
            id: Date.now(),
            origin: 'ForgotPassword.js/handleFindAccount',
          };
          handleError(newError);
        }
        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 500);

        return () => {
          clearTimeout(timer);
        };
      } else {
        setWarningHighlight(true);
      }
    }
  }

  async function handleForgotPasswordRequest() {
    if (!isLoading) {
      if (restrictSend) {
        const newError = {
          message: 'pleaseWaitOneMinute',
          id: Date.now(),
          origin: 'ForgotPassword.js/handleForgotPasswordRequest',
        };

        handleError(newError);
      } else if (resendEmailLimit >= 9) {
        const newError = {
          message: 'pleaseContactSupport',
          id: Date.now(),
          origin: 'ForgotPassword.js/handleForgotPasswordRequest',
        };

        handleError(newError);
        //TO DO: should just navigate to support once page exists
      } else {
        setIsLoading(true);
        try {
          const res = await axiosLimited.patch(
            '/api/main/auth/forgotpassword',
            {
              email: validAccountEmail,
            }
          );

          if (res.status === 200) {
            handleSuccess({
              message: 'emailSent',
            });
            setPage(2);
            let newLimit = resendEmailLimit + 1;
            setResendEmailLimit(newLimit);
          }
        } catch (error) {
          const newError = {
            ...error,
            id: Date.now(),
            origin: 'ForgotPassword.js/handleForgotPasswordRequest',
          };
          handleError(newError);
        }

        const timer = setTimeout(() => {
          setIsLoading(false);
        }, 500);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }

  //Component functions
  useEffect(() => {
    const check = EMAIL_REGEX.test(email);
    setValidityCheck(check);
    if (!email && validityCheck) {
      setValidityCheck(false);
    }
  }, [email]);

  useEffect(() => {
    if (resendEmailLimit > 5) {
      setRestrictSend(true);
    }
  }, [resendEmailLimit]);

  useEffect(() => {
    if (restrictSend) {
      const timer = setTimeout(() => {
        setRestrictSend(false);
      }, 60000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [restrictSend]); //responds to restrict send, which comes into effect after 3 attempts, by setting timer to limit next send attempt

  function handleEmailInput(text) {
    let trimmedEmail = text.trim();
    setEmail(trimmedEmail);
  }

  //JSX conditional
  if (page === 0) {
    contents = (
      <div
        className="form-template"
        id="form-template"
        tabIndex="0"
        style={{ maxWidth: '512px' }}
      >
        <label
          className="content-heading-styled text-gray"
          tabIndex="0"
          id="findAccountLabel"
        >
          {Auth.findYourAccount}
        </label>

        <div className="h24" />
        <div>
          <TextArea
            limit={75}
            id={'findAccountEmailInput'}
            labelText={Auth.emailAddress}
            disableEnter={true}
            handleChange={(e) => handleEmailInput(e, 'email')}
            loadTrigger={true}
            onBlurHandlerOff={true}
            asterisk={true}
          />
          <div className="h12" />
          <ul className="inputfield-validation-list access-ob" tabIndex="0">
            <li
              className="flex-row"
              // aria-label={`${
              //   validityCheck
              //     ? EmailInput.ariaValidEmailSuccess
              //     : EmailInput.ariaValidEmailFail
              // }`}
            >
              {validityCheck ? (
                <img
                  src={checkmark_green}
                  alt={IconAlts.iconValid}
                  aria-hidden="true"
                />
              ) : (
                <img
                  src={cancel_lightgray}
                  alt={IconAlts.iconInvalid}
                  aria-hidden="true"
                  className={warningHighlight ? 'filter-red' : ''}
                />
              )}
              <p
                className={`${
                  validityCheck ? 'valid' : warningHighlight ? 'invalid' : ''
                }`}
              >
                {EmailInput.validEmailAddress}
              </p>
            </li>
          </ul>
        </div>

        <div className="h24" />

        <ItemButton
          fullWidth={true}
          ariaLabel={Generic.search}
          icon={search_dark}
          iconOn={true}
          text={Generic.search}
          isLoading={isLoading}
          handler={handleFindAccount}
        />
      </div>
    );
  } else if (page === 1) {
    contents = (
      <div
        className="form-template"
        id="form-template"
        tabIndex="0"
        style={{ maxWidth: '512px' }}
      >
        <label
          className="content-heading-styled text-gray"
          tabIndex="0"
          id="foundAccountLabel"
        >
          {Auth.foundAccount}
        </label>
        <div className="h24" />

        <div className="radio-input">
          <input
            className="radio-dot"
            type="radio"
            name="primaryEmail"
            id={'primaryEmail'}
            value={resetOption}
            checked={resetOption === 'primaryEmail'}
            onChange={() => setResetOption('primaryEmail')}
          />
          <label htmlFor="primaryEmail" className="radio-dot mrg-r12"></label>
          <span
            className="description-text no-select"
            tabIndex="0"
            aria-checked={resetOption === 'primaryEmail'}
            role="radio"
          >{`${Auth.emailLink} ${validAccountEmail}`}</span>
        </div>

        <div className="h24" />

        <button
          type="button"
          tabIndex="0"
          aria-label={Generic.send}
          className={`item-button access-ob fwn fs16 highlight-o-thin-theme ${
            tapHighlightSend ? 'highlight-o-theme--tap' : ''
          }`}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightSend(true),
                () => setTapHighlightSend(false),
              ],
              [() => handleForgotPasswordRequest()]
            )
          }
        >
          {!isLoading ? <>{Generic.send}</> : <Spinner maxHeight={'32px'} />}
        </button>

        <div className="h24" />

        <div className="flex-center">
          <button
            className="text-btn pad-12 access-ob fs16 color-theme"
            tabIndex="0"
            // onClick={() => navigate('/help')}
          >
            {Auth.requestAssistance}
          </button>
        </div>
      </div>
    );
  } else if (page === 2) {
    contents = (
      <>
        <div
          className="form-template"
          id="form-template"
          tabIndex="0"
          style={{ maxWidth: '512px' }}
        >
          {resendEmailLimit <= 10 && (
            <>
              <label
                className="content-heading-styled text-gray"
                tabIndex="0"
                id="pleaseCheckEmailLabel"
              >
                {Auth.checkEmail}
              </label>
              <div className="h12" />
              <div tabIndex="0" className="access-ob access-o6">
                <div className="description-text">
                  {Auth.checkEmailText1}
                  <span className="description-text text-underline">
                    {`${validAccountEmail}`}.
                  </span>
                </div>

                <div className="h12" />
                <div className="description-text">
                  {Auth.checkEmailText2}
                  <button
                    className="description-text text-underline color-blue"
                    onClick={handleForgotPasswordRequest}
                  >
                    {Auth.resendEmail}
                  </button>
                </div>
              </div>
              <div className="h24" />
            </>
          )}

          <div className="flex-center">
            <button
              className="text-btn pad-12 access-ob fs16 color-theme"
              tabIndex="0"
              // onClick={() => navigate('/help')}
            >
              {Auth.requestAssistance}
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="layout">
      <div className="forgotpassword bg-blur">{contents}</div>
    </div>
  );
};

export default ForgotPassword;

//hooks
import { useEffect, useState } from 'react';

//components

//utils
import { getRandomColor } from '../../../../../../utils/UI/randomColorCodes';
import { dropdown_arrow } from '../../../../../../assets/icons';

const InfoCardContainerNetwork = ({ communityData }) => {
  const [openFollowers, setOpenFollowers] = useState(false);
  const [openFollowing, setOpenFollowing] = useState(false);
  const [openFollowingGroups, setOpenFollowingGroups] = useState(false);

  //UI
  const [handleColorsFg, setHandleColorsFg] = useState([]);
  const [handleColorsFgSub, setHandleColorsFgSub] = useState([]);

  const [handleColorsFollowers, setHandleColorsFollowers] = useState([]);
  const [handleColorsFollowing, setHandleColorsFollowing] = useState([]);

  //initialize
  useEffect(() => {
    if (communityData?.followingGroups) {
      let colorArr = [];

      setHandleColorsFgSub([]);

      communityData.followingGroups?.forEach((tG) => {
        colorArr.push(getRandomColor());
        let colorArrSub = [];

        tG?.following?.forEach((_) => colorArrSub.push(getRandomColor()));
        setHandleColorsFgSub([...handleColorsFgSub, colorArrSub]);
      });

      setHandleColorsFg(colorArr);
    }
  }, [communityData?.followingGroups]);

  useEffect(() => {
    if (communityData?.following) {
      let colorArr = [];
      communityData?.following?.map((_) => colorArr.push(getRandomColor()));
      setHandleColorsFollowing(colorArr);
    }
  }, [communityData?.following]);

  useEffect(() => {
    if (communityData?.followers) {
      let colorArr = [];
      communityData?.followers?.map((_) => colorArr.push(getRandomColor()));
      setHandleColorsFollowers(colorArr);
    }
  }, [communityData?.followers]);

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Community</p>

      <div className="flex-row">
        <p className="fs18 fwsb">
          Followed By ({communityData?.organizer?.followers?.length})
        </p>
        {communityData.organizer?.length > 0 && (
          <button
            className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
            style={{ height: '24px' }}
            onClick={() => {
              setOpenFollowers(!openFollowers);
            }}
          >
            <img
              src={dropdown_arrow}
              alt="arrow"
              style={{ height: '10px' }}
              className={`${openFollowers === false ? 'reverse-vertical' : ''}`}
            />
          </button>
        )}
      </div>
      <ul className="info-card-list">
        {openFollowers &&
          communityData.organizer?.followers?.map((acct, index) => {
            return (
              <li className="mrg-tb6" key={`community-followby-${index}`}>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColorsFollowers[index] }}
                />
                <div className="flex-column">
                  <p>
                    Name: {acct?.personal?.firstName} {acct?.personal?.lastName}
                  </p>
                  <p>Account Reference: [{acct?.meta?.accountReference}]</p>
                </div>
              </li>
            );
          })}
      </ul>
      <div className="flex-row">
        <p className="fs18 fwsb">
          Following ({communityData.following?.length})
        </p>
        {communityData.following?.length > 0 && (
          <button
            className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
            style={{ height: '24px' }}
            onClick={() => {
              setOpenFollowing(!openFollowing);
            }}
          >
            <img
              src={dropdown_arrow}
              alt="arrow"
              style={{ height: '10px' }}
              className={`${openFollowing === false ? 'reverse-vertical' : ''}`}
            />
          </button>
        )}
      </div>
      <ul className="info-card-list">
        {openFollowing &&
          communityData.following?.map((acct, index) => {
            return (
              <li className="mrg-tb6" key={`community-follow-${index}`}>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColorsFollowing[index] }}
                />

                <div className="flex-column">
                  <p>
                    Name: {acct?.personal?.firstName} {acct?.personal?.lastName}
                  </p>
                  <p>Account Reference: [{acct?.meta?.accountReference}]</p>
                </div>
              </li>
            );
          })}
      </ul>

      <div className="flex-row">
        <p className="fs18 fwsb">
          Following Groups ({communityData.followingGroups?.length})
        </p>
        {communityData.followingGroups?.length > 0 && (
          <button
            className="flex-center pad-tb12 pad-lr12 mrg-l6 br--standard highlight-btreg-theme highlight-i-gt"
            style={{ height: '24px' }}
            onClick={() => {
              setOpenFollowingGroups(!openFollowingGroups);
            }}
          >
            <img
              src={dropdown_arrow}
              alt="arrow"
              style={{ height: '10px' }}
              className={`${openFollowing === false ? 'reverse-vertical' : ''}`}
            />
          </button>
        )}
      </div>
      <ul className="info-card-list">
        {openFollowingGroups &&
          communityData.followingGroups?.map((acctGroup, index) => {
            const handleColors = handleColorsFgSub[index];
            return (
              <li className="mrg-tb6" key={`community-follow-${index}`}>
                <div
                  className="list-handle"
                  style={{ backgroundColor: handleColorsFg[index] }}
                />
                <p className="fs18 fwsb">
                  {acctGroup?.label} ({acctGroup?.following?.length}):
                </p>
                {acctGroup?.following?.map((acct, index2) => {
                  return (
                    <div
                      className="translations__container mrg-l24 mrg-tb6"
                      key={`${acctGroup?.id}-${index2}`}
                    >
                      <div
                        className="list-handle"
                        style={{
                          backgroundColor: handleColors[index2],
                        }}
                      />
                      <p>
                        Name: {acct?.personal?.firstName}{' '}
                        {acct?.personal?.lastName}
                      </p>
                      <p>Account Reference: [{acct?.meta?.accountReference}]</p>
                    </div>
                  );
                })}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default InfoCardContainerNetwork;

import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../../../../../context/SettingsProvider';

//language
import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';

const ParticipantListItemName = ({
  participant,
  translationsNameOnly,
  dispatches,
}) => {
  //hooks
  const { width } = useSettings();

  //language
  const { ParticipantListItem } = useLanguageOrganize();

  //state
  const [participantName, setParticipantName] = useState();

  useEffect(() => {
    if (translationsNameOnly) {
      if (participant.translationsSpeakerData?.name) {
        return setParticipantName(participant.translationsSpeakerData?.name);
      } else if (
        participant.organizedAs === 'emailInvitation' &&
        participant?.emailInvitation?.email?.length > 0
      ) {
        if (width > 480) {
          return setParticipantName(
            `${ParticipantListItem.participant} ${participant?.index + 1} | ${
              participant?.emailInvitation?.email
            }`
          );
        } else {
          return setParticipantName(`${participant?.emailInvitation?.email}`);
        }
      } else {
        return setParticipantName(
          `${ParticipantListItem.participant} ${participant?.index + 1}`
        );
      }
    } else {
      if (participant.organizedAs === 'emailInvitation') {
        //emailInvitation has no name yet
        if (
          !participant?.participantData?.personal?.firstName ||
          !participant?.participantData?.personal?.lastName
        ) {
          //email invitation has email
          if (
            participant.emailInvitation.email &&
            participant.emailInvitation.emailValid
          ) {
            return setParticipantName(participant.emailInvitation.email);
          }
        }
      }

      if (
        participant?.participantData?.personal?.firstName ||
        participant?.participantData?.personal?.lastName
      ) {
        let name = '';
        if (participant?.participantData?.personal?.prefix?.length > 0) {
          name = participant?.participantData?.personal?.prefix;
        } //add prefix

        name =
          name +
          `${participant?.participantData?.personal?.firstName} ` +
          `${participant?.participantData?.personal?.lastName}`;

        return setParticipantName(name);
      } else if (participant.translationsSpeakerData?.name) {
        return setParticipantName(
          `${participant.translationsSpeakerData?.name}`
        );
      }

      setParticipantName(
        `${ParticipantListItem.participant} ${participant?.index + 1}`
      );
    }
  }, [participant, width]);

  return (
    <div
      className={`flex-row fwsb ${
        dispatches && width <= 480 ? 'flex-center' : ''
      }`}
    >
      {participantName}

      {participant?.participantData?.eventRole && (
        <>{` | ${participant?.participantData?.eventRole}`}</>
      )}
    </div>
  );
};

export default ParticipantListItemName;

import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../../../../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';

//components
import TextArea from '../../../../../../components/TextArea/TextArea';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utils
import {
  checkmark_green,
  img_award,
  img_create,
} from '../../../../../../assets/icons/index';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

const SpeakerCredentials = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const { handleMobileTap } = useSettings();
  const {
    accountSpeakerCredentialsArtisticActivities,
    accountSpeakerCredentialsCertificatesLicenses,
    accountSpeakerCredentialsEducation,
    accountSpeakerCredentialsEmployment,
    accountSpeakerCredentialsHonorsAwards,
    accountSpeakerCredentialsJobExperience,
    accountSpeakerCredentialsPatentDesigns,
    accountSpeakerCredentialsPresentations,
    accountSpeakerCredentialsProjects,
    accountSpeakerCredentialsPublications,
    accountSpeakerCredentialsSkillsExpertise,
    accountSpeakerCredentialsVolunteerExperience,
    handleAccountSpeakerSetCredentialsArtisticActivities,
    handleAccountSpeakerSetCredentialsCertificatesLicenses,
    handleAccountSpeakerSetCredentialsEducation,
    handleAccountSpeakerSetCredentialsEmployment,
    handleAccountSpeakerSetCredentialsHonorsAwards,
    handleAccountSpeakerSetCredentialsJobExperience,
    handleAccountSpeakerSetCredentialsPresentations,
    handleAccountSpeakerSetCredentialsPatentDesigns,
    handleAccountSpeakerSetCredentialsProjects,
    handleAccountSpeakerSetCredentialsPublications,
    handleAccountSpeakerSetCredentialsSkillsExpertise,
    handleAccountSpeakerSetCredentialsVolunteerExperience,
    accountSpeakerSignUpComplete,
  } = useAccountReduxHandlers();

  //language
  const { CredentialOpts } = useLanguageData();
  const { SpeakerCredentials } = useLanguageAccount();
  const { IconAlts } = useLanguageComponents();

  //state
  const [selectedCredentials, setSelectedCredentials] = useState([]);
  const [tapHighlightCredentialBtn, setTapHighlightCredentialBtn] = useState();
  const [isLoadingTapUI, setIsLoadingTapUI] = useState(false);
  const [saveValid, setSaveValid] = useState(false);

  //registered
  const [newArtisticActivities, setNewArtisticActivities] = useState(
    accountSpeakerCredentialsArtisticActivities || ''
  );
  const [newCertificatesLicenses, setNewCertificatesLicenses] = useState(
    accountSpeakerCredentialsCertificatesLicenses || ''
  );
  const [newEducation, setNewEducation] = useState(
    accountSpeakerCredentialsEducation || ''
  );
  const [newEmployment, setNewEmployment] = useState(
    accountSpeakerCredentialsEmployment || ''
  );
  const [newHonorsAwards, setNewHonorsAwards] = useState(
    accountSpeakerCredentialsHonorsAwards || ''
  );
  const [newJobExperience, setNewJobExperience] = useState(
    accountSpeakerCredentialsJobExperience || ''
  );
  const [newPresentations, setNewPresentations] = useState(
    accountSpeakerCredentialsPresentations || ''
  );
  const [newPatentDesigns, setNewPatentDesigns] = useState(
    accountSpeakerCredentialsPatentDesigns || ''
  );
  const [newProjects, setNewProjects] = useState(
    accountSpeakerCredentialsProjects || ''
  );
  const [newPublications, setNewPublications] = useState(
    accountSpeakerCredentialsPublications || ''
  );
  const [newSkillsExpertise, setNewSkillsExpertise] = useState(
    accountSpeakerCredentialsSkillsExpertise || ''
  );
  const [newVolunteerExperience, setNewVolunteerExperience] = useState(
    accountSpeakerCredentialsVolunteerExperience || ''
  );

  //variables
  const initializeEffectRan = useRef(false);

  useEffect(() => {
    if (initializeEffectRan.current === false) {
      initializeEffectRan.current = true;
      let newArr = [];
      if (accountSpeakerCredentialsArtisticActivities?.length > 0)
        newArr.push('artisticActivities');
      if (accountSpeakerCredentialsCertificatesLicenses?.length > 0)
        newArr.push('certificatesLicenses');
      if (accountSpeakerCredentialsEducation?.length > 0)
        newArr.push('education');
      if (accountSpeakerCredentialsEmployment?.length > 0)
        newArr.push('employment');
      if (accountSpeakerCredentialsHonorsAwards?.length > 0)
        newArr.push('honorsAwards');
      if (accountSpeakerCredentialsJobExperience?.length > 0)
        newArr.push('jobExperience');
      if (accountSpeakerCredentialsPatentDesigns?.length > 0)
        newArr.push('patentDesigns');
      if (accountSpeakerCredentialsPresentations?.length > 0)
        newArr.push('presentations');
      if (accountSpeakerCredentialsProjects?.length > 0)
        newArr.push('projects');
      if (accountSpeakerCredentialsPublications?.length > 0)
        newArr.push('publications');
      if (accountSpeakerCredentialsSkillsExpertise?.length > 0)
        newArr.push('skillsExpertise');
      if (accountSpeakerCredentialsVolunteerExperience?.length > 0)
        newArr.push('volunteerExperience');
      setSelectedCredentials(newArr);
    }
  }, []);

  useEffect(() => {
    if (newArtisticActivities !== accountSpeakerCredentialsArtisticActivities) {
      setSaveValid(true);
    } else if (
      newCertificatesLicenses !== accountSpeakerCredentialsCertificatesLicenses
    ) {
      setSaveValid(true);
    } else if (newEducation !== accountSpeakerCredentialsEducation) {
      setSaveValid(true);
    } else if (newEmployment !== accountSpeakerCredentialsEmployment) {
      setSaveValid(true);
    } else if (newHonorsAwards !== accountSpeakerCredentialsHonorsAwards) {
      setSaveValid(true);
    } else if (newJobExperience !== accountSpeakerCredentialsJobExperience) {
      setSaveValid(true);
    } else if (newPresentations !== accountSpeakerCredentialsPresentations) {
      setSaveValid(true);
    } else if (newPatentDesigns !== accountSpeakerCredentialsPatentDesigns) {
      setSaveValid(true);
    } else if (newProjects !== accountSpeakerCredentialsProjects) {
      setSaveValid(true);
    } else if (newPublications !== accountSpeakerCredentialsPublications) {
      setSaveValid(true);
    } else if (
      newSkillsExpertise !== accountSpeakerCredentialsSkillsExpertise
    ) {
      setSaveValid(true);
    } else if (
      newVolunteerExperience !== accountSpeakerCredentialsVolunteerExperience
    ) {
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
  }, [
    newArtisticActivities,
    newCertificatesLicenses,
    newEducation,
    newEmployment,
    newHonorsAwards,
    newJobExperience,
    newPresentations,
    newPatentDesigns,
    newProjects,
    newPublications,
    newSkillsExpertise,
    newVolunteerExperience,
    accountSpeakerCredentialsArtisticActivities,
    accountSpeakerCredentialsCertificatesLicenses,
    accountSpeakerCredentialsEducation,
    accountSpeakerCredentialsEmployment,
    accountSpeakerCredentialsHonorsAwards,
    accountSpeakerCredentialsJobExperience,
    accountSpeakerCredentialsPatentDesigns,
    accountSpeakerCredentialsPresentations,
    accountSpeakerCredentialsProjects,
    accountSpeakerCredentialsPublications,
    accountSpeakerCredentialsSkillsExpertise,
    accountSpeakerCredentialsVolunteerExperience,
  ]); //validate changes

  function sortList(dataList) {
    const dataArray = Object.entries(dataList);

    dataArray.sort(([keyA, valueA], [keyB, valueB]) => {
      if (valueA < valueB) return -1;
      if (valueA > valueB) return 1;
      return 0;
    });

    return dataArray;
  }

  function handleCredentialSelect(key) {
    if (isLoadingTapUI) return;
    setIsLoadingTapUI(true);
    handleMobileTap(
      [
        () => setTapHighlightCredentialBtn(key),
        () => setTapHighlightCredentialBtn(),
      ],
      [() => credentialsSelect(), () => setIsLoadingTapUI(false)]
    );

    function credentialsSelect() {
      let newArr = [...selectedCredentials];
      if (!selectedCredentials.includes(key)) {
        newArr.push(key);
      } else {
        newArr = newArr.filter((k) => k !== key);
        //empty string value
        if (key === 'artisticActivities') {
          if (accountSpeakerSignUpComplete) {
            setNewArtisticActivities('');
          } else {
            handleAccountSpeakerSetCredentialsArtisticActivities('');
          }
        } else if (key === 'certificatesLicenses') {
          if (accountSpeakerSignUpComplete) {
            setNewCertificatesLicenses('');
          } else {
            handleAccountSpeakerSetCredentialsCertificatesLicenses('');
          }
        } else if (key === 'education') {
          if (accountSpeakerSignUpComplete) {
            setNewEducation('');
          } else {
            handleAccountSpeakerSetCredentialsEducation('');
          }
        } else if (key === 'employment') {
          if (accountSpeakerSignUpComplete) {
            setNewEmployment('');
          } else {
            handleAccountSpeakerSetCredentialsEmployment('');
          }
        } else if (key === 'honorsAwards') {
          if (accountSpeakerSignUpComplete) {
            setNewHonorsAwards('');
          } else {
            handleAccountSpeakerSetCredentialsHonorsAwards('');
          }
        } else if (key === 'jobExperience') {
          if (accountSpeakerSignUpComplete) {
            setNewJobExperience('');
          } else {
            handleAccountSpeakerSetCredentialsJobExperience('');
          }
        } else if (key === 'patentDesigns') {
          if (accountSpeakerSignUpComplete) {
            setNewPatentDesigns('');
          } else {
            handleAccountSpeakerSetCredentialsPatentDesigns('');
          }
        } else if (key === 'presentations') {
          if (accountSpeakerSignUpComplete) {
            setNewPresentations('');
          } else {
            handleAccountSpeakerSetCredentialsPresentations('');
          }
        } else if (key === 'projects') {
          if (accountSpeakerSignUpComplete) {
            setNewProjects('');
          } else {
            handleAccountSpeakerSetCredentialsProjects('');
          }
        } else if (key === 'publications') {
          if (accountSpeakerSignUpComplete) {
            setNewPublications('');
          } else {
            handleAccountSpeakerSetCredentialsPublications('');
          }
        } else if (key === 'skillsExpertise') {
          if (accountSpeakerSignUpComplete) {
            setNewSkillsExpertise('');
          } else {
            handleAccountSpeakerSetCredentialsSkillsExpertise('');
          }
        } else if (key === 'volunteerExperience') {
          if (accountSpeakerSignUpComplete) {
            setNewVolunteerExperience('');
          } else {
            handleAccountSpeakerSetCredentialsVolunteerExperience('');
          }
        }
      }
      setSelectedCredentials(newArr);
    }
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="speakerCredentialsLabel"
        tabIndex="0"
      >
        <img
          src={img_award}
          alt={IconAlts.iconAward}
          style={{ height: '26px' }}
          className="mrg-r12 filter-theme"
        />
        {SpeakerCredentials.credentials}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {SpeakerCredentials.description}
      </p>
      <ul
        className={`add-category-list mrg-t12 min-full-width ${
          selectedCredentials?.length > 0 ? 'mrg-b24' : ''
        }`}
      >
        {sortList(CredentialOpts)?.map(([key, value], index) => {
          return (
            <li
              className="fit-content min-full-width"
              key={`credentials-${index}`}
            >
              <button
                onClick={() => handleCredentialSelect(key)}
                className={`flex-row  br--standard align-center pad-6 highlight-bthin-theme mrg-t12 min-full-width access-ob access-o6 ${
                  tapHighlightCredentialBtn === key
                    ? 'highlight-b-theme--tap '
                    : 'highlight-bthin-theme'
                }`}
              >
                {selectedCredentials.includes(key) ? (
                  <img
                    src={checkmark_green}
                    alt="+"
                    className="pad-4"
                    style={{ height: '24px' }}
                  />
                ) : (
                  <img
                    src={img_create}
                    alt="+"
                    className="filter-gray"
                    style={{ height: '24px' }}
                  />
                )}
                <p className="mrg-l6 fs16 fwn">{value}</p>
              </button>
            </li>
          );
        })}
      </ul>

      {selectedCredentials.includes('artisticActivities') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewArtisticActivities
              : handleAccountSpeakerSetCredentialsArtisticActivities
          }
          limit={2000}
          id={'artisticActivities'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsArtisticActivities}
          labelText={CredentialOpts.artisticActivities}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('certificatesLicenses') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewCertificatesLicenses
              : handleAccountSpeakerSetCredentialsCertificatesLicenses
          }
          limit={2000}
          id={'certificatesLicenses'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsCertificatesLicenses}
          labelText={CredentialOpts.certificatesLicenses}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('education') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewEducation
              : handleAccountSpeakerSetCredentialsEducation
          }
          limit={2000}
          id={'education'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsEducation}
          labelText={CredentialOpts.education}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('employment') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewEmployment
              : handleAccountSpeakerSetCredentialsEmployment
          }
          limit={2000}
          id={'employment'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsEmployment}
          labelText={CredentialOpts.employment}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('honorsAwards') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewHonorsAwards
              : handleAccountSpeakerSetCredentialsHonorsAwards
          }
          limit={2000}
          id={'honorsAwards'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsHonorsAwards}
          labelText={CredentialOpts.honorsAwards}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('jobExperience') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewJobExperience
              : handleAccountSpeakerSetCredentialsJobExperience
          }
          limit={2000}
          id={'jobExperience'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsJobExperience}
          labelText={CredentialOpts.jobExperience}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('patentDesigns') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewPatentDesigns
              : handleAccountSpeakerSetCredentialsPatentDesigns
          }
          limit={2000}
          id={'patentDesigns'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsPatentDesigns}
          labelText={CredentialOpts.patentDesigns}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('presentations') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewPresentations
              : handleAccountSpeakerSetCredentialsPresentations
          }
          limit={2000}
          id={'presentations'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsPresentations}
          labelText={CredentialOpts.presentations}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('projects') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewProjects
              : handleAccountSpeakerSetCredentialsProjects
          }
          limit={2000}
          id={'projects'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsProjects}
          labelText={CredentialOpts.projects}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('publications') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewPublications
              : handleAccountSpeakerSetCredentialsPublications
          }
          limit={2000}
          id={'publications'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsPublications}
          labelText={CredentialOpts.publications}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('skillsExpertise') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewSkillsExpertise
              : handleAccountSpeakerSetCredentialsSkillsExpertise
          }
          limit={2000}
          id={'skillsExpertise'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsSkillsExpertise}
          labelText={CredentialOpts.skillsExpertise}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      {selectedCredentials.includes('volunteerExperience') && (
        <TextArea
          handleChange={
            accountSpeakerSignUpComplete
              ? setNewVolunteerExperience
              : handleAccountSpeakerSetCredentialsVolunteerExperience
          }
          limit={2000}
          id={'volunteerExperience'}
          loadTrigger={true}
          loadValue={accountSpeakerCredentialsVolunteerExperience}
          labelText={CredentialOpts.volunteerExperience}
          disableEnter={false}
          customWrapper={'mrg-t24'}
          hideWordCount={true}
          medium={true}
        />
      )}
      <div className="h24" />
      {accountSpeakerSignUpComplete && (
        <SaveButton
          saveValid={saveValid}
          saveAriaLanguage={SpeakerCredentials.credentials}
          handler={() =>
            saveAccountChange(
              {
                credentials: {
                  artisticActivities: newArtisticActivities,
                  certificatesLicenses: newCertificatesLicenses,
                  education: newEducation,
                  employment: newEmployment,
                  honorsAwards: newHonorsAwards,
                  jobExperience: newJobExperience,
                  presentations: newPresentations,
                  patentDesigns: newPatentDesigns,
                  projects: newProjects,
                  publications: newPublications,
                  skillsExpertise: newSkillsExpertise,
                  volunteerExperience: newVolunteerExperience,
                },
              },
              'speakerCredentials'
            )
          }
          isLoading={loadingSaveBtnUI === 'speakerCredentials'}
        />
      )}
    </>
  );
};

export default SpeakerCredentials;

import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useApiStatus } from '../../context/ApiStatusProvider';
import { useSettingsHandlers } from '../../features/main/settings/useSettingsHandlers';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//api
import { useUpdateAccountFollowingMutation } from '../../features/main/account/accountCommunityApiSlice';

//Utility
import { eventcard_follow, eventcard_follow_green } from '../../assets/icons';

const FollowButton = ({
  followableAccountId,
  customWrapper,
  customWrapperStyle,
  borderOn,
  noFollowMrgBtn,
  textOnLeft,
  customFollowIcon,
}) => {
  //Hooks
  const { handleWarning } = useApiStatus();
  const { handleMobileTap } = useSettings();
  const {
    accountId,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
    accountCommunityBlockedAccounts,
  } = useAccountReduxHandlers();
  const {
    handleSettingsSetSelectedAccountsFollowingIds,
    settingsSelectedAccountsFollowingIds,
  } = useSettingsHandlers();

  //language
  const { FollowButton, IconAlts } = useLanguageComponents();

  //API
  const [
    updateAccountFollowing,
    { isLoading: updateAccountFollowingIsLoading },
  ] = useUpdateAccountFollowingMutation();

  //Component state
  const [isFollowing, setIsFollowing] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);

  //UI state
  const [tapHighlightFollow, setTapHighlightFollow] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (followableAccountId && accountId) {
      let followingIds = [];

      accountCommunityFollowing?.map((obj) => {
        return followingIds.push(obj._id);
      });

      accountCommunityFollowingGroups?.map((groupObj) => {
        groupObj?.following?.map((acct) => followingIds.push(acct._id));
      });
      setIsFollowing(followingIds.includes(followableAccountId));
    }
  }, [
    followableAccountId,
    accountId,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  ]); // set isFollowing

  useEffect(() => {
    if (followableAccountId && accountId) {
      let blockedIds = [];
      accountCommunityBlockedAccounts?.map((acct) =>
        blockedIds.push(acct?._id)
      );

      if (blockedIds?.includes(followableAccountId)) {
        setIsBlocked(true);
      } else {
        setIsBlocked(false);
      }
    }
  }, [accountCommunityBlockedAccounts, followableAccountId]); //determine interaction status

  //API functions
  async function handleFollowAccount() {
    if (followableAccountId.toString() === accountId.toString())
      return handleWarningNoInteractions();
    if (isBlocked) return handleWarningAccountIsBlocked();

    if (!updateAccountFollowingIsLoading) {
      setIsFollowing(!isFollowing);

      await updateAccountFollowing({
        accountId,
        followingId: followableAccountId,
      });

      //add new follow account to active tags for communtiy event search
      if (
        !settingsSelectedAccountsFollowingIds?.includes(followableAccountId)
      ) {
        let newArr = [];
        if (settingsSelectedAccountsFollowingIds?.length > 0) {
          newArr = [...settingsSelectedAccountsFollowingIds];
        }

        newArr.push(followableAccountId);
        handleSettingsSetSelectedAccountsFollowingIds(newArr);
      }
    }
  }

  //functions
  function handleFollowClickWithDelay() {
    let timeout = null;
    timeout = setTimeout(() => {
      handleFollowAccount();
    }, 300);
    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }

  function handleWarningNoInteractions() {
    return handleWarning({
      message: 'cannotInteractWithYourOwnAccount',
      origin: 'ProfileInteractions.js/handleWarningNoInteractions',
      id: Date.now(),
    });
  }
  function handleWarningAccountIsBlocked() {
    return handleWarning({
      message: 'cannotInteractWithBlockedAccount',
      origin: 'ProfileInteractions.js/handleWarningAccountIsBlocked',
      id: Date.now(),
    });
  }

  return (
    <>
      {accountId &&
      accountId?.toString() !== followableAccountId?.toString() &&
      followableAccountId ? (
        <button
          className={`follow-btn access-ob access-o6 ${
            tapHighlightFollow ? 'click-scaledown-95' : ''
          } 
            ${borderOn ? 'highlight-b-theme border-reg' : ''}
            ${borderOn && tapHighlightFollow ? 'highlight-b-theme--tap' : ''}
            ${customWrapper ? customWrapper : ''} `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightFollow(true),
                () => setTapHighlightFollow(false),
              ],
              [() => handleFollowClickWithDelay()],
              true
            )
          }
          title={
            isFollowing
              ? FollowButton.clickToUnfollow
              : FollowButton.clickToFollow
          }
          style={customWrapperStyle}
        >
          {isFollowing ? (
            <img
              src={eventcard_follow_green}
              alt={IconAlts.iconFollowButton}
              style={{ height: '44px' }}
              className={`${customFollowIcon || ''}`}
            />
          ) : (
            <>
              {textOnLeft && <p className="mrg-r6">{FollowButton.follow}</p>}
              <img
                src={eventcard_follow}
                alt={IconAlts.iconFollowButton}
                style={{ height: '30px' }}
                className={`filter-gray`}
              />
              {!textOnLeft && <p className="mrg-l6">{FollowButton.follow}</p>}
            </>
          )}
        </button>
      ) : (
        <div className={noFollowMrgBtn} />
      )}
    </>
  );
};

export default FollowButton;

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider.js';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom.js';
import useLanguageData from '../../../../../language/useLanguageData.js';

//components
import TextArea from '../../../../../components/TextArea/TextArea.js';
import ButtonPlay from '../../../../../components/ButtonPlay/ButtonPlay.js';
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown.js';
import AutocorrectKeywords from '../../../../../components/AutocorrectKeywords/AutocorrectKeywords.js';

//utils
import { warning } from '../../../../../assets/icons/index.js';

const TranslationsSpeakerInputs = ({
  speaker,
  roomLanguageOpts,
  speakerNameOn,
  setSpeaker,
  roomLanguages,
}) => {
  //hooks
  const { keywordsListLengthMaximum } = useTranslationRoomSocket();

  //language
  const {
    TranslationRoomCreateRoomSpeakers,
    CreateRoomSpeakersVoiceAndLanguage,
    CreateRoomSpeakersAutocorrect,
  } = useLanguageTranslationRoom();
  const { VoiceOpts } = useLanguageData();

  //Input handlers
  function handleName(val) {
    let newSpeaker = {
      ...speaker,
      translationsSpeakerData: {
        ...speaker.translationsSpeakerData,
      },
    };
    newSpeaker.translationsSpeakerData.name = val;
    setSpeaker(newSpeaker);
  }

  function handleLanguageOpt(key) {
    let newSpeaker = {
      ...speaker,
      translationsSpeakerData: {
        ...speaker.translationsSpeakerData,
      },
    };
    newSpeaker.translationsSpeakerData.languageOpt = key;
    setSpeaker(newSpeaker);
  }

  function handleVoiceOpt(key) {
    let newSpeaker = {
      ...speaker,
      translationsSpeakerData: {
        ...speaker.translationsSpeakerData,
      },
    };
    newSpeaker.translationsSpeakerData.voiceOpt = key;
    setSpeaker(newSpeaker);
  }

  function handleDiscussionContext(value) {
    let newSpeaker = {
      ...speaker,
      translationsSpeakerData: {
        ...speaker.translationsSpeakerData,
      },
    };
    newSpeaker.translationsSpeakerData.discussionContext = value;
    setSpeaker(newSpeaker);
  }

  function handleKeywordList(newKeywordList) {
    let newSpeaker = {
      ...speaker,
      translationsSpeakerData: {
        ...speaker.translationsSpeakerData,
      },
    };
    newSpeaker.translationsSpeakerData.keywordsList = newKeywordList;
    setSpeaker(newSpeaker);
  }

  return (
    <div
      className={`flex-column full-width mrg-t24 border-thin br--standard bg-color-light pad-24`}
    >
      <div className="flex-column flex-center">
        {speakerNameOn && (
          <TextArea
            handleChange={handleName}
            limit={100}
            id={`${speaker.id}`}
            labelText={`${TranslationRoomCreateRoomSpeakers.name}${
              speaker.index ? ` ${speaker.index + 1}` : ''
            }`}
            disableEnter={true}
            loadValue={speaker?.translationsSpeakerData?.name}
            loadTrigger={true}
            customWrapper={'item-button-standard mrg-b24'}
            onBlurHandlerOff={true}
            asterisk={true}
          />
        )}
        <SelectDropdown
          autoUpdate={true}
          id={'speakerLanguage'}
          list={roomLanguageOpts}
          handleSelection={handleLanguageOpt}
          internalLabel={
            CreateRoomSpeakersVoiceAndLanguage.participantsLanguage
          }
          asterisk={true}
          defaultKey={
            speaker?.translationsSpeakerData?.languageOpt !== 'select'
              ? speaker?.translationsSpeakerData?.languageOpt
              : 'select'
          }
          tempDisable={roomLanguages?.length === 0}
          temporaryDisableMessage={'completeRoomLanguages'}
        />

        <SelectDropdown
          autoUpdate={true}
          id={'voiceOptions'}
          list={VoiceOpts}
          handleSelection={handleVoiceOpt}
          internalLabel={CreateRoomSpeakersVoiceAndLanguage.voiceSelection}
          defaultKey={
            speaker?.translationsSpeakerData?.voiceOpt !== 'select'
              ? speaker?.translationsSpeakerData?.voiceOpt
              : 'select'
          }
          asterisk={true}
          tempDisable={
            !speaker?.translationsSpeakerData?.languageOpt ||
            speaker?.translationsSpeakerData?.languageOpt === 'select'
          }
          temporaryDisableMessage={'speakerLanguageSelectionRequired'}
          customWrapper={`mrg-t24`}
        />

        <ButtonPlay
          speakerLanguageOpt={speaker?.translationsSpeakerData?.languageOpt}
          speakerVoiceOpt={speaker?.translationsSpeakerData?.voiceOpt}
        />
      </div>

      <div className={`flex-column full-width`}>
        <p
          className="fs21 fs18--ph fwsb mrg-tb24 text-center access-ob access-o6"
          tabIndex="0"
          aria-label={CreateRoomSpeakersAutocorrect.discussionContext}
          id={'createRoom'}
        >
          {CreateRoomSpeakersAutocorrect.aiAutocorrectionDescription}
        </p>

        <TextArea
          handleChange={handleDiscussionContext}
          labelText={CreateRoomSpeakersAutocorrect.discussionContext}
          large={true}
          limit={250}
          loadTrigger={true}
          loadValue={
            speaker?.translationsSpeakerData?.discussionContext
              ? speaker?.translationsSpeakerData?.discussionContext
              : ''
          }
          asterisk={true}
          onBlurHandlerOff={true}
          disableEnter={true}
        />
        {speaker?.translationsSpeakerData?.discussionContext?.length < 25 && (
          <div className="flex-row mrg-t12">
            <img
              src={warning}
              alt="img"
              style={{ height: '18px' }}
              className="mrg-r12"
            />
            <p className="fs18">
              {CreateRoomSpeakersAutocorrect.minimumCharacterRequirements}
            </p>
          </div>
        )}
        <p
          className="text-center fs21 fs18--ph fwsb mrg-tb24 access-ob access-o6"
          tabIndex="0"
          aria-label={CreateRoomSpeakersAutocorrect.autoCorrectKeywords}
          id={'createRoom'}
        >
          {CreateRoomSpeakersAutocorrect.autoCorrectKeywords}
        </p>
        <AutocorrectKeywords
          limit={keywordsListLengthMaximum}
          index={speaker?.index}
          speakersKeywordsList={speaker?.translationsSpeakerData?.keywordsList}
          setSpeakersKeywordsList={handleKeywordList}
        />
      </div>
    </div>
  );
};

export default TranslationsSpeakerInputs;

import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';
import { useUpdateRolesMutation } from '../../../accountApiSlice';

//language
import useLanguageAccount from '../../../../../../language/features/useLanguageAccount';

//components
import ButtonSwitch from '../../../../../../components/ButtonSwitch/ButtonSwitch';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//utils

const AttendeeRoleToggle = () => {
  //hooks
  const { accountId, accountAttendeeModeActive: loadAttendeeModeActive } =
    useAccountReduxHandlers();

  //language
  const { Account } = useLanguageAccount();

  //api
  const [updateRoles, { isLoading: updateAccountRolesIsLoading }] =
    useUpdateRolesMutation();

  //component state
  const [rolesChangeValid, setRolesChangeValid] = useState(false);
  const [newAttendeeModeActive, setNewAttendeeModeActive] = useState(
    loadAttendeeModeActive
  );

  //API functions
  async function handleSaveRoleChange() {
    if (rolesChangeValid) {
      if (!updateAccountRolesIsLoading) {
        await updateRoles({
          accountId,
          newData: {
            attendeeRoleChange: newAttendeeModeActive,
          },
        });
        setRolesChangeValid(false);
      }
    }
  }

  useEffect(() => {
    if (newAttendeeModeActive !== loadAttendeeModeActive) {
      setRolesChangeValid(true);
    } else {
      setRolesChangeValid(false);
    }
  }, [newAttendeeModeActive, loadAttendeeModeActive]);

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="attendeeActivationLabel"
      >
        {Account.attendee.attendeeActivation}
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.attendeeActivationDescription}
      </p>

      <ButtonSwitch
        id={'attendeeToggle'}
        loadValue={loadAttendeeModeActive}
        loadTrigger={true}
        handleSwitch={() => setNewAttendeeModeActive(!newAttendeeModeActive)}
        customWrapper={'mrg-t12'}
      />
      <div className="h24" />
      <SaveButton
        saveValid={rolesChangeValid}
        saveAriaLanguage={Account.account.activateAttendeeSection}
        handler={handleSaveRoleChange}
        isLoading={updateAccountRolesIsLoading}
      />
      <div className="h36" />
    </div>
  );
};

export default AttendeeRoleToggle;

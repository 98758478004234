import { useEffect } from 'react';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../../utils/UI/ScrollTo';

const HostSubPageOne = ({ setHostSubPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <>
      <h1 className="fwsb fs21 lh36">Room Code Subscriptions</h1>
      <p className="fs18 lh28 mrg-t24">
        Room codes are obtained by purchasing a room code subscription through a
        MyndFull account. Currently, MyndFull account registration is limited to
        users with pre-launch access codes. If you'd like to request one, please
        contact us at support@myndfull.com.
      </p>

      <p className="fs18 lh28 mrg-t24">
        Importantly, a MyndFull account holder manages the login credentials for
        each room code subscription (logging in as a Translations Host has
        separate portal and credentials than a MyndFull account). The reason for
        this separation is so that the account holder can share their room code
        subscription with others while not exposing their personal login
        credentials or billing information.
      </p>
      <p className="fs18 lh28 mrg-t24">
        This setup is ideal for organizations, departments, or teams who want to
        provide room access to members or employees, as well as for individuals
        who wish to share their subscription with colleagues or friends.
      </p>

      <p className="fs18 lh28 mrg-t24">
        Another notable feature is that you can change your room code at any
        time without needing to cancel or repurchase your subscription. Just
        note that room codes must be unique — so availability is on a
        first-come, first-served basis.
      </p>

      <p className="fs18 lh28 mrg-t24 fwsb">Key Takeaways:</p>
      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          Room code subscriptions are purchased through a MyndFull account.
        </li>
        <li className="mrg-t12">
          Each subscription has its own login credentials, managed by the
          account holder, for easy access sharing.
        </li>
        <li className="mrg-t12">You can change your room code at any time.</li>
      </ul>
      <div className="translations-selections mrg-auto-lr mrg-t36">
        <ItemButton
          text={'Continue'}
          handler={() => setHostSubPage(2)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />

        <ItemButton
          text={'Return'}
          handler={() => setHostSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </>
  );
};

export default HostSubPageOne;

import { useEffect, useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//language
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import FieldValueMissing from './FieldValueMissing';

//utils
import { lock_locked } from '../../../../assets/icons';

const EventPageTranslations = ({
  eventData,
  EventPage,
  eventStatus,
  organizerViewOn,
  decryptedTranslationsPassword,
  isParticipant,
}) => {
  //hooks
  const { IconAlts } = useLanguageComponents();
  const { TranslationLanguagesSingular } = useLanguageData();
  const { accountTranslationRoomsRoomCodes } = useAccountReduxHandlers();
  //state
  const [roomCode, setRoomCode] = useState();

  //variables
  let translationsRoomCodeInfo;
  let translationsRoomPasswordInfo;

  const privateDetailsEnabled =
    eventData.registrationData.privateDetails.enabled;
  const translationsRoomCodeLocked =
    eventData.registrationData.privateDetails.translationsRoomCode;
  const translationsRoomPasswordLocked =
    eventData.registrationData.privateDetails.translationsRoomPassword;

  useEffect(() => {
    if (organizerViewOn && accountTranslationRoomsRoomCodes) {
      const newRoomCode = accountTranslationRoomsRoomCodes?.find((obj) => {
        if (
          obj?._id?.toString() ===
          eventData?.translations?.roomCodeId?.toString()
        ) {
          return obj?.roomCode;
        }
      });
      setRoomCode(newRoomCode?.roomCode);
    }
  }, [organizerViewOn, eventData]);

  if (
    privateDetailsEnabled &&
    translationsRoomCodeLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    translationsRoomCodeInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.roomCode}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (
    organizerViewOn &&
    privateDetailsEnabled &&
    translationsRoomCodeLocked
  ) {
    translationsRoomCodeInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.roomCode}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {eventData?.translations?.roomCode?.length > 0 ||
        roomCode?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {eventData?.translations?.roomCode || roomCode}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    translationsRoomCodeInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.roomCode}:</p>
        <p className="mrg-l6">
          {eventData?.translations?.roomCode || roomCode}
        </p>
      </div>
    );
  }

  if (
    privateDetailsEnabled &&
    translationsRoomPasswordLocked &&
    eventStatus !== 'registeredInPerson' &&
    eventStatus !== 'registeredVirtual' &&
    eventStatus !== 'pendingApprovalInPerson-registeredVirtual' &&
    eventStatus !== 'pendingApprovalVirtual-registeredInPerson' &&
    !isParticipant &&
    !organizerViewOn
  ) {
    translationsRoomPasswordInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.attendeePassword}</p>
        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-l12"
          title={EventPage.valueHiddenForNonRegistered}
        />
      </div>
    );
  } else if (
    organizerViewOn &&
    privateDetailsEnabled &&
    translationsRoomPasswordLocked
  ) {
    translationsRoomPasswordInfo = (
      <div className="ev-info__row access-ob access-o6" tabIndex="0">
        <p>{EventPage.attendeePassword}</p>

        <img
          src={lock_locked}
          alt={IconAlts.iconLock}
          className="lock-img mrg-lr12"
          title={EventPage.valueHiddenForNonRegistered}
        />
        {decryptedTranslationsPassword?.length > 0 ||
        eventData?.translations?.password?.length > 0 ? (
          <p className="previewMode-border outline-offset-2">
            {decryptedTranslationsPassword || eventData?.translations?.password}
          </p>
        ) : (
          <FieldValueMissing />
        )}
      </div>
    );
  } else {
    if (
      decryptedTranslationsPassword?.length > 0 ||
      eventData?.translations?.password?.length > 0
    ) {
      translationsRoomPasswordInfo = (
        <div className="ev-info__row access-ob access-o6" tabIndex="0">
          <p>{EventPage.attendeePassword}:</p>
          <p className="mrg-l6">
            {decryptedTranslationsPassword || eventData?.translations?.password}
          </p>
        </div>
      );
    }
  }

  return (
    <>
      <label
        className="content-heading-styled text-gray mrg-b12"
        tabIndex="0"
        id="translationsLabel"
      >
        {EventPage.myndfullTranslations}
      </label>
      <div className="ev-info__container">
        {translationsRoomCodeInfo}
        {translationsRoomPasswordInfo}
      </div>
      <div
        className="flex-column mrg-t12 fs18 access-ob access-o6"
        tabIndex="0"
      >
        {EventPage.availableLanguages}:
        <ul className="flex-column fs18 mrg-l24 mrg-t12">
          {eventData?.translations?.roomLanguages?.map((lang, index) => {
            return (
              <li
                className={`${
                  index !== eventData?.translations?.roomLanguages?.length - 1
                    ? 'mrg-b6'
                    : ''
                }`}
                key={`translationLang-${index}`}
              >
                {TranslationLanguagesSingular[lang]}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default EventPageTranslations;

{
  /* <ButtonTextSimple
  text={'View'}
  icon={visible_off}
  iconOn={true}
  iconRight={true}
  iconStyle={{ height: '18px' }}
  iconClass={'mrg-l6 filter-gray'}
  customWrapper={'mrg-l12 text-gray'}
  handler={() => function () {}}
/>; */
}

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//hooks
import { useSettingsHandlers } from '../../features/main/settings/useSettingsHandlers';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//utils
import { settings } from '../../assets/icons';
import { useSettings } from '../../context/SettingsProvider';

const SettingsQuickNavigate = ({ settingsDestination, customWrapper }) => {
  //hooks
  const navigate = useNavigate();
  const { handleSettingsSetAccountSettingsView } = useSettingsHandlers();
  const { handleMobileTap, width } = useSettings();

  //language
  const { Generic, IconAlts } = useLanguageComponents();
  const [tapHighlightBtn, setTapHighlightBtn] = useState(false);

  function handleNavigateToAccount(settingsView) {
    handleSettingsSetAccountSettingsView(settingsView);
    navigate('/account');
  }

  function handleNavBtn(e) {
    e.preventDefault();
    handleMobileTap(
      [() => setTapHighlightBtn(true), () => setTapHighlightBtn(false)],
      [() => handleNavigateToAccount(settingsDestination)]
    );
  }

  return (
    <button
      className={`fit-content no-wrap flex-start highlight-b2-theme br--standard  access-ob access-o6 ${
        tapHighlightBtn ? 'highlight-b2-theme--tap' : ''
      } ${customWrapper ? customWrapper : ''}`}
      id="searchSettingsLink"
      onClick={(e) => {
        e.stopPropagation();
        handleNavBtn(e);
      }}
    >
      <div className="align-center" style={{ height: '32px' }}>
        <img
          src={settings}
          alt={IconAlts.iconSettings}
          className={'filter-gray '}
          style={width < 480 ? { height: '28px' } : { height: '21px' }}
        />
        <p className="align-center ff2 fs18 fs16--ph fwn color-gray mrg-l12">
          {Generic.settings}
        </p>
      </div>
    </button>
  );
};

export default SettingsQuickNavigate;

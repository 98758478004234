import { useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//Components
import Spinner from '../Spinner/Spinner';
import useDelayedHandler from '../../hooks/useDelayedHandler';

//Utility

const ButtonMeta = ({
  handleClick,
  ariaLabel,
  btnText,
  activeStateDependency,
  icon,
  iconAlt,
  customClassName,
  iconCustomClassName,
  unavailable,
  availabilityToggle,
  isLoading,
  fullWidth,
  customType,
  iconFilterOff,
  customIconStyle,
}) => {
  //Hooks
  const { handleMobileTap } = useSettings();
  const { delayedHandler } = useDelayedHandler();

  //UI
  const [tapHighlight, setTapHighlight] = useState(false);

  return (
    <button
      className={`button-meta br--standard access-ob fs18 access-o6 color-gray ${
        tapHighlight ? 'highlight-b-theme--tap' : ''
      }  ${
        unavailable && availabilityToggle ? 'opacity-05 pointer-default' : ''
      }  ${
        !unavailable ? 'highlight-b-theme' : 'border-reg'
      } ${customClassName} ${fullWidth ? 'full-width' : 'button-meta-width'} ${
        !iconFilterOff ? 'filter-img-gray' : ''
      } ${activeStateDependency ? 'button-meta--active' : ''} ${
        !iconFilterOff && activeStateDependency ? 'filter-img-theme' : ''
      }`}
      onClick={
        (unavailable && availabilityToggle) || isLoading
          ? null
          : (e) =>
              handleMobileTap(
                [() => setTapHighlight(true), () => setTapHighlight(false)],
                [() => delayedHandler(handleClick, e)]
              )
      }
      aria-label={`${ariaLabel}.`}
      aria-pressed={activeStateDependency}
      type={customType ? customType : 'button'}
    >
      {isLoading ? (
        <Spinner minWidth={'100%'} maxHeight={'100%'} />
      ) : (
        <>
          {icon && (
            <img
              src={icon}
              alt={iconAlt || 'icon'}
              className={`mrg-r12 ${
                iconCustomClassName ? iconCustomClassName : ''
              }`}
              style={customIconStyle}
            />
          )}
          <p>{btnText}</p>
          {activeStateDependency && <div className="button-meta-dot" />}
        </>
      )}
    </button>
  );
};

export default ButtonMeta;

import { createSlice } from '@reduxjs/toolkit';

const adminManagementSlice = createSlice({
  name: 'adminManagement',
  initialState: {
    tickets: [],
  },
  reducers: {
    setAdminManagementTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setAdminManagementTicket: (state, action) => {
      const updatedTicket = action.payload;
      const index = state.tickets.findIndex(
        (ticket) => ticket._id === updatedTicket._id
      );
      if (index !== -1) {
        state.tickets[index] = updatedTicket;
      }
    },
  },
});

export const { setAdminManagementTickets, setAdminManagementTicket } =
  adminManagementSlice.actions;

export default adminManagementSlice.reducer;

export const selectAdminManagementTickets = (state) =>
  state.adminManagement?.tickets;

const EventPageTitle = ({ eventData }) => {
  return (
    <div
      className="ev-divider-bottom full-width flex-center text-center fs21 fwsb  mrg-auto-lr pad-tb24 access-ob"
      tabIndex="0"
      id={'eventPageTitle'}
    >
      {eventData?.generic?.title}
    </div>
  );
};

export default EventPageTitle;

import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

//hooks
import { useApiStatus } from '../../../../context/ApiStatusProvider';
import { useSettings } from '../../../../context/SettingsProvider';
import { useOrganizeHandlers } from '../useOrganizeHandlers';

//language
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//components
import Spinner from '../../../../components/Spinner/Spinner';
import TranslationsSpeakerBase from '../eventSections/EventTranslationsSections/TranslationsSpeakerBase';

//utils
import { checkmark_green } from '../../../../assets/icons';
import { axiosLimited } from '../../../../axios/axios';

const ParticipationTranslationsEmailForm = () => {
  //hooks
  const { participationTranslationsEmailToken } = useParams();
  const { handleWarning, handleError } = useApiStatus();
  const { setNavbar } = useSettings();
  const { handleTranslationsSpeakerDataValidation } = useOrganizeHandlers();

  //language
  const { EventParticipantsEmailInvitationForm } = useLanguageOrganize();

  //state
  const [eventData, setEventData] = useState();
  const [translationsParticipantId, setTranslationsParticipantId] = useState();

  //ui
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);

  //var
  const tokenValidationEffectRan = useRef(false);

  //Initialize
  useEffect(() => {
    if (
      tokenValidationEffectRan.current === false &&
      participationTranslationsEmailToken
    ) {
      setNavbar('');
      tokenValidationEffectRan.current = true;

      if (participationTranslationsEmailToken) {
        handleParticipationTranslationsEmailTokenValidation({
          participationTranslationsEmailToken,
          setPage,
        });
      }
    }
  }, [participationTranslationsEmailToken]); //trigger token validation

  //API Fetch Functions
  async function handleParticipationTranslationsEmailTokenValidation({
    participationTranslationsEmailToken,
    setPage,
  }) {
    try {
      const { data } = await axiosLimited.get(
        `/api/public/validateParticipationTranslationsEmailToken/${participationTranslationsEmailToken}`
      );

      if (data?.expiredToken) {
        setPage(1); //expired page
      }

      if (data?.eventId && data.translationsParticipantId) {
        setTranslationsParticipantId(data.translationsParticipantId);
        await handleGetEvent(data.eventId);
        setPage(3); //submit data page
      }
    } catch (error) {
      setPage(2); //event not found page
      const newError = {
        error,
        id: Date.now(),
        origin:
          'EventParticipantsEmailInvitationForm.js/handleEmailInviteParticipationTokenValidation',
      };

      handleError(newError);
    }
  } //check to see if /:token is valid before rendering page

  async function handleGetEvent(eventId) {
    if (!isLoading) {
      try {
        setIsLoading(true);

        const res = await axiosLimited.get(`/api/public/events/${eventId}`);
        setEventData(res?.data?.event);
      } catch (error) {
        handleError({
          id: Date.now(),
          origin: 'EventParticipantsEmailInvitationForm.js/handleGetEvent',
          error,
        });
      }
      setIsLoading(false);
    }
  }

  async function handleSubmit(speakerData) {
    if (!submitIsLoading) {
      setSubmitIsLoading(true);

      try {
        const completeData = handleTranslationsSpeakerDataValidation(
          speakerData,
          true
        );
        if (!completeData) {
          setSubmitIsLoading(false);
          return handleWarning({
            message: 'incompleteInformation',
            origin: 'TranslationsParticipationForm.js/handleSubmit',
            id: Date.now(),
          });
        }

        const res = await axiosLimited.post(
          '/api/public/uploadEventTranslationsEmailData',
          {
            translationsParticipantId,
            participationTranslationsEmailToken,
            speakerData: speakerData?.translationsSpeakerData,
          }
        );

        if (!res?.error) {
          setPage(4);
        }
      } catch (error) {
        setSubmitIsLoading(false);
      }
    }
  }

  return (
    <div className="mrg-auto-lr min-full-height">
      {page === 0 && <Spinner minHeight={'100%'} />}
      {page === 1 && (
        <div className="float-form__wrapper min-full-height ">
          <div className="float-form__container mrg-auto-top mrg-auto-bottom">
            <label
              className="label"
              tabIndex="0"
              id="invitationExpiredLabel"
              aria-describedby="aria-fieldset-instructions-general"
            >
              {EventParticipantsEmailInvitationForm.invitationExpired}
            </label>
            <p className="fs18">
              {
                EventParticipantsEmailInvitationForm.invitationExpiredDescription
              }
            </p>
          </div>
        </div>
      )}

      {page === 2 && (
        <div className="float-form__wrapper min-full-height ">
          <div className="float-form__container mrg-auto-top mrg-auto-bottom">
            <label
              className="label"
              tabIndex="0"
              id="eventNotFoundLabel"
              aria-describedby="aria-fieldset-instructions-general"
            >
              {EventParticipantsEmailInvitationForm.eventNotFound}
            </label>
            <p className="fs18">
              {EventParticipantsEmailInvitationForm.eventNotFoundDescription}
            </p>
          </div>
        </div>
      )}

      {page === 3 && (
        <div className="mrg-t48">
          <p className="fwb fs21 flex-center">
            {EventParticipantsEmailInvitationForm.participantTranslationsForm}
          </p>
          <TranslationsSpeakerBase
            participantFormSubmissionView={true}
            participantFormSubmissionHandler={handleSubmit}
            roomLanguages={eventData?.translations?.roomLanguages}
            prevSpeaker={{
              id: Date.now(),
              translationsSpeakerData: {
                name: '',
                keywordsList: [],
                languageOpt: 'select',
                voiceOpt: 'select',
                discussionContext: '',
              },
            }}
            submitIsLoading={submitIsLoading || isLoading}
            speakerNameOn={true}
          />
          <div className="h128" />
        </div>
      )}

      {page === 4 && (
        <div className="float-form__wrapper min-full-height ">
          <div className="float-form__container mrg-auto-top mrg-auto-bottom">
            <label
              className="label"
              tabIndex="0"
              id="invitationExpiredLabel"
              aria-describedby="aria-fieldset-instructions-general"
            >
              {EventParticipantsEmailInvitationForm.participantInformationSent}
              <img
                src={checkmark_green}
                className="mrg-l12"
                style={{ height: '18px' }}
                alt=""
              />
            </label>
            <p className="fs18">
              {
                EventParticipantsEmailInvitationForm.participantInformationSentDescription
              }
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticipationTranslationsEmailForm;

import { useState, useEffect, useRef } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Utility
import { visible_off, visible } from '../../assets/icons/index';
import handleInputClick from './handleInputClick';

//only should show a change in validity text with the initial load or onBlur; this is to ensure the text highlighting does not reveal any information regarding the hidden password
const CreatePasswordInput = ({
  asterisk,
  customWidth,
  handleInput,
  id,
  loadValue,
  autocompleteOff,
  customWrapper,
  setValidPassword,
  reset,
}) => {
  //Hooks
  const { dismissKeypad, handleMobileTap } = useSettings();

  //language
  const { PasswordInputs } = useLanguageComponents();

  //Component state
  const [showPassword, setShowPassword] = useState(false);
  const [strengthLevel, setStrengthLevel] = useState(0);

  //UI state
  const [checkRan, setCheckRan] = useState(false);
  const [warning, setWarning] = useState(false);
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);

  const [tapHighlightShowPassword, setTapHighlightShowPassword] =
    useState(false);
  const [strengthLevelColor, setStrengthLevelColor] = useState(
    'strength-level--weak'
  );

  //Component variables
  const passwordInput = useRef();

  //initialize
  useEffect(() => {
    if (loadValue?.length > 0) {
      validation(loadValue);
    }
  }, [loadValue]);

  //Component functions
  useEffect(() => {
    setShowPassword(false);
    setStrengthLevel(0);
    setCheckRan(false);
    setWarning(false);
    setFocusHighlight(false);
    setStrengthLevelColor('strength-level--weak');
  }, [reset]); //resets password

  function validation(value) {
    let newStrength = 0;

    if (value?.length > 0) {
      newStrength++;
    }

    // Check length
    if (value?.length > 5) {
      newStrength++;
    }

    if (value.length >= 9) {
      newStrength++;
    }
    // Check for uppercase and lowercase letters
    if (/[a-z]/.test(value) && /[A-Z]/.test(value)) {
      newStrength++;
    }
    // Check for numbers
    if (/\d/.test(value)) {
      newStrength++;
    }
    // Check for special characters
    if (/[^a-zA-Z0-9]/.test(value)) {
      newStrength++;
    }
    if (newStrength >= 5) {
      setWarning(false);
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }

    if (newStrength < 4) {
      setStrengthLevelColor('strength-level--weak');
    } else if (newStrength === 4) {
      setStrengthLevelColor('strength-level--medium');
    } else {
      setStrengthLevelColor('strength-level--strong');
    }

    handleInput(value);
    setStrengthLevel(newStrength);
    setCheckRan(true);
  }

  return (
    <div className="input-error__wrapper">
      <form
        className={`text-input__wrapper pos-rel ${
          customWrapper ? customWrapper : ''
        }`}
        style={customWidth ? { width: `${customWidth}` } : null}
        onSubmit={(e) => {
          //password elements need to be in form or throws warning
          e.preventDefault();
        }}
        autoComplete="off"
        onMouseEnter={() => setBorderHighlight(true)}
        onMouseLeave={() => setBorderHighlight(false)}
      >
        <label
          id={`${id}-label`}
          className="text-input-label no-select"
          onClick={() => handleInputClick(passwordInput.current)}
        >
          <p>
            {PasswordInputs.newPassword}&nbsp;
            {asterisk && <span className="highlight">*</span>}
          </p>
        </label>

        <input
          id={id}
          name={id}
          type={!showPassword ? 'password' : 'text'}
          className={`text-input text-input-password 
          ${borderHighlight ? 'highlight-o-theme--tap' : ''}
          ${
            focusHighlight
              ? 'highlight-ob-theme--thin--active'
              : 'highlight-ob-theme--thin'
          } ${
            checkRan && warning && strengthLevel !== 0
              ? 'text-input-warning'
              : ''
          }`}
          ref={passwordInput}
          defaultValue={loadValue}
          autoComplete={`${autocompleteOff ? 'new-password' : ''}`}
          aria-label={PasswordInputs.newPassword}
          maxLength="256"
          tabIndex="0"
          enterKeyHint="done"
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setFocusHighlight(false);
              dismissKeypad(e);
            }
          }}
          onMouseEnter={() => setFocusHighlight(true)}
          onMouseLeave={() => setFocusHighlight(false)}
          onChange={(e) => validation(e.target.value)}
          onBlur={() => {
            if (checkRan && strengthLevel < 5) {
              setWarning(true);
            }
          }}
        />
        <button
          tabIndex="0"
          type="button"
          id={`${id}-show-password`}
          className={`highlight-i-lgt access-ob access-o6 ${
            showPassword ? 'show-password-btn' : 'show-password-btn visible-off'
          } ${tapHighlightShowPassword ? 'highlight-i-theme--tap' : ''}        
        `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightShowPassword(true),
                () => setTapHighlightShowPassword(false),
              ],
              [() => setShowPassword(!showPassword)]
            )
          }
        >
          {showPassword ? (
            <img src={visible} alt={PasswordInputs.showPasswordOn} />
          ) : (
            <img src={visible_off} alt={PasswordInputs.showPasswordOff} />
          )}
        </button>
      </form>
      <div
        tabIndex="0"
        className={`input-password-strength__wrapper access-ob access-o6
        ${
          !checkRan
            ? 'strength-level-hide'
            : checkRan && strengthLevel > 0
            ? 'fadeOut-slide-down-anim--strength-level'
            : 'fadeOut-slide-up-anim--strength-level'
        } `}
      >
        <div className="input-password-strength">
          <div
            className={`strength-level ${
              strengthLevel >= 1 ? strengthLevelColor : ''
            }`}
          />
          <div
            className={`strength-level ${
              strengthLevel >= 3 ? strengthLevelColor : ''
            }`}
          />
          <div
            className={`strength-level ${
              strengthLevel >= 4 ? strengthLevelColor : ''
            }`}
          />
          <div
            className={`strength-level ${
              strengthLevel >= 5 ? strengthLevelColor : ''
            }`}
          />
          <div
            className={`strength-level ${
              strengthLevel === 6 ? strengthLevelColor : ''
            }`}
          />
        </div>
        <p
          className={`strenth-level-text ${
            strengthLevel === 0
              ? 'color-gray'
              : strengthLevel <= 4
              ? 'color-red '
              : 'color-green '
          }`}
        >
          {PasswordInputs.passwordStrength}: &nbsp;
          {strengthLevel <= 2 && PasswordInputs.veryWeak}
          {strengthLevel === 3 && PasswordInputs.weak}
          {strengthLevel === 4 && PasswordInputs.medium}
          {strengthLevel === 5 && PasswordInputs.acceptable}
          {strengthLevel === 6 && PasswordInputs.veryStrong}
        </p>
      </div>
    </div>
  );
};

export default CreatePasswordInput;

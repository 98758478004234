import { useEffect, useRef, useState } from 'react';

//language
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import TextArea from '../../../../../components/TextArea/TextArea';
import EmailInput from '../../../../../components/TextArea/EmailInput';
import SelectDropdown from '../../../../../components/SelectDropdown/SelectDropdown';
import ParticipantActionBtns from './ParticipantActionBtns';

//utils
import { textUI } from '../../../../../utils/UI/textUI';

const ParticipantEmailInvitation = ({
  prevParticipant,
  handleSaveAndReturn,
  handleRemoveParticipant,
  translationsDraft,
}) => {
  //language
  const { ParticipantEmailInvitation } = useLanguageOrganize();
  const { EmailLanguageOpts } = useLanguageData();
  const { Generic } = useLanguageComponents();

  //state
  const [participant, setParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const setParticipantEffectRan = useRef(false);

  useEffect(() => {
    if (setParticipantEffectRan.current === false && prevParticipant?.id) {
      setParticipantEffectRan.current = true;
      setParticipant(prevParticipant);
    }
  }, [prevParticipant]);

  useEffect(() => {
    if (participant) {
      setIsLoading(false);
    }
  }, [participant]);

  useEffect(() => {
    if (participant && !participant?.dispatches?.inviteDispatched) {
      if (
        participant?.emailInvitation?.emailValid === true &&
        participant?.emailInvitation?.emailLanguage !== 'select' &&
        participant?.emailInvitation?.emailLanguage
      ) {
        if (participant.completionStatus === 'incomplete') {
          let newParticipantData = JSON.parse(JSON.stringify(participant));
          newParticipantData.completionStatus = 'complete';

          setParticipant(newParticipantData);
        }
      } else if (participant.completionStatus === 'complete') {
        let newParticipantData = JSON.parse(JSON.stringify(participant));
        newParticipantData.completionStatus = 'incomplete';

        setParticipant(newParticipantData);
      }
    }
  }, [participant]);

  function handleEventRoleChange(val) {
    let newParticipantData = {
      ...participant,
      participantData: {
        ...participant.participantData,
      },
      emailInvitation: {
        ...participant.emailInvitation,
      },
    };

    newParticipantData.participantData.eventRole = val;
    setParticipant(newParticipantData);
  }

  function handleEmailInput(val) {
    let newParticipantData = {
      ...participant,
      emailInvitation: {
        ...participant.emailInvitation,
      },
    };
    newParticipantData.emailInvitation.email = val;

    const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    const validEmail = EMAIL_REGEX.test(val);

    newParticipantData.emailInvitation.emailValid = validEmail;
    setParticipant(newParticipantData);
  }

  function handleEmailLanguage(key) {
    let newParticipantData = {
      ...participant,
      emailInvitation: {
        ...participant.emailInvitation,
      },
    };

    newParticipantData.emailInvitation.emailLanguage = key;
    setParticipant(newParticipantData);
  }

  return (
    <>
      <ParticipantActionBtns
        participant={participant}
        handleSaveAndReturn={handleSaveAndReturn}
        handleRemoveParticipant={handleRemoveParticipant}
      />
      <div className="flex-column mrg-t24">
        {!isLoading && (
          <>
            {!translationsDraft &&
              participant?.dispatches?.invitationStatus === 'accepted' && (
                <div className={`participant__wrapper`}>
                  <div className="flex-column align-center  mrg-r24">
                    <div className="participant-img__container">
                      <LoadBucketImage
                        imagePath={
                          participant?.participantData?.personal?.profileImage
                        }
                        containerClassName={'ev-display-container'}
                        imgClassName={'participant-img'}
                        backupPath={'account_img_default.png'}
                        partialPath={'myndfull_participant_images'}
                      />
                    </div>
                    {participant?.participantData?.personal
                      ?.profileImageAlt && (
                      <div className="mrg-t6">
                        <p className="fs16 text-center">{Generic.imageAlt}:</p>
                        <p className="fs16 text-center">
                          {
                            participant?.participantData?.personal
                              ?.profileImageAlt
                          }
                        </p>
                      </div>
                    )}
                  </div>

                  <div className="participant-info full-width">
                    <div className="flex-column full-width">
                      <p className="fs18 fwsb access-ob" tabIndex="0">
                        {participant?.participantData?.personal?.prefix &&
                          `${participant?.participantData?.personal?.prefix} `}
                        {`${participant?.participantData?.personal?.firstName} `}
                        {participant?.participantData?.personal?.lastName}
                        {participant?.participantData?.eventRole &&
                          ` | ${participant?.participantData?.eventRole}`}
                      </p>

                      <p className="italic fs16">
                        {participant?.participantData?.personal?.pronouns &&
                          `${participant?.participantData?.personal?.pronouns}`}
                      </p>
                    </div>

                    {participant?.participantData?.personal?.bio && (
                      <div className="fs16 mrg-t6 access-ob" tabIndex="0">
                        {textUI(participant?.participantData?.personal?.bio)}
                      </div>
                    )}
                  </div>
                </div>
              )}

            {!participant?.dispatches?.inviteDispatched && (
              <div className="border-reg br--standard bg-color-light pad-24">
                {!translationsDraft && (
                  <>
                    <TextArea
                      disableEnter={true}
                      handleChange={handleEventRoleChange}
                      hideWordCount={true}
                      id={'participantEventRole'}
                      labelText={ParticipantEmailInvitation.eventRole}
                      limit={50}
                      loadTrigger={true}
                      loadValue={participant?.participantData?.eventRole}
                      placeHolder={
                        ParticipantEmailInvitation.eventRolePlaceholder
                      }
                      onBlurHandlerOff={true}
                    />
                    <div className="h24" />
                  </>
                )}

                <EmailInput
                  asterisk={true}
                  handleInput={handleEmailInput}
                  id={'participantEmailInvitationEmailInput'}
                  loadValue={participant?.emailInvitation?.email}
                  initialize={true}
                  componentAccountValidationOff={true}
                />

                <div className="h24" />
                <SelectDropdown
                  id={'emailLanguage'}
                  list={EmailLanguageOpts}
                  handleSelection={handleEmailLanguage}
                  internalLabel={
                    ParticipantEmailInvitation.emailInvitationLanguage
                  }
                  defaultKey={
                    participant?.emailInvitation?.emailLanguage
                      ? participant?.emailInvitation?.emailLanguage
                      : null
                  }
                  asterisk={true}
                  fullWidth={true}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ParticipantEmailInvitation;

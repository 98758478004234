import { useEffect, useState } from 'react';

//components
import DispatchesInvitationStatus from './DispatchesInvitationStatus';
import ParticipantListItemOrganizedAs from '../Generic/ParticipantListItemOrganizedAs';

const ParticipantListItemStatusDispatches = ({
  p,
  totalDispatchesAvailable,
  totalCurrentDispatches,
  allDispatchesIssued,
  translationsEnabled,
  translationsDraft,
}) => {
  //state
  const [invitationHasExpired, setInvitationHasExpired] = useState(false);

  useEffect(() => {
    if (p) {
      let tokenExpiration = p.emailInvitation?.tokenExpires;
      if (tokenExpiration) {
        let expirationTimestamp = new Date(tokenExpiration).getTime();
        if (expirationTimestamp < Date.now()) {
          setInvitationHasExpired(true);
        }
      }
    }
  }, [p]); //check token expiration.

  return (
    <>
      {p?.organizedAs === 'inviteContact' && (
        <div className="flex-row flex-column--ph  flex-center--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={
              'mrg-r6 participant-list-item__status-box--dispatches'
            }
          />
          <DispatchesInvitationStatus
            p={p}
            invitationHasExpired={invitationHasExpired}
            allDispatchesIssued={allDispatchesIssued}
            translationsEnabled={translationsEnabled}
            totalDispatchesAvailable={totalDispatchesAvailable}
            totalCurrentDispatches={totalCurrentDispatches}
          />
        </div>
      )}
      {p?.organizedAs === 'emailInvitation' && (
        <div className="flex-row flex-column--ph flex-center--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={
              'mrg-r6 participant-list-item__status-box--dispatches'
            }
          />
          <DispatchesInvitationStatus
            p={p}
            invitationHasExpired={invitationHasExpired}
            allDispatchesIssued={allDispatchesIssued}
            translationsEnabled={translationsEnabled}
            translationsDraft={translationsDraft}
            totalDispatchesAvailable={totalDispatchesAvailable}
            totalCurrentDispatches={totalCurrentDispatches}
          />
        </div>
      )}
    </>
  );
};

export default ParticipantListItemStatusDispatches;

//components
import FindEventsCommunityAndEmail from './FindEventsCommunityAndEmail';
import FindEventsEventType from './FindEventsEventType';
import FindEventsTags from './FindEventsTags';

//does not include find events toolbar which is positioned separately in parent
const ExploreFeaturesFindEvents = ({ currentIndex }) => {
  return (
    <div className="feature__wrapper" id="features-wrapper">
      <FindEventsEventType currentIndex={currentIndex} />
      <FindEventsTags currentIndex={currentIndex} />
      <FindEventsCommunityAndEmail currentIndex={currentIndex} />
    </div>
  );
};

export default ExploreFeaturesFindEvents;

import { useSettings } from '../context/SettingsProvider';

const useLanguageComponents = () => {
  const { contentLanguage } = useSettings();

  const ButtonSwitch = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        yes: 'Yes',
        no: 'No',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        yes: 'Oui',
        no: 'Non',
      };
    }
    return translation;
  };

  const EmailInput = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        emailAddress: 'Email Address',
        emailAlreadyRegistered: 'Email is already registered.',
        invalidEmailAddress: 'Invalid email address.',
        validEmailAddress: 'Valid email address.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        emailAddress: 'Adresse courriel',
        emailAlreadyRegistered: 'E-mail est déjà enregistrée.',
        invalidEmailAddress: 'E-mail invalide.',
        validEmailAddress: 'Adresse e-mail valable.',
      };
    }

    return translation;
  };

  const PhoneNumberInput = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        phoneNumber: 'Phone Number',
        isRequired: 'Phone Number is required.',
        countryCodes: 'Country Codes',
        requirementInstructions: 'Country code and phone number',
        ariaInstructions:
          'Phone number. Please enter your 10 digit phone number. Numbers will be automatically formatted.',
        phoneNumberInvalid: 'Your phone number is invalid.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        phoneNumber: `Numéro de téléphone`,
        isRequired: `Numéro de téléphone est requis.`,
        countryCodes: 'Indicatifs de pays',
        requirementInstructions: 'Indicatif du pays et numéro de téléphone',
        ariaInstructions:
          'Numéro de téléphone. Veuillez entrer votre numéro de téléphone à 10 chiffres. Les nombres seront automatiquement formatés.',
        phoneNumberInvalid: `Votre numéro de téléphone n'est pas valide.`,
      };
    }
    return translation;
  };

  const PasswordInputs = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        password: 'Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        passwordConfirmation: 'Password Confirmation',
        currentPassword: 'Current Password',
        passwordsMatch: 'Passwords Match.',
        showPasswordOn: 'Show password on.',
        showPasswordOff: 'Show password off.',
        passwordMustBeStronger: 'Your password must be stronger.',
        veryWeak: 'Very weak',
        weak: 'Weak',
        medium: 'Medium',
        acceptable: 'Acceptable',
        veryStrong: 'Very Strong',
        passwordStrength: 'Password Strength',
        passwordsDoNotMatch: 'Your passwords do not match.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        password: 'Mot de passe',
        newPassword: 'Nouveau mot de passe',
        confirmNewPassword: 'Confirmez le nouveau mot de passe',
        passwordConfirmation: 'Mot de passe actuel',
        currentPassword: 'Confirmation du mot de passe',
        passwordsMatch: 'Les mots de passe correspondent.',
        showPasswordOn: 'Afficher le mot de passe activé.',
        showPasswordOff: 'Afficher le mot de passe désactivé.',
        passwordMustBeStronger: 'Votre mot de passe doit être plus fort.',
        veryWeak: 'Très faible',
        weak: 'Faible',
        medium: 'Moyen',
        acceptable: 'Acceptable',
        veryStrong: 'Très Fort',
        passwordStrength: 'Force du mot de passe',
        passwordsDoNotMatch: 'Vos mots de passe ne correspondent pas.',
      };
    }
    return translation;
  };

  const SelectDropdown = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        select: 'Select',
        ariaLabelIntro: 'Drop down selection menu for',
        selectionRequired: 'Selection is required.',
        currentSelection: 'Current selection is',
        remove: 'Remove',
        arrow: 'arrow',
        timezone: 'Time Zone',
        searchByCountry: 'Search by Country',
        searchIcon: 'Search icon',
        ariaTimezoneInput:
          'Filter the timezone dropdown menu below by country.',
        noMoreSelections: 'There are no more selections available.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        select: 'Sélectionner',
        ariaLabelIntro: 'Menu déroulant de sélection pour',
        selectionRequired: 'La sélection est obligatoire.',
        currentSelection: 'La sélection actuelle est',
        remove: 'Retirer',
        arrow: 'flèche',
        timezone: 'Fuseau Horaire',
        searchByCountry: 'Recherche par pays',
        searchIcon: 'Icône de recherche',
        ariaTimezoneInput:
          'Filtrez le menu déroulant du fuseau horaire ci-dessous par pays.',
        noMoreSelections: `Il n'y a plus de sélections disponibles.`,
      };
    }
    return translation;
  };

  const SelectDropdownModal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        noMoreSelections: 'There are no more selections available.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        noMoreSelections: `Il n'y a plus de sélections disponibles.`,
      };
    }
    return translation;
  };

  const NavigationArrows = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        continue: 'Continue',
        previous: 'Previous',
        finish: 'Finish',
        minimumRequirementsNotMet:
          'Minimum requirements to continue are not met. Please complete all required fields and options.',
        skip: 'Skip',
        exit: 'Exit',
        next: 'Next',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        continue: 'Continuer',
        previous: 'Précédente',
        finish: 'Finir',
        minimumRequirementsNotMet: `Les exigences minimales pour continuer ne sont pas remplies. Veuillez remplir tous les champs et options obligatoires.`,
        skip: 'Sauter',
        exit: 'Sortie',
        next: 'Suivante',
      };
    }
    return translation;
  };

  const AutocompletePlaces = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        goToLocation: 'Go to Location',
        autocompleteLocationLong: 'Autocomplete Location',
        autocompleteLocationShort: 'Autocomplete',
        eventSearchInputInstructions:
          'Enter the address for your event search location in this field. A list of matching addresses will appear below the input. Use the arrow up and arrow down keys to navigate the list. Press enter on the address to select it. Based on the address location, a marker will appear on Google maps along with a search radius, which can be increased or decreased below.',
        genericInputInstructions:
          'To automatically fill the address fields, enter the desired address  in this input. A list of matching addresses will appear in a list below. Use the arrow up and arrow down keys to navigate the list. Press enter on the address to select it.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        goToLocation: `Aller à l'emplacement`,
        autocompleteLocationLong: `Saisie semi-automatique de l'emplacement`,
        autocompleteLocationShort: `Autocomplétion`,
        eventSearchInputInstructions: `Saisissez l'adresse de l'emplacement de recherche de votre événement dans ce champ. Une liste d'adresses correspondantes apparaîtra sous l'entrée. Utilisez les flèches vers le haut et vers le bas pour naviguer dans la liste. Appuyez sur Entrée sur l'adresse pour la sélectionner. En fonction de l'emplacement de l'adresse, un marqueur apparaîtra sur Google Maps avec un rayon de recherche, qui peut être augmenté ou diminué ci-dessous.`,
        genericInputInstructions: `Pour remplir automatiquement les champs d'adresse, entrez l'adresse souhaitée dans cette entrée. Une liste d'adresses correspondantes apparaîtra dans une liste ci-dessous. Utilisez les flèches vers le haut et vers le bas pour naviguer dans la liste. Appuyez sur Entrée sur l'adresse pour la sélectionner.`,
      };
    }
    return translation;
  };

  const BackToTop = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        backToTop: 'Back to Top',
        ariaBackToTop: 'Scroll back to the top of the page.',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        backToTop: 'Retour au sommet',
        ariaBackToTop: 'Faites défiler vers le haut de la page.',
      };
    }

    return translation;
  };

  const SelectionList = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        sort: 'Sort',
        delete: 'Delete',
        ariaOrderDescending: 'order descending.',
        ariaOrderAsecending: 'order ascending.',
        ariaColumnHeading: 'column heading.',
        lastModified: 'Last Modified',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        sort: 'Trier',
        delete: 'Supprimer',
        ariaOrderDescending: 'ordre décroissant.',
        ariaOrderAsecending: 'ordre croissant.',
        ariaColumnHeading: 'en-tête de colonne.',
        lastModified: 'Dernière modification',
      };
    }

    return translation;
  };

  const WhatsThis = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        whatsThis: `What's this`,
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        whatsThis: `Qu'est-ce que c'est ça`,
      };
    }

    return translation;
  };

  const DragDropList = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        switchDurationToTime: 'Switch to Start/End Times',
        switchTimeToDuration: 'Switch to Duration',
        count: 'Count',
        maximumReached: 'You have reached the maximum number of agenda items.',
        addItem: 'Add Item',
        minutes: 'Minutes',
        startTime: 'Start Time',
        endTime: 'End Time',
        remove: 'Remove',
        moveUp: 'Move up',
        moveDown: 'Move down',
        agendaItemLong: 'Agenda Item . . .',
        agendaItemShort: 'Agenda Item...',
        icon: 'Icon',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        switchDurationToTime: `Passer  à l'heure de début/de fin`,
        switchTimeToDuration: `Passer à la durée`,
        count: 'Compter',
        maximumReached: `Vous avez atteint le nombre maximum de points à l'ordre du jour.`,
        addItem: 'Ajouter un item',
        minutes: 'Minutes',
        start: 'Début',
        end: 'Fin',
        remove: 'supprimer',
        moveUp: 'Monter',
        moveDown: 'Descendre',
        agendaItemLong: `Point de l'ordre du jour . . .`,
        agendaItemShort: 'Article...',
        icon: 'Icône',
      };
    }

    return translation;
  };

  const ImageCropper = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        sorry: 'Sorry',
        screenSizeIncompatible: `This screen size is incompatible with the image cropper. If using a mobile device, try turning your device horizontally.`,
        ariaDeleteImage: 'Delete image.',
        ariaInstructions:
          'Use the arrow keys to re-position your image. Use the plus and minus keys to adjust the zoom level.',
        ariaUploadImageInstructions: 'Upload an image. Image type:',
        convertingImageType: 'Converting image type...',
        remove: 'Remove',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        sorry: 'Désolé',
        screenSizeIncompatible: `Cette taille d'écran est incompatible avec l'outil de recadrage d'image. Si vous utilisez un appareil mobile, essayez de le tourner horizontalement.`,
        ariaDeleteImage: `Supprimer l'image.`,
        ariaUploadImageInstructions: `Téléchargez une image. Type d'image:`,
        convertingImageType: `Conversion du type d'image`,
        remove: 'Retirer',
      };
    }
    return translation;
  };

  const Generic = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        accounts: 'Accounts',
        add: 'Add',
        addSelectedTags: 'Add Selected Tags',
        allRequired: 'All required',
        altText: 'Alt Text',
        ascending: 'Ascending',
        attemptingToReconnect: 'Attempting to reconnect',
        bigTag: 'Big Tag',
        bigTags: 'Big Tags',
        cancel: 'Cancel',
        change: 'Change',
        changeSortOrder: 'Change sort order',
        characterLimit: 'Character limit for this field is',
        clear: 'Clear',
        clearSelection: 'Clear selection',
        close: 'Close',
        closeList: 'Close list',
        community: 'Community',
        communityGroup: 'Community Group',
        complete: 'Complete',
        conflict: 'Conflict',
        currentTextCharacters: 'Current text characters is',
        default: 'Default',
        descending: 'Descending',
        error: 'Error',
        examine: 'Examine',
        exit: 'Exit',
        fieldIsRequired: 'Field is required.',
        goTo: 'Go to',
        hour: 'hour',
        hours: 'hours',
        imageAlt: `Image Alt`,
        incomplete: 'Incomplete',
        limitReached: 'Limit reached',
        minute: 'minute',
        minutes: 'minutes',
        more: 'More',
        nextPage: 'Next page',
        overLimit: 'You have exceeded the limit',
        page: 'Page',
        prevPage: 'Previous page',
        refresh: 'Refresh',
        remove: 'Remove',
        required: 'Required',
        reset: 'Reset',
        resetTableSize: 'Reset Table Size',
        restart: 'Restart',
        return: 'Return',
        search: 'Search',
        select: 'Select',
        selectedTags: 'Selected Tags',
        send: 'Send',
        someRequired: 'Some required',
        settings: 'Settings',
        sortOrder: 'Sort order',
        submit: 'Submit',
        subtract: 'Subtract',
        suggestionPrompt: 'Submit a Suggestion',
        tags: 'Tags',
        timeZone: 'Time Zone',
        tinyTag: 'Tiny Tag',
        tinyTagGroup: 'Tiny Tag Group',
        tinyTags: 'Tiny Tags',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        accounts: 'Comptes',
        add: 'Ajouter ',
        addSelectedTags: 'Ajouter les balises sélectionnées',
        allRequired: 'Tout est requis',
        altText: 'Texte alternatif',
        ascending: 'ascendant',
        attemptingToReconnect: 'Tentative de reconnexion',
        bigTag: 'Grand Balise',
        bigTags: 'Grand Balises',
        cancel: 'Annuler',
        change: 'Changement',
        changeSortOrder: `Modifier l'ordre de tri`,
        characterLimit: 'La limite de caractères pour ce champ est',
        clear: 'Effacer',
        clearSelection: 'Effacer la sélection',
        close: 'Fermer',
        closeList: 'Fermer la liste',
        community: 'Communauté',
        communityGroup: 'Groupe communautaire',
        complete: 'Finir',
        conflict: 'Conflit',
        currentTextCharacters: 'Les caractères de texte actuels sont',
        default: 'Défaut',
        descending: 'descendant',
        error: 'Erreur',
        examine: 'Examiner',
        exit: 'Sortie',
        fieldIsRequired: 'Champs requis.',
        hour: 'heure',
        hours: 'heures',
        imageAlt: `Texte alternatif de l'image`,
        incomplete: 'Incomplet',
        limitReached: 'Limite atteinte',
        minute: 'minute',
        minutes: 'minutes',
        more: 'Plus',
        nextPage: 'Page suivante',
        overLimit: 'Vous avez dépassé la limite',
        page: 'Page',
        prevPage: 'Page précédente',
        refresh: 'Rafraîchir',
        remove: 'Retirer',
        required: 'Requis',
        reset: 'Réinitialiser',
        resetTableSize: 'Réinitialiser la taille du tableau',
        restart: 'Redémarrer',
        return: 'Retour',
        search: 'Recherche',
        settings: 'Paramètres',
        select: 'Sélectionner',
        selectedTags: 'Balises sélectionnées',
        send: 'Envoyer',
        someRequired: 'Certains requis',
        sortOrder: 'Ordre de tri',
        submit: 'Soumettre',
        subtract: 'Soustraire',
        suggestionPrompt: 'Soumettre une suggestion',
        tags: 'Balises',
        timeZone: 'Fuseau horaire',
        tinyTag: 'Petit Balise',
        tinyTagGroup: 'Petit groupe de balise',
        tinyTags: 'Petit Balises',
      };
    }
    return translation;
  };

  const CustomTagsList = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        inputInstructions:
          'Tiny Tags input. Each entry will automatically be assigned a hashtag at the beginning. Tag limit is',
        tagGroup: 'Tag Group',
        tinyTagGroup: 'Tiny Tag Group',
        modifyTinyTagGroup: 'Modify Tiny Tag Group',
        removeTinyTagGroup: 'Remove Tiny Tag Group',
        clickTagsToAdd: 'Click Tags to Add',
        finish: 'Finish',
        ariaAddTag: 'Add tag.',
        seeSuggestions: 'See Suggestions',
        suggestedTinyTags: 'Suggested Tiny Tags',
        skipToTinyTagGroups: 'Skip to Tiny Tag Groups',
        needTagsToGenerateSuggestions:
          'Please add Big Tags to generate suggestions',
        tagsLimitExceeded: 'Tags Limit Exceeded',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        inputInstructions:
          'Entrée de Petit balises. Chaque entrée se verra automatiquement attribuer un hashtag au début. La limite de balises est',
        tagGroup: 'Groupe de balise',
        tinyTagGroup: 'Petit groupe de balise',
        modifyTinyTagGroup: 'Modifier le petit groupe de balises',
        removeTinyTagGroup: 'Supprimer le petit groupe de balises',
        clickTagsToAdd: 'Cliquez sur les balises pour ajouter',
        finish: 'Finir',
        ariaAddTag: 'Ajouter une balise.',
        seeSuggestions: 'Voir les suggestions',
        suggestedTinyTags: 'Petites balises suggérées',

        skipToTinyTagGroups: 'Passer aux petits groupes de balises',
        needTagsToGenerateSuggestions:
          'Veuillez ajouter des Big Tags pour générer des suggestions',
        tagsLimitExceeded: 'Limite de balises dépassée',
      };
    }
    return translation;
  };

  const CommunityGroups = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        communityGroup: 'Community Group',
        modifyCommunityGroup: 'Modify Community Group',
        removeCommunityGroup: 'Remove Community Group',
        addAccountFromYourList: 'Add account from your list',
        finish: 'Finish',
        ariaAddAccount: 'Add Account',
        skipToCommunityGroups: 'Skip to Community Groups',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        communityGroup: 'Groupe communautaire',
        modifyCommunityGroup: 'Modifier le groupe communautaire',
        removeCommunityGroup: 'Supprimer le groupe communautaire',
        addAccountFromYourList: 'Ajouter un compte à partir de votre liste',
        finish: 'Finir',
        ariaAddAccount: 'Ajouter un compte',
        skipToCommunityGroups: 'Passer aux groupes communautaires',
      };
    }
    return translation;
  };

  const GetDateTimeSummary = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        active: 'Active',
        finished: 'Finished',
        today: 'Today',
        day: 'Day',
        days: 'Days',
        nA: 'N/A',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        active: 'Actif',
        finished: 'Fini',
        today: `Aujourd'hui`,
        day: 'Jour',
        days: 'Jours',
        nA: 'N/D',
      };
    }
    return translation;
  };

  const FocusSkip = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        skip: 'Skip',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        skip: 'Passer',
      };
    }
    return translation;
  };

  const CalendarSimple = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        calendarSelectDay: 'Calendar date not selected',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        calendarSelectDay: 'Date du calendrier non sélectionnée',
      };
    }
    return translation;
  };

  const ApiStatusModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        somethingWentWrong: 'Something went wrong!',
        error: 'Error',
        success: 'Success',
        warning: 'Warning',
        automaticallyDismissed: 'This message will be automatically dismissed.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        somethingWentWrong: `Quelque chose s'est mal passé!`,
        error: 'Erreur',
        success: 'Succès',
        warning: 'Avertissement',
        automaticallyDismissed: `Ce message sera automatiquement supprimé.`,
      };
    }

    return translation;
  };

  const Spinner = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        loading: 'loading',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        loading: 'chargement',
      };
    }

    return translation;
  };

  const DistanceInput = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        switchToMiles: 'Switch to Miles',
        switchToKilometers: 'Switch to Kilometers',
        switch: 'Switch',
        ariaDecrease: 'Decrease.',
        ariaIncrease: 'Increase.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        switchToMiles: 'Changer en Miles',
        switchToKilometers: 'Changer to Kilomètres',
        switch: 'Changer',
        ariaDecrease: 'Diminuer.',
        ariaIncrease: 'Augmenter.',
      };
    }

    return translation;
  };

  const LoadingScreen = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        verifying: 'Verifying',
        loading: 'Loading',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        verifying: 'Vérification',
        loading: 'Chargement',
      };
    }

    return translation;
  };

  const FollowButton = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        clickToFollow: 'Click to Follow',
        clickToUnfollow: 'Click to Unfollow',
        follow: 'Follow',
        checkmark: 'Checkmark',
        following: 'Following',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        clickToFollow: 'Cliquez pour suivre',
        clickToUnfollow: 'Cliquez pour ne plus suivre',
        follow: 'Suivre',
        checkmark: 'Coche',
        following: 'Suivi',
      };
    }

    return translation;
  };

  const SearchCommunityInput = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        noMatches: 'No matches',
        ariaInstructions: `Enter a MyndFull organizer's first and or last name, then select the organizer in the list below the input field.`,
        viewProfile: 'View Profile',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        noMatches: 'Pas de correspondance',
        ariaInstructions: `Entrez le prénom et ou le nom d'un organisateur MyndFull, puis sélectionnez l'organisateur dans la liste sous le champ de saisie.`,
        viewProfile: 'Voir le profil',
      };
    }

    return translation;
  };

  const EventStatusBarSwitch = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        inPersonToVirtual:
          'Requesting to transfer attendance from in-person to virtual',
        virtualToInPerson:
          'Requesting to transfer attendance from virtual to in-person',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        inPersonToVirtual:
          'Demander le transfert de la participation du présentiel au virtuel',
        virtualToInPerson:
          'Demander le transfert de la participation du virtuel au présentiel',
      };
    }
    return translation;
  };

  const SaveButton = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        save: 'Save',
        ariaSaveUnavailable:
          'Save Button. No changed data to save, or requirements are not met.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        save: 'Sauvegarder',
        ariaSaveUnavailable:
          'Bouton Sauvegarder. Aucune donnée modifiée à enregistrer, ou les exigences ne sont pas remplies.',
      };
    }
    return translation;
  };

  const ReportModal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        close: 'Close',
        reportEvent: 'Report Event',
        otherViolationTitle: 'Violation Title',
        selectViolation: 'Select Violation:',
        selectBrowser: 'Select Browser:',
        describeViolation:
          'Please provide a detailed description of the violation:',
        describeProblem:
          'Please provide a detailed description of the problem:',
        description: 'Description',
        contactInformation: 'Contact Information',
        name: 'Name',
        email: 'Email',
        submitReport: 'Submit Report',
        reportTechnicalProblem: 'Report Technical Problem',
        translationsInaccurate: 'Translations inaccurate?',
        translationsInaccurateDescription:
          'To report inaccurate translations, please enable save transcripts. Saved transcripts can be accessed through the host homepage, whereby you can load your transcripts, identify inaccuracies, and submit a review.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        close: 'Fermer',
        reportEvent: 'Signaler un événement',
        otherViolationTitle: `Titre de la violation`,
        selectViolation: 'Sélectionnez une infraction:',
        selectBrowser: 'Sélectionnez le navigateur:',
        describeViolation:
          'Veuillez fournir une description détaillée de la violation:',
        describeProblem:
          'Veuillez fournir une description détaillée du problème:',
        description: 'Description',
        contactInformation: 'Coordonnées de contact',
        name: 'Nom',
        email: 'Email',
        submitReport: 'Soumettre un signalement',
        reportTechnicalProblem: 'Signaler un Problème Technique',
        translationsInaccurate: 'Les traductions sont inexactes ?',
        translationsInaccurateDescription: `Pour signaler des traductions inexactes, veuillez activer l'enregistrement des transcriptions. Les transcriptions enregistrées sont accessibles via la page d'accueil de l'hôte, où vous pouvez charger vos transcriptions, identifier les inexactitudes et soumettre une évaluation.`,
      };
    }
    return translation;
  };

  const AutocorrectKeywords = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        keywords: 'Keywords',
        uploadToAutodetect: 'Upload .docx / .pdf to Autodetect',
        upload: 'Upload',
        toAutodetect: 'to Autodetect',
        maximumSize: 'Maximum Size 5 MB',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        keywords: 'Mots clés',
        uploadToAutodetect:
          'Télécharger .docx / .pdf vers la détection automatique',
        upload: 'Télécharger',
        toAutodetect: 'détecter automatiquement',
        maximumSize: 'Taille maximale 5 Mo',
      };
    }
    return translation;
  };

  const TagsContent = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        addTagToAccount: 'Add tag to your account',
        removeTagFromAccount: 'Remove tag from your account',
        tags: 'Tags',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        addTagToAccount: 'Ajoutez cette balise à votre compte',
        removeTagFromAccount: 'Supprimez cette balise de votre compte',
        tags: 'Tags',
      };
    }
    return translation;
  };

  const IconAlts = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        icon: 'icon',
        iconAccount: 'Account icon',
        iconAlert: 'Alert icon',
        iconArchiveBox: 'Archive box icon',
        iconArrow: 'Arrow icon',
        iconAward: 'Award icon',
        iconBell: 'Bell icon',
        iconBigTag: 'Big Tag icon',
        iconBlock: 'Block icon',
        iconCancel: 'Cancel icon',
        iconChecklist: 'Checklist icon',
        iconCalendar: 'Calendar icon',
        iconCheckmark: 'Checkmark icon',
        iconClock: 'Clock icon',
        iconCommunity: 'Community icon',
        iconClose: 'Close icon',
        iconCreate: 'Create icon',
        iconEmail: 'Email icon',
        iconEmptyBox: 'Empty box icon',
        iconFile: 'File icon',
        iconFlag: 'Flag icon',
        iconFolder: 'Folder icon',
        iconFollowButton: 'Follow Button icon',
        iconGlobe: 'Globe icon',
        iconHourglass: 'Hourglass icon',
        iconInPerson: 'In Person icon',
        iconInvalid: 'Invalid icon',
        iconIgnore: 'Ignore icon',
        iconLanguage: 'Language icon',
        iconLoad: 'Load icon',
        iconLocationPin: 'Location pin icon',
        iconLock: 'Lock icon',
        iconMessage: 'Message icon',
        iconPreviewBanner: 'Preview Banner icon',
        iconReconnect: 'Reconnect icon',
        iconReset: 'Reset icon',
        iconResize: 'Resize icon',
        iconRestore: 'Restore icon',
        iconReturn: 'Return icon',
        iconRefresh: 'Refresh icon',
        iconRemove: 'Remove icon',
        iconSave: 'Save icon',
        iconSearch: 'Search icon',
        iconSettings: 'Settings icon',
        iconSpeaker: 'Speaker icon',
        iconStar: 'Star icon',
        iconTinyTag: 'Tiny Tag icon',
        iconTinyTagGroup: 'Tiny Tag Group icon',
        iconTrash: 'Trash icon',
        iconView: 'View icon',
        iconValid: 'Valid icon',
        iconVirtual: 'Virtual icon',
        iconWarning: 'Warning icon',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        icon: 'Icône',
        iconAccount: 'Icône de compte',
        iconAlert: `Icône d'alerte`,
        iconArchiveBox: `Icône de la boîte d'archives`,
        iconArrow: 'Icône de flèche',
        iconAward: 'Icône de récompense',
        iconBell: 'Icône de cloche',
        iconBigTag: 'Icône de Grand Balises',
        iconBlock: 'Icône de bloc',
        iconCalendar: 'Icône de calendrier',
        iconCancel: `Icône d'annulation`,
        iconChecklist: 'Icône de liste de contrôle',
        iconCheckmark: 'Icône de coche',
        iconClock: `Icône d'horloge`,
        iconCommunity: 'Icône de la communauté',
        iconCreate: 'Icône créer',
        iconClose: 'Icône fermer',
        iconEmail: 'Icône de courriel',
        iconEmptyBox: 'Icône de boîte vide',
        iconFile: 'Icône de fichier',
        iconFlag: 'Icône de drapeau',
        iconFolder: 'Folder icon',
        iconFollowButton: 'Icône du bouton Suivre',
        iconGlobe: 'Icône de globe',
        iconHourglass: 'Icône de sablier',
        iconInPerson: 'Icône En personne',
        iconLanguage: 'Icône de langue',
        iconInvalid: 'Icône invalide',
        iconIgnore: `Ignorer l'icône`,
        iconLoad: 'Icône charger',
        iconLocationPin: 'Icône de localisation',
        iconLock: 'Icône de verrouillage',
        iconMessage: 'Icône de message',
        iconPreviewBanner: `Aperçu de l'icône de la bannière`,
        iconReconnect: 'Icône de reconnexion',
        iconReset: 'Icône réinitialiser',
        iconResize: `Redimensionner l'icône`,
        iconRestore: 'Icône restaurer',
        iconReturn: 'Icône de retour',
        iconRefresh: 'Icône de rafraîchissement',
        iconRemove: 'Icône supprimer',
        iconSave: 'Icône sauvegarder',
        iconSearch: 'Icône de recherche',
        iconSettings: 'Icône Paramètres',
        iconSpeaker: 'Icône de Orateur',
        iconStar: 'Icône étoile',
        iconTinyTag: 'Icône de Petit Balises',
        iconTinyTagGroup: 'Icône du petit groupe de balises',
        iconTrash: 'Icône poubelle',
        iconView: 'Vue des icônes',
        iconVirtual: 'Icône virtuelle',
        iconValid: 'Icône valide',
        iconWarning: `Icône d'avertissement`,
      };
    }
    return translation;
  };

  const ProfilePersonal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        return: 'Return',
        requested: 'Requested',
        requestContactInfo: `Request\nContact Info`,
        location: 'Location',
        languages: 'Languages',
        userCreated: 'User Created',
        personalWebsite: 'Personal Website',
        bio: ' Bio',
        Contact: ' Contact',
        contactInfo: 'Contact Info',
        email: 'Email',
        phoneNumber: 'Phone Number',
        nA: 'N/A',
        timeZone: 'Time Zone',
        appLanguage: 'App Language',
        viewableByContacts: 'Viewable by Contacts',
        viewableByOrganizers: 'Viewable by Organizers',
        socialLinks: 'Social Links',
        speakerLanguages: 'Speaker Languages',
        isAccountHolder: 'This is your account.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        return: 'Retour',
        requested: 'Demandé',
        requestContactInfo: 'Demander des informations de contact',
        location: 'Emplacement',
        languages: 'Langues',
        userCreated: 'Utilisateur créé',
        personalWebsite: 'Site personnel',
        bio: ' Bio',
        Contact: `Contact`,
        contactInfo: 'Informations de contact',
        email: 'Courriel',
        phoneNumber: 'Numéro de téléphone',
        nA: 'N/D',
        timeZone: 'Fuseau horaire',
        appLanguage: `Langue de l'application`,
        viewableByContacts: 'Visible par les contacts',
        viewableByOrganizers: 'Visible par les organisateurs',
        socialLinks: 'Liens sociaux',
        speakerLanguages: 'Speaker Languages',
        isAccountHolder: 'Ceci est votre compte.',
      };
    }
    return translation;
  };

  const SelectionsModal = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        limitExceeded: 'Limit exceeded',
        remove: 'Remove',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        limitExceeded: 'Limite dépassée',
        remove: 'Retirer',
      };
    }
    return translation;
  };

  const NoticeOverlay = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        notices: 'Notices',
        archive: 'Archive',
        settings: 'Settings',
        unseenNotice: 'Unseen Notice',
        ariaCloseNoticesMenu: 'Close notices menu.',
        actionRequired: 'Action Required',
        from: 'From',
        clearArchive: 'Clear Archive',
        event: 'Event',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        notices: 'Avis',
        archive: 'Archive',
        settings: 'Paramètres',
        unseenNotice: 'Notification non vue',
        ariaCloseNoticesMenu: 'Fermer le menu des avis.',
        actionRequired: 'Action requise',
        from: 'Depuis',
        clearArchive: 'Effacer les archives',
        event: 'Événement',
      };
    }
    return translation;
  };

  const NoticeSpeakerComponents = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        accepted: 'Accepted',
        accept: 'Accept',
        ignore: 'Ignore',
        ignored: 'Ignored',
        remove: 'Remove',
        viewProfile: 'View Profile',
        archive: 'Archive',
        removeFromArchive: 'Remove from Archive',
        history: 'History',
        archiveNotice: 'Archive Notice',
        rejected: 'Rejected',
        reject: 'Reject',
        viewEvent: 'View Event',
        goToForm: 'Go to Form',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        accepted: 'Accepté',
        accept: 'Accepter',
        ignore: 'Ignorer',
        ignored: 'Ignoré',
        remove: 'Supprimer',
        archive: 'Archiver',
        viewProfile: 'Voir le profil',
        removeFromArchive: 'Supprimer des archives',
        history: 'Histoire',
        archiveNotice: `Avis d'archive`,
        rejected: 'Rejeté',
        reject: 'Rejeter',
        viewEvent: `Voir l'événement`,
        goToForm: 'Aller au formulaire',
      };
    }
    return translation;
  };

  const ImportEventDataModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        importEventData: 'Import Event Data',
        selectFolder: 'Select a folder',
        emptyFolder: 'Folder is empty',
        eventDrafts: 'Event Drafts',
        activeEvents: 'Active Events',
        scheduledEvents: 'Scheduled Events',
        archivedEvents: 'Archived Events',
        savedData: 'Saved Data',
        myndfullEvents: 'MyndFull Events',
        sessionDrafts: 'Session Drafts',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        importEventData: `Importer des données d'événement`,
        selectFolder: 'Sélectionnez un dossier',
        emptyFolder: 'Le dossier est vide',
        eventDrafts: `Projets d'événements`,
        activeEvents: 'Événements actifs',
        scheduledEvents: 'Événements programmés',
        archivedEvents: 'Événements archivés',
        savedData: 'Données enregistrées',
        myndfullEvents: 'Événements MyndFull',
        sessionDrafts: 'Brouillons de session',
      };
    }
    return translation;
  };

  const ViewContactedSpeakersModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        selectFolder: 'Select a folder',
        emptyFolder: 'Folder is empty',
        contacts: 'Contacts',
        requested: 'Requested',
        accepted: 'Accepted',
        invitedContactsViewEventPreview:
          'Invited Contacts will have access to your event preview.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        selectFolder: 'Sélectionnez un dossier',
        emptyFolder: 'Le dossier est vide',
        contacts: 'Contacts',
        requested: 'Demandé',
        accepted: 'Accepté',
        invitedContactsViewEventPreview: `Les contacts invités auront accès à l'aperçu de votre événement.`,
      };
    }
    return translation;
  };

  const ProfileInteractions = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        block: 'Block',
        unblock: 'Unblock',
        blocked: 'Blocked',
        clickToBlock: 'Click to Block',
        clickToUnblock: 'Click to Unblock',

        follow: 'Follow',
        unfollow: 'Unfollow',
        following: 'Following',
        clickToFollow: 'Click to Follow',
        clickToUnfollow: 'Click to Unfollow',

        requested: 'Requested',
        requestContact: 'Request Contact',
        clickToRequestContact: 'Click to Request Contact',

        contact: 'Contact',
        removeContact: 'Remove Contact',
        revokeRequest: 'Revoke Request',
        clickToRevokeRequest: 'Click to Revoke Request',
        clickToRemoveContact: 'Click to Remove Contact',

        report: 'Report',
        clickToReport: 'Click to Report Account',
        showAdditionalOptions: 'Show Additional Options',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        block: 'Bloc',
        blocked: 'Bloqué',
        unblock: 'Débloquer',
        clickToBlock: 'Cliquez pour bloquer',
        clickToUnblock: 'Cliquez pour débloquer',

        follow: 'Suivre',
        following: 'Abonnements',
        unfollow: 'Ne plus suivre',
        clickToFollow: 'Cliquez pour suivre',
        clickToUnfollow: 'Cliquez pour ne plus suivre',

        contact: 'Contact',
        requested: 'Demande en cours',
        requestContact: 'Demande de contact',
        removeContact: 'Supprimer le contact',
        revokeRequest: 'Révoquer la demande',
        clickToRequestContact: 'Cliquez pour demander un contact',
        clickToRevokeRequest: 'Cliquez pour révoquer la demande',
        clickToRemoveContact: 'Cliquez pour supprimer le contact',

        report: 'Signaler',
        clickToReport: 'Cliquez pour signaler',
        showAdditionalOptions: 'Afficher les options supplémentaires',
      };
    }
    return translation;
  };

  const CommunityListAccountPersonal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        contact: 'Contact',
        contactRequested: 'Contact Requested',
        personalAccount: 'Personal Account',
        speaker: 'Speaker',
        organizer: 'Organizer',
        openProfileView: 'Open profile view',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        contact: 'Contact',
        contactRequested: 'Contact demandé',
        personalAccount: 'Compte personnel',
        speaker: 'Orateur',
        organizer: 'Organisateur',
        openProfileView: 'Ouvrir la vue de profil',
      };
    }
    return translation;
  };

  const IconSelectModal = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        selectAgendaIcon: 'Select Agenda Icon',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        selectAgendaIcon: `Sélectionner l'icône de l'agenda`,
      };
    }
    return translation;
  };

  const AgendaIconAlts = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        muffin: 'Muffin',
        hourglass: 'Hourglass',
        drink: 'Drink',
        speechBubble: 'Speech bubble',
        exchangeArrows: 'Exchange Arrows',
        penSigning: 'Pen Signing',
        clock: 'Clock',
        microphone: 'Microphone',
        pieChart: 'Pie Chart',
        people: 'People',
        lightBulb: 'Light Bulb',
        star: 'Star',
        playButton: 'Play Button',
        enterDoor: 'Enter Door',
        tools: 'Tools',
        questionMark: 'Question Mark',
        beaker: 'Beaker',
        award: 'Award',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        muffin: 'Muffin',
        hourglass: 'Sablier',
        drink: 'Boisson',
        speechBubble: 'Bulle de dialogue',
        exchangeArrows: 'Flèches d’échange',
        penSigning: 'Signature avec stylo',
        clock: 'Horloge',
        microphone: 'Microphone',
        pieChart: 'Diagramme circulaire',
        people: 'Personnes',
        lightBulb: 'Ampoule',
        star: 'Étoile',
        playButton: 'Bouton de lecture',
        enterDoor: 'Entrer dans la porte',
        tools: 'Outils',
        questionMark: 'Point d’interrogation',
        beaker: 'Bécher',
        award: 'Récompense',
      };
    }
    return translation;
  };

  const GetEventInvitationType = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        eventDraftInvitation: 'Event Draft Invitation',
        activeEventInvitation: 'Event Invitation',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        eventDraftInvitation: `Invitation à un brouillon d’événement`,
        activeEventInvitation: `Invitation à un événement`,
      };
    }
    return translation;
  };

  const TranslationsSpeakerData = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        participantTranslationData: 'Participant Translation Data',
        discussionContext: 'Discussion Context',
        keywords: 'Keywords',
        nameNA: 'Name N/A',
        languageNA: 'Language N/A',
        voiceNA: 'Voice N/A',
        na: 'N/A',
        unsupportedLanguage: `Translation Room does not support this selected language`,
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        participantTranslationData: 'Données de traduction des participants',
        discussionContext: 'Contexte de la discussion',
        keywords: 'Mots clés',
        nameNA: 'Nom N/D',
        languageNA: 'Langue N/D',
        voiceNA: 'Voix N/D',
        na: 'N/D',
        unsupportedLanguage: `La salle de traduction ne prend pas en charge la langue sélectionnée`,
      };
    }
    return translation;
  };

  const SortingComponent = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        sortBy: 'Sort by',
        ariaLabel: 'Sorting Selections.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        sortBy: 'Trier par',
        ariaLabel: 'Tri des sélections.',
      };
    }
    return translation;
  };

  const WordSearchInput = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        searchByName: 'Search by name',
        ariaLabelSearchCommunityAccounts:
          'Search current community accounts list names.',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        searchByName: 'Recherche par nom',
        ariaLabelSearchCommunityAccounts:
          'Rechercher les noms de la liste des comptes communautaires actuels.',
      };
    }
    return translation;
  };

  const FieldValueMissing = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        fieldValueMissing: 'Field is locked but no value is provided',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        fieldValueMissing: `Le champ est verrouillé, mais aucune valeur n'est fournie`,
      };
    }
    return translation;
  };

  const LanguageNotListed = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        languages: 'Languages',
        language: 'Language',
        addLanguages: 'Add Languages',
        ariaNotListedLanguageTextInput: 'Add a non-listed language here.',
        addAnotherNonListedLanguage: 'Add Another Non-listed Language',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        languages: 'Langues',
        addLanguages: 'Ajouter des langues',
        language: 'Langue',
        addAnotherNonListedLanguage: 'Ajouter une autre langue non répertoriée',
        ariaNotListedLanguageTextInput:
          'Ajoutez ici une langue non répertoriée.',
      };
    }
    return translation;
  };

  const ValidationsList = () => {
    let translation;
    if (contentLanguage === 'en-CA') {
      translation = {
        fieldsThatRequireCompletion: 'Fields that require completion:',
      };
    }
    if (contentLanguage === 'fr-CA') {
      translation = {
        fieldsThatRequireCompletion: 'Champs qui nécessitent la complétion:',
      };
    }
    return translation;
  };

  return {
    ValidationsList: ValidationsList(),
    Generic: Generic(),
    ButtonSwitch: ButtonSwitch(),
    EmailInput: EmailInput(),
    PhoneNumberInput: PhoneNumberInput(),
    PasswordInputs: PasswordInputs(),
    SelectDropdown: SelectDropdown(),
    NavigationArrows: NavigationArrows(),
    AutocompletePlaces: AutocompletePlaces(),
    BackToTop: BackToTop(),
    SelectionList: SelectionList(),
    WhatsThis: WhatsThis(),
    DragDropList: DragDropList(),
    ImageCropper: ImageCropper(),
    GetDateTimeSummary: GetDateTimeSummary(),
    ApiStatusModal: ApiStatusModal(),
    Spinner: Spinner(),
    CustomTagsList: CustomTagsList(),
    DistanceInput: DistanceInput(),
    FocusSkip: FocusSkip(),
    SelectDropdownModal: SelectDropdownModal(),
    LoadingScreen: LoadingScreen(),
    FollowButton: FollowButton(),
    CommunityGroups: CommunityGroups(),
    SearchCommunityInput: SearchCommunityInput(),
    EventStatusBarSwitch: EventStatusBarSwitch(),
    SaveButton: SaveButton(),
    ReportModal: ReportModal(),
    CalendarSimple: CalendarSimple(),
    AutocorrectKeywords: AutocorrectKeywords(),
    IconAlts: IconAlts(),
    TagsContent: TagsContent(),
    ProfilePersonal: ProfilePersonal(),
    SelectionsModal: SelectionsModal(),
    NoticeOverlay: NoticeOverlay(),
    NoticeSpeakerComponents: NoticeSpeakerComponents(),
    ImportEventDataModal: ImportEventDataModal(),
    ViewContactedSpeakersModal: ViewContactedSpeakersModal(),
    ProfileInteractions: ProfileInteractions(),
    CommunityListAccountPersonal: CommunityListAccountPersonal(),
    IconSelectModal: IconSelectModal(),
    AgendaIconAlts: AgendaIconAlts(),
    GetEventInvitationType: GetEventInvitationType(),
    TranslationsSpeakerData: TranslationsSpeakerData(),
    SortingComponent: SortingComponent(),
    WordSearchInput: WordSearchInput(),
    FieldValueMissing: FieldValueMissing(),
    LanguageNotListed: LanguageNotListed(),
  };
};

export default useLanguageComponents;

import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

//Hooks
import { useAuthReduxHandlers } from './useAuthHandlers';
import { useSettingsHandlers } from '../settings/useSettingsHandlers';
import { useCreateEventReduxHandlers } from '../organize/create/useCreateEventHandlers';
import { useLazyRefreshQuery } from './authApiSlice';
import { useLazyMyAccountQuery } from '../account/accountApiSlice';
import { useAccountPersonalSocket } from '../../../context/sockets/main/AccountPersonalProvider';

//Components
import BlankScreen from '../../../layout/BlankScreen';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';

const PersistLogin = () => {
  //Hooks
  const {
    authIsLoggingOut,
    authIsLoggingIn,
    authCurrentToken,
    handleUserLogout,
    authCurrentRoles,
  } = useAuthReduxHandlers();
  const { accountId } = useAccountReduxHandlers();
  const {
    handleSettingsResetRedux,
    settingsAccountIdVerification,
    settingsPersist,
  } = useSettingsHandlers();
  const { handleCreateEventReset, createEventIdVerification } =
    useCreateEventReduxHandlers();
  const { connectAccountPersonalSocket, accountPersonalSocketStatus } =
    useAccountPersonalSocket();

  //API
  const [noToken] = useLazyRefreshQuery();
  const [myAccount] = useLazyMyAccountQuery();

  //Component functions
  const [isLoading, setIsLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(true);

  //Initialize functions
  useEffect(() => {
    !authCurrentToken && settingsPersist
      ? verifyRefreshToken()
      : setIsLoading(false);

    return () => {
      setIsMounted(false);
    };

    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    if (authCurrentRoles?.includes(process.env.REACT_APP_USER) && accountId) {
      if (accountPersonalSocketStatus === 'closed') {
        connectAccountPersonalSocket();
      }
    }
  }, [authCurrentToken, accountId]);

  const verifyRefreshToken = async () => {
    try {
      const token = await noToken();

      if (!token.error && token) {
        const account = await myAccount();

        if (account.status === 'rejected') {
          return handleUserLogout();
        }

        if (
          account?.data?.myAccount?._id !== settingsAccountIdVerification ||
          (createEventIdVerification &&
            account?.data?.myAccount?._id !== createEventIdVerification)
        ) {
          handleSettingsResetRedux(account.data.myAccount._id);
          handleCreateEventReset(account.data.myAccount._id);
        }
      }
    } catch (err) {
      return null;
    } finally {
      isMounted && setIsLoading(false);
    }
  };

  //prettier-ignore
  return (
    <>
      {!settingsPersist 
            ? <Outlet />
            : isLoading || authIsLoggingOut || authIsLoggingIn
                ? <BlankScreen />
                : <Outlet />
        }
   </>
  );
};

export default PersistLogin;

import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//Components
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';
import LanguageNotListed from '../../../../../../components/LanguageNotListed/LanguageNotListed';

//Utility
import { checkmark_green, img_language } from '../../../../../../assets/icons';
import { deepEqual } from '../../../../../../utils/logic/deepEqual';

const SearchLanguages = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const {
    accountSearchLanguages: loadSearchLanguages,
    accountAttendee,
    accountSearchLanguagesNotListed: loadSearchLanguagesNotListed,
  } = useAccountReduxHandlers();

  //language
  const { LanguageSimpleOpts } = useLanguageData();
  const { Generic, IconAlts } = useLanguageComponents();

  //Component state
  const [searchLanguages, setSearchLanguages] = useState(loadSearchLanguages);
  const [searchLanguagesNotListed, setSearchLanguagesNotListed] = useState(
    loadSearchLanguagesNotListed || []
  );

  const [searchLanguagesSaveValid, setSearchLanguagesSaveValid] =
    useState(false);
  const [
    searchLanguagesNotListedSaveValid,
    setSearchLanguagesNotListedSaveValid,
  ] = useState(false);

  //Component functions
  useEffect(() => {
    const sameContents =
      searchLanguages.length === loadSearchLanguages.length &&
      searchLanguages.every(
        (value, index) => value === loadSearchLanguages[index]
      );
    if (
      !sameContents &&
      !searchLanguages.includes('select') &&
      searchLanguages?.length > 0
    ) {
      setSearchLanguagesSaveValid(true);
    } else {
      setSearchLanguagesSaveValid(false);
    }
  }, [searchLanguages, loadSearchLanguages]);

  useEffect(() => {
    //remove id field;
    const formattedLoadSearchLanguagesNotListed = [];
    const formattedSearchLanguagesNotListed = [];

    loadSearchLanguagesNotListed?.map((langObj) => {
      let newObj = {
        language: langObj.language,
      };
      formattedLoadSearchLanguagesNotListed.push(newObj);
    });

    searchLanguagesNotListed?.map((langObj) => {
      let newObj = {
        language: langObj.language,
      };
      formattedSearchLanguagesNotListed.push(newObj);
    });

    //compare language fields
    const sameContents = deepEqual(
      formattedLoadSearchLanguagesNotListed,
      formattedSearchLanguagesNotListed
    );

    if (!sameContents) {
      setSearchLanguagesNotListedSaveValid(true);
    } else {
      setSearchLanguagesNotListedSaveValid(false);
    }
  }, [searchLanguagesNotListed, loadSearchLanguagesNotListed]);

  function setNewSearchLanguages(arr) {
    if (arr?.includes('notListed')) {
      if (searchLanguagesNotListed?.length === 0) {
        setSearchLanguagesNotListed([
          {
            id: uuidv4(),
            language: '',
          },
        ]);
      }
    } else {
      setSearchLanguagesNotListed([]);
    }

    setSearchLanguages(arr);
  } //provides formatting for notListed.

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
        tabIndex="0"
        id="searchLanguagesLabel"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={img_language}
            alt={IconAlts.iconLocationPin}
            style={{ height: '26px' }}
            className="mrg-r12 filter-theme"
          />
          <p>{Account.attendee.searchLanguages}</p>
        </div>
        <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
          <p className="fs14 mrg-auto-left">1 {Generic.required}</p>

          {searchLanguages?.length > 0 &&
            !searchLanguages.includes('select') && (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            )}
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.searchLanguagesDescription}
      </p>

      <div className="h24" />
      <SelectionsModal
        addButtonText={Account.attendee.addLanguages}
        DataOpts={LanguageSimpleOpts}
        fullWidth={true}
        iconModal={img_language}
        iconModalClass={'filter-theme'}
        iconOff={true}
        modalTitle={Account.attendee.searchLanguages}
        selectionState={searchLanguages}
        setSelectionState={setNewSearchLanguages}
        subTextOn={false}
        id={'searchLanguagesSelections'}
        itemButtonCustomWrapper={'item-button-full'}
      />

      <LanguageNotListed
        languageNotListed={searchLanguagesNotListed}
        setLanguagesNotListed={setSearchLanguagesNotListed}
        languages={searchLanguages}
        setLanguages={setNewSearchLanguages}
        customWrapper={'mrg-t24'}
        TextAreaOnBlurHandlerOff={true}
      />

      <div className="h24" />
      <SaveButton
        saveValid={
          searchLanguagesSaveValid || searchLanguagesNotListedSaveValid
        }
        handler={() =>
          saveAccountChange(
            {
              attendee: {
                ...accountAttendee,
                searchLanguages,
                searchLanguagesNotListed,
              },
            },
            'searchLanguages'
          )
        }
        saveAriaLanguage={Account.attendee.searchLanguages}
        isLoading={loadingSaveBtnUI === 'searchLanguages'}
      />
      <div className="h36" />
    </div>
  );
};

export default SearchLanguages;

import { useEffect, useState } from 'react';

//hooks
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import TextArea from '../../../../../components/TextArea/TextArea';

//utility
import { image_gears } from '../../../../../assets/images';
import {
  img_lightning,
  return_curved_arrow,
} from '../../../../../assets/icons';
import ScrollTo from '../../../../../utils/UI/ScrollTo';

const JoinRoom = ({ setPage }) => {
  //hooks
  const { settingsLastRoomCode } = useSettingsHandlers();
  const {
    connectTranslationRoomSocket,
    translationRoomSocketStatus,
    userSessionIdTranslations,
    translationsConnectionIsInitializing,
  } = useTranslationRoomSocket();
  const { handleSettingsSetLastRoomCode } = useSettingsHandlers();
  const { handleWarning } = useApiStatus();
  const { dismissKeypad } = useSettings();

  //language
  const { TranslationJoinRoom } = useLanguageTranslationRoom();

  //state
  const [joinRoomCode, setJoinRoomCode] = useState();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);

  //initialize
  useEffect(() => {
    ScrollTo({
      focusId: 'enterRoom',
      scrollToTop: true,
    });
  }, []); //accessibility

  function handleKeyDown(e, submitId) {
    if ((e.key === 'Enter' || submitId) && joinRoomCode?.length > 3) {
      dismissKeypad(e);
      handleConnectToRoom(joinRoomCode);
    }
  }

  function handleConnectToRoom(roomCode) {
    if (translationsConnectionIsInitializing) {
      handleWarning({
        message: 'tryAgain',
        origin: 'TranslationJoinRoom/handleConnectToRoom',
        id: Date.now(),
      });
    }

    if (roomCode?.length === 0) throw new Error('tryAgain');
    let formattedRoomCode = roomCode.toUpperCase().trim();

    if (translationRoomSocketStatus !== 'connected') {
      handleSettingsSetLastRoomCode(formattedRoomCode);
      connectTranslationRoomSocket(
        false,
        formattedRoomCode,
        userSessionIdTranslations
      );
    }
  }

  return (
    <div className={`translations__container ${!imgLoaded ? 'remove' : ''} `}>
      <img
        src={image_gears}
        alt="img"
        className="svg"
        onLoad={() => setImgLoaded(true)}
        style={{ maxHeight: '200px' }}
      />
      <div className="translations-selections">
        <p
          className="fs21 fwsb mrg-tb24 mrg-t12--ph mrg-b12--ph access-ob access-o6"
          aria-label={TranslationJoinRoom.joinRoom}
          tabIndex="0"
          id="enterRoom"
        >
          {TranslationJoinRoom.joinRoom}
        </p>

        <TextArea
          customWidth={'100%'}
          disableEnter={true}
          handleChange={setJoinRoomCode}
          hideWordCount={true}
          id={'roomCode'}
          labelText={TranslationJoinRoom.roomCode}
          limit={100}
          onBlurHandlerOff={true}
          ariaText={TranslationJoinRoom.roomCode}
          customKeyDownHandler={handleKeyDown}
          enterKeyHint={'enter'}
        />
        <ItemButton
          text={TranslationJoinRoom.submit}
          handler={(e) => handleKeyDown(e, true)}
          icon={return_curved_arrow}
          iconOn={true}
          customWrapper={'full-width  mrg-tb24'}
          iconCustomClass={'reverse'}
          isLoading={translationsConnectionIsInitializing}
          iconStyle={{ height: '21px' }}
        />

        {settingsLastRoomCode?.length > 0 && (
          <ItemButton
            text={`${TranslationJoinRoom.usePrevious} "${settingsLastRoomCode}"`}
            handler={() => handleConnectToRoom(settingsLastRoomCode)}
            icon={img_lightning}
            iconOn={true}
            customWrapper={'full-width mrg-b24'}
            unavailable={translationsConnectionIsInitializing}
            iconStyle={{ height: '24px' }}
          />
        )}

        <div className="divider full-width mrg-tb12 " />
        <ItemButton
          text={TranslationJoinRoom.exit}
          handler={() => setPage(1)}
          customWrapper={'full-width mrg-t24'}
          unavailable={translationsConnectionIsInitializing}
          iconStyle={{ height: '21px' }}
        />
      </div>
    </div>
  );
};

export default JoinRoom;

import { useState, useEffect, useRef } from 'react';

//Hooks
import { useSettings } from '../../../context/SettingsProvider';
import { useTranslationRoomSocket } from '../../../context/sockets/main/TranslationRoomSocketProvider';

//Components
import TranslationInitialize from './Content/TranslationInitialize';
import TranslationCreateRoom from './Content/CreateRoom/TranslationCreateRoom';
import TranslationJoinRoom from './Content/JoinRoom/TranslationJoinRoom';
import Spinner from '../../../components/Spinner/Spinner';

//Utility
import ScrollTo from '../../../utils/UI/ScrollTo';
import MyndFullPattern from '../../../layout/shared/MyndFullPattern/MyndFullPattern';
import TranslationsTutorial from './Content/Tutorial/TranslationsTutorial';

const Translations = () => {
  //Hooks
  const { setNavbar } = useSettings();
  const { translationRoomSocketStatus, closeTranslationRoomSocket } =
    useTranslationRoomSocket();

  //Component state
  const [page, setPage] = useState(1);

  //UI
  const [eventPageLoadComplete, setEventPageLoadComplete] = useState(false);
  const [isLoadingTranslationPage, setIsLoadingTranslationPage] =
    useState(false);

  const [removeAnimationBackground, setRemoveAnimationBackground] =
    useState(false);

  const initializationTimer = useRef();

  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
    setNavbar('translations');

    if (translationRoomSocketStatus !== 'closed') {
      closeTranslationRoomSocket({
        source:
          'Translations/page useEffect, translationRoomSocketStatus !== closed',
      });
    }

    return () => {
      closeTranslationRoomSocket({
        source: 'Translations/component unmounted',
      });
      clearTimeout(initializationTimer.current);
    };
  }, []); //on page load + on exit close translation socket connection

  useEffect(() => {
    if (page === 1) {
      setEventPageLoadComplete(false);
    }
    if (page !== 1) {
      setIsLoadingTranslationPage(true);
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      initializationTimer.current = setTimeout(() => {
        setEventPageLoadComplete(true);
      }, 500);
    }
  }, [page]);

  useEffect(() => {
    const pageTransitionTimer = setTimeout(() => {
      setIsLoadingTranslationPage(false);
    }, 500);

    return () => clearTimeout(pageTransitionTimer);
  }, [isLoadingTranslationPage]);

  return (
    <div className="translations full-width pos-rel  flex-center">
      {!removeAnimationBackground && <MyndFullPattern patternActive={true} />}

      {eventPageLoadComplete && page === 1 ? (
        <TranslationInitialize setPage={setPage} />
      ) : eventPageLoadComplete && !isLoadingTranslationPage && page === 2 ? (
        <TranslationJoinRoom
          setPage={setPage}
          setRemoveAnimationBackground={setRemoveAnimationBackground}
        />
      ) : eventPageLoadComplete && !isLoadingTranslationPage && page === 3 ? (
        <TranslationCreateRoom
          setPage={setPage}
          setRemoveAnimationBackground={setRemoveAnimationBackground}
        />
      ) : eventPageLoadComplete && !isLoadingTranslationPage && page === 4 ? (
        <TranslationsTutorial
          setPage={setPage}
          setRemoveAnimationBackground={setRemoveAnimationBackground}
        />
      ) : (
        isLoadingTranslationPage && (
          <div className="min-full-height flex-center mrg-auto-top mrg-auto-bottom">
            <Spinner minHeight={'100%'} minWidth={'100%'} />
          </div>
        )
      )}
    </div>
  );
};

export default Translations;

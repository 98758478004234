import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//Hooks
import { useUpdatePasswordMutation } from '../../accountApiSlice';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import useReCaptcha from '../../../../../hooks/useReCaptcha';
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//Components
import PasswordCheck from '../../../../../components/TextArea/PasswordCheck';
import PasswordConfirmInput from '../../../../../components/TextArea/PasswordConfirmInput';
import SaveButton from '../../../../../components/SaveButton/SaveButton';
import CreatePasswordInput from '../../../../../components/TextArea/CreatePasswordInput';

//Utility

const Password = ({ Account }) => {
  //Hooks
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { accountId } = useAccountReduxHandlers();
  const [
    updatePassword,
    {
      isLoading: updateAccountPasswordIsLoading,
      isFetching: updateAccountPasswordIsFetching,
    },
  ] = useUpdatePasswordMutation();

  //language
  const { PasswordInputs } = useLanguageComponents();

  //Component state
  const [saveValid, setSaveValid] = useState(false);
  const [reset, setReset] = useState(false);

  //
  const [currentPassword, setCurrentPassword] = useState();

  //
  const [newPassword, setNewPassword] = useState();
  const [newPasswordValid, setNewPasswordValid] = useState(false);

  //
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  //UI state
  const { handleMobileTap } = useSettings();
  const [tapHighlightForgotPassword, setTapHighlightForgotPassword] =
    useState(false);

  //Component variables
  const currentPasswordEl = document.getElementById('currentPassword');
  const newPasswordEl = document.getElementById('newPassword');
  const confirmNewPasswordEl = document.getElementById('confirmNewPassword');

  useEffect(() => {
    if (
      passwordsMatch &&
      newPasswordValid &&
      currentPassword?.length > 0 &&
      newPassword?.length > 0 &&
      passwordConfirm?.length > 0
    ) {
      setSaveValid(true);
    } else {
      setSaveValid(false);
    }
  }, [
    passwordsMatch,
    newPasswordValid,
    currentPassword,
    newPassword,
    passwordConfirm,
  ]);

  //API functions
  async function saveAccountPasswordChange(newData) {
    if (saveValid) {
      const recaptchaVerified = await handleReCaptchaVerify(
        'ACCOUNT_CHANGE_PASSWORD',
        false
      );

      if (recaptchaVerified !== true) return false;

      if (!updateAccountPasswordIsLoading && !updateAccountPasswordIsFetching) {
        try {
          await updatePassword({ accountId, newData });
        } catch (err) {
          handleError(
            {
              message: 'updateFailed',
            },
            'AccountPersonalContent/Email.js/saveAccountEmailChange'
          );
        }
        currentPasswordEl.value = '';
        newPasswordEl.value = '';
        confirmNewPasswordEl.value = '';

        setCurrentPassword('');
        setNewPassword('');
        setPasswordConfirm('');
        setPasswordsMatch(false);
        setNewPasswordValid(false);
        setSaveValid(false);
        setReset(!reset);
      }
    }
  }
  return (
    <>
      <div className="section">
        <div className="flex-row space-between">
          <label
            className="content-heading-styled text-gray"
            tabIndex="0"
            id="passwordLabel"
          >
            {Account.account.password}
          </label>
          <button
            className={`fs16 highlight-t-theme access-ob access-o6 ${
              tapHighlightForgotPassword ? 'highlight-t-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightForgotPassword(true),
                  () => setTapHighlightForgotPassword(false),
                ],
                [() => navigate('/forgotpassword')]
              )
            }
          >
            {Account.account.forgotPassword}
          </button>
        </div>
        <PasswordCheck
          id={'currentPassword'}
          labelText={PasswordInputs.currentPassword}
          handleChange={setCurrentPassword}
          currentPassword={currentPassword}
          asterisk={true}
          customWrapper={'mrg-t24'}
        />
        <CreatePasswordInput
          id={'newPassword'}
          asterisk={true}
          handleInput={setNewPassword}
          setValidPassword={setNewPasswordValid}
          autocompleteOff={true}
          customWrapper={'mrg-t24'}
          reset={reset}
        />
        <PasswordConfirmInput
          asterisk={true}
          customWrapper={'mrg-t24'}
          handleInput={setPasswordConfirm}
          id={'confirmNewPassword'}
          newPassword={newPassword}
          passwordConfirm={passwordConfirm}
          passwordsMatch={passwordsMatch}
          reset={reset}
          setPasswordsMatch={setPasswordsMatch}
        />
        <div className="h24" />
        <SaveButton
          saveValid={saveValid}
          handler={() =>
            saveAccountPasswordChange({ currentPassword, newPassword })
          }
          saveAriaLanguage={Account.account.password}
          isLoading={
            updateAccountPasswordIsLoading || updateAccountPasswordIsFetching
          }
        />
      </div>
      <div className="h36" />
    </>
  );
};

export default Password;

import { useEffect } from 'react';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';
import { cancel, checkmark_green } from '../../../../../assets/icons/index';

const TranslationsTutorialSpeaker = ({ setSubPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <div className="full-width">
      <h1 className="fwsb fs21 lh36">Learn as a Speaker</h1>
      <p className="fs18 lh28 mrg-t24 ">
        As a speaker, you will be required to provide the host with information
        related to your presentation. The host may request this from you
        directly, or you might receive an email with a link to a form hosted on
        MyndFull.
      </p>
      <h1 className="fwsb fs21 lh36 mrg-t24 ">Discussion Context</h1>
      <p className="fs18 lh28 mrg-t24 ">
        MyndFull Translations requires you to submit a brief description of your
        presentation — between 25 and 250 characters. This discussion context
        helps guide the translation tool by providing context for your talk.
        It's important to clearly define the scope of your discussion to
        maximize the accuracy of the translations.
      </p>
      <div className="flex-column mrg-t24">
        <p className="fs18">For example:</p>

        <div className="flex-row align-center mrg-t24">
          <img
            src={cancel}
            alt=""
            className="filter-red"
            style={{ height: '21px' }}
          />
          <p className="fs18 lh28 mrg-l12">
            "An overview of historical poetry."
          </p>
        </div>

        <div className="flex-row align-center mrg-t24">
          <img
            src={checkmark_green}
            alt=""
            className=""
            style={{ height: '21px' }}
          />
          <p className="fs18 lh28 mrg-l12">
            "An overview of sixteenth- and seventeenth-century English poetry."
          </p>
        </div>

        <p className="fs18 lh28 mrg-t24">
          The second example offers a more specific context, which leads to more
          accurate translations. For instance, the translation tool might
          receive poor voice-to-text input like: "Among the most famous
          sixteenth century poets was William shake a pear." With a clearly
          defined discussion context, the translation tool can intelligently
          correct this to: "Among the most famous sixteenth-century poets was
          William Shakespeare."
        </p>
      </div>
      <h1 className="fwsb fs21 lh36 mrg-t24">Keywords</h1>
      <p className="fs18 lh28 mrg-t24 ">
        You'll also be able to provide a list of up to 50 keywords. These might
        include words that are:
      </p>
      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">important to your topic</li>
        <li className="mrg-t12">difficult to translate</li>
        <li className="mrg-t12">challenging to pronounce</li>
        <li className="mrg-t12">
          from a language different than the one you're speaking in
        </li>
      </ul>
      <p className="fs18 lh28 mrg-t24 ">
        For example, if you're presenting in English and plan to reference the
        poet George Gascoigne, you should add his name as a keyword. The surname
        is non-English and can be hard to pronounce or transcribe accurately.
      </p>
      <p className="fs18 lh28 mrg-t24 ">
        To kick-start your keyword list, we've built a tool that can scan an
        uploaded document, such as your presentation, and suggest relevant
        keywords. This tool is available directly within the speaker submission
        form. Please note that MyndFull does not retain any copies of your
        submission.
      </p>
      <h1 className="fwsb fs21 lh36 mrg-t24">Presenting</h1>
      <p className="fs18 lh28 mrg-t24 ">
        When delivering your presentation, keep the following tips in mind to
        optimize translation quality:
      </p>
      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          Translations are processed after pauses in speech. To reduce delay,
          briefly pause at the end of each sentence.
        </li>
        <li className="mrg-t12">
          Speak directly into the microphone at all times.
        </li>
        <li className="mrg-t12">
          Enunciate clearly and maintain a reasonable speaking pace.
        </li>
      </ul>
      <p className="fs18 lh28 mrg-t24">
        That concludes the MyndFull Translations Speaker Tutorial. We hope you
        have a great experience — and please share your feedback with us, as
        we're always working to improve!
      </p>
      <div className="translations-selections mrg-auto-lr mrg-t24">
        <ItemButton
          text={'Return'}
          handler={() => setSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </div>
  );
};

export default TranslationsTutorialSpeaker;

//Hooks
import { useAccountReduxHandlers } from '../account/useAccountHandlers.js';
import { useAccountPersonalSocket } from '../../../context/sockets/main/AccountPersonalProvider.js';

//api
import { useLazyMyEventsQuery } from '../events/eventsApiSlice.js';
import {
  useLazyMyOrganizedEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
} from '../organize/organizeApiSlice';
import { useLazyMyDraftEventsQuery } from '../organize/draftEventApiSlice';
import {
  useArchiveNoticeMutation,
  useIgnoreRequestSpeakerContactMutation,
  useLazyGetAccountNoticesQuery,
  useRemoveAllNoticesMutation,
  useRemoveMyNoticeMutation,
  useSetAccountNoticesSeenMutation,
} from './noticesApiSlice.js';

export const useNoticeHandlers = () => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { accountPersonalSocketStatus, connectAccountPersonalSocket } =
    useAccountPersonalSocket();

  //notices api
  const [getAccountNotices, { isLoading: getAccountNoticesIsLoading }] =
    useLazyGetAccountNoticesQuery();
  const [setAccountNoticesSeen, { isLoading: setAccountNoticesSeenIsLoading }] =
    useSetAccountNoticesSeenMutation();
  const [
    ignoreRequestSpeakerContact,
    { isLoading: ignoreRequestSpeakerContactIsLoading },
  ] = useIgnoreRequestSpeakerContactMutation();
  const [archiveNotice, { isLoading: archiveNoticeIsLoading }] =
    useArchiveNoticeMutation();
  const [removeNotice, { isLoading: removeNoticeIsLoading }] =
    useRemoveMyNoticeMutation();
  const [removeAllNotices, { isLoading: removeAllNoticesIsLoading }] =
    useRemoveAllNoticesMutation();

  //event notices api
  const [getMyEvents, { isLoading: getMyEventsIsLoading }] =
    useLazyMyEventsQuery();
  const [getMyOrganizedEvents, { isLoading: getMyOrganizedEventsIsLoading }] =
    useLazyMyOrganizedEventsQuery();
  const [
    getMyOrganizedScheduledEvents,
    { isLoading: getMyOrganizedScheduledEventsIsLoading },
  ] = useLazyMyOrganizedScheduledEventsQuery();
  const [getMyDraftEvents, { isLoading: getMyDraftEventsIsLoading }] =
    useLazyMyDraftEventsQuery();

  //variables
  const getMyEventNoticeTypes = [
    'eventParticipationPublished',
    'draftParticipationDraftRemoved',
    'eventParticipationEventRemoved',
    'removalFromEventParticipation',
    'removalFromDraftParticipation',
  ];

  const getMyDraftEventsNoticeTypes = [
    'draftParticipationInviteAccepted',
    'draftParticipationInviteRejected',
    'translationsDataRequestRejected',
    'translationsDataRequestSubmitted',
  ];

  const getMyOrganizedEventsNoticeTypes = [
    'eventParticipationInviteAccepted',
    'eventParticipationInviteRejected',
    'translationsDataRequestRejected',
    'translationsDataRequestSubmitted',
    'emailInvitationCompleted',
  ];

  const noticeTypesWithActionsArr = [
    'contactRequest',
    'eventParticipationInvite',
    'eventParticipationTranslationsRequest',
  ];

  const noticeTypesWithNoResponseComponent = [
    'contactRequest',
    'eventParticipationInvite',
    'eventParticipationTranslationsRequest',
  ];

  function noticeReceivedRecently(notice) {
    const now = Date.now();
    const createdAt = new Date(notice.createdAt).getTime();
    const timeDifferenceCreatedAt = now - createdAt;

    if (timeDifferenceCreatedAt <= 10 * 500) {
      return true;
    }

    if (notice?.updatedAt) {
      const updatedAt = new Date(notice.updatedAt).getTime();
      const timeDifferenceUpdatedAt = now - updatedAt;

      if (timeDifferenceUpdatedAt <= 10 * 500) {
        return true;
      }
    }

    return false;
  }

  async function checkNoticeDataForDataRefresh(accountNotices) {
    try {
      //need to handle api endpoint refreshes for incoming changes (rather than the outgoing changes which can trigger refreshes via invalidatesTags)
      //Dont await; dont want to delay the time comparison
      if (accountNotices?.length > 0) {
        for (const notice of accountNotices) {
          const recent = noticeReceivedRecently(notice);

          if (recent) {
            if (notice.noticeStatus === 'unseen') {
              //Incoming Participant Refresh Checks//
              if (getMyEventNoticeTypes.includes(notice.noticeType)) {
                if (!getMyEventsIsLoading) {
                  getMyEvents(accountId);
                }
              }
              if (getMyDraftEventsNoticeTypes.includes(notice.noticeType)) {
                if (!getMyDraftEventsIsLoading) {
                  getMyDraftEvents(accountId);
                }
              }
              if (getMyOrganizedEventsNoticeTypes.includes(notice.noticeType)) {
                if (!getMyDraftEventsIsLoading) {
                  getMyDraftEvents(accountId);
                }

                if (!getMyOrganizedEventsIsLoading) {
                  getMyOrganizedEvents(accountId);
                }

                if (!getMyOrganizedScheduledEventsIsLoading) {
                  getMyOrganizedScheduledEvents(accountId);
                }
              }
              if (notice.noticeType === 'participantWithdrew') {
                if (notice.eventNoticeId?.draftId) {
                  if (!getMyDraftEventsIsLoading) {
                    getMyDraftEvents(accountId);
                  }
                }
                if (notice.eventNoticeId?.eventId) {
                  if (!getMyOrganizedEventsIsLoading) {
                    getMyOrganizedEvents(accountId);
                  }
                }
                if (notice.eventNoticeId?.scheduledEventId) {
                  if (!getMyOrganizedScheduledEventsIsLoading) {
                    getMyOrganizedScheduledEvents(accountId);
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        console.log(
          'DEV useNoticeHandlers checkNoticeDataForDataRefresh error',
          error
        );
      }
    }
  }

  async function handleGetAccountNotices() {
    if (!getAccountNoticesIsLoading) {
      await getAccountNotices(accountId);
    }
  }

  async function handleSetAccountNoticesSeen() {
    if (accountPersonalSocketStatus === 'connected') {
      if (!setAccountNoticesSeenIsLoading) {
        await setAccountNoticesSeen({ accountId });
      }
    } else {
      if (
        accountPersonalSocketStatus !== 'pending' &&
        accountPersonalSocketStatus !== 'initializing'
      ) {
        connectAccountPersonalSocket();
      }
    }
  }

  async function handleRemoveNotice({ noticeId }) {
    if (accountPersonalSocketStatus === 'connected') {
      if (!removeNoticeIsLoading) {
        await removeNotice({ accountId, noticeId });
      }
    } else {
      if (
        accountPersonalSocketStatus !== 'pending' &&
        accountPersonalSocketStatus !== 'initializing'
      ) {
        connectAccountPersonalSocket();
      }
    }
  }

  async function handleIgnoreRequestSpeakerContact({ noticeId }) {
    if (accountPersonalSocketStatus === 'connected') {
      if (!ignoreRequestSpeakerContactIsLoading) {
        await ignoreRequestSpeakerContact({ accountId, noticeId });
      }
    } else {
      if (
        accountPersonalSocketStatus !== 'pending' &&
        accountPersonalSocketStatus !== 'initializing'
      ) {
        connectAccountPersonalSocket();
      }
    }
  }

  async function handleArchiveNotice({ noticeId }) {
    if (accountPersonalSocketStatus === 'connected') {
      if (!archiveNoticeIsLoading) {
        await archiveNotice({ accountId, noticeId });
      }
    } else {
      if (
        accountPersonalSocketStatus !== 'pending' &&
        accountPersonalSocketStatus !== 'initializing'
      ) {
        connectAccountPersonalSocket();
      }
    }
  }

  async function handleRemoveAllNotices() {
    if (accountPersonalSocketStatus === 'connected') {
      if (!removeAllNoticesIsLoading) {
        await removeAllNotices({ accountId });
      }
    } else {
      if (
        accountPersonalSocketStatus !== 'pending' &&
        accountPersonalSocketStatus !== 'initializing'
      ) {
        connectAccountPersonalSocket();
      }
    }
  }

  return {
    checkNoticeDataForDataRefresh,
    ///
    handleGetAccountNotices,
    handleSetAccountNoticesSeen,
    handleRemoveNotice,
    handleIgnoreRequestSpeakerContact,
    handleArchiveNotice,
    handleRemoveAllNotices,

    //loading state
    getAccountNoticesIsLoading,
    setAccountNoticesSeenIsLoading,
    ignoreRequestSpeakerContactIsLoading,
    archiveNoticeIsLoading,
    removeNoticeIsLoading,
    removeAllNoticesIsLoading,
    getMyEventsIsLoading,
    getMyOrganizedEventsIsLoading,
    getMyOrganizedScheduledEventsIsLoading,
    getMyDraftEventsIsLoading,

    //data arrays
    getMyEventNoticeTypes,
    getMyDraftEventsNoticeTypes,
    getMyOrganizedEventsNoticeTypes,
    noticeTypesWithActionsArr,
    noticeTypesWithNoResponseComponent,
  };
};

import { useState, useEffect } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useGenericModal } from '../../../../context/GenericModalProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import Spinner from '../../../Spinner/Spinner';

//Utility
import {
  cancel,
  return_curved_arrow,
  trash,
  warning,
} from '../../../../assets/icons';

const SelectDropdownModal = ({
  id,
  selectionKey,
  selectionValue,
  handleModalSelection,
  SelectionOpts,
  modalLabel,
  index,
}) => {
  //Hooks
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //language
  const { Generic, SelectDropdownModal, IconAlts } = useLanguageComponents();

  //Component state
  const [filteredList, setFilteredList] = useState();

  //UI state

  const [tapHighlightCloseBtn, setTapHighlightCloseBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();

  //Component variables
  let tagsList;

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId(`${id}-dropdown-main`);
  }, []);

  //Component functions
  useEffect(() => {
    if (selectionKey !== null && SelectionOpts[selectionKey]) {
      const filteredList = { ...SelectionOpts };
      delete filteredList[selectionKey];
      setFilteredList(filteredList);
    } else {
      setFilteredList(SelectionOpts);
    }
  }, [selectionKey, SelectionOpts]); //removes selected tags

  useEffect(() => {
    if (filteredList) {
      document.getElementById(`selectionDropdownModalLabel-${id}`).focus();
    }
  }, [filteredList]);

  //JSX conditional
  if (filteredList) {
    if (Object.keys(filteredList)?.length > 0) {
      let lastItemIndex = Object.entries(filteredList).length - 1;

      tagsList = (
        <>
          {Object.entries(filteredList)?.map(([key, value], index) => {
            const isLastItem = index === lastItemIndex;
            if (key === 'select') return null;

            return (
              <li
                key={`${key}`}
                style={isLastItem ? { marginBottom: '160px' } : null}
              >
                <button
                  className={`item fs16 fwsb ${
                    tapHighlightSelectedKey === key ? 'item-selected' : ''
                  }`}
                  key={`list-item-${index}`}
                  onClick={() =>
                    handleMobileTap(
                      [
                        () => setTapHighlightSelectedKey(key),
                        () => setTapHighlightSelectedKey(),
                      ],
                      [
                        () => handleModalSelection(key),
                        () => handleCloseModal(),
                      ]
                    )
                  }
                  type="button"
                  tabIndex="0"
                  aria-label={value.country}
                  id={`list-item-${index}`}
                >
                  {value}
                </button>
              </li>
            );
          })}
        </>
      );
    } else {
      tagsList = (
        <>
          <div className="h24" />
          <div className="flex-center flex-column align-center">
            <img
              src={warning}
              alt={IconAlts.iconWarning}
              className="svg"
              style={{ maxHeight: '40px' }}
            />
            <p className="fs18 fwn flex-center text-center mrg-tb24">
              {SelectDropdownModal.noMoreSelections}
            </p>
          </div>
          <div className="h24" />
        </>
      );
    }
  } else {
    tagsList = <Spinner minHeight={'100%'} />;
  }

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="selectDropdownModal"
      key={`selectdropdownmodal-${index}`}
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`selectionDropdownModalLabel-${id}`}
          >
            {modalLabel}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgt access-ob ${
            tapHighlightCloseBtn ? 'filter-img-theme' : 'filter-img-lightgray'
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightCloseBtn(true),
                () => setTapHighlightCloseBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img
            src={return_curved_arrow}
            alt={IconAlts.iconClose}
            className="svg"
            style={{ maxHeight: '48px' }}
          />
        </button>
      </div>
      <ul
        className="modal-basic__container list-style-none"
        id="selectDropdownList"
      >
        {selectionValue && selectionKey !== 'select' && (
          <li className="mrg-b12">
            <button
              className={`item flex-row flex-center access-ob access-o6  ${
                tapHighlightSelectedKey === selectionKey ? 'item-selected' : ''
              }`}
              tabIndex="0"
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightSelectedKey(selectionKey),
                    () => setTapHighlightSelectedKey(),
                  ],
                  [
                    () => {
                      handleModalSelection('select');
                      handleCloseModal();
                    },
                  ]
                )
              }
              aria-label={Generic.clearSelection}
            >
              <div className="flex-row align-center fwsb fs16 flex-center">
                <div className="flex-row fwsb fs16">{selectionValue}</div>
                <img
                  className={`mrg-l12 filter-gray`}
                  src={cancel}
                  alt={IconAlts.iconRemove}
                  style={{ height: '21px' }}
                />
              </div>
            </button>
          </li>
        )}

        {tagsList}
      </ul>
    </div>
  );
};

export default SelectDropdownModal;

import { useState, useRef, useEffect } from 'react';

//Hooks
import { useRegistrationHandlers } from '../../useRegistrationHandlers';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';

//Components
import PersonalAccountInfo from './PersonalAccountInfo';
import PersonalSearchSettings from './PersonalSearchSettings';
import PersonalCompletionPage from './PersonalCompletionPage';
import TranslationRoomCodeRegistration from '../Hybrid/TranslationRoomCodeRegistration';
import PersonalBilling from './PersonalBilling';
import EmailPreferences from '../Hybrid/EmailPreferences';

//Utility
import { retryWrapper } from '../../../../../utils/logic/retryFns';

const PersonalRegistrationMain = () => {
  //Hooks
  const {
    handleCreateMyndFullAccountPersonal,
    handleRegistrationSetGenericIsLoadingCompleteRegistration,
    registrationComponentArr,
    registrationIsSuccess,
    registrationPage,
    registrationTranslationRoomCodesOptIn,
    handleAddPaymentMethodIdToUnverifiedAccount,
    handleSendWelcomeEmail,
    handleRemoveUnverifiedAccount,
  } = useRegistrationHandlers();
  const { handleError } = useApiStatus();

  //State Personal
  const [temporaryPMId, setTemporaryPMId] = useState();
  const [zoom, setZoom] = useState(1);
  const [croppedImg, setCroppedImg] = useState({
    id: '',
    croppedImage: '',
    imagePreview: '',
  });
  const [crop, setCrop] = useState({
    x: 0,
    y: 0,
    width: 264,
    height: 264,
  });

  //Stripe
  const [clientSecret, setClientSecret] = useState('');

  //UI
  const [fadeState, setFadeState] = useState('registration-container--fade-in');
  const [currentPage, setCurrentPage] = useState('personalAccountInfo');

  const [initialAnimationRan, setInitialAnimationRan] = useState(false);

  //variables
  const paymentCardRef = useRef();
  const formContainerRef = useRef();

  const initializationAnimationTimer = useRef();
  const pageTransitionTimer = useRef();

  //UI
  useEffect(() => {
    initializationAnimationTimer.current = setTimeout(() => {
      setInitialAnimationRan(true);
    }, 400);

    return () => {
      clearTimeout(initializationAnimationTimer.current);
      clearTimeout(pageTransitionTimer.current);
    };
  }, []);

  useEffect(() => {
    if (initialAnimationRan) {
      setFadeState('registration-container--fade-out');

      pageTransitionTimer.current = setTimeout(() => {
        setCurrentPage(registrationComponentArr[registrationPage - 3]);
        setFadeState('registration-container--fade-in');
      }, 400);

      return () => clearTimeout(pageTransitionTimer.current);
    }
  }, [registrationPage]);

  //Complete registration func.
  async function handleCompleteRegistration() {
    handleRegistrationSetGenericIsLoadingCompleteRegistration(true);
    let newAccountIdToRemoveIfError;

    try {
      //add any billing-related opt-in conditions here -- currently billing only realted to translation rooms.
      if (!registrationTranslationRoomCodesOptIn) {
        await retryWrapper(handleCreateMyndFullAccountPersonal, [
          false,
          croppedImg,
        ]);
      } else {
        const { unverifiedAccountId, accountVerificationURL } =
          await retryWrapper(handleCreateMyndFullAccountPersonal, [
            true,
            croppedImg,
          ]);
        newAccountIdToRemoveIfError = unverifiedAccountId;

        //add paymentMethod
        if (unverifiedAccountId && accountVerificationURL) {
          let newPaymentMethodId;

          if (!temporaryPMId) {
            newPaymentMethodId =
              await paymentCardRef.current.handleFinalizeRegistrationCustomerAndSubscriptions();
            setTemporaryPMId(newPaymentMethodId);
          } //cant refetch same pmId so need temp storage for retries

          if (!newPaymentMethodId && !temporaryPMId) {
            if (process.env.REACT_APP_ENV === 'development') {
              console.log('DEV: no payment method id.');
            }
            throw new Error();
          }

          await handleAddPaymentMethodIdToUnverifiedAccount({
            unverifiedAccountId,
            paymentMethodId: newPaymentMethodId || temporaryPMId,
          });

          //registration complete send verification email.
          await handleSendWelcomeEmail(
            unverifiedAccountId,
            accountVerificationURL
          );
        }
      }
    } catch (error) {
      //remove unverifiedAccount so user can retry
      if (newAccountIdToRemoveIfError) {
        await handleRemoveUnverifiedAccount(newAccountIdToRemoveIfError, true);
      }

      if (error?.message !== 'stripe') {
        handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin: 'PersonalRegistrationMain.js/handleCompleteRegistration',
        });
      }

      handleRegistrationSetGenericIsLoadingCompleteRegistration(false);
    }
  }

  return (
    <div className={`${fadeState}`} ref={formContainerRef}>
      {!registrationIsSuccess ? (
        <>
          {currentPage === 'personalAccountInfo' && (
            <PersonalAccountInfo
              croppedImg={croppedImg}
              setCroppedImg={setCroppedImg}
              HOCrop={crop}
              HOZoom={zoom}
              setHOCrop={setCrop}
              setHOZoom={setZoom}
            />
          )}

          {currentPage === 'personalSearchSettings' && (
            <PersonalSearchSettings />
          )}

          {currentPage === 'translationRoomCodeRegistration' && (
            <TranslationRoomCodeRegistration />
          )}

          <div
            className={`${
              currentPage === 'billing'
                ? ''
                : 'opacity-0 registration-offscreen visibility-hidden'
            }`}
            aria-hidden={currentPage === 'billing' ? false : true}
            style={
              currentPage === 'billing'
                ? { height: 'fit-content' }
                : { height: '0px' }
            }
          >
            <PersonalBilling
              paymentCardRef={paymentCardRef}
              clientSecret={clientSecret}
              setClientSecret={setClientSecret}
              activePage={currentPage === 'billing'}
            />
          </div>

          {currentPage === 'emailPreferences' && (
            <EmailPreferences
              handleCompleteRegistration={handleCompleteRegistration}
            />
          )}
        </>
      ) : (
        <PersonalCompletionPage />
      )}
    </div>
  );
};

export default PersonalRegistrationMain;

import { useSettings } from '../../context/SettingsProvider';

const DateInput = ({ id, labelText, setDateInput, dateInput }) => {
  //hooks
  const { dismissKeypad } = useSettings();

  const handleOnChange = (e) => {
    // Handle backspace
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      return setDateInput(e.target.value);
    }

    let num = e.target.value;
    let filteredNum = num.replace(/\D/g, '');

    let size = filteredNum.length;

    if (size > 3) {
      filteredNum = filteredNum.slice(0, 4) + '-' + filteredNum.slice(4, 10);
    }
    if (size > 6) {
      filteredNum = filteredNum.slice(0, 7) + '-' + filteredNum.slice(7);
    }
    setDateInput(filteredNum);
  };

  function handleKeyDown(e) {
    dismissKeypad(e);
  }

  return (
    <div className="flex-column">
      {labelText && <label className="fs18 fwsb mrg-b6">{labelText}</label>}
      <input
        id={id}
        className="date-input"
        inputMode="numeric"
        type="text"
        maxLength="10"
        placeholder="YYYY-MM-DD"
        onChange={(e) => handleOnChange(e)}
        onKeyDown={(e) => handleKeyDown(e)}
        enterKeyHint="done"
        tabIndex="0"
        value={dateInput}
      ></input>
    </div>
  );
};

export default DateInput;

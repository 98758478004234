import { useSettings } from '../../context/SettingsProvider';

const useLanguageAdmin = () => {
  const { contentLanguage } = useSettings();

  const Auth = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        emailAddress: 'Email Address',
        password: 'Password',
        signIn: 'Sign In',
        signInWithEmail: 'Sign in with email',
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        emailAddress: 'Adresse courriel',
        password: 'Mot de passe',
        signIn: 'Se connecter',
        signInWithEmail: 'Connectez-vous avec e-mail',
      };
    }
    return translation;
  };

  function AdminStatusEl(status) {
    let statusEl;

    if (contentLanguage === 'en-CA' && status === 'open') {
      statusEl = <div className="fs18 fwsb color-red">Open</div>;
    }

    if (contentLanguage === 'en-CA' && status === 'pendingUserAction') {
      statusEl = (
        <div className="fs18 fwsb color-theme ">Pending User Action</div>
      );
    }

    if (contentLanguage === 'en-CA' && status === 'resolved') {
      statusEl = <div className="fs18 fwsb color-green">Resolved</div>;
    }

    return statusEl;
  }

  const SupportHistoryStatus = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        open: 'Open',
        acceptedAssignment: 'Accepted Assignment',
        removedAssignment: 'Removed Assignment',
        pendingUserAction: 'Set Pending User Action',
        resolved: 'Resolved',
        reopened: 'Reopened',
        createdTicket: 'Created Ticket',
        createdTicketAndAcceptedAssignment:
          'Created Ticket and Accepted Assignment',
      };
    }

    //ADD FRENCH

    return translation;
  };

  const AdminTicketType = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        event: 'Event',
        account: 'Account',
        translationRoom: 'Translation Room',
      };
    }

    //ADD FRENCH

    return translation;
  };

  //add to ticketModel
  const SupportStatus = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        open: 'Open',
        resolved: 'Resolved',
        pendingUserAction: 'Pending User Action',
        myAssignment: 'My Assignment',
        unassigned: 'Unassigned',
        assigned: 'Assigned',
      };
    }

    //ADD FRENCH

    return translation;
  };

  const EventVerificationStatus = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        eventCreated: 'Event Created',
        scheduledEventCreated: 'Scheduled Event Created',
        eventUpdated: 'Event Updated',
        eventRemoved: 'Event Removed',
        eventArchived: 'Event Archived',
        eventVerificationDisapproved: 'Event Verification Disapproved',
        eventVerificationApproved: 'Event Verification Approved',
      };
    }

    //ADD FRENCH

    return translation;
  };

  const TicketTableFilters = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        status: {
          open: {
            open: 'Open',
          },
          pendingUserAction: {
            pendingUserAction: 'Pending User Action',
          },
          resolved: {
            resolved: 'Resolved',
          },
        },
        issues: {
          spam: {
            spam: 'Spam',
          },
          falseInfo: {
            falseInfo: 'False Information',
          },
          unlawfulActivity: {
            unlawfulActivity:
              'Unlawful activity or promoting unlawful activity',
          },
          defamDiscrim: {
            defamDiscrim:
              'Defamatory, discriminatory, or mean-spirited content',
          },
          infringingRight: {
            infringingRight:
              'Infringing on 3rd party rights (patent, trademark, copyright etc.)',
          },
          violatingPrivacy: {
            violatingPrivacy: 'Violating the privacy of another person',
          },
          other: {
            other: 'Other',
          },
        },
        type: {
          event: {
            event: 'Event',
          },
          account: {
            account: 'Account',
          },
        },
        submitUserFilter: {
          myndFullOnly: {
            myndFullOnly: 'MyndFull Only',
          },
          nonMyndFull: {
            nonMyndFull: 'Non-MyndFull',
          },
        },
      };
    }

    //ADD FRENCH

    return translation;
  };

  const Status = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        active: 'Active',
        inactive: 'Inactive',
        suspended: 'Suspended',
        locked: 'Locked',
        removed: 'Removed',
        archived: 'Archived',
      };
    }

    //ADD FRENCH

    return translation;
  };

  const AccountEvents = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        archivedEvents: 'Archived Events',
        draftEvents: 'Draft Events',
        ignoredEvents: 'Ignored Events',
        organizedEvents: 'Organized Events',
        organizedEventsArchived: 'Organized Events Archived',
        pendingApprovalInPerson: 'Pending Approval (In Person)',
        pendingApprovalVirtual: 'Pending Approval (Virtual)',
        savedEvents: 'Saved Events',
        registrationsInPerson: 'Registrations (In Person)',
        registrationsVirtual: 'Registrations (Virtual)',
        removedEvents: 'Removed Events',
      };
    }

    //ADD FRENCH

    return translation;
  };

  function StatusEl(status) {
    let statusEl;

    if (contentLanguage === 'en-CA' && status === 'active') {
      statusEl = (
        <div className="flex-row align-center">
          <p>Active</p>
          <div className="status-dot status-dot-green" />
        </div>
      );
    }

    if (contentLanguage === 'en-CA' && status === 'inactive') {
      statusEl = (
        <div className="flex-row align-center">
          <p>Inactive</p>
          <div className="status-dot status-dot-orange" />
        </div>
      );
    }

    if (contentLanguage === 'en-CA' && status === 'suspended') {
      statusEl = (
        <div className="flex-row align-center">
          <p>Suspended</p>
          <div className="status-dot status-dot-red" />
        </div>
      );
    }

    if (contentLanguage === 'en-CA' && status === 'locked') {
      statusEl = (
        <div className="flex-row align-center">
          <p>Locked</p>
          <div className="status-dot status-dot-red" />
        </div>
      );
    }

    if (contentLanguage === 'en-CA' && status === 'removed') {
      statusEl = (
        <div className="flex-row align-center">
          <p>Removed</p>
          <div className="status-dot status-dot-grey" />
        </div>
      );
    }

    if (contentLanguage === 'en-CA' && status === 'archived') {
      statusEl = (
        <div className="flex-row align-center">
          <p>Archived</p>
          <div className="status-dot status-dot-orange" />
        </div>
      );
    }

    return statusEl;
  }

  return {
    Auth: Auth(),
    SupportHistoryStatus: SupportHistoryStatus(),
    SupportStatus: SupportStatus(),
    AdminTicketType: AdminTicketType(),
    Status: Status(),
    TicketTableFilters: TicketTableFilters(),
    EventVerificationStatus: EventVerificationStatus(),
    AccountEvents: AccountEvents(),
    //Translation Functions for elements
    AdminStatusEl,
    StatusEl,
  };
};

export default useLanguageAdmin;

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

const NoticeOverlayMobileBackground = ({
  width,
  openMobileNotificationsMenu,
  setOpenMobileNotificationsMenu,
  setSettingsMode,
  setArchiveMode,
}) => {
  //language
  const { NoticeOverlay } = useLanguageComponents();

  return (
    <>
      {width <= 480 && (
        <button
          className={`access-ow access-o-12 ${
            openMobileNotificationsMenu ? 'slidemenu__exit' : 'remove'
          }`}
          onClick={() => {
            setOpenMobileNotificationsMenu(false);
            setSettingsMode(false);
            setArchiveMode(false);
          }}
          tabIndex="0"
          aria-label={NoticeOverlay.ariaCloseNoticesMenu}
          id="overlay"
        />
      )}
    </>
  );
};

export default NoticeOverlayMobileBackground;

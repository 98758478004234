import { useState, useEffect, useRef } from 'react';

//Hooks
import { useApiStatus } from '../../context/ApiStatusProvider';
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponent from '../../language/useLanguageComponents';

//utils
import { axiosLimited } from '../../axios/axios';
import handleInputClick from './handleInputClick';

// allowSaveIfMatchingLoadValue, used for account saving where the value has already been established but not for new registrations

const EmailInput = ({
  asterisk,
  customWrapper,
  customWidth,
  emailCollectionToCheck,
  handleInput,
  id,
  loadValue,
  setSaveValid,
  checkRanAfterBlurToggle,
  reset,
  allowSaveIfMatchingLoadValue,
  componentAccountValidationOff,
  initialize,
}) => {
  //Hooks
  const { dismissKeypad } = useSettings();
  const { handleError } = useApiStatus();

  //language
  const { EmailInput } = useLanguageComponent();

  //Component state
  const [previousInput, setPreviousInput] = useState();
  const [checkRan, setCheckRan] = useState(false);
  const [invalidEmailAddress, setInvalidEmailAddress] = useState(false);
  const [emailAddressRegistered, setEmailAddressRegistered] = useState(false);
  const [checkRanAfterBlur, setCheckRanAfterBlur] = useState(false);
  //UI

  const [activeWarning, setActiveWarning] = useState(false);
  const [warningText, setWarningText] = useState();
  const [focusHighlight, setFocusHighlight] = useState(false);
  const [borderHighlight, setBorderHighlight] = useState(false);

  //Component variables
  const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const emailInput = useRef();

  useEffect(() => {
    if (initialize) {
      validation(loadValue);
    }
  }, [initialize]);

  //funct
  useEffect(() => {
    if (checkRanAfterBlur) validation(emailInput.current.value);
  }, [checkRanAfterBlur]); //if blur toggle prop is true, this will ensure validation checked after first blur

  //UI
  useEffect(() => {
    if (!invalidEmailAddress && !emailAddressRegistered && activeWarning) {
      const timer = setTimeout(() => {
        setActiveWarning(false);
        setWarningText('');
      }, 500);
      return () => clearTimeout(timer);
    } //this triggers animation to fade away warning text

    //this timer creates a short delay to give the user a chance to provide valid email and not render the warning text
    let invalidEmailAddressTimer;

    if (invalidEmailAddress) {
      invalidEmailAddressTimer = setTimeout(() => {
        setActiveWarning(true);
        setWarningText(EmailInput.invalidEmailAddress);
      }, 1000);

      return () => clearTimeout(invalidEmailAddressTimer);
    }

    if (!invalidEmailAddress) clearTimeout(invalidEmailAddressTimer);
  }, [invalidEmailAddress, emailAddressRegistered]);

  useEffect(() => {
    setPreviousInput();
    setCheckRan(false);
    setInvalidEmailAddress(false);
    setEmailAddressRegistered(false);
    setCheckRanAfterBlur(false);
    setActiveWarning(false);
    setWarningText();
    setFocusHighlight(false);
  }, [reset]);

  function validation(value) {
    let newEmail = value?.trim()?.toLowerCase();
    const check = EMAIL_REGEX.test(newEmail);

    if (checkRanAfterBlurToggle && !loadValue) {
      if (!checkRanAfterBlur) return;
    }

    if (componentAccountValidationOff) {
      if ((!newEmail || !check) && newEmail?.length > 0) {
        setInvalidEmailAddress(true);
      } else {
        setInvalidEmailAddress(false);
      }

      setCheckRan(true);
      return handleInput(value);
    } //ignores validations for account creation but runs validation

    if (allowSaveIfMatchingLoadValue) {
      if (loadValue && newEmail === loadValue) {
        setInvalidEmailAddress(false);
        return setSaveValid(false);
      } //same as load value
    }

    if (!newEmail || !check) {
      setInvalidEmailAddress(true);
      setSaveValid(false);
    } else {
      setInvalidEmailAddress(false);
      if (previousInput && newEmail === previousInput) return;
      handleCheckIfEmailExists(newEmail);
    }

    setCheckRan(true);
  }

  //api
  async function handleCheckIfEmailExists(newEmail) {
    if (!emailCollectionToCheck) return;
    try {
      const { data } = await axiosLimited.get(
        `/api/main/auth/emailValidation/${newEmail}/${emailCollectionToCheck}`
      );

      if (data.emailExists) {
        setActiveWarning(true);
        setWarningText(EmailInput.emailAlreadyRegistered);
        setEmailAddressRegistered(true);
        setSaveValid(false);
      } else {
        setEmailAddressRegistered(false);
        setSaveValid(true);
        handleInput(newEmail);
      }
    } catch (error) {
      handleError({
        error,
        id: Date.now(),
        origin: 'EmailInput.js/handleCheckIfEmailExists',
      });
    }
    setPreviousInput(newEmail);
  }

  return (
    <div className="input-error__wrapper">
      <div
        className={`text-input__wrapper 
        ${customWrapper ? customWrapper : ''}     `}
        style={customWidth ? { width: `${customWidth}` } : null}
        onMouseEnter={() => setBorderHighlight(true)}
        onMouseLeave={() => setBorderHighlight(false)}
      >
        <label
          className="text-input-label no-select"
          onClick={() => handleInputClick(emailInput.current)}
        >
          <p>
            {EmailInput.emailAddress}&nbsp;
            {asterisk && <span className="highlight">*</span>}
          </p>
        </label>

        <input
          id={id}
          name={id}
          type="text"
          ref={emailInput}
          autoComplete="on"
          maxLength="255"
          enterKeyHint="done"
          defaultValue={loadValue}
          aria-label={`${EmailInput.emailAddress}.`}
          className={`text-input ${
            (invalidEmailAddress || emailAddressRegistered) &&
            activeWarning &&
            checkRan
              ? 'text-input-warning'
              : ''
          } ${borderHighlight ? 'highlight-o-theme--tap' : ''} ${
            focusHighlight
              ? 'highlight-ob-theme--thin--active'
              : 'highlight-ob-theme--thin'
          }`}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setFocusHighlight(false);
              dismissKeypad(e);
            }
          }}
          onFocus={() => {
            setFocusHighlight(true);
          }}
          onBlur={(e) => {
            setFocusHighlight(false);
            if (checkRanAfterBlurToggle) {
              setCheckRanAfterBlur(true);
              validation(e.target.value, true);
            }
          }}
          onChange={(e) => validation(e.target.value)}
        />
      </div>
      <p
        className={`input-error access-ob ${
          (invalidEmailAddress || emailAddressRegistered) &&
          activeWarning &&
          checkRan
            ? 'input-error-animation'
            : activeWarning && checkRan
            ? 'input-error-hide'
            : ''
        }`}
        tabIndex={invalidEmailAddress || emailAddressRegistered ? '0' : '-1'}
      >
        {warningText}
      </p>
    </div>
  );
};

export default EmailInput;

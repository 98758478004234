import {
  image_preview_banner_enCA,
  image_preview_banner_frCA,
} from '../assets/language_images';
import { useSettings } from '../context/SettingsProvider';

const useLanguageData = () => {
  const { contentLanguage } = useSettings();

  const PreviewBanner = () => {
    let imageSrc;

    if (contentLanguage === 'en-CA') {
      imageSrc = image_preview_banner_enCA;
    }
    if (contentLanguage === 'fr-CA') {
      imageSrc = image_preview_banner_frCA;
    }
    return imageSrc;
  };

  return {
    PreviewBanner: PreviewBanner(),
  };
};

export default useLanguageData;

import { useRef, useState, useEffect } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useNavigate } from 'react-router-dom';
import { useAuthReduxHandlers } from '../useAuthHandlers';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import { useApiStatus } from '../../../../context/ApiStatusProvider';

//language
import useLanguageAuth from '../../../../language/features/useLanguageAuth';

//Components
import Spinner from '../../../../components/Spinner/Spinner';

const LoginContainer = () => {
  //Hooks
  const navigate = useNavigate();
  const { handleError } = useApiStatus();
  const { handleLogin, handleGetMyAccount } = useAuthReduxHandlers();
  const { setNavbar, width, handleMobileTap, dismissKeypad, isMobileDevice } =
    useSettings();
  const { settingsPersist, settingsLoginEmail, handleSettingsSetLoginEmail } =
    useSettingsHandlers();

  //language
  const { Auth } = useLanguageAuth();

  //Component state
  const pwdRef = useRef();
  const emailRef = useRef();
  const [loginEmail, setLoginEmail] = useState();
  const [pwd, setPwd] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  //UI state
  const [tapHighlightForgotPassword, setTapHighlightForgotPassword] =
    useState(false);
  const [tapHighlightSignUp, setTapHighlightSignUp] = useState(false);

  //Component variables
  const loadStoredEmailEffectRan = useRef(false);
  const handlePwdInput = (e) => setPwd(e?.target?.value || '');

  //Initialize & UI functions
  useEffect(() => {
    setNavbar('login');
  }, []);

  useEffect(() => {
    if (!isMobileDevice) {
      if (loginEmail && pwdRef.current) {
        pwdRef.current.focus();
      }
    }
  }, [isMobileDevice, pwdRef]);

  useEffect(() => {
    if (loadStoredEmailEffectRan.current === false) {
      loadStoredEmailEffectRan.current = true;

      if (settingsPersist) {
        const localStorageEmail = JSON.parse(localStorage.getItem('email'));

        if (localStorageEmail || settingsLoginEmail) {
          setLoginEmail(localStorageEmail || settingsLoginEmail);
          const loginEl = document.getElementById('email');
          if (loginEl) {
            loginEl.value = localStorageEmail || settingsLoginEmail;
          }
        }
      } else {
        handleSettingsSetLoginEmail('');
      }
    }
  }, [localStorage, settingsLoginEmail, settingsPersist]);

  //Component functions
  async function login(e) {
    e.preventDefault();
    dismissKeypad(e);

    if (!loginEmail || !pwd)
      return handleError({
        id: Date.now(),
        origin: 'Login.js/login',
        message: 'incompleteInformation',
      });

    setIsLoading(true);

    if (settingsPersist) {
      localStorage.setItem('email', JSON.stringify(loginEmail));
    }
    const loginAccountId = await handleLogin(loginEmail, pwd);

    if (loginAccountId) {
      await handleGetMyAccount({
        ignoreAuthLoadingDisplay: true,
        loginAccountId,
      });
    } else {
      setPwd('');
    }
    setIsLoading(false);
  }

  return (
    <div className="login">
      <div
        className="login__container login-box-shadow-hover access-ob access-o-6"
        tabIndex="0"
        aria-label={Auth.signInWithEmail}
      >
        <h1 className="color-gray fs24 mrg-auto-left mrg-auto-right mrg-t12--ph">
          {Auth.signInWithEmail}
        </h1>
        <div className="h36-ph24" />
        <form
          onSubmit={(e) => {
            setIsLoading(true);
            login(e, loginEmail, pwd);
            setIsLoading(false);
          }}
          aria-label={Auth.signIn}
        >
          <input
            type="email"
            id="email"
            ref={emailRef}
            onChange={(e) => {
              e.preventDefault();
              setLoginEmail(e.target.value);
            }}
            autoComplete="off"
            placeholder={Auth.emailAddress}
            required
            className="login-input fs21"
            tabIndex="0"
            maxLength="255"
            enterKeyHint="next"
          />
          <div className="h24" />
          <input
            type="password"
            id="password"
            ref={pwdRef}
            onChange={(e) => {
              e.preventDefault();
              handlePwdInput(e);
            }}
            value={pwd}
            placeholder={Auth.password}
            autoComplete="on"
            required
            className="login-input fs21"
            tabIndex="0"
            maxLength="255"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                login(e);
              }
            }}
            enterKeyHint="enter"
          />

          <div className="h36" />
          {isLoading ? (
            <Spinner maxHeight={width > 480 ? '64px' : '54px'} />
          ) : (
            <div className="login-btn__wrapper">
              <button
                className="login-btn fs24 fwn color-white ff2 access-ob access-o6"
                id="signin"
                tabIndex="0"
                aria-label={`${
                  !settingsLoginEmail && `${Auth.legend.emailRequired}`
                } ${!pwd && `${Auth.legend.passwordRequired}`} ${
                  settingsLoginEmail && pwd && `${Auth.legend.publicDevice}`
                }`}
                onClick={(e) => login(e)}
              >
                {Auth.signIn}
              </button>
            </div>
          )}
        </form>
        <div className="h24" />
        <div className="login-additional ">
          <button
            className={`flex-center color-gray fs18 fwsb highlight-it-theme mrg-b24 ${
              tapHighlightSignUp ? 'highlight-it-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightSignUp(true),
                  () => setTapHighlightSignUp(false),
                ],
                [() => navigate('/register')]
              )
            }
          >
            {Auth.dontHaveAnAccount}
          </button>
          <button
            className={`flex-center color-gray fs18 fwsb highlight-it-theme ${
              tapHighlightForgotPassword ? 'highlight-it-theme--tap' : ''
            }`}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightForgotPassword(true),
                  () => setTapHighlightForgotPassword(false),
                ],
                [() => navigate('/forgotpassword')]
              )
            }
          >
            {Auth.forgotPassword}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;

//language
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

const OrganizeViewsSummaryMobile = ({ view }) => {
  //language
  const { SettingsOrganizeViewsSummaryMobile } = useLanguageSettings();

  let viewContent;

  if (view === 'overview') {
    viewContent = (
      <p
        className="mrg-t24 flex-center full-width fs18 color-gray fwsb opacity-06 access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsOrganizeViewsSummaryMobile.manage}
      >
        {SettingsOrganizeViewsSummaryMobile.manage}
      </p>
    );
  } else if (view === 'create') {
    viewContent = (
      <p
        className="mrg-t24 flex-center full-width fs18 color-gray fwsb opacity-06 access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsOrganizeViewsSummaryMobile.create}
      >
        {SettingsOrganizeViewsSummaryMobile.create}
      </p>
    );
  } else if (view === 'speakers') {
    viewContent = (
      <p
        className="mrg-t24 flex-center full-width fs18 color-gray fwsb opacity-06 access-ob access-o6"
        tabIndex="0"
        aria-label={SettingsOrganizeViewsSummaryMobile.findSpeakers}
      >
        {SettingsOrganizeViewsSummaryMobile.findSpeakers}
      </p>
    );
  }

  return viewContent;
};

export default OrganizeViewsSummaryMobile;

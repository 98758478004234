//language
import useLanguageData from '../../../language/useLanguageData';

//components
import TagsContent from '../../TagsContent/TagsContent';

//utils
import { textUI } from '../../../utils/UI/textUI';

const ProfileSpeakerInfo = ({ ProfilePersonal, profile, previewMode }) => {
  //language
  const { CredentialOpts, LanguageOpts } = useLanguageData();

  return (
    <>
      <div
        className={`mrg-b24 ${
          previewMode ? 'account-previewMode__outline' : ''
        }`}
      >
        {previewMode && (
          <div className="account-previewMode--outline-header">
            <p>{ProfilePersonal.viewableByOrganizers}</p>
          </div>
        )}
        {/* 
        <label
          className="content-heading-styled text-gray mrg-b12"
          tabIndex="0"
          id="speakerLanguagesLabel"
        >
          {ProfilePersonal.speakerLanguages}
        </label>
        <div className="h12" />

        <ul className="account-profile-lang-list access-ob" tabIndex="0">
          {profile?.speaker?.speakerLanguages?.map((lang, index) => {
            if (lang !== 'notListed') {
              return (
                <li key={`speaker-lang-${index}`}>
                  <p>{LanguageOpts[lang]}</p>
                </li>
              );
            }
          })}
          {profile?.speaker?.speakerLanguagesNotListed?.length > 0 &&
            profile?.speaker?.speakerLanguagesNotListed?.map(
              (langCustom, index) => {
                return (
                  <li
                    key={`speaker-lang-custom-${index}`}
                    title={ProfilePersonal.userCreated}
                  >
                    <p>{langCustom?.language}</p>
                  </li>
                );
              }
            )}
        </ul>
        <div className="h36" /> */}

        {profile?.speaker?.credentials?.artisticActivities?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="artisticActivitiesLabel"
            >
              {CredentialOpts['artisticActivities']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.artisticActivities)}
          </div>
        )}

        {profile?.speaker?.credentials?.certificatesLicenses?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="certificatesLicensesLabel"
            >
              {CredentialOpts['certificatesLicenses']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.certificatesLicenses)}
          </div>
        )}

        {profile?.speaker?.credentials?.education?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="educationLabel"
            >
              {CredentialOpts['education']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.education)}
          </div>
        )}

        {profile?.speaker?.credentials?.employment?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="employmentLabel"
            >
              {CredentialOpts['employment']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.employment)}
          </div>
        )}

        {profile?.speaker?.credentials?.honorsAwards?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="honorsAwardsLabel"
            >
              {CredentialOpts['honorsAwards']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.honorsAwards)}
          </div>
        )}

        {profile?.speaker?.credentials?.jobExperience?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="jobExperienceLabel"
            >
              {CredentialOpts['jobExperience']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.jobExperience)}
          </div>
        )}

        {profile?.speaker?.credentials?.patentDesigns?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="patentDesignsLabel"
            >
              {CredentialOpts['patentDesigns']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.patentDesigns)}
          </div>
        )}

        {profile?.speaker?.credentials?.presentations?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="presentationsLabel"
            >
              {CredentialOpts['presentations']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.presentations)}
          </div>
        )}

        {profile?.speaker?.credentials?.projects?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="projectsLabel"
            >
              {CredentialOpts['projects']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.projects)}
          </div>
        )}

        {profile?.speaker?.credentials?.publications?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="publicationsLabel"
            >
              {CredentialOpts['publications']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.publications)}
          </div>
        )}

        {profile?.speaker?.credentials?.skillsExpertise?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="skillsExpertiseLabel"
            >
              {CredentialOpts['skillsExpertise']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.skillsExpertise)}
          </div>
        )}

        {profile?.speaker?.credentials?.volunteerExperience?.length > 0 && (
          <div className="credential">
            <label
              className="content-heading-styled text-gray"
              tabIndex="0"
              id="volunteerExperienceLabel"
            >
              {CredentialOpts['volunteerExperience']}
            </label>
            <div className="h12" />
            {textUI(profile?.speaker?.credentials?.volunteerExperience)}
          </div>
        )}
        <TagsContent
          bigTagsContent={profile?.speaker?.tags?.speakerBigTags}
          tinyTagsContent={profile?.speaker?.tags?.speakerTinyTags}
          customWrapper={'mrg-tb12'}
        />
      </div>
    </>
  );
};

export default ProfileSpeakerInfo;

export const translationTextBoxLanguage = (languageSelection) => {
  let translation;

  if (languageSelection === 'en-CA') {
    translation = {
      roomCode: 'Room Code',
      password: 'Password',
    };
  }

  if (languageSelection === 'fr-CA') {
    translation = {
      roomCode: 'Code de la salle',
      password: 'Mot de passe',
    };
  }

  if (languageSelection === 'es') {
    translation = {
      roomCode: 'Código de habitación',
      password: 'Contraseña',
    };
  }

  if (languageSelection === 'it') {
    translation = {
      roomCode: 'Codice della stanza',
      password: 'Password',
    };
  }

  return translation;
};

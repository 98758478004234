//slice
import { apiSlice } from '../../../app/api/apiSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';
import { settingsSetFindSpeakersHasMore } from '../settings/settingsSlice';
import {
  setMyOrganizedEvents,
  setMyScheduledOrganizedEvents,
  setMyArchivedOrganizedEvents,
} from './organizeSlice';

export const organizedEventsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    myOrganizedEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/event/myEvents',
          params: { accountId },
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      transformResponse: (response) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setMyOrganizedEvents(data));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, myOrganizedEvents error:',
              error
            );
          }
        }
      },
      providesTags: ['OrganizerEvent'],
    }),
    myOrganizedScheduledEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/event/myScheduledEvents',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          dispatch(setMyScheduledOrganizedEvents(data));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, myOrganizedScheduledEvents error:',
              error
            );
          }
        }
      },
      providesTags: ['OrganizerEvent'],
    }),
    myOrganizedArchivedEvents: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/event/myArchivedEvents',
          params: { accountId },
        };
      },
      transformResponse: (response) => {
        return response.data;
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }

          dispatch(setMyArchivedOrganizedEvents(data));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, myOrganizedArchivedEvents error:',
              error
            );
          }
        }
      },
      providesTags: ['OrganizerEvent'],
    }),
    createEvent: builder.mutation({
      query: ({ body }) => {
        return {
          url: '/api/organize/event/create',
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, createEvent error:',
              error
            );
          }
          return error;
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    updateEvent: builder.mutation({
      query: ({ body }) => ({
        url: `/api/organize/event/update`,
        method: 'PATCH',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, updateEvent error:',
              error
            );
          }
          return error;
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    removeEventActiveUpdateAccounts: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: `/api/organize/event/remove/active`,
        method: 'PATCH',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventActiveUpdateAccounts',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeEventScheduledUpdateAccounts: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: `/api/organize/event/remove/scheduled`,
        method: 'PATCH',
        body: { accountId, scheduledEventId: eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventUpdateAccounts',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
    }),
    removeEventArchivedUpdateAccounts: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: `/api/organize/event/remove/archived`,
        method: 'PATCH',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventUpdateAccounts',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent'],
    }),
    removeEventActive: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: '/api/organize/event/remove/active',
        method: 'DELETE',
        body: { accountId, eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEventActive',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    removeEventScheduled: builder.mutation({
      query: ({ accountId, eventId }) => ({
        url: '/api/organize/event/remove/scheduled',
        method: 'DELETE',
        body: { accountId, scheduledEventId: eventId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeEvent',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['OrganizerEvent', 'Event'],
    }),
    acceptPendingApprovalInPerson: builder.mutation({
      query: ({ accountId, eventId, pendingApprovalInPersonId }) => ({
        url: '/api/organize/acceptPendingApprovalInPerson',
        method: 'PATCH',
        body: { accountId, eventId, pendingApprovalInPersonId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/acceptPendingApprovalInPerson',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    removeRegistrationInPerson: builder.mutation({
      query: ({ accountId, eventId, registeredAttendeeInPersonId }) => ({
        url: '/api/organize/removeRegistrationInPerson',
        method: 'PATCH',
        body: { accountId, eventId, registeredAttendeeInPersonId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeRegistrationInPerson',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    acceptPendingApprovalInPersonAll: builder.mutation({
      query: ({ accountId, eventId, pendingApprovalInPersonAll }) => ({
        url: '/api/organize/acceptPendingApprovalInPersonAll',
        method: 'PATCH',
        body: { accountId, eventId, pendingApprovalInPersonAll },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/acceptPendingApprovalInPersonAll',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    acceptPendingApprovalVirtual: builder.mutation({
      query: ({ accountId, eventId, pendingApprovalVirtualId }) => ({
        url: '/api/organize/acceptPendingApprovalVirtual',
        method: 'PATCH',
        body: { accountId, eventId, pendingApprovalVirtualId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/acceptPendingApprovalVirtual',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    removeRegistrationVirtual: builder.mutation({
      query: ({ accountId, eventId, registeredAttendeeVirtualId }) => ({
        url: '/api/organize/removeRegistrationVirtual',
        method: 'PATCH',
        body: { accountId, eventId, registeredAttendeeVirtualId },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'OrganizedEventApiSlice/removeRegistrationVirtual',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      invalidatesTags: ['Event'],
    }),
    findSpeakers: builder.query({
      query: (body) => {
        return {
          url: '/api/organize/findSpeakers',
          method: 'POST',
          body,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          const { hasMore } = data;
          dispatch(settingsSetFindSpeakersHasMore(hasMore));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, findSpeakers error:',
              error
            );
          }
        }
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const endpoint = `findSpeakers-${queryArgs?.reduxEndpoint}`;
        return endpoint;
      },
      merge: (currentCache, newItems) => {
        currentCache.data.push(...newItems.data);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg.queryPage !== previousArg.queryPage;
      },
    }),
    getContactedSpeakers: builder.query({
      query: (accountId) => {
        return {
          url: '/api/organize/findSpeakers/contactedSpeakers',
          method: 'GET',
          params: accountId,
        };
      },
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
          //data is populated, dont set in account slice and overwrite ids, handle data directly.
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'DEV organizeEventsApiSlice, getContactedSpeakers error:',
              error
            );
          }
        }
      },
    }),
    encryptPasswordForLocalDevice: builder.mutation({
      query: (body) => ({
        url: `/api/organize/encryptPasswordForLocalDevice`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'Dev organizeApiSlice/encryptPasswordForLocalDevice error,',
              error
            );
          }
        }
      },
    }),
    decryptPasswordForLocalDevice: builder.mutation({
      query: (body) => ({
        url: `/api/organize/decryptPasswordForLocalDevice`,
        method: 'POST',
        body,
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.error) {
            throw data.error;
          }
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log(
              'Dev organizeApiSlice/decryptPasswordForLocalDevice error,',
              error
            );
          }
        }
      },
    }),
  }),
});

export const {
  useMyOrganizedEventsQuery,
  useLazyMyOrganizedEventsQuery,
  useMyOrganizedScheduledEventsQuery,
  useLazyMyOrganizedScheduledEventsQuery,
  useMyOrganizedArchivedEventsQuery,
  useLazyMyOrganizedArchivedEventsQuery,
  useAcceptPendingApprovalInPersonMutation,

  useRemoveRegistrationInPersonMutation,
  useAcceptPendingApprovalInPersonAllMutation,
  useCreateEventMutation,
  useUpdateEventMutation,

  useRemoveEventActiveMutation,
  useRemoveEventScheduledMutation,
  useAcceptPendingApprovalVirtualMutation,
  useRemoveRegistrationVirtualMutation,
  useRemoveArchivedEventOrganizerMutation,

  useRemoveEventActiveUpdateAccountsMutation,
  useRemoveEventScheduledUpdateAccountsMutation,
  useRemoveEventArchivedUpdateAccountsMutation,

  //
  useEncryptPasswordForLocalDeviceMutation,
  useDecryptPasswordForLocalDeviceMutation,

  //find speakers
  useLazyFindSpeakersQuery,
  useLazyGetContactedSpeakersQuery,
} = organizedEventsApiSlice;

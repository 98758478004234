import { useState } from 'react';

//hooks
import { useApiStatus } from '../../../context/ApiStatusProvider.js';
import { useTranslationRoomSocket } from '../../../context/sockets/main/TranslationRoomSocketProvider.js';

//language
import useLanguageTranslationRoom from '../../../language/features/useLanguageTranslationRoom.js';

//Utility
import { fr_voiceBob } from './audio/fr_voiceBob.js';
import { fr_voiceJudy } from './audio/fr_voiceJudy.js';
import { en_voiceBob } from './audio/en_voiceBob.js';
import { en_voiceJudy } from './audio/en_voiceJudy.js';
import { es_voiceBob } from './audio/es_voiceBob.js';
import { es_voiceJudy } from './audio/es_voiceJudy.js';
import { axiosLimited } from '../../../axios/axios.js';
import useTimezoneData from '../../../language/useTimezoneData.js';
import useLanguageData from '../../../language/useLanguageData.js';
import {
  formatDateFromCreatedAt,
  useDateTimeCalculations,
} from '../../../utils/dateTime/DateCalc.js';
import { extractCharactersFromString } from '../../../utils/misc/extractCharactertsFromString.js';

export const useTranslationsHandlers = () => {
  //Hooks
  const { handleWarning, handleSuccess, handleErrorClientErrorLogOnly } =
    useApiStatus();
  const {
    roomCodeId,
    roomCode,
    savedTranslationsRoomData,
    setSavedTranslationsRoomData,
    translationsTranscripts,
    setTranslationsTranscripts,
  } = useTranslationRoomSocket();
  const { getFormatTimeFromTimestamp } = useDateTimeCalculations();

  //language
  const { TranslationTranscripts } = useLanguageTranslationRoom();
  const { TimeZonesAbbr } = useTimezoneData();
  const { TranslationLanguagesSingular, VoiceOpts } = useLanguageData();

  //state
  const [translationsLogoutIsLoading, setTranslationsLogoutIsLoading] =
    useState(false);

  function handleTestPlayBtn({
    speechLang,
    voiceOption,
    testAudioIsPlaying,
    setTestAudioIsPlaying,
  }) {
    if (!testAudioIsPlaying) {
      setTestAudioIsPlaying(true);

      if (speechLang === 'en-CA' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${en_voiceBob}`;
        audio.play();
      }

      if (speechLang === 'en-CA' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${en_voiceJudy}`;
        audio.play();
      }

      if (speechLang === 'fr-CA' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${fr_voiceBob}`;
        audio.play();
      }

      if (speechLang === 'fr-CA' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${fr_voiceJudy}`;
        audio.play();
      }
      if (speechLang === 'es' && voiceOption === 'voiceBob') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${es_voiceBob}`;
        audio.play();
      }
      if (speechLang === 'es' && voiceOption === 'voiceJudy') {
        const audio = new Audio();
        audio.src = `data:audio/mp3;base64,${es_voiceJudy}`;
        audio.play();
      }
    }
  }

  async function handleSaveTranslationsDraft({
    saveName,
    participants,
    translationsJoinRoomPasswordToggle,
    attendeePassword,
    roomLanguages,
    isLoadedDraft,
    loadedDraftId,
  }) {
    try {
      if (!saveName || !roomCodeId) {
        return handleWarning({
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleSaveTranslationsDraft',
          message: 'incompleteInformation',
        });
      }

      const formData = new FormData();

      let formattedParticipants = [];

      if (participants?.length > 0) {
        formattedParticipants = JSON.stringify(participants);
      }

      formData.append('participants', formattedParticipants);
      formData.append('saveName', saveName.trim());
      formData.append(
        'joinRoomPasswordToggle',
        translationsJoinRoomPasswordToggle
      );

      if (attendeePassword?.length > 0) {
        formData.append('attendeePassword', attendeePassword);
      }

      formData.append('roomLanguages', roomLanguages || []);
      formData.append('roomCode', roomCode);
      formData.append('roomCodeId', roomCodeId);

      if (isLoadedDraft) {
        formData.append('_id', loadedDraftId);
      }

      let response;

      if (!isLoadedDraft) {
        response = await axiosLimited.post(
          '/api/public/translations/translationsSessionDraft',
          formData
        );
      } else {
        response = await axiosLimited.patch(
          '/api/public/translations/translationsSessionDraft',
          formData
        );
      }
      if (response?.error) {
        throw new Error(response?.error);
      }

      if (response?.data?.status === 'success') {
        let newTranslationsSession = response.data.translationsSession;

        //handle send email invitations
        for (const participant of participants || []) {
          if (
            participant.organizedAs === 'emailInvitation' &&
            participant?.dispatches?.translationsDispatched === false &&
            participant?.dispatches?.translationsStatus === 'dispatching' &&
            participant.emailInvitation.emailValid &&
            !participant.emailInvitation.duplicateEmail
          ) {
            let emailInvitationResponse = await axiosLimited.post(
              '/api/public/translations/draftEmailInvitations',
              {
                translationsSession: newTranslationsSession,
                roomCodeId,
                roomCode,
              }
            );

            if (emailInvitationResponse?.data?.status === 'success') {
              newTranslationsSession =
                emailInvitationResponse.data.updatedTranslationsSession;
            }
          }
        }

        //add the updated data to teh savedTranslationsRoomData for loading drafts/sessions
        let updatedTranslationsRoomData = JSON.parse(
          JSON.stringify(savedTranslationsRoomData)
        );

        if (isLoadedDraft) {
          updatedTranslationsRoomData.translationsSessions =
            updatedTranslationsRoomData.translationsSessions?.map(
              (sessionData) => {
                if (
                  sessionData._id.toString() ===
                  newTranslationsSession._id.toString()
                ) {
                  return newTranslationsSession;
                } else {
                  return sessionData;
                }
              }
            );
        } else {
          updatedTranslationsRoomData.translationsSessions = [
            ...updatedTranslationsRoomData.translationsSessions,
            newTranslationsSession,
          ];
        }

        setSavedTranslationsRoomData(updatedTranslationsRoomData);

        handleSuccess({
          message: 'draftSaved',
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleSaveTranslationsDraft',
        });
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleRemoveTranslationsDrafts({ removeDraftIds }) {
    try {
      if (removeDraftIds?.length === 0) {
        return handleWarning({
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleRemoveTranslationsDrafts',
          message: 'selectionRequired',
        });
      }

      const response = await axiosLimited.post(
        `/api/public/translations/removeTranslationsSessionDrafts`,
        {
          removeDraftIds,
          roomCodeId,
          roomCode,
        }
      );

      if (response?.data?.status === 'success') {
        //remove from context state.
        let updatedTranslationsRoomData = JSON.parse(
          JSON.stringify(savedTranslationsRoomData)
        );

        updatedTranslationsRoomData.translationsSessions =
          updatedTranslationsRoomData.translationsSessions?.filter(
            (sessionData) =>
              !removeDraftIds.includes(sessionData._id.toString())
          );

        setSavedTranslationsRoomData(updatedTranslationsRoomData);

        handleSuccess({
          message: 'draftsRemoved',
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleRemoveTranslationsDraft',
        });
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleTranslationsLogout(fromLocation) {
    if (process.env.REACT_APP_ENV === 'development') {
      console.log('DEV: fromLocation', fromLocation);
    }

    if (!translationsLogoutIsLoading) {
      setTranslationsLogoutIsLoading(true);
      try {
        await axiosLimited.get('/api/public/translations/logout');
      } catch (error) {
        await handleErrorClientErrorLogOnly({
          message: 'error',
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleTranslationsLogout',
          createClientLog: error,
        });
      }
      setTranslationsLogoutIsLoading(false);
    }
  }

  function checkValidParticipantLanguageSelection(
    newParticipant,
    roomLanguages
  ) {
    let hasConflict = false;
    if (
      !newParticipant.translationsSpeakerData.languageOpt ||
      newParticipant.translationsSpeakerData.languageOpt === 'select'
    ) {
      return hasConflict;
    }
    if (
      newParticipant.translationsSpeakerData.languageOpt &&
      newParticipant.translationsSpeakerData.languageOpt !== 'select' &&
      !roomLanguages?.includes(
        newParticipant.translationsSpeakerData.languageOpt
      )
    ) {
      hasConflict = true;
    }
    return hasConflict;
  }

  async function handleRemoveTranscripts({ removeTranscriptIds }) {
    try {
      if (removeTranscriptIds?.length === 0) {
        return handleWarning({
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleRemoveTranscripts',
          message: 'selectionRequired',
        });
      }

      const response = await axiosLimited.post(
        `/api/public/translations/removeTranscripts`,
        {
          removeTranscriptIds,
          roomCode,
          roomCodeId,
        }
      );

      if (response?.data?.status === 'success') {
        //remove from context state.
        let updatedTranslationTranscripts = JSON.parse(
          JSON.stringify(translationsTranscripts)
        );

        updatedTranslationTranscripts = updatedTranslationTranscripts?.filter(
          (transcript) =>
            !removeTranscriptIds.includes(transcript._id.toString())
        );

        setTranslationsTranscripts(updatedTranslationTranscripts);

        handleSuccess({
          message: 'transcriptsRemoved',
          id: Date.now(),
          origin: 'useTranslationsHandlers/handleRemoveTranscripts',
        });
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleDownloadTranscriptForHost({
    downloadOption,
    transcriptData,
    contentLanguage,
    hostTimeZone,
    sessionToken,
  }) {
    const title = `${TranslationTranscripts.myndfullTranscript}\n`;
    const extractedId = extractCharactersFromString({
      input: sessionToken,
      charCount: 5,
      toUpperCase: true,
    });
    const transcriptID = `${TranslationTranscripts.sessionId}: ${extractedId}\n\n`;

    let saveName;

    if (transcriptData?.meta?.saveName?.length > 0) {
      saveName = `\n${TranslationTranscripts.saveName}: ${transcriptData.meta.saveName}\n\n`;
    }

    const transcriptCreated = `${
      TranslationTranscripts.transcriptCreated
    }: ${formatDateFromCreatedAt(
      transcriptData.meta.createdAt,
      contentLanguage,
      hostTimeZone
    )}  (${TimeZonesAbbr[hostTimeZone]})\n`;

    let lastTranscriptUpdate;

    if (transcriptData?.meta?.lastUpdated) {
      lastTranscriptUpdate = `${
        TranslationTranscripts.lastTranscriptUpdate
      }: ${formatDateFromCreatedAt(
        transcriptData?.meta?.lastUpdated,
        contentLanguage,
        hostTimeZone
      )} (${TimeZonesAbbr[hostTimeZone]})\n`;
    }

    const totalTranscriptLines = `${TranslationTranscripts.totalTranscriptLines}: ${transcriptData?.transcript?.length}\n\n`;

    let participantData = [];
    let participantText = '';

    transcriptData?.speakersData?.forEach((participant, participantIndex) => {
      let participantEntry = `${participant.name}\n${
        TranslationTranscripts.language
      }: ${TranslationLanguagesSingular[participant.languageOpt]}\n${
        TranslationTranscripts.discussionContext
      }: ${participant.discussionContext}\n${
        TranslationTranscripts.keywordsList
      }: ${participant.keywordsList?.map((keyword, keyworkIndex) => {
        if (keyworkIndex === 0) {
          return `${keyword}`;
        } else if (keyworkIndex === participant.keywordsList?.length - 1) {
          return ` ${keyword}.`;
        } else {
          return ` ${keyword}`;
        }
      })}\n`;

      if (downloadOption === 'full') {
        let updateSummaries = [];

        transcriptData?.speakerUpdates?.map(
          (speakerUpdateData, speakerUpdateIndex) => {
            if (speakerUpdateData.id === participant.id) {
              let speakerComparison;

              if (
                speakerUpdateIndex ===
                transcriptData.speakerUpdates?.length - 1
              ) {
                speakerComparison = participant;
              } else if (transcriptData.speakerUpdates?.length > 0) {
                speakerComparison =
                  transcriptData.speakerUpdates[speakerUpdateIndex + 1];
              }

              if (speakerComparison) {
                let updateEntry = [];
                let updateText;
                //
                let createdAt;
                let languageChanged;
                let voiceChanged;
                let removedKeywordsChanged;
                let addedKeywordsChanged;
                let discussionContextChanged;

                createdAt = `${formatDateFromCreatedAt(
                  speakerUpdateData.updatedAt,
                  contentLanguage,
                  hostTimeZone
                )}(${TimeZonesAbbr[hostTimeZone]})`;

                updateEntry.push(createdAt);

                if (
                  speakerUpdateData.languageOpt !==
                  speakerComparison.languageOpt
                ) {
                  languageChanged = `${TranslationTranscripts.changedFrom} ${
                    TranslationLanguagesSingular[speakerUpdateData.languageOpt]
                  }\n`;
                  updateEntry.push(languageChanged);
                }

                if (speakerUpdateData.voiceOpt !== speakerComparison.voiceOpt) {
                  voiceChanged = `${TranslationTranscripts.changedFrom} ${
                    VoiceOpts[speakerUpdateData.voiceOpt]
                  }\n`;
                  updateEntry.push(voiceChanged);
                }

                if (
                  JSON.stringify(speakerUpdateData.keywordsList) !==
                  JSON.stringify(speakerComparison.keywordsList)
                ) {
                  let addedKeywords = [];
                  let removedKeywords = [];

                  speakerUpdateData.keywordsList?.filter((kw) => {
                    if (!speakerComparison.keywordsList.includes(kw)) {
                      removedKeywords.push(kw);
                    }
                  });

                  speakerComparison.keywordsList?.filter((kw) => {
                    if (!speakerUpdateData.keywordsList.includes(kw)) {
                      addedKeywords.push(kw);
                    }
                  });

                  if (removedKeywords?.length > 0) {
                    removedKeywordsChanged = `${
                      TranslationTranscripts.removedKeywords
                    }:  ${removedKeywords?.map((v, removedIndex) => {
                      if (removedIndex === 0) {
                        return `${v}`;
                      } else if (removedIndex === removedKeywords?.length - 1) {
                        return ` ${v}.`;
                      } else {
                        return ` ${v}`;
                      }
                    })}`;

                    updateEntry.push(`${removedKeywordsChanged}\n`);
                  }

                  if (addedKeywords?.length > 0) {
                    addedKeywordsChanged = `${
                      TranslationTranscripts.addedKeywords
                    }:  ${addedKeywords?.map((v, addedIndex) => {
                      if (addedIndex === 0) {
                        return `${v}`;
                      } else if (addedIndex === addedKeywords?.length - 1) {
                        return ` ${v}.`;
                      } else {
                        return ` ${v}`;
                      }
                    })}`;

                    updateEntry.push(`${addedKeywordsChanged}\n`);
                  }
                }

                if (
                  speakerUpdateData.discussionContext !==
                  speakerComparison.discussionContext
                ) {
                  discussionContextChanged = `${TranslationTranscripts.changedFromDiscussionContext}: ${speakerUpdateData.discussionContext}\n`;
                  updateEntry.push(discussionContextChanged);
                }

                updateText = updateEntry.join('\n');
                updateSummaries.push(updateText);
              }
            }
          }
        );

        if (updateSummaries?.length > 0) {
          let updateTitle = `${participant.name} ${TranslationTranscripts.inSessionUpdates}:\n`;
          let participantUpdateText = updateSummaries.join('\n');
          participantEntry = `${participantEntry}\n${updateTitle}\n${participantUpdateText}`;
        }
      }

      participantData.push(participantEntry);
    });

    participantText = participantData.join('\n');

    ////
    let transcriptText;

    if (downloadOption === 'basic') {
      let transcriptLinesBasic = [];
      transcriptLinesBasic.push(`\n${TranslationTranscripts.transcript}\n`);

      transcriptData?.transcript?.forEach((t, index) => {
        let speakerName;

        if (index === 0) {
          transcriptData?.speakersData?.map((speaker, index) => {
            if (speaker.id === t.currentRoomSpeakerId) {
              speakerName = `${TranslationTranscripts.participant}: ${speaker.name}\n\n`;
            }
          });
        } else {
          const prevT = transcriptData?.transcript[index - 1];
          if (prevT.currentRoomSpeakerId !== t.currentRoomSpeakerId) {
            for (const speaker of transcriptData?.speakersData || []) {
              if (speaker?.id === t?.currentRoomSpeakerId) {
                speakerName = `${TranslationTranscripts.participant}: ${speaker.name}\n\n`;
              }
            }
          }
        }

        const timeStamp = getFormatTimeFromTimestamp(
          t.timeStamp,
          contentLanguage,
          hostTimeZone
        );

        const formattedLine = `${speakerName ? speakerName : ''}${timeStamp}: ${
          t.originalText
        }\n`;
        transcriptLinesBasic.push(formattedLine);
      });

      transcriptText = transcriptLinesBasic.join('\n');
    }

    if (downloadOption === 'full') {
      let transcriptLinesFull = [];
      transcriptLinesFull.push(`\n${TranslationTranscripts.transcript}\n`);

      transcriptData?.transcript?.map((t, index) => {
        //display current speaker update event
        let speakerUpdateTimes = [];
        let displayUpdateEvent;
        let speakerName;

        if (index === 0) {
          transcriptData?.speakersData?.map((speaker, index) => {
            if (speaker.id === t.currentRoomSpeakerId) {
              speakerName = `${TranslationTranscripts.participant}: ${speaker.name}\n\n`;
            }
          });
        } else {
          const prevT = transcriptData?.transcript[index - 1];
          if (prevT.currentRoomSpeakerId !== t.currentRoomSpeakerId) {
            for (const speaker of transcriptData?.speakersData || []) {
              if (speaker?.id === t?.currentRoomSpeakerId) {
                speakerName = `${TranslationTranscripts.participant}: ${speaker.name}\n\n`;
              }
            }
          }
        }

        if (transcriptData?.speakerUpdates?.length > 0) {
          transcriptData?.speakerUpdates?.map((speakerUpdate) => {
            if (speakerUpdate.id === t.currentRoomSpeakerId) {
              let updateTimestamp = new Date(speakerUpdate.updatedAt);
              updateTimestamp = updateTimestamp.getTime();
              return speakerUpdateTimes.push(updateTimestamp);
            } else {
              return null;
            }
          });
        }

        let prevTimeStamp = transcriptData?.transcript[index - 1];

        if (prevTimeStamp?.timeStamp && speakerUpdateTimes?.length > 0) {
          for (const speakerUpdateTime of speakerUpdateTimes || []) {
            if (
              speakerUpdateTime > prevTimeStamp?.timeStamp &&
              speakerUpdateTime < t?.timeStamp
            ) {
              let updateName;
              transcriptData?.speakersData?.map((speaker) => {
                if (speaker.id === t.currentRoomSpeakerId) {
                  updateName = speaker.name;
                }
              });
              displayUpdateEvent = `(${updateName} ${TranslationTranscripts.participantInSessionUpdate})\n\n\n`;
            }
          }
        }

        const timeStamp = getFormatTimeFromTimestamp(
          t.timeStamp,
          contentLanguage,
          hostTimeZone
        );

        /////////
        const textLine = [];
        let textLinesCombined;

        Object.entries(t?.translationObj)?.map(([key, value], subIndex) => {
          const newTextLine = `${TranslationLanguagesSingular[key]}: ${value}\n`;
          return textLine.push(newTextLine);
        });

        textLinesCombined = textLine.join('\n');

        /////////
        const formattedLine = `${displayUpdateEvent ? displayUpdateEvent : ''}${
          speakerName ? speakerName : ''
        }${timeStamp}:\n${textLinesCombined}\n`;
        transcriptLinesFull.push(formattedLine);
      });

      transcriptText = transcriptLinesFull.join('\n');
    }

    let blob;

    if (lastTranscriptUpdate) {
      blob = new Blob(
        [
          title,
          saveName,
          transcriptID,
          transcriptCreated,
          lastTranscriptUpdate,
          totalTranscriptLines,
          participantText,
          transcriptText,
        ],
        {
          type: 'text/plain',
        }
      );
    } else {
      blob = new Blob(
        [
          title,
          saveName,
          transcriptID,
          transcriptCreated,
          totalTranscriptLines,
          participantText,
          transcriptText,
        ],
        {
          type: 'text/plain',
        }
      );
    }

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${roomCode}-${transcriptID}.txt`;
    // Append the link to the body (required for Firefox)
    document.body.appendChild(a);
    a.click(); // Trigger the download

    // Clean up
    document.body.removeChild(a);
    return URL.revokeObjectURL(url);
  }

  async function handleTranslationsRestoreSession({
    roomCode,
    sessionToken,
    setAttendeeJoinRoomPassword,
  }) {
    try {
      const response = await axiosLimited.post(
        `/api/public/translations/restoreSession`,
        {
          roomCode,
          sessionToken,
        }
      );

      if (response?.data?.status === 'success') {
        return setAttendeeJoinRoomPassword(response.data.password);
      }
    } catch (error) {}
  }

  function handleTranslationsParticipantValidation(p) {
    if (p.organizedAs === 'emailInvitation') {
      if (
        p.emailInvitation.emailValid === true &&
        p.emailInvitation.emailLanguage !== 'select' &&
        p.emailInvitation.emailLanguage &&
        !p.emailInvitation.duplicateEmail
      ) {
        return true;
      } else {
        return false;
      }
    }

    if (p.organizedAs === 'create') {
      if (p.translationsSpeakerData.name?.length === 0) {
        return false;
      }
      if (
        p.translationsSpeakerData.languageOpt === 'select' ||
        !p.translationsSpeakerData.languageOpt
      ) {
        return false;
      }
      if (
        p.translationsSpeakerData.voiceOpt === 'select' ||
        !p.translationsSpeakerData.voiceOpt
      ) {
        return false;
      }

      if (p.translationsSpeakerData.discussionContext?.length < 25) {
        return false;
      }
      return true;
    }
  }

  return {
    handleTestPlayBtn,
    handleSaveTranslationsDraft,
    handleTranslationsLogout,
    translationsLogoutIsLoading,
    setTranslationsLogoutIsLoading,
    handleRemoveTranslationsDrafts,
    handleTranslationsRestoreSession,
    //
    handleRemoveTranscripts,
    handleDownloadTranscriptForHost,
    //
    checkValidParticipantLanguageSelection,
    handleTranslationsParticipantValidation,
  };
};

import { useState, useEffect } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../../language/useLanguageData';

//Components
import SaveButton from '../../../../../../components/SaveButton/SaveButton';
import SelectionsModal from '../../../../../../components/Selections/SelectionsModal';

//Utility
import { bigtag_nobg, checkmark_green } from '../../../../../../assets/icons';
import { areArraysEqualIgnoreOrder } from '../../../../../../utils/logic/arrayEqualIgnoreOrder';

const BigTags = ({ Account, saveAccountChange, loadingSaveBtnUI }) => {
  //Hooks
  const {
    accountBigTags: loadBigTags,
    accountAttendeeTags,
    accountAttendee,
  } = useAccountReduxHandlers();

  //language
  const { Generic, IconAlts } = useLanguageComponents();
  const { BigTagsOpts, BigTagsOptsWithSubText, BigTagIcon } = useLanguageData();

  //Component state
  const [bigTags, setBigTags] = useState(loadBigTags);
  const [bigTagsSaveValid, setBigTagsSaveValid] = useState(false);

  //Initialize functions
  useEffect(() => {
    if (loadBigTags) {
      const sortedBigTags = [...loadBigTags].sort((a, b) => {
        return a.localeCompare(b);
      });

      setBigTags(sortedBigTags.reverse());
    }
  }, [loadBigTags]);

  //Component functions
  useEffect(() => {
    const sameContents = areArraysEqualIgnoreOrder(bigTags, loadBigTags);

    if (!sameContents && bigTags?.length > 0) {
      setBigTagsSaveValid(true);
    } else {
      setBigTagsSaveValid(false);
    }
  }, [bigTags, loadBigTags]);

  return (
    <>
      <div className="section">
        <label
          className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
          tabIndex="0"
          id="bigTagsLabel"
        >
          <div className="flex-row align-center mrg-auto-right">
            <img
              src={BigTagIcon}
              alt={IconAlts.iconBigTag}
              style={{ height: '22px' }}
              className="mrg-r12"
            />
            <p>{Account.attendee.bigTags}</p>
          </div>

          <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
            <p className="fs14 mrg-auto-left">1 {Generic.required}</p>

            {bigTags?.length > 0 && (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>

        <div className="h12" />
        <p
          className="description-text"
          tabIndex="0"
          aria-label={Account.attendee.ariaBigTagsInputInstructions}
        >
          {Account.attendee.bigTagsDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={`${Generic.add} ${Generic.bigTags}`}
          iconModalClass={'bT'}
          DataOpts={BigTagsOptsWithSubText}
          DataOptsSubTextTranslations={BigTagsOpts}
          fullWidth={true}
          iconModal={bigtag_nobg}
          iconOff={true}
          limit={20}
          modalTitle={Generic.bigTags}
          selectionState={bigTags}
          setSelectionState={setBigTags}
          subTextOn={true}
          id={'bigTagSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />

        <div className="h24" />
        <SaveButton
          saveValid={bigTagsSaveValid}
          handler={() =>
            saveAccountChange(
              {
                attendee: {
                  ...accountAttendee,
                  tags: {
                    ...accountAttendeeTags,
                    bigTags,
                  },
                },
              },
              'bigTags'
            )
          }
          saveAriaLanguage={Account.attendee.bigTags}
          isLoading={loadingSaveBtnUI === 'bigTags'}
        />
        <div className="h36" />
      </div>
    </>
  );
};

export default BigTags;

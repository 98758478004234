import { useState, useEffect } from 'react';

//Hooks
import { useCreateEventReduxHandlers } from '../create/useCreateEventHandlers';
import { useEventValidation } from '../useOrganizeHandlers';
import useDelayedHandler from '../../../../hooks/useDelayedHandler';

//language
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import ItemButton from '../../../../components/ItemButton/ItemButton';
import CalendarSimple from '../../../../components/CalendarSimple/CalendarSimple';
import ButtonSwitch from '../../../../components/ButtonSwitch/ButtonSwitch';
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown';
import SelectDropdownTimezones from '../../../../components/SelectDropdown/SelectDropdownTimezones';
import ButtonTextSimple from '../../../../components/ButtonTextSimple/ButtonTextSimple';
import ValidationsList from '../../../../components/ValidationsList/ValidationsList';

//Utility
import {
  organize,
  preview,
  clock,
  img_checklist,
  trash,
} from '../../../../assets/icons/index';
import { timesData } from '../../../../data/timesData';

const Publish = ({
  editEventMode,
  initialize,
  setOrganizeIsLoading,
  setEventPreview,

  //state
  submitIsLoading,
  loadScheduleEnabled,
  loadSchedulePublishDate,
  loadSchedulePublishTime,
  loadSchedulePublishTimeZone,
  decryptedTranslationsPassword,
  decryptedVirtualPasscode,
  //handlers
  handleSetScheduleEnabled,
  handleSetSchedulePublishDate,
  handleSetSchedulePublishTime,
  handleSetSchedulePublishTimeZone,
  handleSubmitEvent,
}) => {
  //Hooks
  const { delayedHandler } = useDelayedHandler();
  const { handleEventValidation } = useEventValidation();
  const { scheduleEnabled, lastModified, createEventData } =
    useCreateEventReduxHandlers();

  //language
  const { CreateEvent, EditOverview } = useLanguageOrganize();

  //Component state
  const [validations, setValidations] = useState();
  const [verifiedSuccessful, setVerifiedSuccessful] = useState(false);
  const [verifiedTimestamp, setVerifiedTimestamp] = useState();

  //API functions
  async function initializeSubmitEvent(submissionType) {
    if (submissionType === 'schedulePublish') {
      if (scheduleEnabled) {
        initializePublish();
      }
    } else if (submissionType === 'publish') {
      initializePublish();
    }
  }

  //Component functions
  useEffect(() => {
    if (lastModified > verifiedTimestamp) {
      setVerifiedSuccessful(false);
    }
  }, [lastModified]); //if createEvent state in redux is updated, this will invalidate the users ability to publish event

  //Component functions
  function checkCompletion() {
    const validationsCheck = handleEventValidation({ saveType: 'create' });

    if (Array.isArray(validationsCheck)) {
      setValidations(validationsCheck);
      return setVerifiedSuccessful(false);
    } else {
      setVerifiedTimestamp(Date.now());
      setVerifiedSuccessful(true);
      setValidations();
    }
  }

  function initializePublish() {
    let validationsCheckArrOrEventData = handleEventValidation({
      saveType: 'create',
      decryptedTranslationsPassword,
    });

    if (Array.isArray(validationsCheckArrOrEventData)) {
      setValidations(validationsCheckArrOrEventData);
      setVerifiedSuccessful(false);
    } else {
      //create event
      let handleType;
      if (validationsCheckArrOrEventData?.schedule?.scheduleEnabled) {
        handleType = 'publishScheduled';
      } else {
        handleType = 'publish';
      }
      setOrganizeIsLoading(true);

      validationsCheckArrOrEventData = JSON.parse(
        JSON.stringify(validationsCheckArrOrEventData)
      );

      validationsCheckArrOrEventData.hosting.virtual.passcode =
        decryptedVirtualPasscode;
      validationsCheckArrOrEventData.translations.password =
        decryptedTranslationsPassword;

      handleSubmitEvent({
        eventData: validationsCheckArrOrEventData,
        handleType,
        exit: true,
      });
    }
  }

  function schedulePublishToggle() {
    handleSetScheduleEnabled(!loadScheduleEnabled);
  }

  return (
    <fieldset className="fieldset" id="fieldset-publish">
      <legend id="aria-fieldset-instructions-publish">
        {CreateEvent.publish.ariaFieldsetInstructions}
      </legend>
      <label
        className="label"
        id="publishLabel"
        tabIndex="0"
        aria-describedby="aria-fieldset-instructions-publish"
      >
        {CreateEvent.publish.publish}
      </label>
      {verifiedSuccessful ? (
        <p className="ready-status color-green" tabIndex="0">
          {CreateEvent.publish.complete}
        </p>
      ) : (
        <p className="ready-status text-red access-ob" tabIndex="0">
          {CreateEvent.publish.incomplete}
        </p>
      )}

      {!editEventMode && (
        <ButtonSwitch
          id={'scheduleEventPublication'}
          title={EditOverview.togglePublicationSchedule}
          handleSwitch={schedulePublishToggle}
          noWrap={true}
          loadTrigger={initialize}
          loadValue={loadScheduleEnabled}
          horizontal={true}
        />
      )}

      {loadScheduleEnabled && (
        <div className="ce-row-col--mob">
          <div className="flex-column ce-align-left--mob full-width">
            <div className="h24" />

            <div className="ce-col-row--mob full-width ce-space-evenly--mob">
              <CalendarSimple
                initialize={initialize}
                handleSetDate={handleSetSchedulePublishDate}
                loadDate={loadSchedulePublishDate}
              />

              <div className="flex-column ce-align-right--mob">
                <div className="ce-h24--mob" />
                <SelectDropdownTimezones
                  id={'publishTimezone'}
                  handleSelection={handleSetSchedulePublishTimeZone}
                  defaultValue={
                    initialize && loadSchedulePublishTimeZone
                      ? loadSchedulePublishTimeZone
                      : null
                  }
                />
                <div className="h24" />
                <SelectDropdown
                  id={'publishTime'}
                  list={timesData}
                  handleSelection={handleSetSchedulePublishTime}
                  defaultKey={
                    initialize && loadSchedulePublishTime
                      ? loadSchedulePublishTime
                      : null
                  }
                  internalLabel={EditOverview.publishTime}
                  asterisk={true}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex-column full-width flex-center">
        <ItemButton
          handler={() => setEventPreview(createEventData)}
          text={CreateEvent.publish.previewEvent}
          iconOn={true}
          icon={preview}
          fullWidth={true}
          customWrapper={'mrg-tb24 item-button-standard'}
          unavailable={submitIsLoading}
        />

        {!verifiedSuccessful && (
          <>
            <div className="ce-h24--mob" />
            <ItemButton
              handler={checkCompletion}
              text={CreateEvent.publish.checkCompletion}
              iconOn={true}
              icon={img_checklist}
              fullWidth={true}
              customWrapper={'item-button-standard'}
              unavailable={submitIsLoading}
            />
            <div className="ce-h24--mob" />
          </>
        )}

        {verifiedSuccessful && scheduleEnabled && (
          <ItemButton
            handler={() =>
              delayedHandler(initializeSubmitEvent, 'schedulePublish')
            }
            text={CreateEvent.publish.publishDraft}
            iconOn={true}
            icon={clock}
            fullWidth={true}
            customWrapper={'item-button-standard'}
          />
        )}

        {verifiedSuccessful && !scheduleEnabled && (
          <ItemButton
            handler={() => delayedHandler(initializeSubmitEvent, 'publish')}
            text={CreateEvent.publish.publishDraft}
            iconOn={true}
            icon={organize}
            fullWidth={true}
            customWrapper={'item-button-standard'}
          />
        )}
      </div>

      {validations?.length > 0 && (
        <>
          <ValidationsList
            customWrapper={'mrg-t24'}
            validations={validations}
          />
          <ButtonTextSimple
            handler={() => {
              setValidations([]);
              setVerifiedSuccessful(false);
            }}
            icon={trash}
            iconOn={true}
            iconClass={'filter-gray mrg-r12'}
            iconStyle={{ height: '18px' }}
            text={CreateEvent.publish.removeList}
            customWrapper={'mrg-t12 mrg-auto-lr text-gray'}
          />
        </>
      )}
    </fieldset>
  );
};

export default Publish;

import { useState, useEffect } from 'react';

//Hooks
import { useGenericModal } from '../../../context/GenericModalProvider';
import { useSettings } from '../../../context/SettingsProvider';

const ConfirmationModal = ({
  handler,
  headingText,
  button2Text,
  button1Text,
  focusId,
  button1Color,
  button2Color,
}) => {
  //Hooks
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap, width } = useSettings();

  //UI state
  const [tapHighlightButton, setTapHighlightBtn] = useState();

  //Initialize functions
  useEffect(() => {
    if (focusId) {
      setCloseModalFocusId(`${focusId}`);
    }
  }, []); //set id for focus when modal closes

  useEffect(() => {
    const floatModalEl = document.getElementById(`float-modal`);
    if (floatModalEl) {
      floatModalEl.focus();
    }
  }, []);

  return (
    <div
      className="float-modal access-ow access-o12"
      tabIndex="0"
      id="float-modal"
    >
      <p className="fs18 fwn pad-t12 pad-b24 text-center">{headingText}</p>

      <ul
        className="flex-row flex-column--ph list-style-none space-evenly mrg-b12 mrg-auto-lr"
        id="list__container"
      >
        <li className="">
          <button
            className={`confirmation-btn highlight-bthin-theme br--small access-ob access-o6
            ${width <= 480 ? 'mrg-b24' : ''} 
            ${
              button1Color === 'red'
                ? 'highlight-t-white highlight-bg-red--hover confirmation--neg'
                : ''
            } 
              ${
                button1Color === 'green'
                  ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                  : ''
              }
              ${
                !button1Color && tapHighlightButton === 'button1'
                  ? 'highlight-b-theme--tap'
                  : ''
              }
              ${
                button1Color === 'green' && tapHighlightButton === 'button1'
                  ? 'highlight-bg-green--tap'
                  : button1Color === 'green'
                  ? 'highlight-b-theme--tap'
                  : ''
              }
                ${
                  button1Color === 'red' && tapHighlightButton === 'button1'
                    ? 'highlight-bg-red--tap'
                    : button1Color === 'green'
                    ? 'highlight-b-theme--tap'
                    : ''
                }
            `}
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightBtn('button1'),
                  () => setTapHighlightBtn(),
                ],
                [() => handleCloseModal()]
              )
            }
          >
            {button1Text}
          </button>
        </li>
        <li>
          <button
            className={`confirmation-btn highlight-bthin-theme br--small access-ob access-o6 
            ${width <= 480 ? '' : 'mrg-l24'} 
            ${
              button2Color === 'red'
                ? 'highlight-t-white highlight-bg-red--hover confirmation--neg'
                : ''
            } 
              ${
                button2Color === 'green'
                  ? 'confirmation--pos highlight-t-white highlight-bg-green--hover'
                  : ''
              }
               ${
                 !button2Color && tapHighlightButton === 'button2'
                   ? 'highlight-b-theme--tap'
                   : ''
               }
               ${
                 button2Color === 'green' && tapHighlightButton === 'button2'
                   ? 'highlight-bg-green--tap'
                   : button2Color === 'green'
                   ? 'highlight-b-theme--tap'
                   : ''
               }
                ${
                  button2Color === 'red' && tapHighlightButton === 'button2'
                    ? 'highlight-bg-red--tap'
                    : button2Color === 'green'
                    ? 'highlight-b-theme--tap'
                    : ''
                }
            `}
            onClick={(e) =>
              handleMobileTap(
                [
                  () => setTapHighlightBtn('button2'),
                  () => setTapHighlightBtn(),
                ],
                [() => handler(), () => handleCloseModal()]
              )
            }
          >
            {button2Text}
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ConfirmationModal;

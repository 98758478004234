import { useState } from 'react';

//hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//language
import useLanguageTranslationRoom from '../../../../language/features/useLanguageTranslationRoom';

//components
import ItemButton from '../../../../components/ItemButton/ItemButton';

//utility
import { image_translations } from '../../../../assets/images';

const TranslationInitialize = ({ setPage }) => {
  //hooks
  const { accountId } = useAccountReduxHandlers();

  //language
  const { TranslationInitialize } = useLanguageTranslationRoom();

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div
      className={`translations__container translations__container--no-bg--ph onscreen-fade-in-500ms ${
        imgLoaded ? '' : 'remove'
      }`}
    >
      <img
        src={image_translations}
        alt="img"
        className="svg"
        onLoad={() => setImgLoaded(true)}
        style={{ maxHeight: '300px' }}
      />
      <div className="translations-selections mrg-t24">
        <ItemButton
          text={TranslationInitialize.join}
          handler={() => setPage(2)}
          customWrapper={'full-width mrg-b36 mrg-t12 fs18'}
        />
        <ItemButton
          text={TranslationInitialize.hostLogin}
          handler={() => setPage(3)}
          customWrapper={'full-width fs18'}
        />
        {/* <ItemButton
          text={TranslationInitialize.hostLogin}
          handler={() => setPage(3)}
          customWrapper={'full-width mrg-b36 fs18'}
        /> */}
        {accountId && (
          <ItemButton
            text={TranslationInitialize.tutorial}
            handler={() => setPage(4)}
            customWrapper={'full-width fs18 mrg-t36'}
          />
        )}
      </div>
    </div>
  );
};

export default TranslationInitialize;

import { createContext, useContext, useState, useRef } from 'react';
import io from 'socket.io-client';

//hooks
import { useAdminAccountReduxHandlers } from '../../../features/admin/account/useAdminAccountHandlers';
import useAdminTechnicalHandlers from '../../../features/admin/technical/useAdminTechnicalHandlers';

const TechnicalLogsSocketContext = createContext({});

export const TechnicalLogsSocketProvider = ({ children }) => {
  //Hooks
  const { handleSocketTechnicalLogData, handleSocketTechnicalLogArchiveData } =
    useAdminTechnicalHandlers();
  const { adminAccountId } = useAdminAccountReduxHandlers();

  //state
  const [technicalLogsSocketStatus, setTechnicalLogsSocketStatus] =
    useState('closed');
  const [
    technicalLogsArchiveSocketStatus,
    setTechnicalLogsArchiveSocketStatus,
  ] = useState('closed');

  //variables
  const logsOn = false;

  const socketTechnicalLogsRef = useRef(null);
  const socketTechnicalLogsArchiveRef = useRef(null);

  const ioTechnicalLogsURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/technicalLogs'
      : 'https://myndfull.com/ws/technicalLogs';

  const ioTechnicalLogsArchiveURL =
    process.env.REACT_APP_ENV === 'development'
      ? 'http://localhost:5000/ws/technicalLogsArchive'
      : 'https://myndfull.com/ws/technicalLogsArchive';

  const withCredentials =
    process.env.REACT_APP_ENV === 'development' ? false : true;

  const connectTechnicalLogsSocket = () => {
    if (socketTechnicalLogsRef.current) {
      socketTechnicalLogsRef.current.off();
      socketTechnicalLogsRef.current.disconnect();
      socketTechnicalLogsRef.current = null;
    }

    if (
      !socketTechnicalLogsRef.current &&
      technicalLogsSocketStatus !== 'connected'
    ) {
      setTechnicalLogsSocketStatus('initializing');
      let newSocket;

      newSocket = io(ioTechnicalLogsURL, {
        query: {
          adminAccountId,
        },
        reconnectionAttempts: 10,
        withCredentials: withCredentials,
      });

      newSocket.on('connect', () => {
        logsOn &&
          console.log(`Connected to TechnicalLogs Socket, id: ${newSocket.id}`);
        setTechnicalLogsSocketStatus('connected');
      });

      newSocket.on('technicalLogData', (technicalLogData) => {
        handleSocketTechnicalLogData(technicalLogData);
      });

      newSocket.on('disconnect', (reason, details) => {
        logsOn &&
          console.error(
            `Technical Logs socket disconnect, Reason: ${reason}, Details: ${details}`
          );
        setTechnicalLogsSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn &&
            console.error(
              'Technical Logs socket disconnect, no automatic reconnect'
            );
          closeTechnicalLogsSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn &&
            console.error(
              'Technical Logs socket disconnect, automatic reconnect'
            );
        } else {
          logsOn &&
            console.error(
              `Technical Logs socket disconnect, Unexpected disconnect reason: ${reason}`
            );
          closeTechnicalLogsSocket();
        }
      });

      newSocket.on('error', (error) => {
        logsOn && console.error('Technical Logs socket, socket error:', error);
        setTechnicalLogsSocketStatus('closed');
        closeTechnicalLogsSocket();
      });

      return (socketTechnicalLogsRef.current = newSocket);
    }
  };

  const getTechnicalLogsSocket = () => {
    return socketTechnicalLogsRef.current;
  };

  const closeTechnicalLogsSocket = () => {
    const socket = socketTechnicalLogsRef.current;

    if (socket) {
      socket.close();
      socketTechnicalLogsRef.current = null;
      setTechnicalLogsSocketStatus('closed');
    }
  };

  ////TECHNICAL LOGS ARCHIVE
  const connectTechnicalLogsArchiveSocket = () => {
    if (socketTechnicalLogsArchiveRef.current) {
      socketTechnicalLogsArchiveRef.current.off();
      socketTechnicalLogsArchiveRef.current.disconnect();
      socketTechnicalLogsArchiveRef.current = null;
    }

    if (
      !socketTechnicalLogsArchiveRef.current &&
      technicalLogsArchiveSocketStatus !== 'connected'
    ) {
      let newSocket;

      newSocket = io(ioTechnicalLogsArchiveURL, {
        query: {
          adminAccountId,
        },
        reconnectionAttempts: 10,
        withCredentials: withCredentials,
      });

      newSocket.on('connect', () => {
        logsOn &&
          console.log(
            `Connected to TechnicalLogsArchive Socket, id: ${newSocket.id}`
          );
        setTechnicalLogsArchiveSocketStatus('connected');
      });

      newSocket.on('technicalLogsArchiveData', (technicalLogsArchiveData) => {
        handleSocketTechnicalLogArchiveData(technicalLogsArchiveData);
      });

      newSocket.on('disconnect', (reason, details) => {
        logsOn &&
          console.error(
            `Tickets socket disconnect, Reason: ${reason}, Details: ${details}`
          );
        setTechnicalLogsArchiveSocketStatus('pending');

        if (
          reason === 'io client disconnect' ||
          reason === 'io server disconnect'
        ) {
          logsOn &&
            console.error('Tickets socket disconnect, no automatic reconnect');
          closeTechnicalLogsArchiveSocket();
        } else if (
          reason === 'transport close' ||
          reason === 'transport error'
        ) {
          logsOn &&
            console.error('Tickets socket disconnect, automatic reconnect');
        } else {
          logsOn &&
            console.error(
              `Tickets socket disconnect, Unexpected disconnect reason: ${reason}`
            );
          closeTechnicalLogsArchiveSocket();
        }
      });

      newSocket.on('error', (error) => {
        logsOn && console.error('Tickets socket, socket error:', error);
        setTechnicalLogsArchiveSocketStatus('closed');
        closeTechnicalLogsArchiveSocket();
      });

      return (socketTechnicalLogsArchiveRef.current = newSocket);
    }
  };

  const getTechnicalLogsArchiveSocket = () => {
    return socketTechnicalLogsArchiveRef.current;
  };

  const closeTechnicalLogsArchiveSocket = () => {
    const socket = socketTechnicalLogsArchiveRef.current;

    if (socket) {
      socket.close();
      socketTechnicalLogsArchiveRef.current = null;
      setTechnicalLogsArchiveSocketStatus('closed');
    }
  };

  return (
    <TechnicalLogsSocketContext.Provider
      value={{
        //technical logs
        connectTechnicalLogsSocket,
        closeTechnicalLogsSocket,
        getTechnicalLogsSocket,
        technicalLogsSocketStatus,

        //technical logs archive
        connectTechnicalLogsArchiveSocket,
        getTechnicalLogsArchiveSocket,
        closeTechnicalLogsArchiveSocket,
        technicalLogsArchiveSocketStatus,
      }}
    >
      {children}
    </TechnicalLogsSocketContext.Provider>
  );
};

export const useTechnicalLogsSocket = () => {
  return useContext(TechnicalLogsSocketContext);
};

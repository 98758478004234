import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

//hooks
import { useApiStatus } from '../../../../context/ApiStatusProvider';
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//components
import EventPage from '../../events/EventPage/EventPage';
import TextArea from '../../../../components/TextArea/TextArea';
import ImageCropper from '../../../../components/ImageCropper/ImageCropper';
import ItemButton from '../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../components/Spinner/Spinner';

//utils
import { axiosLimited, axiosLimitedMultiPart } from '../../../../axios/axios';
import ScrollTo from '../../../../utils/UI/ScrollTo';
import convertBase64ToJPEG from '../../../../utils/images/convertBase64ToJPEG';
import {
  checkmark_green,
  img_arrow_stubby,
  preview,
} from '../../../../assets/icons';

const EventParticipantsEmailInvitationForm = () => {
  //hooks
  const { emailParticipationInviteToken } = useParams();
  const { handleWarning, handleError } = useApiStatus();
  const { setNavbar, width } = useSettings();

  //language
  const { Generic } = useLanguageComponents();
  const { ParticipantCreated, EventParticipantsEmailInvitationForm } =
    useLanguageOrganize();

  //state
  const [participantData, setParticipantData] = useState({
    pronouns: '',
    prefix: '',
    firstName: '',
    lastName: '',
    bio: '',
    id: null,
  });
  const [eventData, setEventData] = useState();
  const [eventPreview, setEventPreview] = useState(false);

  //images
  const [pauseCrop, setPauseCrop] = useState(false);
  const [participantImage, setParticipantImage] = useState();

  //UI
  const [isLoading, setIsLoading] = useState(false);
  const [uploadDataIsLoading, setUploadDataIsLoading] = useState(false);

  //ui
  const [page, setPage] = useState(0);

  //var
  const initializationEffectRan = useRef(false);

  useEffect(() => {
    if (
      initializationEffectRan.current === false &&
      emailParticipationInviteToken
    ) {
      initializationEffectRan.current = true;
      setNavbar('');
      if (emailParticipationInviteToken) {
        handleEmailInviteParticipationTokenValidation({
          emailParticipationInviteToken,
          setPage,
        });
      }
    }
  }, [emailParticipationInviteToken]);

  //API Fetch Functions
  async function handleEmailInviteParticipationTokenValidation({
    emailParticipationInviteToken,
    setPage,
  }) {
    try {
      const { data } = await axiosLimited.get(
        `/api/public/validateEmailInviteParticipationToken/${emailParticipationInviteToken}`
      );
      if (data?.expiredToken) {
        setPage(1); //expired page
      }

      if (data?.eventId) {
        setPage(3); //submit data page
        handleGetEvent(data.eventId);
        setParticipantData({
          ...participantData,
          id: data.invitedParticipantId,
        });
      }
    } catch (error) {
      setPage(2); //event not found page
      const newError = {
        error,
        id: Date.now(),
        origin:
          'EventParticipantsEmailInvitationForm.js/handleEmailInviteParticipationTokenValidation',
      };

      handleError(newError);
    }
  } //check to see if /:token is valid before rendering page

  async function handleGetEvent(eventId) {
    if (!isLoading) {
      try {
        setIsLoading(true);

        const res = await axiosLimited.get(`/api/public/events/${eventId}`);
        setEventData(res?.data?.event);
      } catch (error) {
        handleError({
          id: Date.now(),
          origin: 'EventParticipantsEmailInvitationForm.js/handleGetEvent',
          error,
        });
      }
      setIsLoading(false);
    }
  }

  //API Submission Functions
  async function handleUploadParticipationData() {
    if (
      participantData?.firstName?.length === 0 &&
      participantData?.lastName?.length === 0 &&
      participantData?.bio?.length === 0
    ) {
      return handleWarning({
        message: 'incompleteInformation',
        origin:
          'EventParticipantsEmailInvitationForm.js/handleUploadParticipationData',
        id: Date.now(),
      });
    }

    if (!uploadDataIsLoading) {
      setUploadDataIsLoading(true);

      const formData = new FormData();

      const newParticipantdata = JSON.stringify(participantData);

      formData.append('participantData', newParticipantdata);
      formData.append(
        'emailParticipationInviteToken',
        emailParticipationInviteToken
      );

      if (participantImage?.croppedImage?.croppedImage) {
        const convertedImage = await convertBase64ToJPEG(
          participantImage.croppedImage.croppedImage,
          `${participantData.id}.jpg`
        );
        formData.append(`participantImages`, convertedImage);
      }

      try {
        const res = await axiosLimitedMultiPart.post(
          '/api/public/uploadEventTranslationsInviteContactData',
          formData
        );

        if (!res?.error) {
          setPage(4);
        }
      } catch (error) {
        handleError({
          error,
          id: Date.now(),
          origin:
            'EventParticipantsEmailInvitationForm.js/handleUploadParticipationData',
        });
      }
      setUploadDataIsLoading(false);
    }
  }

  //Data Handlers
  function handleParticipantImageChange(newImgData) {
    let newImg = {
      ...newImgData,
      crop: { ...newImgData.crop },
      croppedImage: { ...newImgData.croppedImage },
      id: participantData.id,
    };
    setParticipantImage(newImg);
  }

  function handlePrefix(val) {
    let newParticipantData = {
      ...participantData,
    };
    newParticipantData.prefix = val;
    setParticipantData(newParticipantData);
  }

  function handlePronouns(val) {
    let newParticipantData = {
      ...participantData,
    };
    newParticipantData.pronouns = val;
    setParticipantData(newParticipantData);
  }

  function handleFirstName(val) {
    let newParticipantData = {
      ...participantData,
    };
    newParticipantData.firstName = val;
    setParticipantData(newParticipantData);
  }

  function handleLastName(val) {
    let newParticipantData = {
      ...participantData,
    };
    newParticipantData.lastName = val;
    setParticipantData(newParticipantData);
  }

  function handleBio(val) {
    let newParticipantData = {
      ...participantData,
    };
    newParticipantData.bio = val;
    setParticipantData(newParticipantData);
  }

  function handleImageAltChange(val) {
    let newParticipantData = {
      ...participantData,
    };
    newParticipantData.profileImageAlt = val;

    setParticipantData(newParticipantData);
  }

  //Event Preview
  function handleViewEvent() {
    if (eventData) {
      //add new participant data;
      let newEventData = JSON.parse(JSON.stringify(eventData));
      let newParticipants = [];

      for (let participant of newEventData.participants) {
        if (participant.id === participantData.id) {
          participant.participantData.personal = participantData;
        }
        newParticipants.push(participant);
      }
      newEventData.participants = newParticipants;
      setPauseCrop(true);
      setEventPreview(newEventData);
    }
  }

  function handleReturnFromViewEvent() {
    setEventPreview();

    const animationTimer = setTimeout(() => {
      ScrollTo({
        focusId: 'participantProfileLabel',
        scrollToTop: true,
      });
      setPauseCrop(false);
    }, 100);
    return () => clearTimeout(animationTimer);
  }

  return (
    <>
      {eventPreview && eventData ? (
        <div className="flex-center mrg-auto-lr full-width">
          <EventPage
            previewEventData={eventPreview}
            participantPreviewOn={true}
            handleCustomReturn={handleReturnFromViewEvent}
            participantImagesCropped={
              participantImage ? [participantImage] : null
            }
          />
        </div>
      ) : (
        <div className="mrg-auto-lr min-full-height">
          {page === 0 && <Spinner minHeight={'100%'} />}
          {page === 1 && (
            <div className="float-form__wrapper min-full-height ">
              <div className="float-form__container mrg-auto-top mrg-auto-bottom">
                <label
                  className="label"
                  tabIndex="0"
                  id="invitationExpiredLabel"
                  aria-describedby="aria-fieldset-instructions-general"
                >
                  {EventParticipantsEmailInvitationForm.invitationExpired}
                </label>
                <p className="fs18">
                  {
                    EventParticipantsEmailInvitationForm.invitationExpiredDescription
                  }
                </p>
              </div>
            </div>
          )}
          {page === 2 && (
            <div className="float-form__wrapper min-full-height ">
              <div className="float-form__container mrg-auto-top mrg-auto-bottom">
                <label
                  className="label"
                  tabIndex="0"
                  id="eventNotFoundLabel"
                  aria-describedby="aria-fieldset-instructions-general"
                >
                  {EventParticipantsEmailInvitationForm.eventNotFound}
                </label>
                <p className="fs18">
                  {
                    EventParticipantsEmailInvitationForm.eventNotFoundDescription
                  }
                </p>
              </div>
            </div>
          )}
          {page === 3 && (
            <div className="float-form__wrapper mrg-t24 mrg-b48 mrg-t0--ph">
              <div className="float-form__container mrg-auto-top mrg-auto-bottom">
                <label
                  className="label"
                  tabIndex="0"
                  id="participantProfileLabel"
                  aria-describedby="aria-fieldset-instructions-general"
                >
                  {EventParticipantsEmailInvitationForm.participantProfile}
                </label>

                <>
                  <div className={'participant-base__wrapper'}>
                    <div className="flex-row ff-col-row--mob full-width">
                      <div className="flex-column flex-center full-width mrg-t36--ph mrg-r24">
                        <ImageCropper
                          ariaImageDescription={
                            ParticipantCreated.profilePicture
                          }
                          cover={'horizontal-cover'}
                          handleImage={handleParticipantImageChange}
                          height={264}
                          id={`participant-image`}
                          imagePreview={participantImage?.imagePreview}
                          pauseCrop={isLoading || pauseCrop}
                          profileFrame={true}
                          width={264}
                          customFileUploaderWrapper={
                            'file-uploader__wrapper--profile'
                          }
                          customFileUploaderContainer={
                            'file-uploader__container--profile'
                          }
                          ignoreRemoveImageOnDismount={true}
                          saveCroppedPos={true}
                          loadCroppedPos={
                            participantImage
                              ? {
                                  crop: participantImage.crop,
                                  zoom: participantImage.zoom,
                                }
                              : null
                          }
                          trashButtonSpacerOn={true}
                        />
                        <TextArea
                          handleChange={handleImageAltChange}
                          limit={200}
                          id={'imageAltText'}
                          labelText={Generic.imageAlt}
                          disableEnter={true}
                          loadTrigger={true}
                          customWrapper={'mrg-auto-top mrg-b24--mob'}
                          loadValue={participantData.profileImageAlt}
                          onBlurHandlerOff={true}
                          hideWordCount={true}
                        />
                      </div>

                      <div className="full-width mrg-auto-bottom">
                        <div className="flex-row flex-column--ph">
                          <TextArea
                            customWidth={width >= 480 ? '108px' : '100%'}
                            disableEnter={true}
                            hideWordCount={true}
                            id={'participantPrefix'}
                            labelText={ParticipantCreated.prefix}
                            limit={8}
                            loadTrigger={true}
                            customWrapper={
                              width >= 480 ? 'mrg-r24 mrg-b24' : 'mrg-b24'
                            }
                            loadValue={participantData?.prefix}
                            handleChange={handlePrefix}
                            onBlurHandlerOff={true}
                          />
                          <TextArea
                            customWidth={'100%'}
                            customWrapper={'mrg-b24'}
                            disableEnter={true}
                            hideWordCount={true}
                            id={'participantPronouns'}
                            labelText={ParticipantCreated.pronouns}
                            limit={20}
                            loadTrigger={true}
                            handleChange={handlePronouns}
                            loadValue={participantData?.pronouns}
                            onBlurHandlerOff={true}
                          />
                        </div>

                        <TextArea
                          disableEnter={true}
                          hideWordCount={true}
                          id={'participantFirstName'}
                          labelText={ParticipantCreated.firstName}
                          limit={50}
                          loadTrigger={true}
                          handleChange={handleFirstName}
                          loadValue={participantData?.firstName}
                          onBlurHandlerOff={true}
                          asterisk={true}
                        />
                        <TextArea
                          disableEnter={true}
                          hideWordCount={true}
                          id={'participantLastName'}
                          labelText={ParticipantCreated.lastName}
                          limit={50}
                          loadTrigger={true}
                          handleChange={handleLastName}
                          loadValue={participantData?.lastName}
                          onBlurHandlerOff={true}
                          customWrapper={'mrg-t24'}
                          asterisk={true}
                        />
                        <TextArea
                          handleChange={handleBio}
                          limit={3000}
                          id={'participantPersonalBio'}
                          medium={true}
                          loadTrigger={true}
                          loadValue={participantData?.bio}
                          labelText={ParticipantCreated.personalBio}
                          disableEnter={false}
                          customWrapper={'mrg-t24--ph mrg-t24'}
                          asterisk={true}
                        />
                      </div>
                    </div>

                    <ItemButton
                      handler={handleViewEvent}
                      text={EventParticipantsEmailInvitationForm.viewEvent}
                      fullWidth={true}
                      customWrapper={'mrg-auto-top mrg-t36'}
                      iconOn={true}
                      icon={preview}
                      iconStyle={{ height: '16px' }}
                    />

                    <ItemButton
                      handler={handleUploadParticipationData}
                      text={EventParticipantsEmailInvitationForm.submit}
                      fullWidth={true}
                      customWrapper={'mrg-auto-top mrg-t24'}
                      isLoading={uploadDataIsLoading}
                      iconOn={true}
                      iconCustomClass={'rotate-ccw90'}
                      icon={img_arrow_stubby}
                      iconStyle={{ height: '18px' }}
                    />
                  </div>
                </>
              </div>
              <div className="h48" />
            </div>
          )}
          {page === 4 && (
            <div className="float-form__wrapper min-full-height ">
              <div className="float-form__container mrg-auto-top mrg-auto-bottom">
                <label
                  className="label"
                  tabIndex="0"
                  id="invitationExpiredLabel"
                  aria-describedby="aria-fieldset-instructions-general"
                >
                  {
                    EventParticipantsEmailInvitationForm.participantInformationSent
                  }
                  <img
                    src={checkmark_green}
                    className="mrg-l12"
                    style={{ height: '18px' }}
                    alt=""
                  />
                </label>
                <p className="fs18">
                  {
                    EventParticipantsEmailInvitationForm.participantInformationSentDescription
                  }
                </p>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EventParticipantsEmailInvitationForm;

//Hooks
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useAdminAuthReduxHandlers } from './useAdminAuthHandlers';

const RequireAdminAuth = ({ allowedRoles }) => {
  //Hooks
  const { adminAuthCurrentToken: token, adminAuthCurrentRoles: roles } =
    useAdminAuthReduxHandlers();
  const location = useLocation();

  return token && roles?.find((role) => allowedRoles?.includes(`${role}`)) ? (
    <Outlet />
  ) : token ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAdminAuth;

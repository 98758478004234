//hooks
import useLanguageNotListedHandlers from '../../utils/utilsHandlers/useLanguageNotListedHandlers';

//languages
import useLanguageComponents from '../../language/useLanguageComponents';

//components
import ItemButton from '../ItemButton/ItemButton';
import TextArea from '../TextArea/TextArea';

//utils
import { trash } from '../../assets/icons';

const LanguageNotListed = ({
  languageNotListed,
  setLanguagesNotListed,
  languages,
  setLanguages,
  customWrapper,
  TextAreaOnBlurHandlerOff,
}) => {
  //hooks
  const {
    handleAddNotListedLanguage,
    handleRemoveNotListedLanguage,
    handleNotListedLanguages,
  } = useLanguageNotListedHandlers();

  //language
  const { LanguageNotListed } = useLanguageComponents();

  return (
    <>
      {languageNotListed?.length > 0 && (
        <div className={`${customWrapper}`}>
          <ItemButton
            handler={() =>
              handleAddNotListedLanguage({
                currentLanguagesNotListed: languageNotListed,
                handleSetLanguagesNotListed: setLanguagesNotListed,
              })
            }
            text={LanguageNotListed.addAnotherNonListedLanguage}
            fullWidth={true}
            customWrapper={'mrg-b24'}
            unavailable={languageNotListed?.length > 5}
          />
          {languageNotListed?.map((languageObj, index) => {
            return (
              <div
                className={`flex-row align-center ${
                  index !== languageNotListed?.length - 1 ? 'mrg-b24' : ''
                }`}
                key={languageObj.id}
              >
                <TextArea
                  limit={100}
                  id={languageObj.id}
                  labelText={LanguageNotListed.language}
                  disableEnter={true}
                  customWidth={'100%'}
                  handleChange={(text) =>
                    handleNotListedLanguages({
                      text,
                      langId: languageObj.id,
                      currentLanguagesNotListed: languageNotListed,
                      handleSetLanguagesNotListed: setLanguagesNotListed,
                    })
                  }
                  loadValue={languageObj.language}
                  loadTrigger={true}
                  onBlurHandlerOff={TextAreaOnBlurHandlerOff}
                  ariaText={LanguageNotListed.ariaNotListedLanguageTextInput}
                  hideWordCount={true}
                />
                <button
                  className="mrg-l24 min44 highlight-i-lgr align-center access-ob access-o6"
                  onClick={() =>
                    handleRemoveNotListedLanguage({
                      id: languageObj.id,
                      currentLanguagesNotListed: languageNotListed,
                      handleSetLanguagesNotListed: setLanguagesNotListed,
                      currentLanguages: languages,
                      handleSetLanguages: setLanguages,
                    })
                  }
                >
                  <img src={trash} alt="X" className="filter-gray" />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default LanguageNotListed;

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  //Generic
  selectSettingsAccountIdVerification,
  selectSettingsLastRoomCode,
  selectSettingsDeviceId,
  settingsReset,
  settingsSetAccountIdVerification,
  settingsSetLastRoomCode,
  settingsSetDeviceId,

  //Account
  selectSettingsAccountSettingsView,
  settingsSetAccountSettingsView,

  //Searches - general
  selectSettingsEventSearchCategory,
  selectSettingsEventSearchDirection,
  selectSettingsEventSearchFormat,
  settingsSetEventSearchCategory,
  settingsSetEventSearchDirection,
  settingsSetEventSearchFormat,
  selectSettingsEventSearchView,
  settingsSetEventSearchView,

  //Searches - big tags
  selectSettingsBigTagsListOpened,
  selectSettingsSelectAllBTBtn,
  selectSettingsSelectedBigTags,
  settingsAddSelectedBigTags,
  settingsRemoveAllSelectedBigTags,
  settingsRemoveSelectedBigTags,
  settingsSetBigTagsListOpened,
  settingsSetSelectAllAccountsFollowingBtn,
  settingsSetSelectAllBTBtn,
  settingsSetSelectedBigTags,
  settingsSetBTInPersonQueryHasMore,
  settingsSetBTInPersonQueryPage,
  settingsSetBTOnlineQueryHasMore,
  settingsSetBTOnlineQueryPage,
  selectSettingsBTInPersonQueryHasMore,
  selectSettingsBTInPersonQueryPage,
  selectSettingsBTOnlineQueryHasMore,
  selectSettingsBTOnlineQueryPage,

  //Searches - community
  selectSettingsAccountsFollowingListOpened,
  selectSettingsSelectAllAccountsFollowingBtn,
  selectSettingsSelectedAccountsFollowingIds,
  settingsAddSelectedAccountsFollowingId,
  settingsRemoveAllSelectedAccountsFollowingIds,
  settingsRemoveSelectedAccountsFollowingId,
  settingsSetAccountsFollowingListOpened,
  settingsSetSelectedAccountsFollowingIds,
  settingsSetCommunityInPersonQueryHasMore,
  settingsSetCommunityInPersonQueryPage,
  settingsSetCommunityOnlineQueryHasMore,
  settingsSetCommunityOnlineQueryPage,
  selectSettingsCommunityInPersonQueryHasMore,
  selectSettingsCommunityInPersonQueryPage,
  selectSettingsCommunityOnlineQueryHasMore,
  selectSettingsCommunityOnlineQueryPage,

  //Searches - community groups
  selectSettingsSelectedAccountsFollowingGroupIds,
  settingsAddSelectedAccountsFollowingGroupIds,
  settingsRemoveAllSelectedAccountsFollowingGroupIds,
  settingsRemoveSelectedAccountsFollowingGroupIds,
  settingsSetSelectedAccountsFollowingGroupIds,

  //Searches - my events
  selectSettingsMyEventsListOpened,
  selectSettingsMyEventsSelectedMyEvents,
  selectSettingsMyEventsSelectAllMyEventsBtn,
  settingsSetSelectedMyEvents,
  settingsSetMyEventsListOpened,
  settingsSetSelectAllMyEventsBtn,

  //Searches - tiny tags
  selectSettingsSelectAllTTBtn,
  selectSettingsSelectedTinyTags,
  selectSettingsTinyTagsListOpened,
  settingsAddSelectedTinyTags,
  settingsRemoveAllSelectedTinyTags,
  settingsRemoveSelectedTinyTags,
  settingsSetSelectAllTTBtn,
  settingsSetSelectedTinyTags,
  settingsSetTinyTagsListOpened,
  settingsSetTTInPersonQueryHasMore,
  settingsSetTTInPersonQueryPage,
  settingsSetTTOnlineQueryHasMore,
  settingsSetTTOnlineQueryPage,
  selectSettingsTTInPersonQueryHasMore,
  selectSettingsTTInPersonQueryPage,
  selectSettingsTTOnlineQueryHasMore,
  selectSettingsTTOnlineQueryPage,

  //Searches - tiny tag groups
  selectSettingsSelectedTinyTagGroupIds,
  settingsAddSelectedTinyTagGroupIds,
  settingsRemoveAllSelectedTinyTagGroupIds,
  settingsRemoveSelectedTinyTagGroupIds,
  settingsSetSelectedTinyTagGroupIds,

  //Searches - find speakers
  settingsResetFindSpeakers,
  settingsSetFindSpeakersHasMore,
  settingsSetFindSpeakersQueryPage,
  settingsSetFindSpeakersSearchSpeakerLanguages,
  settingsSetFindSpeakersSearchSpeakerLanguagesNotListed,
  settingsSetFindSpeakersSearchLocationType,
  settingsSetFindSpeakersSearchCountries,
  settingsSetFindSpeakersSearchCoordinates,
  settingsSetFindSpeakersDistanceFilter,
  settingsSetFindSpeakersDistanceMeasurement,
  settingsSetFindSpeakersSearchBigTags,
  settingsSetFindSpeakersSearchTinyTags,
  selectSettingsFindSpeakersHasMore,
  selectSettingsFindSpeakersQueryPage,
  selectSettingsFindSpeakersSearchSpeakerLanguages,
  selectSettingsFindSpeakersSearchSpeakerLanguagesNotListed,
  selectSettingsFindSpeakersSearchLocationType,
  selectSettingsFindSpeakersSearchCountries,
  selectSettingsFindSpeakersSearchCoordinates,
  selectSettingsFindSpeakersDistanceFilter,
  selectSettingsFindSpeakersDistanceMeasurement,
  selectSettingsFindSpeakersSearchBigTags,
  selectSettingsFindSpeakersSearchTinyTags,

  //Organize
  selectSettingsOrganizeView,
  selectSettingsOrganizeOverviewSortCategory,
  selectSettingsOrganizeOverviewDirection,
  selectSettingsOrganizeOverviewFormat,
  selectSettingsOrganizeSelectedOrganizers,
  selectSettingsOrganizeSelectAllOrganizersBtn,
  selectSettingsOrganizeOverviewListOpened,
  settingsSetOrganizeView,
  settingsSetOrganizeOverviewSortCategory,
  settingsSetOrganizeOverviewDirection,
  settingsSetOrganizeOverviewFormat,
  settingsSetSelectAllOrganizersBtn,
  settingsSetOrganizeOverviewListOpened,
  settingsAddSelectedOrganizer,
  settingsRemoveSelectedOrganizer,
  settingsSetSelectedOrganizers,
  selectSettingsOrganizeOverviewContent,
  settingsSetOrganizeOverviewContent,
  settingsSetStandaloneGridEvents,
  selectSettingsOrganizeOverviewStandaloneGridEvents,
  settingsSetPersist,
  selectSettingsPersist,
  settingsSetLoginEmail,
  selectSettingsLoginEmail,
} from './settingsSlice';
import { useAccountReduxHandlers } from '../account/useAccountHandlers';
import { useAdminAccountReduxHandlers } from '../../admin/account/useAdminAccountHandlers';
import { useApiStatus } from '../../../context/ApiStatusProvider';

export const useSettingsHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const {
    accountId,
    accountBigTags,
    accountTinyTags,
    accountTinyTagGroups,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  } = useAccountReduxHandlers();
  const { handleAdminAccountEmptyAdminAccountData } =
    useAdminAccountReduxHandlers();
  const { handleWarning } = useApiStatus();

  //Generic
  const settingsAccountIdVerification = useSelector(
    selectSettingsAccountIdVerification
  );
  const settingsDeviceId = useSelector(selectSettingsDeviceId);

  const settingsLastRoomCode = useSelector(selectSettingsLastRoomCode);

  //Account
  const settingsAccountSettingsView = useSelector(
    selectSettingsAccountSettingsView
  );

  const settingsPersist = useSelector(selectSettingsPersist);

  const settingsLoginEmail = useSelector(selectSettingsLoginEmail);

  //Searches - General
  const settingsEventSearchDirection = useSelector(
    selectSettingsEventSearchDirection
  );
  const settingsEventSearchCategory = useSelector(
    selectSettingsEventSearchCategory
  );
  const settingsEventSearchFormat = useSelector(
    selectSettingsEventSearchFormat
  );

  const settingsEventSearchView = useSelector(selectSettingsEventSearchView);

  //Searches - Big Tags
  const settingsSelectedBigTags = useSelector(selectSettingsSelectedBigTags);

  const settingsBigTagsListOpened = useSelector(
    selectSettingsBigTagsListOpened
  );

  const settingsBTInPersonQueryHasMore = useSelector(
    selectSettingsBTInPersonQueryHasMore
  );

  const settingsBTInPersonQueryPage = useSelector(
    selectSettingsBTInPersonQueryPage
  );

  const settingsBTOnlineQueryHasMore = useSelector(
    selectSettingsBTOnlineQueryHasMore
  );

  const settingsBTOnlineQueryPage = useSelector(
    selectSettingsBTOnlineQueryPage
  );

  const settingsSelectAllBTBtn = useSelector(selectSettingsSelectAllBTBtn);

  //Searches - myEvents
  const settingsMyEventsListOpened = useSelector(
    selectSettingsMyEventsListOpened
  );
  const settingsSelectedMyEvents = useSelector(
    selectSettingsMyEventsSelectedMyEvents
  );
  const settingsSelectAllMyEventsBtn = useSelector(
    selectSettingsMyEventsSelectAllMyEventsBtn
  );

  //Searches - Community
  const settingsSelectedAccountsFollowingIds = useSelector(
    selectSettingsSelectedAccountsFollowingIds
  );

  const settingsAccountsFollowingListOpened = useSelector(
    selectSettingsAccountsFollowingListOpened
  );

  const settingsSelectAllAccountsFollowingBtn = useSelector(
    selectSettingsSelectAllAccountsFollowingBtn
  );

  const settingsSelectedAccountsFollowingGroupIds = useSelector(
    selectSettingsSelectedAccountsFollowingGroupIds
  );

  const settingsCommunityInPersonQueryHasMore = useSelector(
    selectSettingsCommunityInPersonQueryHasMore
  );

  const settingsCommunityInPersonQueryPage = useSelector(
    selectSettingsCommunityInPersonQueryPage
  );

  const settingsCommunityOnlineQueryHasMore = useSelector(
    selectSettingsCommunityOnlineQueryHasMore
  );

  const settingsCommunityOnlineQueryPage = useSelector(
    selectSettingsCommunityOnlineQueryPage
  );

  //Searches - Tiny Tags
  const settingsSelectAllTTBtn = useSelector(selectSettingsSelectAllTTBtn);

  const settingsSelectedTinyTagGroupIds = useSelector(
    selectSettingsSelectedTinyTagGroupIds
  );

  const settingsSelectedTinyTags = useSelector(selectSettingsSelectedTinyTags);

  const settingsTinyTagsListOpened = useSelector(
    selectSettingsTinyTagsListOpened
  );

  const settingsTTInPersonQueryHasMore = useSelector(
    selectSettingsTTInPersonQueryHasMore
  );

  const settingsTTInPersonQueryPage = useSelector(
    selectSettingsTTInPersonQueryPage
  );

  const settingsTTOnlineQueryHasMore = useSelector(
    selectSettingsTTOnlineQueryHasMore
  );

  const settingsTTOnlineQueryPage = useSelector(
    selectSettingsTTOnlineQueryPage
  );

  //Searches find speakers
  const settingsFindSpeakersHasMore = useSelector(
    selectSettingsFindSpeakersHasMore
  );
  const settingsFindSpeakersQueryPage = useSelector(
    selectSettingsFindSpeakersQueryPage
  );
  const settingsFindSpeakersSearchSpeakerLanguages = useSelector(
    selectSettingsFindSpeakersSearchSpeakerLanguages
  );
  const settingsFindSpeakersSearchSpeakerLanguagesNotListed = useSelector(
    selectSettingsFindSpeakersSearchSpeakerLanguagesNotListed
  );
  const settingsFindSpeakersSearchLocationType = useSelector(
    selectSettingsFindSpeakersSearchLocationType
  );
  const settingsFindSpeakersSearchCountries = useSelector(
    selectSettingsFindSpeakersSearchCountries
  );
  const settingsFindSpeakersSearchCoordinates = useSelector(
    selectSettingsFindSpeakersSearchCoordinates
  );
  const settingsFindSpeakersDistanceFilter = useSelector(
    selectSettingsFindSpeakersDistanceFilter
  );
  const settingsFindSpeakersDistanceMeasurement = useSelector(
    selectSettingsFindSpeakersDistanceMeasurement
  );
  const settingsFindSpeakersSearchBigTags = useSelector(
    selectSettingsFindSpeakersSearchBigTags
  );
  const settingsFindSpeakersSearchTinyTags = useSelector(
    selectSettingsFindSpeakersSearchTinyTags
  );

  //Organize
  const settingsOrganizeView = useSelector(selectSettingsOrganizeView);

  const settingsOrganizeOverviewSortCategory = useSelector(
    selectSettingsOrganizeOverviewSortCategory
  );
  const settingsOrganizeOverviewDirection = useSelector(
    selectSettingsOrganizeOverviewDirection
  );
  const settingsOrganizeOverviewFormat = useSelector(
    selectSettingsOrganizeOverviewFormat
  );
  const settingsOrganizeSelectedOrganizers = useSelector(
    selectSettingsOrganizeSelectedOrganizers
  );
  const settingsOrganizeSelectAllOrganizersBtn = useSelector(
    selectSettingsOrganizeSelectAllOrganizersBtn
  );
  const settingsOrganizeStandaloneGridEvents = useSelector(
    selectSettingsOrganizeOverviewStandaloneGridEvents
  );

  const settingsOrganizeOverviewListOpened = useSelector(
    selectSettingsOrganizeOverviewListOpened
  );
  const settingsOrganizeOverviewContent = useSelector(
    selectSettingsOrganizeOverviewContent
  );

  //Generic
  function handleSettingsSetAccountIdVerification(str) {
    dispatch(settingsSetAccountIdVerification(str));
  }

  function handleSettingsSetLastRoomCode(str) {
    dispatch(settingsSetLastRoomCode(str));
  }
  function handleSettingsReset() {
    dispatch(settingsReset());
  }

  function handleSettingsSetPersist(boolean) {
    dispatch(settingsSetPersist(boolean));
  }

  function handleSettingsSetLoginEmail(str) {
    dispatch(settingsSetLoginEmail(str));
  }

  function handleSettingsSetDeviceId() {
    const newDeviceId = uuidv4();
    dispatch(settingsSetDeviceId(newDeviceId));
  }

  //Account
  function handleSettingsSetAccountSettingsView(str) {
    dispatch(settingsSetAccountSettingsView(str));
  }

  //Searches general
  function handleSettingsSetEventSearchCategory(str) {
    dispatch(settingsSetEventSearchCategory(str));
  }
  function handleSettingsSetEventSearchDirection(str) {
    dispatch(settingsSetEventSearchDirection(str));
  }
  function handleSettingsSetEventSearchFormat(str) {
    dispatch(settingsSetEventSearchFormat(str));
  }
  function handleSettingsSetEventView(str) {
    dispatch(settingsSetEventSearchView(str));
  }

  //Searches bigTags
  // function handleSettingsSetBTQueryHasMore(boolean) {
  //   dispatch(settingsSetBTQueryHasMore(boolean));
  // }
  // function handleSettingsSetBTQueryPage(num) {
  //   dispatch(settingsSetBTQueryPage(num));
  // }

  function handleSettingsSetBTInPersonQueryHasMore(boolean) {
    dispatch(settingsSetBTInPersonQueryHasMore(boolean));
  }
  function handleSettingsSetBTInPersonQueryPage(num) {
    dispatch(settingsSetBTInPersonQueryPage(num));
  }
  function handleSettingsSetBTOnlineQueryHasMore(boolean) {
    dispatch(settingsSetBTOnlineQueryHasMore(boolean));
  }
  function handleSettingsSetBTOnlineQueryPage(num) {
    dispatch(settingsSetBTOnlineQueryPage(num));
  }

  function handleSettingsSetBigTagsListOpened(boolean) {
    dispatch(settingsSetBigTagsListOpened(boolean));
  }
  function handleSettingsSetSelectAllBTBtn(boolean) {
    dispatch(settingsSetSelectAllBTBtn(boolean));
  }
  function handleSettingsAddSelectedBigTags(arr) {
    dispatch(settingsAddSelectedBigTags(arr));
  }
  function handleSettingsRemoveAllSelectedBigTags() {
    dispatch(settingsRemoveAllSelectedBigTags());
  }
  function handleSettingsRemoveSelectedBigTags(str) {
    dispatch(settingsRemoveSelectedBigTags(str));
  }
  function handleSettingsSetSelectedBigTags(arr) {
    dispatch(settingsSetSelectedBigTags(arr));
  }

  //Searches Community
  function handleSettingsSetSelectAllAccountsFollowingBtn(boolean) {
    dispatch(settingsSetSelectAllAccountsFollowingBtn(boolean));
  }
  function handleSettingsSetAccountsFollowingListOpened(boolean) {
    dispatch(settingsSetAccountsFollowingListOpened(boolean));
  }
  function handleSettingsSetSelectedAccountsFollowingIds(arr) {
    dispatch(settingsSetSelectedAccountsFollowingIds(arr));
  }
  function handleSettingsAddSelectedAccountsFollowingId(str) {
    dispatch(settingsAddSelectedAccountsFollowingId(str));
  }
  function handleSettingsRemoveAllSelectedAccountsFollowingIds() {
    dispatch(settingsRemoveAllSelectedAccountsFollowingIds());
  }
  function handleSettingsRemoveSelectedAccountsFollowingId(str) {
    dispatch(settingsRemoveSelectedAccountsFollowingId(str));
  }

  function handleSettingsSetCommunityInPersonQueryHasMore(boolean) {
    dispatch(settingsSetCommunityInPersonQueryHasMore(boolean));
  }
  function handleSettingsSetCommunityInPersonQueryPage(num) {
    dispatch(settingsSetCommunityInPersonQueryPage(num));
  }

  function handleSettingsSetCommunityOnlineQueryHasMore(boolean) {
    dispatch(settingsSetCommunityOnlineQueryHasMore(boolean));
  }
  function handleSettingsSetCommunityOnlineQueryPage(num) {
    dispatch(settingsSetCommunityOnlineQueryPage(num));
  }

  //Searches - Community Groups
  function handleSettingsRemoveSelectedAccountsFollowingGroupIds(str) {
    dispatch(settingsRemoveSelectedAccountsFollowingGroupIds(str));
  }
  function handleSettingsAddSelectedAccountsFollowingGroupIds(arr) {
    dispatch(settingsAddSelectedAccountsFollowingGroupIds(arr));
  }
  function handleSettingsRemoveAllSelectedAccountsFollowingGroupIds() {
    dispatch(settingsRemoveAllSelectedAccountsFollowingGroupIds());
  }
  function handleSettingsSetSelectedAccountsFollowingGroupIds(arr) {
    dispatch(settingsSetSelectedAccountsFollowingGroupIds(arr));
  }

  //Searches My Events
  function handleSettingsSetMyEventsListOpened(arr) {
    dispatch(settingsSetMyEventsListOpened(arr));
  }

  function handleSettingsSetSelectedMyEvents(boolean) {
    dispatch(settingsSetSelectedMyEvents(boolean));
  }

  function handleSettingsSetSelectAllMyEventsBtn(boolean) {
    dispatch(settingsSetSelectAllMyEventsBtn(boolean));
  }

  //Searches Tiny Tags
  function handleSettingsSetTTInPersonQueryHasMore(boolean) {
    dispatch(settingsSetTTInPersonQueryHasMore(boolean));
  }
  function handleSettingsSetTTInPersonQueryPage(num) {
    dispatch(settingsSetTTInPersonQueryPage(num));
  }

  function handleSettingsSetTTOnlineQueryHasMore(boolean) {
    dispatch(settingsSetTTOnlineQueryHasMore(boolean));
  }
  function handleSettingsSetTTOnlineQueryPage(num) {
    dispatch(settingsSetTTOnlineQueryPage(num));
  }

  function handleSettingsSetTinyTagsListOpened(boolean) {
    dispatch(settingsSetTinyTagsListOpened(boolean));
  }
  function handleSettingsSetSelectAllTTBtn(boolean) {
    dispatch(settingsSetSelectAllTTBtn(boolean));
  }

  function handleSettingsAddSelectedTinyTags(str) {
    dispatch(settingsAddSelectedTinyTags(str));
  }
  function handleSettingsRemoveSelectedTinyTags(str) {
    dispatch(settingsRemoveSelectedTinyTags(str));
  }
  function handleSettingsRemoveAllSelectedTinyTags() {
    dispatch(settingsRemoveAllSelectedTinyTags());
  }
  function handleSettingsSetSelectedTinyTags(arr) {
    dispatch(settingsSetSelectedTinyTags(arr));
  }

  //Searches Tiny tag groups
  function handleSettingsRemoveSelectedTinyTagGroupIds(str) {
    dispatch(settingsRemoveSelectedTinyTagGroupIds(str));
  }
  function handleSettingsAddSelectedTinyTagGroupIds(arr) {
    dispatch(settingsAddSelectedTinyTagGroupIds(arr));
  }
  function handleSettingsRemoveAllSelectedTinyTagGroupIds() {
    dispatch(settingsRemoveAllSelectedTinyTagGroupIds());
  }
  function handleSettingsSetSelectedTinyTagGroupIds(arr) {
    dispatch(settingsSetSelectedTinyTagGroupIds(arr));
  }

  //Searches Find speakers
  function handleSettingsResetFindSpeakers() {
    dispatch(settingsResetFindSpeakers());
  }

  function handleSettingsSetFindSpeakersHasMore(boolean) {
    dispatch(settingsSetFindSpeakersHasMore(boolean));
  }

  function handleSettingsSetFindSpeakersQueryPage(num) {
    dispatch(settingsSetFindSpeakersQueryPage(num));
  }

  function handleSettingsSetFindSpeakersSearchSpeakerLanguages(arr) {
    if (arr?.includes('notListed')) {
      if (settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length === 0) {
        handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed([
          {
            id: uuidv4(),
            language: '',
          },
        ]);
      }
    } else {
      handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed([]);
    }

    dispatch(settingsSetFindSpeakersSearchSpeakerLanguages(arr));
  }

  function handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed(arr) {
    dispatch(settingsSetFindSpeakersSearchSpeakerLanguagesNotListed(arr));
  }
  function handleSettingsSetFindSpeakersSearchLocationType(str) {
    dispatch(settingsSetFindSpeakersSearchLocationType(str));
  }
  function handleSettingsSetFindSpeakersSearchCountries(arr) {
    dispatch(settingsSetFindSpeakersSearchCountries(arr));
  }
  function handleSettingsSetFindSpeakersSearchCoordinates(arr) {
    dispatch(settingsSetFindSpeakersSearchCoordinates(arr));
  }
  function handleSettingsSetFindSpeakersDistanceFilter(num) {
    dispatch(settingsSetFindSpeakersDistanceFilter(num));
  }
  function handleSettingsSetFindSpeakersDistanceMeasurement(str) {
    dispatch(settingsSetFindSpeakersDistanceMeasurement(str));
  }
  function handleSettingsSetFindSpeakersSearchBigTags(arr) {
    dispatch(settingsSetFindSpeakersSearchBigTags(arr));
  }
  function handleSettingsSetFindSpeakersSearchTinyTags(arr) {
    dispatch(settingsSetFindSpeakersSearchTinyTags(arr));
  }

  //Organize
  function handleSettingsSetOrganizeView(str) {
    dispatch(settingsSetOrganizeView(str));
  }
  function handleSettingsSetOrganizeOverviewSortCategory(str) {
    dispatch(settingsSetOrganizeOverviewSortCategory(str));
  }
  function handleSettingsSetOrganizeOverviewDirection(str) {
    dispatch(settingsSetOrganizeOverviewDirection(str));
  }
  function handleSettingsSetOrganizeOverviewFormat(str) {
    dispatch(settingsSetOrganizeOverviewFormat(str));
  }
  function handleSettingsSetOrganizeOverviewListOpened(boolean) {
    dispatch(settingsSetOrganizeOverviewListOpened(boolean));
  }
  function handleSettingsSetSelectAllOrganizersBtn(boolean) {
    dispatch(settingsSetSelectAllOrganizersBtn(boolean));
  }
  function handleSettingsAddSelectedOrganizer(str) {
    dispatch(settingsAddSelectedOrganizer(str));
  }
  function handleSettingsRemoveSelectedOrganizer(str) {
    dispatch(settingsRemoveSelectedOrganizer(str));
  }
  function handleSettingsSetSelectedOrganizers(arr) {
    dispatch(settingsSetSelectedOrganizers(arr));
  }
  function handleSettingsSetOrganizeOverviewStandaloneGridEvents(str) {
    dispatch(settingsSetStandaloneGridEvents(str));
  }

  function handleSettingsSetOrganizeOverviewContent(str) {
    dispatch(settingsSetOrganizeOverviewContent(str));
  }

  //ensure that before this handler is called, the accountId and accountIdVerification do not match
  function handleSettingsResetRedux(acctId) {
    //clear settings Redux for different user
    handleSettingsReset();
    handleSettingsSetAccountIdVerification(acctId || accountId);

    if (accountBigTags?.length > 0) {
      handleSettingsSetSelectAllBTBtn(true);
      handleSettingsSetSelectedBigTags(accountBigTags);
    }

    if (accountTinyTags?.length > 0) {
      handleSettingsSetSelectAllTTBtn(true);
      handleSettingsSetSelectedTinyTags(accountTinyTags);
    }

    if (accountTinyTagGroups?.length > 0) {
      handleSettingsSetSelectedTinyTagGroupIds(accountTinyTagGroups);
    }

    if (accountCommunityFollowing?.length > 0) {
      handleSettingsSetSelectAllAccountsFollowingBtn(true);
      handleSettingsSetSelectedAccountsFollowingIds(accountCommunityFollowing);
    }
  }

  function handleSettingsAdminResetRedux() {
    //clear settings Redux for different user
    handleAdminAccountEmptyAdminAccountData();
  }

  function handleValidateFindSpeakersQuery() {
    //returns validationFailed
    let warningMessage;

    if (
      settingsFindSpeakersSearchSpeakerLanguages?.includes('notListed') &&
      settingsFindSpeakersSearchSpeakerLanguagesNotListed.some(
        (nL) => nL.language?.length === 0
      )
    ) {
      warningMessage = 'languageIsIncomplete';
    }

    if (settingsFindSpeakersSearchSpeakerLanguages?.length === 0) {
      warningMessage = 'languageIsIncomplete';
    }

    if (
      settingsFindSpeakersSearchBigTags?.length === 0 &&
      settingsFindSpeakersSearchTinyTags?.length === 0
    ) {
      warningMessage = 'tagsIsIncomplete';
    }

    if (!settingsFindSpeakersSearchLocationType) {
      warningMessage = 'locationIsIncomplete';
    }

    if (settingsFindSpeakersSearchLocationType === 'locationMap') {
      if (
        !settingsFindSpeakersSearchCoordinates[0] ||
        settingsFindSpeakersSearchCoordinates[0] === 0
      ) {
        warningMessage = 'locationIsIncomplete';
      }
      if (
        !settingsFindSpeakersSearchCoordinates[1] ||
        settingsFindSpeakersSearchCoordinates[1] === 0
      ) {
        warningMessage = 'locationIsIncomplete';
      }
    }

    if (settingsFindSpeakersSearchLocationType === 'locationCountry') {
      if (settingsFindSpeakersSearchCountries?.length === 0) {
        warningMessage = 'locationIsIncomplete';
      }
    }

    if (warningMessage) {
      handleWarning({
        message: warningMessage,
        origin: 'useSettingsHandlers/handleValidateFindSpeakersQuery',
        id: Date.now(),
      });
      return true;
    } else {
      return false;
    }
  }

  function handleSelectAllTagsIfLoadedEmpty() {
    if (settingsEventSearchView === 'bigtags') {
      if (settingsSelectedBigTags?.length === 0) {
        handleSettingsSetSelectAllBTBtn(true);
        handleSettingsSetSelectedBigTags(accountBigTags);
      }
    }

    if (settingsEventSearchView === 'tinytags') {
      if (
        settingsSelectedTinyTags?.length === 0 &&
        settingsSelectedTinyTagGroupIds?.length === 0
      ) {
        handleSettingsSetSelectAllTTBtn(true);
        handleSettingsSetSelectedTinyTags(accountTinyTags);

        let tGIds = [];

        accountTinyTagGroups.forEach((tG) => tGIds.push(tG.id));
        handleSettingsSetSelectedTinyTagGroupIds(tGIds);
      }
    }

    if (settingsEventSearchView === 'community') {
      if (
        settingsSelectedAccountsFollowingGroupIds?.length === 0 &&
        settingsSelectedAccountsFollowingIds?.length === 0
      ) {
        let accountsFollowingIdsArr = [];
        let accountsFollowingGroupIdsArr = [];

        accountCommunityFollowing?.map((account) =>
          accountsFollowingIdsArr.push(account._id)
        );
        handleSettingsSetSelectedAccountsFollowingIds(accountsFollowingIdsArr);

        accountCommunityFollowingGroups?.forEach((accountFollowingGroup) =>
          accountsFollowingGroupIdsArr.push(accountFollowingGroup.id)
        );

        handleSettingsSetSelectedAccountsFollowingGroupIds(
          accountsFollowingGroupIdsArr
        );

        handleSettingsSetSelectAllAccountsFollowingBtn(true);
      }
    }

    if (settingsEventSearchView === 'myevents') {
      if (settingsSelectedMyEvents?.length === 0) {
        handleSettingsSetSelectedMyEvents([
          'registered',
          'saved',
          'participationEvents',
        ]);
      }
    }
  }

  return {
    //Generic
    handleSettingsReset,
    handleSettingsResetRedux,
    handleSettingsAdminResetRedux,
    settingsDeviceId,
    handleSettingsSetDeviceId,

    //Account
    handleSettingsSetPersist,
    settingsPersist,
    settingsLoginEmail,
    handleSettingsSetLoginEmail,
    handleSettingsSetAccountIdVerification,
    settingsAccountIdVerification,

    //Searches General
    handleSettingsSetAccountSettingsView,
    handleSettingsSetAccountsFollowingListOpened,
    handleSettingsSetEventSearchCategory,
    handleSettingsSetEventSearchDirection,
    handleSettingsSetEventSearchFormat,
    handleSettingsSetEventView,
    settingsAccountSettingsView,
    settingsAccountsFollowingListOpened,
    settingsEventSearchCategory,
    settingsEventSearchDirection,
    settingsEventSearchFormat,
    settingsEventSearchView,

    //Searches Big Tags
    handleSettingsAddSelectedBigTags,
    handleSettingsRemoveAllSelectedBigTags,
    handleSettingsRemoveSelectedBigTags,
    handleSettingsSetBigTagsListOpened,
    handleSettingsSetBTInPersonQueryHasMore,
    handleSettingsSetBTInPersonQueryPage,
    handleSettingsSetBTOnlineQueryHasMore,
    handleSettingsSetBTOnlineQueryPage,

    handleSettingsSetSelectAllBTBtn,
    handleSettingsSetSelectedBigTags,
    settingsBigTagsListOpened,
    settingsSelectAllBTBtn,
    settingsSelectedBigTags,
    settingsBTInPersonQueryHasMore,
    settingsBTInPersonQueryPage,
    settingsBTOnlineQueryHasMore,
    settingsBTOnlineQueryPage,

    //Searches Communiy
    handleSettingsAddSelectedAccountsFollowingGroupIds,
    handleSettingsAddSelectedAccountsFollowingId,
    handleSettingsRemoveAllSelectedAccountsFollowingGroupIds,
    handleSettingsRemoveAllSelectedAccountsFollowingIds,
    handleSettingsRemoveSelectedAccountsFollowingGroupIds,
    handleSettingsRemoveSelectedAccountsFollowingId,
    handleSettingsSetSelectedAccountsFollowingGroupIds,
    handleSettingsSetSelectedAccountsFollowingIds,
    settingsSelectAllAccountsFollowingBtn,
    settingsSelectedAccountsFollowingGroupIds,
    settingsSelectedAccountsFollowingIds,
    handleSettingsSetSelectAllAccountsFollowingBtn,

    settingsCommunityInPersonQueryHasMore,
    settingsCommunityInPersonQueryPage,
    settingsCommunityOnlineQueryHasMore,
    settingsCommunityOnlineQueryPage,
    handleSettingsSetCommunityInPersonQueryHasMore,
    handleSettingsSetCommunityInPersonQueryPage,
    handleSettingsSetCommunityOnlineQueryHasMore,
    handleSettingsSetCommunityOnlineQueryPage,

    //Searches Tiny Tags
    handleSettingsAddSelectedTinyTagGroupIds,
    handleSettingsAddSelectedTinyTags,
    handleSettingsRemoveAllSelectedTinyTagGroupIds,
    handleSettingsRemoveAllSelectedTinyTags,
    handleSettingsRemoveSelectedTinyTagGroupIds,
    handleSettingsRemoveSelectedTinyTags,
    handleSettingsSetSelectAllTTBtn,
    handleSettingsSetSelectedTinyTagGroupIds,
    handleSettingsSetSelectedTinyTags,
    handleSettingsSetTinyTagsListOpened,
    settingsSelectAllTTBtn,
    settingsSelectedTinyTagGroupIds,
    settingsSelectedTinyTags,
    settingsTinyTagsListOpened,
    settingsTTInPersonQueryHasMore,
    settingsTTInPersonQueryPage,
    settingsTTOnlineQueryHasMore,
    settingsTTOnlineQueryPage,
    handleSettingsSetTTInPersonQueryHasMore,
    handleSettingsSetTTInPersonQueryPage,
    handleSettingsSetTTOnlineQueryHasMore,
    handleSettingsSetTTOnlineQueryPage,

    //Searches My Events
    handleSettingsSetSelectedMyEvents,
    handleSettingsSetSelectAllMyEventsBtn,
    handleSettingsSetMyEventsListOpened,
    settingsSelectedMyEvents,
    settingsMyEventsListOpened,
    settingsSelectAllMyEventsBtn,

    //Organize
    handleSettingsAddSelectedOrganizer,
    handleSettingsRemoveSelectedOrganizer,
    handleSettingsSetOrganizeOverviewContent,
    handleSettingsSetOrganizeOverviewDirection,
    handleSettingsSetOrganizeOverviewFormat,
    handleSettingsSetOrganizeOverviewListOpened,
    handleSettingsSetOrganizeOverviewSortCategory,
    handleSettingsSetOrganizeOverviewStandaloneGridEvents,
    handleSettingsSetOrganizeView,
    handleSettingsSetSelectAllOrganizersBtn,
    handleSettingsSetSelectedOrganizers,
    settingsOrganizeOverviewContent,
    settingsOrganizeOverviewDirection,
    settingsOrganizeOverviewFormat,
    settingsOrganizeOverviewListOpened,
    settingsOrganizeOverviewSortCategory,
    settingsOrganizeSelectAllOrganizersBtn,
    settingsOrganizeSelectedOrganizers,
    settingsOrganizeStandaloneGridEvents,
    settingsOrganizeView,

    //translations
    settingsLastRoomCode,
    handleSettingsSetLastRoomCode,

    //find speaker
    handleValidateFindSpeakersQuery,
    handleSettingsResetFindSpeakers,
    settingsResetFindSpeakers,
    settingsFindSpeakersHasMore,
    settingsFindSpeakersQueryPage,
    handleSettingsSetFindSpeakersHasMore,
    handleSettingsSetFindSpeakersQueryPage,
    handleSettingsSetFindSpeakersSearchSpeakerLanguages,
    handleSettingsSetFindSpeakersSearchSpeakerLanguagesNotListed,
    handleSettingsSetFindSpeakersSearchLocationType,
    handleSettingsSetFindSpeakersSearchCountries,
    handleSettingsSetFindSpeakersSearchCoordinates,
    handleSettingsSetFindSpeakersDistanceFilter,
    handleSettingsSetFindSpeakersDistanceMeasurement,
    handleSettingsSetFindSpeakersSearchBigTags,
    handleSettingsSetFindSpeakersSearchTinyTags,
    settingsFindSpeakersSearchSpeakerLanguages,
    settingsFindSpeakersSearchSpeakerLanguagesNotListed,
    settingsFindSpeakersSearchLocationType,
    settingsFindSpeakersSearchCountries,
    settingsFindSpeakersSearchCoordinates,
    settingsFindSpeakersDistanceFilter,
    settingsFindSpeakersDistanceMeasurement,
    settingsFindSpeakersSearchBigTags,
    settingsFindSpeakersSearchTinyTags,

    //utils
    handleSelectAllTagsIfLoadedEmpty,
  };
};

import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import { useSettings } from '../../../../../context/SettingsProvider';

//api
import { useLazyTinyTagEventsQuery } from '../../eventsApiSlice';

//components
import EventsGrid from '../EventsGrid';
import EventPage from '../../EventPage/EventPage';

const TinyTagsGrid = () => {
  //hooks
  const { accountId } = useAccountReduxHandlers();
  const { cards, settingsViewEventDetails } = useSettings();
  const {
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchCategory: searchCategory,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    settingsSelectedTinyTags: searchTTs,
    settingsSelectedTinyTagGroupIds: searchTGIds,
    handleSettingsSetTTInPersonQueryPage,
    handleSettingsSetTTInPersonQueryHasMore,
    settingsTTInPersonQueryPage: tTInPersonQueryPage,
    settingsTTInPersonQueryHasMore: tTInPersonQueryHasMore,
    handleSettingsSetTTOnlineQueryPage,
    handleSettingsSetTTOnlineQueryHasMore,
    settingsTTOnlineQueryPage: tTOnlineQueryPage,
    settingsTTOnlineQueryHasMore: tTOnlineQueryHasMore,

    //utils
    handleSelectAllTagsIfLoadedEmpty,
  } = useSettingsHandlers();

  //api
  const [
    getTinyTagEvents,
    {
      data: tTData,
      isFetching: tTisFetching,
      isLoading: tTisLoading,
      isUninitialized: tTisUninitialized,
    },
  ] = useLazyTinyTagEventsQuery();

  //state
  const [filteredTTInPersonEvents, setFilteredTTInPersonEvents] = useState([]);
  const [filteredTTOnlineEvents, setFilteredTTOnlineEvents] = useState([]);
  const [TTCardsIsFetchingToFillRow, setTTCardsIsFetchingToFillRow] =
    useState(false);

  //ui
  const [focusId, setFocusId] = useState();
  const [returnFromEventPageComplete, setReturnFromEventPageComplete] =
    useState(true);

  //variables
  let filteredEvents = [];

  let seenTTInPersonIds = new Set();
  let seenTTOnlineIds = new Set();

  const fetchDelayTimer = useRef();

  //initialize
  useEffect(() => {
    handleSelectAllTagsIfLoadedEmpty();
    /*eslint-disable-next-line*/
  }, []); //selects all tags if loaded empty

  useEffect(() => {
    if (settingsViewEventDetails) {
      setFocusId(settingsViewEventDetails?.meta?.eventReference);
    }
  }, [settingsViewEventDetails]); //sets card focus + works with eventsGrid

  //Component functions
  useEffect(() => {
    if (accountId && tTisUninitialized && searchTTs && searchTGIds) {
      if (
        accountId &&
        (searchTTs?.length > 0 || searchTGIds?.length > 0) &&
        searchCategory &&
        searchDirection &&
        searchFormat &&
        cards
      ) {
        handleSettingsSetTTInPersonQueryPage(0);
        handleSettingsSetTTInPersonQueryHasMore(true);
        handleSettingsSetTTOnlineQueryPage(0);
        handleSettingsSetTTOnlineQueryHasMore(true);

        getTinyTagEvents({
          accountId,
          searchTTs,
          searchTGIds,
          searchCategory,
          searchDirection,
          searchFormat,
          queryCardsPerPage: cards,
          tTQueryPage: 0,
        });
      }
    }

    /*eslint-disable-next-line*/
  }, [
    view,
    cards,
    accountId,
    searchTTs,
    searchTGIds,
    searchDirection,
    searchFormat,
    tTisUninitialized,
  ]); //initial search

  useEffect(() => {
    if (!tTisFetching && !tTisLoading) {
      if (searchFormat === 'inPerson') {
        getNewTTInPersonEventsList();
      } else if (searchFormat === 'online') {
        getNewTTOnlineEventsList();
      }
    }
    /*eslint-disable-next-line*/
  }, [searchCategory, searchDirection, searchFormat, searchTTs, searchTGIds]);

  //TINY TAG FUNCTIONS
  useEffect(() => {
    if (tTData?.data) {
      if (searchFormat === 'inPerson') {
        tTData.data.forEach((item) => {
          if (!seenTTInPersonIds.has(item._id)) {
            filteredEvents.push(item);
            seenTTInPersonIds.add(item._id);
          }
        });
        setFilteredTTInPersonEvents(filteredEvents);
        filteredEvents = [];
      } else if (searchFormat === 'online') {
        tTData.data.forEach((item) => {
          if (!seenTTOnlineIds.has(item._id)) {
            filteredEvents.push(item);
            seenTTOnlineIds.add(item._id);
          }
        });
        setFilteredTTOnlineEvents(filteredEvents);
        filteredEvents = [];
      }
    }
    return () => {
      seenTTInPersonIds.clear();
      seenTTOnlineIds.clear();
    };
  }, [tTData]); //removal of duplicate events

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (
      filteredTTInPersonEvents?.length % cards !== 0 &&
      tTInPersonQueryHasMore &&
      !TTCardsIsFetchingToFillRow
    ) {
      getNextTTInPersonEventsPage();
    } else {
      fetchDelayTimer.current = setTimeout(() => {
        setTTCardsIsFetchingToFillRow(false);
      }, 500); // need time out as precaution against rapid queries
    }
  }, [filteredTTInPersonEvents]);

  useEffect(() => {
    //check to see if full row; if not need to continue loading state
    if (
      filteredTTOnlineEvents?.length % cards !== 0 &&
      tTOnlineQueryHasMore &&
      !TTCardsIsFetchingToFillRow
    ) {
      getNextTTOnlineEventsPage();
    } else {
      fetchDelayTimer.current = setTimeout(() => {
        setTTCardsIsFetchingToFillRow(false);
      }, 500); // need time out as precaution against rapid queries
    }
  }, [filteredTTOnlineEvents]);

  function getNextTTInPersonEventsPage() {
    if (
      !tTisFetching &&
      !tTisLoading &&
      accountId &&
      (searchTTs?.length > 0 || searchTGIds?.length > 0)
    ) {
      setTTCardsIsFetchingToFillRow(true);
      const newPage = tTInPersonQueryPage + 1;
      handleSettingsSetTTInPersonQueryPage(newPage);
      getTinyTagEvents({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat: 'inPerson',
        queryCardsPerPage: cards,
        tTQueryPage: newPage,
      });
    }
  }

  function getNextTTOnlineEventsPage() {
    if (
      !tTisFetching &&
      !tTisLoading &&
      accountId &&
      (searchTTs?.length > 0 || searchTGIds?.length > 0)
    ) {
      setTTCardsIsFetchingToFillRow(true);
      const newPage = tTOnlineQueryPage + 1;
      handleSettingsSetTTOnlineQueryPage(newPage);
      getTinyTagEvents({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat: 'online',
        queryCardsPerPage: cards,
        tTQueryPage: newPage,
      });
    }
  }

  function getNewTTInPersonEventsList() {
    if (!tTisFetching && !tTisLoading && accountId) {
      setTTCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetTTInPersonQueryPage(newPage);
      getTinyTagEvents({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat: 'inPerson',
        queryCardsPerPage: cards,
        tTQueryPage: newPage,
      });
    }
  }

  function getNewTTOnlineEventsList() {
    if (!tTisFetching && !tTisLoading && accountId) {
      setTTCardsIsFetchingToFillRow(true);
      const newPage = 0;
      handleSettingsSetTTOnlineQueryPage(newPage);
      getTinyTagEvents({
        accountId,
        searchTTs,
        searchTGIds,
        searchCategory,
        searchDirection,
        searchFormat: 'online',
        queryCardsPerPage: cards,
        tTQueryPage: newPage,
      });
    }
  }

  async function updateEventDataListPostRegistration(newEventData) {
    setFilteredTTInPersonEvents(
      filteredTTInPersonEvents?.map((ev) =>
        ev._id === newEventData._id ? newEventData : ev
      )
    );
    setFilteredTTOnlineEvents(
      filteredTTOnlineEvents?.map((ev) =>
        ev._id === newEventData._id ? newEventData : ev
      )
    );
  }

  return (
    <>
      {settingsViewEventDetails ? (
        <EventPage
          updateEventDataListPostRegistration={
            updateEventDataListPostRegistration
          }
        />
      ) : (
        <EventsGrid
          cards={cards}
          events={
            searchFormat === 'inPerson'
              ? filteredTTInPersonEvents
              : filteredTTOnlineEvents
          }
          isLoading={TTCardsIsFetchingToFillRow}
          isUninitialized={tTisUninitialized}
          nextPageFunction={
            searchFormat === 'inPerson'
              ? getNextTTInPersonEventsPage
              : getNextTTOnlineEventsPage
          }
          hasMore={
            searchFormat === 'inPerson'
              ? tTInPersonQueryHasMore
              : tTOnlineQueryHasMore
          }
          tagsCheck={searchTTs?.length || searchTGIds?.length}
          eventsGridType={'tinytags'}
          key={`eventsGrid-tinytags-${searchFormat}`}
          focusId={focusId}
          setFocusId={setFocusId}
          returnFromEventPageComplete={returnFromEventPageComplete}
          setReturnFromEventPageComplete={setReturnFromEventPageComplete}
        />
      )}
    </>
  );
};

export default TinyTagsGrid;

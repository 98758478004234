//Hooks
import { useState } from 'react';
import { useApiStatus } from '../../../../context/ApiStatusProvider';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//api
import { useLazyMyEventsQuery } from '../../events/eventsApiSlice';
import {
  useUpdateMyEventInviteParticipantStatusMutation,
  useUpdateParticipantAccountEventsMutation,
  useUpdateInviteContactParticipantEventsFromPublicationMutation,
  useNotifyInviteContactsEventPublishedMutation,
  useChangePendingNoticesForInviteContactsFromEventPublicationMutation,
  useEventParticipationInviteResponseMutation,
  useInviteContactEventParticipantMutation,
  useOrganizerRemovePendingParticipantMutation,
  useOrganizerRemoveAcceptedParticipantUpdateAccountEventsMutation,
  useOrganizerRemoveParticipantIssueNoticeToParticipantMutation,
  useWithdrawAsParticipantMutation,
  useParticipantWithdrewMutation,
  useSendParticipantEmailInvitationMutation,
  useInviteContactEventParticipantTranslationsMutation,
  useEventParticipationTranslationsResponseMutation,
  useUpdateEventParticipantTranslationsStatusMutation,
  useSendInviteContactTranslationsDataMutation,
  useNotifyOrganizerTranslationsDataSubmittedMutation,
  useSendParticipantEmailTranslationsMutation,
} from './eventParticipantsApiSlice';

export const useEventParticipantsHandlers = () => {
  //Hooks
  const { handleError, handleSuccess } = useApiStatus();
  const { accountId } = useAccountReduxHandlers();

  //API Notices + Participants
  const [inviteContactEventParticipant] =
    useInviteContactEventParticipantMutation();

  const [
    eventParticipationInviteResponse,
    { isLoading: eventParticipationInviteResponseIsLoading },
  ] = useEventParticipationInviteResponseMutation();

  const [updateMyEventInviteParticipantStatus] =
    useUpdateMyEventInviteParticipantStatusMutation();

  const [updateParticipantAccountEvents] =
    useUpdateParticipantAccountEventsMutation();

  const [changePendingNoticesForInviteContactsFromEventPublication] =
    useChangePendingNoticesForInviteContactsFromEventPublicationMutation();

  const [updateInviteContactParticipantEventsFromPublication] =
    useUpdateInviteContactParticipantEventsFromPublicationMutation();

  const [notifyInviteContactsEventPublished] =
    useNotifyInviteContactsEventPublishedMutation();

  const [organizerRemovePendingParticipant] =
    useOrganizerRemovePendingParticipantMutation();

  const [organizerRemoveAcceptedParticipantUpdateAccountEvents] =
    useOrganizerRemoveAcceptedParticipantUpdateAccountEventsMutation();

  const [organizerRemoveParticipantIssueNoticeToParticipant] =
    useOrganizerRemoveParticipantIssueNoticeToParticipantMutation();

  const [withdrawAsParticipant] = useWithdrawAsParticipantMutation();

  const [participantWithdrew] = useParticipantWithdrewMutation();

  const [sendParticipantEmailInvitation] =
    useSendParticipantEmailInvitationMutation();

  //Translations
  const [inviteContactEventParticipantTranslations] =
    useInviteContactEventParticipantTranslationsMutation();

  const [sendParticipantEmailTranslations] =
    useSendParticipantEmailTranslationsMutation();

  const [
    eventParticipationTranslationsResponse,
    { isLoading: eventParticipationTranslationsResponseIsLoading },
  ] = useEventParticipationTranslationsResponseMutation();

  const [updateEventParticipantTranslationsStatus] =
    useUpdateEventParticipantTranslationsStatusMutation();

  const [
    sendInviteContactTranslationsData,
    { isLoading: sendInviteContactTranslationsDataIsLoading },
  ] = useSendInviteContactTranslationsDataMutation();

  const [notifyOrganizerTranslationsDataSubmitted] =
    useNotifyOrganizerTranslationsDataSubmittedMutation();

  //Events API
  const [getMyEvents] = useLazyMyEventsQuery();

  //UI state
  const [
    eventParticipantHandlerIsLoading,
    setEventParticipantHandlerIsLoading,
  ] = useState(false);

  async function handleSendEventParticipantInviteDispatches({
    eventData,
    eventType,
  }) {
    try {
      for (const participant of eventData?.participants || []) {
        if (
          participant?.organizedAs === 'inviteContact' &&
          participant?.dispatches?.inviteDispatched === false &&
          participant?.dispatches?.invitationStatus === 'dispatching'
        ) {
          await inviteContactEventParticipant({
            accountId,
            eventId: eventData._id,
            eventType,
            invitedParticipantAccountId:
              participant?.participantData?.participantAccountId,
          });
        }
        if (
          participant?.organizedAs === 'emailInvitation' &&
          participant?.dispatches?.inviteDispatched === false &&
          participant?.dispatches?.invitationStatus === 'dispatching' &&
          participant.emailInvitation.emailValid &&
          !participant.emailInvitation.duplicateEmail
        ) {
          await sendParticipantEmailInvitation({
            accountId,
            eventId: eventData._id,
            eventType,
            participant,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleMyEventParticipationInviteResponse({
    accountId,
    notice,
    inviteResponse,
  }) {
    if (!eventParticipantHandlerIsLoading) {
      setEventParticipantHandlerIsLoading(true);
      let updateEventData;
      if (notice?.eventInvitation?.eventId) {
        updateEventData = {
          eventType: 'event',
          id: notice?.eventInvitation?.eventId,
        };
      } else if (notice?.eventInvitation?.scheduledEventId) {
        updateEventData = {
          eventType: 'scheduledEvent',
          id: notice?.eventInvitation?.scheduledEventId,
        };
      } else if (notice?.eventInvitation?.draftId) {
        updateEventData = {
          eventType: 'draft',
          id: notice?.eventInvitation?.draftId,
        };
      }

      try {
        const res = await eventParticipationInviteResponse({
          accountId,
          noticeId: notice?._id,
          inviteResponse,
          updateEventData,
        });

        //update event participant status
        if (res?.data?.status === 'success') {
          const res2 = await updateMyEventInviteParticipantStatus({
            accountId,
            updateEventData,
            inviteResponse,
          });

          if (
            res2?.data?.status === 'success' &&
            inviteResponse === 'accepted'
          ) {
            let operation;

            if (inviteResponse === 'accepted') {
              operation = '$push';
            } else if (inviteResponse === 'rejected') {
              operation = '$pull';
            }

            await updateParticipantAccountEvents({
              accountId,
              updateEventData,
              operation,
            });

            //get new myEvents after accountEvents updated
            if (inviteResponse === 'accepted') {
              await getMyEvents(accountId);
            }
          }
        }
      } catch (error) {
        return handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin:
            'useOrganizeHandlers.js/handleMyEventParticipationInviteResponse',
        });
      }
      setEventParticipantHandlerIsLoading(false);
    }
  }

  async function handleRemoveParticipationInvite({
    accountId,
    updateEventData,
    removedParticipantsWithDispatchedInvites,
  }) {
    try {
      //needs to handle pending and accepted with shared conditions so if participant accepts in the background it will still run it through
      for (const removedParticipant of removedParticipantsWithDispatchedInvites ||
        []) {
        //if withdrew, no need to process.
        if (removedParticipant?.dispatches?.invitationStatus !== 'withdrew') {
          if (
            removedParticipant?.dispatches?.invitationStatus === 'pending' ||
            removedParticipant?.dispatches?.translationsStatus === 'pending'
          ) {
            //remove notice requesting participation -- is okay to check pending with stale data
            await organizerRemovePendingParticipant({
              accountId,
              updateEventData,
              removedParticipantId:
                removedParticipant?.participantData?.participantAccountId,
            });
          }

          //remove from account events
          await organizerRemoveAcceptedParticipantUpdateAccountEvents({
            accountId,
            updateEventData,
            removedParticipantId:
              removedParticipant?.participantData?.participantAccountId,
          });

          //send notice of removal
          await organizerRemoveParticipantIssueNoticeToParticipant({
            accountId,
            updateEventData,
            removedParticipantId:
              removedParticipant?.participantData?.participantAccountId,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  //EVENTS
  async function handlePublishedEventParticipationInvites({
    eventId,
    draftId,
    eventParticipants,
    eventType,
  }) {
    try {
      if (eventParticipants?.length > 0) {
        const hasInviteContacts = eventParticipants.some(
          (p) => p.organizedAs === 'inviteContact'
        );

        if (hasInviteContacts) {
          //1 change pending notices
          if (draftId) {
            await changePendingNoticesForInviteContactsFromEventPublication({
              accountId,
              draftId,
              eventParticipants,
              eventId,
            });
          }

          //2 remove draft id from participant's events + add published event
          await updateInviteContactParticipantEventsFromPublication({
            accountId,
            draftId,
            eventParticipants,
            eventId,
          });

          //3 send notice that the event was published
          await notifyInviteContactsEventPublished({
            accountId,
            eventParticipants,
            eventId,
            eventType,
          });
        }
      }
    } catch (error) {
      return handleError({
        message: 'tryAgain',
        id: Date.now(),
        origin:
          'useOrganizeHandlers.js/handlePublishedEventParticipationInvites',
      });
    }
  } // check for stale data issues

  async function handleWithdrawAsParticipant({
    accountId,
    updateEventData,
    organizerId,
  }) {
    try {
      //remove event from participant's accountEvents + adjust participant in event
      await withdrawAsParticipant({
        accountId,
        updateEventData,
      });

      //send notice to organizer
      await participantWithdrew({
        accountId,
        updateEventData,
        organizerId,
      });
      return true;
    } catch (error) {
      return handleError({
        message: error?.error,
        id: Date.now(),
        origin: 'useOrganizeHandlers.js/handleWithdrawAsParticipant',
      });
    }
  }

  //TRANSLATIONS API
  async function handleSendEventParticipantTranslationsDispatches({
    eventData,
    eventType,
  }) {
    try {
      for (const participant of eventData?.participants || []) {
        if (
          participant?.organizedAs === 'inviteContact' &&
          participant?.dispatches?.translationsDispatched === false &&
          participant?.dispatches?.translationsStatus === 'dispatching'
        ) {
          await inviteContactEventParticipantTranslations({
            accountId,
            eventId: eventData._id,
            eventType,
            invitedParticipantAccountId:
              participant?.participantData?.participantAccountId,
          });
        }

        if (
          participant?.organizedAs === 'emailInvitation' &&
          participant?.dispatches?.translationsDispatched === false &&
          participant?.dispatches?.translationsStatus === 'dispatching' &&
          participant.emailInvitation.emailValid &&
          !participant.emailInvitation.duplicateEmail
        ) {
          await sendParticipantEmailTranslations({
            accountId,
            eventId: eventData._id,
            eventType,
            participant,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  }

  async function handleMyEventParticipationTranslationsResponse({
    accountId,
    notice,
    translationsResponse,
  }) {
    if (
      !eventParticipationTranslationsResponseIsLoading &&
      !eventParticipantHandlerIsLoading
    ) {
      setEventParticipantHandlerIsLoading(true);
      let updateEventData;

      if (notice?.eventInvitation?.eventId) {
        updateEventData = {
          eventType: 'event',
          id: notice?.eventInvitation?.eventId,
        };
      } else if (notice?.eventInvitation?.scheduledEventId) {
        updateEventData = {
          eventType: 'scheduledEvent',
          id: notice?.eventInvitation?.scheduledEventId,
        };
      } else if (notice?.eventInvitation?.draftId) {
        updateEventData = {
          eventType: 'draft',
          id: notice?.eventInvitation?.draftId,
        };
      }

      try {
        const res = await eventParticipationTranslationsResponse({
          accountId,
          noticeId: notice?._id,
          translationsResponse,
          updateEventData,
        });

        if (res?.data?.status === 'success') {
          await updateEventParticipantTranslationsStatus({
            accountId,
            participantId: accountId,
            updateEventData,
            translationsResponse,
          });
        }
      } catch (error) {
        return handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin:
            'useOrganizeHandlers.js/handleMyEventParticipationTranslationsResponse',
        });
      }
      setEventParticipantHandlerIsLoading(false);
    }
  }

  async function handleSendEventTranslationsInviteContactData({
    participantId,
    eventId,
    speakerData,
    noticeId,
  }) {
    if (!sendInviteContactTranslationsDataIsLoading) {
      try {
        //send data + archive invitee's notification
        const res = await sendInviteContactTranslationsData({
          participantId,
          eventId,
          speakerData,
          noticeId,
        });

        if (res?.data?.status === 'success') {
          //notify organizer of data submission
          await notifyOrganizerTranslationsDataSubmitted({
            participantId,
            eventId,
          });

          handleSuccess({
            message: 'success',
            id: Date.now(),
            origin:
              'useEventParticipantHandlers.js/handleSendEventTranslationsInviteContactData',
          });
        }
      } catch (error) {
        return handleError({
          message: 'tryAgain',
          id: Date.now(),
          origin:
            'useOrganizeHandlers.js/handleSendEventTranslationsInviteContactData',
        });
      }
    }
  }

  //UTILS FUNCTIONS//
  function handleResetParticipantInvitesAndTranslations(eventData) {
    return new Promise((resolve, reject) => {
      try {
        let newEventData = JSON.parse(JSON.stringify(eventData));

        const newParticipants = newEventData?.participants?.map((p) => {
          if (
            p.organizedAs === 'inviteContact' ||
            p.organizedAs === 'emailInvitation'
          ) {
            p.dispatches.inviteDispatched = false;
            p.dispatches.invitationStatus = 'notDispatched';
            p.dispatches.translationsDispatched = false;
            p.dispatches.translationsStatus = 'notDispatched';
            p.translationsCompletionStatus = 'incomplete';

            p.translationsSpeakerData = {
              name: '',
              keywordsList: [],
              languageOpt: 'select',
              voiceOpt: 'select',
              discussionContext: '',
            };

            if (p.organizedAs === 'emailInvitation') {
              p.emailInvitation = {
                ...p.emailInvitation,
                token: '',
                tokenExpires: '',
              };
              p.participantData.personal = {
                bio: '',
                firstName: '',
                lastName: '',
                prefix: '',
                pronouns: '',
                profileImage: '',
                profileImageAlt: '',
              };
            }
          }
          return p;
        });
        newEventData.participants = newParticipants;

        resolve(newEventData);
      } catch (error) {
        if (process.env.REACT_APP_ENV === 'development') {
          console.log(error);
        }
        reject(error);
      }
    });
  }

  function eventDataHasAcceptedInviteContactParticipants(participantsData) {
    let hasAcceptedInviteContactParticipants = false;

    if (participantsData?.length > 0) {
      hasAcceptedInviteContactParticipants = participantsData?.some(
        (participant) =>
          participant.organizedAs === 'inviteContact' &&
          participant?.participantData?.participantAccountId &&
          participant?.dispatches?.invitationStatus === 'accepted'
      );
    }

    return hasAcceptedInviteContactParticipants;
  }

  function eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts(
    participantsData
  ) {
    let hasAcceptedOrPendingInviteContactParticipants = false;

    if (participantsData?.length > 0) {
      hasAcceptedOrPendingInviteContactParticipants = participantsData?.some(
        (participant) =>
          participant.organizedAs === 'inviteContact' &&
          participant?.participantData?.participantAccountId &&
          (participant?.dispatches?.invitationStatus === 'pending' ||
            participant?.dispatches?.invitationStatus === 'accepted' ||
            participant?.dispatches?.invitationStatus === 'dispatching' ||
            participant?.dispatches?.translationsStatus === 'pending' ||
            participant?.dispatches?.translationsStatus === 'accepted' ||
            participant?.dispatches?.translationsStatus === 'dispatching')
      );
    }

    return hasAcceptedOrPendingInviteContactParticipants;
  }

  return {
    //organize api
    handleSendEventParticipantInviteDispatches,
    handleMyEventParticipationInviteResponse,
    handlePublishedEventParticipationInvites,
    handleRemoveParticipationInvite,
    handleWithdrawAsParticipant,

    //translations api
    handleSendEventParticipantTranslationsDispatches,
    handleMyEventParticipationTranslationsResponse,
    handleSendEventTranslationsInviteContactData,

    //utils
    handleResetParticipantInvitesAndTranslations,
    eventDataHasAcceptedInviteContactParticipants,
    eventParticipantsHasAcceptedOrPendingDispatchesInviteContacts,

    //UI
    eventParticipantHandlerIsLoading,
  };
};

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../useAccountHandlers';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';
import { useUpdateBannerImageMutation } from '../../accountApiSlice';

//language
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import ImageCropper from '../../../../../components/ImageCropper/ImageCropper';
import LoadBucketImage from '../../../../../components/LoadBucketImage/LoadBucketImage';
import ConfirmSelection from '../../../../../components/ConfirmSelection/ConfirmSelection';
import ChangeButton from '../../../../../components/ChangeButton/ChangeButton';
import TextArea from '../../../../../components/TextArea/TextArea';

//utils
import convertBase64ToJPEG from '../../../../../utils/images/convertBase64ToJPEG';
import { handleDBImageBlob } from '../../../../../utils/images/handleDBImageBlob';
import { retryWrapper } from '../../../../../utils/logic/retryFns';
import Spinner from '../../../../../components/Spinner/Spinner';

let ProfileBanner = ({ pauseCrop }, ref) => {
  //hooks
  const { width } = useSettings();
  const { handleError } = useApiStatus();
  const {
    accountPersonalBannerImage,
    accountId,
    accountPersonalBannerImageAlt,
    handleAccountPersonalSetBannerImageAlt,
  } = useAccountReduxHandlers();

  //language
  const { ProfileBanner } = useLanguageAccount();
  const { Generic } = useLanguageComponents();

  //api
  const [updateSpeakerBanner, { isLoading: updateSpeakerBannerIsLoading }] =
    useUpdateBannerImageMutation();

  //state
  const [changeBannerImage, setChangeBannerImage] = useState(false);
  const [croppedImg, setCroppedImg] = useState({
    croppedImage: '',
    fileName: '',
    imagePreview: '',
  });
  const [bannerImageAlt, setBannerImageAlt] = useState(
    accountPersonalBannerImageAlt || ''
  );
  const [convertingImage, setConvertingImage] = useState(false);

  //ui
  const [isLoading, setIsLoading] = useState(false);

  //Initialize functions
  useImperativeHandle(ref, () => ({
    handleGetSpeakerBannerImage() {
      if (croppedImg) {
        return croppedImg;
      }
    },
  }));

  useEffect(() => {
    if (
      accountPersonalBannerImage !== 'banner_img_default.png' &&
      changeBannerImage
    ) {
      retryWrapper(initializeBannerImage);
    }
  }, [accountPersonalBannerImage, changeBannerImage]); //gets new blob for new bannerImage or if user cancels change

  useEffect(() => {
    return () => {
      handleCancelSaveImage();
    };
  }, []);

  //api
  async function initializeBannerImage() {
    try {
      await handleDBImageBlob({
        imagePath: accountPersonalBannerImage,
        imageType: 'banner',
        setImage: setCroppedImg,
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'development') {
        handleError({
          origin: 'ProfileBanner/initializeBannerImage',
          message: 'notFound',
          id: Date.now(),
        });
      }
    }
  }

  async function saveAccountBannerImage(e) {
    e.preventDefault();

    if (!updateSpeakerBannerIsLoading) {
      setIsLoading(true);
      const formData = new FormData();
      const speakerBannerImage = croppedImg;
      let bannerImageJPG;

      if (speakerBannerImage.croppedImage.croppedImage) {
        bannerImageJPG = await convertBase64ToJPEG(
          speakerBannerImage.croppedImage.croppedImage,
          'bannerImage.jpg'
        );
      }

      //5 mb max.
      if (bannerImageJPG?.size >= 5242880) {
        return handleError({
          message: 'imageSizeTooLarge',
          id: Date.now(),
          origin: 'ProfileBanner.js/saveAccountBannerImage',
        });
      }

      formData.append('bannerImage', bannerImageJPG);
      formData.append('accountId', accountId);
      formData.append('bannerImageAlt', bannerImageAlt);

      await updateSpeakerBanner(formData);
      setChangeBannerImage(false);
      handleAccountPersonalSetBannerImageAlt(bannerImageAlt);
      setIsLoading(false);
    }
  }

  //functions
  function handleBannerImg(img) {
    let newImg = {};
    newImg = {
      croppedImage: img.croppedImage,
      fileName: img.fileName,
      imagePreview: img.imagePreview,
    };
    setCroppedImg(newImg);
  }

  function handleCancelSaveImage() {
    if (croppedImg.imagePreview) {
      URL.revokeObjectURL(croppedImg.imagePreview);
    }
    setCroppedImg({
      croppedImage: '',
      fileName: '',
      imagePreview: '',
    });
    setChangeBannerImage(false);
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row align-center full-width"
        id="bannerLabel"
        tabIndex="0"
      >
        {ProfileBanner.bannerImage}
      </label>

      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {ProfileBanner.description}
      </p>
      <div className="h24" />

      {changeBannerImage && !convertingImage && (
        <ImageCropper
          ariaImageDescription={ProfileBanner.ariaImageDescription}
          center={true}
          cover={'horizontal-cover'}
          handleImage={handleBannerImg}
          id={'speakerBannerCropper'}
          imagePreview={croppedImg.imagePreview}
          pauseCrop={pauseCrop}
          width={512}
          height={96}
          setHOActionBtnsUnavailable={setConvertingImage}
          customFileUploaderWrapper={'file-uploader__wrapper--speaker'}
          spinnerMinWidth={'96px'}
          spinnerMinHeight={'96px'}
        />
      )}

      {changeBannerImage && (
        <>
          <TextArea
            handleChange={setBannerImageAlt}
            limit={200}
            id={'bannerImageAltText'}
            labelText={Generic.altText}
            disableEnter={true}
            loadTrigger={true}
            customWrapper={'br--none mrg-t24'}
            loadValue={accountPersonalBannerImageAlt}
            onBlurHandlerOff={true}
            hideWordCount={true}
          />
          <div className="h24" />
        </>
      )}

      {changeBannerImage && !convertingImage && (
        <ConfirmSelection
          btn1Text={ProfileBanner.cancel}
          btn1Color={'neutral'}
          btn1Handler={handleCancelSaveImage}
          btn2Text={ProfileBanner.save}
          btn2Color={'pos'}
          btn2Handler={width > 512 ? saveAccountBannerImage : null}
          center={true}
          customWrapper={
            changeBannerImage ? 'mrg-t24 mrg-auto-lr' : 'mrg-auto-lr'
          }
        />
      )}

      <div className="flex-center flex-column full-width ">
        {!changeBannerImage && (
          <div
            className="flex-column full-width mrg-auto-left mrg-auto-right justify-center align-center"
            key={`profileBanner-${accountPersonalBannerImage}`}
          >
            <LoadBucketImage
              imagePath={accountPersonalBannerImage}
              containerClassName={'mrg-auto-lr'}
              imgClassName={'account-profile-banner--image'}
              backupPath={'banner_img_default.png'}
              partialPath={'myndfull_banner_images'}
              altAriaText={accountPersonalBannerImageAlt}
            />
            <div className="h24" />
            <ChangeButton handler={setChangeBannerImage} />
          </div>
        )}
        {isLoading && !changeBannerImage && (
          <Spinner minHeight={'45px'} maxHeight={'45px'} />
        )}
      </div>
      <div className="h36" />
    </div>
  );
};

ProfileBanner = forwardRef(ProfileBanner);

export default ProfileBanner;

//Hooks
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import FollowButton from '../../../../components/FollowButton/FollowButton';

//Utility
import { calendar, clock } from '../../../../assets/icons';
import {
  GetTimeRangeFromEventObject,
  useDateTimeCalculations,
} from '../../../../utils/dateTime/DateCalc';

const HeadingSummary = ({
  eventData,
  EventPage,
  width,
  organizerViewOn,
  previewMode,
}) => {
  //Hooks
  const {
    accountContentLanguage,
    accountTimeZone,
    accountProfileImage,
    accountFirstName,
    accountLastName,
  } = useAccountReduxHandlers();
  const {
    getDateTimeSummaryForEventPreview,
    getDateShortMonth,
    getDateLongMonth,
    getDateTimeSummary,
  } = useDateTimeCalculations();

  //language
  const { IconAlts } = useLanguageComponents();

  return (
    <>
      <div className="heading-row fwn fs18">
        <div className="flex-row align-center">
          <img
            className="mrg-r12 heading-icon"
            src={calendar}
            alt={IconAlts.iconCalendar}
          />
          <div className="flex-column access-o6 access-ob" tabIndex="0">
            {width > 480 ? (
              <>
                {getDateLongMonth(
                  eventData.dateTime.date,
                  accountContentLanguage
                )}
              </>
            ) : (
              <>
                {getDateShortMonth(
                  eventData.dateTime.date,
                  accountContentLanguage
                )}
              </>
            )}
            {width <= 320 && (
              <div
                className="access-o6 access-ob color-theme fwsb"
                tabIndex="0"
              >
                {!previewMode
                  ? getDateTimeSummary(eventData.dateTime, accountTimeZone)
                  : getDateTimeSummaryForEventPreview(
                      eventData.dateTime,
                      accountTimeZone
                    )}
              </div>
            )}
          </div>
        </div>

        <div className="flex-row color-theme fwsb mrg-l12">
          {width > 320 && (
            <div className="access-o6 access-ob" tabIndex="0">
              {!previewMode
                ? getDateTimeSummary(eventData.dateTime, accountTimeZone)
                : getDateTimeSummaryForEventPreview(
                    eventData.dateTime,
                    accountTimeZone
                  )}
            </div>
          )}
        </div>
      </div>
      <div className="flex-row align-center mrg-t12">
        <img
          className="mrg-r12 heading-icon filter-theme"
          src={clock}
          alt={IconAlts.iconClock}
        />
        <p className="fwn fs18 access-ob access-o6" tabIndex="0">
          {GetTimeRangeFromEventObject(eventData.dateTime)}
        </p>
      </div>
      <div className="heading-row--organizer fwn fs18 mrg-t12">
        <div className="flex-row align-center ">
          {previewMode && organizerViewOn ? (
            <LoadBucketImage
              imagePath={accountProfileImage}
              imgClassName="ev-org-profile-img mrg-r12"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
            />
          ) : (
            <LoadBucketImage
              imagePath={
                eventData?.organizers?.organizerData?.personal?.profileImage
              }
              imgClassName="ev-org-profile-img mrg-r12"
              backupPath={'account_img_default.png'}
              partialPath={'myndfull_account_images'}
              altAriaText={
                eventData?.organizers?.organizerData?.personal?.profileImageAlt
              }
            />
          )}

          <div className="flex-column access-ob access-o6" tabIndex="0">
            <p>{EventPage.organizer}</p>
            {previewMode && organizerViewOn ? (
              <p>
                {accountFirstName} {accountLastName}
              </p>
            ) : (
              <p>
                {eventData?.organizers?.organizerData?.personal?.firstName}
                &nbsp;
                {eventData?.organizers?.organizerData?.personal?.lastName}
              </p>
            )}
          </div>
        </div>

        <FollowButton
          followableAccountId={eventData?.organizers?.organizerData?._id}
          noFollowMrgBtn={'mrg-b24'}
          textOnLeft={true}
        />
      </div>
    </>
  );
};

export default HeadingSummary;

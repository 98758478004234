import { useState } from 'react';

//components
import NoticeResponse from './NoticeTypes/NoticeResponse';
import NoticeContactRequest from './NoticeTypes/NoticeContactRequest';
import NoticeInviteParticipant from './NoticeTypes/NoticeInviteParticipant';
import NoticeInviteParticipantTranslations from './NoticeTypes/NoticeInviteParticipantTranslations';
import NoticeHeader from './NoticeHeader';
import { useNoticeHandlers } from '../../useNoticeHandlers';

const NoticeBase = ({ n, noticeOverlayIsOpened, setNoticeOverlayIsOpened }) => {
  //state
  const [openedNotice, setOpenedNotice] = useState();

  //hooks
  const { noticeTypesWithNoResponseComponent } = useNoticeHandlers();

  //UI
  const [highlightNoticeEntryTap, setHighlightNoticeEntryTap] = useState(false);

  //variables
  return (
    <li
      className={`pos-rel ${
        highlightNoticeEntryTap === n?._id ? 'highlight-bg-lg--tap' : ''
      } ${
        noticeTypesWithNoResponseComponent.includes(n?.noticeType) &&
        openedNotice
          ? 'notice--opened'
          : ''
      }`}
    >
      <NoticeHeader
        n={n}
        setHighlightNoticeEntryTap={setHighlightNoticeEntryTap}
        openedNotice={openedNotice}
        setOpenedNotice={setOpenedNotice}
        noticeOverlayIsOpened={noticeOverlayIsOpened}
        setNoticeOverlayIsOpened={setNoticeOverlayIsOpened}
      />

      {n.noticeType === 'eventParticipationInvite' && (
        <NoticeInviteParticipant
          n={n}
          openedNotice={openedNotice}
          setOpenedNotice={setOpenedNotice}
        />
      )}
      {n.noticeType === 'eventParticipationTranslationsRequest' && (
        <NoticeInviteParticipantTranslations
          n={n}
          openedNotice={openedNotice}
          setOpenedNotice={setOpenedNotice}
          setNoticeOverlayIsOpened={setNoticeOverlayIsOpened}
        />
      )}
      {n.noticeType === 'contactRequest' && (
        <NoticeContactRequest
          n={n}
          openedNotice={openedNotice}
          setOpenedNotice={setOpenedNotice}
        />
      )}
      {!noticeTypesWithNoResponseComponent.includes(n.noticeType) &&
        n?.noticeHistory && (
          <NoticeResponse
            n={n}
            openedNotice={openedNotice}
            setOpenedNotice={setOpenedNotice}
          />
        )}
    </li>
  );
};

export default NoticeBase;

import { useEffect, useState } from 'react';

//language
import useLanguageEvents from '../../language/features/useLanguageEvents';

const EventStatusBarOrganizer = ({
  registrationData,
  virtualEvent,
  inPersonEvent,
  borders,
}) => {
  //Language
  const { EventCard } = useLanguageEvents();

  //Component State
  const [eventStatus, setEventStatus] = useState();

  //Component Variables
  let content;

  useEffect(() => {
    if (virtualEvent) {
      if (
        registrationData?.maxRegistrationsVirtualAmount > 0 &&
        registrationData?.registeredAttendeesVirtual?.length >=
          registrationData?.maxRegistrationsVirtualAmount
      ) {
        return setEventStatus('full');
      }

      if (
        registrationData?.maxRegistrationsVirtualAmount > 0 &&
        registrationData?.maxRegistrationsVirtualAmount / 2 <=
          registrationData?.registeredAttendeesVirtual?.length
      ) {
        return setEventStatus('almostFull');
      }
      return setEventStatus('open');
    } else if (inPersonEvent) {
      if (
        registrationData?.maxRegistrationsInPersonAmount > 0 &&
        registrationData?.registeredAttendeesInPerson?.length >=
          registrationData?.maxRegistrationsInPersonAmount
      ) {
        return setEventStatus('full');
      }

      if (
        registrationData?.maxRegistrationsInPersonAmount > 0 &&
        registrationData?.maxRegistrationsInPersonAmount / 2 <=
          registrationData?.registeredAttendeesInPerson?.length
      ) {
        return setEventStatus('almostFull');
      }
      return setEventStatus('open');
    }
  }, [registrationData]);

  if (eventStatus === 'open') {
    content = (
      <div
        className={`status-bar-organizer ${
          borders ? 'status-color-registration-open' : 'color-open'
        }`}
      >
        {EventCard.open}
      </div>
    );
  } else if (eventStatus === 'full') {
    content = (
      <div
        className={`status-bar-organizer ${
          borders ? 'status-color-full' : 'color-red'
        }`}
      >
        {EventCard.full}
      </div>
    );
  } else if (eventStatus === 'almostFull') {
    content = (
      <div
        className={`status-bar-organizer ${
          borders ? 'status-color-almost-full' : 'color-red'
        }`}
      >
        {EventCard.almostFull}
      </div>
    );
  }

  return content;
};

export default EventStatusBarOrganizer;

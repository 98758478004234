import React, { useEffect, useState } from 'react';

//hooks
import { useEventHandlers } from '../../useEventHandlers';
import { useSettingsHandlers } from '../../../settings/useSettingsHandlers';
import { useAccountReduxHandlers } from '../../../account/useAccountHandlers';
import { useSettings } from '../../../../../context/SettingsProvider';

//api
import { useLazyMyEventsQuery } from '../../eventsApiSlice';

//components
import EventsGrid from '../EventsGrid';
import EventPage from '../../EventPage/EventPage';

const MyEventsGrid = ({ hasViewChangedToMyEvents }) => {
  const { accountId } = useAccountReduxHandlers();
  const { cards, settingsViewEventDetails } = useSettings();
  const { handleMyEventsSorted } = useEventHandlers();
  const {
    settingsEventSearchDirection: searchDirection,
    settingsEventSearchFormat: searchFormat,
    settingsEventSearchView: view,
    //utils
    handleSelectAllTagsIfLoadedEmpty,
  } = useSettingsHandlers();

  //api
  const [
    getMyEvents,
    {
      data: myEventsData,
      isLoading: myEventsisLoading,
      isSuccess: myEventsisSuccess,
      isUninitialized: myEventsisUninitialized,
    },
  ] = useLazyMyEventsQuery();

  //state
  const [myEvents, setMyEvents] = useState([]);

  //ui
  const [focusId, setFocusId] = useState();
  const [returnFromEventPageComplete, setReturnFromEventPageComplete] =
    useState(true);

  //initialize
  useEffect(() => {
    handleSelectAllTagsIfLoadedEmpty();
    /*eslint-disable-next-line*/
  }, []); //selects all tags if loaded empty

  useEffect(() => {
    if (settingsViewEventDetails) {
      setFocusId(settingsViewEventDetails?.meta?.eventReference);
    }
  }, [settingsViewEventDetails]); //sets card focus + works with eventsGrid

  useEffect(() => {
    if (accountId) {
      getMyEvents(accountId);
    }
    /*eslint-disable-next-line*/
  }, [
    view,
    cards,
    accountId,
    searchDirection,
    searchFormat,
    myEventsisUninitialized,
  ]); //initial search

  useEffect(() => {
    if (
      (myEventsisSuccess && !myEventsData) ||
      hasViewChangedToMyEvents !== 'myEvents'
    ) {
      const filteredAndSortedMyEvents = handleMyEventsSorted(myEventsData);
      setMyEvents(filteredAndSortedMyEvents);
    }
  }, [hasViewChangedToMyEvents, myEventsData]); //dont want to refresh the entire list when the user is managing saves on the myEvents page; but if the user is switching to the myEvents page, should update with latest myEvents state

  async function updateEventDataListPostRegistration(newEventData) {
    setMyEvents(
      myEvents?.map((ev) => (ev._id === newEventData._id ? newEventData : ev))
    );
  }

  return (
    <>
      {settingsViewEventDetails ? (
        <EventPage
          updateEventDataListPostRegistration={
            updateEventDataListPostRegistration
          }
        />
      ) : (
        <EventsGrid
          cards={cards}
          events={myEvents}
          isLoading={myEventsisLoading}
          isUninitialized={myEventsisUninitialized}
          eventsGridType={'myEvents'}
          key={'eventsGrid-myEvents'}
          focusId={focusId}
          setFocusId={setFocusId}
          returnFromEventPageComplete={returnFromEventPageComplete}
          setReturnFromEventPageComplete={setReturnFromEventPageComplete}
        />
      )}
    </>
  );
};

export default MyEventsGrid;

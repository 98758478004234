import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//hooks

import useLanguageRegistration from '../../../../../language/features/useLanguageRegistration';
import { useApiStatus } from '../../../../../context/ApiStatusProvider';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageAccount from '../../../../../language/features/useLanguageAccount';

//components
import ButtonMeta from '../../../../../components/ButtonMeta/ButtonMeta';
import AutocompletePlacesNoAddress from '../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import CustomTagsList from '../../../../../components/CustomTagsList/CustomTagsList';
import DistanceInput from '../../../../../components/DistanceInput/DistanceInput';
import MapContainerWithDistanceFilter from '../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import SelectionsModal from '../../../../../components/Selections/SelectionsModal';
import LanguageNotListed from '../../../../../components/LanguageNotListed/LanguageNotListed';

//utils
import {
  bigtag_nobg,
  cancel,
  checkmark_green,
  img_country,
  img_language,
  img_location,
  img_search_location,
  save,
} from '../../../../../assets/icons';

const AttendeeSignUp = ({ saveAccountChange, loadingSaveBtnUI }) => {
  //hooks
  const {
    LanguageSimpleOpts,
    CountriesOpts,
    BigTagsOpts,
    BigTagsOptsWithSubText,
    BigTagIcon,
    TinyTagIcon,
  } = useLanguageData();
  const { handleError } = useApiStatus();

  //language
  const { AttendeeSignUp } = useLanguageAccount();
  const { Generic, IconAlts } = useLanguageComponents();
  const { PersonalSearchSettings } = useLanguageRegistration();

  //state
  const [attendeeSignUpValid, setAttendeeSignUpValid] = useState(false);

  //state - fields
  const [bigTags, setBigTags] = useState([]);
  const [coords, setCoords] = useState('');
  const [distanceFilter, setDistanceFilter] = useState(30);
  const [distanceMeasurement, setDistanceMeasurement] = useState('km');
  const [eventSearchOnlineCountries, setEventSearchOnlineCountries] = useState(
    []
  );
  const [placeCoordinates, setPlaceCoordinates] = useState({
    lat: '',
    lng: '',
  });
  const [searchLanguages, setSearchLanguages] = useState([]);
  const [searchLanguagesNotListed, setSearchLanguagesNotListed] = useState([]);
  const [tinyTagGroups, setTinyTagGroups] = useState([]);
  const [tinyTags, setTinyTags] = useState([]);

  //initialize
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  }, []); ///set default position to user's location

  useEffect(() => {
    if (
      bigTags?.length > 0 &&
      (tinyTags?.length > 0 ||
        tinyTagGroups.some((obj) => obj.tags && obj.tags.length > 0)) &&
      searchLanguages?.length > 0 &&
      !searchLanguages.includes('select') &&
      !eventSearchOnlineCountries.includes('select') &&
      coords
    ) {
      setAttendeeSignUpValid(true);
    } else {
      setAttendeeSignUpValid(false);
    }
    /*eslint-disable-next-line*/
  }, [bigTags, tinyTags, coords, searchLanguages]); //valid data for continue

  useEffect(() => {
    if (!coords && placeCoordinates?.lat && placeCoordinates?.lng) {
      let newCoords = [placeCoordinates?.lat, placeCoordinates?.lng];
      setCoords(newCoords);
    }
    /*eslint-disable-next-line*/
  }, [placeCoordinates]);

  //api
  async function formatAttendeeDataThenSave() {
    let newDistance;
    if (distanceMeasurement === 'km') {
      newDistance = distanceFilter * 1000;
    } else {
      newDistance = parseInt(distanceFilter * 1609.344);
    }

    if (!coords[0] || !coords[1]) {
      handleError({
        message: 'dataNotFound',
        id: Date.now(),
        origin: 'AttendeeSignUp/formatAttendeeDataThenSave',
      });
    }

    let backendGeoPosition = {
      type: 'Point',
      coordinates: [coords[0], coords[1]],
    };

    let newData = {
      attendee: {
        attendeeModeActive: true,
        eventSearchOnlineCountries,
        distanceFilter: newDistance,
        distanceMeasurement,
        geoPosition: backendGeoPosition,
        searchLanguages,
        searchLanguagesNotListed,
        tags: {
          bigTags,
          tinyTags,
          tinyTagGroups,
        },
      },
    };
    await saveAccountChange(newData, 'attendeeSignUp');
  }

  //functions
  const success = (position) => {
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setPlaceCoordinates(currentPosition);
  };

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    setCoords(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  function setNewSearchLanguages(arr) {
    if (arr?.includes('notListed')) {
      if (searchLanguagesNotListed?.length === 0) {
        setSearchLanguagesNotListed([
          {
            id: uuidv4(),
            language: '',
          },
        ]);
      }
    } else {
      setSearchLanguagesNotListed([]);
    }

    setSearchLanguages(arr);
  } //provides formatting for notListed.

  return (
    <>
      <label
        className="content-heading-styled text-gray flex-row"
        tabIndex="0"
        id="attendeeSignUpLabel"
      >
        {AttendeeSignUp.attendeeSignUp}
      </label>
      <div className="h12" />

      <ul className="mrg-l12 fs18">
        <li>
          <p className="description-text" tabIndex="0">
            {AttendeeSignUp.instructions}
          </p>
        </li>
      </ul>

      <div className="h36" />
      <div className="border-reg pad-24 br--standard" id="fieldset-access">
        <ButtonMeta
          handleClick={formatAttendeeDataThenSave}
          btnText={Generic.complete}
          ariaLabel={Generic.complete}
          icon={save}
          iconAlt={IconAlts.iconSave}
          unavailable={!attendeeSignUpValid}
          availabilityToggle={true}
          isLoading={loadingSaveBtnUI}
          customClassName={'mrg-auto-lr mrg-b12'}
        />

        <div className="h12" />
        <label
          aria-label={PersonalSearchSettings.ariaBigTags}
          className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
          id="bigTagsLabel"
          tabIndex="0"
        >
          <div className="flex-row align-center mrg-auto-right">
            <img
              src={BigTagIcon}
              alt={IconAlts.iconBigTag}
              style={{ height: '22px' }}
              className="mrg-r12"
            />
            <p>{PersonalSearchSettings.bigTagsLabel}</p>
          </div>

          <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
            <p className="fs14">1 {Generic.required}</p>
            {bigTags?.length > 0 && (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>

        <div className="h12" />
        <p
          className="description-text"
          tabIndex="0"
          aria-label={PersonalSearchSettings.ariaBigTagsInputInstructions}
        >
          {PersonalSearchSettings.bigTagsDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={`${Generic.add} ${Generic.bigTags}`}
          DataOpts={BigTagsOptsWithSubText}
          DataOptsSubTextTranslations={BigTagsOpts}
          fullWidth={true}
          iconModal={bigtag_nobg}
          iconOff={true}
          limit={20}
          modalTitle={Generic.bigTags}
          selectionState={bigTags}
          setSelectionState={setBigTags}
          subTextOn={true}
          iconModalClass={'bT'}
          id={'bigTagSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaTinyTags}
          className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
          id="tinyTagsLabel"
          tabIndex="0"
        >
          <div className="flex-row align-center mrg-auto-right">
            <img
              src={TinyTagIcon}
              alt={IconAlts.iconTinyTag}
              style={{ height: '28px' }}
              className="mrg-r12"
            />
            <p>{PersonalSearchSettings.tinyTagsLabel}</p>
          </div>

          <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
            <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
            {(tinyTags?.length > 0 ||
              tinyTagGroups.some((obj) => obj.tags && obj.tags.length > 0)) && (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.tinyTagsDescription}
        </p>
        <div className="h24" />
        <CustomTagsList
          id={'tinyTags'}
          limit={200}
          tinyTags={tinyTags}
          setTinyTags={setTinyTags}
          twoTagRows={true}
          tinyTagGroups={tinyTagGroups}
          setTinyTagGroups={setTinyTagGroups}
          bigTags={bigTags}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaEventSearchInPerson}
          className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
          id="searchLocationLabel"
          tabIndex="0"
        >
          <div className="flex-row align-center mrg-auto-right">
            <img
              src={img_search_location}
              alt={IconAlts.iconLocationPin}
              style={{ height: '32px' }}
              className="mrg-r12 filter-theme"
            />
            <p>{PersonalSearchSettings.eventSearchInPerson}</p>
          </div>

          <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
            <p className="fs14 mrg-auto-left">{Generic.required}</p>
            {distanceMeasurement && placeCoordinates && (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            )}
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.eventSearchInPersonDescription}
        </p>
        <div className="h24" />
        {placeCoordinates && (
          <MapContainerWithDistanceFilter
            distanceFilter={distanceFilter}
            distanceMeasurement={distanceMeasurement}
            handler={setCoords}
            loadTrigger={true}
            loadValue={coords}
            placeCoordinates={placeCoordinates}
          />
        )}
        <div className="h24" />
        <AutocompletePlacesNoAddress
          setAutocompleteCoordinates={setAutocompleteCoordinates}
        />
        <div className="h24" />
        <DistanceInput
          distanceFilter={distanceFilter}
          setDistanceFilter={setDistanceFilter}
          distanceMeasurement={distanceMeasurement}
          setDistanceMeasurement={setDistanceMeasurement}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaEventSearchOnline}
          className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
          id="eventSearchOnlineLabel"
          tabIndex="0"
        >
          <div className="flex-row align-center mrg-auto-right">
            <img
              src={img_country}
              alt={IconAlts.iconFlag}
              style={{ height: '26px' }}
              className="mrg-r12 filter-theme"
            />
            <p>{PersonalSearchSettings.eventLimitOnlineSearches}</p>
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.eventSearchOnlineDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={PersonalSearchSettings.addCountries}
          DataOpts={CountriesOpts}
          fullWidth={true}
          iconModal={img_location}
          iconModalClass={'filter-theme'}
          iconOff={true}
          modalTitle={PersonalSearchSettings.eventLimitOnlineSearches}
          selectionState={eventSearchOnlineCountries}
          setSelectionState={setEventSearchOnlineCountries}
          subTextOn={false}
          id={'locationCountrySelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />
        <div className="h36" />

        <label
          aria-label={PersonalSearchSettings.ariaSearchLanguages}
          className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
          id="searchLanguagesLabel"
          tabIndex="0"
        >
          <div className="flex-row align-center mrg-auto-right">
            <img
              src={img_language}
              alt={IconAlts.iconLocationPin}
              style={{ height: '26px' }}
              className="mrg-r12 filter-theme"
            />
            <p>{PersonalSearchSettings.searchLanguagesLabel}</p>
          </div>

          <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
            {searchLanguages?.length >= 2 &&
            searchLanguages.includes('select') ? (
              <p className="fs14 mrg-auto-left">{Generic.incomplete}</p>
            ) : (
              <p className="fs14 mrg-auto-left">1 {Generic.required}</p>
            )}

            {searchLanguages?.length > 0 &&
            !searchLanguages.includes('select') ? (
              <img
                src={checkmark_green}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6"
              />
            ) : searchLanguages?.length >= 2 &&
              searchLanguages.includes('select') ? (
              <img
                src={cancel}
                alt={IconAlts.iconCheckmark}
                className="mrg-l6 filter-red"
              />
            ) : null}
          </div>
        </label>
        <div className="h12" />
        <p className="description-text" tabIndex="0">
          {PersonalSearchSettings.searchLanguagesDescription}
        </p>
        <div className="h24" />
        <SelectionsModal
          addButtonText={PersonalSearchSettings.addLanguages}
          DataOpts={LanguageSimpleOpts}
          fullWidth={true}
          iconModal={img_language}
          iconModalClass={'filter-theme'}
          iconOff={true}
          modalTitle={PersonalSearchSettings.searchLanguagesLabel}
          selectionState={searchLanguages}
          setSelectionState={setNewSearchLanguages}
          subTextOn={false}
          id={'searchLanguagesSelections'}
          itemButtonCustomWrapper={'item-button-full'}
        />

        <LanguageNotListed
          languageNotListed={searchLanguagesNotListed}
          setLanguagesNotListed={setSearchLanguagesNotListed}
          languages={searchLanguages}
          setLanguages={setNewSearchLanguages}
          customWrapper={'mrg-t24'}
          TextAreaOnBlurHandlerOff={true}
        />
      </div>
    </>
  );
};
export default AttendeeSignUp;

import { useState, useEffect } from 'react';

//Hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useGenericModal } from '../../../../context/GenericModalProvider';
import { useTranslationRoomSocket } from '../../../../context/sockets/main/TranslationRoomSocketProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';

//Utility
import {
  arrow_circular_hallow,
  cancel,
  img_empty_box,
  img_folder_closed,
  img_folder_open,
  img_language,
  icon_profile,
} from '../../../../assets/icons';

const ImportTranslationsRoomDataModal = ({ handler, sessionDraftsOnly }) => {
  //Hooks
  const { handleCloseModal, setCloseModalFocusId } = useGenericModal();
  const { handleMobileTap } = useSettings();
  const { savedTranslationsRoomData } = useTranslationRoomSocket();

  //language
  const { Generic, IconAlts, ImportEventDataModal } = useLanguageComponents();

  //Component state
  const [openedEventsFolder, setOpenedEventsFolder] = useState();
  const [filteredList, setFilteredList] = useState([]);
  const [parentFolderSelection, setParentFolderSelection] = useState();

  //UI state
  const [tapHighlightExitBtn, setTapHighlightExitBtn] = useState(false);
  const [tapHighlightSelectedKey, setTapHighlightSelectedKey] = useState();

  //Initialize functions
  useEffect(() => {
    setCloseModalFocusId('translationsRoomLoadBtn');
    document.getElementById(`importTranslationsRoomDataLabel`).focus();
  }, []);

  //Component functions
  useEffect(() => {
    if (openedEventsFolder === 'draft') {
      setFilteredList(savedTranslationsRoomData.drafts);
    }
    if (openedEventsFolder === 'active') {
      setFilteredList(savedTranslationsRoomData.events);
    }
    if (openedEventsFolder === 'scheduled') {
      setFilteredList(savedTranslationsRoomData.scheduledEvents);
    }
    if (openedEventsFolder === 'archived') {
      setFilteredList(savedTranslationsRoomData.archived);
    }
    if (!openedEventsFolder) {
      setFilteredList();
    }

    if (parentFolderSelection === 'translationsSessions') {
      setFilteredList(savedTranslationsRoomData.translationsSessions);
    }
  }, [openedEventsFolder, parentFolderSelection]);

  return (
    <div
      className="modal-basic__wrapper access-ow access-o12"
      tabIndex="0"
      id="importTranslationsRoomData"
    >
      <div className="heading">
        <div className="flex-row flex-center">
          <label
            className="fs18 fwsb access-ob access-o6"
            tabIndex="0"
            id={`importTranslationsRoomDataLabel`}
          >
            {ImportEventDataModal.savedData}
          </label>
        </div>
        <button
          className={`close-btn highlight-i-lgr access-ob ${
            tapHighlightExitBtn ? 'filter-img-red' : 'filter-img-lightgray'
          } `}
          onClick={() =>
            handleMobileTap(
              [
                () => setTapHighlightExitBtn(true),
                () => setTapHighlightExitBtn(false),
              ],
              [() => handleCloseModal()]
            )
          }
          type="button"
          title={Generic.close}
        >
          <img src={cancel} alt={IconAlts.iconClose} className="svg" />
        </button>
      </div>

      <div className="modal-basic__container list-style-none hide-scrollbar">
        {!sessionDraftsOnly && (
          <button
            onClick={() => {
              if (parentFolderSelection === 'myndfullEvents') {
                setParentFolderSelection();
              } else {
                setParentFolderSelection('myndfullEvents');
              }
            }}
            aria-pressed={parentFolderSelection === 'myndfullEvents'}
            tabIndex="0"
            className="access-ob"
          >
            <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
              {parentFolderSelection === 'myndfullEvents' ? (
                <img
                  src={img_folder_open}
                  className="filter-theme mrg-r12 svg"
                  alt={IconAlts.iconFolder}
                  style={{ height: '32px', width: '48px' }}
                />
              ) : (
                <img
                  src={img_folder_closed}
                  className="filter-theme mrg-r12 svg"
                  alt={IconAlts.iconFolder}
                  style={{ height: '32px', width: '48px' }}
                />
              )}
              <p className="mrg-t2">{ImportEventDataModal.myndfullEvents}</p>
            </div>
          </button>
        )}

        {parentFolderSelection === 'myndfullEvents' && (
          <div className="flex-column mrg-l24">
            <button
              onClick={() => {
                if (openedEventsFolder === 'draft') {
                  setOpenedEventsFolder();
                } else {
                  setOpenedEventsFolder('draft');
                }
              }}
              aria-pressed={openedEventsFolder === 'draft'}
              tabIndex="0"
              className="access-ob"
            >
              <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
                {openedEventsFolder === 'draft' ? (
                  <img
                    src={img_folder_open}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                ) : (
                  <img
                    src={img_folder_closed}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                )}
                <p className="mrg-t2">
                  {ImportEventDataModal.eventDrafts} (
                  {savedTranslationsRoomData?.drafts?.length})
                </p>
              </div>
            </button>
            <button
              onClick={() => {
                if (openedEventsFolder === 'active') {
                  setOpenedEventsFolder();
                } else {
                  setOpenedEventsFolder('active');
                }
              }}
              aria-pressed={openedEventsFolder === 'active'}
              tabIndex="0"
              className="access-ob"
            >
              <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
                {openedEventsFolder === 'active' ? (
                  <img
                    src={img_folder_open}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                ) : (
                  <img
                    src={img_folder_closed}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                )}
                <p className="mrg-t2">
                  {ImportEventDataModal.activeEvents} (
                  {savedTranslationsRoomData?.events?.length})
                </p>
              </div>
            </button>
            <button
              onClick={() => {
                if (openedEventsFolder === 'scheduled') {
                  setOpenedEventsFolder();
                } else {
                  setOpenedEventsFolder('scheduled');
                }
              }}
              aria-pressed={openedEventsFolder === 'scheduled'}
              tabIndex="0"
              className="access-ob"
            >
              <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
                {openedEventsFolder === 'scheduled' ? (
                  <img
                    src={img_folder_open}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                ) : (
                  <img
                    src={img_folder_closed}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                )}
                <p className="mrg-t2">
                  {ImportEventDataModal.scheduledEvents} (
                  {savedTranslationsRoomData?.scheduledEvents?.length})
                </p>
              </div>
            </button>
            <button
              onClick={() => {
                if (openedEventsFolder === 'archived') {
                  setOpenedEventsFolder();
                } else {
                  setOpenedEventsFolder('archived');
                }
              }}
              aria-pressed={openedEventsFolder === 'archived'}
              tabIndex="0"
              className="access-ob"
            >
              <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
                {openedEventsFolder === 'archived' ? (
                  <img
                    src={img_folder_open}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                ) : (
                  <img
                    src={img_folder_closed}
                    className="filter-theme mrg-r12 svg"
                    alt={IconAlts.iconFolder}
                    style={{ height: '24px', width: '48px' }}
                  />
                )}
                <p className="mrg-t2">
                  {ImportEventDataModal.archivedEvents} (
                  {savedTranslationsRoomData?.archived?.length})
                </p>
              </div>
            </button>
          </div>
        )}

        <button
          onClick={() => {
            if (parentFolderSelection === 'translationsSessions') {
              setOpenedEventsFolder();
              setParentFolderSelection();
            } else {
              setOpenedEventsFolder();
              setParentFolderSelection('translationsSessions');
            }
          }}
          aria-pressed={parentFolderSelection === 'translationsSessions'}
          tabIndex="0"
          className="access-ob"
        >
          <div className="flex-row flex-start full-width fs21 fwn align-center pad-12">
            {parentFolderSelection === 'translationsSessions' ? (
              <img
                src={img_folder_open}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            ) : (
              <img
                src={img_folder_closed}
                className="filter-theme mrg-r12 svg"
                alt={IconAlts.iconFolder}
                style={{ height: '32px', width: '48px' }}
              />
            )}
            <p className="mrg-t2">{ImportEventDataModal.sessionDrafts}</p>
          </div>
        </button>

        <div className="divider mrg-t12 mrg-b24 opacity-05" />

        <ul
          className="import-events-data hide-scrollbar pad-l12 pad-r12  overflow-y-scroll overflow-x-hidden list-style-none"
          id="selectDropdownList"
        >
          {filteredList?.length > 0 ? (
            <>
              {filteredList?.map((ev, index) => {
                const eventId = ev._id;
                const isLastItem = filteredList?.length === index + 1;

                return (
                  <li
                    key={`importEventData-${index}`}
                    style={
                      isLastItem
                        ? { marginBottom: '160px' }
                        : { marginBottom: '12px' }
                    }
                    className="flex-column mrg-t6"
                  >
                    <button
                      className={`item fs16 fwsb access-ob ${
                        tapHighlightSelectedKey === eventId
                          ? 'item-selected'
                          : ''
                      }`}
                      key={`list-item-${index}`}
                      tabIndex="0"
                      onClick={() =>
                        handleMobileTap(
                          [
                            () => setTapHighlightSelectedKey(eventId),
                            () => setTapHighlightSelectedKey(),
                          ],
                          [
                            () => handler(eventId, openedEventsFolder),
                            () => handleCloseModal(),
                          ]
                        )
                      }
                      type="button"
                      aria-label={
                        openedEventsFolder === 'draft' ||
                        parentFolderSelection === 'translationsSessions'
                          ? ev.meta?.saveName
                          : ev.generic?.title
                      }
                      id={`list-item-${index}`}
                    >
                      {openedEventsFolder === 'draft' ||
                      parentFolderSelection === 'translationsSessions'
                        ? ev.meta?.saveName
                        : ev.generic?.title}

                      <div className="flex-row full-width align-center space-evenly mrg-auto-top mrg-auto-bottom mrg-tb12">
                        <div className="flex-row align-center">
                          <img
                            src={icon_profile}
                            alt={IconAlts.iconLanguage}
                            className="filter-theme"
                            style={{ height: '24px' }}
                          />
                          <p className="mrg-l6 fs18">
                            {ev.participants?.length}
                          </p>
                        </div>

                        <div className="flex-row align-center">
                          <img
                            src={img_language}
                            alt={IconAlts.iconLanguage}
                            className="filter-theme"
                          />
                          <p className="mrg-l6 fs18">
                            {ev.translations.roomLanguages?.length}
                          </p>
                        </div>
                      </div>
                    </button>
                  </li>
                );
              })}
            </>
          ) : openedEventsFolder ||
            parentFolderSelection === 'translationsSessions' ? (
            <div className="flex-row align-center justify-center">
              <img
                src={img_empty_box}
                alt={IconAlts.iconEmptyBox}
                className="mrg-r12 filter-gray svg"
                style={{ height: '28px' }}
              />
              <p className="fs18 fwn">{ImportEventDataModal.emptyFolder}</p>
            </div>
          ) : (
            <div className="flex-row align-center justify-center">
              <img
                src={arrow_circular_hallow}
                alt={IconAlts.iconArrow}
                className="mrg-r12 filter-gray svg"
                style={{ height: '28px' }}
              />
              <p className="fs18 fwn">{ImportEventDataModal.selectFolder}</p>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ImportTranslationsRoomDataModal;

import { useState, useEffect, useRef } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useGenericModal } from '../../context/GenericModalProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//Components
import CountryCodesModal from '../Modal/ModalComponents/ListModals/CountryCodesModal';

//Utility
import { unknown_flag } from '../../assets/icons';
import GetFlag from '../../utils/images/GetFlag';
import handleInputClick from './handleInputClick';

const PhoneInput = ({
  asterisk,
  customWrapper,
  customWidth,
  id,
  phoneNumber,
  setPhoneNumber,
  countryCode,
  setCountryCode,
  setPhoneDataValid,
}) => {
  //Hooks
  const { dismissKeypad, handleMobileTap } = useSettings();
  const { setGenericModal, genericModal } = useGenericModal();

  //language
  const { PhoneNumberInput, IconAlts } = useLanguageComponents();

  //Component state
  const [checkRan, setCheckRan] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);

  //UI state
  const [flag, setFlag] = useState();
  const [listOpened, setListOpened] = useState(false);

  const [activeWarning, setActiveWarning] = useState(false);
  const [focusHighlightInput, setFocusHighlightInput] = useState(false);
  const [borderHighlightInput, setBorderHighlightInput] = useState(false);
  const [focusHighlightBtn, setFocusHighlightBtn] = useState(false);

  //Variables
  const phoneInput = useRef();

  //Initialize & UI
  useEffect(() => {
    setFlag(GetFlag(countryCode));
  }, [countryCode]);

  useEffect(() => {
    if (genericModal) {
      setListOpened(true);
    }
    if (!genericModal && listOpened) {
      setListOpened(false);
    }
  }, [genericModal]); //country code, sets focus back on See Full List btn after full list modal closes

  function handleOpenCountryCodeList(e) {
    e.preventDefault();
    setListOpened(true);
    setGenericModal(
      <CountryCodesModal
        countryCode={countryCode}
        setCountryCode={setCountryCode}
      />
    );
  }

  function handleOpenCountryCodeModal(e) {
    e.preventDefault();
    handleMobileTap(
      [() => setFocusHighlightBtn(true), () => setFocusHighlightBtn(false)],
      [() => handleOpenCountryCodeList(e)]
    );
  }

  //Component functions
  useEffect(() => {
    validation(phoneNumber);
  }, [countryCode]);

  //Component functions
  function validation(value) {
    if (value?.length === 0 && !countryCode) {
      setInvalidPhoneNumber(false);
      if (setPhoneDataValid) {
        setPhoneDataValid(true);
      }
    } else if (value?.length === 14 && countryCode) {
      setInvalidPhoneNumber(false);
      if (setPhoneDataValid) {
        setPhoneDataValid(true);
      }
    } else {
      setInvalidPhoneNumber(true);
      if (setPhoneDataValid) {
        setPhoneDataValid(false);
      }
    }
  }

  function handlePhoneInput(phoneNumber) {
    let num = phoneNumber;
    let filteredNum = num.replace(/\D/g, '');
    let size = filteredNum.length;
    if (size > 0) {
      filteredNum = '(' + filteredNum;
    }
    if (size > 3) {
      filteredNum = filteredNum.slice(0, 4) + ') ' + filteredNum.slice(4, 11);
    }
    if (size > 6) {
      filteredNum = filteredNum.slice(0, 9) + '-' + filteredNum.slice(9);
    }
    validation(filteredNum);
    setPhoneNumber(filteredNum);
  }

  useEffect(() => {
    if (checkRan) {
      if (!invalidPhoneNumber && activeWarning) {
        const timer = setTimeout(() => {
          setActiveWarning(false);
        }, 500);
        return () => clearTimeout(timer);
      }

      let invalidEmailAddressTimer;

      if (!invalidPhoneNumber) clearTimeout(invalidEmailAddressTimer);

      if (invalidPhoneNumber) {
        invalidEmailAddressTimer = setTimeout(() => {
          setActiveWarning(true);
        }, 1000);

        return () => clearTimeout(invalidEmailAddressTimer);
      }
    }
  }, [invalidPhoneNumber, checkRan]); //this timer creates a short delay to give the user a chance to provide valid email and not render the warning text

  return (
    <div className="input-error__wrapper">
      <div
        className={`text-input__wrapper ${customWrapper ? customWrapper : ''} `}
        style={
          customWidth
            ? { width: `${customWidth}`, maxWidth: `${customWidth}` }
            : null
        }
      >
        <div className="flex-row">
          <button
            className={`text-input-button mrg-auto-top mrg-auto-bottom fs16 fwn mrg-r12 ${
              focusHighlightBtn
                ? 'highlight-ob-theme--thin--active'
                : 'highlight-ob-theme--thin'
            }
            ${
              invalidPhoneNumber && activeWarning && checkRan
                ? 'text-input-warning'
                : ''
            }
            `}
            onClick={(e) => handleOpenCountryCodeModal(e)}
            id="countryCodeOpenListBtn"
          >
            <div className="flex-column flex-center">
              {countryCode ? (
                <>{flag}</>
              ) : (
                <img alt={IconAlts.iconFlag} src={unknown_flag} />
              )}
            </div>
          </button>
          <div
            className="text-input__wrapper"
            onMouseEnter={() => setBorderHighlightInput(true)}
            onMouseLeave={() => setBorderHighlightInput(false)}
          >
            <label
              id={`${id}-label`}
              className="text-input-label no-select"
              onClick={() => handleInputClick(phoneInput.current)}
            >
              <p>
                {PhoneNumberInput.phoneNumber}&nbsp;
                {asterisk && <span className="highlight">*</span>}
              </p>
            </label>

            <textarea
              id={id}
              type="text"
              ref={phoneInput}
              inputMode="numeric"
              placeholder="(xxx) xxx-xxxx"
              aria-label={PhoneNumberInput.ariaInstructions}
              enterKeyHint="done"
              maxLength="14"
              value={phoneNumber}
              className={`text-input textarea--phone  ${
                borderHighlightInput ? 'highlight-o-theme--tap' : ''
              }
              ${
                focusHighlightInput
                  ? 'highlight-ob-theme--thin--active'
                  : 'highlight-ob-theme--thin'
              }  
              ${
                invalidPhoneNumber && activeWarning && checkRan
                  ? 'text-input-warning'
                  : ''
              }  `}
              onBlur={(e) => {
                if (e.target.value?.length > 0 || countryCode) {
                  validation(e.target.value);
                }
                setCheckRan(true);
                setFocusHighlightInput(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setFocusHighlightInput(false);
                  dismissKeypad(e);
                }
              }}
              onChange={(e) => {
                handlePhoneInput(e.target.value);
              }}
              onFocus={() => setFocusHighlightInput(true)}
            />
          </div>
        </div>
      </div>
      <p
        className={`input-error access-ob ${
          invalidPhoneNumber && activeWarning && checkRan
            ? 'input-error-animation'
            : activeWarning && checkRan
            ? 'input-error-hide'
            : ''
        }`}
        tabIndex={invalidPhoneNumber ? '0' : '-1'}
      >
        {PhoneNumberInput.phoneNumberInvalid}
      </p>
    </div>
  );
};

export default PhoneInput;

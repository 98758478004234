//components
import ButtonOrder from '../ButtonOrder/ButtonOrder';
import SelectDropdown from '../SelectDropdown/SelectDropdown';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

const SortingComponent = ({
  sortOrder,
  setSortOrder,
  sortingType,
  setSortingType,
  sortingOpts,
  customWrapper,
  dropdDownId,
}) => {
  //language
  const { SortingComponent } = useLanguageComponents();

  return (
    <div>
      <div
        className={`flex-row flex-column--ph mrg-tb24 align-center ${
          customWrapper ? customWrapper : ''
        }`}
        aria-label={SortingComponent.ariaLabel}
      >
        <p className="fs21 mrg-b24--ph no-wrap mrg-r12">
          {SortingComponent.sortBy}:
        </p>
        <div className="flex-row flex-center full-width ">
          <SelectDropdown
            id={dropdDownId}
            list={sortingOpts}
            handleSelection={setSortingType}
            internalLabel={SortingComponent.sortBy}
            ariaTitle={SortingComponent.sortBy}
            autoUpdate={true}
            defaultKey={sortingType}
          />

          <ButtonOrder
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
            customWrapper={'mrg-l24'}
          />
        </div>
      </div>
    </div>
  );
};

export default SortingComponent;

//hooks

import useLanguageData from '../../../../../../language/useLanguageData';

//components
//utils

const InfoCardContainerEventGeneric = ({ eventGenericData }) => {
  //hooks
  const { EventTypes, AudienceTypes } = useLanguageData();

  return (
    <div className="support-ticket__container">
      <p className="fs21 fwsb mrg-b6">Generic</p>
      <div className="mrg-t6">Title: {eventGenericData.title}</div>
      <div className="mrg-t6">
        Audience: {AudienceTypes[eventGenericData?.audienceType]}
      </div>
      <div className="mrg-t6">
        Event Type: {EventTypes[eventGenericData?.eventType]}
      </div>
      <div className="mrg-t6">Description: {eventGenericData.description}</div>

      {eventGenericData?.agenda?.items?.length > 0 && (
        <div>
          Agenda:
          <div className="flex-column">
            {eventGenericData?.agenda?.format}
            {eventGenericData?.agenda?.items?.map((item, index) => (
              <div key={`agenda-item-${item.id}`}>
                <div>Item {index}:</div>
                <div>Value: {item.value}</div>
                <div>Start Time: {item.startTime}</div>
                <div>End Time: {item.endTime}</div>
              </div>
            ))}
          </div>
        </div>
      )}

      <p className="fs18 fwsb mrg-t24">Accessibility: </p>
      <div className="mrg-t6">
        Wheelchair Accessibility:
        {eventGenericData.wheelchairAccessibility ? ' True' : ' False'}
      </div>
      <div className="mrg-t6">
        Sign Language Interpreter:
        {eventGenericData.signLanguageInterpreter ? ' True' : ' False'}
      </div>
      <div className="mrg-t6">
        Accessibility Notes:
        {eventGenericData.accessibilityNotes?.length > 0
          ? eventGenericData.accessibilityNotes
          : ' N/A'}
      </div>
    </div>
  );
};

export default InfoCardContainerEventGeneric;

//Language
import useLanguageLayout from '../language/useLanguageLayout';

const PageNotFound = () => {
  //Language
  const { App } = useLanguageLayout();

  return (
    <div className="layout">
      <div className="panel-container bg-blur">
        <div className="panel mrg-auto flex-center">
          <h1>{App.pageNotFound}</h1>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;

import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../context/SettingsProvider';
//language
import useLanguageLayout from '../../language/useLanguageLayout';

//components
import ExploreFeatures from './ExploreFeatures';
import ExploreHeading from './ExploreHeading';
import ExploreMyndFullIntroduction from './ExploreMyndFullIntroduction';
import ExploreCommunity from './ExploreCommunity';
import ExploreMyndFullBanner from './ExploreMyndFullBanner';
import ExploreTranslations from './ExploreTranslations';
import ExploreAccessibility from './ExploreAccessibility';
import ExploreMore from './ExploreMore';

//utils
import ScrollTo from '../../utils/UI/ScrollTo';

const ExploreMain = () => {
  //hooks
  const { setNavbar } = useSettings();

  //language
  const { ExploreMyndFull } = useLanguageLayout();

  //state
  const [exploreHeadingIsInitialized, setExploreHeadingIsInitialized] =
    useState(false);

  useEffect(() => {
    setNavbar('');
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <div className="layout">
      <div
        className={`explore__wrapper ${
          exploreHeadingIsInitialized ? 'opacity-1' : 'opacity-0'
        }`}
      >
        <ExploreHeading
          ExploreMyndFull={ExploreMyndFull}
          setExploreHeadingIsInitialized={setExploreHeadingIsInitialized}
        />
        <ExploreMyndFullIntroduction ExploreMyndFull={ExploreMyndFull} />
        <ExploreCommunity ExploreMyndFull={ExploreMyndFull} />
        <ExploreMyndFullBanner ExploreMyndFull={ExploreMyndFull} />
        <ExploreFeatures />
        <ExploreTranslations />
        <ExploreAccessibility />
        <ExploreMore />
      </div>
    </div>
  );
};

export default ExploreMain;

import { useSettings } from '../../context/SettingsProvider';

const useLanguageAuth = () => {
  const { contentLanguage } = useSettings();

  const Auth = () => {
    let translation;

    if (contentLanguage === 'en-CA') {
      translation = {
        emailAddress: 'Email Address',
        password: 'Password',
        signIn: 'Sign In',
        signInWithEmail: 'Sign in with email',
        publicDevice: 'Public Device',
        privateDevice: 'Personal Device',
        lock: 'lock',
        installApp: 'Install App',
        forgotPassword: 'Forgot Password?',
        dontHaveAnAccount: `Don't have an account? Sign up`,
        findYourAccount: 'Find your account',
        foundAccount: 'We found this information associated with your account.',
        requestAssistance: 'Request assistance',
        emailLink: 'Email a link to',
        checkEmail: 'Please check your email',
        checkEmailText1: 'Click the reset password link that was sent to ',
        checkEmailText2:
          'If you cannot find the email, please check your spam filters and ',
        resendEmail: 'resend the email.',
        resetYourPassword: 'Reset your password',
        passwordChangeSuccessful: 'Password change successful!',
        continueToMyndFull: 'Continue to MyndFull',
        ariaFoundAccount: 'Account found',
        ariaSendEmail: 'Send reset link to account.',
        invalidLink: 'Invalid Link',
        incorrectResetLink:
          'Your reset password link is incorrect or it has expired.',
        goToForgotPassword: 'Go to Forgot Password',
        legend: {
          publicDevice: `Sign in button. If you are using a public device, please check the public device checkbox below the sign in button. This setting ensures you are logged out if you close the browser tab or change domains.`,
          emailRequired: 'Email required.',
          passwordRequired: 'Password required',
        },
      };
    }

    if (contentLanguage === 'fr-CA') {
      translation = {
        emailAddress: 'Adresse courriel',
        password: 'Mot de passe',
        signIn: 'Se connecter',
        signInWithEmail: 'Connectez-vous avec e-mail',
        publicDevice: 'Appareil public',
        privateDevice: 'Appareil personnel',
        lock: 'serrure',
        installApp: `Installer l'application`,
        forgotPassword: `Mot de passe oublié?`,
        dontHaveAnAccount: `Vous n'avez pas de compte ? S'inscrire`,
        findYourAccount: 'Trouvez votre compte',
        foundAccount: `Nous avons trouvé ces informations associées à votre compte.`,
        requestAssistance: `Demander de l'aide`,
        emailLink: 'Envoyer un lien par e-mail à',
        checkEmail: 'Merci de consulter vos emails',
        checkEmailText1:
          'Cliquez sur le lien de réinitialisation du mot de passe qui a été envoyé à ',
        checkEmailText2: `Si vous ne trouvez pas l'e-mail, veuillez vérifier vos filtres anti-spam et `,
        resendEmail: `renvoyer l'email.`,
        resetYourPassword: 'Réinitialisez votre mot de passe',
        passwordChangeSuccessful: 'Changement de mot de passe réussi !',
        continueToMyndFull: 'Continuer vers MyndFull',
        ariaFoundAccount: 'Compte trouvé',
        ariaSendEmail: 'Envoyer le lien de réinitialisation au compte.',
        invalidLink: 'Lien invalide',
        incorrectResetLink:
          'Votre lien de réinitialisation du mot de passe est incorrect ou il a expiré.',
        goToForgotPassword: 'Allez sur Mot de passe oublié',
        legend: {
          publicDevice: `Bouton de connexion. Si vous utilisez un appareil public, veuillez cocher la case Appareil public sous le bouton de connexion. Ce paramètre garantit que vous êtes déconnecté si vous fermez l'onglet du navigateur ou changez de domaine.`,
          emailRequired: 'Email requis.',
          passwordRequired: 'Mot de passe requis.',
        },
      };
    }
    return translation;
  };

  return {
    Auth: Auth(),
  };
};

export default useLanguageAuth;

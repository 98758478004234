import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ButtonSwitch from '../../../../../components/ButtonSwitch/ButtonSwitch';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import PasswordInput from '../../../../../components/TextArea/PasswordInput';

//utils
import { return_curved_arrow } from '../../../../../assets/icons';
import { image_lockscreen } from '../../../../../assets/images';

const CreateRoomPassword = () => {
  //hooks
  const {
    defaultAttendeePassword,
    attendeeJoinRoomPassword,
    setAttendeeJoinRoomPassword,
    joinRoomPasswordToggle,
    setJoinRoomPasswordToggle,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
  } = useTranslationRoomSocket();

  //language
  const { TranslationCreateRoomPassword } = useLanguageTranslationRoom();

  //UI
  const [renderSpinner, setRenderSpinner] = useState(false);
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  useEffect(() => {
    if (joinRoomPasswordToggle && defaultAttendeePassword?.length > 0) {
      setAttendeeJoinRoomPassword(defaultAttendeePassword);
    }
  }, [joinRoomPasswordToggle]);

  return (
    <div
      className={`translations__container translations__container--no-box-shadow ${
        !imgLoaded ? 'remove' : ''
      } `}
    >
      <img
        src={image_lockscreen}
        alt="img"
        style={{ height: '180px' }}
        className="svg"
        aria-hidden={true}
        onLoad={() => setImgLoaded(true)}
      />
      <div className="translations-selections mrg-t24">
        <p
          className="fs21 fwsb mrg-b24 text-center access-ob access-o6"
          tabIndex="0"
          aria-label={TranslationCreateRoomPassword.passwordOptions}
          id={'createRoom'}
        >
          {TranslationCreateRoomPassword.passwordOptions}
        </p>

        <ButtonSwitch
          id={'passwordToggle'}
          title={TranslationCreateRoomPassword.attendeesRequirePassword}
          handleSwitch={setJoinRoomPasswordToggle}
          loadValue={joinRoomPasswordToggle}
          loadTrigger={true}
          customWrapper={'mrg-auto-right'}
          horizontal={true}
        />
        {joinRoomPasswordToggle && (
          <PasswordInput
            handleInput={setAttendeeJoinRoomPassword}
            preventDefaultOff={true}
            id={'password'}
            resetTrigger={!joinRoomPasswordToggle}
            customWrapper={'full-width mrg-t24'}
            defaultValue={attendeeJoinRoomPassword}
          />
        )}

        <ItemButton
          text={TranslationCreateRoomPassword.continue}
          handler={() => handleCreatePageChange('createLanguages')}
          customWrapper={'full-width mrg-t24'}
          iconOn={true}
          icon={return_curved_arrow}
          iconCustomClass={'reverse'}
          iconStyle={{ height: '21px' }}
          unavailable={joinRoomPasswordToggle && !attendeeJoinRoomPassword}
        />

        <div className="divider full-width mrg-tb36" />

        <ItemButton
          text={TranslationCreateRoomPassword.back}
          handler={() => {
            handleCreatePageChange('createTitle');
          }}
          fullWidth={true}
        />
      </div>
    </div>
  );
};

export default CreateRoomPassword;

import { useEffect, useState } from 'react';

//hooks
import { useSettings } from '../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../language/useLanguageComponents';

//utils
import { dropdown_arrow } from '../../../assets/icons';

const ExploreFeaturesNavBtns = ({
  exploreFeature,
  currentIndex,
  setCurrentIndex,
}) => {
  //hooks
  const { handleMobileTap, width } = useSettings();

  //language
  const { IconAlts } = useLanguageComponents();

  //state
  const [indexTotal, setIndexTotal] = useState();
  const [prevButtonTap, setPrevButtonTap] = useState(false);
  const [nextButtonTap, setNextButtonTap] = useState(false);

  useEffect(() => {
    if (width > 1280) {
      if (exploreFeature === 'findEvents') {
        setIndexTotal(4);
      }
    } else {
      if (exploreFeature === 'findEvents') {
        setIndexTotal(7);
      }
    }

    if (width > 1280) {
      if (exploreFeature === 'organize') {
        setIndexTotal(3);
      }
    } else {
      if (exploreFeature === 'organize') {
        setIndexTotal(4);
      }
    }

    if (exploreFeature === 'speaker') {
      setIndexTotal(2);
    }
  }, [width, exploreFeature]);

  const goToNextSlide = () => {
    if (exploreFeature === 'findEvents') {
      if (width > 1280) {
        if (currentIndex < 3) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }

        if (currentIndex === 3) {
          setCurrentIndex(0);
        }
      } else {
        if (currentIndex < 6) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }

        if (currentIndex === 6) {
          setCurrentIndex(0);
        }
      }
    }

    if (exploreFeature === 'organize') {
      if (width > 1280) {
        if (currentIndex < 2) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }

        if (currentIndex === 2) {
          setCurrentIndex(0);
        }
      } else {
        if (currentIndex < 3) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }

        if (currentIndex === 3) {
          setCurrentIndex(0);
        }
      }
    }

    if (exploreFeature === 'speaker') {
      if (currentIndex === 0) {
        setCurrentIndex(1);
      } else {
        setCurrentIndex(0);
      }
    }
  };

  const goToPrevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div
      className={`flex-row flex-center pos-rel mrg-b36 ${
        width > 900 ? 'mrg-t48' : 'mrg-t24'
      }`}
    >
      <button
        className={`carousel-button carousel-button--left carousel-button--explore-scheme no-select access-ob access-o6 ${
          prevButtonTap ? 'carousel-button--tap' : ''
        }`}
        tabIndex="0"
        onClick={() =>
          handleMobileTap(
            [() => setPrevButtonTap(true), () => setPrevButtonTap(false)],
            [() => goToPrevSlide()]
          )
        }
      >
        <img
          src={dropdown_arrow}
          alt={IconAlts.iconArrow}
          className={`${
            prevButtonTap ? 'filter-darkgray' : 'filter-white'
          } rotate-cw90 svg`}
          style={{ height: '12px' }}
          aria-hidden={true}
        />
      </button>
      {exploreFeature && (
        <div className="explore-features-page-summary">
          <p className="access-ob access-o6" tabIndex="0">
            {currentIndex + 1} / {indexTotal}
          </p>
        </div>
      )}
      <button
        className={`carousel-button carousel-button--right carousel-button--explore-scheme no-select access-ob access-o6 ${
          nextButtonTap ? 'carousel-button--mobile--tap' : ''
        }`}
        onClick={() =>
          handleMobileTap(
            [() => setNextButtonTap(true), () => setNextButtonTap(false)],
            [() => goToNextSlide()]
          )
        }
      >
        <img
          src={dropdown_arrow}
          alt={IconAlts.iconArrow}
          className={`${
            nextButtonTap ? 'filter-darkgray' : 'filter-white'
          } rotate-ccw90 svg`}
          style={{ height: '12px' }}
          aria-hidden={true}
        />
      </button>
    </div>
  );
};

export default ExploreFeaturesNavBtns;

import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useAccountReduxHandlers } from '../../features/main/account/useAccountHandlers';
import { useAuthReduxHandlers } from '../../features/main/auth/useAuthHandlers';

//language
import useLanguageLayout from '../../language/useLanguageLayout';

//Components
import LoadBucketImage from '../../components/LoadBucketImage/LoadBucketImage';

//Utils
import { logOut, organize, search } from '../../assets/icons';

const NavLoggedIn = ({ currentRoles }) => {
  //Hooks
  const { navbar, setNavbar, setSettingsSetViewEventDetails } = useSettings();
  const { accountProfileImage } = useAccountReduxHandlers();
  const { handleUserLogout } = useAuthReduxHandlers();

  //language
  const { NavbarLoggedIn } = useLanguageLayout();

  //ui
  const [profileImage, setProfileImage] = useState(accountProfileImage);
  const [initialized, setInitialized] = useState(false);
  const [resetProfileImage, setRefreshProfileImage] = useState(false);

  //variables
  const prevImage = useRef();
  const imageRefreshTimer = useRef();

  useEffect(() => {
    if (!initialized && accountProfileImage) {
      setInitialized(true);
      prevImage.current = accountProfileImage;
      setProfileImage(accountProfileImage);
    }

    if (initialized) {
      if (accountProfileImage !== prevImage.current) {
        setRefreshProfileImage(true);

        imageRefreshTimer.current = setTimeout(() => {
          setProfileImage(accountProfileImage);
          setRefreshProfileImage(false);
          prevImage.current = accountProfileImage;
        }, 500);
      }
    }
  }, [accountProfileImage]);

  return (
    <div className="nav__links no-select">
      {currentRoles.includes(process.env.REACT_APP_ATTENDEE) && (
        <Link
          to={{ pathname: '/events' }}
          onClick={() => {
            setSettingsSetViewEventDetails();
            setNavbar('events');
          }}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img src={search} alt={NavbarLoggedIn.events} aria-hidden="true" />
            <span
              className={`${
                navbar === 'events' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          {NavbarLoggedIn.events}
        </Link>
      )}
      {currentRoles.includes(process.env.REACT_APP_ORGANIZER) && (
        <Link
          to={{ pathname: '/organize' }}
          onClick={() => {
            setSettingsSetViewEventDetails();
            setNavbar('organize');
          }}
          className="fs18 fwb color-white access-ob access-o6"
        >
          <div className="icon disable-select">
            <img
              aria-hidden="true"
              src={organize}
              alt={NavbarLoggedIn.organize}
              className="filter-white"
            />
            <span
              className={`${
                navbar === 'organize' ? 'active fadeIn' : 'inactive'
              }`}
            />
          </div>
          {NavbarLoggedIn.organize}
        </Link>
      )}
      <Link
        to={{ pathname: '/account' }}
        onClick={() => setNavbar('account')}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <div style={{ zIndex: '7' }}>
            {!resetProfileImage && (
              <LoadBucketImage
                imagePath={profileImage}
                imgClassName={'profile-image'}
                backupPath={'account_img_default.png'}
                partialPath={'myndfull_account_images'}
              />
            )}
          </div>
          <span
            className={`${navbar === 'account' ? 'active fadeIn' : 'inactive'}`}
            style={{ zIndex: '2' }}
          />
        </div>
        {NavbarLoggedIn.account}
      </Link>
      <Link
        to={{ pathname: '/' }}
        onClick={handleUserLogout}
        className="fs18 fwb color-white access-ob access-o6"
      >
        <div className="icon disable-select">
          <img aria-hidden="true" src={logOut} alt={NavbarLoggedIn.signOut} />
          <span
            className={`${navbar === 'logout' ? 'active fadeIn' : 'inactive'}`}
          />
        </div>
        {NavbarLoggedIn.signOut}
      </Link>
    </div>
  );
};

export default NavLoggedIn;

// {displaySocketSpinner && (
//   <img
//     alt="connection spinner"
//     src={img_refresh_two_circular}
//     className={`mrg-r12 filter-white socket-status-connecting`}
//     title={NavbarLoggedIn.attemptingToReconnect}
//   />
// )}

/*eslint-disable*/
import { useEffect, useState } from 'react';

//Hooks
import { useEventValidation } from '../useOrganizeHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import ItemButton from '../../../../components/ItemButton/ItemButton';
import ConfirmSelection from '../../../../components/ConfirmSelection/ConfirmSelection';
import ButtonMeta from '../../../../components/ButtonMeta/ButtonMeta';
import EditEventConflictsAndSaveConfirmation from '../edit/EditEventConflictsAndSaveConfirmation';
import ValidationsList from '../../../../components/ValidationsList/ValidationsList';

//Utility
import { deepEqual } from '../../../../utils/logic/deepEqual';
import {
  cancel,
  preview,
  refresh,
  return_curved_arrow,
  save,
} from '../../../../assets/icons';
import editEventConflictObjDefault from '../edit/editEventConflictObjDefault';

//note: for the change list, state that changes simultaneously needs to be handled in a single useEffect, otherwise race conditions will remove one of the update properties;
//ex. hostingOption while the event has maxRegistrations; hostingOption is added to changelist but then removed by maxReg change which happens simultaneously. fixed problem by re-addressing hostingOption within the maxReg useEffect.
//will want to refactor this code in a way that makes inter-dependencies more trackable.

const EditOverview = ({
  setEditEvent,
  editEventData,
  setEventPreview,
  setOrganizeIsLoading,
  submitIsLoading,
  organizeIsLoading,
  //change list
  changeList,
  pauseChangeList,
  setPauseChangeList,
  handleEditEventChangeList,
  //tracker state
  eventAdditionalChangeTracker,
  eventImageDisplayChangeTracker,
  participantChangeTracker,
  participantImagesChangeTracker,
  //actions
  handleRemoveEvent,
  handleUpdateEvent,
  handleEditEventReset,
  reset,
  handleReset,
  //meta
  eventId,
  lastModified,
  //generic
  loadTitle,
  loadDescription,
  loadEventType,
  loadAudienceType,
  loadDate,
  loadLanguages,
  loadLanguagesNotListed,
  loadTimeZone,
  loadTimeStart,
  loadTimeEnd,
  loadAgendaItems,
  loadSignLanguageInterpreter,
  loadWheelchairAccessibility,
  loadAccessibilityNotes,
  //hosting
  loadHostingOption,
  loadCoordinates,
  loadVirtualMeetingId,
  loadVirtualPasscode,
  loadVirtualPlatform,
  loadVirtualLink,
  loadLocation,
  //participants

  //registrations
  loadApproveRegistrations,
  loadMaxRegistrationsInPersonAmount,
  loadMaxRegistrationsInPersonEnabled,
  loadMaxRegistrationsVirtualAmount,
  loadMaxRegistrationsVirtualEnabled,
  loadPrivateDetails,
  loadRegistrationsActive,

  //schedule
  loadSchedulePublishDate,
  loadSchedulePublishTime,
  loadSchedulePublishTimeZone,
  //contact
  loadContactProvideContact,
  loadContactEmail,
  loadContactPhoneNumber,
  loadContactCountryCode,
  //tags
  loadBigTags,
  loadTinyTags,

  //translations
  loadTranslationsEnabled,
  loadTranslationsRoomCodeId,
  loadTranslationsJoinRoomPasswordToggle,
  loadTranslationsPassword,
  loadTranslationsRoomLanguages,
}) => {
  //Hooks
  const { handleMobileTap, width } = useSettings();
  const { accountId } = useAccountReduxHandlers();
  const { settingsOrganizeStandaloneGridEvents } = useSettingsHandlers();
  const { handleEventValidation, handleEditEventCheckForConflicts } =
    useEventValidation();

  //language
  const { EditOverview, WarningList } = useLanguageOrganize();
  const { Generic, IconAlts } = useLanguageComponents();

  //Component State
  const [initialData, setInitialData] = useState();
  const [confirmSelectionBtn, setConfirmSelectionBtn] = useState();
  const [validations, setValidations] = useState();
  const [verifiedSuccessful, setVerifiedSuccessful] = useState(false);
  const [verifiedTimestamp, setVerifiedTimestamp] = useState();
  const [warningList, setWarningList] = useState([]);

  //editEvent save data conflicts state
  const [editEventConflictsResolved, setEditEventConflictsResolved] =
    useState(false);
  const [editEventConflictObj, setEditEventConflictObj] = useState();

  //UI state
  const [tapHighlightReturn, setTapHighlightReturn] = useState(false);
  const [displayMetaBtns, setDisplayMetaBtns] = useState(true);
  const [tapHighlightMobileReturnBtn, setTapHighlightMobileReturnBtn] =
    useState(false);

  //Initialize functions
  useEffect(() => {
    setInitialData(editEventData);
    setEditEventConflictObj(editEventConflictObjDefault);
    setEditEventConflictsResolved(false);
  }, []);

  useEffect(() => {
    if (reset) {
      setInitialData(editEventData);
      setEditEventConflictObj(editEventConflictObjDefault);
      setEditEventConflictsResolved(false);
    }
  }, [reset]);

  //UI
  useEffect(() => {
    if (width >= 480) {
      setDisplayMetaBtns(true);
    } else {
      if (confirmSelectionBtn) {
        setDisplayMetaBtns(false);
      } else {
        setDisplayMetaBtns(true);
      }
    }
  }, [width, confirmSelectionBtn, setConfirmSelectionBtn]); //toggle some UI display options

  //Component functions
  function handleReturnClick() {
    setPauseChangeList(true);
    handleMobileTap(
      [() => setTapHighlightReturn(true), () => setTapHighlightReturn(false)],
      [() => handleEditEventReset(), () => setEditEvent()],
      true
    );
  }

  useEffect(() => {
    if (lastModified > verifiedTimestamp && confirmSelectionBtn) {
      setVerifiedSuccessful(false);
      setConfirmSelectionBtn();
      setVerifiedTimestamp();
      setEditEventConflictsResolved(false);
    }
  }, [lastModified, verifiedTimestamp]); //if createEvent state in redux is updated, this will invalidate the users ability to publish event

  //Component functions
  async function handleSaveEditChanges() {
    setPauseChangeList(true);
    setOrganizeIsLoading(true);
    setConfirmSelectionBtn();
    await handleUpdateEvent({
      eventData: editEventData,
      handleType: editEventData?.schedule?.scheduleEnabled
        ? 'editScheduled'
        : 'editPublished',
      editEventConflictObj,
    });
  }

  function handleSaveBtnClick() {
    if (confirmSelectionBtn === 'save') {
      setConfirmSelectionBtn();
      setEditEventConflictObj(editEventConflictObjDefault);
    } else {
      setConfirmSelectionBtn('save');
      const validationsCheck = handleEventValidation({ saveType: 'edit' });

      if (Array.isArray(validationsCheck)) {
        setValidations(validationsCheck);
        setVerifiedSuccessful(false);
      } else {
        setVerifiedTimestamp(Date.now());
        setVerifiedSuccessful(true);
        setValidations();

        //validations passed; check for old v. new data conflicts
        const conflictCheckResults =
          handleEditEventCheckForConflicts(initialData);

        setEditEventConflictObj(conflictCheckResults);
      }
    }
  }

  //GENERIC checks
  useEffect(() => {
    if (!pauseChangeList) {
      if (
        eventImageDisplayChangeTracker &&
        !changeList.includes('eventImageDisplay')
      ) {
        handleEditEventChangeList([...changeList, 'eventImageDisplay']);
      } else if (!eventImageDisplayChangeTracker) {
        let newList = changeList.filter((item) => item !== 'eventImageDisplay');
        handleEditEventChangeList(newList);
      }
    }
  }, [eventImageDisplayChangeTracker]); //event image + speaker images

  useEffect(() => {
    if (!pauseChangeList) {
      if (
        eventAdditionalChangeTracker &&
        !changeList.includes('eventAdditionalImages')
      ) {
        handleEditEventChangeList([...changeList, 'eventAdditionalImages']);
      } else if (!eventAdditionalChangeTracker) {
        let newList = changeList.filter(
          (item) => item !== 'eventAdditionalImages'
        );
        handleEditEventChangeList(newList);
      }
    }
  }, [eventAdditionalChangeTracker]); //event image + speaker images

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTitle !== initialData?.generic?.title) {
          if (!changeList.includes('title')) {
            handleEditEventChangeList([...changeList, 'title']);
          }
        } else {
          if (changeList.includes('title')) {
            let newList = changeList.filter((item) => item !== 'title');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTitle]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadDescription !== initialData?.generic?.description) {
          if (!changeList.includes('description')) {
            handleEditEventChangeList([...changeList, 'description']);
          }
        } else {
          if (changeList.includes('description')) {
            let newList = changeList.filter((item) => item !== 'description');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadDescription]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadEventType !== initialData?.generic?.eventType) {
          if (!changeList.includes('eventType')) {
            handleEditEventChangeList([...changeList, 'eventType']);
          }
        } else {
          if (changeList.includes('eventType')) {
            let newList = changeList.filter((item) => item !== 'eventType');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadEventType]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadAudienceType !== initialData?.generic?.audienceType) {
          if (!changeList.includes('audienceType')) {
            handleEditEventChangeList([...changeList, 'audienceType']);
          }
        } else {
          if (changeList.includes('audienceType')) {
            let newList = changeList.filter((item) => item !== 'audienceType');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadAudienceType]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadDate === null) {
          if (!warningList?.includes('expiredDate')) {
            return setWarningList([...warningList, 'expiredDate']);
          } else {
            return;
          }
        } else if (warningList.includes('expiredDate')) {
          let newList = warningList.filter((item) => item !== 'expiredDate');
          setWarningList(newList);
        }

        if (loadDate !== initialData?.dateTime?.date) {
          if (!changeList.includes('date')) {
            handleEditEventChangeList([...changeList, 'date']);
          }
        } else {
          if (changeList.includes('date')) {
            let newList = changeList.filter((item) => item !== 'date');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadDate]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        //CHECK LISTED LANGUAGES
        const languagesEqualityCheck = deepEqual(
          loadLanguages,
          initialData?.generic?.languages
        );

        if (!languagesEqualityCheck) {
          if (!changeList.includes('languages')) {
            return handleEditEventChangeList([...changeList, 'languages']);
          }
        }

        //CHECK NON-LISTED LANGUAGES
        const formattedInitialLanguagesNotListed = [];
        const formattedNewLanguagesNotListed = [];

        loadLanguagesNotListed?.map((langObj) => {
          let newObj = {
            language: langObj.language,
          };
          formattedNewLanguagesNotListed.push(newObj);
        });

        initialData?.generic?.languagesNotListed?.map((langObj) => {
          let newObj = {
            language: langObj.language,
          };
          formattedInitialLanguagesNotListed.push(newObj);
        });

        //compare language fields
        const equalityCheckNotListed = deepEqual(
          formattedInitialLanguagesNotListed,
          formattedNewLanguagesNotListed
        );

        if (!equalityCheckNotListed) {
          if (!changeList.includes('languages')) {
            return handleEditEventChangeList([...changeList, 'languages']);
          }
        }

        //PASS
        if (
          equalityCheckNotListed &&
          languagesEqualityCheck &&
          changeList.includes('languages')
        ) {
          let newList = changeList.filter((item) => item !== 'languages');
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadLanguages, loadLanguagesNotListed]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTimeZone !== initialData?.dateTime?.date) {
          if (!changeList.includes('date')) {
            handleEditEventChangeList([...changeList, 'date']);
          }
        } else {
          if (changeList.includes('date')) {
            let newList = changeList.filter((item) => item !== 'date');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTimeZone]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTimeStart !== initialData?.dateTime?.timeStart) {
          if (!changeList.includes('timeStart')) {
            handleEditEventChangeList([...changeList, 'timeStart']);
          }
        } else {
          if (changeList.includes('timeStart')) {
            let newList = changeList.filter((item) => item !== 'timeStart');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTimeStart]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadTimeEnd !== initialData?.dateTime?.timeEnd) {
          if (!changeList.includes('timeEnd')) {
            handleEditEventChangeList([...changeList, 'timeEnd']);
          }
        } else {
          if (changeList.includes('timeEnd')) {
            let newList = changeList.filter((item) => item !== 'timeEnd');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTimeEnd]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        const equalityCheck = deepEqual(
          loadAgendaItems,
          initialData?.generic?.agenda?.items
        );

        if (!equalityCheck) {
          if (!changeList.includes('agenda')) {
            handleEditEventChangeList([...changeList, 'agenda']);
          }
        } else {
          if (changeList.includes('agenda')) {
            let newList = changeList.filter((item) => item !== 'agenda');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadAgendaItems]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadSignLanguageInterpreter !==
          initialData?.generic?.signLanguageInterpreter
        ) {
          if (!changeList.includes('signLanguageInterpreter')) {
            handleEditEventChangeList([
              ...changeList,
              'signLanguageInterpreter',
            ]);
          }
        } else {
          if (changeList.includes('signLanguageInterpreter')) {
            let newList = changeList.filter(
              (item) => item !== 'signLanguageInterpreter'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSignLanguageInterpreter]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadWheelchairAccessibility !==
          initialData?.generic?.wheelchairAccessibility
        ) {
          if (!changeList.includes('wheelchairAccessibility')) {
            handleEditEventChangeList([
              ...changeList,
              'wheelchairAccessibility',
            ]);
          }
        } else {
          if (changeList.includes('wheelchairAccessibility')) {
            let newList = changeList.filter(
              (item) => item !== 'wheelchairAccessibility'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadWheelchairAccessibility]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadAccessibilityNotes !== initialData?.generic?.accessibilityNotes
        ) {
          if (!changeList.includes('accessibilityNotes')) {
            handleEditEventChangeList([...changeList, 'accessibilityNotes']);
          }
        } else {
          if (changeList.includes('accessibilityNotes')) {
            let newList = changeList.filter(
              (item) => item !== 'accessibilityNotes'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadAccessibilityNotes]);

  //Hosting
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadHostingOption !== initialData?.hosting?.hostingOption) {
          if (!changeList.includes('hostingOption')) {
            handleEditEventChangeList([...changeList, 'hostingOption']);
          }
        } else {
          if (changeList.includes('hostingOption')) {
            let newList = changeList.filter((item) => item !== 'hostingOption');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadHostingOption]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadCoordinates[0] !==
            initialData?.hosting?.inPerson?.geoPosition?.coordinates[0] &&
          loadCoordinates[1] !==
            initialData?.hosting?.inPerson?.geoPosition?.coordinates[1]
        ) {
          if (!changeList.includes('coordinates')) {
            handleEditEventChangeList([...changeList, 'coordinates']);
          }
        } else {
          if (changeList.includes('coordinates')) {
            let newList = changeList.filter((item) => item !== 'coordinates');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadCoordinates]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualMeetingId !== initialData?.hosting?.virtual?.meetingId) {
          if (!changeList.includes('virtualMeetingId')) {
            handleEditEventChangeList([...changeList, 'virtualMeetingId']);
          }
        } else {
          if (changeList.includes('virtualMeetingId')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualMeetingId'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualMeetingId]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualPasscode !== initialData?.hosting?.virtual?.passcode) {
          if (!changeList.includes('virtualMeetingPasscode')) {
            handleEditEventChangeList([
              ...changeList,
              'virtualMeetingPasscode',
            ]);
          }
        } else {
          if (changeList.includes('virtualMeetingPasscode')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualMeetingPasscode'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualPasscode]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualPlatform !== initialData?.hosting?.virtual?.platform) {
          if (!changeList.includes('virtualPlatform')) {
            handleEditEventChangeList([...changeList, 'virtualPlatform']);
          }
        } else {
          if (changeList.includes('virtualPlatform')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualPlatform'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualPlatform]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadVirtualLink !== initialData?.hosting?.virtual?.meetingLink) {
          if (!changeList.includes('virtualMeetingLink')) {
            handleEditEventChangeList([...changeList, 'virtualMeetingLink']);
          }
        } else {
          if (changeList.includes('virtualMeetingLink')) {
            let newList = changeList.filter(
              (item) => item !== 'virtualMeetingLink'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadVirtualLink]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadLocation,
          initialData?.hosting?.inPerson?.location
        );
        if (!equalityCheck) {
          if (!changeList.includes('location')) {
            handleEditEventChangeList([...changeList, 'location']);
          }
        } else {
          if (changeList.includes('location')) {
            let newList = changeList.filter((item) => item !== 'location');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadLocation]);

  //Participants
  useEffect(() => {
    if (!pauseChangeList && initialData) {
      let newList = [...changeList];

      // Handle Participants
      if (participantChangeTracker) {
        if (!newList.includes('participants')) {
          newList.push('participants');
        }
      } else {
        newList = newList.filter((item) => item !== 'participants');
      }

      // Handle Participant Images
      if (participantImagesChangeTracker) {
        if (!newList.includes('participantImages')) {
          newList.push('participantImages');
        }
      } else {
        newList = newList.filter((item) => item !== 'participantImages');
      }

      // Only update if the list has changed to prevent infinite re-renders
      if (JSON.stringify(newList) !== JSON.stringify(changeList)) {
        handleEditEventChangeList(newList);
      }
    }
  }, [
    participantChangeTracker,
    participantImagesChangeTracker,
    pauseChangeList,
    initialData,
    changeList,
  ]);

  // Registrations;
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadRegistrationsActive !==
          initialData?.registrationData?.registrationsActive
        ) {
          if (!changeList.includes('requireRegistrations')) {
            handleEditEventChangeList([...changeList, 'requireRegistrations']);
          }
        } else {
          if (changeList.includes('requireRegistrations')) {
            let newList = changeList.filter(
              (item) => item !== 'requireRegistrations'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    } //particular behaviour requires change list to be added as a dependency. otehr registration buttons are dependent on the require registrations/active registrations toggle, but there are race conditions and the dependent states will call the change list handler but ultimately wipe out the requireRegistrations item before it can be passed down.
  }, [loadRegistrationsActive, changeList]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadApproveRegistrations !==
          initialData?.registrationData?.approveRegistrations
        ) {
          if (!changeList.includes('approveRegistrations')) {
            handleEditEventChangeList([...changeList, 'approveRegistrations']);
          }
        } else {
          if (changeList.includes('approveRegistrations')) {
            let newList = changeList.filter(
              (item) => item !== 'approveRegistrations'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadApproveRegistrations]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadMaxRegistrationsInPersonAmount !==
            initialData?.registrationData?.maxRegistrationsInPersonAmount ||
          loadMaxRegistrationsInPersonEnabled !==
            initialData?.registrationData?.maxRegistrationsInPersonEnabled
        ) {
          if (!changeList.includes('maxRegistrationsInPerson')) {
            let newChangeList = [...changeList, 'maxRegistrationsInPerson'];

            if (loadHostingOption !== initialData?.hosting?.hostingOption) {
              if (!newChangeList.includes('hostingOption')) {
                newChangeList = [...newChangeList, 'hostingOption'];
              }
            }
            handleEditEventChangeList(newChangeList);
          }
        } else {
          if (changeList.includes('maxRegistrationsInPerson')) {
            let newChangeList = [...changeList];

            newChangeList = changeList.filter(
              (item) => item !== 'maxRegistrationsInPerson'
            );

            if (loadHostingOption === initialData?.hosting?.hostingOption) {
              if (changeList.includes('hostingOption')) {
                newChangeList = newChangeList.filter(
                  (item) => item !== 'hostingOption'
                );
              }
            }
            handleEditEventChangeList(newChangeList);
          }
        }
      }
    }
  }, [loadMaxRegistrationsInPersonAmount, loadMaxRegistrationsInPersonEnabled]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadMaxRegistrationsVirtualEnabled !==
            initialData?.registrationData?.maxRegistrationsVirtualEnabled ||
          loadMaxRegistrationsVirtualAmount !==
            initialData?.registrationData?.maxRegistrationsVirtualAmount
        ) {
          if (!changeList.includes('maxRegistrationsVirtual')) {
            let newChangeList = [...changeList, 'maxRegistrationsVirtual'];

            if (loadHostingOption !== initialData?.hosting?.hostingOption) {
              if (!newChangeList.includes('hostingOption')) {
                newChangeList = [...newChangeList, 'hostingOption'];
              }
            }
            handleEditEventChangeList(newChangeList);
          }
        } else {
          if (changeList.includes('maxRegistrationsVirtual')) {
            let newChangeList = [...changeList];

            newChangeList = changeList.filter(
              (item) => item !== 'maxRegistrationsVirtual'
            );

            if (loadHostingOption === initialData?.hosting?.hostingOption) {
              if (changeList.includes('hostingOption')) {
                newChangeList = newChangeList.filter(
                  (item) => item !== 'hostingOption'
                );
              }
            }
            handleEditEventChangeList(newChangeList);
          }
        }
      }
    }
  }, [loadMaxRegistrationsVirtualEnabled, loadMaxRegistrationsVirtualAmount]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadPrivateDetails,
          initialData?.registrationData?.privateDetails
        );

        if (!equalityCheck) {
          if (!changeList.includes('privateDetails')) {
            handleEditEventChangeList([...changeList, 'privateDetails']);
          }
        } else {
          if (changeList.includes('privateDetails')) {
            let newList = changeList.filter(
              (item) => item !== 'privateDetails'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadPrivateDetails]);

  //Schedule
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadSchedulePublishDate !== initialData?.schedule?.publishDate) {
          if (!changeList.includes('schedulePublishDate')) {
            handleEditEventChangeList([...changeList, 'schedulePublishDate']);
          }
        } else {
          if (changeList.includes('schedulePublishDate')) {
            let newList = changeList.filter(
              (item) => item !== 'schedulePublishDate'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSchedulePublishDate]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadSchedulePublishTimeZone !== initialData?.schedule?.publishTimeZone
        ) {
          if (!changeList.includes('schedulePublishTimeZone')) {
            handleEditEventChangeList([
              ...changeList,
              'schedulePublishTimeZone',
            ]);
          }
        } else {
          if (changeList.includes('schedulePublishTimeZone')) {
            let newList = changeList.filter(
              (item) => item !== 'schedulePublishTimeZone'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSchedulePublishTimeZone]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadSchedulePublishTime !== initialData?.schedule?.publishTime) {
          if (!changeList.includes('schedulePublishTime')) {
            handleEditEventChangeList([...changeList, 'schedulePublishTime']);
          }
        } else {
          if (changeList.includes('schedulePublishTime')) {
            let newList = changeList.filter(
              (item) => item !== 'schedulePublishTime'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadSchedulePublishTime]);

  //Tags
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(loadBigTags, initialData?.tags?.bigTags);

        if (!equalityCheck) {
          if (!changeList.includes('bigTags')) {
            handleEditEventChangeList([...changeList, 'bigTags']);
          }
        } else {
          if (changeList.includes('bigTags')) {
            let newList = changeList.filter((item) => item !== 'bigTags');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadBigTags]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadTinyTags,
          initialData?.tags?.tinyTags
        );

        if (!equalityCheck) {
          if (!changeList.includes('tinyTags')) {
            handleEditEventChangeList([...changeList, 'tinyTags']);
          }
        } else {
          if (changeList.includes('tinyTags')) {
            let newList = changeList.filter((item) => item !== 'tinyTags');
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTinyTags]);

  //translations
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadTranslationsEnabled !==
          initialData?.translations?.translationsEnabled
        ) {
          if (!changeList.includes('translationsEnabled')) {
            handleEditEventChangeList([...changeList, 'translationsEnabled']);
          }
        } else {
          if (changeList.includes('translationsEnabled')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsEnabled'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsEnabled]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadTranslationsRoomCodeId &&
          loadTranslationsRoomCodeId?.toString() !==
            initialData?.translations?.roomCodeId?.toString()
        ) {
          if (!changeList.includes('translationsRoomCode')) {
            handleEditEventChangeList([...changeList, 'translationsRoomCode']);
          }
        } else {
          if (changeList.includes('translationsRoomCode')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsRoomCode'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsRoomCodeId]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadTranslationsJoinRoomPasswordToggle !==
            initialData?.translations?.joinRoomPasswordToggle ||
          loadTranslationsPassword !== initialData?.translations?.password
        ) {
          if (!changeList.includes('translationsPassword')) {
            handleEditEventChangeList([...changeList, 'translationsPassword']);
          }
        } else {
          if (changeList.includes('translationsPassword')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsPassword'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsJoinRoomPasswordToggle, loadTranslationsPassword]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        let equalityCheck = deepEqual(
          loadTranslationsRoomLanguages,
          initialData?.translations?.roomLanguages
        );

        if (!equalityCheck) {
          if (!changeList.includes('translationsRoomLanguages')) {
            handleEditEventChangeList([
              ...changeList,
              'translationsRoomLanguages',
            ]);
          }
        } else {
          if (changeList.includes('translationsRoomLanguages')) {
            let newList = changeList.filter(
              (item) => item !== 'translationsRoomLanguages'
            );
            handleEditEventChangeList(newList);
          }
        }
      }
    }
  }, [loadTranslationsRoomLanguages]);

  //contact
  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadContactProvideContact !== initialData?.contact?.provideContact
        ) {
          if (!changeList.includes('provideContact')) {
            handleEditEventChangeList([...changeList, 'provideContact']);
          }
        } else {
          let newList = changeList.filter((item) => item !== 'provideContact');
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadContactProvideContact]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (loadContactEmail !== initialData?.contact?.contactEmail) {
          if (!changeList.includes('contactEmail')) {
            handleEditEventChangeList([...changeList, 'contactEmail']);
          }
        } else {
          let newList = changeList.filter((item) => item !== 'contactEmail');
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadContactEmail]);

  useEffect(() => {
    if (!pauseChangeList) {
      if (initialData) {
        if (
          loadContactPhoneNumber !== initialData?.contact?.contactPhoneNumber ||
          loadContactCountryCode !== initialData?.contact?.contactCountryCode
        ) {
          if (!changeList.includes('contactPhoneNumber')) {
            handleEditEventChangeList([...changeList, 'contactPhoneNumber']);
          }
        } else {
          let newList = changeList.filter(
            (item) => item !== 'contactPhoneNumber'
          );
          handleEditEventChangeList(newList);
        }
      }
    }
  }, [loadContactPhoneNumber, loadContactCountryCode]);

  return (
    <>
      <ul className="editActions-btns">
        <li>
          <button
            className={`action-btn  
            ${
              tapHighlightReturn
                ? 'highlight-i-theme--tap click-scaledown-90'
                : ''
            } ${
              submitIsLoading ? 'opacity-05 unavailable' : 'highlight-i-theme'
            }`}
            onClick={submitIsLoading ? null : () => handleReturnClick()}
            title={Generic.return}
          >
            <img
              src={return_curved_arrow}
              alt={IconAlts.iconArrow}
              className="filter-lightgray"
            />
          </button>
        </li>
      </ul>

      <div className="fieldset mrg-t24" id="fieldset-editactions">
        {/* <legend id="aria-fieldset-instructions-editOverview"> */}
        {/* {CreateEvent.general.ariaFieldsetInstructions} */}
        {/* </legend> */}
        <label
          className="label"
          id="editOverviewLabel"
          aria-describedby="aria-fieldset-instructions-editOverview"
          tabIndex="0"
        >
          {EditOverview.editOverview}
        </label>
        {warningList?.length > 0 && (
          <div className="flex-column">
            <p className="fs18 fwsb">{EditOverview.warningList}:</p>

            <ul className="mrg-l24 mrg-tb24">
              {warningList?.map((item, index) => (
                <li className="fs18 fwsb mrg-b6" key={`warningList-${index}`}>
                  {WarningList[item]}
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="flex-column">
          <p className="fs18 fwsb">{EditOverview.changeList}:</p>

          <ul className="mrg-l24 mrg-t24">
            {changeList?.map((item, index) => (
              <li
                className={`fs18 fwsb access-ob access-06 ${
                  index !== changeList?.length - 1 ? 'mrg-b6' : ''
                }`}
                key={`changeList-${index}`}
                tabIndex="0"
              >
                {EditOverview[item]}
              </li>
            ))}
          </ul>
          {changeList?.length === 0 && (
            <p
              className="mrg-l24 fs18 fwsb mrg-b6 access-ob access-o6"
              tabIndex="0"
            >
              {EditOverview.noChange}
            </p>
          )}
        </div>
        <div className="divider mrg-tb36 opacity-08" />

        {displayMetaBtns && (
          <div className={`button-meta__container flex-center space-evenly `}>
            <ButtonMeta
              handleClick={handleSaveBtnClick}
              btnText={EditOverview.saveChanges}
              icon={save}
              iconAlt={IconAlts.iconSave}
              activeStateDependency={confirmSelectionBtn === 'save'}
              unavailable={
                changeList?.length === 0 ? true : false || submitIsLoading
              }
              availabilityToggle={true}
            />

            <ButtonMeta
              handleClick={() => {
                if (confirmSelectionBtn === 'reset') {
                  setConfirmSelectionBtn();
                } else {
                  setConfirmSelectionBtn('reset');
                }
              }}
              btnText={EditOverview.undoChanges}
              icon={refresh}
              iconAlt={IconAlts.iconReset}
              activeStateDependency={confirmSelectionBtn === 'reset'}
              unavailable={
                changeList?.length === 0 ? true : false || submitIsLoading
              }
              availabilityToggle={true}
            />
            <ButtonMeta
              handleClick={() => {
                if (confirmSelectionBtn === 'cancel') {
                  setConfirmSelectionBtn();
                } else {
                  setConfirmSelectionBtn('cancel');
                }
              }}
              btnText={EditOverview.cancelEvent}
              icon={cancel}
              iconAlt={'X'}
              activeStateDependency={confirmSelectionBtn === 'cancel'}
              unavailable={submitIsLoading || organizeIsLoading}
              availabilityToggle={true}
            />
          </div>
        )}

        {!confirmSelectionBtn && (
          <ItemButton
            handler={() => setEventPreview(editEventData)}
            text={EditOverview.previewEvent}
            iconOn={true}
            icon={preview}
            fullWidth={true}
            customWrapper={'pad-24 mrg-t36'}
            unavailable={submitIsLoading || organizeIsLoading}
          />
        )}

        {/* confirmation selections*/}
        {confirmSelectionBtn === 'save' && verifiedSuccessful && (
          <>
            <EditEventConflictsAndSaveConfirmation
              editEventConflictObj={editEventConflictObj}
              setEditEventConflictObj={setEditEventConflictObj}
              EditOverview={EditOverview}
              editEventConflictsResolved={editEventConflictsResolved}
              setEditEventConflictsResolved={setEditEventConflictsResolved}
              handleSaveBtnClick={handleSaveBtnClick}
              handleSaveEditChanges={handleSaveEditChanges}
              setConfirmSelectionBtn={setConfirmSelectionBtn}
            />
          </>
        )}

        {confirmSelectionBtn === 'save' && validations && (
          <>
            {width <= 480 && (
              <button
                className={`close-btn highlight-i-lgt access-ob  ${
                  tapHighlightMobileReturnBtn ? 'highlight-i-lgt--tap' : ''
                }`}
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightMobileReturnBtn(true),
                      () => setTapHighlightMobileReturnBtn(false),
                    ],
                    [() => setConfirmSelectionBtn()]
                  )
                }
                type="button"
                title={Generic.return}
              >
                <img
                  src={return_curved_arrow}
                  alt={IconAlts.iconReturn}
                  className="svg"
                  style={{ maxHeight: '48px' }}
                />
              </button>
            )}
            <ValidationsList
              validations={validations}
              customWrapper={'mrg-t36 mrg-t24--ph'}
            />
          </>
        )}

        {confirmSelectionBtn === 'reset' && (
          <ConfirmSelection
            text={EditOverview.undoConfirmation}
            btn1Text={EditOverview.cancel}
            btn1Color={'neutral'}
            btn1Handler={() => setConfirmSelectionBtn()}
            btn2Text={EditOverview.confirm}
            btn2Color={'pos'}
            btn2Handler={() => {
              setPauseChangeList(true);
              setConfirmSelectionBtn();
              handleReset();
            }}
            customWrapper={'mrg-t36 mrg-t24--ph text-center'}
            ignoreMaxWidth={true}
          />
        )}
        {confirmSelectionBtn === 'cancel' && (
          <ConfirmSelection
            text={EditOverview.deleteConfirmation}
            btn1Text={EditOverview.cancel}
            btn1Color={'neutral'}
            btn1Handler={() => setConfirmSelectionBtn()}
            btn2Text={EditOverview.deletePermanently}
            btn2Color={'neg'}
            btn2Handler={() => {
              setConfirmSelectionBtn();
              setOrganizeIsLoading(true);
              handleRemoveEvent({
                eventId,
                organizerId: accountId,
                eventType: settingsOrganizeStandaloneGridEvents,
                participants: initialData?.participants || [],
              });
            }}
            customWrapper={'mrg-t36 mrg-t24--ph text-center'}
            ignoreMaxWidth={true}
          />
        )}
      </div>
    </>
  );
};

export default EditOverview;

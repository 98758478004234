import { useState } from 'react';

//language
import useLanguageComponents from '../../language/useLanguageComponents';
import useLanguageLayout from '../../language/useLanguageLayout';

//components
import EmailInput from '../../components/TextArea/EmailInput';

//utils
import {
  img_add_account,
  img_bullseye,
  img_messages,
  linkedin_color,
} from '../../assets/icons';
import { logo_cbg } from '../../assets/images/explore';
import { useSettings } from '../../context/SettingsProvider';

const ExploreMore = () => {
  //hooks
  const { width } = useSettings();
  //state
  const [newsletterEmail, setNewsletterEmail] = useState('');

  //language
  const { IconAlts } = useLanguageComponents();
  const { Footer } = useLanguageLayout();

  return (
    <div
      className="explore-myndfull-introduction  bg-cream"
      id="myndfull-exploreMore"
    >
      <div className="explore-base-text">
        <div className="flex-row align-center full-width mrg-b24">
          <img
            src={logo_cbg}
            alt="MyndFull"
            className="explore-logo explore-logo--launch"
          />
          <h2 className="access-ob access-o6" tabIndex="0">
            MyndFull Launch
          </h2>
        </div>

        {width > 390 ? (
          <div
            className="flex-row align-center explore-base-text--small access-ob"
            tabIndex="0"
            aria-label={
              'MyndFull Translations launch 2025. MyndFull Events Platform launch Q4 2026.'
            }
          >
            <div className="flex-column">
              <h3 className="align-center">
                MyndFull Translations &mdash;{' '}
                <img src={img_bullseye} style={{ height: '42px' }} alt="" />
                2025
              </h3>
              <h3 className="align-center mrg-t12">
                MyndFull Events &mdash;{' '}
                <img src={img_bullseye} style={{ height: '42px' }} alt="" />
                Q4 2026
              </h3>
            </div>
          </div>
        ) : (
          <div
            className="flex-column explore-base-text--small "
            tabIndex="0"
            aria-label={
              'MyndFull Translations launch 2025. MyndFull Events Platform launch Q4 2026.'
            }
          >
            <div className="flex-column">
              <h3 className="align-center">MyndFull Translations</h3>
              <div className="flex-row align-center">
                <img
                  src={img_bullseye}
                  style={{ height: '42px', width: '42px' }}
                  alt=""
                />{' '}
                <h3>2025</h3>
              </div>

              <h3 className="align-center mrg-t12"> Events Platform</h3>
              <div className="flex-row align-center">
                <img
                  src={img_bullseye}
                  style={{ height: '42px', width: '42px' }}
                  alt=""
                />{' '}
                <h3> Q4 2026</h3>
              </div>
            </div>
          </div>
        )}

        <p className="mrg-t48 mrg-t24--ph access-ob access-o6" tabIndex="0">
          MyndFull is currently in its pre-launch phase. Our plan is to launch
          MyndFull Translations in 2025 and MyndFull Events in late 2026.
        </p>

        <p className="mrg-t48 mrg-t24--ph access-ob access-o6" tabIndex="0">
          For MyndFull Events, our platform will be free for attendees and
          speakers. Organizers can also sign up and create events for free, but
          they can support us through premium subscription plans that will also
          unlock further value in our service.
        </p>

        <div
          className="divider mrg-tb48 mrg-auto-lr"
          style={{ width: '80%' }}
        />

        <p className="mrg-t36 access-ob access-o6" tabIndex="0">
          To support our work, you can sign up for pre-launch access and assist
          in development. Also consider joining our Newsletter or follow us on
          LinkedIn for the latest updates and opportunities.
        </p>
        <div className="flex-row mrg-t48 mrg-t24--ph flex-center">
          <button
            className="explore-button align-center mrg-tb36 access-ob access-o6"
            tabIndex="0"
          >
            <img
              src={img_add_account}
              className="filter-white mrg-r12"
              alt={IconAlts.iconAccount}
            />
            Sign Up
          </button>
        </div>
      </div>

      <div className="explore-myndfull-more-links full-width">
        <div className="explore-myndfull-more-email">
          <div className=" explore-base-text flex-row ">
            <img
              src={img_messages}
              className="filter-theme mrg-r12"
              alt={IconAlts.iconEmail}
            />
            <h2 className="access-ob access-o6" tabIndex="0">
              Join our Newsletter
            </h2>
          </div>
          <p className="mrg-tb24 fs21 access-ob access-o6" tabIndex="0">
            Receive the latest updates and opportunities.
          </p>

          <div className="fs18">
            <EmailInput
              loadValue={newsletterEmail}
              handleInput={setNewsletterEmail}
            />
          </div>
        </div>

        <div className="follow-us">
          <div className="explore-base-text mrg-b24">
            <h2 className="access-ob access-o6" tabIndex="0">
              Follow us on LinkedIn
            </h2>
          </div>
          <a
            href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89784148&keywords=myndfull&origin=RICH_QUERY_SUGGESTION&position=0&searchId=353b33b8-d1f3-478a-a051-a844ab85140a&sid=*cc"
            aria-label={Footer.visitOurLinkedInPage}
            className="flex-row fit-content align-center access-ob access-o6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin_color} alt="LinkedIn" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExploreMore;

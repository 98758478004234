const clockConversion = (time) => {
  const [hours, minutes] = time.split(':')?.map(Number);

  const period = hours >= 12 ? 'PM' : 'AM';

  const hours12 = hours > 12 ? hours - 12 : hours === 0 ? 12 : hours;

  const formattedTime = `${hours12.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')} ${period}`;

  return <>{formattedTime}</>;
};
export default clockConversion;

import { useState } from 'react';

//Hooks
import { useCreateEventReduxHandlers } from './useCreateEventHandlers';

//language
import useLanguageOrganize from '../../../../language/features/useLanguageOrganize';

//Components
import Initialize from '../eventSections/Initialize';
import General from '../eventSections/General';
import Images from '../eventSections/Images';
import Access from '../eventSections/Access';
import Registration from '../eventSections/Registration';
import Publish from '../eventSections/Publish';
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop';
import Spinner from '../../../../components/Spinner/Spinner';
import Agenda from '../eventSections/Agenda';
import EventTags from '../eventSections/EventTags';
import Contact from '../eventSections/Contact';
import EventParticipants from '../eventSections/EventParticipants';
import EventTranslations from '../eventSections/EventTranslations';
import Dispatches from '../eventSections/Dispatches';

const CreateEvent = ({
  eventPreview,
  setEventPreview,
  handleSubmitEvent,
  handleSubmitEventDraft,
  removedParticipantsWithDispatchedInvites,
  setRemovedParticipantsWithDispatchedInvites,

  //loading state
  setSubmitIsLoading,
  organizeIsLoading,
  setOrganizeIsLoading,
  submitIsLoading,

  //cropped imgs
  clearImages,
  eventAdditionalImagesCropped,
  eventImageDisplayCropped,
  participantImagesCropped,
  setEventAdditionalImagesCropped,
  setEventImageDisplayCropped,
  setParticipantImagesCropped,

  //pwd state
  decryptedTranslationsPassword,
  setDecryptedTranslationsPassword,
  decryptedVirtualPasscode,
  setDecryptedVirtualPasscode,
}) => {
  //hooks
  const {
    //Contact
    handleCreateEventSetProvideContact,
    handleCreateEventSetContactEmail,
    handleCreateEventSetContactPhoneNumber,
    handleCreateEventSetContactCountryCode,

    //DateTime
    handleCreateEventSetDate,
    handleCreateEventSetTimeZone,
    handleCreateEventSetTimeStart,
    handleCreateEventSetTimeEnd,

    //Generic
    handleCreateEventSetTitle,
    handleCreateEventSetDescription,
    handleCreateEventSetEventType,
    handleCreateEventSetAudienceType,
    handleCreateEventSetAgendaFormat,
    handleCreateEventSetAgendaItems,
    handleCreateEventSetLanguages,
    handleCreateEventSetLanguagesNotListed,
    handleCreateEventSetEventImageDisplay,
    handleCreateEventSetEventAdditionalImages,

    //Access
    handleCreateEventClearLocation,
    handleCreateEventSetCoordinates,
    handleCreateEventSetHostingOption,
    handleCreateEventSetLocation,
    handleCreateEventSetAccessibilityNotes,
    handleCreateEventSetSignLanguageInterpreter,
    handleCreateEventSetWheelchairAccessibility,

    //Schedule
    handleCreateEventSetSchedulePublishDate,
    handleCreateEventSetSchedulePublishTime,
    handleCreateEventSetSchedulePublishTimeZone,
    handleCreateEventSetScheduleEnabled,

    //Participants
    handleCreateEventSetParticipants,

    //Hosting
    handleCreateEventSetVirtualMeetingId,
    handleCreateEventSetVirtualPasscode,
    handleCreateEventSetVirtualPlatform,
    handleCreateEventSetVirtualMeetingLink,

    //Registrations
    handleCreateEventSetApproveRegistrations,
    handleCreateEventSetMaxRegistrationsInPersonEnabled,
    handleCreateEventSetMaxRegistrationsVirtualEnabled,
    handleCreateEventSetEnablePrivateDetails,
    handleCreateEventSetMaxRegistrationsInPersonAmount,
    handleCreateEventSetMaxRegistrationsVirtualAmount,
    handleCreateEventSetPrivateDetails,
    handleCreateEventSetRegistrations,

    //Tags
    handleCreateEventSetBigTags,
    handleCreateEventSetTinyTags,

    //Translations
    handleCreateEventSetTranslationsTranslationsEnabled,
    handleCreateEventSetTranslationsRoomCodeId,
    handleCreateEventSetTranslationsJoinRoomPasswordToggle,
    handleCreateEventSetTranslationsPassword,
    handleCreateEventSetTranslationsRoomLanguages,

    //Contact
    contactProvideContact: loadContactProvideContact,
    contactContactEmail: loadContactEmail,
    contactContactPhoneNumber: loadContactPhoneNumber,
    contactContactCountryCode: loadContactCountryCode,

    //Generic
    title: loadTitle,
    description: loadDescription,
    eventType: loadEventType,
    audienceType: loadAudienceType,
    date: loadDate,
    languages: loadLanguages,
    languagesNotListed: loadLanguagesNotListed,
    timeZone: loadTimeZone,
    timeStart: loadTimeStart,
    timeEnd: loadTimeEnd,
    agendaItems: loadAgendaItems,
    agendaFormat: loadAgendaFormat,
    signLanguageInterpreter: loadSignLanguageInterpreter,
    wheelchairAccessibility: loadWheelchairAccessibility,
    accessibilityNotes: loadAccessibilityNotes,
    eventImageDisplay: loadEventImageDisplay,
    eventAdditionalImages: loadEventAdditionalImages,

    //Hosting
    hostingOption: loadHostingOption,
    coordinates: loadCoordinates,
    virtualMeetingId: loadVirtualMeetingId,
    virtualPasscode: loadVirtualPasscode,
    virtualPlatform: loadVirtualPlatform,
    virtualLink: loadVirtualLink,
    location: loadLocation,

    //Participants
    createEventParticipants: loadEventParticipants,

    //Registrations
    approveRegistrations: loadApproveRegistrations,
    maxRegistrationsInPersonEnabled: loadMaxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled: loadMaxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount: loadMaxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount: loadMaxRegistrationsVirtualAmount,
    registrationsActive: loadRegistrationsActive,
    privateDetails: loadPrivateDetails,
    enablePrivateDetails: loadEnablePrivateDetails,

    //Schedule
    scheduleEnabled: loadScheduleEnabled,
    schedulePublishDate: loadSchedulePublishDate,
    schedulePublishTime: loadSchedulePublishTime,
    schedulePublishTimeZone: loadSchedulePublishTimeZone,

    //Tags
    bigTags: loadBigTags,
    tinyTags: loadTinyTags,

    //translations
    createEventTranslationsTranslationsEnabled:
      loadTranslationsTranslationsEnabled,
    createEventTranslationsRoomCodeId: loadTranslationsRoomCodeId,
    createEventTranslationsJoinRoomPasswordToggle:
      loadTranslationsJoinRoomPasswordToggle,
    createEventTranslationsPassword: loadTranslationsPassword,
    createEventTranslationsRoomLanguages: loadTranslationsRoomLanguages,
  } = useCreateEventReduxHandlers();

  //language
  const { CreateEvent } = useLanguageOrganize();

  //Component state
  const [initialize, setInitialize] = useState(false);

  return (
    <div className={`flex-column ${eventPreview ? 'remove' : ''}`}>
      <div className="create-event__wrapper">
        <div className="create-event mrg-t24 mrg-t0--ph">
          <Initialize
            handleSubmitEventDraft={handleSubmitEventDraft}
            participants={loadEventParticipants}
            clearImages={clearImages}
            //loading state
            setInitialize={setInitialize}
            initialize={initialize}
            setOrganizeIsLoading={setOrganizeIsLoading}
            setSubmitIsLoading={setSubmitIsLoading}
            submitIsLoading={submitIsLoading}
            organizeIsLoading={organizeIsLoading}
            //pwd
            decryptedTranslationsPassword={decryptedTranslationsPassword}
            decryptedVirtualPasscode={decryptedVirtualPasscode}
            setDecryptedTranslationsPassword={setDecryptedTranslationsPassword}
            setDecryptedVirtualPasscode={setDecryptedVirtualPasscode}
            translationsPassword={loadTranslationsPassword}
            virtualPasscode={loadVirtualPasscode}
          />
          {initialize && !organizeIsLoading ? (
            <>
              <Publish
                setEventPreview={setEventPreview}
                initialize={initialize}
                setOrganizeIsLoading={setOrganizeIsLoading}
                //state
                loadSchedulePublishDate={loadSchedulePublishDate}
                loadSchedulePublishTime={loadSchedulePublishTime}
                loadSchedulePublishTimeZone={loadSchedulePublishTimeZone}
                loadScheduleEnabled={loadScheduleEnabled}
                submitIsLoading={submitIsLoading}
                //pwd
                decryptedTranslationsPassword={decryptedTranslationsPassword}
                decryptedVirtualPasscode={decryptedVirtualPasscode}
                //handlers
                handleSubmitEvent={handleSubmitEvent}
                handleSetSchedulePublishDate={
                  handleCreateEventSetSchedulePublishDate
                }
                handleSetSchedulePublishTime={
                  handleCreateEventSetSchedulePublishTime
                }
                handleSetSchedulePublishTimeZone={
                  handleCreateEventSetSchedulePublishTimeZone
                }
                handleSetScheduleEnabled={handleCreateEventSetScheduleEnabled}
              />
              <Dispatches
                skipToId={'generalLabel'}
                skipToLabel={CreateEvent.skipTo.skipToGeneral}
                //state
                participants={loadEventParticipants}
                translationsEnabled={loadTranslationsTranslationsEnabled}
                //handlers
                handleSetParticipants={handleCreateEventSetParticipants}
              />
              <General
                initialize={initialize}
                CreateEvent={CreateEvent}
                skipToId={'contactLabel'}
                skipToLabel={CreateEvent.skipTo.skipToContact}
                //state
                loadTitle={loadTitle}
                loadDescription={loadDescription}
                loadEventType={loadEventType}
                loadAudienceType={loadAudienceType}
                loadDate={loadDate}
                loadLanguages={loadLanguages}
                loadLanguagesNotListed={loadLanguagesNotListed}
                loadTimeZone={loadTimeZone}
                loadTimeStart={loadTimeStart}
                loadTimeEnd={loadTimeEnd}
                //handlers
                handleSetTitle={handleCreateEventSetTitle}
                handleSetDescription={handleCreateEventSetDescription}
                handleSetEventType={handleCreateEventSetEventType}
                handleSetAudienceType={handleCreateEventSetAudienceType}
                handleSetDate={handleCreateEventSetDate}
                handleSetTimeZone={handleCreateEventSetTimeZone}
                handleSetTimeStart={handleCreateEventSetTimeStart}
                handleSetTimeEnd={handleCreateEventSetTimeEnd}
                handleSetLanguages={handleCreateEventSetLanguages}
                handleSetLanguagesNotListed={
                  handleCreateEventSetLanguagesNotListed
                }
              />

              <Contact
                initialize={initialize}
                skipToId={'participantsLabel'}
                skipToLabel={CreateEvent.skipTo.skipToParticipants}
                CreateEvent={CreateEvent}
                //state
                loadContactProvideContact={loadContactProvideContact}
                loadContactEmail={loadContactEmail}
                loadContactPhoneNumber={loadContactPhoneNumber}
                loadContactCountryCode={loadContactCountryCode}
                //handlers
                handleEventSetProvideContact={
                  handleCreateEventSetProvideContact
                }
                handleEventSetContactEmail={handleCreateEventSetContactEmail}
                handleEventSetContactPhoneNumber={
                  handleCreateEventSetContactPhoneNumber
                }
                handleEventSetContactCountryCode={
                  handleCreateEventSetContactCountryCode
                }
              />

              <EventParticipants
                CreateEvent={CreateEvent}
                editEventMode={false}
                pauseCrop={eventPreview ? true : false}
                skipToId={'translationsLabel'}
                skipToLabel={CreateEvent.skipTo.skipToTranslations}
                //state
                participantImagesCropped={participantImagesCropped}
                setParticipantImagesCropped={setParticipantImagesCropped}
                participants={loadEventParticipants}
                translationsEnabled={loadTranslationsTranslationsEnabled}
                translationsRoomLanguages={loadTranslationsRoomLanguages}
                removedParticipantsWithDispatchedInvites={
                  removedParticipantsWithDispatchedInvites
                }
                //handlers
                handleSetParticipants={handleCreateEventSetParticipants}
                setRemovedParticipantsWithDispatchedInvites={
                  setRemovedParticipantsWithDispatchedInvites
                }
              />
              <EventTranslations
                initialize={initialize}
                CreateEvent={CreateEvent}
                skipToId={'agendaLabel'}
                skipToLabel={CreateEvent.skipTo.skipToAgenda}
                //state
                participants={loadEventParticipants}
                participantImagesCropped={participantImagesCropped}
                translationsEnabled={loadTranslationsTranslationsEnabled}
                translationsRoomCodeId={loadTranslationsRoomCodeId}
                translationsJoinRoomPasswordToggle={
                  loadTranslationsJoinRoomPasswordToggle
                }
                translationsRoomLanguages={loadTranslationsRoomLanguages}
                //pwd
                decryptedTranslationsPassword={decryptedTranslationsPassword}
                setDecryptedTranslationsPassword={
                  setDecryptedTranslationsPassword
                }
                //handlers
                handleSetParticipants={handleCreateEventSetParticipants}
                handleSetTranslationsTranslationsEnabled={
                  handleCreateEventSetTranslationsTranslationsEnabled
                }
                handleSetTranslationsRoomCodeId={
                  handleCreateEventSetTranslationsRoomCodeId
                }
                handleSetTranslationsJoinRoomPasswordToggle={
                  handleCreateEventSetTranslationsJoinRoomPasswordToggle
                }
                handleSetTranslationsPassword={
                  handleCreateEventSetTranslationsPassword
                }
                handleSetTranslationsRoomLanguages={
                  handleCreateEventSetTranslationsRoomLanguages
                }
              />

              <Agenda
                initialize={initialize}
                CreateEvent={CreateEvent}
                skipToId={'accessLabel'}
                skipToLabel={CreateEvent.skipTo.skipToAccess}
                //state
                loadAgendaItems={loadAgendaItems}
                loadAgendaFormat={loadAgendaFormat}
                //handlers
                handleSetAgendaFormat={handleCreateEventSetAgendaFormat}
                handleSetAgendaItems={handleCreateEventSetAgendaItems}
              />
              <Access
                initialize={initialize}
                CreateEvent={CreateEvent}
                skipToId={'registrationLabel'}
                skipToLabel={CreateEvent.skipTo.skipToRegistration}
                //state
                loadHostingOption={loadHostingOption}
                loadVirtualPlatform={loadVirtualPlatform}
                loadVirtualMeetingId={loadVirtualMeetingId}
                loadVirtualLink={loadVirtualLink}
                loadLocation={loadLocation}
                loadCoordinates={loadCoordinates}
                loadWheelchairAccessibility={loadWheelchairAccessibility}
                loadSignLanguageInterpreter={loadSignLanguageInterpreter}
                loadAccessibilityNotes={loadAccessibilityNotes}
                //pwd
                decryptedVirtualPasscode={decryptedVirtualPasscode}
                setDecryptedVirtualPasscode={setDecryptedVirtualPasscode}
                //handlers
                handleSetAccessibilityNotes={
                  handleCreateEventSetAccessibilityNotes
                }
                handleClearLocation={handleCreateEventClearLocation}
                handleSetCoordinates={handleCreateEventSetCoordinates}
                handleSetHostingOption={handleCreateEventSetHostingOption}
                handleSetLocation={handleCreateEventSetLocation}
                handleSetSignLanguageInterpreter={
                  handleCreateEventSetSignLanguageInterpreter
                }
                handleSetVirtualMeetingId={handleCreateEventSetVirtualMeetingId}
                handleSetVirtualPasscode={handleCreateEventSetVirtualPasscode}
                handleSetVirtualPlatform={handleCreateEventSetVirtualPlatform}
                handleSetVirtualMeetingLink={
                  handleCreateEventSetVirtualMeetingLink
                }
                handleSetWheelchairAccessibility={
                  handleCreateEventSetWheelchairAccessibility
                }
              />
              <Registration
                initialize={initialize}
                CreateEvent={CreateEvent}
                skipToId={'imagesLabel'}
                skipToLabel={CreateEvent.skipTo.skipToImages}
                //state
                translationsEnabled={loadTranslationsTranslationsEnabled}
                contactEnabled={loadContactProvideContact}
                loadHostingOption={loadHostingOption}
                loadMaxRegistrationsInPersonEnabled={
                  loadMaxRegistrationsInPersonEnabled
                }
                loadMaxRegistrationsVirtualEnabled={
                  loadMaxRegistrationsVirtualEnabled
                }
                loadEnablePrivateDetails={loadEnablePrivateDetails}
                loadRegistrationsActive={loadRegistrationsActive}
                loadApproveRegistrations={loadApproveRegistrations}
                loadMaxRegistrationsInPersonAmount={
                  loadMaxRegistrationsInPersonAmount
                }
                loadMaxRegistrationsVirtualAmount={
                  loadMaxRegistrationsVirtualAmount
                }
                loadPrivateDetails={loadPrivateDetails}
                //handlers
                handleSetApproveRegistrations={
                  handleCreateEventSetApproveRegistrations
                }
                handleSetMaxRegistrationsInPersonEnabled={
                  handleCreateEventSetMaxRegistrationsInPersonEnabled
                }
                handleSetMaxRegistrationsVirtualEnabled={
                  handleCreateEventSetMaxRegistrationsVirtualEnabled
                }
                handleSetEnablePrivateDetails={
                  handleCreateEventSetEnablePrivateDetails
                }
                handleSetMaxRegistrationsInPersonAmount={
                  handleCreateEventSetMaxRegistrationsInPersonAmount
                }
                handleSetMaxRegistrationsVirtualAmount={
                  handleCreateEventSetMaxRegistrationsVirtualAmount
                }
                handleSetPrivateDetails={handleCreateEventSetPrivateDetails}
                handleSetRegistrations={handleCreateEventSetRegistrations}
              />
              <Images
                initialize={initialize}
                CreateEvent={CreateEvent}
                pauseCrop={eventPreview ? true : false}
                editEventMode={true}
                skipToId={'tagsLabel'}
                skipToLabel={CreateEvent.skipTo.skipToTags}
                //state
                loadEventImageDisplay={loadEventImageDisplay}
                loadEventAdditionalImages={loadEventAdditionalImages}
                eventImageDisplayCropped={eventImageDisplayCropped}
                eventAdditionalImagesCropped={eventAdditionalImagesCropped}
                //handlers
                handleSetEventImageDisplay={
                  handleCreateEventSetEventImageDisplay
                }
                handleSetEventAdditionalImages={
                  handleCreateEventSetEventAdditionalImages
                }
                setEventImageDisplayCropped={setEventImageDisplayCropped}
                setEventAdditionalImagesCropped={
                  setEventAdditionalImagesCropped
                }
              />
              <EventTags
                CreateEvent={CreateEvent}
                skipToId={'initializeLabel'}
                skipToLabel={CreateEvent.skipTo.skipToInitialize}
                //state
                loadTinyTags={loadTinyTags}
                loadBigTags={loadBigTags}
                //handlers
                handleSetBigTags={handleCreateEventSetBigTags}
                handleSetTinyTags={handleCreateEventSetTinyTags}
              />

              <ScrollToTop focusId={'initializeLabel'} />
            </>
          ) : (
            organizeIsLoading && <Spinner />
          )}
          <div className="h48 h96--ph" />
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;

//hooks
import { useNoticeHandlers } from '../useNoticeHandlers';

//components
import Spinner from '../../../../components/Spinner/Spinner';
import ButtonTextSimple from '../../../../components/ButtonTextSimple/ButtonTextSimple';

const NoticeSettingsMenu = ({ NoticeOverlay }) => {
  //hooks
  const { handleRemoveAllNotices, removeAllNoticesIsLoading } =
    useNoticeHandlers();

  return (
    <li>
      <div className="noticeOverlay-action-btns__container mrg-tb24">
        {removeAllNoticesIsLoading ? (
          <Spinner
            minHeight={'44px'}
            maxHeight={'44px'}
            customClassName={' mrg-t12'}
          />
        ) : (
          <ButtonTextSimple
            text={NoticeOverlay.clearArchive}
            handler={() => handleRemoveAllNotices()}
            iconRight={true}
            iconClass={'mrg-l12 filter-gray'}
            customClassText={'color-gray'}
            customWrapper={
              'noticeOverlay-action-btn mrg-t12 full-width mrg-auto-lr flex-center'
            }
          />
        )}
      </div>
    </li>
  );
};

export default NoticeSettingsMenu;

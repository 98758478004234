import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../../../../../context/SettingsProvider';

//language
import useLanguageData from '../../../../../../language/useLanguageData';
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//components
import SpeakerActionBtns from '../../../../organize/eventSections/EventTranslationsSections/SpeakerActionBtns';
import TranslationsSpeakerData from '../../../../organize/eventSections/EventTranslationsSections/TranslationsSpeakerData';
import TranslationsSpeakerInputs from '../../../../organize/eventSections/EventTranslationsSections/TranslationsSpeakerInputs';
import ItemButton from '../../../../../../components/ItemButton/ItemButton';
import ParticipantEmailInvitation from '../../../../organize/eventSections/ParticipantSections/ParticipantEmailInvitation';

//for translations drafts, added editMode for email invitations since the email input is typically done through the participants section of organize events

const TranslationsSpeaker = ({
  prevSpeaker,
  handleSaveAndReturn,
  roomLanguages,
  speakerNameOn,
  participantFormSubmissionView,
  participantFormSubmissionHandler,
  submitIsLoading,
  handleRemoveSpeaker,
  actionButtonsOff,
}) => {
  const { contentLanguage } = useSettings();
  const { TranslationLanguageOpts } = useLanguageData();
  const { Generic } = useLanguageComponents();

  //state
  const [speaker, setSpeaker] = useState({});
  const [roomLanguageOpts, setRoomLanguageOpts] = useState([]);

  const [editObj, setEditObj] = useState();

  //UI
  const initializePreSpeakerStateEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (
      initializePreSpeakerStateEffectRan.current === false &&
      prevSpeaker?.id
    ) {
      initializePreSpeakerStateEffectRan.current = true;
      setSpeaker(prevSpeaker);
    }
  }, [prevSpeaker]); //set initialize state

  useEffect(() => {
    const translationOpts = TranslationLanguageOpts;
    const filteredOpts = Object.keys(translationOpts)
      .filter((key) => roomLanguages.includes(key))
      .reduce((obj, key) => {
        obj[key] = translationOpts[key];
        return obj;
      }, {});
    setRoomLanguageOpts(filteredOpts);
  }, [contentLanguage, roomLanguages]); //set language options for speaker.

  return (
    <div className="flex-column full-width">
      {speaker && (
        <>
          {!participantFormSubmissionView && !actionButtonsOff && (
            <SpeakerActionBtns
              speaker={speaker}
              handleSaveAndReturn={handleSaveAndReturn}
              setEditObj={setEditObj}
              editObj={editObj}
              handleRemoveSpeaker={handleRemoveSpeaker}
            />
          )}

          {speaker.organizedAs === 'create' && (
            <TranslationsSpeakerInputs
              speaker={speaker}
              roomLanguageOpts={roomLanguageOpts}
              speakerNameOn={speakerNameOn}
              setSpeaker={setSpeaker}
              roomLanguages={roomLanguages}
            />
          )}

          {speaker.organizedAs === 'emailInvitation' && (
            <>
              {!speaker.dispatches?.translationsDispatched ? (
                <ParticipantEmailInvitation
                  prevParticipant={speaker}
                  handleSaveAndReturn={handleSaveAndReturn}
                  handleRemoveParticipant={handleRemoveSpeaker}
                  translationsDraft={true}
                />
              ) : editObj ? (
                <TranslationsSpeakerInputs
                  speaker={speaker}
                  roomLanguageOpts={roomLanguageOpts}
                  speakerNameOn={speakerNameOn}
                  setSpeaker={setSpeaker}
                  roomLanguages={roomLanguages}
                />
              ) : (
                <TranslationsSpeakerData
                  speakerData={speaker?.translationsSpeakerData}
                  roomLanguages={roomLanguages}
                />
              )}
            </>
          )}
        </>
      )}

      {participantFormSubmissionView && (
        <ItemButton
          fullWidth={true}
          handler={() => participantFormSubmissionHandler(speaker)}
          text={Generic.submit}
          id={'translationsFormSubmissionBtn'}
          customWrapper={'mrg-t48'}
          isLoading={submitIsLoading}
        />
      )}
    </div>
  );
};

export default TranslationsSpeaker;

import { useEffect, useState } from 'react';

//Hooks
import useReCaptcha from '../../../hooks/useReCaptcha';
import { useApiStatus } from '../../../context/ApiStatusProvider';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import { useAdminRegistrationMutation } from './adminManagementApiSlice';

//Components
import TextArea from '../../../components/TextArea/TextArea';
import ItemButton from '../../../components/ItemButton/ItemButton';
import Spinner from '../../../components/Spinner/Spinner';
import PasswordConfirmInput from '../../../components/TextArea/PasswordConfirmInput';
import PhoneInput from '../../../components/TextArea/PhoneInput';
import EmailInput from '../../../components/TextArea/EmailInput';
import CreatePasswordInput from '../../../components/TextArea/CreatePasswordInput';

const AdminRegistration = () => {
  //Hooks
  const { handleError, handleSuccess, handleWarning } = useApiStatus();
  const { handleReCaptchaVerify } = useReCaptcha();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const [adminRegistration, { isLoading, isFetching }] =
    useAdminRegistrationMutation();

  //Component state
  const [submitValid, setSubmitValid] = useState(false);
  const [reset, setReset] = useState(false);

  //Account
  const [email, setEmail] = useState();
  const [validEmail, setValidEmail] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [newPasswordValid, setNewPasswordValid] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState();
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  //Personal
  const [pronouns, setPronouns] = useState();
  const [prefix, setPrefix] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [countryCode, setCountryCode] = useState();
  const [phoneNumberSaveValid, setPhoneNumberSaveValid] = useState(false);

  //company
  const [position, setPosition] = useState();

  //validations
  useEffect(() => {
    if (
      !isLoading &&
      validEmail &&
      newPasswordValid &&
      passwordsMatch &&
      phoneNumberSaveValid &&
      firstName &&
      lastName &&
      position
    ) {
      setSubmitValid(true);
    } else {
      setSubmitValid(false);
    }
  }, [
    isLoading,
    validEmail,
    newPasswordValid,
    passwordsMatch,
    phoneNumberSaveValid,
    firstName,
    lastName,
    position,
  ]);

  //Registration API
  async function handleCompleteRegistration(e) {
    if (!submitValid) return;
    e.preventDefault();

    const recaptchaVerified = await handleReCaptchaVerify(
      'ADMIN_REGISTER',
      false
    );

    if (recaptchaVerified !== true) return false;

    if (!isLoading || !isFetching) {
      try {
        await adminRegistration({
          adminAccountId,
          email,
          password: newPassword,
          prefix,
          pronouns,
          firstName,
          lastName,
          countryCode,
          phoneNumber,
          position,
        });

        setReset(true);
        handleSuccess({
          message: 'success',
          id: Date.now(),
          origin: 'AdminRegistration.js/handleCompleteRegistration',
        });
      } catch (error) {
        const newError = {
          error,
          id: Date.now(),
          origin: 'PARegMain.js/handleCompleteRegistration',
        };
        handleError(newError);
      }
    }
  }

  function resetData() {
    setSubmitValid(false);
    setReset(false);
    setEmail();
    setValidEmail(false);
    setNewPassword();
    setNewPasswordValid(false);
    setPasswordConfirm();
    setPasswordsMatch(false);
    setPronouns();
    setPrefix();
    setFirstName();
    setLastName();
    setPhoneNumber();
    setCountryCode();
    setPhoneNumberSaveValid(false);
    setPosition();
  }

  useEffect(() => {
    resetData();

    const timerId = setTimeout(() => {
      setReset(false);
    }, 1000);

    return () => clearTimeout(timerId);
  }, [reset]);

  return (
    <>
      {reset && (
        <div className="flex-center" style={{ width: '340px' }}>
          <Spinner />
        </div>
      )}
      {!reset && (
        <div className="mrg-l48  mrg-t24">
          <h1 className="content-label mrg-auto-left mrg-auto-right">
            Account
          </h1>

          <div className="h24" />
          <EmailInput
            asterisk={true}
            emailCollectionToCheck={'AdminAccount'}
            handleInput={setEmail}
            id={'email'}
            loadValue={email}
            setSaveValid={setValidEmail}
            reset={reset}
            checkRanAfterBlurToggle={true}
          />
          <div className="h24" />
          <CreatePasswordInput
            id={'newPassword'}
            asterisk={true}
            autocompleteOff={true}
            handleInput={setNewPassword}
            setValidPassword={setNewPasswordValid}
            reset={reset}
          />
          <div className="h24" />
          <PasswordConfirmInput
            asterisk={true}
            handleInput={setPasswordConfirm}
            id={'confirmNewPassword'}
            newPassword={newPassword}
            passwordConfirm={passwordConfirm}
            passwordsMatch={passwordsMatch}
            reset={reset}
            setPasswordsMatch={setPasswordsMatch}
          />
          <div className="h36" />

          <h1 className="content-label mrg-auto-left mrg-auto-right">
            Personal
          </h1>
          <div className="h24" />
          <TextArea
            customWidth={'340px'}
            disableEnter={true}
            handleChange={setPronouns}
            hideWordCount={true}
            id={'pronouns'}
            labelText={'Pronouns'}
            limit={20}
            loadTrigger={true}
            loadValue={pronouns}
            customWrapper={'mrg-b24--ph'}
            onBlurHandlerOff={true}
          />
          <div className="h24" />
          <TextArea
            customWidth={'108px'}
            disableEnter={true}
            handleChange={setPrefix}
            hideWordCount={true}
            id={'prefix'}
            labelText={'Prefix'}
            limit={8}
            loadTrigger={true}
            loadValue={prefix}
            onBlurHandlerOff={true}
          />
          <TextArea
            asterisk={true}
            disableEnter={true}
            handleChange={setFirstName}
            hideWordCount={true}
            id={'firstName'}
            labelText={'First Name'}
            limit={50}
            loadTrigger={true}
            loadValue={firstName}
            customWrapper={'mrg-t24'}
            onBlurHandlerOff={true}
          />
          <TextArea
            asterisk={true}
            disableEnter={true}
            handleChange={setLastName}
            hideWordCount={true}
            id={'lastName'}
            labelText={'Last Name'}
            limit={50}
            loadTrigger={true}
            loadValue={lastName}
            customWrapper={'mrg-t24'}
            onBlurHandlerOff={true}
          />
          <PhoneInput
            id={'accountPhoneInput'}
            setPhoneNumber={setPhoneNumber}
            phoneNumber={phoneNumber}
            countryCode={countryCode}
            setCountryCode={setCountryCode}
            setPhoneDataValid={setPhoneNumberSaveValid}
            asterisk={true}
            customWrapper={'mrg-t24'}
          />

          <h1 className="content-label mrg-auto-left mrg-auto-right mrg-t24">
            Company
          </h1>

          <TextArea
            asterisk={true}
            disableEnter={true}
            handleChange={setPosition}
            hideWordCount={true}
            id={'position'}
            labelText={'Position'}
            limit={120}
            loadTrigger={true}
            loadValue={position}
            customWrapper={'mrg-t24'}
            onBlurHandlerOff={true}
          />

          <ItemButton
            customWrapper={'mrg-t24'}
            isLoading={isLoading}
            text={'Submit'}
            fullWidth={true}
            handler={
              !submitValid
                ? () =>
                    handleWarning({
                      message: 'incompleteInformation',
                      statusCode: 500,
                      origin: 'ButtonSwitchLOrder.js',
                    })
                : (e) => handleCompleteRegistration(e)
            }
            unavailable={!submitValid}
          />

          <div className="h72" />
        </div>
      )}
    </>
  );
};

export default AdminRegistration;

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';
import useLanguageOrganize from '../../../../../language/features/useLanguageOrganize';
import useLanguageData from '../../../../../language/useLanguageData';

//utils
import { img_language, img_location } from '../../../../../assets/icons';

const MobileFilterSelections = ({
  filterSelection,
  setFilterSelection,
  distanceFilter,
  distanceMeasurement,
  searchLocationType,
  searchCountries,
  searchSpeakerLanguages,
  settingsFindSpeakersSearchSpeakerLanguagesNotListed,
  searchBigTags,
  searchTinyTags,
}) => {
  //language
  const { IconAlts } = useLanguageComponents();
  const { MobileFilterSelections } = useLanguageOrganize();
  const { BigTagIcon, TinyTagIcon } = useLanguageData();

  return (
    <ul className="fs-mob-con-search-filters mrg-auto-lr">
      <li
        className={`fs-mob-con-search-filters--li ${
          filterSelection === 'location' ? 'fs-mob-filter-selected' : ''
        }`}
      >
        <button
          className={`fs-mob-filter-btn `}
          onClick={() => {
            if (filterSelection === 'location') {
              setFilterSelection();
            } else {
              setFilterSelection('location');
            }
          }}
          title={MobileFilterSelections.locationFilter}
        >
          <img
            src={img_location}
            alt={IconAlts.iconLocationPin}
            style={{ minHeight: '48px' }}
          />
        </button>
        {searchLocationType === 'locationMap' && (
          <span className="fs-mob-filter-sum fs-mob-filter-sum">
            {distanceFilter} {distanceMeasurement}
          </span>
        )}
        {searchLocationType === 'locationCountry' && (
          <span className="fs-mob-filter-sum fs-mob-filter-sum">
            {searchCountries?.length}
          </span>
        )}
        {/* <span className="fs-mob-filter-btn--incomplete">
          <img src={warning} alt="" />
        </span> */}
      </li>
      <li
        className={`fs-mob-con-search-filters--li ${
          filterSelection === 'language' ? 'fs-mob-filter-selected' : ''
        }`}
      >
        <button
          className="fs-mob-filter-btn"
          onClick={() => {
            if (filterSelection === 'language') {
              setFilterSelection();
            } else {
              setFilterSelection('language');
            }
          }}
          title={MobileFilterSelections.languageFilter}
        >
          <img
            src={img_language}
            alt={IconAlts.iconLanguage}
            className="filter-theme"
            style={{ minHeight: '42px' }}
          />
        </button>
        {searchSpeakerLanguages?.length > 0 && (
          <span className="fs-mob-filter-sum fs-mob-filter-sum">
            {!searchSpeakerLanguages.includes('notListed') ? (
              <>{searchSpeakerLanguages?.length}</>
            ) : (
              <>
                {searchSpeakerLanguages?.length -
                  1 +
                  settingsFindSpeakersSearchSpeakerLanguagesNotListed?.length}
              </>
            )}
          </span>
        )}
      </li>
      <li
        className={`fs-mob-con-search-filters--li ${
          filterSelection === 'bigTags' ? 'fs-mob-filter-selected' : ''
        }`}
      >
        <button
          className="fs-mob-filter-btn"
          onClick={() => {
            if (filterSelection === 'bigTags') {
              setFilterSelection();
            } else {
              setFilterSelection('bigTags');
            }
          }}
          title={MobileFilterSelections.bigTagsFilter}
        >
          <img
            src={BigTagIcon}
            alt={IconAlts.iconBigTag}
            style={{ maxHeight: '36px' }}
          />
        </button>
        {searchBigTags?.length > 0 && (
          <span className="fs-mob-filter-sum fs-mob-filter-sum">
            {searchBigTags?.length}
          </span>
        )}
      </li>
      <li
        className={`fs-mob-con-search-filters--li ${
          filterSelection === 'tinyTags' ? 'fs-mob-filter-selected' : ''
        }`}
      >
        <button
          className="fs-mob-filter-btn"
          onClick={() => {
            if (filterSelection === 'tinyTags') {
              setFilterSelection();
            } else {
              setFilterSelection('tinyTags');
            }
          }}
          title={MobileFilterSelections.tinyTagsFilter}
        >
          <img
            src={TinyTagIcon}
            alt={IconAlts.iconTinyTag}
            style={{ maxHeight: '42px' }}
          />
        </button>
        {searchTinyTags?.length > 0 && (
          <span className="fs-mob-filter-sum fs-mob-filter-sum">
            {searchTinyTags?.length}
          </span>
        )}
      </li>
    </ul>
  );
};

export default MobileFilterSelections;

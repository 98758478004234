import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';
import { useGenericModal } from '../../context/GenericModalProvider';
import { useApiStatus } from '../../context/ApiStatusProvider';
import { useAuthReduxHandlers } from '../../features/main/auth/useAuthHandlers';
import { useAdminAuthReduxHandlers } from '../../features/admin/auth/useAdminAuthHandlers';

//language
import useLanguageLayout from '../../language/useLanguageLayout';

//Components
import Navbar from './Navbar';
import Footer from './Footer';
import SlideMenu from './SlideMenu';
import ErrorPage from '../ErrorPage';
import ApiStatusModal from '../../components/Modal/ApiStatusModal';
import GenericModal from '../../components/Modal/GenericModal';
import LoadingScreenContent from '../../components/LoadingScreenContent/LoadingScreenContent';
import NoticeOverlay from '../../features/main/notices/NoticeOverlay/NoticeOverlay';

//Utils
import ErrorBoundary from '../../utils/misc/ErrorBoundary';

const Layout = () => {
  //Hooks
  const { width } = useSettings();
  const { apiStatus, handleClearApiStatus } = useApiStatus();
  const { genericModal, genericModalMobileFloat } = useGenericModal();
  const { authCurrentToken } = useAuthReduxHandlers();
  const { adminAuthCurrentToken } = useAdminAuthReduxHandlers();

  //language
  const { App } = useLanguageLayout();

  //state
  const [componentIsMounting, setComponentIsMounting] = useState(true);

  useEffect(() => {
    const pageAnimationTimer = setTimeout(() => {
      setComponentIsMounting(false);
    }, 600);

    return () => clearTimeout(pageAnimationTimer);
  }, []); //page loading component animation

  return (
    <>
      {componentIsMounting && (
        <LoadingScreenContent
          loadCheck1={true}
          numberOfLoadChecks={1}
          customWrapper={'min-height-100vh'}
        />
      )}
      <div
        className={`${
          !componentIsMounting
            ? 'app onscreen-fade-in '
            : 'offscreen visibility-hidden'
        }`}
      >
        <ErrorBoundary fallback={<ErrorPage />}>
          {width <= 1280 && <SlideMenu />}

          <Navbar layoutIsMounting={componentIsMounting} />
          <main
            className="app__contents"
            aria-label={App.main}
            id={'app__contents'}
          >
            <Outlet />
          </main>
          {!authCurrentToken && !adminAuthCurrentToken && (
            <Footer layoutIsMounting={componentIsMounting} />
          )}
          {authCurrentToken && <NoticeOverlay />}

          {apiStatus?.length > 0 && (
            <div className="apiStatus-modal__wrapper">
              {apiStatus?.map((apiStatusObj) => (
                <ApiStatusModal
                  apiStatus={apiStatusObj}
                  handleClearApiStatus={handleClearApiStatus}
                  key={`${apiStatusObj?.id}`}
                />
              ))}
            </div>
          )}

          {(genericModal || genericModalMobileFloat) && <GenericModal />}
        </ErrorBoundary>
      </div>
    </>
  );
};

export default Layout;

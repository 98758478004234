//components
import ProfilePersonalBase from '../../../../components/Profiles/ProfilePersonalBase';
import ProfilePersonalSkeleton from '../../../../components/Profiles/ProfilePersonalSkeleton';

const FindSpeakersSelection = ({ viewSpeaker, setViewSpeaker }) => {
  return (
    <div className="fs-selection__wrapper">
      {viewSpeaker && (
        <ProfilePersonalBase
          profileData={viewSpeaker}
          bordersOn={true}
          customExitHandler={setViewSpeaker}
        />
      )}
      <div className="fs-selection__profile-skeleton-wrapper">
        <ProfilePersonalSkeleton />
      </div>
    </div>
  );
};

export default FindSpeakersSelection;

import { useState, useRef, useEffect } from 'react';
//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//only autogrows if using phone or phone narrow because drag and drop functionality will not be enabled; will use arrows instead
const DragDropTextarea = ({ item, index, handleItemTextChange, maxChar }) => {
  //Hooks
  const { window, dismissKeypad } = useSettings();

  //language
  const { DragDropList: DDLTranslation, Generic } = useLanguageComponents();

  //Component state
  const [charCount, setCharCount] = useState(0);

  //UI state
  const [shakeCharLimit, setShakeCharLimit] = useState(false);
  const [textAreaActive, setTextAreaActive] = useState(false);

  //Component variables
  const textarea = useRef();
  let charCounter;

  //Initialize functions
  useEffect(() => {
    //sets initial size according to auto_grow rather than css (which has none)
    if (window !== 'desktop' || window !== 'desktop--narrow') {
      auto_grow(`${item.id}-textarea`);
    }
  }, []);

  //Component functions
  useEffect(() => {
    if (charCount === maxChar && textAreaActive) {
      setShakeCharLimit(true);
    }
    if (!textAreaActive || charCount !== maxChar) {
      setShakeCharLimit(false);
    }
    /*eslint-disable-next-line*/
  }, [charCount, textAreaActive]);

  function auto_grow(id) {
    if (window !== 'desktop' || window !== 'desktop--narrow') {
      const element = document.getElementById(`${id}`);
      element.style.height = '42px';
      element.style.height = element.scrollHeight + 'px';
    }
  }

  function handleChange(e, index) {
    e.preventDefault();
    let text = e.target.value;
    handleItemTextChange(text, index);
    setCharCount(e.target.value.length);
  }

  //JSX conditional
  if (textAreaActive) {
    charCounter = (
      <div className="charCount fs14">
        <p
          className={`${charCount === maxChar ? 'maximum' : ''} ${
            shakeCharLimit ? 'shake' : ''
          }`}
        >
          {charCount}/{maxChar}
        </p>
      </div>
    );
  }

  return (
    <div className="textarea">
      <textarea
        onInput={() => auto_grow(`${item.id}-textarea`)}
        type="text"
        value={item.text}
        maxLength={`${maxChar}`}
        id={`${item.id}-textarea`}
        placeholder={
          window !== 'phone--narrow'
            ? `${DDLTranslation.agendaItemLong}`
            : `${DDLTranslation.agendaItemShort}`
        }
        onChange={(e) => handleChange(e, index)}
        ref={textarea}
        onKeyDown={(e) => dismissKeypad(e)}
        onFocus={() => setTextAreaActive(true)}
        onBlur={() => setTextAreaActive(false)}
        aria-label={`${Generic.characterLimit}  ${maxChar}. ${
          item.text && Generic.currentTextCharacters + item.text.length
        }.`}
        className="fs16 textarea highlight-breg-theme"
        enterKeyHint="enter"
      />
      {charCounter}
    </div>
  );
};

export default DragDropTextarea;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  registrationInitialized: false,
  generic: {
    accessCode: '',
    accountType: '',
    accountVerificationURL: '',
    componentArr: [],
    isLoadingCompleteRegistration: false,
    isSuccess: false,
    registrationPage: 0,
    registrationPageIndex: 0,
    registrationPageStatus: [],
    resendVerificationEmailLimit: 0,
    unverifiedAccountId: '',
  },
  attendee: {
    bigTags: [],
    coords: '',
    distanceFilter: 30,
    distanceMeasurement: 'km',
    eventSearchOnlineCountries: [],
    placeCoordinates: { lat: '', lng: '' },
    searchLanguages: [],
    searchLanguagesNotListed: [],
    tinyTagGroups: [],
    tinyTags: [],
  },
  billing: {
    taxData: [
      {
        taxTitle: '',
        taxRate: '',
        taxTotal: '',
      },
    ],
    totalCost: 0,
    subtotalCost: 0,
  },
  emailPreferences: {
    myndFullNewsletter: false,
    promotions: false,
  },
  personal: {
    contentLanguage: '',
    countryCode: '',
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirm: '',
    phoneNumber: '',
    prefix: '',
    pronouns: '',
    timeZone: '',
    profileImageAlt: '',
  },
  selections: {
    attendEventsOptIn: false,
    organizeEventsOptIn: false,
    speakerEventsOptIn: false,
    translationRoomCodesOptIn: false,
  },
  translationRooms: {
    roomCodes: [],
    isLoadingRoomCodeRegistrationSearch: false,
  },
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState: initialState,
  reducers: {
    resetRegistration: (state) => {
      return initialState;
    },
    softResetRegistration: (state) => {
      return { ...initialState, generic: state.generic };
    },
    //Generic
    setAccessCode: (state, action) => {
      state.generic.accessCode = action.payload;
    },
    setAccountType: (state, action) => {
      state.generic.accountType = action.payload;

      if (action.payload === 'personal') {
        state.generic.componentArr = ['personalAccountInfo'];
      } else if (action.payload === 'institutional') {
        state.generic.componentArr = ['institutionalAccountInfo'];
      }
    },
    setAccountVerificationURL: (state, action) => {
      state.generic.accountVerificationURL = action.payload;
    },
    setRegistrationPage: (state, action) => {
      state.generic.registrationPage = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.generic.isSuccess = action.payload;
    },
    setRegistrationPageStatus: (state, action) => {
      state.generic.registrationPageStatus = action.payload;
    },
    setRegistrationPageIndex: (state, action) => {
      state.generic.registrationPageIndex = action.payload;
    },
    setRegistrationIsLoadingCompleteRegistration: (state, action) => {
      state.generic.isLoadingCompleteRegistration = action.payload;
    },
    setRegistrationComponentArr: (state, action) => {
      state.generic.componentArr = action.payload;
    },
    setResendVerificationEmailLimit: (state, action) => {
      state.generic.resendVerificationEmailLimit = action.payload;
    },
    setUnverifiedAccountId: (state, action) => {
      state.generic.unverifiedAccountId = action.payload;
    },
    //Selections
    setTranslationRoomCodesOptIn: (state, action) => {
      state.selections.translationRoomCodesOptIn = action.payload;
      if (action.payload === false) {
        //reset
        state.translationRooms.roomCodes = [];
      }
    },
    setAttendEventsOptIn: (state, action) => {
      state.selections.attendEventsOptIn = action.payload;
      if (action.payload === false) {
        //reset
        state.attendee = {
          bigTags: [],
          coords: '',
          distanceFilter: 5,
          distanceMeasurement: 'km',
          eventSearchOnlineCountries: [],
          placeCoordinates: { lat: '', lng: '' },
          searchLanguages: ['select'],
          tinyTagGroups: [],
          tinyTags: [],
        };
      }
    },
    setSpeakerEventsOptIn: (state, action) => {
      state.selections.speakerEventsOptIn = action.payload;
    },
    setOrganizeEventsOptIn: (state, action) => {
      state.selections.organizeEventsOptIn = action.payload;
    },
    //Billing
    setBillingTaxData: (state, action) => {
      state.billing.taxData = action.payload;
    },
    setBillingTotalCost: (state, action) => {
      state.billing.totalCost = action.payload;
    },
    setBillingSubtotalCost: (state, action) => {
      state.billing.subtotalCost = action.payload;
    },
    //Personal
    setPersonalContentLanguage: (state, action) => {
      state.personal.contentLanguage = action.payload;
    },
    setPersonalCountryCode: (state, action) => {
      state.personal.countryCode = action.payload;
    },
    setPersonalEmail: (state, action) => {
      state.personal.email = action.payload;
    },
    setPersonalFirstName: (state, action) => {
      state.personal.firstName = action.payload;
    },
    setPersonalLastName: (state, action) => {
      state.personal.lastName = action.payload;
    },
    setPersonalPassword: (state, action) => {
      state.personal.password = action.payload;
    },
    setPersonalPasswordConfirm: (state, action) => {
      state.personal.passwordConfirm = action.payload;
    },
    setPersonalPhoneNumber: (state, action) => {
      state.personal.phoneNumber = action.payload;
    },
    setPersonalPrefix: (state, action) => {
      state.personal.prefix = action.payload;
    },
    setPersonalPronouns: (state, action) => {
      state.personal.pronouns = action.payload;
    },
    setPersonalTimeZone: (state, action) => {
      state.personal.timeZone = action.payload;
    },
    setPersonalProfileImageAlt: (state, action) => {
      state.personal.profileImageAlt = action.payload;
    },
    //Attendee
    setAttendeeBigTags: (state, action) => {
      state.attendee.bigTags = action.payload;
    },
    setAttendeeCoords: (state, action) => {
      state.attendee.coords = action.payload;
    },
    setAttendeeDistanceFilter: (state, action) => {
      state.attendee.distanceFilter = action.payload;
    },
    setAttendeeDistanceMeasurement: (state, action) => {
      state.attendee.distanceMeasurement = action.payload;
    },
    setAttendeeEventSearchOnlineCountries: (state, action) => {
      state.attendee.eventSearchOnlineCountries = action.payload;
    },
    setAttendeePlaceCoordinates: (state, action) => {
      state.attendee.placeCoordinates = action.payload;
    },
    setAttendeeSearchLanguages: (state, action) => {
      state.attendee.searchLanguages = action.payload;
    },
    setAttendeeSearchLanguagesNotListed: (state, action) => {
      state.attendee.searchLanguagesNotListed = action.payload;
    },
    setAttendeeTinyTagGroups: (state, action) => {
      state.attendee.tinyTagGroups = action.payload;
    },
    setAttendeeTinyTags: (state, action) => {
      state.attendee.tinyTags = action.payload;
    },

    //TranslationRoomCodes
    setTranslationRoomsRoomCodes: (state, action) => {
      state.translationRooms.roomCodes = action.payload;
    },

    //Email Preferences
    setEmailPreferencesMyndFullNewsletter: (state, action) => {
      state.emailPreferences.myndFullNewsletter = action.payload;
    },
    setEmailPreferencesPromotions: (state, action) => {
      state.emailPreferences.promotions = action.payload;
    },
  },
});

export const {
  resetRegistration,
  softResetRegistration,

  //Generic
  setAccessCode,
  setAccountType,
  setAccountVerificationURL,
  setRegistrationPage,
  setRegistrationPageStatus,
  setRegistrationPageIndex,
  setTranslationRoomCodesOptIn,
  setAttendEventsOptIn,
  setSpeakerEventsOptIn,
  setOrganizeEventsOptIn,
  setRegistrationIsLoadingCompleteRegistration,
  setIsSuccess,
  setResendVerificationEmailIsLoading,
  setResendVerificationEmailLimit,
  setRegistrationComponentArr,
  setUnverifiedAccountId,

  //Attendee
  setAttendeeBigTags,
  setAttendeeCoords,
  setAttendeeDistanceFilter,
  setAttendeeDistanceMeasurement,
  setAttendeeEventSearchOnlineCountries,
  setAttendeePlaceCoordinates,
  setAttendeeSearchLanguages,
  setAttendeeSearchLanguagesNotListed,
  setAttendeeTinyTagGroups,
  setAttendeeTinyTags,

  //Billing
  setBillingTaxData,
  setBillingTotalCost,
  setBillingSubtotalCost,

  //Email Preferences
  setEmailPreferencesMyndFullNewsletter,
  setEmailPreferencesPromotions,

  //Personal
  setPersonalContentLanguage,
  setPersonalCountryCode,
  setPersonalEmail,
  setPersonalFirstName,
  setPersonalLastName,
  setPersonalPassword,
  setPersonalPasswordConfirm,
  setPersonalPhoneNumber,
  setPersonalPrefix,
  setPersonalProfileImageAlt,
  setPersonalPronouns,
  setPersonalTimeZone,

  //TranslationRooms
  setTranslationRoomsRoomCodes,
} = registrationSlice.actions;

export default registrationSlice.reducer;

//Generic
export const selectRegistrationGenericAccessCode = (state) =>
  state.registration?.generic?.accessCode;

export const selectRegistrationGenericAccountType = (state) =>
  state.registration?.generic?.accountType;

export const selectRegistrationGenericAccountVerificationURL = (state) =>
  state.registration?.generic?.accountVerificationURL;

export const selectRegistrationGenericRegistrationPage = (state) =>
  state.registration?.generic?.registrationPage;

export const selectRegistrationGenericRegistrationPageStatus = (state) =>
  state.registration?.generic?.registrationPageStatus;

export const selectRegistrationGenericRegistrationPageIndex = (state) =>
  state.registration?.generic?.registrationPageIndex;

export const selectRegistrationGenericIsLoadingCompleteRegistration = (state) =>
  state.registration?.generic?.isLoadingCompleteRegistration;

export const selectRegistrationGenericIsSuccess = (state) =>
  state.registration?.generic?.isSuccess;

export const selectRegistrationGenericComponentArr = (state) =>
  state.registration?.generic?.componentArr;

export const selectRegistrationGenericResendVerificationEmailIsLoading = (
  state
) => state.registration?.generic?.resendVerificationEmailIsLoading;

export const selectRegistrationGenericResendVerificationEmailLimit = (state) =>
  state.registration?.generic?.resendVerificationEmailLimit;

export const selectRegistrationGenericUnverifiedAccountId = (state) =>
  state.registration?.generic?.unverifiedAccountId;

//Attendee
export const selectRegistrationAttendeeBigTags = (state) =>
  state.registration?.attendee?.bigTags;
export const selectRegistrationAttendeeCoords = (state) =>
  state.registration?.attendee?.coords;
export const selectRegistrationAttendeeDistanceFilter = (state) =>
  state.registration?.attendee?.distanceFilter;
export const selectRegistrationAttendeeDistanceMeasurement = (state) =>
  state.registration?.attendee?.distanceMeasurement;
export const selectRegistrationAttendeeEventSearchOnlineCountries = (state) =>
  state.registration?.attendee?.eventSearchOnlineCountries;
export const selectRegistrationAttendeePlaceCoordinates = (state) =>
  state.registration?.attendee?.placeCoordinates;
export const selectRegistrationAttendeeSearchLanguages = (state) =>
  state.registration?.attendee?.searchLanguages;
export const selectRegistrationAttendeeSearchLanguagesNotListed = (state) =>
  state.registration?.attendee?.searchLanguagesNotListed;
export const selectRegistrationAttendeeTinyTagGroups = (state) =>
  state.registration?.attendee?.tinyTagGroups;
export const selectRegistrationAttendeeTinyTags = (state) =>
  state.registration?.attendee?.tinyTags;

//Billing
export const selectRegistrationBillingTaxData = (state) =>
  state.registration?.billing?.taxData;

export const selectRegistrationBillingTotalCost = (state) =>
  state.registration?.billing?.totalCost;

export const selectRegistrationBillingSubtotalCost = (state) =>
  state.registration?.billing?.subtotalCost;

//Selections
export const selectRegistrationSelectionsTranslationRoomCodesOptIn = (state) =>
  state.registration?.selections?.translationRoomCodesOptIn;

export const selectRegistrationSelectionsAttendEventsOptIn = (state) =>
  state.registration?.selections?.attendEventsOptIn;

export const selectRegistrationSelectionsSpeakerEventsOptIn = (state) =>
  state.registration?.selections?.speakerEventsOptIn;

export const selectRegistrationSelectionsOrganizeEventsOptIn = (state) =>
  state.registration?.selections?.organizeEventsOptIn;

//Personal
export const selectRegistrationPersonalContentLanguage = (state) =>
  state.registration?.personal?.contentLanguage;
export const selectRegistrationPersonalCountryCode = (state) =>
  state.registration?.personal?.countryCode;
export const selectRegistrationPersonalEmail = (state) =>
  state.registration?.personal?.email;
export const selectRegistrationPersonalFirstName = (state) =>
  state.registration?.personal?.firstName;
export const selectRegistrationPersonalLastName = (state) =>
  state.registration?.personal?.lastName;
export const selectRegistrationPersonalPassword = (state) =>
  state.registration?.personal?.password;
export const selectRegistrationPersonalPasswordConfirm = (state) =>
  state.registration?.personal?.passwordConfirm;
export const selectRegistrationPersonalPhoneNumber = (state) =>
  state.registration?.personal?.phoneNumber;
export const selectRegistrationPersonalPrefix = (state) =>
  state.registration?.personal?.prefix;
export const selectRegistrationPersonalProfileImageAlt = (state) =>
  state.registration?.personal?.profileImageAlt;
export const selectRegistrationPersonalPronouns = (state) =>
  state.registration?.personal?.pronouns;
export const selectRegistrationPersonalTimeZone = (state) =>
  state.registration?.personal?.timeZone;

//TranslationRooms
export const selectRegistrationTranslationRoomsRoomCodes = (state) =>
  state.registration?.translationRooms?.roomCodes;

//Email Preferences
export const selectRegistrationEmailPreferencesMyndFullNewsletter = (state) =>
  state.registration?.emailPreferences?.myndFullNewsletter;

export const selectRegistrationEmailPreferencesPromotions = (state) =>
  state.registration?.emailPreferences?.promotions;

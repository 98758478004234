import { apiSlice } from '../../../app/api/apiSlice';
import { setAuthIsLoggingOut, setCredentials } from './authSlice';
import { emptyAccountData } from '../account/accountSlice';
import { addReduxApiStatus } from '../apiStatus/apiStatusSlice';
import { setAdminCredentials } from '../../admin/auth/adminAuthSlice';
import { emptyAdminAccountData } from '../../admin/account/adminAccountSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/api/main/auth',
        method: 'PATCH',
        body: { ...credentials },
      }),
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { email, roles, accessToken } = data;
          dispatch(setCredentials({ email, accessToken, roles }));
          dispatch(
            setAdminCredentials({ email: '', accessToken: '', roles: '' })
          ); //clear any admin credentials + account
          return dispatch(emptyAdminAccountData());
          //set regular credentials
        } catch (error) {
          const err = {
            id: Date.now(),
            status: 'error',
            error: error.error,
            origin: 'AuthApiSlice/login',
          };
          return dispatch(addReduxApiStatus(err));
        }
      },
      providesTags: ['Auth'],
    }),
    refresh: builder.query({
      query: () => '/refresh',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { email, roles, accessToken } = data;
          dispatch(setCredentials({ email, accessToken, roles }));
          return data;
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV authApiSlice, refresh error:', error);
          }
        }
      },
      providesTags: ['Auth'],
    }),
    logout: builder.query({
      query: () => '/api/main/logout',
      transformErrorResponse: (response, meta, arg) => {
        return response.data;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          dispatch(setAuthIsLoggingOut(true));
          await queryFulfilled;
          dispatch(setCredentials({ email: '', accessToken: '', roles: '' }));
          dispatch(emptyAccountData());
          return dispatch(setAuthIsLoggingOut(false));
        } catch (error) {
          if (process.env.REACT_APP_ENV === 'development') {
            console.log('DEV authApiSlice, logout error:', error);
          }
          dispatch(setCredentials({ email: '', accessToken: '', roles: '' }));
          dispatch(emptyAccountData());
          return dispatch(setAuthIsLoggingOut(false));
        }
      },
    }),
  }),
});

export const { useLoginMutation, useLazyRefreshQuery, useLazyLogoutQuery } =
  authApiSlice;

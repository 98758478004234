//language
import useLanguageComponents from '../../../language/useLanguageComponents';
import useLanguageData from '../../../language/useLanguageData';

//components
import LoadBucketImage from '../../LoadBucketImage/LoadBucketImage';

//utils
import { img_clock2, img_language } from '../../../assets/icons';

const ProfilePersonalInfo = ({
  ProfilePersonal,
  profile,
  setProfileImgLoaded,
}) => {
  //language
  const { IconAlts } = useLanguageComponents();
  const { TimeZoneOpts, LanguageSimpleOpts } = useLanguageData();

  return (
    <>
      <div className="flex-row full-width mrg-b24">
        <div
          className="flex-column flex-center full-width mrg-r12"
          style={{ maxHeight: 'fit-content' }}
        >
          <LoadBucketImage
            imagePath={profile?.personal?.profileImage}
            imgClassName="account-profile-img"
            backupPath={'account_img_default.png'}
            partialPath={'myndfull_account_images'}
            containerClassName={'mrg-auto-bottom'}
            altAriaText={profile?.personal?.profileImageAlt}
            setImageLoadConfirmation={setProfileImgLoaded}
          />
        </div>
        <div className="flex-column flex-start full-width">
          <h1 className="fs18 fwb access-ob access-o6" tabIndex="0">
            {profile?.personal?.prefix && `${profile?.personal?.prefix} `}
            {profile?.personal?.firstName && `${profile?.personal?.firstName} `}
            {profile?.personal?.lastName && `${profile?.personal?.lastName}`}
          </h1>
          {profile?.personal?.pronouns && (
            <p className="fs16 access-ob" tabIndex="0">
              ({profile?.personal?.pronouns})
            </p>
          )}
          <div
            className="flex-row mrg-tb12 align-center"
            title={ProfilePersonal.timeZone}
          >
            <img
              src={img_clock2}
              alt={IconAlts.iconClock}
              style={{ height: '28px', width: '24px' }}
              className="mrg-r12 filter-theme"
            />
            <p className="fs16 access-ob" tabIndex="0">
              {TimeZoneOpts[profile?.appSettings?.timeZone]}
            </p>
          </div>
          <div className="flex-row mrg-t12" title={ProfilePersonal.languages}>
            <img
              src={img_language}
              alt={IconAlts.iconLanguage}
              style={{ height: '21px', width: '24px' }}
              className="filter-theme mrg-r12"
            />
            <div
              className="flex-column flex-start fs18 list-style-none align-center access-ob"
              tabIndex="0"
            >
              {profile?.speaker?.speakerLanguages?.length > 0 ? (
                <>
                  {profile?.speaker?.speakerLanguages?.map((l, index) => {
                    const lastItem =
                      profile?.speaker?.speakerLanguages?.length - 1 === index;

                    return `${LanguageSimpleOpts[l]}${lastItem ? '' : ', '}`;
                  })}
                </>
              ) : (
                <>{LanguageSimpleOpts[profile?.appSettings?.contentLanguage]}</>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePersonalInfo;

import { Link } from 'react-router-dom';
import { useState } from 'react';

//Hooks
import { useSettings } from '../../context/SettingsProvider';

//language
import useLanguageLayout from '../../language/useLanguageLayout';

//api
import { useAdminAuthReduxHandlers } from '../../features/admin/auth/useAdminAuthHandlers';
import { useAdminAccountReduxHandlers } from '../../features/admin/account/useAdminAccountHandlers';
import { useLazyAdminLogoutQuery } from '../../features/admin/auth/adminAuthApiSlice';

//Utils
import { login, linkedin, logOut } from '../../assets/icons';

const AdminSlideMenu = () => {
  //Hooks
  const { adminAuthCurrentRoles } = useAdminAuthReduxHandlers();
  const { openSlideMenu, setOpenSlideMenu, handleMobileTap } = useSettings();
  const { NavbarLoggedIn, SlideMenu } = useLanguageLayout();
  const { adminAccountId } = useAdminAccountReduxHandlers();

  const [adminLogOut] = useLazyAdminLogoutQuery();

  //UI state
  const [tapHighlightSlideBtn, setTapHighlightSlideBtn] = useState();

  //UI functions

  function handleLogOutAndSlideMenu() {
    handleMobileTap(
      [
        () => setTapHighlightSlideBtn('slide-logout'),
        () => setTapHighlightSlideBtn(),
      ],
      [() => setOpenSlideMenu(false), () => adminLogOut()]
    );
  }

  function handleCloseSlideMenu(id) {
    handleMobileTap(
      [() => setTapHighlightSlideBtn(id), () => setTapHighlightSlideBtn()],
      [() => setOpenSlideMenu(false)]
    );
  }

  return (
    <>
      <nav
        className={`slidemenu ${openSlideMenu ? 'slidemenu__open' : ''}`}
        aria-label={
          openSlideMenu
            ? `${SlideMenu.slideMenuOnScreen}`
            : `${SlideMenu.slideMenuOffScreen}`
        }
      >
        <div className="slidemenu__container">
          <div className="nav__links">
            {!adminAuthCurrentRoles || !adminAccountId ? (
              <Link
                id="top-link"
                to={{ pathname: '/' }}
                onClick={() => handleCloseSlideMenu('top-link')}
                tabIndex="0"
                className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                  tapHighlightSlideBtn === 'top-link' ? 'nav-link--clicked' : ''
                }`}
              >
                {NavbarLoggedIn.signIn}
                <img
                  src={login}
                  alt={NavbarLoggedIn.signIn}
                  aria-hidden="true"
                />
              </Link>
            ) : (
              <>
                <Link
                  onClick={handleLogOutAndSlideMenu}
                  tabIndex="0"
                  className={`fwsb fs21 fs18--ph color-white access-ob access-o6 ${
                    tapHighlightSlideBtn === 'slide-logout'
                      ? 'nav-link--clicked'
                      : ''
                  }`}
                  id="slide-logout"
                >
                  {NavbarLoggedIn.signOut}
                  <img
                    src={logOut}
                    alt={NavbarLoggedIn.signOut}
                    aria-hidden="true"
                  />
                </Link>
              </>
            )}
          </div>
        </div>
        <div className="slidemenu__footer">
          <div className="social-media">
            <a
              href="https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89784148&keywords=myndfull&origin=RICH_QUERY_SUGGESTION&position=0&searchId=353b33b8-d1f3-478a-a051-a844ab85140a&sid=*cc"
              aria-label={SlideMenu.visitOurLinkedInPage}
              className="access-ob access-o6"
            >
              <img
                src={linkedin}
                alt="LinkedIn"
                className="filter-white"
                aria-hidden="true"
              />
            </a>
          </div>
        </div>
      </nav>
      <button
        className={`access-ow access-o-12 ${
          openSlideMenu ? 'slidemenu__exit' : 'remove'
        }`}
        onClick={handleCloseSlideMenu}
        tabIndex="0"
        aria-label={SlideMenu.closeNavigationMenu}
        id="overlay"
      />
    </>
  );
};

export default AdminSlideMenu;

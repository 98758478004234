import { useEffect, useState } from 'react';

//Hooks
import { useSettingsHandlers } from '../useSettingsHandlers';
import { useAccountReduxHandlers } from '../../account/useAccountHandlers';
import { useSettings } from '../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../language/useLanguageComponents';
import useLanguageData from '../../../../language/useLanguageData';
import useLanguageSettings from '../../../../language/features/useLanguageSettings';

//Components
import LoadBucketImage from '../../../../components/LoadBucketImage/LoadBucketImage';
import SettingsQuickNavigate from '../../../../components/SettingsQuickNavigate/SettingsQuickNavigate';

//Utility
import { folder } from '../../../../assets/icons/index';

const TagsList = () => {
  //Hooks
  const { width } = useSettings();
  const {
    accountBigTags: bigTags,
    accountTinyTags: tinyTags,
    accountTinyTagGroups: tinyTagGroups,
    accountCommunityFollowing,
    accountCommunityFollowingGroups,
  } = useAccountReduxHandlers();
  const {
    //General
    settingsEventSearchView,

    //Big Tags
    settingsSelectedBigTags: selectedBigTags,
    settingsSelectAllBTBtn: selectAllBT,
    handleSettingsRemoveSelectedBigTags,
    handleSettingsAddSelectedBigTags,
    handleSettingsRemoveAllSelectedBigTags,
    handleSettingsSetSelectAllBTBtn,

    //My Events
    settingsSelectedMyEvents: selectedMyEvents,
    settingsSelectAllMyEventsBtn,
    handleSettingsSetSelectedMyEvents,
    handleSettingsSetSelectAllMyEventsBtn,

    //Tiny Tags
    settingsSelectedTinyTags: selectedTinyTags,
    settingsSelectedTinyTagGroupIds: selectedTinyTagGroupIds,
    settingsSelectAllTTBtn: selectAllTT,
    handleSettingsRemoveSelectedTinyTags,
    handleSettingsAddSelectedTinyTags,
    handleSettingsSetSelectAllTTBtn,
    handleSettingsRemoveAllSelectedTinyTags,
    handleSettingsRemoveSelectedTinyTagGroupIds,
    handleSettingsAddSelectedTinyTagGroupIds,
    handleSettingsRemoveAllSelectedTinyTagGroupIds,

    //Community
    settingsSelectedAccountsFollowingIds: selectedAccountsFollowing,
    settingsSelectedAccountsFollowingGroupIds:
      selectedAccountsFollowingGroupIds,
    settingsSelectAllAccountsFollowingBtn: selectAllAccountsFollowing,
    handleSettingsRemoveAllSelectedAccountsFollowingIds,
    handleSettingsRemoveSelectedAccountsFollowingId,
    handleSettingsAddSelectedAccountsFollowingId,
    handleSettingsSetSelectAllAccountsFollowingBtn,
    handleSettingsRemoveSelectedAccountsFollowingGroupIds,
    handleSettingsAddSelectedAccountsFollowingGroupIds,
    handleSettingsRemoveAllSelectedAccountsFollowingGroupIds,
  } = useSettingsHandlers();

  //language
  const { SettingsEventsLanguage } = useLanguageSettings();
  const { IconAlts } = useLanguageComponents();
  const { BigTagsOpts } = useLanguageData();

  //Component state
  const [showAllTags, setShowAllTags] = useState(false);

  //UI functions
  useEffect(() => {
    setShowAllTags(false);
  }, [settingsEventSearchView]);

  //Component funcitons
  function handleMyEventsClick(key) {
    if (selectedMyEvents?.includes(key)) {
      let newArr = selectedMyEvents?.filter((x) => x !== key);
      handleSettingsSetSelectedMyEvents(newArr);
    } else {
      let newArr = [...selectedMyEvents, key];
      handleSettingsSetSelectedMyEvents(newArr);
    }
  }

  useEffect(() => {
    if (selectedMyEvents?.length === 0 && settingsSelectAllMyEventsBtn) {
      handleSettingsSetSelectAllMyEventsBtn(false);
    }

    if (selectedMyEvents?.length === 3 && !settingsSelectAllMyEventsBtn) {
      handleSettingsSetSelectAllMyEventsBtn(true);
    }

    /*eslint-disable-next-line*/
  }, [selectedMyEvents]);

  function handleSelectAll(selectAll) {
    if (settingsEventSearchView === 'myevents') {
      if (selectAll) {
        handleSettingsSetSelectedMyEvents([
          'registered',
          'saved',
          'participationEvents',
        ]);
      } else if (!selectAll) {
        handleSettingsSetSelectedMyEvents([]);
      }
      handleSettingsSetSelectAllMyEventsBtn(selectAll);
    }

    if (settingsEventSearchView === 'bigtags') {
      if (selectAll) {
        handleSettingsRemoveAllSelectedBigTags();
      } else if (!selectAll) {
        let newSelections = bigTags.filter(
          (bT) => !selectedBigTags.includes(bT)
        );
        newSelections?.map((bT) => handleSettingsAddSelectedBigTags(bT));
      }
      handleSettingsSetSelectAllBTBtn(!selectAll);
    }

    if (settingsEventSearchView === 'tinytags') {
      if (selectAll) {
        handleSettingsRemoveAllSelectedTinyTags();
        handleSettingsRemoveAllSelectedTinyTagGroupIds();
      } else if (!selectAll) {
        let newSelections = tinyTags.filter(
          (tT) => !selectedTinyTags.includes(tT)
        );

        let newSelectionGroups = tinyTagGroups.filter(
          (tG) => !selectedTinyTagGroupIds.includes(tG.id)
        );
        newSelections?.map((tT) => handleSettingsAddSelectedTinyTags(tT));
        newSelectionGroups?.map((tG) =>
          handleSettingsAddSelectedTinyTagGroupIds(tG.id)
        );
      }

      handleSettingsSetSelectAllTTBtn(!selectAll);
    }

    if (settingsEventSearchView === 'community') {
      if (selectAll) {
        handleSettingsRemoveAllSelectedAccountsFollowingIds();
        handleSettingsRemoveAllSelectedAccountsFollowingGroupIds();
      } else if (!selectAll) {
        let followingIds = [];

        accountCommunityFollowing?.map((acct) => followingIds.push(acct?._id));

        let newSelections = followingIds.filter(
          (account) => !selectedAccountsFollowing.includes(account._id)
        );

        let groupIds = [];
        accountCommunityFollowingGroups?.((group) => groupIds.push(group.id));

        let newSelectionGroups = groupIds.filter(
          (followingGroup) =>
            !selectedAccountsFollowingGroupIds.includes(followingGroup.id)
        );

        newSelections?.map((account) =>
          handleSettingsAddSelectedAccountsFollowingId(account._id)
        );
        newSelectionGroups?.map((followingGroup) =>
          handleSettingsAddSelectedAccountsFollowingGroupIds(followingGroup.id)
        );
      }
      handleSettingsSetSelectAllAccountsFollowingBtn(!selectAll);
    }
  }

  //Big Tags
  function handleBigTag(bigTag) {
    if (selectedBigTags.includes(bigTag)) {
      handleSettingsRemoveSelectedBigTags(bigTag);
    } else {
      handleSettingsAddSelectedBigTags(bigTag);
    }
  }

  useEffect(() => {
    if (selectedBigTags?.length === 0 && selectAllBT) {
      handleSettingsSetSelectAllBTBtn(!selectAllBT);
    }
    if (selectedBigTags?.length === bigTags?.length && !selectAllBT) {
      handleSettingsSetSelectAllBTBtn(!selectAllBT);
    }
    /*eslint-disable-next-line*/
  }, [selectedBigTags]); //bT select all when selecting or unselecting all tags

  //Tiny Tags
  function handleTinyTag(tinyTag) {
    if (selectedTinyTags.includes(tinyTag)) {
      handleSettingsRemoveSelectedTinyTags(tinyTag);
    } else {
      handleSettingsAddSelectedTinyTags(tinyTag);
    }
  }

  function handleTinyTagGroup(tinyTagGroupId) {
    if (selectedTinyTagGroupIds.includes(tinyTagGroupId)) {
      handleSettingsRemoveSelectedTinyTagGroupIds(tinyTagGroupId);
    } else {
      handleSettingsAddSelectedTinyTagGroupIds(tinyTagGroupId);
    }
  }

  useEffect(() => {
    if (
      selectedTinyTags?.length === 0 &&
      selectedTinyTagGroupIds?.length === 0 &&
      selectAllTT
    ) {
      handleSettingsSetSelectAllTTBtn(!selectAllTT);
    }
    if (
      selectedTinyTags?.length === tinyTags?.length &&
      selectedTinyTagGroupIds?.length === tinyTagGroups?.length &&
      !selectAllTT
    ) {
      handleSettingsSetSelectAllTTBtn(!selectAllTT);
    }
    /*eslint-disable-next-line*/
  }, [selectedTinyTags, selectedTinyTagGroupIds]); //tT select all when selecting or unselecting all tags

  //Community
  function handleCommunityAccount(accountId) {
    if (selectedAccountsFollowing.includes(accountId)) {
      handleSettingsRemoveSelectedAccountsFollowingId(accountId);
    } else {
      handleSettingsAddSelectedAccountsFollowingId(accountId);
    }
  }

  function handleAccountsFollowingGroup(accountsFollowingGroupId) {
    if (selectedAccountsFollowingGroupIds.includes(accountsFollowingGroupId)) {
      handleSettingsRemoveSelectedAccountsFollowingGroupIds(
        accountsFollowingGroupId
      );
    } else {
      handleSettingsAddSelectedAccountsFollowingGroupIds(
        accountsFollowingGroupId
      );
    }
  }

  useEffect(() => {
    if (
      selectedAccountsFollowing?.length === 0 &&
      selectedAccountsFollowingGroupIds?.length === 0 &&
      selectAllAccountsFollowing
    ) {
      handleSettingsSetSelectAllAccountsFollowingBtn(
        !selectAllAccountsFollowing
      );
    }
    if (
      selectedAccountsFollowing?.length === accountCommunityFollowing?.length &&
      selectedAccountsFollowingGroupIds?.length ===
        accountCommunityFollowingGroups?.length &&
      !selectAllAccountsFollowing
    ) {
      handleSettingsSetSelectAllAccountsFollowingBtn(
        !selectAllAccountsFollowing
      );
    }
    /*eslint-disable-next-line*/
  }, [selectedAccountsFollowing, selectedAccountsFollowingGroupIds]); //community select all when selecting or unselecting all tags

  return (
    <>
      {width <= 1280 && (
        <h2 className="fs16 mrg-b12 color-black">
          {settingsEventSearchView === 'myevents'
            ? SettingsEventsLanguage.myEvents
            : SettingsEventsLanguage.tags}
        </h2>
      )}
      <div
        className={`settings-tags__wrapper full-width ${
          width <= 1280 ? '' : 'mrg-t16'
        }`}
        id="tags__wrapper"
      >
        {settingsEventSearchView === 'myevents' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="settings-tags-container"
          >
            <li key={'settingsNavBtn'}>
              <SettingsQuickNavigate
                settingsDestination={'attendee'}
                customWrapper={'pad-tb8 pad-lr12'}
              />
            </li>
            <li key={'select-all-myEvents-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-lr12 pad-8 pad-lr12 access-ob access-o6 ${
                  settingsSelectAllMyEventsBtn ? 'selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAll(!settingsSelectAllMyEventsBtn);
                }}
              >
                {settingsSelectAllMyEventsBtn ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            <li key={'registeredEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 
                  ${
                    selectedMyEvents?.includes('registered') ? 'selected' : ''
                  }`}
                key={'registeredEvents-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyEventsClick('registered');
                }}
                aria-pressed={selectedMyEvents?.includes('registered')}
              >
                <p>{SettingsEventsLanguage.registered}</p>
              </button>
            </li>
            <li key={'savedEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectedMyEvents?.includes('saved') ? 'selected' : ''
                }`}
                key={'savedEvents-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyEventsClick('saved');
                }}
                aria-pressed={selectedMyEvents?.includes('saved')}
              >
                <p>{SettingsEventsLanguage.saved}</p>
              </button>
            </li>
            <li key={'participationEvents-btn-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                  selectedMyEvents?.includes('participationEvents')
                    ? 'selected'
                    : ''
                }`}
                key={'participationEvents-btn'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMyEventsClick('participationEvents');
                }}
                aria-pressed={selectedMyEvents?.includes('participationEvents')}
              >
                <p>{SettingsEventsLanguage.participation}</p>
              </button>
            </li>
          </ul>
        )}
        {settingsEventSearchView === 'bigtags' && (
          <div className="flex-column full-width">
            <ul
              className="settings-tags__container access-ob access-o6"
              tabIndex="0"
              aria-label={SettingsEventsLanguage.ariaEventFilters}
              id="tags-container"
            >
              <li key={'settingsNavBtn'}>
                <SettingsQuickNavigate
                  settingsDestination={'attendee'}
                  customWrapper={'pad-tb8 pad-lr12'}
                />
              </li>
              <li key={'select-all-bigtag-li'}>
                <button
                  className={`settings-tag ff2 color-theme mrg-lr12 pad-8 pad-lr12 access-ob access-o6 ${
                    selectAllBT ? 'selected' : ''
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelectAll(selectAllBT);
                  }}
                >
                  {selectAllBT ? (
                    <p>{SettingsEventsLanguage.unselectAll}</p>
                  ) : (
                    <p>{SettingsEventsLanguage.selectAll}</p>
                  )}
                </button>
              </li>
              {settingsEventSearchView === 'bigtags' && bigTags && (
                <>
                  {[...bigTags].reverse()?.map((bT, index) => {
                    if (width > 1280 || index < 5 || showAllTags) {
                      return (
                        <li key={`${bT}-li`}>
                          <button
                            className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                              selectedBigTags.includes(bT) ? 'selected' : ''
                            }`}
                            key={bT}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleBigTag(bT);
                            }}
                            aria-pressed={selectedBigTags.includes(bT)}
                            aria-label={`${SettingsEventsLanguage.ariaTag} ${BigTagsOpts[bT]}.`}
                          >
                            <p>{BigTagsOpts[bT]}</p>
                          </button>
                        </li>
                      );
                    }
                    return null;
                  })}

                  {!showAllTags && width < 1280 && bigTags?.length > 5 && (
                    <li key={'show-more-bigtag-li'}>
                      <button
                        className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                          true ? 'selected' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowAllTags(true);
                        }}
                        aria-label={
                          SettingsEventsLanguage.ariaShowRemainingTags
                        }
                      >
                        <p> . . . ({bigTags?.length - 5})</p>
                      </button>
                    </li>
                  )}
                </>
              )}
            </ul>
          </div>
        )}

        {settingsEventSearchView === 'tinytags' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'settingsNavBtn'}>
              <SettingsQuickNavigate
                settingsDestination={'attendee'}
                customWrapper={'pad-tb8 pad-lr12'}
              />
            </li>

            <li key={'select-all-tinytag-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-lr12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllTT ? 'selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAll(selectAllTT);
                }}
              >
                {selectAllTT ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {settingsEventSearchView === 'tinytags' && tinyTagGroups && (
              <>
                {[...tinyTagGroups].reverse()?.map((tG, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${tG.label}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 align-center access-ob access-o6 ${
                            selectedTinyTagGroupIds.includes(tG.id)
                              ? 'selected'
                              : ''
                          }`}
                          key={tG.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTinyTagGroup(tG.id);
                          }}
                          aria-pressed={selectedTinyTagGroupIds.includes(tG.id)}
                          aria-label={`${SettingsEventsLanguage.ariaTinyTagGroup} ${tG.label}.`}
                        >
                          <img
                            src={folder}
                            alt={IconAlts.iconFolder}
                            className="filter-theme folder-img"
                          />
                          <p>{tG.label}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}

                {tinyTags?.map((tT, index) => {
                  if (width > 1280 || index < 5 || showAllTags) {
                    return (
                      <li key={`${tT}-li`}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                            selectedTinyTags.includes(tT) ? 'selected' : ''
                          }`}
                          key={tT}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTinyTag(tT);
                          }}
                          aria-pressed={selectedTinyTags.includes(tT)}
                          aria-label={`${SettingsEventsLanguage.ariaTag} ${tT}.`}
                        >
                          <p>{tT}</p>
                        </button>
                      </li>
                    );
                  }
                  return null;
                })}
                {!showAllTags && width < 1280 && tinyTags?.length > 5 && (
                  <li key="show-more-tinytag-li">
                    <button
                      className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                        true ? 'selected' : ''
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowAllTags(true);
                      }}
                      aria-label={SettingsEventsLanguage.ariaShowRemainingTags}
                    >
                      <p>. . . ({tinyTags?.length - 5})</p>
                    </button>
                  </li>
                )}
              </>
            )}
          </ul>
        )}

        {settingsEventSearchView === 'community' && (
          <ul
            className="settings-tags__container access-ob access-o6"
            tabIndex="0"
            aria-label={SettingsEventsLanguage.ariaEventFilters}
            id="tags-container"
          >
            <li key={'settingsNavBtn'}>
              <SettingsQuickNavigate
                settingsDestination={'attendee'}
                customWrapper={'pad-tb8 pad-lr12'}
              />
            </li>
            <li key={'select-all-community-li'}>
              <button
                className={`settings-tag ff2 color-theme mrg-lr12 pad-8 pad-lr12 access-ob access-o6 ${
                  selectAllAccountsFollowing ? 'selected' : ''
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectAll(selectAllAccountsFollowing);
                }}
              >
                {selectAllAccountsFollowing ? (
                  <p>{SettingsEventsLanguage.unselectAll}</p>
                ) : (
                  <p>{SettingsEventsLanguage.selectAll}</p>
                )}
              </button>
            </li>
            {[...accountCommunityFollowingGroups]
              .reverse()
              ?.map((groupObj, index) => {
                if (width > 1280 || index < 5 || showAllTags) {
                  return (
                    <li key={`${groupObj.label}-li`}>
                      <button
                        className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                          selectedAccountsFollowingGroupIds?.includes(
                            groupObj.id
                          )
                            ? 'selected'
                            : ''
                        }`}
                        key={groupObj.id}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAccountsFollowingGroup(groupObj.id);
                        }}
                        aria-pressed={selectedAccountsFollowingGroupIds?.includes(
                          groupObj.id
                        )}
                      >
                        <img
                          src={folder}
                          alt={IconAlts.iconFolder}
                          className="filter-theme folder-img"
                        />
                        <p>{groupObj.label}</p>
                      </button>
                    </li>
                  );
                }
                return null;
              })}
            {settingsEventSearchView === 'community' &&
              accountCommunityFollowing && (
                <>
                  {[...accountCommunityFollowing]
                    .reverse()
                    ?.map((accountFollowingObj, index) => {
                      if (width > 1280 || index < 5 || showAllTags) {
                        return (
                          <li key={`accountFollowing-li-${index}`}>
                            <button
                              className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                                selectedAccountsFollowing.includes(
                                  accountFollowingObj._id
                                )
                                  ? 'selected'
                                  : ''
                              }`}
                              key={`account-${accountFollowingObj?.personal?.profileImage}-${index}`}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleCommunityAccount(accountFollowingObj._id);
                              }}
                              aria-pressed={selectedAccountsFollowing.includes(
                                accountFollowingObj._id
                              )}
                            >
                              <LoadBucketImage
                                imagePath={
                                  accountFollowingObj?.personal?.profileImage
                                }
                                imgClassName="tag-profile-img mrg-r6"
                                backupPath={'account_img_default.png'}
                                partialPath={'myndfull_account_images'}
                              />
                              <p>
                                {accountFollowingObj?.personal?.firstName}&nbsp;
                                {accountFollowingObj?.personal?.lastName}
                              </p>
                            </button>
                          </li>
                        );
                      }
                      return null;
                    })}
                  {!showAllTags &&
                    width < 1280 &&
                    accountCommunityFollowing?.length > 5 && (
                      <li key={'show-more-accountfollowing-li'}>
                        <button
                          className={`settings-tag ff2 color-theme mrg-r12 pad-8 access-ob access-o6 ${
                            true ? 'selected' : ''
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowAllTags(true);
                          }}
                          aria-label={
                            SettingsEventsLanguage.ariaShowRemainingTags
                          }
                        >
                          <p>
                            {' '}
                            . . . ({accountCommunityFollowing?.length - 5})
                          </p>
                        </button>
                      </li>
                    )}
                </>
              )}
          </ul>
        )}
      </div>
    </>
  );
};

export default TagsList;

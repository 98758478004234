import useLanguageOrganize from '../../../../../../../language/features/useLanguageOrganize';

const ParticipantListItemOrganizedAs = ({ organizedAs, customWrapper }) => {
  //language
  const { ParticipantListItem } = useLanguageOrganize();

  return (
    <div
      className={`participant-list-item__status-box ${
        customWrapper ? customWrapper : ''
      }`}
    >
      <p>{ParticipantListItem[organizedAs]}</p>
    </div>
  );
};

export default ParticipantListItemOrganizedAs;

import { useState } from 'react';

//hooks
import { useEventParticipantsHandlers } from '../../../organize/eventParticipants/useEventParticipantsHandlers';
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//utils
import { img_exit, return_curved_arrow } from '../../../../../assets/icons';

const EventPageParticipantButton = ({
  eventData,
  EventPage,
  organizerViewOn,
  participantPreviewOn,
  accountId,
  setIsParticipant,
  handleReturn,
  setActionBtnsIsLoading,
}) => {
  //hooks
  const { handleMobileTap } = useSettings();
  const { handleWithdrawAsParticipant } = useEventParticipantsHandlers();

  //lanuage
  const { IconAlts } = useLanguageComponents();

  //UI
  const [tapHighlightWithdrawFromEvent, setTapHighlightWithdrawFromEvent] =
    useState();
  const [tapHighlightReturn, setTapHighlightReturn] = useState(false);

  //api
  async function handleWithdrawFromEvent() {
    setActionBtnsIsLoading(true);
    let updateEventData = {
      eventId: eventData?._id,
      eventType: '',
    };

    if (eventData.meta.isDraft) {
      updateEventData.eventType = 'draft';
    } else if (eventData?.schedule?.enabled) {
      updateEventData.eventType = 'scheduledEvent';
    } else {
      updateEventData.eventType = 'event';
    }

    await handleWithdrawAsParticipant({
      accountId,
      updateEventData,
      organizerId: eventData?.organizers?.organizerId,
    });

    const timer = setTimeout(() => {
      setActionBtnsIsLoading(false);
      setIsParticipant(false);
    }, 500);

    return () => clearTimeout(timer);
  }

  return (
    <div className="flex-row full-width flex-center">
      <button
        className={`ev__action-btn mrg-r48 highlight-i-theme
           ${
             tapHighlightReturn
               ? 'highlight-i-theme--tap click-scaledown-95'
               : ''
           }`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightReturn(true),
              () => setTapHighlightReturn(false),
            ],
            [() => handleReturn()],
            true
          )
        }
        title={EventPage.return}
      >
        <img
          src={return_curved_arrow}
          alt={IconAlts.iconArrow}
          className="filter-lightgray"
          style={{ height: '28px' }}
        />
      </button>

      <button
        className={`ev__action-btn--large flex-column highlight-i-theme ${
          tapHighlightWithdrawFromEvent ? `click-scaledown-95` : ''
        } ${organizerViewOn || participantPreviewOn ? 'opacity-03' : ''}`}
        onClick={() =>
          handleMobileTap(
            [
              () => setTapHighlightWithdrawFromEvent(true),
              () => setTapHighlightWithdrawFromEvent(false),
            ],
            [() => handleWithdrawFromEvent()]
          )
        }
      >
        <div className="flex-row full-width ">
          <img
            className="filter-lightgray"
            src={img_exit}
            alt={EventPage.registrationIcon}
          />
          <div className="flex-column flex-center full-width">
            <p>{EventPage.withdrawAsParticipant}</p>
          </div>
        </div>
      </button>
    </div>
  );
};

export default EventPageParticipantButton;

import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom/client';
import React from 'react';

//hooks
import { useGenericModal } from '../../../../../context/GenericModalProvider';
import { useSettings } from '../../../../../context/SettingsProvider';

//language
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';

//components
import ReportModal from '../../../../../components/Modal/ModalComponents/ReportModal/ReportModal';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import ConfirmationModal from '../../../../../components/Modal/ModalComponents/ConfirmationModal';
import CheckBoxBoolean from '../../../../../components/CheckBoxes/CheckBoxBoolean';

//utility
import {
  checkmark_green,
  flag,
  img_font_decrease,
  img_font_increase,
  img_moon,
  img_new_window,
  img_sun,
  lock_locked,
  lock_unlocked,
  myndfull_icon,
  warning,
} from '../../../../../assets/icons';
import { translationTextBoxLanguage } from '../../../../../data/translationTextBoxLang';

const TranslationTextBox = ({
  translationTextBoxRef,
  isHost,
  roomCode,
  width,
  translationLang,
  audioElementRef,
  handleAudioEnded,
  setAudioContentQueue,
  handleExit,
  childWindow,
  setChildWindow,
  joinRoomPasswordToggle,
  attendeeJoinRoomPassword,
  componentTranslationsData,
  saveTranslationTranscript,
  exitIsLoading,
  changeSaveTranscriptToggle,
}) => {
  //hooks
  const { setGenericModal, setGenericModalMobileFloat } = useGenericModal();
  const { handleMobileTap } = useSettings();

  //language
  const { TranslationRoom } = useLanguageTranslationRoom();

  //state
  const [lightDarkMode, setLightDarkMode] = useState('light');
  const [fontSize, setFontSize] = useState(width < 480 ? '18px' : '24px');
  const [revealRoomPassword, setRevealRoomPassword] = useState(false);

  //UI
  const [tapHighlightWindowModeBtn, setTapHighlightWindowModeBtn] =
    useState(false);
  const [tapHighlightLightDarkModeBtn, setTapHighlightLightDarkModeBtn] =
    useState(false);
  const [tapHighlightPasswordRevealBtn, setTapHighlightPasswordRevealBtn] =
    useState(false);
  const [tapHighlightIncreaseFontBtn, setTapHighlightIncreaseFontBtn] =
    useState(false);
  const [tapHighlightDecreaseFontBtn, setTapHighlightDecreaseFontBtn] =
    useState(false);

  //variables
  const initializeTextBoxEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (
      initializeTextBoxEffectRan.current === false &&
      componentTranslationsData?.length > 0
    ) {
      initializeTextBoxEffectRan.current = true;

      const initializeTextBox = setTimeout(() => {
        translationTextBoxRef.current.scrollTop =
          translationTextBoxRef.current.scrollHeight;
      }, 1000);
      return () => {
        clearTimeout(initializeTextBox);
      };
    }
  }, [componentTranslationsData]); //adjust textbox scroll

  //functions
  useEffect(() => {
    translationTextBoxRef.current.scrollTop =
      translationTextBoxRef.current.scrollHeight;
  }, [componentTranslationsData]); //adjust textbox scroll

  useEffect(() => {
    if (childWindow && !childWindow.closed) {
      childWindow.postMessage(
        {
          type: 'TEXT_LANGUAGE',
          textLang: translationLang,
        },
        window.location.origin
      );
    }
  }, [translationLang, childWindow]);

  function openNewWindow() {
    if (childWindow) return;

    if (!childWindow || childWindow.closed) {
      let newWindow = window.open(
        '/translationBox.html',
        '_blank',
        'width=800,height=600,scrollbars=yes,resizable=yes'
      );

      newWindow.opener = null;

      newWindow.onload = () => {
        const container = newWindow.document.getElementById('translationBox');

        if (!container) {
          console.error('Child window container not found');
          return;
        }

        const TranslationWindow = () => {
          const [attendeePassword, setAttendeePassword] = React.useState('');
          const [windowLightDarkMode, setWindowLightDarkMode] =
            React.useState(lightDarkMode);
          const [windowFontSize, setWindowFontSize] = React.useState('24px');
          const [displayScale, setDisplayScale] = React.useState(1);
          const [
            textboxDisplayTranslationsData,
            setTextboxDisplayTranslationsData,
          ] = React.useState([]);
          const [TranslationRoomLanguage, setTranslationRoomLanguage] =
            React.useState({});

          function handleTranslationRoomLangauge(lang) {
            const newLang = translationTextBoxLanguage(lang || translationLang);
            console.log('newLangObj', newLang);
            setTranslationRoomLanguage(newLang);
          }

          React.useEffect(() => {
            handleTranslationRoomLangauge(!translationLang || 'en-CA');
          }, []);

          React.useEffect(() => {
            const handleMessage = (event) => {
              if (event.origin !== window.location.origin) {
                console.warn('Message from untrusted origin:', event.origin);
                return;
              }

              const { type } = event.data || {};

              if (type === 'LIGHT_DARK_MODE') {
                setWindowLightDarkMode(event.data.newLightDarkMode);
              } else if (type === 'TOGGLE_PASSWORD_REVEAL') {
                setAttendeePassword(event.data.password);
              } else if (type === 'FONT_SIZE') {
                const fontSize = event.data.fontSize;
                setWindowFontSize(fontSize);

                const numericFontSize = parseFloat(fontSize.replace('px', ''));
                const newScale = numericFontSize / 24;
                setDisplayScale(newScale);
              } else if (type === 'TRANSLATION_DATA') {
                setTextboxDisplayTranslationsData((prevData) => [
                  ...prevData,
                  event.data.translationData,
                ]);
              } else if (type === 'TEXT_LANGUAGE') {
                handleTranslationRoomLangauge(event.data.textLang || 'en-CA');
              }
            };

            if (newWindow) {
              newWindow.addEventListener('message', handleMessage);
            }

            return () => {
              newWindow.removeEventListener('message', handleMessage);
            };
          }, []);

          return (
            <>
              {TranslationRoomLanguage && (
                <div
                  id="translations-display"
                  style={{
                    color: windowLightDarkMode === 'light' ? 'black' : 'white',
                    backgroundColor:
                      windowLightDarkMode === 'light' ? 'white' : 'black',
                    minHeight: '100vh',
                  }}
                >
                  <div className="flex-column">
                    <div
                      style={{
                        padding: '24px 24px 0px 24px',
                      }}
                      className="pos-rel space-between"
                      aria-hidden="true"
                      tabIndex="-1"
                    >
                      <p
                        id={'translations-display-expand-hint'}
                        className="translations-display-expand-hint"
                      >
                        {TranslationRoom.pressF11ForFullScreen}
                      </p>
                      <div
                        className="display-banner"
                        id={'translations-display-banner'}
                        style={{
                          transform: `scale(${displayScale})`,
                          transformOrigin: 'top left',
                        }}
                      >
                        <img
                          src={myndfull_icon}
                          alt="img"
                          className="mrg-r12"
                          style={{ maxHeight: '54px' }}
                        />
                        <div className="flex-column">
                          myndfull.com/tr
                          <div className="mrg-t6 flex-center">
                            {TranslationRoomLanguage.roomCode}: {roomCode}&nbsp;
                            {attendeePassword && (
                              <span
                                style={{
                                  opacity: attendeePassword ? '1' : '0',
                                }}
                              >
                                {`/ ${TranslationRoomLanguage.password}: ${attendeePassword}`}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul
                      id={'translations-display-expanded-textbox'}
                      className="translations-textbox"
                      style={{
                        fontSize: `${windowFontSize}`,
                      }}
                    >
                      {textboxDisplayTranslationsData?.map((data, index) => {
                        return (
                          <li
                            key={`translationData-${index}`}
                            tabIndex="0"
                            className="translation-li"
                          >
                            <p>{data}</p>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}
            </>
          );
        };

        const root = ReactDOM.createRoot(container);
        root.render(<TranslationWindow />);
      };

      setChildWindow(newWindow);

      // Listen for the child window close event
      const interval = setInterval(() => {
        if (newWindow.closed) {
          clearInterval(interval);
          setChildWindow(null);
          setRevealRoomPassword(false);
          setFontSize(width < 480 ? '18px' : '24px');
          setLightDarkMode('light');
        }
      }, 500);
    }
  }

  function handleLightDarkMode() {
    const newMode = lightDarkMode === 'light' ? 'dark' : 'light';
    setLightDarkMode(newMode);
    if (childWindow && !childWindow.closed) {
      childWindow.postMessage(
        {
          type: 'LIGHT_DARK_MODE',
          newLightDarkMode: newMode,
        },
        window.location.origin
      );
    }
  }

  function handleIncreaseFontSize() {
    if (fontSize === '18px') {
      setFontSize('21px');
    }
    if (fontSize === '21px') {
      setFontSize('24px');
    }
    if (fontSize === '24px') {
      setFontSize('28px');
    }
    if (fontSize === '28px') {
      setFontSize('32px');
    }
    if (fontSize === '32px') {
      setFontSize('36px');
    }
    if (fontSize === '36px') {
      setFontSize('48px');
    }
  }

  function handleDecreaseFontSize() {
    if (fontSize === '48px') {
      setFontSize('36px');
    }
    if (fontSize === '36px') {
      setFontSize('32px');
    }
    if (fontSize === '32px') {
      setFontSize('28px');
    }
    if (fontSize === '28px') {
      setFontSize('24px');
    }
    if (fontSize === '24px') {
      setFontSize('21px');
    }
    if (fontSize === '21px') {
      setFontSize('18px');
    }
  }

  useEffect(() => {
    if (childWindow && !childWindow.closed) {
      childWindow.postMessage(
        {
          type: 'FONT_SIZE',
          fontSize: fontSize,
        },
        window.location.origin
      );
    }
  }, [fontSize, childWindow]); //scales the host pop out window with font size increase

  function handlePasswordReveal() {
    function revealPasswordInWindow() {
      setRevealRoomPassword(true);

      if (childWindow && !childWindow.closed) {
        childWindow.postMessage(
          {
            type: 'TOGGLE_PASSWORD_REVEAL',
            password: attendeeJoinRoomPassword,
          },
          window.location.origin
        );
      }
    }

    if (!revealRoomPassword) {
      setGenericModalMobileFloat(
        <ConfirmationModal
          headingText={TranslationRoom.revealPasswordModal}
          button1Text={TranslationRoom.cancel}
          button2Text={TranslationRoom.yes}
          focusId={'revealRoomPassword'}
          handler={() => revealPasswordInWindow()}
        />
      );
    } else {
      setRevealRoomPassword(false);

      if (childWindow && !childWindow.closed) {
        childWindow.postMessage(
          {
            type: 'TOGGLE_PASSWORD_REVEAL',
            password: '',
          },
          window.location.origin
        );
      }
    }
  }

  return (
    <>
      <div className={`pos-rel full-width`}>
        {isHost && (
          <button
            onClick={() =>
              handleMobileTap(
                [
                  () => setTapHighlightWindowModeBtn(true),
                  () => setTapHighlightWindowModeBtn(false),
                ],
                [() => openNewWindow()]
              )
            }
            className={`translation-box-btn  align-center  ${
              tapHighlightWindowModeBtn && !childWindow
                ? 'highlight-i-theme--tap'
                : ''
            } ${childWindow ? '' : 'highlight-i-theme'}`}
            style={{ right: '36px', top: '36px' }}
            aria-label={TranslationRoom.ariaExpandTextAreaToFullScreen}
          >
            {childWindow && <img src={checkmark_green} alt="img" />}
            <img
              src={img_new_window}
              className={`svg filter-gray`}
              style={{ height: '44px', width: '44px', padding: '8px' }}
              alt="img"
              aria-hidden="true"
            />
          </button>
        )}
        {isHost && childWindow && (
          <div
            className="flex-column translation-box-btn"
            style={{ top: '84px', right: '36px' }}
          >
            <button
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightLightDarkModeBtn(true),
                    () => setTapHighlightLightDarkModeBtn(false),
                  ],
                  [() => handleLightDarkMode()]
                )
              }
              className={`highlight-i-theme ${
                tapHighlightLightDarkModeBtn ? 'highlight-i-theme--tap' : ''
              }`}
              title={
                lightDarkMode === 'light'
                  ? `${TranslationRoom.lightMode}`
                  : `${TranslationRoom.darkMode}`
              }
              aria-label={
                lightDarkMode === 'light'
                  ? TranslationRoom.ariaLightModeActive
                  : TranslationRoom.ariaDarkModeActive
              }
              id={'colorModeButton'}
            >
              <img
                src={lightDarkMode === 'light' ? img_sun : img_moon}
                style={{ height: '44px', width: '44px', padding: '6px' }}
                className={`svg filter-gray`}
                alt="img"
              />
            </button>

            {joinRoomPasswordToggle && attendeeJoinRoomPassword?.length > 0 && (
              <button
                onClick={() =>
                  handleMobileTap(
                    [
                      () => setTapHighlightPasswordRevealBtn(true),
                      () => setTapHighlightPasswordRevealBtn(false),
                    ],
                    [() => handlePasswordReveal()]
                  )
                }
                className={` ${
                  tapHighlightPasswordRevealBtn ? 'highlight-i-theme--tap' : ''
                } ${
                  fontSize === '18px'
                    ? 'unavailable opacity-05'
                    : 'highlight-i-theme'
                }`}
                title={
                  revealRoomPassword
                    ? TranslationRoom.hideRoomPassword
                    : TranslationRoom.revealRoomPassword
                }
                aria-label={
                  revealRoomPassword
                    ? TranslationRoom.hideRoomPassword
                    : TranslationRoom.revealRoomPassword
                }
                id={'revealRoomPassword'}
              >
                {revealRoomPassword ? (
                  <img
                    src={lock_unlocked}
                    style={{ height: '44px', width: '44px', padding: '10px' }}
                    className={`svg filter-gray`}
                    alt="img"
                  />
                ) : (
                  <img
                    src={lock_locked}
                    style={{ height: '44px', width: '44px', padding: '10px' }}
                    className={`svg filter-gray`}
                    alt="img"
                  />
                )}
              </button>
            )}

            <p
              className={`fs16 fwsb color-gray min-44 text-center align-center`}
            >
              {fontSize}
            </p>

            <button
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightIncreaseFontBtn(true),
                    () => setTapHighlightIncreaseFontBtn(false),
                  ],
                  [() => handleIncreaseFontSize()]
                )
              }
              className={`${
                tapHighlightIncreaseFontBtn ? 'highlight-i-theme--tap' : ''
              }
            ${
              fontSize === '48px'
                ? 'unavailable opacity-05'
                : 'highlight-i-theme'
            }
            `}
              title={TranslationRoom.increaseFontSize}
              aria-label={TranslationRoom.increaseFontSize}
              id={'fontSizeIncrease'}
            >
              <img
                src={img_font_increase}
                style={{ height: '44px', width: '44px', padding: '4px' }}
                className={`svg filter-gray`}
                alt="img"
              />
            </button>
            <button
              onClick={() =>
                handleMobileTap(
                  [
                    () => setTapHighlightDecreaseFontBtn(true),
                    () => setTapHighlightDecreaseFontBtn(false),
                  ],
                  [() => handleDecreaseFontSize()]
                )
              }
              className={` ${
                tapHighlightDecreaseFontBtn ? 'highlight-i-theme--tap' : ''
              } ${
                fontSize === '18px'
                  ? 'unavailable opacity-05'
                  : 'highlight-i-theme'
              }`}
              title={TranslationRoom.decreaseFontSize}
              aria-label={TranslationRoom.decreaseFontSize}
              id={'fontSizeDecrease'}
            >
              <img
                src={img_font_decrease}
                style={{ height: '44px', width: '44px', padding: '4px' }}
                className={`svg filter-gray`}
                alt="img"
              />
            </button>
          </div>
        )}

        <ul
          ref={translationTextBoxRef}
          className={`translation-text-box fs21 mrg-b24`}
          tabIndex="0"
          id={'expandedTranslationTextBox'}
        >
          <audio onEnded={handleAudioEnded} ref={audioElementRef} />
          {!isHost && (!translationLang || translationLang === 'select') && (
            <div
              className="translation-text-box-warning access-ob access-o6"
              tabIndex="0"
            >
              <img src={warning} alt="img" aria-hidden={true} />
              <p>{TranslationRoom.selectLanguage}</p>
            </div>
          )}
          {componentTranslationsData?.map((data, index) => {
            return (
              <li
                key={`translationData-${index}`}
                tabIndex="0"
                className="translation-li"
                id={`"translation-li-${componentTranslationsData?.length}`}
              >
                <p>{data}</p>
              </li>
            );
          })}
        </ul>

        <div
          className={`flex-row flex-column--phn full-width space-between align-center ${
            width <= 480 ? 'mrg-b12' : 'mrg-b48'
          }`}
        >
          {isHost && (
            <CheckBoxBoolean
              handler={changeSaveTranscriptToggle}
              customWrapper={'color-darkgray'}
              handleAccountId={true}
              loadTrigger={true}
              loadValue={saveTranslationTranscript}
              label={TranslationRoom.saveTranscript}
              id={'toggleTranscripts'}
            />
          )}

          <button
            id="report"
            className={`align-center highlight-i-lgr color-gray ${
              !isHost ? 'mrg-auto-left' : ''
            } `}
            onClick={() =>
              setGenericModal(<ReportModal type={'translationRoom'} />)
            }
            style={{ height: '44px' }}
          >
            <p className={`fwsb fs16 mrg-r12 `}>{TranslationRoom.report}</p>
            <img src={flag} alt="img" style={{ height: '24px' }} />
          </button>
        </div>

        {((width <= 1280 && isHost) || (!isHost && width <= 1280)) && (
          <ItemButton
            text={isHost ? TranslationRoom.logout : TranslationRoom.exit}
            handler={() => {
              setAudioContentQueue();
              handleExit();
            }}
            isLoading={exitIsLoading}
            fullWidth={true}
            customWrapper={`action-btns mrg-auto-right mrg-auto-left mrg-b48 mrg-t24--ph translations-max-width`}
          />
        )}
      </div>
    </>
  );
};

export default TranslationTextBox;

import { useEffect } from 'react';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../../utils/UI/ScrollTo';

const HostSubPageFour = ({ setHostSubPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <>
      <h1 className="fwsb fs21 lh36">Reviewing a Session</h1>
      <p className="fs18 lh28 mrg-t24">
        Hosts have the option to toggle the "Save Transcript" button during a
        session. If enabled, a copy of the transcript will be saved and securely
        retained for the host. We take privacy seriously—all transcripts are
        fully encrypted.
      </p>

      <p className="fs18 lh28 mrg-t24">
        After the session, hosts can access their transcripts by navigating to
        the Transcripts section from the main login screen. Based on your
        verified login credentials, the transcripts will be decrypted so you can
        review the transcript, download a simple or detailed version, and
        permanently delete the transcript.
      </p>

      <h1 className="fwsb fs21 lh36 mrg-t24">Transcript Review</h1>

      <p className="fs18 lh28 mrg-t24">
        MyndFull Translations is still in an experimental stage, and your
        feedback is incredibly valuable. If you'd like to help us improve the
        tool—or if you're dissatisfied with a session and wish to request a
        refund—you can submit a Transcript Review. As part of the review, you
        can flag translation entries and provide comments. Once submitted, the
        selected portions of the transcript will be decrypted, along with the
        room's preferences and speaker profiles to help us review the identified
        problems.
      </p>

      <p className="fs18 lh28 mrg-t24 fwsb">Key Takeaways:</p>
      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          Hosts can choose to save encrypted transcripts of their sessions.
        </li>
        <li className="mrg-t12">
          Transcripts can be reviewed, downloaded, or permanently deleted by the
          host.
        </li>
        <li className="mrg-t12">
          Transcripts can be submitted for review to help improve the tool or to
          initiate a support/remediation process.
        </li>
      </ul>

      <p className="fs18 lh28 mrg-t24">
        That concludes the MyndFull Translations Host Tutorial. We hope you have
        a great experience — and as always, we welcome your feedback as we
        continue to improve!
      </p>

      <div className="translations-selections mrg-auto-lr mrg-t36">
        <ItemButton
          text={'Return'}
          handler={() => setHostSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </>
  );
};

export default HostSubPageFour;

import { useState, useEffect, useRef } from 'react';
//Hooks
import { useAccountReduxHandlers } from '../../../useAccountHandlers';

//language
import useLanguageComponents from '../../../../../../language/useLanguageComponents';

//Components
import MapContainerWithDistanceFilter from '../../../../../../components/MapContainer/MapContainerWithDistanceFilter';
import AutocompletePlacesNoAddress from '../../../../../../components/AutocompletePlaces/AutocompletePlacesNoAddress';
import DistanceInput from '../../../../../../components/DistanceInput/DistanceInput';
import SaveButton from '../../../../../../components/SaveButton/SaveButton';

//Utility
import {
  checkmark_green,
  img_search_location,
} from '../../../../../../assets/icons';

const EventSearchInPerson = ({
  Account,
  saveAccountChange,
  loadingSaveBtnUI,
}) => {
  //Hooks
  const {
    accountGeoPositionCoordinates: loadCoords,
    accountGeoPosition,
    accountAttendee,
    accountDistanceFilter: loadDistanceFilter,
    accountDistanceMeasurement: loadDistanceMeasurement,
  } = useAccountReduxHandlers();

  //language
  const { Generic, IconAlts } = useLanguageComponents();

  //Component state
  const [coords, setCoords] = useState(loadCoords);

  const [distanceFilter, setDistanceFilter] = useState(0);
  const [distanceMeasurement, setDistanceMeasurement] = useState(
    loadDistanceMeasurement
  );
  const [searchLocationSaveValid, setSearchLocationSaveValid] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  //Component variables
  const initialDistanceFilter = useRef();
  const initializeEffectRan = useRef(false);

  //Initialize functions
  useEffect(() => {
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (initializeEffectRan.current === false) {
      setDistanceFilterCheck(loadDistanceFilter, loadDistanceMeasurement);

      initializeEffectRan.current = true;
    }
  }, [loadDistanceFilter]);

  //Component functions
  useEffect(() => {
    const sameContents = coords.every((value, index) => {
      return value === loadCoords[index];
    });

    if (!sameContents) {
      setSearchLocationSaveValid(true);
    } else if (initialDistanceFilter.current !== distanceFilter) {
      setSearchLocationSaveValid(true);
    } else if (distanceMeasurement !== loadDistanceMeasurement) {
      setSearchLocationSaveValid(true);
    } else {
      setSearchLocationSaveValid(false);
    }
  }, [coords, loadCoords, distanceFilter]);

  function handleSaveSearchLocation() {
    let oldGeoPosition = accountGeoPosition;
    let geoPosition = { ...oldGeoPosition };
    geoPosition.coordinates = [...oldGeoPosition.coordinates];
    geoPosition.coordinates[0] = coords[0];
    geoPosition.coordinates[1] = coords[1];

    //ADD DISTANCE FILTER + MEASUREMENT
    let newDistance;
    if (distanceMeasurement === 'km') {
      newDistance = distanceFilter * 1000;
    } else {
      newDistance = parseInt(distanceFilter * 1609.344);
    }

    let backendGeoPosition = {
      type: 'Point',
      coordinates: [geoPosition.coordinates[0], geoPosition.coordinates[1]],
    };

    saveAccountChange(
      {
        attendee: {
          ...accountAttendee,
          geoPosition: backendGeoPosition,
          distanceFilter: newDistance,
          distanceMeasurement,
        },
      },
      'eventSearchInPerson'
    );

    setDistanceFilterCheck(newDistance, distanceMeasurement);
  }

  function setAutocompleteCoordinates(newCoordinates) {
    let newCoords = [newCoordinates.lat, newCoordinates.lng];
    setCoords(newCoords);
    const autocomplete = document.getElementById('autocomplete');
    autocomplete.value = '';
  }

  function setDistanceFilterCheck(distanceInMeters, measurement) {
    let convertedDistance;
    if (measurement === 'km') {
      convertedDistance = distanceInMeters / 1000;
      initialDistanceFilter.current = convertedDistance;
      setDistanceFilter(convertedDistance);
    } else if (measurement === 'mi') {
      convertedDistance = distanceInMeters / 1609.344;
      convertedDistance = Number(convertedDistance.toFixed(1));
      initialDistanceFilter.current = convertedDistance;
      setDistanceFilter(convertedDistance);
    }
  }

  return (
    <div className="section">
      <label
        className="content-heading-styled text-gray flex-row flex-column--ph align-center full-width"
        tabIndex="0"
        id="eventSearchInPersonLabel"
      >
        <div className="flex-row align-center mrg-auto-right">
          <img
            src={img_search_location}
            alt={IconAlts.iconLocationPin}
            style={{ height: '32px' }}
            className="mrg-r12 filter-theme"
          />
          <p>{Account.attendee.eventSearchInPerson}</p>
        </div>
        <div className="flex-row mrg-auto-right--ph mrg-t12--ph">
          <p className="fs14 mrg-auto-left">{Generic.required}</p>

          {coords?.length === 2 && coords[0] !== 0 && coords[1] !== 0 && (
            <img
              src={checkmark_green}
              alt={IconAlts.iconCheckmark}
              className="mrg-l6"
            />
          )}
        </div>
      </label>
      <div className="h12" />
      <p className="description-text" tabIndex="0">
        {Account.attendee.eventSearchInPersonDescription}
      </p>

      <div className="h24" />
      {coords && (
        <MapContainerWithDistanceFilter
          loadTrigger={true}
          loadValue={coords}
          handler={setCoords}
          distanceFilter={distanceFilter}
          distanceMeasurement={distanceMeasurement}
        />
      )}
      <div className="h24" />

      <AutocompletePlacesNoAddress
        setAutocompleteCoordinates={setAutocompleteCoordinates}
      />
      <div className="h24" />

      {isLoaded && (
        <>
          <DistanceInput
            distanceFilter={distanceFilter}
            setDistanceFilter={setDistanceFilter}
            distanceMeasurement={distanceMeasurement}
            setDistanceMeasurement={setDistanceMeasurement}
          />
          <div className="h24" />
        </>
      )}
      <SaveButton
        saveValid={searchLocationSaveValid}
        handler={() => handleSaveSearchLocation()}
        saveAriaLanguage={Account.attendee.eventSearchInPerson}
        isLoading={loadingSaveBtnUI === 'eventSearchInPerson'}
      />
      <div className="h36" />
    </div>
  );
};

export default EventSearchInPerson;

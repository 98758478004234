import { useEffect, useState } from 'react';

//components
import ParticipantListItemCompletionStatus from '../Generic/ParticipantListItemCompletionStatus';
import ParticipantListItemInvitationStatus from '../Generic/ParticipantListItemInvitationStatus';
import ParticipantListItemOrganizedAs from '../Generic/ParticipantListItemOrganizedAs';

const ParticipantListItemStatusTranslations = ({ p }) => {
  //state
  const [invitationHasExpired, setInvitationHasExpired] = useState(false);
  const [isDispatched, setIsDispatched] = useState(false);

  useEffect(() => {
    if (p) {
      let tokenExpiration = p.dispatches?.translationsTokenExpires;
      if (tokenExpiration) {
        let expirationTimestamp = new Date(tokenExpiration).getTime();
        if (expirationTimestamp < Date.now()) {
          setInvitationHasExpired(true);
        }
      }
      setIsDispatched(p?.dispatches?.translationsDispatched);
    }
  }, [p]); //check token expiration.

  return (
    <>
      {p?.organizedAs === 'create' && (
        <div className="flex-row flex-column--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={'mrg-r6'}
          />
          <ParticipantListItemCompletionStatus
            completionStatus={p.translationsCompletionStatus}
            organizedAs={p.organizedAs}
            translationsLanguageConflict={p.translationsLanguageConflict}
            translationsRoomRequiresLanguage={
              p.translationsRoomRequiresLanguage
            }
          />
        </div>
      )}

      {p?.organizedAs === 'inviteContact' && (
        <div className="flex-row flex-column--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={'mrg-r6'}
          />

          {(p.translationsLanguageConflict ||
            p.translationsRoomRequiresLanguage) && (
            <ParticipantListItemCompletionStatus
              completionStatus={p.translationsCompletionStatus}
              organizedAs={p.organizedAs}
              translationsLanguageConflict={p.translationsLanguageConflict}
              translationsRoomRequiresLanguage={
                p.translationsRoomRequiresLanguage
              }
              translationsIsDispatched={isDispatched}
            />
          )}

          {p?.dispatches?.translationsDispatched && (
            <ParticipantListItemInvitationStatus
              invitationStatus={p.dispatches.translationsStatus}
            />
          )}
        </div>
      )}

      {p?.organizedAs === 'emailInvitation' && (
        <div className="flex-row flex-column--ph">
          <ParticipantListItemOrganizedAs
            organizedAs={p.organizedAs}
            customWrapper={'mrg-r6'}
          />
          {p?.dispatches?.translationsStatus !== 'accepted' &&
            p?.dispatches?.translationsStatus !== 'pending' && (
              <ParticipantListItemCompletionStatus
                completionStatus={p.completionStatus}
                duplicateEmail={p?.emailInvitation?.duplicateEmail}
                invalidEmail={
                  p?.emailInvitation?.email?.length > 0 &&
                  p?.emailInvitation?.emailValid === false
                }
                organizedAs={p.organizedAs}
                translationsLanguageConflict={p.translationsLanguageConflict}
                translationsRoomRequiresLanguage={
                  p.translationsRoomRequiresLanguage
                }
                translationsIsDispatched={isDispatched}
              />
            )}

          {p?.dispatches?.translationsDispatched && (
            <>
              {(p.translationsLanguageConflict ||
                p.translationsRoomRequiresLanguage) && (
                <ParticipantListItemCompletionStatus
                  completionStatus={p.translationsCompletionStatus}
                  duplicateEmail={p?.emailInvitation?.duplicateEmail}
                  invalidEmail={
                    p?.emailInvitation?.email?.length > 0 &&
                    p?.emailInvitation?.emailValid === false
                  }
                  organizedAs={p.organizedAs}
                  translationsLanguageConflict={p.translationsLanguageConflict}
                  translationsRoomRequiresLanguage={
                    p.translationsRoomRequiresLanguage
                  }
                  translationsIsDispatched={isDispatched}
                />
              )}

              <ParticipantListItemInvitationStatus
                invitationStatus={p.dispatches.translationsStatus}
                invitationHasExpired={invitationHasExpired}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ParticipantListItemStatusTranslations;

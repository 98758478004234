import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

//language
import useLanguageComponents from '../../language/useLanguageComponents';

//components
import Spinner from '../Spinner/Spinner';

//utils
import {
  folder,
  img_file,
  img_folder_open,
  img_refresh_two_circular,
} from '../../assets/icons';

// note that the path will gain a '/' at the beginning that needs to be added from parent functions to match this component's file selections

let FileDirectory = (
  {
    directory,
    isLoading,
    title,
    icon,
    iconOn,
    iconCustomClass,
    customImgStyle,
    handleFileSelection,
    connectionStatusTrackerOn,
    connectionStatus,
  },
  ref
) => {
  //language
  const { Generic, IconAlts } = useLanguageComponents();

  // state
  const [fileDirectory, setFileDirectory] = useState();
  const [folderSelections, setFolderSelections] = useState([]);
  const [fileSelections, setFileSelections] = useState([]);

  // initialize
  useEffect(() => {
    setFileDirectory(directory);
  }, [directory]);

  const handleSelection = (isFolder, selectionPath) => {
    if (isFolder) {
      if (folderSelections.includes(selectionPath)) {
        setFolderSelections(
          folderSelections.filter((path) => path !== selectionPath)
        );
      } else {
        setFolderSelections([...folderSelections, selectionPath]);
      }
    } else {
      if (fileSelections.includes(selectionPath)) {
        setFileSelections(
          fileSelections.filter((path) => path !== selectionPath)
        );
      } else {
        setFileSelections([...fileSelections, selectionPath]);
      }
      handleFileSelection(selectionPath);
    }
  };

  useImperativeHandle(ref, () => ({
    handleFileDirectorySelection(isFolder, selectionPath) {
      return handleSelection(isFolder, selectionPath);
    },
  }));

  const renderDirectory = (
    currentDirectory,
    currentPath = '',
    indentation = 0
  ) => {
    return (
      <ul className="list-style-none">
        {Object.entries(currentDirectory)?.map(([name, subDirectory]) => {
          const isFolder = Object.keys(subDirectory).length > 0;
          const fullPath = `${currentPath}/${name}`;
          const isSelectedFolder = folderSelections.includes(fullPath);
          const isSelectedFile = fileSelections.includes(fullPath);

          return (
            <li key={fullPath}>
              <button
                className="flex-row align-center mrg-tb24"
                style={{ marginLeft: `${indentation * 16}px` }}
                onClick={() => handleSelection(isFolder, fullPath)}
              >
                {isFolder ? (
                  isSelectedFolder ? (
                    <img
                      src={img_folder_open}
                      alt="folder"
                      className="filter-theme"
                    />
                  ) : (
                    <img src={folder} alt="" className="filter-gray" />
                  )
                ) : (
                  <img
                    src={img_file}
                    alt="file"
                    className={isSelectedFile ? 'filter-theme' : 'filter-gray'}
                  />
                )}
                <p className="mrg-l12 fs18">{name}</p>
              </button>
              {isSelectedFolder &&
                isFolder &&
                renderDirectory(subDirectory, fullPath, indentation + 1)}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="folder-selection__wrapper">
            <div className="pad-tb24 fs21 fwb flex-center full-width">
              {iconOn && (
                <img
                  src={icon}
                  alt="folder"
                  className={`${iconCustomClass}`}
                  style={customImgStyle}
                />
              )}
              <div className="live-connection-icon mrg-l12">
                {connectionStatusTrackerOn && (
                  <img
                    src={img_refresh_two_circular}
                    alt={IconAlts.icon}
                    className={`svg ${
                      connectionStatus !== 'connected'
                        ? 'live-connection-status--pending--staging'
                        : 'remove'
                    }`}
                    title={Generic.attemptingToReconnect}
                  />
                )}
              </div>
              <p className="mrg-auto-lr">{title}</p>
            </div>
            <div className="folder-selection__container">
              <div className="mrg-tb24 mrg-l24 ">
                {fileDirectory && renderDirectory(fileDirectory)}
              </div>
            </div>
          </div>
          <div className="h128 " />
        </>
      )}
    </>
  );
};

FileDirectory = forwardRef(FileDirectory);

export default FileDirectory;

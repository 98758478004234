import { useEffect } from 'react';

//components
import ItemButton from '../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../utils/UI/ScrollTo';

const TranslationsTutorialAttendee = ({ setSubPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <div className="full-width">
      <h1 className="fwsb fs21 lh36">Learn as an Attendee</h1>

      <p className="fs18 lh28 mrg-t24 ">
        To participate in MyndFull Translations as an attendee, you'll need a
        phone, tablet, or computer, as well as a pair of headphones.
      </p>

      <p className="fs18 lh28 mrg-t24 ">
        To join a room, enter the room code provided by the host. The host may
        also require a password. Once entered successfully, you'll be taken to
        the "Staging Room", where you'll wait for the host to join from their
        device.
      </p>

      <p className="fs18 lh28 mrg-t24 ">
        When the host enters the room, you'll be presented with a list of
        available languages set by the host. Select your preferred language and
        proceed.
      </p>

      <p className="fs18 lh28 mrg-t24 ">
        If you're using a mobile device, please enable our sleep timer override
        once you've entered the session room. This will temporarily prevent your
        device from going into sleep mode, ensuring you continue to receive
        uninterrupted translations without needing to unlock your screen.
      </p>

      <p className="fs18 lh28 mrg-t24 ">
        With these steps complete, you're ready to begin receiving translations!
      </p>

      <p className="fs18 lh28 mrg-t24">A couple of final notes:</p>

      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          When the speaker is presenting in your selected language, you will
          receive text only (rather than both text and audio).
        </li>
        <li className="mrg-t12">
          MyndFull Translations is still in an early stage of development. While
          it has demonstrated a high level of accuracy (typically between 96%
          and 98%), it's not perfect. Performance may vary depending on external
          factors such as the host's settings, equipment, environment, and
          pronunciation. For these reasons, please give the speaker the benefit
          of the doubt if a translation seems unusual.
        </li>
      </ul>

      <p className="fs18 lh28 mrg-t24 ">
        That concludes the MyndFull Translations Attendee Tutorial. We hope you
        have a great experience — and please share your feedback with us, as
        we're always working to improve!
      </p>

      <div className="flex-center mrg-t36">
        <ItemButton
          text={'Return'}
          handler={() => setSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </div>
  );
};

export default TranslationsTutorialAttendee;

import { useState } from 'react';

//hooks
import { useSettings } from '../../../../context/SettingsProvider';
import { useSettingsHandlers } from '../../settings/useSettingsHandlers';

//language
import useLanguageEvents from '../../../../language/features/useLanguageEvents';
import useLanguageComponents from '../../../../language/useLanguageComponents';

//utils
import { settings } from '../../../../assets/icons/index';

import { useNavigate } from 'react-router-dom';

const EventsExpandSearchBtn = () => {
  //hooks
  const { handleMobileTap } = useSettings();
  const { handleSettingsSetAccountSettingsView } = useSettingsHandlers();
  const { settingsEventSearchView } = useSettingsHandlers();
  const navigate = useNavigate();

  //language
  const { EventsGrid } = useLanguageEvents();
  const { IconAlts } = useLanguageComponents();

  //ui
  const [highlightTapExpandSearchBtn, setHighlightTapExpandSearchBtn] =
    useState(false);

  //functions
  function handleNavigateToAccountAttendee(settingsView) {
    handleSettingsSetAccountSettingsView(settingsView);
    navigate('/account');
  }

  return (
    <>
      {settingsEventSearchView !== 'myevents' && (
        <button
          className={`ec-search-complete-change-search-btn access-ob access-o6 mrg-auto-bottom--ph ${
            highlightTapExpandSearchBtn
              ? 'ec-search-complete-change-search-btn--tap'
              : ''
          }`}
          tabIndex="0"
          aria-label={EventsGrid.expandSearchParameters}
          onClick={() =>
            handleMobileTap(
              [
                () => setHighlightTapExpandSearchBtn(true),
                () => setHighlightTapExpandSearchBtn(false),
              ],
              [() => handleNavigateToAccountAttendee('attendee')],
              true
            )
          }
        >
          <div className="pad-tb24 flex-center flex-column fs16 color-gray ff2">
            <p>{EventsGrid.expandSearchParameters}</p>
            <img
              src={settings}
              style={{ height: '44px' }}
              alt={IconAlts.iconSettings}
              className="filter-gray mrg-t12"
            />
          </div>
        </button>
      )}
    </>
  );
};

export default EventsExpandSearchBtn;

import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  selectCreateEventIdVerification,
  selectCreateEvent,
  setCreateEventIdVerification,
  resetCreateEvent,
  setCreateEvent,

  //contact
  selectCreateEventContactProvideContact,
  selectCreateEventContactContactEmail,
  selectCreateEventContactContactPhoneNumber,
  selectCreateEventContactCountryCode,
  setCreateEventContactProvideContact,
  setCreateEventContactEmail,
  setCreateEventContactPhoneNumber,
  setCreateEventContactCountryCode,

  //dateTime
  selectCreateEventDateTimeDate,
  selectCreateEventDateTimeTimeEnd,
  selectCreateEventDateTimeTimeStart,
  selectCreateEventDateTimeTimeZone,
  setCreateEventDateTimeDate,
  setCreateEventDateTimeTimeEnd,
  setCreateEventDateTimeTimeStart,
  setCreateEventDateTimeTimeZone,

  //generic
  selectCreateEventGenericAccessibilityNotes,
  selectCreateEventGenericAgendaItems,
  selectCreateEventGenericAgendaFormat,
  selectCreateEventGenericAudienceType,
  selectCreateEventGenericDescription,
  selectCreateEventGenericEventImageDisplay,
  selectCreateEventGenericEventAdditionalImages,
  selectCreateEventGenericEventType,
  selectCreateEventGenericLanguages,
  selectCreateEventGenericLanguagesNotListed,
  selectCreateEventGenericTitle,
  selectCreateEventGenericWheelchairAccessibility,
  selectCreateEventGenericSignLanguageInterpreter,
  setCreateEventGenericAccessibilityNotes,
  setCreateEventGenericAgendaItems,
  setCreateEventGenericAgendaFormat,
  setCreateEventGenericAudienceType,
  setCreateEventGenericDescription,
  setCreateEventGenericEventType,
  setCreateEventGenericLanguages,
  setCreateEventGenericLanguagesNotListed,
  setCreateEventGenericSignLanguageInterpreter,
  setCreateEventGenericTitle,
  setCreateEventGenericWheelchairAccessibility,
  setCreateEventGenericEventImageDisplay,
  setCreateEventGenericEventAdditionalImages,

  //hosting
  selectCreateEventHostingInPersonGeoPositionCoordinates,
  selectCreateEventHostingHostingOption,
  selectCreateEventHostingInPersonLocation,
  selectCreateEventHostingVirtualMeetingId,
  selectCreateEventHostingVirtualPasscode,
  selectCreateEventHostingVirtualPlatform,
  selectCreateEventHostingVirtualLink,
  setCreateEventHostingGeoPositionCoordinates,
  setCreateEventHostingHostingOption,
  setCreateEventHostingInPersonLocation,
  setCreateEventHostingVirtualMeetingId,
  setCreateEventHostingVirtualPasscode,
  setCreateEventHostingVirtualPlatform,
  setCreateEventHostingVirtualLink,
  reverseCreateEventHostingGeoPositionCoordinates,

  //meta
  selectCreateEventMetaEventReference,
  selectCreateEventMetaLastModified,
  selectCreateEventMetaSaveName,
  setCreateEventMetaSaveName,
  setCreateEventMetaId,

  //participants
  setCreateEventParticipants,
  selectCreateEventParticipants,

  //registrations
  selectCreateEventRegistrationDataApproveRegistrations,
  selectCreateEventRegistrationDataMaxRegistrationsInPersonEnabled,
  selectCreateEventRegistrationDataMaxRegistrationsVirtualEnabled,
  selectCreateEventRegistrationDataPrivateDetailsEnabled,
  selectCreateEventRegistrationDataMaxRegistrationsInPersonAmount,
  selectCreateEventRegistrationDataMaxRegistrationsVirtualAmount,
  selectCreateEventRegistrationDataPrivateDetails,
  selectCreateEventRegistrationDataRegistrationsActive,
  setCreateEventRegistrationDataApproveRegistrations,
  setCreateEventRegistrationDataMaxRegistrationsInPersonEnabled,
  setCreateEventRegistrationDataMaxRegistrationsVirtualEnabled,
  setCreateEventRegistrationDataPrivateDetailsEnabled,
  setCreateEventRegistrationDataMaxRegistrationsInPersonAmount,
  setCreateEventRegistrationDataMaxRegistrationsVirtualAmount,
  setCreateEventRegistrationDataPrivateDetails,
  setCreateEventRegistrationDataRegistrationsActive,

  //schedule
  selectCreateEventSchedulePublishDate,
  selectCreateEventSchedulePublishTime,
  selectCreateEventSchedulePublishTimeZone,
  setCreateEventSchedulePublishDate,
  setCreateEventSchedulePublishTime,
  setCreateEventSchedulePublishTimeZone,
  setCreateEventScheduleEnabled,
  selectCreateEventScheduleEnabled,

  //tags
  selectCreateEventTagsBigTags,
  selectCreateEventTagsTinyTags,
  addCreateEventTagsTinyTag,
  removeCreateEventTagsTinyTag,
  setCreateEventTagsBigTags,
  setCreateEventTagsTinyTags,
  selectCreateEventId,

  //translations
  setCreateEventTranslationsTranslationsEnabled,
  setCreateEventTranslationsRoomCodeId,
  setCreateEventTranslationsJoinRoomPasswordToggle,
  setCreateEventTranslationsPassword,
  setCreateEventTranslationsRoomLanguages,
  selectCreateEventTranslationsTranslationsEnabled,
  selectCreateEventTranslationsRoomCodeId,
  selectCreateEventTranslationsJoinRoomPasswordToggle,
  selectCreateEventTranslationsPassword,
  selectCreateEventTranslationsRoomLanguages,
} from './createEventSlice';

export const useCreateEventReduxHandlers = () => {
  const dispatch = useDispatch();

  const isCreateEventStateInitialized = Boolean(selectCreateEvent);

  //contact
  const contactProvideContact = useSelector(
    selectCreateEventContactProvideContact
  );
  const contactContactEmail = useSelector(selectCreateEventContactContactEmail);
  const contactContactPhoneNumber = useSelector(
    selectCreateEventContactContactPhoneNumber
  );
  const contactContactCountryCode = useSelector(
    selectCreateEventContactCountryCode
  );

  //dateTime
  const date = useSelector(selectCreateEventDateTimeDate);
  const timeEnd = useSelector(selectCreateEventDateTimeTimeEnd);
  const timeStart = useSelector(selectCreateEventDateTimeTimeStart);
  const timeZone = useSelector(selectCreateEventDateTimeTimeZone);

  //Generic
  const accessibilityNotes = useSelector(
    selectCreateEventGenericAccessibilityNotes
  );
  const agendaItems = useSelector(selectCreateEventGenericAgendaItems);
  const agendaFormat = useSelector(selectCreateEventGenericAgendaFormat);
  const audienceType = useSelector(selectCreateEventGenericAudienceType);
  const description = useSelector(selectCreateEventGenericDescription);
  const eventImageDisplay = useSelector(
    selectCreateEventGenericEventImageDisplay
  );
  const eventAdditionalImages = useSelector(
    selectCreateEventGenericEventAdditionalImages
  );
  const eventType = useSelector(selectCreateEventGenericEventType);
  const languages = useSelector(selectCreateEventGenericLanguages);
  const languagesNotListed = useSelector(
    selectCreateEventGenericLanguagesNotListed
  );
  const signLanguageInterpreter = useSelector(
    selectCreateEventGenericSignLanguageInterpreter
  );
  const title = useSelector(selectCreateEventGenericTitle);
  const wheelchairAccessibility = useSelector(
    selectCreateEventGenericWheelchairAccessibility
  );

  //Hosting
  const hostingOption = useSelector(selectCreateEventHostingHostingOption);
  const coordinates = useSelector(
    selectCreateEventHostingInPersonGeoPositionCoordinates
  );
  const virtualMeetingId = useSelector(
    selectCreateEventHostingVirtualMeetingId
  );
  const virtualPasscode = useSelector(selectCreateEventHostingVirtualPasscode);
  const virtualPlatform = useSelector(selectCreateEventHostingVirtualPlatform);
  const virtualLink = useSelector(selectCreateEventHostingVirtualLink);
  const location = useSelector(selectCreateEventHostingInPersonLocation);

  //Meta
  const createEventEventReference = useSelector(
    selectCreateEventMetaEventReference
  );
  const lastModified = useSelector(selectCreateEventMetaLastModified);
  const saveName = useSelector(selectCreateEventMetaSaveName);
  const createEventData = useSelector(selectCreateEvent);
  const createEventIdVerification = useSelector(
    selectCreateEventIdVerification
  );
  const createEventId = useSelector(selectCreateEventId);

  //Participants
  const createEventParticipants = useSelector(selectCreateEventParticipants);

  //RegistrationData
  const approveRegistrations = useSelector(
    selectCreateEventRegistrationDataApproveRegistrations
  );
  const maxRegistrationsInPersonEnabled = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsInPersonEnabled
  );
  const maxRegistrationsVirtualEnabled = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsVirtualEnabled
  );
  const maxRegistrationsInPersonAmount = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsInPersonAmount
  );
  const maxRegistrationsVirtualAmount = useSelector(
    selectCreateEventRegistrationDataMaxRegistrationsVirtualAmount
  );
  const registrationsActive = useSelector(
    selectCreateEventRegistrationDataRegistrationsActive
  );
  const privateDetails = useSelector(
    selectCreateEventRegistrationDataPrivateDetails
  );
  const enablePrivateDetails = useSelector(
    selectCreateEventRegistrationDataPrivateDetailsEnabled
  );

  //SchedulePublish
  const scheduleEnabled = useSelector(selectCreateEventScheduleEnabled);
  const schedulePublishDate = useSelector(selectCreateEventSchedulePublishDate);
  const schedulePublishTime = useSelector(selectCreateEventSchedulePublishTime);
  const schedulePublishTimeZone = useSelector(
    selectCreateEventSchedulePublishTimeZone
  );

  //Tags
  const bigTags = useSelector(selectCreateEventTagsBigTags);
  const tinyTags = useSelector(selectCreateEventTagsTinyTags);

  //Translations
  const createEventTranslationsTranslationsEnabled = useSelector(
    selectCreateEventTranslationsTranslationsEnabled
  );
  const createEventTranslationsRoomCodeId = useSelector(
    selectCreateEventTranslationsRoomCodeId
  );
  const createEventTranslationsJoinRoomPasswordToggle = useSelector(
    selectCreateEventTranslationsJoinRoomPasswordToggle
  );
  const createEventTranslationsPassword = useSelector(
    selectCreateEventTranslationsPassword
  );
  const createEventTranslationsRoomLanguages = useSelector(
    selectCreateEventTranslationsRoomLanguages
  );

  ////HANDLERS
  function handleCreateEventReturnEventData() {
    return createEventData;
  }

  function handleCreateEventReset(accountId) {
    dispatch(resetCreateEvent());
    dispatch(setCreateEventIdVerification(accountId));
  }

  function handleCreateEventSetEvent(eventData) {
    dispatch(setCreateEvent(eventData));
  }

  //contact
  function handleCreateEventSetProvideContact(boolean) {
    dispatch(setCreateEventContactProvideContact(boolean));
  }
  function handleCreateEventSetContactEmail(str) {
    dispatch(setCreateEventContactEmail(str));
  }
  function handleCreateEventSetContactPhoneNumber(str) {
    dispatch(setCreateEventContactPhoneNumber(str));
  }

  function handleCreateEventSetContactCountryCode(str) {
    dispatch(setCreateEventContactCountryCode(str));
  }

  //dateTime
  function handleCreateEventSetDate(str) {
    dispatch(setCreateEventDateTimeDate(str));
  }
  function handleCreateEventSetTimeZone(str) {
    dispatch(setCreateEventDateTimeTimeZone(str));
  }
  function handleCreateEventSetTimeStart(str) {
    dispatch(setCreateEventDateTimeTimeStart(str));
  }
  function handleCreateEventSetTimeEnd(str) {
    dispatch(setCreateEventDateTimeTimeEnd(str));
  }

  //Generic
  function handleCreateEventSetAgendaFormat(str) {
    dispatch(setCreateEventGenericAgendaFormat(str));
  }

  function handleCreateEventSetAgendaItems(arr) {
    dispatch(setCreateEventGenericAgendaItems(arr));
  }

  function handleCreateEventSetTitle(str) {
    dispatch(setCreateEventGenericTitle(str));
  }
  function handleCreateEventSetDescription(str) {
    dispatch(setCreateEventGenericDescription(str));
  }
  function handleCreateEventSetEventType(str) {
    dispatch(setCreateEventGenericEventType(str));
  }
  function handleCreateEventSetEventImageDisplay(str) {
    dispatch(setCreateEventGenericEventImageDisplay(str));
  }
  function handleCreateEventSetEventAdditionalImages(arr) {
    dispatch(setCreateEventGenericEventAdditionalImages(arr));
  }

  function handleCreateEventSetAudienceType(str) {
    dispatch(setCreateEventGenericAudienceType(str));
  }

  function handleCreateEventSetLanguages(arr) {
    if (arr?.includes('notListed')) {
      if (languagesNotListed?.length === 0) {
        handleCreateEventSetLanguagesNotListed([
          {
            id: uuidv4(),
            language: '',
          },
        ]);
      }
    } else {
      handleCreateEventSetLanguagesNotListed([]);
    }
    dispatch(setCreateEventGenericLanguages(arr));
  }

  function handleCreateEventSetLanguagesNotListed(arr) {
    dispatch(setCreateEventGenericLanguagesNotListed(arr));
  }

  function handleCreateEventSetWheelchairAccessibility(boolean) {
    dispatch(setCreateEventGenericWheelchairAccessibility(boolean));
  }

  function handleCreateEventSetSignLanguageInterpreter(
    signLanguageInterpreter
  ) {
    dispatch(
      setCreateEventGenericSignLanguageInterpreter(signLanguageInterpreter)
    );
  }

  function handleCreateEventSetAccessibilityNotes(str) {
    dispatch(setCreateEventGenericAccessibilityNotes(str));
  }

  //Hosting
  function handleCreateEventSetHostingOption(str) {
    dispatch(setCreateEventHostingHostingOption(str));
  }

  function handleCreateEventSetVirtualPlatform(str) {
    dispatch(setCreateEventHostingVirtualPlatform(str));
  }

  function handleCreateEventSetVirtualMeetingId(str) {
    dispatch(setCreateEventHostingVirtualMeetingId(str));
  }

  function handleCreateEventSetVirtualMeetingLink(str) {
    dispatch(setCreateEventHostingVirtualLink(str));
  }

  function handleCreateEventSetVirtualPasscode(str) {
    dispatch(setCreateEventHostingVirtualPasscode(str));
  }

  function handleCreateEventSetLocation(obj) {
    dispatch(setCreateEventHostingInPersonLocation(obj));
  }

  function handleCreateEventClearLocation() {
    dispatch(
      setCreateEventHostingInPersonLocation({
        room: '',
        locationName: '',
        streetAddress: '',
        city: '',
        country: '',
        postalCode: '',
        placeId: '',
      })
    );
    dispatch(setCreateEventHostingGeoPositionCoordinates([0, 0]));
  }

  function handleCreateEventSetCoordinates(arr) {
    dispatch(setCreateEventHostingGeoPositionCoordinates(arr));
  }

  function handleReverseCreateEventHostingGeoPositionCoordinates() {
    dispatch(reverseCreateEventHostingGeoPositionCoordinates());
  }

  //Meta
  function handleCreateEventSetSaveName(saveName) {
    dispatch(setCreateEventMetaSaveName(saveName));
  }
  function handleCreateEventSetId(id) {
    dispatch(setCreateEventMetaId(id));
  }

  //Participants
  function handleCreateEventSetParticipants(arr) {
    dispatch(setCreateEventParticipants(arr));
  }

  //RegistrationData
  function handleCreateEventSetRegistrations(boolean) {
    dispatch(setCreateEventRegistrationDataRegistrationsActive(boolean));
  }

  function handleCreateEventSetApproveRegistrations(boolean) {
    dispatch(setCreateEventRegistrationDataApproveRegistrations(boolean));
  }

  function handleCreateEventSetMaxRegistrationsInPersonEnabled(boolean) {
    dispatch(
      setCreateEventRegistrationDataMaxRegistrationsInPersonEnabled(boolean)
    );
  }

  function handleCreateEventSetMaxRegistrationsInPersonAmount(num) {
    dispatch(setCreateEventRegistrationDataMaxRegistrationsInPersonAmount(num));
  }

  function handleCreateEventSetMaxRegistrationsVirtualEnabled(boolean) {
    dispatch(
      setCreateEventRegistrationDataMaxRegistrationsVirtualEnabled(boolean)
    );
  }

  function handleCreateEventSetMaxRegistrationsVirtualAmount(num) {
    dispatch(setCreateEventRegistrationDataMaxRegistrationsVirtualAmount(num));
  }

  function handleCreateEventSetEnablePrivateDetails(enablePrivateDetails) {
    dispatch(
      setCreateEventRegistrationDataPrivateDetailsEnabled(enablePrivateDetails)
    );
  }

  function handleCreateEventSetPrivateDetails(id, val) {
    dispatch(setCreateEventRegistrationDataPrivateDetails({ id, val }));
  }

  //SchedulePublish
  function handleCreateEventSetScheduleEnabled(boolean) {
    dispatch(setCreateEventScheduleEnabled(boolean));
  }
  function handleCreateEventSetSchedulePublishDate(str) {
    dispatch(setCreateEventSchedulePublishDate(str));
  }
  function handleCreateEventSetSchedulePublishTime(str) {
    dispatch(setCreateEventSchedulePublishTime(str));
  }
  function handleCreateEventSetSchedulePublishTimeZone(str) {
    dispatch(setCreateEventSchedulePublishTimeZone(str));
  }

  //Tags
  function handleCreateEventAddTinyTag(str) {
    dispatch(addCreateEventTagsTinyTag(str));
  }

  function handleCreateEventRemoveTinyTag(str) {
    dispatch(removeCreateEventTagsTinyTag(str));
  }

  function handleCreateEventSetBigTags(arr) {
    dispatch(setCreateEventTagsBigTags(arr));
  }

  function handleCreateEventSetTinyTags(arr) {
    dispatch(setCreateEventTagsTinyTags(arr));
  }

  //Translations
  function handleCreateEventSetTranslationsTranslationsEnabled(boolean) {
    dispatch(setCreateEventTranslationsTranslationsEnabled(boolean));
  }
  function handleCreateEventSetTranslationsRoomCodeId(objId) {
    dispatch(setCreateEventTranslationsRoomCodeId(objId));
  }
  function handleCreateEventSetTranslationsJoinRoomPasswordToggle(boolean) {
    dispatch(setCreateEventTranslationsJoinRoomPasswordToggle(boolean));
  }
  function handleCreateEventSetTranslationsPassword(str) {
    dispatch(setCreateEventTranslationsPassword(str));
  }
  function handleCreateEventSetTranslationsRoomLanguages(arr) {
    dispatch(setCreateEventTranslationsRoomLanguages(arr));
  }

  return {
    handleCreateEventReturnEventData,
    isCreateEventStateInitialized,
    createEventIdVerification,
    createEventData,
    handleCreateEventReset,
    handleCreateEventSetEvent,
    handleCreateEventSetSaveName,

    //contact
    contactProvideContact,
    contactContactEmail,
    contactContactPhoneNumber,
    contactContactCountryCode,
    handleCreateEventSetProvideContact,
    handleCreateEventSetContactEmail,
    handleCreateEventSetContactPhoneNumber,
    handleCreateEventSetContactCountryCode,

    //dateTime
    date,
    timeEnd,
    timeStart,
    timeZone,
    handleCreateEventSetTimeEnd,
    handleCreateEventSetTimeStart,
    handleCreateEventSetTimeZone,
    handleCreateEventSetDate,

    //generic
    accessibilityNotes,
    agendaFormat,
    agendaItems,
    audienceType,
    description,
    eventImageDisplay,
    eventAdditionalImages,
    eventType,
    languages,
    languagesNotListed,
    signLanguageInterpreter,
    title,
    wheelchairAccessibility,
    handleCreateEventSetAccessibilityNotes,
    handleCreateEventSetAgendaFormat,
    handleCreateEventSetAgendaItems,
    handleCreateEventSetAudienceType,
    handleCreateEventSetDescription,
    handleCreateEventSetEventType,
    handleCreateEventSetLanguages,
    handleCreateEventSetSignLanguageInterpreter,
    handleCreateEventSetTitle,
    handleCreateEventSetWheelchairAccessibility,
    handleCreateEventSetEventImageDisplay,
    handleCreateEventSetEventAdditionalImages,
    handleCreateEventSetLanguagesNotListed,

    //hosting
    coordinates,
    hostingOption,
    location,
    virtualLink,
    virtualMeetingId,
    virtualPasscode,
    virtualPlatform,
    handleCreateEventSetVirtualMeetingId,
    handleCreateEventSetVirtualPasscode,
    handleCreateEventSetVirtualPlatform,
    handleCreateEventSetVirtualMeetingLink,
    handleCreateEventClearLocation,
    handleCreateEventSetCoordinates,
    handleCreateEventSetHostingOption,
    handleCreateEventSetLocation,
    handleReverseCreateEventHostingGeoPositionCoordinates,

    //meta
    saveName,
    lastModified,
    createEventId,
    createEventEventReference,
    handleCreateEventSetId,

    //participants
    createEventParticipants,
    handleCreateEventSetParticipants,

    //registrations
    maxRegistrationsInPersonEnabled,
    maxRegistrationsVirtualEnabled,
    maxRegistrationsInPersonAmount,
    maxRegistrationsVirtualAmount,
    approveRegistrations,
    enablePrivateDetails,
    privateDetails,
    registrationsActive,
    handleCreateEventSetEnablePrivateDetails,
    handleCreateEventSetRegistrations,
    handleCreateEventSetMaxRegistrationsInPersonEnabled,
    handleCreateEventSetMaxRegistrationsInPersonAmount,
    handleCreateEventSetMaxRegistrationsVirtualEnabled,
    handleCreateEventSetMaxRegistrationsVirtualAmount,
    handleCreateEventSetApproveRegistrations,
    handleCreateEventSetPrivateDetails,

    //schedule
    scheduleEnabled,
    schedulePublishDate,
    schedulePublishTime,
    schedulePublishTimeZone,
    handleCreateEventSetSchedulePublishDate,
    handleCreateEventSetSchedulePublishTime,
    handleCreateEventSetSchedulePublishTimeZone,
    handleCreateEventSetScheduleEnabled,

    //tags
    bigTags,
    tinyTags,
    handleCreateEventSetBigTags,
    handleCreateEventSetTinyTags,
    handleCreateEventAddTinyTag,
    handleCreateEventRemoveTinyTag,

    //translations
    createEventTranslationsTranslationsEnabled,
    createEventTranslationsRoomCodeId,
    createEventTranslationsJoinRoomPasswordToggle,
    createEventTranslationsPassword,
    createEventTranslationsRoomLanguages,
    handleCreateEventSetTranslationsTranslationsEnabled,
    handleCreateEventSetTranslationsRoomCodeId,
    handleCreateEventSetTranslationsJoinRoomPasswordToggle,
    handleCreateEventSetTranslationsPassword,
    handleCreateEventSetTranslationsRoomLanguages,
  };
};

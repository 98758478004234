import { useSelector, useDispatch } from 'react-redux';

//Hooks
import {
  setAdminSupportTickets,
  selectAdminSupportTickets,
  selectAdminSupportSearchQueries,
  removeAdminSupportTicketById,
  setAdminSupportTicket,
} from './adminSupportSlice';
import {
  useAcceptAssignmentMutation,
  usePendingUserActionMutation,
  useRemoveAssignmentMutation,
  useResolveTicketMutation,
  useReopenTicketMutation,
  useLazySearchReferenceQuery,
} from './adminSupportApiSlice';
import { useAdminAccountReduxHandlers } from '../account/useAdminAccountHandlers';
import { useAdminSettingsHandlers } from '../settings/useAdminSettingsHandlers';
import { useApiStatus } from '../../../context/ApiStatusProvider';

export const useAdminSupportReduxHandlers = () => {
  //Hooks
  const dispatch = useDispatch();
  const { handleWarning } = useApiStatus();
  const { adminAccountId } = useAdminAccountReduxHandlers();
  const { handleAdminSettingsTablesTicketSortingDate } =
    useAdminSettingsHandlers();

  //API
  const [searchReference, { isLoading: searchReferenceisLoading }] =
    useLazySearchReferenceQuery();

  const [acceptAssignment, { isLoading: acceptAssignmentisLoading }] =
    useAcceptAssignmentMutation();

  const [removeAssignment, { isLoading: removeAssignmentisLoading }] =
    useRemoveAssignmentMutation();
  const [pendingUserAction, { isLoading: pendingUserActionisLoading }] =
    usePendingUserActionMutation();

  const [resolveTicket, { isLoading: resolveTicketisLoading }] =
    useResolveTicketMutation();

  const [reopenTicket, { isLoading: reopenTicketisLoading }] =
    useReopenTicketMutation();

  //SELECTORS
  const adminSupportTickets = useSelector(selectAdminSupportTickets);

  const adminSupportSearchQueries = useSelector(
    selectAdminSupportSearchQueries
  );

  //Redux Handlers

  //TICKETS
  function handleAdminSupportSetTickets(arr) {
    dispatch(setAdminSupportTickets(arr));
  }

  function handleAdminSupportSetTicket(obj) {
    dispatch(setAdminSupportTicket(obj));
  }

  function handleAdminSupportRemoveTicketById(str) {
    dispatch(removeAdminSupportTicketById(str));
  }

  async function handleAcceptAssignment(ticketId, notes, adminReference) {
    if (!acceptAssignmentisLoading) {
      await acceptAssignment({
        adminAccountId,
        ticketId,
        notes,
        adminReference,
      });
    }
  }

  async function handleRemoveAssignment(ticketId, notes, adminReference) {
    if (!removeAssignmentisLoading) {
      await removeAssignment({
        adminAccountId,
        ticketId,
        notes,
        adminReference,
      });
    }
  }

  async function handlePendingUserAction(ticketId, notes, adminReference) {
    if (!pendingUserActionisLoading) {
      await pendingUserAction({
        adminAccountId,
        ticketId,
        notes,
        adminReference,
      });
    }
  }

  async function handleResolveTicket(ticketId, notes, adminReference) {
    if (!resolveTicketisLoading) {
      await resolveTicket({
        adminAccountId,
        ticketId,
        notes,
        adminReference,
      });
    }
  }

  async function handleReopenTicket(ticketId, notes, adminReference) {
    if (!reopenTicketisLoading) {
      await reopenTicket({
        adminAccountId,
        ticketId,
        notes,
        adminReference,
      });
    }
  }

  //searches
  async function handleSearchReference(searchType, referenceId) {
    if (!searchReferenceisLoading) {
      const res = await searchReference({
        adminAccountId,
        searchType,
        referenceId,
      });
      return res.data?.doc;
    }
  }

  async function handleViewEvent(eventReference, cardsRowIds, setCardsRowIds) {
    if (!searchReferenceisLoading) {
      const res = await handleSearchReference('event', eventReference);

      if (res?.[0]) {
        if (cardsRowIds?.includes(res[0]?._id)) {
          handleWarning({
            message: 'alreadySelected',
            id: Date.now(),
            origin: 'AdminSearch.js/searchReference',
          });
        } else {
          setCardsRowIds([...cardsRowIds, res[0]?._id]);
        }
      }
    }
  }

  //Sorting
  function handleAdminSupportTicketsSorting(sortingBoolean) {
    const copyArr = [...adminSupportTickets];

    let newSortingOrder;
    if (sortingBoolean === true) {
      newSortingOrder = 'descending';
    } else {
      newSortingOrder = 'ascending';
    }

    const sortedTickets = copyArr.sort((a, b) => {
      const dateA = new Date(a.meta.createdAt);
      const dateB = new Date(b.meta.createdAt);

      if (newSortingOrder === 'descending') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    handleAdminSupportSetTickets(sortedTickets);
    handleAdminSettingsTablesTicketSortingDate(newSortingOrder);
  }

  //Socket
  function handleSocketTicketsData(dataString) {
    let parsedData;
    try {
      parsedData = JSON.parse(dataString);
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        console.error('Dev, Error parsing JSON:', error);
      }
    }

    const docId = parsedData?.id;
    const operationType = parsedData.operationType;

    if (operationType) {
      if (operationType === 'delete' && docId) {
        handleAdminSupportRemoveTicketById(docId);
      } else if (
        parsedData.operationType === 'update' ||
        parsedData.operationType === 'insert'
      ) {
        if (parsedData?.ticket) {
          handleAdminSupportSetTicket(parsedData.ticket);
        }
      }
    }
  }

  return {
    //TICKETS
    adminSupportTickets,
    handleAdminSupportSetTickets,
    handleAdminSupportSetTicket,
    handleAcceptAssignment,
    handleRemoveAssignment,
    handlePendingUserAction,
    handleResolveTicket,
    handleReopenTicket,
    handleSocketTicketsData,
    handleAdminSupportRemoveTicketById,
    acceptAssignmentisLoading,
    removeAssignmentisLoading,
    pendingUserActionisLoading,
    resolveTicketisLoading,
    reopenTicketisLoading,

    //QUERY
    handleViewEvent,
    adminSupportSearchQueries,
    handleSearchReference,
    searchReferenceisLoading,

    //SORTING
    handleAdminSupportTicketsSorting,

    //load
  };
};

import { useEffect, useState } from 'react';

//hooks
import { useTranslationRoomSocket } from '../../../../../context/sockets/main/TranslationRoomSocketProvider';

//language
import useLanguageData from '../../../../../language/useLanguageData';
import useLanguageTranslationRoom from '../../../../../language/features/useLanguageTranslationRoom';
import useLanguageComponents from '../../../../../language/useLanguageComponents';

//components
import ButtonToggle from '../../../../../components/ButtonSwitch/ButtonToggle';
import ItemButton from '../../../../../components/ItemButton/ItemButton';
import Spinner from '../../../../../components/Spinner/Spinner';
import SelectionsModal from '../../../../../components/Selections/SelectionsModal';

//utility
import { image_speaker_languages } from '../../../../../assets/images';
import {
  img_language,
  return_curved_arrow,
  warning,
} from '../../../../../assets/icons';

const CreateRoomLanguages = () => {
  //hooks
  const {
    roomLanguages,
    setRoomLanguages,
    handleCreatePageChange,
    setIsLoadingCreatePage,
    isLoadingCreatePage,
  } = useTranslationRoomSocket();

  //language
  const { TranslationRoomCreateRoomLanguages } = useLanguageTranslationRoom();
  const { TranslationLanguageOpts } = useLanguageData();
  const { IconAlts } = useLanguageComponents();

  //state
  const [allLanguagesActive, setAllLanguagesActive] = useState(false);

  //UI
  const [imgLoaded, setImgLoaded] = useState(false);
  const [renderSpinner, setRenderSpinner] = useState(false);

  //UI
  useEffect(() => {
    if (imgLoaded) {
      setIsLoadingCreatePage(false);
    }
  }, [imgLoaded]);

  useEffect(() => {
    let loadSpinnerTransitionTimer = null;

    if (isLoadingCreatePage) {
      loadSpinnerTransitionTimer = setTimeout(() => {
        setRenderSpinner(true);
      }, 500);
    } else {
      if (renderSpinner) {
        setRenderSpinner(false);
      }
    }

    return () => clearTimeout(loadSpinnerTransitionTimer);
  }, [isLoadingCreatePage]); //will render the spinner if loading is taking longer than 500 ms

  //Functions
  function handleRoomLanguages(allLanguages) {
    if (allLanguages) {
      let newRoomLanguages = [];
      const languageKeys = Object.keys(TranslationLanguageOpts);
      newRoomLanguages = languageKeys.filter((opt) => opt !== 'select');
      setRoomLanguages(newRoomLanguages);
      setAllLanguagesActive(true);
    } else {
      setAllLanguagesActive(false);
      setRoomLanguages([]);
    }
  }

  return (
    <>
      {renderSpinner && <Spinner minHeight={'100%'} />}

      <div
        className={`translations__container translations__container--no-box-shadow ${
          renderSpinner ? 'remove' : ''
        }`}
      >
        <img
          src={image_speaker_languages}
          alt="img"
          style={{ height: '200px' }}
          className="svg"
          aria-hidden={true}
          onLoad={() => setImgLoaded(true)}
        />
        <div className="translations-selections">
          <p
            className="fs21 fwsb mrg-tb24 access-ob access-o6"
            tabIndex="0"
            aria-label={TranslationRoomCreateRoomLanguages.selectRoomLanguages}
            id={'createRoom'}
          >
            {TranslationRoomCreateRoomLanguages.selectRoomLanguages}
          </p>
          <ButtonToggle
            customWrapper={'mrg-b24 flex-center'}
            textLeft={TranslationRoomCreateRoomLanguages.manual}
            textRight={TranslationRoomCreateRoomLanguages.allAvailableLanguages}
            handleSwitch={handleRoomLanguages}
            loadValue={allLanguagesActive}
            loadTrigger={true}
          />

          {!allLanguagesActive && (
            <SelectionsModal
              addButtonText={TranslationRoomCreateRoomLanguages.addLanguages}
              DataOpts={TranslationLanguageOpts}
              fullWidth={true}
              iconModal={img_language}
              iconModalClass={'filter-theme'}
              iconOff={true}
              modalTitle={TranslationRoomCreateRoomLanguages.roomLanguages}
              selectionState={roomLanguages}
              setSelectionState={setRoomLanguages}
              subTextOn={false}
              id={'roomLanguages'}
              itemButtonCustomWrapper={'full-width'}
              customWrapper={'full-width'}
            />
          )}
          {roomLanguages?.length < 2 && (
            <div
              className={`flex-row flex-center access-ob access-o6 ${
                roomLanguages?.length === 1 ? 'mrg-t24' : ''
              }`}
              tabIndex="0"
            >
              <img
                src={warning}
                style={{ height: '21px' }}
                alt={IconAlts.iconWarning}
                className="mrg-r6"
              />
              <p className={`fs18 fwsb color-gray`}>
                {TranslationRoomCreateRoomLanguages.twoLanguagesRequired}
              </p>
            </div>
          )}

          <ItemButton
            text={TranslationRoomCreateRoomLanguages.continue}
            handler={() => handleCreatePageChange('createSpeakers')}
            customWrapper={
              !allLanguagesActive ? 'full-width mrg-t48' : 'full-width'
            }
            unavailable={
              (roomLanguages?.length < 2 ||
                roomLanguages?.includes('select')) &&
              !allLanguagesActive
            }
            iconOn={true}
            icon={return_curved_arrow}
            iconStyle={{ height: '21px' }}
            iconCustomClass={'reverse'}
          />

          <div className="divider full-width mrg-tb36" />

          <ItemButton
            text={TranslationRoomCreateRoomLanguages.back}
            handler={() => handleCreatePageChange('createPassword')}
            fullWidth={true}
          />
        </div>
      </div>
    </>
  );
};

export default CreateRoomLanguages;

import { useEffect, useRef, useState } from 'react';

//hooks
import { useSettings } from '../../../../../context/SettingsProvider.js';

//language
import useLanguageData from '../../../../../language/useLanguageData.js';
import useLanguageComponents from '../../../../../language/useLanguageComponents.js';

//components
import SpeakerActionBtns from './SpeakerActionBtns.js';
import TranslationsSpeakerData from './TranslationsSpeakerData.js';
import ItemButton from '../../../../../components/ItemButton/ItemButton.js';
import TranslationsSpeakerInputs from './TranslationsSpeakerInputs.js';

//utility
import { img_upload_file } from '../../../../../assets/icons/index.js';

//for translations drafts, added editMode for email invitations since the email input is typically done through the participants section of organize events

const TranslationsSpeakerBase = ({
  prevSpeaker,
  handleSaveAndReturn,
  roomLanguages,
  speakerNameOn,
  participantFormSubmissionView,
  participantFormSubmissionHandler,
  submitIsLoading,
  displaySpeakerName,
  handleRemoveSpeaker,
}) => {
  //hooks
  const { contentLanguage } = useSettings();

  //language
  const { TranslationLanguageOpts } = useLanguageData();
  const { Generic, IconAlts } = useLanguageComponents();

  //state
  const [speaker, setSpeaker] = useState({});
  const [roomLanguageOpts, setRoomLanguageOpts] = useState([]);
  const [editModeAvailable, setEditModeAvailable] = useState(false);
  const [editObj, setEditObj] = useState();

  //UI
  const initializationEffectRan = useRef(false);

  //initialize
  useEffect(() => {
    if (initializationEffectRan.current === false && prevSpeaker?.id) {
      initializationEffectRan.current = true;
      setSpeaker(prevSpeaker);

      if (
        prevSpeaker.organizedAs === 'inviteContact' ||
        prevSpeaker.organizedAs === 'emailInvitation'
      ) {
        setEditModeAvailable(true);
      }
    }
  }, [prevSpeaker]); //set initialize state

  useEffect(() => {
    const translationOpts = TranslationLanguageOpts;
    const filteredOpts = Object.keys(translationOpts)
      .filter((key) => roomLanguages.includes(key))
      .reduce((obj, key) => {
        obj[key] = translationOpts[key];
        return obj;
      }, {});
    setRoomLanguageOpts(filteredOpts);
  }, [contentLanguage, roomLanguages]); //set language options for speaker.

  return (
    <div className="flex-column full-width">
      {speaker && (
        <>
          {!participantFormSubmissionView && (
            <SpeakerActionBtns
              speaker={speaker}
              handleSaveAndReturn={handleSaveAndReturn}
              setEditObj={setEditObj}
              editObj={editObj}
              handleRemoveSpeaker={handleRemoveSpeaker}
            />
          )}

          {displaySpeakerName &&
          speaker?.translationsSpeakerData?.name &&
          (speaker.organizedAs === 'create' || editObj) ? (
            <p className="fs21 fwsb flex-center mrg-t24">
              {speaker.translationsSpeakerData.name}
            </p>
          ) : (
            displaySpeakerName && <div className="h24" />
          )}

          {editModeAvailable && !editObj ? (
            <TranslationsSpeakerData
              speakerData={speaker?.translationsSpeakerData}
              roomLanguages={roomLanguages}
            />
          ) : (
            <TranslationsSpeakerInputs
              speaker={speaker}
              roomLanguageOpts={roomLanguageOpts}
              speakerNameOn={speakerNameOn}
              setSpeaker={setSpeaker}
              roomLanguages={roomLanguages}
            />
          )}
        </>
      )}

      {participantFormSubmissionView && (
        <ItemButton
          fullWidth={true}
          handler={() => participantFormSubmissionHandler(speaker)}
          text={Generic.submit}
          id={'translationsFormSubmissionBtn'}
          customWrapper={'mrg-t48'}
          isLoading={submitIsLoading}
          iconOn={true}
          icon={img_upload_file}
          iconStyle={{ height: '21px' }}
          altText={IconAlts.iconFile}
        />
      )}
    </div>
  );
};

export default TranslationsSpeakerBase;

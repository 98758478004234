import { useEffect } from 'react';

//components
import ItemButton from '../../../../../../components/ItemButton/ItemButton';

//utils
import ScrollTo from '../../../../../../utils/UI/ScrollTo';

const HostSubPageTwo = ({ setHostSubPage }) => {
  //Initialize
  useEffect(() => {
    ScrollTo({
      scrollToTop: true,
    });
  }, []);

  return (
    <>
      <h1 className="fwsb fs21 lh36">Creating a Session</h1>
      <p className="fs18 lh28 mrg-t24">
        After you have logged in as a MyndFull Translations host, you can create
        a session. Most of the session setup is fairly straight-forward,
        including setting the room password, as well as the languages available
        to speakers and attendees.
      </p>

      <p className="fs18 lh28 mrg-t24">
        You'll also need to provide information for each speaker, including:
      </p>

      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">Spoken Language</li>
        <li className="mrg-t12">
          Voice Preference (how their translated speech will sound to event
          attendees)
        </li>
        <li className="mrg-t12">Discussion context</li>
        <li className="mrg-t12">Keywords</li>
      </ul>

      <p className="fs18 lh28 mrg-t24">
        Discussion context and keywords are critical to increase the accuracy of
        the translated audio and text. For more on speaker data, see the “Learn
        as a Speaker” section.
      </p>

      <h1 className="fwsb fs21 lh36 mrg-t24">
        Session Drafts & Speaker Profile Requests
      </h1>

      <p className="fs18 lh28 mrg-t24">
        One of MyndFull's helpful features is Session Drafts, which lets you
        prepare session data in advance. To make this even smoother, you can
        send speakers participating in your event a Translation Data Request via
        email. This email includes a link to a form hosted on MyndFull, where
        speakers can directly setup their speaker profiles (language, voice,
        discussion context, and keywords).
      </p>

      <p className="fs18 lh28 mrg-t24">
        Once a speaker submits the form, the corresponding Session Draft is
        automatically updated, saving you time and removing the need to manually
        collect and enter speaker info.
      </p>

      <h1 className="fwsb fs21 lh36 mrg-t24">Reconnects & Restore Session</h1>
      <p className="fs18 lh28 mrg-t24">
        MyndFull Translations includes built-in recovery features to help keep
        your session on track despite internet disconnections. While you're
        presenting, all speaker voice-to-text data is queued along with any room
        management actions. If there's a disconnection, MyndFull will
        automatically commence reconnecting. Once reconnected, all queued data
        is processed in the correct order — so nothing is lost or out of sync.
      </p>

      <p className="fs18 lh28 mrg-t24">
        If your browser unexpectedly closes during the session, don't worry.
        When you log back in, you'll have the option to "Restore Previous
        Session" and pick up right where you left off!
      </p>

      <p className="fs18 lh28 mrg-t24 fwsb">Key Takeaways:</p>
      <ul className="fs18 mrg-t24 mrg-l24">
        <li className="mrg-t12">
          Creating a Translations Session requires setup, but you can create
          Session Drafts ahead of time and load them the day of the event.
        </li>
        <li className="mrg-t12">
          You can invite speakers to submit their own Speaker Profile data via a
          secure form — no need to gather it manually.
        </li>
        <li className="mrg-t12">
          MyndFull Translations includes fallback features to keep sessions
          running smoothly during internet or browser disruptions.
        </li>
      </ul>

      <div className="translations-selections mrg-auto-lr mrg-t36">
        <ItemButton
          text={'Continue'}
          handler={() => setHostSubPage(3)}
          customWrapper={'item-button-standard mrg-b36 fs18'}
        />

        <ItemButton
          text={'Return'}
          handler={() => setHostSubPage(0)}
          customWrapper={'item-button-standard fs18'}
        />
      </div>
    </>
  );
};

export default HostSubPageTwo;
